import VautlOrgSelector from "./vault-org-selector";
import VaultInfoDisclaimer from "./vault-info-disclaimer";

const VaultOrgSelection = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      <VautlOrgSelector className="sm:col-span-2" />
      <VaultInfoDisclaimer 
        testId="vault-disclaimer"
        className="hidden md:block h-fit"
      />
    </div>
  );
};

export default VaultOrgSelection;
