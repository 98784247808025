import React, { useContext, useEffect, useMemo, useReducer } from 'react';
import OpenText from './open-text';
import { SnapSelectMenu } from '../suit';
import { SnapSelectMenuCustomEvent, SnapSelectMenuOption } from '@snap-mobile/snap-ui/dist/types/components';
import { APPROVER_ROLES, NEW_APPROVER_KEY } from './constants';
import { ApprovalsContext } from '../ApprovalsGlobalState';
import useGroupLeaderAssociatedSchoolMembers from '../hooks/use-group-leader-associated-school-members';
import { FundraiserApprover, UserPublic } from '../graphql/generated';
import { validEmail, validName } from './validation-helpers';
import { validatePhoneNumber } from '../utils/InputValidationUtils';
import { FundraiserApproverErrors, approverReducer, approverReducerEmptyErrors, approverReducerInitialState } from './approver-reducer';
import ReadonlyFormValue from '../layout/readonly-form-value';

interface ApproversProps {
  testId: string;
  locked?: boolean;
  onApproverChange?: (approver: FundraiserApprover) => void;
  className?: string;
}

const Approvers: React.FC<ApproversProps> = ({ testId, locked = false, onApproverChange = () => { }, className = "" }) => {
  const { appState } = useContext(ApprovalsContext);
  const { fundraiserApprovalForm: { editableSubmission } } = appState;
  const { organizationId, fundraiserApprovers } = editableSubmission;
  const associatedSchoolMembers: UserPublic[] = useGroupLeaderAssociatedSchoolMembers(organizationId || null);
  const [approverState, dispatch] = useReducer(approverReducer, approverReducerInitialState);
  const { approver, errors } = approverState;
  const approvers = useMemo(
    () => {
      const hasApprover = fundraiserApprovers.length > 0;
      const approverId = hasApprover ? fundraiserApprovers[0]?.userId : null;
      const memberOptions = associatedSchoolMembers.map(member => {
        return {
          name: `${member.firstName} ${member.lastName}`,
          value: member.id ? member.id : "",
          selected: approverId === member.id
        }
      });
      const allowedApprovers = [{
        name: "+ Add New Approver",
        value: NEW_APPROVER_KEY,
        selected: associatedSchoolMembers.length === 0 || approverId === NEW_APPROVER_KEY
      }];
      if (associatedSchoolMembers.length > 0) {
        allowedApprovers.push(...memberOptions);
      }
      return allowedApprovers;
    },
    [associatedSchoolMembers, fundraiserApprovers]
  );

  useEffect(() => {
    const newApproverProps = fundraiserApprovers.length > 0 ? fundraiserApprovers[0] : approverReducerInitialState.approver;
    dispatch({
      type: "UPDATE_APPROVER",
      payload: {
        approver: newApproverProps
      }
    });
  }, [fundraiserApprovers]);

  const roleOptions = Object.entries(
    APPROVER_ROLES
  ).map(([roleKey, roleName]) => ({
    name: roleName,
    value: roleKey,
    selected: roleKey === approver.roleId,
  }));

  const [selectedRoll] = Object.entries(
    APPROVER_ROLES
  ).flatMap(([roleKey, roleName]) => roleKey === approver.roleId ? roleName : null);

  const updateApproverState = (approverProps: FundraiserApprover): FundraiserApprover => {
    const updateProps = { ...approverProps };
    if (!approverProps.userId && !approver.userId) {
      updateProps.userId = NEW_APPROVER_KEY;
    }
    dispatch({
      type: "UPDATE_APPROVER",
      payload: {
        approver: updateProps
      }
    });
    return updateProps;
  };

  const updateApproverStateErrors = (errors: FundraiserApproverErrors) => {
    dispatch({
      type: "UPDATE_APPROVER",
      payload: {
        errors
      }
    });
  };

  const onApproverSelectChange = (e: SnapSelectMenuCustomEvent<any>) => {
    const selected = e.detail.find((option: SnapSelectMenuOption) => option.selected);
    const approverProps = {
      userId: selected.value,
      firstName: "",
      lastName: "",
      email: "",
      roleId: "",
      phone: "",
    };
    if (selected.value !== NEW_APPROVER_KEY) {
      const approverData = associatedSchoolMembers.find(member => member.id === selected.value);
      approverProps.firstName = approverData?.firstName || "";
      approverProps.lastName = approverData?.lastName || "";
    }
    const updatedProps = updateApproverState(approverProps);
    updateApproverStateErrors(approverReducerEmptyErrors);
    onApproverChange(updatedProps);
  };

  const onFirstNameChange = (firstName: string) => {
    const error = validName(firstName);
    updateApproverStateErrors({ firstName: error });
    if (error) {
      return;
    }
    const approverProps = {
      firstName: firstName,
    };
    const updatedProps = updateApproverState(approverProps);
    onApproverChange(updatedProps);
  };

  const onLastNameChange = (lastName: string) => {
    const error = validName(lastName);
    updateApproverStateErrors({ lastName: error });
    if (error) {
      return;
    }
    const approverProps = {
      lastName: lastName,
    };
    const updatedProps = updateApproverState(approverProps);
    onApproverChange(updatedProps);
  };

  const onEmailChange = (email: string) => {
    const error = validEmail(email);
    updateApproverStateErrors({ email: error });
    if (error) {
      return;
    }
    const approverProps = {
      email: email,
    };
    const updatedProps = updateApproverState(approverProps);
    onApproverChange(updatedProps);
  };

  const onRoleSelectChange = (e: SnapSelectMenuCustomEvent<any>) => {
    const selected = e.detail.find((option: SnapSelectMenuOption) => option.selected);
    const approverProps = {
      roleId: selected.value,
    };
    const updatedProps = updateApproverState(approverProps);
    onApproverChange(updatedProps);
  };

  const onPhoneChange = (phone: string) => {
    const error = validatePhoneNumber(phone);
    updateApproverStateErrors({ phone: error });
    if (error) {
      return;
    }
    const approverProps = {
      phone: phone,
    };
    const updatedProps = updateApproverState(approverProps);
    onApproverChange(updatedProps);
  };

  const showApproverFormField = (): boolean => {
    return approver.userId === NEW_APPROVER_KEY || associatedSchoolMembers.length === 0 || fundraiserApprovers[0]?.userId === NEW_APPROVER_KEY;
  };

  const lockedContent = (
    <div className='flex flex-col gap-5'>
      <ReadonlyFormValue testId={"approver-name"} label={"First Name"} value={approver.firstName || ""} />
      <ReadonlyFormValue testId={"approver-last-name"} label={"Last Name"} value={approver.lastName || ""} />
      <ReadonlyFormValue testId={"approver-email"} label={"Email"} value={approver.email || ""} />
      <ReadonlyFormValue testId={"approver-role"} label={"Role"} value={selectedRoll || ""} />
      <ReadonlyFormValue testId={"approver-phone"} label={"Phone"} value={approver.phone || ""} />
    </div>
  );
  const formContent = (
    <>

      <div className="flex flex-col sm:flex-row sm:pb-3">
        <OpenText
          fieldId="approver-first-name"
          testId="approver-first-name"
          label={"First Name"}
          value={approver.firstName || ""}
          required
          locked={locked}
          textarea={false}
          onBlur={onFirstNameChange}
          errorMessage={errors.firstName}
          className="flex-1 sm:mr-4 mb-3 sm:mb-0"
        />
        <OpenText
          fieldId="approver-last-name"
          testId="approver-last-name"
          label={"Last Name"}
          value={approver.lastName || ""}
          required
          locked={locked}
          textarea={false}
          onBlur={onLastNameChange}
          errorMessage={errors.lastName}
          className="flex-1 mb-3 sm:mb-0"
        />
      </div>
      <div className="flex flex-col sm:flex-row sm:pb-3">
        <OpenText
          fieldId="approver-email"
          testId="approver-email"
          label={"Email"}
          value={approver.email || ""}
          required
          locked={locked}
          textarea={false}
          onBlur={onEmailChange}
          errorMessage={errors.email}
          className="flex-1 sm:mr-4 mb-3 sm:mb-0"
        />
        <SnapSelectMenu
          error={!!errors.roleId}
          helpText={errors.roleId}
          id="approver-role"
          modalTitle="Role"
          selectMenuOptions={roleOptions}
          onSnap-select-menu-updated={onRoleSelectChange}
          label="Role"
          required
          locked={locked}
          className="flex-1 mb-3 sm:mb-0"
        />
      </div>
      <div className="flex flex-col sm:flex-row sm:pb-3">
        <OpenText
          fieldId="approver-phone"
          testId="approver-phone"
          label={"Phone"}
          value={approver.phone || ""}
          textarea={false}
          onBlur={onPhoneChange}
          errorMessage={errors.phone}
          locked={locked}
          className="flex-1 sm:mr-4 mb-3 sm:mb-0"
        />
        <div className="flex-1 hidden sm:block"></div>
      </div>
    </>
  )

  return (
    <div data-testid={testId} className={`${className}`}>
      <p className="text-gray-800 text-lg font-semibold pb-1">Approver Contact Information</p>
      {locked && !showApproverFormField() ?
        <ReadonlyFormValue testId={"approver-name"} label={`${approver.firstName} ${approver.lastName}`} value={""} />
        :
        (!locked && <SnapSelectMenu
          error={false}
          helpText={""}
          id="approver-selector"
          modalTitle="Approver"
          selectMenuOptions={approvers}
          onSnap-select-menu-updated={onApproverSelectChange}
          label="Approver"
          required
          locked={locked}
          className="flex-1 mb-3"
        />)
      }

      {showApproverFormField() ?
        locked ? lockedContent :
          formContent
        :
        null
      }
    </div>
  );
};

export default Approvers;
