import { useContext } from "react";
import { VaultPaymentProfileUrl } from "./constants";
import VaultIconImg from "../assets/VaultIcon.png";
import Modal from "../layout/modal";
import { SnapButton, SnapIcon, SnapLink } from "../suit";
import { isScreenSizeBelow } from "../utils/UIUtils";
import { AppContext } from "../AppGlobalState";

interface VaultWelcomeModalProps{
  showModal: boolean;
  onClose: () => void;
  onGetStarted: () => void;
}

const VaultWelcomeModal: React.FC<VaultWelcomeModalProps> = ({
  showModal,
  onClose,
  onGetStarted
}) => {
  const {appState} = useContext(AppContext);

  return(
    <Modal
      testId="vault-welcome-modal"
      showModal={showModal}
      onClose={onClose}
      verticalPosition={isScreenSizeBelow(appState.windowSize.screen, 'md') ? "bottom" : "center"}
      className="w-full sm:max-w-xl rounded-xl"
    >
      <div className="w-full sm:max-w-xl flex flex-col max-h-[inherit]">
        <div className="flex flex-col-reverse sm:flex-row justify-between w-full rounded-t-xl px-4 py-3 bg-brandblue">
          <div className="w-6"></div>
          <h3 className="text-center text-xl font-semibold text-white">Say Hello to Snap! Vault</h3>
          <div className="flex flex-row justify-end">
            <SnapIcon icon="x-solid" size="sm" className="cursor-pointer text-gray-200" onClick={onClose} />
          </div>
        </div>
        <div className="p-4 sm:pt-6 grow overflow-y-auto">
          <div className="flex flex-col sm:flex-row">
            <div className="">
              <h4 className="text-gray-800 text-lg font-medium pb-2">Set It and Forget It!</h4>
              <p className="text-gray-800 text-base font-normal pb-2">Snap! Vault enables you to securely create your financial profile which is required for all fundraising activities. Once submitted and approved, all programs within your organization will be able to run a fundraiser without the need to go through the process again.</p>
              <SnapLink
                text="Learn More"
                target="_blank"
                href={VaultPaymentProfileUrl}
                srOnly="srOnly"
                iconPosition="right"
                icon="external-link-solid"
              />
            </div>
            <div className="p-0 sm:pl-4 flex sm:block justify-center">
              <img src={VaultIconImg} alt="Snap! Vault" className="max-w-none w-40 sm:w-52" />
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center p-4 border-t sm:border-none border-gray-200 sticky -bottom-1 bg-white z-20">
          <SnapButton
            size="md"
            fullWidth
            variant="tertiary"
            className="w-auto sm:w-[40%]"
            onClick={onClose}
          >
            Remind Me Later
          </SnapButton>
          <SnapButton
            size="md"
            fullWidth
            variant="primary"
            className="ml-2  sm:ml-4 w-auto sm:w-[40%]"
            onClick={onGetStarted}
          >
            Get Started
          </SnapButton>
        </div>
        <div className="w-full h-9 bg-brandblue rounded-b-xl hidden sm:block"></div>
      </div>
    </Modal>
  )
  }

export default VaultWelcomeModal;
