import { GroupLeaderCampaign, UserWithPermissions } from "../graphql/generated";
import { RAISE_PRICE_AND_FEES_COPY, RAISE_HOW_IT_WORKS_COPY, FUNDRAISER_DURATION, FUNDRAISER_SUPPORT_INFO, NEW_FUNDRAISER_SERVICE_URL, SAFETY_AND_SECURITY_INFORMATION_COPY } from "./constants";

export const getSnapSelectionChangeFields = (fundraiser: GroupLeaderCampaign | null, user: UserWithPermissions, newFundraiser: boolean) => {
  if (newFundraiser || !fundraiser) return getNewOrNonSnapFundraiserChangeFields(user, newFundraiser);
  return getExistingSnapFundraiserChangeFields(fundraiser, user);
}

const getExistingSnapFundraiserChangeFields = (fundraiser: GroupLeaderCampaign | null, user: UserWithPermissions) => ({
  newFundraiser: false,
  firstName: user.firstName,
  lastName: user.lastName,
  campaignRaiseId: fundraiser ? parseInt(fundraiser?.id, 10) : '',
  groupSize: fundraiser ? fundraiser.teamSize : null,
  estimatedTotalRaisedCents: fundraiser ? fundraiser.goalInDollars : null,
  pricingAndFees: RAISE_PRICE_AND_FEES_COPY,
  howThisWorks: RAISE_HOW_IT_WORKS_COPY,
  customerSupportInfo: FUNDRAISER_SUPPORT_INFO,
  fundraiserServiceWebsite: fundraiser ? fundraiser.donationLink : null,
  duration: FUNDRAISER_DURATION,
  safety: SAFETY_AND_SECURITY_INFORMATION_COPY
})


const getNewOrNonSnapFundraiserChangeFields = (user: UserWithPermissions, newFundraiser: boolean) => ({
  newFundraiser: newFundraiser,
  firstName: user.firstName,
  lastName: user.lastName,
  campaignRaiseId: null,
  groupSize: null,
  estimatedTotalRaisedCents: null,
  pricingAndFees: RAISE_PRICE_AND_FEES_COPY,
  howThisWorks: RAISE_HOW_IT_WORKS_COPY,
  duration: FUNDRAISER_DURATION,
  customerSupportInfo: FUNDRAISER_SUPPORT_INFO,
  fundraiserServiceWebsite: newFundraiser ? NEW_FUNDRAISER_SERVICE_URL : "",
  safety: newFundraiser ? SAFETY_AND_SECURITY_INFORMATION_COPY : ""
});

export const findGroupLeaderCampaignById = (
  fundraiserList: GroupLeaderCampaign[],
  id: string
) => {
  return fundraiserList.filter((fundraiser) => fundraiser.id === id);
};
