

export enum FILE_STATUS {
  inProgress = "inProgress",
  success = "success",
  error = "error",
  previousUpload = "previousUpload"
}

export enum HTTP_OPERATIONS {
  get = "GET",
  put = "PUT",
  post = "POST",
  delete = "DELETE"
}

export const FILE_FORMATS = {
  jpeg: "image/jpeg",
  jpg: "image/jpg",
  gif: "image/gif",
  png: "image/png",
  webp: "image/webp",
  mp3: "audio/mpeg",
  ogg: "audio/ogg",
  mp4: "video/mp4",
  webm: "video/webm",
  csv: "text/csv",
  pdf: "application/pdf",
  xls: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  doc: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ppt: "application/vnd.ms-powerpoint",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  xml: "application/xml",
}
export type FileFormatType = keyof typeof FILE_FORMATS;
export type UploadResponse = { url: string, file: File, status: FILE_STATUS };

const uploadFile = async (
  url: string,
  file: File,
  progressRef: { upload: (event: ProgressEvent) => void }
): Promise<UploadResponse> => {
  const uploadPromise: Promise<UploadResponse> = new Promise((resolve, reject) => {

    const xhr = new XMLHttpRequest();
    xhr.open(HTTP_OPERATIONS.put, url, true);
    xhr.setRequestHeader("Content-Type", file.type);
    xhr.setRequestHeader("Content-Disposition", `attachment; filename="${file.name}"`);
    progressRef.upload({ loaded: 0, total: 100 } as ProgressEvent)
    xhr.upload.onprogress = (event) => progressRef.upload(event);
    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve({ url, file: file, status: FILE_STATUS.success });
      } else {
        reject({ url, file: file, status: FILE_STATUS.error });
      }
    };
    xhr.onerror = () => {
      progressRef.upload({ loaded: 0, total: 100 } as ProgressEvent)
      reject({ url, file: file, status: FILE_STATUS.error });
    };
    xhr.send(file);
  });

  return uploadPromise;
}

export type UploadFunction = typeof uploadFile;


export default uploadFile;
