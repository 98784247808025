import { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation } from "react-router-dom";
import DashboardList from '../components/dashboard/DashboardList';
import WhiteCard from '../components/WhiteCard';
import { isScreenSizeBelow } from '../utils/UIUtils';
import { AppContext } from '../AppGlobalState';
import SearchDashboard from '../components/SearchDashboard';
import { getDashboards, getDashboardsCount } from '../data-helpers/OrgsDataHelper';
import { DashboardSummary } from '../components/dashboard/DashboardTypes';
import { LoadingData } from '../components/loading-empty-state/LoadingData';
import { NoResults } from '../components/loading-empty-state/NoResults'; 
import { NoDashboards } from '../components/loading-empty-state/NoDashboards';
import { checkIfDashboardsEmpty } from '../data-helpers/PreapprovalsDataHelper';
import VaultWelcome from '../vault/vault-welcome';
import { SplitFeatureFlags } from '../constants/splitFeatureFlags';
import useOnOffTreatment from '../hooks/useOnOffTreatment';

const filterDashboardByName = (dashboards:DashboardSummary[], search:string):DashboardSummary[] => {
  return dashboards.filter(dashboard => dashboard.name.toLowerCase().includes(search.toLowerCase()));
};

const DashboardsPage = () => {
  const {appState} = useContext(AppContext);
  const { treatmentOn: vaultTreatmentOn } = useOnOffTreatment(SplitFeatureFlags.INS_818_vault_integration);
  const location = useLocation();
  const districtDashboards = getDashboards(appState, 'district') as DashboardSummary[];
  const schoolDashboards = getDashboards(appState, 'school') as DashboardSummary[];
  const affiliateDashboards = getDashboards(appState, 'affiliate') as DashboardSummary[];
  const [districtDashboardsFiltered, setDistrictDashboardsFiltered] = useState<DashboardSummary[] | null>(null);
  const [schoolDashboardsFiltered, setSchoolDashboardsFiltered] = useState<DashboardSummary[] | null>(null);
  const [affiliateDashboardsFiltered, setAffiliateDashboardsFiltered] = useState<DashboardSummary[] | null>(null);
  const [searchBarTouched, setSearchBarTouched] = useState<boolean>(false);

  useEffect( () => {
    window.history.pushState({}, 'Snap! Insights', '/dashboards');
  }, [] );

  const filtersApplied = (search:string) => {
    if (getDashboardsCount(appState) > 0 && !appState.orgsLoading) {
      if(search.length > 0 ) {
        setSearchBarTouched(true);
        setDistrictDashboardsFiltered(filterDashboardByName(districtDashboards, search));
        setSchoolDashboardsFiltered(filterDashboardByName(schoolDashboards, search));
        setAffiliateDashboardsFiltered(filterDashboardByName(affiliateDashboards, search));
      } else {
        setSearchBarTouched(false);
        setDistrictDashboardsFiltered(null);
        setSchoolDashboardsFiltered(null);
        setAffiliateDashboardsFiltered(null);
      }
    }
  };

  if(location.pathname !== '/dashboards') {
    return ( <Outlet /> );
  } else if(appState.orgsLoading && getDashboardsCount(appState) < 1 ) {
    return ( <LoadingData className='h-screen'/> );
  } else if(getDashboardsCount(appState) < 1 && !searchBarTouched) {
    return ( <NoDashboards/> );
  }else return (
      <div className="py-4 sm:px-6">
        <WhiteCard fullPageWidth={isScreenSizeBelow(appState.windowSize.screen, 'sm')}>
          <SearchDashboard onFiltersApplied={filtersApplied} />
          {checkIfDashboardsEmpty(districtDashboardsFiltered, schoolDashboardsFiltered, affiliateDashboardsFiltered) ? <NoResults/> : null}
          <DashboardList title='District dashboards' dashboards={districtDashboardsFiltered || districtDashboards} />
          <DashboardList title='School dashboards' dashboards={schoolDashboardsFiltered || schoolDashboards} />
          <DashboardList title='Affiliate dashboards' dashboards={affiliateDashboardsFiltered || affiliateDashboards} />
        </WhiteCard>
        {vaultTreatmentOn && <VaultWelcome />}
      </div>
    );
};

export default DashboardsPage;
