import { FundraiserApprovalSubmission, FundraiserApprovalSubmissionDocument, S3PresignedInsightsApprovalsUrlMutation } from "../graphql/generated";
import notEmpty from "../utils/not-empty";
import { UploadResponse } from "../utils/upload-file";
import { FileStatusMap } from "./contracts-and-documents";

export interface S3FileData {
  filename: string;
  s3Filename: string;
  url: string;
  status: string;
}
export interface S3FileDataHash {
  [name: string]: S3FileData;
}

export const getS3FileDataHash = (
  S3FileData: S3PresignedInsightsApprovalsUrlMutation
): S3FileDataHash | null => {
  if (!S3FileData || !S3FileData.s3PresignedInsightsApprovalsUrl) return null;
  return S3FileData.s3PresignedInsightsApprovalsUrl.reduce((mem, s3FileData) => {
    const url = new URL(s3FileData.url);
    url.hash = "";
    url.search = ""
    return {
      ...mem,
      [s3FileData.filename]: {
        ...s3FileData,
        url: url.toString()
      }
    }
  }, {})
}

export const getUpdatedFileStatus = (
  fileInfo: PromiseSettledResult<UploadResponse>[],
  fileStatus: FileStatusMap,
  S3FIleHash: S3FileDataHash
): FileStatusMap => {
  return fileInfo.reduce((mem: FileStatusMap, fileStatusPromises: PromiseSettledResult<UploadResponse>) => {
    const fileResponseObj: UploadResponse = fileStatusPromises.status === "fulfilled" ? fileStatusPromises.value : fileStatusPromises.reason
    const fileName: string = fileResponseObj.file?.name;
    return {
      ...mem,
      [fileName]: {
        ...mem[fileName],
        status: fileResponseObj.status,
        s3Url: S3FIleHash[fileName].url,
        s3Filename: S3FIleHash[fileName].s3Filename
      }
    }
  }, { ...fileStatus })
}

export const filterFilesToUpload = (fileStatus: FileStatusMap, status: string): File[] => {
  return Object.values(fileStatus).filter((fileStatus) => {
    return fileStatus.status === status
  }).map((status) => status.file);
}

export const removeDocFromDocList = (submission: FundraiserApprovalSubmission, fileKey: string): FundraiserApprovalSubmissionDocument[] => {
  const { fundraiserApprovalSubmissionDocs: existingDocs } = submission;
  const notEmptyExistingDocs = existingDocs.filter(notEmpty);
  return notEmptyExistingDocs.filter((doc) => doc?.s3Filename !== fileKey);
}

export const getFilesNameExtension = (
  files: string[]
): { name: string; ext: string }[] => {
  return files.map((file) => {
    if (file.indexOf("/") >= 0 || file.indexOf(".") < 0) {
      throw new Error(
        "Error: file name should not contain directories and has explicit file extension. " +
        file
      );
    }
    return {
      name: file.substring(0, file.lastIndexOf(".")),
      ext: file.substring(file.lastIndexOf(".") + 1),
    };
  });
};

export const getAvailableFileName = (file: File, fileStatus: FileStatusMap): string => {
  if (!fileStatus[file.name]) return file.name;
  const [{ name, ext }] = getFilesNameExtension([file.name]);
  const availableSuffixes = [1, 2];
  const firstAvailableSuffix = availableSuffixes.find((suffix) => {
    if (!fileStatus[`${name}(${suffix}).${ext}`]) return suffix;
    return null;
  })
  if (!firstAvailableSuffix)
    throw new Error(
      `File has reached max file versions (${availableSuffixes.length}) ${file.name}`
    );

  return `${name}(${firstAvailableSuffix}).${ext}`;
}
