
import { Navigate, RouteObject, useRoutes } from "react-router-dom";
import App from "./App";
import DashboardsPage from "./pages/Dashboards";
import DashboardPage from "./pages/Dashboard";
import ReportingPage from "./pages/Reporting";
import CampaignsPage from './pages/reports/Campaigns';
import SettlementsPage from './pages/reports/Settlements';
import SchoolsAffiliatesPage from './pages/reports/SchoolsAffiliates';
import ProgramsPage from './pages/reports/Programs';
import KYCFormsPage from './pages/reports/KYCForms';
import DonationsPage from './pages/reports/Donations';
import DepositsPage from './pages/reports/Deposits';
import RollupPage from "./pages/Rollup";
import SettingsPage from "./pages/Settings";
import Notifications from './pages/settingsNotifications/Notifications';
import PreApprovalPage from './pages/dashboardTabs/preApproval';
import OverviewPage from './pages/dashboardTabs/overview';
import PreApprovalCTAPage from "./pages/PreApprovalCTA";
import ApprovalForm from "./approvals/approval-form"
import ApprovalsApp from "./ApprovalsApp";
import ApprovalSubmission from "./approvals/approval-submission";
import InsightsApp from "./InsightsApp";
import VaultPage from "./vault/vault-page";
import VaultOrgSelection from "./vault/vault-org-selection";
import VaultOrgForm from "./vault/vault-org-form";
import ApprovalConfirmation from "./approvals/approval-confirmation";
import ApprovedPage from "./approvals/approved-page";
import DocumentsPage from "./approvals/documents-page";
import ApprovalRequestRevision from "./approvals/approval-request-revision";
import ApprovalRevisionConfirmation from "./approvals/approval-revision-confirmation";

const routes: RouteObject[] = [
  {
    element: <App />,
    path: "/",
    children: [
      { element: <Navigate to="dashboards"/>, index:true},
      {
        element: <InsightsApp />,
        path: "pre-approvals-cta",
        children:[
          {
            element: <PreApprovalCTAPage />,
            path: "",
          },
        ]
      },
      {
        element: <InsightsApp />,
        path: "dashboards",
        children:[
          {
          path:"",
          element: <DashboardsPage />,
          }
        ]
      },
      {
        path: "dashboards/",
        element: <InsightsApp />,
        children: [
          {
            path:"",
            element: <DashboardsPage />,
            },
          {
            element: <DashboardPage/>,
            path: ":dashboardId/",
            children: [
              {element: <OverviewPage/>, path: "overview"},
              {element: <PreApprovalPage/>, path: "preapprovals"},
              {element: <Navigate to="overview"/>, path: "*"},
              {element: <Navigate to="overview"/>, path: ""}
            ]
          },
          {element: <Navigate to="dashboards"/>, path: "*"},
          {element: <Navigate to="dashboards"/>, path: ""}
        ]
      },
      {
        element: <InsightsApp />,
        path: "vault",
        children: [
          {
            element: <VaultPage />,
            path: "",
            children: [
              {element: <VaultOrgSelection />, path: ""},
              {element: <VaultOrgForm />, path: ":orgId"},
            ],
          }
        ]
      },
      {
        element: <InsightsApp />,
        path: "reporting",
        children: [
          {
            element: <ReportingPage />,
            path: "",
            children:[
              { element: <CampaignsPage />, path: "campaigns"},
              { element: <SettlementsPage />, path: "settlements" },
              { element: <SchoolsAffiliatesPage />, path: "schools-affiliates" },
              { element: <ProgramsPage />, path: "programs" },
              { element: <KYCFormsPage />, path: "kyc-forms" },
              { element: <DonationsPage />, path: "donations" },
              { element: <DepositsPage />, path: "deposits" },
              { element: <Navigate to="campaigns"/>, path: "*"},
              { element: <Navigate to="campaigns"/>, path: ""}
            ]
          }
        ],
      },
      { element: <InsightsApp />, path: "rollup",
        children:[
          { element: <RollupPage />, path: "" }
        ]
      },
      {
        element: <InsightsApp />,
        path: "settings",
        children: [
          {
            element: <SettingsPage />,
            path: "",
            children:[
              { element: <Notifications />, path: "notifications"},
              { element: <Navigate to="notifications"/>, path: "*"},
              { element: <Navigate to="notifications"/>, path: ""}
            ]
        }],
      },
      {
        element: <ApprovalsApp />,
        path: "approvals/",
        children:[
          {
            element:<ApprovalForm/>,
            path: "form",
            children:[
              {
                element: <ApprovalSubmission/>,
                path: ":formId/"
              }
            ]
          },
          {
            element:<ApprovalConfirmation/>,
            path: "confirmation",
          },
          {
            element:<ApprovedPage/>,
            path: "approve/:token",
          },
          {
            element:<ApprovalRequestRevision />,
            path: "request-revision/:token",
          },
          {
            element:<ApprovalRevisionConfirmation />,
            path: "revision-confirmation",
          },
          {
            element:<DocumentsPage />,
            path: "documents",
          },
        ]
      },
      { element: <Navigate to="dashboards"/>, path: "*"}
    ],
  },
];

export const noPaddingRoutes = [
  '/reporting',
  '/dashboards',
  '/dashboards/',
  '/rollup',
  '/settings',
  '/vault'
];

function AppRouter() {
  const element = useRoutes(routes);

  return element;
}

export default AppRouter;
