import { useEffect, useRef } from "react";

const useDebouncedCallback = <T extends (...args: any[]) => any>(
  functionToDebounce: T,
  time: number
): T => {
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  return ((...args: Parameters<T>) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      functionToDebounce(...args);
    }, time);
  }) as T;
};

export default useDebouncedCallback;
