import React, { useRef, useState } from "react";
import { SnapIcon } from "../suit";
import { FILE_FORMATS, FileFormatType } from "../utils/upload-file";

const MB_SIZE = 1024 * 1024;



interface DropFileBoxProps {
  testId: string;
  id: string;
  showIcon?: boolean;
  fileFormats?: FileFormatType[];
  maxFileSizeMB?: number;
  onFilesReceived?: (files: File[]) => void;
  className?: string;
}

const DropFileBox: React.FC<DropFileBoxProps> = ({ testId, id, showIcon = true, fileFormats = [], maxFileSizeMB = 3, onFilesReceived = () => { }, className = "" }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [dragActive, setDragActive] = useState<boolean>(false);
  const [fileError, setFileError] = useState<boolean>(false);
  const allowedMimeTypes = fileFormats.map(f => FILE_FORMATS[f]);

  const handleDrag = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
      return;
    }
    if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const validateFiles = (files: FileList) => {
    const allowedFiles: File[] = [];
    const maxSizeBytes = maxFileSizeMB * MB_SIZE;
    let file: File;
    let fileErrors = false;
    for (var i = 0; i < files.length; i++) {
      file = files[i];
      if (file.size > maxSizeBytes || allowedMimeTypes.indexOf(file.type) < 0) {
        fileErrors = true;
        continue;
      }
      allowedFiles.push(file);
    }
    if (fileErrors) {
      setFileError(fileErrors);
    }
    if (allowedFiles.length > 0) {
      onFilesReceived(allowedFiles);
      if (inputRef.current) {
        inputRef.current.value = "";
      }
    }
  };

  const handleDrop = (e: DragEvent) => {
    setFileError(false);
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (!e.dataTransfer || !e.dataTransfer.files || !e.dataTransfer.files[0]) return;
    validateFiles(e.dataTransfer.files);
  };

  const handleChange = (e: Event) => {
    setFileError(false);
    e.preventDefault();
    if (!e.target) return;
    const target: HTMLInputElement = e.target as HTMLInputElement;
    if (!target.files || !target.files[0]) return;
    validateFiles(target.files);
  };

  const onButtonClick = () => {
    inputRef.current && inputRef.current.click();
  };

  const renderIcon = () => {
    return (
      <div data-testid={`${testId}-icon`} className="flex items-center justify-center w-16 h-16 rounded-[32px] bg-white border-blue-600 border mb-2">
        <SnapIcon icon="upload-alt-2-solid" size="xl" className="text-blue-600" />
      </div>
    );
  };

  const renderInstruction = () => {
    return (
      <p className="text-sm font-medium text-gray-600">
        Drag & Drop Files or&nbsp;
        <button
          className="cursor-pointer border-none bg-transparent hover:underline font-bold text-blue-700"
          onClick={onButtonClick}
        >
          Browse
        </button>
      </p>
    );
  };

  const renderFileLegend = () => {
    const allowedFormats = fileFormats.map(f => f.toUpperCase()).join(", ");
    return (
      <p className="text-sm font-normal text-gray-500">
        Supported Formats {allowedFormats} up to {maxFileSizeMB}MB
      </p>
    );
  };

  const renderFileErrorMessage = () => {
    return (
      <p className="text-xs font-normal text-red-600 pt-4">
        Some files were not processed as they exceeded max size or have incorrect format.
      </p>
    );
  };

  return (
    <form id={`${id}-form`} className="w-full max-w-full text-center relative" onDragEnter={e => handleDrag(e.nativeEvent)} onSubmit={e => e.preventDefault()}>
      <input
        ref={inputRef}
        type="file"
        id={`${id}-input`}
        data-testid={`${testId}-input`}
        multiple={false}
        accept={allowedMimeTypes.join(", ")}
        className="hidden"
        onChange={e => handleChange(e.nativeEvent)}
      />
      <label
        data-testid={testId + "-droppable"}
        id={`${id}-label`}
        htmlFor={`${id}-input-fu`}
        className={`flex items-center justify-center border-2 border-dashed bg-blue-50 border-gray-200 rounded-lg p-5 ${className}`}
      >
        <div className="flex flex-col items-center justify-center">
          {showIcon ? renderIcon() : null}
          {renderInstruction()}
          {renderFileLegend()}
          {fileError ? renderFileErrorMessage() : null}
        </div>
      </label>
      {dragActive &&
        <div
          id={`${id}-drag-file`}
          className="absolute w-full h-full top-0 bottom-0 left-0 right-0 bg-blue-300 opacity-25 rounded-lg"
          onDragEnter={(e) => { handleDrag(e.nativeEvent) }}
          onDragLeave={(e) => { handleDrag(e.nativeEvent) }}
          onDragOver={(e) => { handleDrag(e.nativeEvent) }}
          onDrop={(e) => { handleDrop(e.nativeEvent) }}>
        </div>
      }
    </form>
  );
};

export default DropFileBox;
