import React, { useContext, useEffect, useState } from 'react';
import { FinAcctStatus, VaultFinancialProvider, useOrgQuery, useVaultAccountInfoLazyQuery } from '../graphql/generated';
import { getLatestFinancialAccount } from '../vault/vault-data-helper';
import { AppContext } from '../AppGlobalState';

interface SingleOrgDataLoaderProps {
  orgId: string;
  onOrgDataLoaded: (orgId:string, data:any) => void;
}

const SingleOrgDataLoader: React.FC<SingleOrgDataLoaderProps> = ({ orgId, onOrgDataLoaded }) => {
  const { dispatch } = useContext(AppContext);
  const [vaultInfoRequired, setVaultInfoRequired] = useState<boolean>(false);
  const {data: orgData, loading: orgDataLoading} = useOrgQuery({variables: { orgId: orgId, hierarchy: true}});
  const[fetchVaultAccountInfo, {data: vaultAccountInfoData, loading: loadingVaultAccountInfo}] = useVaultAccountInfoLazyQuery({variables: {provider: VaultFinancialProvider.Stripe, finAcctId: "", accountId: ""}});

  useEffect(() => {
    if(orgDataLoading) {
      return;
    }
    const financialAccount = getLatestFinancialAccount(orgData?.financialAcctOrg || []);
    if(financialAccount && financialAccount.status === FinAcctStatus.ActionRequired) {
      //requires vault status
      setVaultInfoRequired(true);
      fetchVaultAccountInfo({variables: {provider: VaultFinancialProvider.Stripe, finAcctId: financialAccount.finAcctId || "", accountId: financialAccount.acctId}});
    } else {
      onOrgDataLoaded(orgId, orgData);
    } 
  }, [orgDataLoading]); //eslint-disable-line

  useEffect(() => {
    if(!vaultInfoRequired || loadingVaultAccountInfo) {
      return;
    }
    if(vaultAccountInfoData?.financialAcctUsers) {
      dispatch({
        type: "ADD_FINANCIAL_USERS_INDEX",
        payload: {
          orgId: orgId,
          financialUsers: vaultAccountInfoData.financialAcctUsers
        }
      });
    }
    if(vaultAccountInfoData?.vaultAccountStatus) {
      dispatch({
        type: "ADD_VAULT_STATUS_INDEX",
        payload: {
          orgId: orgId,
          vaultStatus: vaultAccountInfoData.vaultAccountStatus
        }
      });
    }
    onOrgDataLoaded(orgId, orgData);
  }, [vaultInfoRequired, loadingVaultAccountInfo]); //eslint-disable-line

  return (
    <></>
  );
};

export default SingleOrgDataLoader;
