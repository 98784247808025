import { useContext } from "react";
import { ApprovalsContext } from "../ApprovalsGlobalState";
import WhiteCard from "../components/WhiteCard";
import { isScreenSizeBelow } from "../utils/UIUtils";
import ApprovalContactFooter from "./approval-contact-footer";
import successfulImg from '../assets/Successful.png';
import insightsLogo from '../assets/InsightsLogo.png';
import { SnapButton, SnapIcon } from "../suit";
import { INSIGHTS_LEAD_GEN_FORM_URL } from "./constants";

interface ApprovalRevisionConfirmationNonSSOProps {
  submitterName: string;
  className?: string;
}

const ApprovalRevisionConfirmationNonSSO: React.FC<ApprovalRevisionConfirmationNonSSOProps> = ({ submitterName, className = ""}) => {
  const { appState } = useContext(ApprovalsContext);

  return (
    <main data-testid="revision-confirmation-non-sso">
      <WhiteCard
        fullPageWidth={isScreenSizeBelow(appState.windowSize.screen, "md")}
        borderRadius="large"
        className={`lg:w-[55%] mx-auto max-w-5xl ${className}`}
        paddingX="px-6"
      >
        <div className="flex flex-col items-center mb-5">
          <img src={successfulImg} alt="All set" className="w-1/3 my-5" />
          <h2 className="text-center text-2xl text-gray-800 font-semibold mb-2">Revisions Sent</h2>
          <p className="text-center text-base text-gray-800 font-medium pb-4">We'll let {submitterName} know you've requested revisions.</p>
          <div className={`flex flex-col items-center border border-gray-200 rounded-xl text-center bg-card-1-mobile md:bg-card-1 bg-right-bottom bg-no-repeat bg-[size:80%] w-full px-2 sm:px-4`}>
            <img src={insightsLogo} alt="Snap! Insights" className="w-1/3 my-4" />
            <h3 className="text-lg text-gray-800 font-medium pb-0 sm:pb-2">Get Full Administrative Control</h3>
            <p className="text-sm text-gray-800 font-normal">Snap! Insights gives you unmatched oversight for all your organization's fundraising efforts.</p>
            <div className="flex flex-col sm:flex-row justify-between gap-5 sm:gap-3 py-4 px-3 sm:px-0">
              <div className="flex flex-row sm:flex-col items-center text-left sm:text-center">
                <SnapIcon icon="search-solid" bgColor="#15339B" state="rounded" size="sm" color="white" className="mb-0 sm:mb-3 mr-3 sm:mr-0" />
                <p className="text-sm text-gray-800 font-normal">Manage your fundraiser approvals and revisions all from one convenient place.</p>
              </div>
              <div className="flex flex-row sm:flex-col items-center text-left sm:text-center">
                <SnapIcon icon="pencil-alt-solid" bgColor="#15339B" state="rounded" size="sm" color="white" className="mb-0 sm:mb-3 mr-3 sm:mr-0" />
                <p className="text-sm text-gray-800 font-normal">Make edits to fundraiser requests and save on approval time.</p>
              </div>
              <div className="flex flex-row sm:flex-col items-center text-left sm:text-center">
                <SnapIcon icon="document-report-solid" bgColor="#15339B" state="rounded" size="sm" color="white" className="mb-0 sm:mb-3 mr-3 sm:mr-0" />
                <p className="text-sm text-gray-800 font-normal">Run comprehensive reports and track the health of your organization.</p>
              </div>
            </div>
            <SnapButton
              href={INSIGHTS_LEAD_GEN_FORM_URL}
              fullWidth
              variant="primary"
              className="mb-4"
            >
              Learn More & Get Started
            </SnapButton>
          </div>
        </div>
        <ApprovalContactFooter testId="footer" />
      </WhiteCard>
    </main>
  );
};

export default ApprovalRevisionConfirmationNonSSO;
