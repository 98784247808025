export const EmailStatus = {
    OPEN : "open",
    PENDING: "pending",
    CLICKED: "clicked",
    CREATED : "created",
    DELIVERED : "delivered",
    LINK_OPEN : "link_open",
    BOUNCE : "bounce",
    DEFERRED : "deferred",
    DROPPED : "dropped",
    UNDELIVERABLE : "undeliverable",
    RE_SENT : "re-sent"
}

export const FilteredOptions = {
    CLOSED : "closed",
    UPCOMING: "upcoming",
    ACTIVE: "active"
}

export const PhoneRegEx = /^\(?([2-9][0-8][0-9])\)?[-.● ]?([2-9][0-9]{2})[-.● ]?([0-9]{4})$/;