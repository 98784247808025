import Modal from "./modal";
import { SnapButton, SnapIcon } from "../suit";

interface ConfirmActionProps {
  testId: string;
  showConfirmation: boolean;
  message: string;
  onConfirmationClose?: () => void;
  action?: () => void;
  className?: string;
}

const ConfirmAction: React.FC<ConfirmActionProps> = ({ testId, showConfirmation, message, onConfirmationClose = () => {}, action = () => {}, className = "" }) => {
  return (
    <Modal
      testId={testId}
      showModal={showConfirmation}
      onClose={onConfirmationClose}
      className={`min-w-80 rounded-md ${className}`}
    >
      <div className="flex flex-row justify-between items-center px-4 py-3 border-b border-gray-200">
        <span className="text-base font-bold text-gray-600">Confirmation</span>
        <SnapIcon icon="x-solid" size="md" className="cursor-pointer text-gray-500" onClick={onConfirmationClose} />
      </div>
      <div className="py-6 px-4">
        <p className="text-base text-gray-600 font-normal">{message}</p>
      </div>
      <div className="flex flex-row justify-end pt-2 pb-4 px-4">
        <SnapButton
          onClick={onConfirmationClose}
          fullWidth
          className="mr-2"
        >
          Cancel
        </SnapButton>
        <SnapButton
          onClick={action}
          variant="primary"
          fullWidth
        >
          Confirm
        </SnapButton>
      </div>
    </Modal>
  );
}

export default ConfirmAction;
