import { useReducer } from "react"
import { UserNotificationPreference } from "../../graphql/generated"
import { isUserNotificationOffOnDB } from "./Utils"

interface userNotificationsState  {
    affectedAddedNotifications: string[]
    affectedRemovedNotifications: string[]
}

const initialState: userNotificationsState = {
    affectedAddedNotifications: [],
    affectedRemovedNotifications: []
}

interface UpdateUserSettingsPayload {
    addedUserNotifications: string[],
    removedUserNotifications: string[]
}
interface UserSettingsReducerAction {
    type: string;
    payload: UpdateUserSettingsPayload;
  }

const reducer = (state: userNotificationsState, action: UserSettingsReducerAction)=>{
    const {payload} = action;
    switch (action.type) {
        case "UPDATE_USER_NOTIFICATIONS":
            return {
              ...state,
              affectedAddedNotifications: payload.addedUserNotifications,
              affectedRemovedNotifications: payload.removedUserNotifications
            }
        default:
            return state;

    }
}

export const UseUserNotificationsSettings = (userNotificationPreferences: UserNotificationPreference[])=>{

    const [userNoticationsState, dispatch] = useReducer(reducer, initialState)
   
    const removeUserNotification = (notificationId:string) => {
        let updatedRemoveddUserNotification = userNoticationsState.affectedRemovedNotifications;

        if(!isUserNotificationOffOnDB(userNotificationPreferences, notificationId)){
            const updatedUserNotificationHash = Object.assign({...userNoticationsState.affectedRemovedNotifications, notificationId})
            updatedRemoveddUserNotification = (Object.values(updatedUserNotificationHash));
        }

        const updatedAddedNotifications = [...userNoticationsState.affectedAddedNotifications].filter((currentNotificationId)=> currentNotificationId !== notificationId);

        dispatch({
            type: "UPDATE_USER_NOTIFICATIONS",
            payload: {
                addedUserNotifications: updatedAddedNotifications,
                removedUserNotifications: updatedRemoveddUserNotification
            }
        })
    }

    const addUserNotifcation = (notificationId:string) =>{
        let updatedAddedNotifications = userNoticationsState.affectedAddedNotifications;

        if(isUserNotificationOffOnDB(userNotificationPreferences, notificationId)){
            const updatedUserNotificationHash = Object.assign({...userNoticationsState.affectedAddedNotifications, notificationId})
            updatedAddedNotifications = (Object.values(updatedUserNotificationHash));
        }
        const updatedRemovedotifications = [...userNoticationsState.affectedRemovedNotifications].filter((currentNotificationId)=> currentNotificationId !== notificationId);
        dispatch({
            type: "UPDATE_USER_NOTIFICATIONS",
            payload: {
                addedUserNotifications: updatedAddedNotifications,
                removedUserNotifications: updatedRemovedotifications
            }
        })
    }

    const isUserNotificationRemoved = (userNotificationId:string) : boolean | undefined=>{
        if(userNoticationsState.affectedRemovedNotifications.indexOf(userNotificationId) >= 0){
            return true;
        } else {
          if(isUserNotificationOffOnDB(userNotificationPreferences, userNotificationId) && 
          userNoticationsState.affectedAddedNotifications.indexOf(userNotificationId) < 0
          ) {
            return true;
          } else{
            return false;
          }
        }
    }
    const resetUserNotifications = ()=>{
        dispatch({
            type: "UPDATE_USER_NOTIFICATIONS",
            payload: {
                addedUserNotifications: [],
                removedUserNotifications: []
            }
        })
    }

    return {
        addUserNotifcation,
        affectedAddedNotifications: userNoticationsState.affectedAddedNotifications,
        affectedRemovedNotifications: userNoticationsState.affectedRemovedNotifications,
        isUserNotificationRemoved,
        removeUserNotification,
        resetUserNotifications
    }
}
