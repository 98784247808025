import { useContext } from "react";
import { ApprovalsContext } from "../ApprovalsGlobalState";
import WhiteCard from "../components/WhiteCard";
import { isScreenSizeBelow } from "../utils/UIUtils";
import ApprovalContactFooter from "./approval-contact-footer";
import successfulImg from '../assets/Successful.png';
import { SnapButton } from "../suit";

interface ApprovedSSOProps {
  submitterName: string;
  className?: string;
}

const ApprovedSSO: React.FC<ApprovedSSOProps> = ({ submitterName, className = ""}) => {
  const { appState } = useContext(ApprovalsContext);

  return (
    <main data-testid="approved-sso-confirmation">
      <WhiteCard
        fullPageWidth={isScreenSizeBelow(appState.windowSize.screen, "md")}
        borderRadius="large"
        className={`lg:w-[55%] mx-auto max-w-5xl ${className}`}
        paddingX="px-6"
      >
        <div className="flex flex-col items-center mb-5">
          <img src={successfulImg} alt="All set" className="w-1/3 my-5" />
          <h2 className="text-center text-2xl text-gray-800 font-semibold mb-2">You're All Set!</h2>
          <p className="text-center text-base text-gray-800 font-medium pb-4">We'll let {submitterName} know that you've approved the fundraiser.</p>
          <p className="text-center text-base text-gray-800 font-medium">Manage your fundraiser approvals and revisions all from Snap! Insights.</p>
          <div className="w-full flex flex-col sm:flex-row items-center sm:justify-center my-5">
            <SnapButton
              href="/"
              fullWidth
              variant="primary"
              className="w-full sm:w-1/3 mb-4 sm:mb-0 sm:mr-4"
            >
              Go to Snap! Insights
            </SnapButton>
            <SnapButton
              href="/"
              fullWidth
              className="w-full sm:w-1/3"
            >
              Close Window
            </SnapButton>
          </div>
        </div>
        <ApprovalContactFooter testId="footer" />
      </WhiteCard>
    </main>
  );
};

export default ApprovedSSO;
