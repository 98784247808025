import { Outlet } from "react-router-dom";
import Header from "./components/Header";
import { SplitFeatureFlags } from "./constants/splitFeatureFlags";
import useOnOffTreatment from "./hooks/useOnOffTreatment";
import ApprovalsGlobalState from "./ApprovalsGlobalState";
import ImpersonationModeWrapper from "./components/ImpersonationModeWrapper";

const ApprovalsApp = ()=>{
  const { treatmentOn: ins670TreatmentOn } = useOnOffTreatment(SplitFeatureFlags.INS_670_approvals);
  return(
    <>
    {ins670TreatmentOn && (
      <ApprovalsGlobalState>
        <ImpersonationModeWrapper>
          <Header theme={"mobile"}/>
          <main className="w-full pt-16">
            <Outlet />
          </main>
        </ImpersonationModeWrapper>
      </ApprovalsGlobalState>
    )}
    </>
  )
}

export default ApprovalsApp;
