import React from "react";
import { SnapInput } from "../suit";

interface groupSizeProps {
  fieldId: string;
  testId: string;
  className?: string;
  value: string;
  errorMessage: string;
  onValueChange: (value:string)=>void;
  onInput: (value:string)=>void;
}

const GroupSize: React.FC<groupSizeProps>  = ({value, errorMessage, onValueChange, onInput, testId, fieldId, className})=>{
    return (
      <div data-testid={testId} className={className}>
          <SnapInput
           _id={fieldId}
           _type="number"
           label="Group Size"
           value={value}
           required={true}
           error={!!errorMessage}
           helpText={!!errorMessage ? errorMessage : "" }
           errorIcon={true}
           placeholder={"0"}
           name={"approval-gz"}
           onInput={(e) => {onInput((e.target as HTMLInputElement).value) }}
           onSnap-input-change={(e) => {onValueChange((e.target as unknown as HTMLInputElement).value) }}
           />
      </div>
    )
};

export default GroupSize;
