import React from "react";
import { SnapInput } from "../suit";

interface OpenTextProps {
  fieldId: string;
  testId: string;
  label: string;
  className?: string;
  value: string;
  errorMessage?: string;
  placeholder?: string;
  locked?: boolean;
  required?: boolean;
  onBlur?: (value: string) => void;
  onInput?: (value: string) => void;
  displayCharCount?: boolean;
  maxLength?: string;
  textarea?: boolean;
}

const OpenText: React.FC<OpenTextProps> = ({
  required = false,
  value,
  errorMessage,
  onBlur,
  onInput,
  testId,
  fieldId,
  label,
  className,
  placeholder,
  locked = false,
  displayCharCount = false,
  maxLength = "1500",
  textarea = true,
}) => {
  const icon = locked ? "lock-closed-solid" : undefined;
  return (
    <div data-testid={testId} className={className}>
      <SnapInput
        _id={fieldId}
        _type="text"
        icon={icon}
        locked={locked ? locked : false}
        required={required ? required : false}
        textarea={textarea}
        label={label}
        value={value}
        error={!!errorMessage}
        helpText={!!errorMessage ? errorMessage : ""}
        errorIcon={true}
        placeholder={placeholder}
        name={fieldId}
        maxlength={maxLength}
        onBlur={(e) => {
          onBlur && onBlur(e.target.value);
        }}
        onInput={(e) => {
          onInput && onInput((e.target as HTMLInputElement).value);
        }}
        disabled={locked}
        displayCharCount={displayCharCount}
      />
    </div>
  );
};

export default OpenText;
