import { ReactElement, useContext } from "react";
import { ApprovalsContext } from "../ApprovalsGlobalState";
import { PAGE_ERRORS } from "./constants";
import {
  SnapAlert,
  SnapAlertAction,
  SnapAlertDescription,
  SnapAlertTitle,
  SnapButton,
  SnapLink,
} from "../suit";
import { useNavigate } from "react-router-dom";
import WhiteCard from "../components/WhiteCard";
import { isScreenSizeBelow } from "../utils/UIUtils";

const ErrorPage = () => {
  const { appState, dispatch } = useContext(ApprovalsContext);
  const navigate = useNavigate();

  const onCreateFormClick = () => {
    dispatch({
      type: "FUNDRAISER_APPROVALS_SUBMISSION_INVALID_ACCESS",
      payload: { error: null },
    });
    navigate("/approvals/form");
  };
  let message: ReactElement = <p></p>;
  switch (appState.pageError) {
    case PAGE_ERRORS.invalidSubmissionAccess:
      message = (
        <p>
          There is a problem accessing this form, please reach support or click{" "}
          <SnapLink
            size="sm"
            variant="warning"
            onClick={onCreateFormClick}
          >
            here
          </SnapLink>{" "}
          to create a new Form instead
        </p>
      );
      break;
    case PAGE_ERRORS.invalidToken:
      message = (
        <p>There is a problem accessing this form, please reach support"</p>
      );
      break;
    default:
      break;
  }
  if (!appState.pageError) return null;
  return (
    <WhiteCard
      fullPageWidth={isScreenSizeBelow(appState.windowSize.screen, "md")}
      borderRadius="large"
      className=" pb-64 lg:w-[55%] border-grey-200 bg-white  mx-auto max-w-5xl"
    >
      <SnapAlert type="warning" border={true} close={false}>
        <SnapAlertTitle>There was an error.</SnapAlertTitle>
        <SnapAlertDescription>{message}</SnapAlertDescription>
        <SnapAlertAction slot="start">
          <SnapButton
            onClick={onCreateFormClick}
            size="md"
            variant="warning"
          >
            Create New Form
          </SnapButton>
        </SnapAlertAction>
      </SnapAlert>
    </WhiteCard>
  );
};

export default ErrorPage;
