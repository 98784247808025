import { useContext, useEffect, useState } from "react";
import WhiteCard from "../components/WhiteCard";
import { ApprovalsContext } from "../ApprovalsGlobalState";
import { isScreenSizeBelow } from "../utils/UIUtils";
import exclamationImg from '../assets/ExclamationMark.png';
import ApprovalContactFooter from "./approval-contact-footer";
import ApprovalRevisionForm from "./approval-revision-form";
import ApprovalDetailViewer from "./approval-detail-viewer";
import ApprovalSubmissionHistory from "./approval-submission-history";
import { useNavigate, useParams } from "react-router-dom";
import { FundraiserApprovalSubmission, FundraiserApprovalSubmissionsOrgNames, RevisionFundraiserApprovalSubmissionData, useInsightsApprovalSubmissionHistoryLazyQuery } from "../graphql/generated";
import SnapFundraiserDetailsSkeleton from "./snap-fundraiser-details-skeleton";
import { REVISION_CONFIRMATION_PAGE } from "../constants/app-routes";


const ApprovalRequestRevision = () => {
  const { appState, dispatch } = useContext(ApprovalsContext);
  const { token } = useParams();
  const navigate = useNavigate();
  const [fetchSubmissionHistory, { data: submissionHistoryData, loading: submissionHistoryLoading }] = useInsightsApprovalSubmissionHistoryLazyQuery();
  const[submissionsNotFound, setSubmissionsNotFound] = useState<boolean>(false);
  const[latestSubmission, setLatestSubmission] = useState<FundraiserApprovalSubmission|null>(null);
  const[submissionHistory, setSubmissionHistory] = useState<FundraiserApprovalSubmission[]>([]);
  const[orgNames, setOrgNames] = useState<FundraiserApprovalSubmissionsOrgNames|null>(null);

  useEffect(() => {
    if (token) {
      fetchSubmissionHistory({variables: {token: token}});
    }
  }, [token, fetchSubmissionHistory]);

  useEffect(() => {
    if (submissionHistoryData) {
      const allSubmission = submissionHistoryData?.fundraiserApprovalSubmissionsHistory ? submissionHistoryData?.fundraiserApprovalSubmissionsHistory : [];
      setSubmissionsNotFound(allSubmission.length === 0);
      setLatestSubmission(allSubmission && allSubmission.length > 0 ? allSubmission[allSubmission.length - 1] : null);
      setSubmissionHistory(allSubmission && allSubmission.length > 1 ? allSubmission.slice(0, -1) : []);
      setOrgNames(submissionHistoryData.fundraiserApprovalSubmissionsOrgNames.__typename === "FundraiserApprovalSubmissionsOrgNames" ? submissionHistoryData.fundraiserApprovalSubmissionsOrgNames : null);
    }
  }, [submissionHistoryData]);

  const onFormSubmitted = (result: RevisionFundraiserApprovalSubmissionData) => {
    dispatch({
      type: "SET_REVISION_CONFIRMATION_PROPS",
      payload: {
        submitterName: result.submitterName,
        isSsoUser: result.isSsoApprover,
      },
    });
    navigate(REVISION_CONFIRMATION_PAGE);
  };

  if (submissionHistoryLoading) {
    return (
      <main data-testid="approval-confirmation">
        <WhiteCard
          fullPageWidth={isScreenSizeBelow(appState.windowSize.screen, "md")}
          borderRadius="large"
          className={"lg:w-[55%] mx-auto max-w-5xl"}
          paddingX="px-6"
        >
          <SnapFundraiserDetailsSkeleton />
        </WhiteCard>
      </main>
    );
  }

  if (!token || submissionsNotFound) {
    return (
      <main data-testid="request-revision-not-found">
        <WhiteCard
          fullPageWidth={isScreenSizeBelow(appState.windowSize.screen, "md")}
          borderRadius="large"
          className={"lg:w-[55%] mx-auto max-w-5xl"}
          paddingX="px-6"
        >
          <p className="text-center py-12">There were problems finding approval submission information, please contact support.</p>
          <ApprovalContactFooter testId="footer" />
        </WhiteCard>
      </main>
    );
  }

  return (
    <main data-testid="approval-confirmation">
      <WhiteCard
        fullPageWidth={isScreenSizeBelow(appState.windowSize.screen, "md")}
        borderRadius="large"
        className={"lg:w-[55%] mx-auto max-w-5xl"}
        paddingX="px-6"
      >
        <div className="flex flex-col items-center">
          <img src={exclamationImg} alt="Request revision" className="w-1/3 my-5" />
        </div>
        <ApprovalRevisionForm token={token} submitterName={`${latestSubmission?.firstName}`} onFormSubmitted={onFormSubmitted} />
        { (submissionHistory.length > 0 && orgNames) &&
          <ApprovalSubmissionHistory
            submissions={submissionHistory}
            token={token}
            fundraiserName={orgNames.fundraiserName}
            organizationName={orgNames.organizationName}
            programName={orgNames.programName}
            className="mb-5"
          />
        }
        { (latestSubmission && orgNames) &&
          <ApprovalDetailViewer
            submission={latestSubmission}
            token={token}
            fundraiserName={orgNames.fundraiserName}
            organizationName={orgNames.organizationName}
            programName={orgNames.programName}
          />
        }
        <ApprovalContactFooter
          testId="footer"
          className="mt-5"
        />
      </WhiteCard>
    </main>
  );
};

export default ApprovalRequestRevision;
