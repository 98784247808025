import { FILE_VISUALIZER_MODE, FileVisualizerMode } from "../approvals/constants";
import { SnapIcon } from "../suit";
import { FILE_STATUS } from "../utils/upload-file";

interface FileControlProps {
  testId: string;
  fileName: string;
  onFileDelete?: (fileName: string, status: FILE_STATUS) => void;
  className?: string;
  progress?: number;
  status: FILE_STATUS;
  location: string;
  mode?: FileVisualizerMode;
}
const FileControl: React.FC<FileControlProps> = ({ testId, fileName, onFileDelete = () => { }, className = "", progress, status, location, mode = FILE_VISUALIZER_MODE.edit }) => {
  const statusClass = status === FILE_STATUS.error ? "border-red-600" : "border-gray-200";
  const progressClass = (status === FILE_STATUS.inProgress) ? `${progress || 0 + 1}%` : "100%";
  return (
    <div
      data-testid={testId}
      className={`flex w-full relative justify-between ${mode === FILE_VISUALIZER_MODE.edit ? "border" : ""} py-1 px-2 rounded-lg mb-2 ${className} ${statusClass}`}
      role="document"
    >
      {status !== FILE_STATUS.error && mode === FILE_VISUALIZER_MODE.edit ? <div className="absolute top-0 left-0 opacity-35 bg-blue-300 rounded-lg  h-[28px]" style={{ width: progressClass }}></div> : null}
      {
        location ? (
          <a href={location} className=" relative h-[22pxf] flex flex-row items-center">
            { mode === FILE_VISUALIZER_MODE.visualize ?
              <SnapIcon icon="document-text-solid" size="sm" className="cursor-pointer text-blue-600" />
            :
              null
            }
            <span className={`flex-1 text-sm overflow-hidden text-ellipsis ${mode === FILE_VISUALIZER_MODE.edit ? "text-gray-800 font-normal whitespace-nowrap" : " text-blue-600 font-bold"}`}>{fileName}</span>
          </a>
        ) :
          (
            <span className="flex-1 text-sm font-normal text-gray-800 whitespace-nowrap overflow-hidden text-ellipsis h-[23px]">{fileName}</span>
          )
      }
      { mode === FILE_VISUALIZER_MODE.edit ?
        <div className="flex">
          <SnapIcon icon="trash-solid" size="sm" className="cursor-pointer text-gray-500" onClick={() => { onFileDelete(fileName, status) }} />
        </div>
      :
        null
      }
    </div>
  );
}

export default FileControl;
