import React, { useContext } from 'react';
import { SnapMainNavigation } from '../suit';
import { MainNavigationUpdatedEvent } from '../suit/components';
import { useSideNavigation } from '../hooks/side-navigation';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../AppGlobalState';

const InsightsNavigation: React.FC = () => {
  const {appState} = useContext(AppContext);
  const sideNavOptions = useSideNavigation();
  const navigate = useNavigate();
  
  return (
    <SnapMainNavigation
      onNavigationupdate={(event: CustomEvent<MainNavigationUpdatedEvent>) => !!event.detail.path && navigate(event.detail.path)}
      data-testid="snap-main-navigation"
      theme="insights"
      navigationOptionsInput={sideNavOptions} 
      impersonationMode={appState.impersonateMode}
    />
  );
};

export default InsightsNavigation;