import { useContext, useEffect, useState } from "react";
import {
  FundraiserApprover,
  useCampaignOrgIdLazyQuery,
  useFundraiserOrgAndGroupNamesLazyQuery,
  useOrgsAndCampaignNameByCampaignIdLazyQuery,
} from "../graphql/generated";
import {
  findAssociatedOrgs,
  shouldFetchForOrgDetails,
} from "./snap-fundraiser-details-data-helper";
import { ApprovalsContext } from "../ApprovalsGlobalState";
import {
  mergeApproverProps,
  processQueryFetchFundraiserOrgAndGroupNames,
  updateDraft,
} from "./approvals-data-helpers";
import { FORM_STEPS } from "./constants";
import notEmpty from "../utils/not-empty";
import OpenText from "./open-text";
import { validReason } from "./validation-helpers";
import useDebouncedCallback from "../hooks/use-debounced-callback";
import FundraiserLinks from "./fundraiser-links";
import Approvers from "./approvers";
import ContractsAndDocuments from "./contracts-and-documents";
import ApprovalFormActions from "./approval-form-actions";
import ReadonlyFormValue from "../layout/readonly-form-value";
import SnapFundraiserDetailsSkeleton from "./snap-fundraiser-details-skeleton";
import { dateMMDDYYYY } from "../utils/RenderDataUtils";

const SnapFundraiserDetailsForm = () => {
  const { appState, dispatch } = useContext(ApprovalsContext);
  const { editing, fundraiserApprovalForm: fundraiserApprovalFormState } = appState;
  const {

    formStep,
    editableSubmission,
    editableSubmissionOrgName,
    editableSubmissionProgramName,
  } = fundraiserApprovalFormState;
  const [reasonError, setReasonError] = useState<string>("");
  const [campaignName, setCampaignName] = useState<string>("");
  const [
    fetchCampaignOrgId,
    { data: campaignOrgId, loading: campaignOrgIdLoading },
  ] = useCampaignOrgIdLazyQuery();

  const [
    fetchFundraiserOrgAndGroupNames,
    { loading: orgAndProgramNameLoading },
  ] = useFundraiserOrgAndGroupNamesLazyQuery();

  const [
    fetchOrgsByCampaignId,
    { data: fundraiserOrgs, loading: fundraiserOrgsLoading },
  ] = useOrgsAndCampaignNameByCampaignIdLazyQuery();

  useEffect(() => {
    if (
      !editableSubmission.newFundraiser &&
      editableSubmission.campaignRaiseId
    ) {
      fetchCampaignOrgId({
        variables: {
          raiseId: editableSubmission.campaignRaiseId,
        },
      });
    }
  }, [
    campaignOrgId,
    editableSubmission.campaignRaiseId,
    editableSubmission.newFundraiser,
    fetchCampaignOrgId,
  ]);

  useEffect(() => {
    if (campaignOrgId && campaignOrgId.orgId) {
      fetchOrgsByCampaignId({
        variables: {
          campaignId: campaignOrgId.orgId,
        },
      });
    }
  }, [campaignOrgId, fetchOrgsByCampaignId]);

  useEffect(() => {
    if (
      fundraiserOrgs &&
      fundraiserOrgs.orgCampaignId &&
      shouldFetchForOrgDetails(editableSubmission, formStep)
    ) {
      const validOrgs = fundraiserOrgs.orgCampaignId.filter(notEmpty);
      const [program, parentOgr] = findAssociatedOrgs(validOrgs);
      if (parentOgr && program) {
        dispatch({
          type: "FUNDRAISER_EDITABLE_APPROVAL_SUBMISSION_UPDATED",
          payload: {
            fields: {
              organizationId: parentOgr.id,
              programId: program.id,
            },
            formStep: FORM_STEPS.formDetails,
          },
        });
      }
    }
    if (fundraiserOrgs && fundraiserOrgs.org) {
      setCampaignName(fundraiserOrgs.org[0]?.name || "")
    }
  }, [
    campaignOrgId,
    dispatch,
    fundraiserOrgs,
    editableSubmission.organizationId,
    editableSubmission.formType,
    formStep,
    editableSubmission,
  ]);

  useEffect(() => {
    if (editableSubmission.organizationId && editableSubmission.programId) {
      fetchFundraiserOrgAndGroupNames({
        variables: {
          orgId: editableSubmission.organizationId,
          programId: editableSubmission.programId,
        },
      }).then((response) => {
        const { orgName, programName } =
          processQueryFetchFundraiserOrgAndGroupNames(response);
        dispatch({
          type: "FUNDRAISER_FORM_ORG_SELECTED",
          payload: {
            orgName,
            programName,
          },
        });
      });
    }
  }, [
    dispatch,
    editableSubmission.organizationId,
    editableSubmission.programId,
    fetchFundraiserOrgAndGroupNames,
  ]);

  const fundraiserReasonOnBlur = (value: string) => {
    const error = validReason(value);
    setReasonError(error);
    if (!error) {
      updateDraft("reason", value, dispatch);
    }
  };

  const reasonOnInput = (value: string) => {
    setReasonError(validReason(value));
  };
  const debouncedReasonOnInput = useDebouncedCallback(reasonOnInput, 100);

  const approverChanged = (approver: FundraiserApprover) => {
    const approverUpdated = mergeApproverProps(editableSubmission.fundraiserApprovers, approver);
    updateDraft("fundraiserApprovers", [approverUpdated], dispatch);
  };

  if (campaignOrgIdLoading || fundraiserOrgsLoading || orgAndProgramNameLoading)
    return <SnapFundraiserDetailsSkeleton />;

  return (
    <div>
      <p className=" text-lg font-semibold  pb-5">Fundraiser Details</p>
      <div className="flex flex-col gap-5 font-medium text-gray-500 text-sm pb-3">
        {editing ?
          <>
            <ReadonlyFormValue testId={"raise-name"} label={"Snap! Raise Fundraiser Name"} value={campaignName} forceTwoLines />
            <ReadonlyFormValue testId={"form-type-name"} label={"Fundraiser Type"} value={editableSubmission.formType || ""} />
          </>
          :
          null
        }
        <ReadonlyFormValue testId={"approval-first-name"} label={"Your First Name"} value={editableSubmission.firstName || ""} />
        <ReadonlyFormValue testId={"approval-Last-name"} label={"Your Last Name"} value={editableSubmission.lastName || ""} />
        <ReadonlyFormValue testId={"approval-organization"} label={"Organization"} value={editableSubmissionOrgName || ""} />
        <ReadonlyFormValue testId={"approval-program"} label={"Program"} value={editableSubmissionProgramName || ""} />
        <ReadonlyFormValue testId={"approval-group-size"} label={"Group Size"} value={`${editableSubmission.groupSize}`} />
        <ReadonlyFormValue testId={"approval-estimated-raise"} label={"Estimated Raise"} value={`${editableSubmission.estimatedTotalRaisedCents}`} />
        <ReadonlyFormValue testId={"approval-prices-fees"} label={"Snap! Raise Pricing and Fees"} value={editableSubmission.pricingAndFees || ""} forceTwoLines />
        <ReadonlyFormValue testId={"approval-how-snap-works"} label={"How Snap! Raise Works"} value={editableSubmission.howThisWorks || ""} forceTwoLines />
        <OpenText
          errorMessage={reasonError}
          fieldId={"reason"}
          testId={"test-reason"}
          label={"Reason for Fundraiser "}
          value={editableSubmission.reason || ""}
          onBlur={(reason) => fundraiserReasonOnBlur(reason)}
          onInput={(reason) => debouncedReasonOnInput(reason)}
          required
          displayCharCount
        />
        <ReadonlyFormValue testId={"approval-proposed-start-date"} label={"Proposed Start Date"} value={dateMMDDYYYY(new Date(editableSubmission.proposedStartDate))} />
        <ReadonlyFormValue testId={"approval-fundraiser-duration"} label={"Fundraiser Duration"} value={editableSubmission.duration || ""} />
        <ReadonlyFormValue testId={"approval-services-website"} label={"Fundraiser Service's Website"} value={editableSubmission.fundraiserServiceWebsite || ""} />
        <ContractsAndDocuments
          testId="contracts-and-documents"
          className="pb-3"
        />
        <ReadonlyFormValue testId={"approval-safety-and-security-information"} label={"Safety and Security Information"} value={editableSubmission.safety || ""} />
        <ReadonlyFormValue testId={"approval-support-information"} label={"Customer Support Information"} value={editableSubmission.customerSupportInfo || ""} />
        <OpenText
          className="pb-3"
          fieldId={"additional-notes"}
          testId={"additional-notes"}
          label={"Additional Notes"}
          value={editableSubmission.additionalNotes || ""}
          onBlur={(additionalNotes) => {
            updateDraft("additionalNotes", additionalNotes, dispatch);
          }}
        />
        <Approvers
          locked={editing}
          testId="approvers"
          onApproverChange={approverChanged}
        />
        {editableSubmission.campaignRaiseId ?
          <FundraiserLinks
            testId="fundraiser-page-link"
            fundraiserId={editableSubmission.campaignRaiseId}
            className="pb-3"
          />
          :
          null
        }
        <ApprovalFormActions
          testId="form-actions"
          className="py-4"
        />
      </div>
    </div >
  );
};

export default SnapFundraiserDetailsForm;
