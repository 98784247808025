import { useState } from "react";
import { SnapIcon } from "../suit";
import { FundraiserApprovalSubmission } from "../graphql/generated";
import ApprovalDetailViewer from "./approval-detail-viewer";

interface ApprovalSubmissionHistoryProps {
  submissions: FundraiserApprovalSubmission[];
  token: string;
  fundraiserName: string;
  organizationName: string;
  programName: string;
  className?: string;
}

const ApprovalSubmissionHistory: React.FC<ApprovalSubmissionHistoryProps>  = ({submissions, token, fundraiserName, organizationName, programName, className = ""}) => {
  const[open, setOpen] = useState<boolean>(false);

  return (
    <div data-testid="submission-history" className={`border-gray-200 border rounded-lg p-3 ${className}`}>
      <div className="flex flex-row justify-between items-center">
        <h3>Fundraiser History</h3>
        <SnapIcon
          icon={`${open ? "chevron-up-line" : "chevron-down-line"}`}
          className="py-1 px-1 border-gray-200 cursor-pointer"
          style={{
            borderRadius: "50%",
            borderOutline: "#e5e7eb",
            border: "1px solid #e5e7eb",
          }}
          size="md"
          onClick={() => { setOpen(!open) }}
        />
      </div>
      { open ?
        <>
          {submissions.map((submission, index) => <ApprovalDetailViewer key={index} submission={submission} token={token} fundraiserName={fundraiserName} organizationName={organizationName} programName={programName} showSubmittedDate showDivider={false} />)}
        </>
        :
        null
      }
    </div>
  );
};

export default ApprovalSubmissionHistory;
