import * as Sentry from '@sentry/react';
import { useRef } from "react";

const useSentry = (userId?: string, requireSetUser:boolean = false): void => {
  const sentryHasInitialized = useRef(false);
  const sampleRate:number = process.env.REACT_APP_ENVIRONMENT === 'prod' ? 0.05 : 1.0;

  if(requireSetUser && !userId){
    return;
  }

  if (sentryHasInitialized.current === false) {
    sentryHasInitialized.current = true;
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [Sentry.browserTracingIntegration()],
      tracesSampleRate: sampleRate,
      enabled: process.env.REACT_APP_ENVIRONMENT !== 'local',
      environment: process.env.REACT_APP_ENVIRONMENT,
      ignoreErrors: [],
    });
    Sentry.setUser({
      username: userId,
    });
    Sentry.setTag('project', 'insights');
  }
}

export default useSentry;
