import React, { useEffect, useState } from "react";
import { SnapIcon, SnapInput, SnapLink, SnapSelectMenu } from "../../../suit";
import {
  SnapInputCustomEvent,
  SnapSelectMenuCustomEvent,
  SnapSelectMenuOption,
} from "@snap-mobile/snap-ui/dist/types/components";
import { PreApprovedContactsActivityTypes } from "../../../constants/preApprovedContactsActivityTypes";
import { FormData } from "../../modals/PreApprovalsModal";
import { formatPhoneNumber } from "../../../utils/RenderDataUtils";

interface FormProps {
  key: number;
  className: string;
  formData: FormData;
  onChange: (
    event: SnapInputCustomEvent<any> | SnapSelectMenuCustomEvent<any>
  ) => void;
  onDelete: () => void;
  forms: FormData[];
  bottomRef: React.RefObject<HTMLDivElement>;
  index: number;
}

const ContactForm: React.FC<FormProps> = ({
  className,
  formData,
  onChange,
  onDelete,
  forms,
  bottomRef,
  index,
}) => {
  let { firstName, lastName, email, activity, phoneNumber, errors } = formData;
  const [activities, setActivities] = useState<SnapSelectMenuOption[]>(
    Object.values(PreApprovedContactsActivityTypes).map((activity) => {
      return { name: activity.name, value: activity.value, selected: false };
    })
  );

  const defaultActivity = activities.find(
    (option) => option.value === activity
  );

  const handleDelete = () => {
    firstName = "";
    lastName = "";
    email = "";
    activity = "";
    phoneNumber= ""; 
    setActivities(
      [...activities].map((activity) => ({ ...activity, selected: false }))
    );
    onDelete();
  };

  const handleMenuUpdate = (e: any) => {
    setActivities(e.detail);
    onChange(e);
  };

  useEffect(() => {
    if (activity === "" && forms.length === 1) {
      handleDelete();
    }
  }, [activity]); //eslint-disable-line

  return (
    <div className={className + " py-4 px-6"}>
      <div className="w-full flex flex-row justify-end">
        <SnapIcon icon="trash-solid" color="red" className="pr-1" onClick={handleDelete}/>
        <SnapLink variant="danger" size="sm" onClick={handleDelete}>
          Delete Contact
        </SnapLink>
      </div>
      <form>
        <div className={`grid md:grid-cols-3 md:gap-8 gap-4`}>
          <SnapInput
            error={!!errors.firstName}
            error-icon={!!errors.firstName}
            helpText={errors.firstName}
            _id="input-first-name"
            name="firstName"
            value={firstName}
            onSnap-input-change={onChange}
            label="First Name *"
            />
          <SnapInput
            error={!!errors.lastName}
            error-icon={!!errors.lastName}
            helpText={errors.lastName}
            _id="input-last-name"
            name="lastName"
            value={lastName}
            onSnap-input-change={onChange}
            label="Last Name *"
            />
          <SnapInput
            error={!!errors.phoneNumber}
            error-icon={!!errors.phoneNumber}
            helpText={errors.phoneNumber}
            _id="input-phone-number"
            _type="tel"
            name="phoneNumber"
            maxlength="15"
            label="Phone Number"
            placeholder="(xxx) xxx-xxxx"
            value={phoneNumber? formatPhoneNumber(phoneNumber) : ""}
            onSnap-input-change={onChange}
          /> 
        </div>
        <div className="grid md:grid-cols-2 md:gap-8 gap-4 pt-5">
          <SnapInput
            error={!!errors.email}
            error-icon={!!errors.email}
            helpText={errors.email}
            _id="input-email"
            name="email"
            value={email}
            onSnap-input-change={onChange}
            label="Email *"
            />
          <SnapSelectMenu
            //relative  uncomment on dropdown update
            onClick={() =>
              index === forms.length - 1 &&
              setTimeout(() => bottomRef?.current?.scrollIntoView(), 0)
            }
            error={!!errors.activity}
            helpText={errors.activity}
            id="input-activity"
            modalTitle="Activity"
            selectMenuOptions={activities}
            onSnap-select-menu-updated={(e) => handleMenuUpdate(e)}
            label="Activity *"
            placeholder={defaultActivity?.name}
            search
            />
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
