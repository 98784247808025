import { useContext, useMemo } from "react";
import WhiteCard from "../components/WhiteCard";
import { ApprovalsContext } from "../ApprovalsGlobalState";
import { isScreenSizeBelow } from "../utils/UIUtils";
import successfulImg from '../assets/Successful.png';
import { SnapButton } from "../suit";
import { getRaiseHomePageUrl } from "../utils/RouteUtils";
import ApprovalContactFooter from "./approval-contact-footer";
import { getApproverName } from "./approvals-data-helpers";


const ApprovalConfirmation = () => {
  const { appState } = useContext(ApprovalsContext);
  const { editing, fundraiserApprovalForm: { editableSubmission } } = appState;
  const { fundraiserApprovers } = editableSubmission;
  const approverName: string = useMemo(
    () => {
      const approver = fundraiserApprovers[0];
      if (approver && approver.firstName && approver.lastName) {
        return getApproverName(approver);
      }
      return "";
    },
    [fundraiserApprovers]
  );

  const title = editing ? "Fundraiser Has Been Revised" :
    "Fundraiser has been sent";
  const body = editing ? `We'll email ${approverName} with the revisions. When they respond we’ll email you back.` :
    `We'll email ${approverName} with the details of your fundraiser. When they respond to your request we'll email you back.`

  return (
    <main data-testid="approval-confirmation">
      <WhiteCard
        fullPageWidth={isScreenSizeBelow(appState.windowSize.screen, "md")}
        borderRadius="large"
        className={"lg:w-[55%] mx-auto max-w-5xl"}
        paddingX="px-6"
      >
        <div className="flex flex-col items-center">
          <img src={successfulImg} alt={title} className="w-1/3 my-5" />
          <h2 className="text-center text-2xl text-gray-800 font-semibold mb-2">{title}</h2>
          <p className="text-center text-base text-gray-800 font-medium">{body}</p>
          <div className="w-full flex flex-col sm:flex-row items-center sm:justify-center my-5">
            <SnapButton
              href={getRaiseHomePageUrl()}
              fullWidth
              variant="primary"
              className="w-1/2 sm:w-1/3 mb-4 sm:mb-0 sm:mr-4"
            >
              Go to Snap! Raise
            </SnapButton>
            <SnapButton
              href="/"
              fullWidth
              className="w-1/2 sm:w-1/3"
            >
              Close Window
            </SnapButton>
          </div>
        </div>
        <ApprovalContactFooter testId="footer" />
      </WhiteCard>
    </main>
  );
};

export default ApprovalConfirmation;
