import React, { createContext, useEffect, useReducer } from "react";
import { useWindowSize, WindowSize } from "./hooks/useWindowSize";
import { FundraiserApprovalSubmission } from "./graphql/generated";
import { DEFAULT_SUBMISSION_PROPERTIES, PAGE_ERRORS } from "./approvals/constants";
import { emptySubmission } from "./approvals/approvals-data-helpers";



export interface FundraiserApprovalForm {
  editableSubmission: FundraiserApprovalSubmission;
  editableSubmissionOrgName: string;
  editableSubmissionProgramName: string;
  formStep: number;
  loading: boolean;
  resetting: boolean;
  docUploading: boolean;
  requiresApiSync: boolean;
}
export interface FundraiserApprovalRevisionConfirmation {
  submitterName: string;
  isSsoUser: boolean;
}
export interface ApprovalsState {
  windowSize: { screen: string };
  impersonateMode: boolean;
  pageError: PAGE_ERRORS | null;
  currentSubmission: FundraiserApprovalSubmission | null;
  editing: boolean;
  fundraiserApprovalForm: FundraiserApprovalForm
  revisionConfirmation: FundraiserApprovalRevisionConfirmation
}

export interface ReducerAction {
  type: string;
  payload: any;
}

export const initialState: ApprovalsState = {
  windowSize: {
    screen: "xs",
  },
  impersonateMode: false,
  currentSubmission: null,
  pageError: null,
  editing: false,
  fundraiserApprovalForm: {
    editableSubmissionOrgName: "",
    editableSubmissionProgramName: "",
    formStep: 0,
    loading: false,
    resetting: false,
    docUploading: false,
    editableSubmission: emptySubmission(),
    requiresApiSync: true
  },
  revisionConfirmation: {
    submitterName: "",
    isSsoUser: false,
  }
};

export const ApprovalsContext = createContext<{
  appState: ApprovalsState;
  dispatch: React.Dispatch<ReducerAction>;
}>({ appState: initialState, dispatch: () => null });

interface AppGlobalStateProps {
  children: React.ReactNode;
}

export const ApprovalsReducer = (state: ApprovalsState, action: ReducerAction) => {
  const { payload } = action
  switch (action.type) {
    case "UPDATE_WINDOW_SIZE":
      return { ...state, windowSize: action.payload };
    case "SET_IMPERSONATE_MODE":
      return { ...state, impersonateMode: action.payload.mode };
    case "FUNDRAISER_APPROVALS_LOADING_CHANGE":
      return { ...state, fundraiserApprovalForm: { ...state.fundraiserApprovalForm, loading: payload.loading } };
    case "FUNDRAISER_APPROVALS_SUBMISSION_INVALID_ACCESS":
      return { ...state, pageError: payload.error };
    case "FUNDRAISER_CURRENT_APPROVAL_SUBMISSION_UPDATED":
      return { ...state, editing: !!payload.submission.previousRevision, currentSubmission: { ...state.currentSubmission, ...payload.submission } };
    case "FUNDRAISER_EDITABLE_APPROVAL_SUBMISSION_UPDATED":
      return {
        ...state,
        fundraiserApprovalForm: {
          ...state.fundraiserApprovalForm,
          requiresApiSync: true,
          editableSubmission: { ...state.fundraiserApprovalForm.editableSubmission, ...payload.fields },
          formStep: payload.formStep || state.fundraiserApprovalForm.formStep
        }
      };
    case "FUNDRAISER_EDITABLE_APPROVAL_SUBMISSION_SYNCED":
      return {
        ...state,
        fundraiserApprovalForm: {
          ...state.fundraiserApprovalForm,
          requiresApiSync: false,
          editableSubmission: { ...state.fundraiserApprovalForm.editableSubmission, ...payload.fields },
          formStep: payload.formStep || state.fundraiserApprovalForm.formStep,
          resetting: false
        }
      };
    case "FUNDRAISER_FORM_ORG_SELECTED":
      return {
        ...state,
        fundraiserApprovalForm: {
          ...state.fundraiserApprovalForm,
          editableSubmissionOrgName: payload.orgName,
          editableSubmissionProgramName: payload.programName
        }
      };
    case "FUNDRAISER_EDITABLE_APPROVAL_SUBMISSION_RESET":
      return {
        ...state,
        fundraiserApprovalForm: {
          ...state.fundraiserApprovalForm,
          requiresApiSync: true,
          editableSubmission: {
            id: state.fundraiserApprovalForm.editableSubmission.id,
            formId: state.fundraiserApprovalForm.editableSubmission.formId,
            firstName: state.fundraiserApprovalForm.editableSubmission.firstName,
            lastName: state.fundraiserApprovalForm.editableSubmission.lastName,
            ...DEFAULT_SUBMISSION_PROPERTIES,
            fundraiserApprovers: state.fundraiserApprovalForm.editableSubmission.fundraiserApprovers,
          },
          formStep: payload.formStep,
          resetting: true,
        }
      };
    case "SET_REVISION_CONFIRMATION_PROPS":
      return { ...state, revisionConfirmation: action.payload };
    default:
      return state;
  }
};

const ApprovalsGlobalState: React.FC<AppGlobalStateProps> = ({ children }) => {
  const [appState, dispatch] = useReducer(ApprovalsReducer, initialState);

  const windowSize: WindowSize = useWindowSize();

  useEffect(() => {
    if (appState.windowSize.screen !== windowSize.screen) {
      dispatch({
        type: "UPDATE_WINDOW_SIZE",
        payload: { screen: windowSize.screen || "" },
      });
    }
  }, [windowSize, appState]);

  return (
    <ApprovalsContext.Provider value={{ appState, dispatch }}>
      {children}
    </ApprovalsContext.Provider>
  );
};

export default ApprovalsGlobalState;
