import { SnapDivider, SnapDividerLabel, SnapLink } from "../suit";
import ReadonlyFormValue from "../layout/readonly-form-value";
import { FundraiserApprovalSubmission } from "../graphql/generated";
import { centsToDollars, formatAmount } from "../utils/CurrencyUtils";
import { dateMMDDYYYY } from "../utils/RenderDataUtils";
import { getFundraiserPageUrl } from "../utils/RouteUtils";
import ViewDocumentsLink from "./view-documents-link";
import FileListVisualizer from "../layout/file-list-visualizer";
import notEmpty from "../utils/not-empty";
import { FILE_VISUALIZER_MODE } from "./constants";

interface ApprovalDetailViewerProps {
  submission: FundraiserApprovalSubmission;
  token: string;
  fundraiserName: string;
  organizationName: string;
  programName: string;
  showSubmittedDate?: boolean;
  showDivider?: boolean;
  showAccessToDocuments?: boolean;
  className?: string;
}

const ApprovalDetailViewer: React.FC<ApprovalDetailViewerProps>  = ({submission, token, fundraiserName, organizationName, programName, showSubmittedDate = false, showDivider = true, showAccessToDocuments = false, className = ""}) => {
  const fundraiserPageUrl = submission.campaignRaiseId ? getFundraiserPageUrl(`${submission.campaignRaiseId}`) : "#";
  const documentsCount = submission.fundraiserApprovalSubmissionDocs.length;
  const estimatedTotalRaised = submission.estimatedTotalRaisedCents ? formatAmount(centsToDollars(submission.estimatedTotalRaisedCents)) : "";

  return (
    <div className={`${className}`}>
      <div className="mb-5">
        { showSubmittedDate ?
          <SnapDivider>
            <SnapDividerLabel>Submitted: {dateMMDDYYYY(new Date(submission.updatedAt)) }</SnapDividerLabel>
          </SnapDivider>
        :
        <h3 className="text-lg text-gray-800 font-semibold mb-3">Fundraiser Details</h3>
        }
        <div className="flex flex-col gap-5">
          <ReadonlyFormValue
            testId="fundraiser-name"
            label="Snap! Raise Fundraiser Name"
            value={fundraiserName}
            forceTwoLines
          />
          <ReadonlyFormValue
            testId="fundraiser-type"
            label="Fundraiser Type"
            value={`${submission.formType}`}
          />
          <ReadonlyFormValue
            testId="organization"
            label="Organization"
            value={organizationName}
          />
          <ReadonlyFormValue
            testId="program"
            label="Program"
            value={programName}
          />
          <ReadonlyFormValue
            testId="group-size"
            label="Group Size"
            value={`${submission.groupSize}`}
          />
          <ReadonlyFormValue
            testId="estimated-raise"
            label="Estimated Raise"
            value={estimatedTotalRaised}
          />
          <ReadonlyFormValue
            testId="reason"
            label="Reason For Fundraiser"
            value={`${submission.reason}`}
            forceTwoLines
          />
          <ReadonlyFormValue
            testId="notes"
            label="Additional Notes"
            value={`${submission.additionalNotes}`}
            forceTwoLines
          />
          <ReadonlyFormValue
            testId="pricing-fees"
            label="Snap! Raise Pricing and Fees"
            value={`${submission.pricingAndFees}`}
            forceTwoLines
          />
          <ReadonlyFormValue
            testId="how-works"
            label="How Snap! Raise Works"
            value={`${submission.howThisWorks}`}
            forceTwoLines
          />
          <ReadonlyFormValue
            testId="proposed-start"
            label="Proposed Start Date"
            value={dateMMDDYYYY(new Date(submission.proposedStartDate)) || ""}
          />
          <ReadonlyFormValue
            testId="duration"
            label="Fundraiser Duration"
            value={`${submission.duration}`}
          />
          <ReadonlyFormValue
            testId="website"
            label="Fundraiser Service's Website"
            value={`${submission.fundraiserServiceWebsite}`}
            forceTwoLines
          />
          { documentsCount > 0 ?
            <>
              { showAccessToDocuments ?
                <>
                  <p className={`text-sm text-gray-500 font-medium`}>Contract & Documents</p>
                  <FileListVisualizer
                    testId="documents"
                    files={[]}
                    fileStatusMap={{}}
                    existingFiles={submission.fundraiserApprovalSubmissionDocs.filter(notEmpty)}
                    mode={FILE_VISUALIZER_MODE.visualize}
                  />
                </>
              :
                <ViewDocumentsLink
                  testId={"documents-link"}
                  formId={submission.formId}
                  submissionId={submission.id}
                  submitterId={`${submission.submitterId}`}
                  token={token}
                  documentsCount={documentsCount}
                />
              }
            </>
          :
            null
          }
          <ReadonlyFormValue
            testId="safety"
            label="Snap! Raise Safety and Security"
            value={`${submission.safety}`}
            forceTwoLines
          />
          <ReadonlyFormValue
            testId="support"
            label="Customer Support"
            value={`${submission.customerSupportInfo}`}
            forceTwoLines
          />
        </div>
        { submission.campaignRaiseId ?
          <>
            <h4 className="text-lg text-gray-800 font-semibold mb-3">Fundraiser Page</h4>
            <SnapLink
              text="View Fundraiser Page"
              href={fundraiserPageUrl}
              target="_blank"
              size="sm"
              iconPosition="right"
              icon="external-link-solid"
              data-testid="fundraiser-page-link"
            />
          </>
        :
          null
        }
      </div>
      { showDivider ?
        <SnapDivider>
          <SnapDividerLabel></SnapDividerLabel>
        </SnapDivider>
      :
        null
      }
    </div>
  );
};

export default ApprovalDetailViewer;
