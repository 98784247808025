import { useContext, useEffect, useState } from "react";
import { ApprovalsContext } from "../ApprovalsGlobalState";
import ApprovalsUserName from "./approvals-user-name";
import OrgSearchSelector from "./org-search-selector";
import ProgramSearchSelector from "./program-search-selector";
import GroupSize from "./group-size";
import EstimatedTotalRaised from "./estimated-total-raised";
import { mergeApproverProps, proposedDateInitialValue, updateDraft } from "./approvals-data-helpers";
import {
  validEstimatedTotalRaised,
  validGroupSize,
  validProposedStartDate,
  validUrl,
} from "./validation-helpers";
import { centsToDollars } from "../utils/CurrencyUtils";
import OpenText from "./open-text";
import ProposedStartDate from "./proposed-start-date";
import Duration from "./duration";
import ServiceWebsite from "./service-website";
import Approvers from "./approvers";
import { FundraiserApprover, useSuperBasicUserInfoQuery } from "../graphql/generated";
import ContractsAndDocuments from "./contracts-and-documents";
import ApprovalFormActions from "./approval-form-actions";

const OtherFundraiserDetails = () => {
  const {
    appState: { editing, fundraiserApprovalForm: fundraiserApprovalFormState },
    dispatch,
  } = useContext(ApprovalsContext);
  const { editableSubmission, loading } = fundraiserApprovalFormState;
  const [groupSizeError, setGroupSizeError] = useState<string>("");
  const [estimatedTotalRaisedError, setEstimatedTotalRaisedError] =
    useState<string>("");
  const [proposedStartDateError, setProposedStartDateError] =
    useState<string>("");
  const [fundraiserDurationError, setFundraiserDurationError] = useState<string>("");
  const [websiteUrlError, setWebsiteUrlError] = useState<string>("");
  const proposedDateValue = proposedDateInitialValue(editableSubmission);
  const { data: currentUser } = useSuperBasicUserInfoQuery()


  useEffect(() => {
    if (!editableSubmission.firstName && (currentUser && currentUser.me)) {
      updateDraft("firstName", currentUser.me.firstName || "", dispatch);
      updateDraft("lastName", currentUser.me.lastName || "", dispatch);
    }
  }, [currentUser, dispatch, editableSubmission.firstName])

  const groupSizeOnInput = (value: string) => {
    if (!validGroupSize(value)) {
      setGroupSizeError(
        "Group size is required, value must be between 1 and 9,999"
      );
      return;
    }
    updateDraft("groupSize", parseInt(value, 10), dispatch);
    setGroupSizeError("");
  };

  const groupSizeChange = (value: string) => {
    if (!validGroupSize(value)) {
      setGroupSizeError(
        "Group size is required, value must be between 1 and 9,999"
      );
    }
  };

  const estimatedTotalRaisedOnInput = (value: string) => {
    if (!validEstimatedTotalRaised(value)) {
      setEstimatedTotalRaisedError(
        "Total is required, value must be between 1 and 999,999"
      );
      return;
    }
    updateDraft(
      "estimatedTotalRaisedCents",
      parseInt(value, 10) * 100,
      dispatch
    );
    setEstimatedTotalRaisedError("");
  };

  const estimatedTotalRaisedChange = (value: string) => {
    if (!validEstimatedTotalRaised(value)) {
      setEstimatedTotalRaisedError(
        "Total is required, value must be between 1 and 999,999"
      );
    }
  };

  const proposedStartDateOnValueChange = (value: string) => {
    const error = validProposedStartDate(value);
    setProposedStartDateError(error);
    if (error) {
      return;
    }
    const date = (new Date(value)).toISOString();
    updateDraft("proposedStartDate", date, dispatch);
  };

  const fundraiserDurationOnBlur = (value: string) => {
    if (value.trim().length === 0) {
      setFundraiserDurationError("Fundraiser duration is required");
      return;
    }
    updateDraft("duration", value.trim(), dispatch);
    setFundraiserDurationError("");
  };

  const websiteUrlOnBlur = (value: string) => {
    const trimmedValue = value.trim();
    const error = validUrl(trimmedValue);
    setWebsiteUrlError(error);
    if (error) {
      return;
    }
    updateDraft("fundraiserServiceWebsite", value, dispatch);
  }

  const approverChanged = (approver: FundraiserApprover) => {
    const approverUpdated = mergeApproverProps(editableSubmission.fundraiserApprovers, approver);
    updateDraft("fundraiserApprovers", [approverUpdated], dispatch);
  };

  if (loading) return null;

  return (
    <div>
      <p className="text-lg font-semibold text-gray-800 pb-3">
        Fundraiser Details
      </p>
      <div className="flex flex-col sm:flex-row sm:pb-3">
        <ApprovalsUserName
          fieldId="first-name"
          semanticType="first"
          name={editableSubmission.firstName || ""}
          className="flex-1 sm:mr-4 mb-3 sm:mb-0"
        />
        <ApprovalsUserName
          fieldId="last-name"
          semanticType="last"
          name={editableSubmission.lastName || ""}
          className="flex-1 mb-3 sm:mb-0"
        />
      </div>
      <OrgSearchSelector
        fieldId="org-selector-input"
        testId="submission-org-selector"
        orgId={editableSubmission.organizationId || ""}
        error=""
        className="pb-3"
        onOrgSelected={(org) => {
          updateDraft("organizationId", org.id, dispatch);
        }}
      />
      <ProgramSearchSelector
        fieldId="program-activity"
        testId="program-activity"
        programId={editableSubmission.programId || ""}
        error=""
        className="pb-3"
        onProgramSelected={(key) => updateDraft("programId", key, dispatch)}
      />
      <div className="flex flex-col sm:flex-row sm:pb-3">
        <GroupSize
          testId="test-approval-group-size"
          fieldId="approval-group-size"
          className="flex-1 sm:mr-4 mb-3 sm:mb-0"
          value={`${editableSubmission.groupSize}` || ""}
          onInput={groupSizeOnInput}
          onValueChange={groupSizeChange}
          errorMessage={groupSizeError}
        />
        <EstimatedTotalRaised
          testId="estimated-total-raised"
          fieldId="estimated-total-raised"
          value={
            editableSubmission.estimatedTotalRaisedCents
              ? `${centsToDollars(
                editableSubmission.estimatedTotalRaisedCents
              )}`
              : ""
          }
          errorMessage={estimatedTotalRaisedError}
          onInput={estimatedTotalRaisedOnInput}
          onValueChange={estimatedTotalRaisedChange}
          className="flex-1 mb-3 sm:mb-0"
        />
      </div>
      <OpenText
        className="pb-3"
        fieldId={"pricingAndFees"}
        testId={"test-pricing-fees"}
        label={"Pricing and Fees"}
        value={editableSubmission.pricingAndFees || ""}
        onBlur={(pricingAndFees) => {
          updateDraft("pricingAndFees", pricingAndFees, dispatch);
        }}
      />
      <OpenText
        className="pb-3"
        fieldId={"howThisWorks"}
        testId={"test-howThisWorks"}
        label={"How This Fundraiser Service Works"}
        value={editableSubmission.howThisWorks || ""}
        onBlur={(howThisWorks) => {
          updateDraft("howThisWorks", howThisWorks, dispatch);
        }}
      />
      <OpenText
        className="pb-3"
        fieldId={"reason"}
        testId={"test-reason"}
        label={"Reason for Fundraiser "}
        value={editableSubmission.reason || ""}
        onBlur={(reason) => {
          updateDraft("reason", reason, dispatch);
        }}
      />
      <div className="flex flex-col sm:flex-row sm:pb-3">
        <ProposedStartDate
          testId="proposed-start-date"
          fieldId="proposed-start-date"
          className="flex-1 sm:mr-4 mb-3 sm:mb-0"
          value={proposedDateValue}
          onValueChange={proposedStartDateOnValueChange}
          errorMessage={proposedStartDateError}
        />
        <Duration
          testId="fundraiser-duration"
          fieldId="fundraiser-duration"
          value={editableSubmission.duration || ""}
          errorMessage={fundraiserDurationError}
          className="flex-1 mb-3 sm:mb-0"
          onBlur={(value) => {
            fundraiserDurationOnBlur(value);
          }}
        />
      </div>
      <ServiceWebsite
        className="pb-3"
        fieldId={"services-web-site"}
        testId={"services-web-site"}
        value={editableSubmission.fundraiserServiceWebsite || ""}
        errorMessage={websiteUrlError}
        onBlur={(value) => {
          websiteUrlOnBlur(value);
        }}
      />
      <ContractsAndDocuments
        testId="contracts-and-documents"
        className="pb-3"
      />
      <OpenText
        className="pb-3"
        fieldId={"safety-and-security"}
        testId={"test-safety-and-security"}
        label={"Safety and Security Information"}
        value={editableSubmission.safety || ""}
        onBlur={(safetyInfo) => {
          updateDraft("safety", safetyInfo, dispatch);
        }}
      />
      <OpenText
        className="pb-3"
        fieldId={"customer-support-info"}
        testId={"customer-support-info"}
        label={"Customer Support Information"}
        value={editableSubmission.customerSupportInfo || ""}
        onBlur={(customerSupportInfo) => {
          updateDraft("customerSupportInfo", customerSupportInfo, dispatch);
        }}
      />
      <OpenText
        className="pb-3"
        fieldId={"additional-notes"}
        testId={"additional-notes"}
        label={"Additional Notes"}
        value={editableSubmission.additionalNotes || ""}
        onBlur={(additionalNotes) => {
          updateDraft("additionalNotes", additionalNotes, dispatch);
        }}
      />
      <Approvers
        locked={editing}
        testId="approvers"
        onApproverChange={approverChanged}
      />
      <ApprovalFormActions
        testId="form-actions"
        className="py-4"
      />
    </div>
  );
};

export default OtherFundraiserDetails;
