// Container used for render nodes in a different part of the DOM
// Must provide an id

interface PortalOutletProps {
  id: string;
  className?: string;
}

const PortalOutlet: React.FC<PortalOutletProps> = ({
  id,
  className,
}) => {
  return (
    <div 
      data-testid={id}
      id={id}
      className={`${className}`}
    >
    </div>
  );
};

export default PortalOutlet;
