module.exports = {
  content: ["./src/**/*.{js,jsx,ts,tsx}", "./public/index.html"],
  theme: {
    screens: {
      xs: "0",
      sm: "576px",
      md: "768px",
      lg: "992px",
      xl: "1200px",
      "2xl": "1400px",
    },
    extend: {
      colors: {
        'brandblue': '#15339B',
      },
      backgroundImage: theme => ({
        'card-1': "url('assets/CardBg1.png')",
        'card-1-mobile': "url('assets/CardBg1Mobile.png')",
      })
    },
  },
  plugins: [],
};
