import React from "react";
import { SnapInput } from "../suit";

interface ProposedStartDateProps {
  fieldId: string;
  testId: string;
  value: string;
  errorMessage: string;
  onValueChange?: (value:string)=>void;
  onInput?: (value:string)=>void;
  className?: string;
}

const ProposedStartDate: React.FC<ProposedStartDateProps>  = ({fieldId, testId, value, errorMessage, onValueChange, onInput, className})=>{
  return (
    <div data-testid={testId} className={className}>
      <SnapInput
        _id={fieldId}
        _type="text"
        date
        label="Proposed Start Date"
        value={value}
        placeholder="MM/DD/YYYY"
        required={true}
        error={!!errorMessage}
        helpText={errorMessage}
        errorIcon={true}
        name={"proposed-start-date"}
        onInput={(e) => { onInput && onInput((new Date((e.target as HTMLInputElement).value)).toISOString()) }}
        onSnap-input-change={(e) => { onValueChange && onValueChange((e.detail.target as unknown as HTMLInputElement).value) }}
        />
    </div>
  )
};

export default ProposedStartDate;
