import { useContext } from "react";
import { ApprovalsContext, ReducerAction } from "../ApprovalsGlobalState";
import { FundraiserApprovalSubmission, FundraiserApprovalSubmissionDocument, FundraiserApprover, useOrgQuery } from "../graphql/generated";
import ReadonlyFormValue from "../layout/readonly-form-value";
import ApprovalFormActions from "./approval-form-actions";
import Approvers from "./approvers";
import ContractsAndDocuments from "./contracts-and-documents";
import OpenText from "./open-text";
import ProposedStartDate from "./proposed-start-date";
import { proposedDateInitialValue } from "./approvals-data-helpers";
import SnapFundraiserDetailsSkeleton from "./snap-fundraiser-details-skeleton";

interface NewSnapFundraiserDetailsEditProps {
  editableSubmission: FundraiserApprovalSubmission;
  fundraiserReasonOnBlur: (value: string) => void;
  debouncedReasonOnInput: (value: string) => void;
  proposedStartDateOnValueChange: (value: string) => void;
  updateDraft: (field: string, value: string | number | FundraiserApprovalSubmissionDocument[] | FundraiserApprover[], dispatch: React.Dispatch<ReducerAction>) => void;
  proposedStartDateError: string;
  reasonError: string;
}

const NewSnapFundraiserDetailsEdit: React.FC<NewSnapFundraiserDetailsEditProps> = ({
  editableSubmission,
  fundraiserReasonOnBlur,
  debouncedReasonOnInput,
  proposedStartDateOnValueChange,
  updateDraft,
  proposedStartDateError,
  reasonError
}) => {
  const { dispatch } = useContext(ApprovalsContext);

  const proposedDateValue = proposedDateInitialValue(editableSubmission);

  const { data, loading } = useOrgQuery({ variables: { orgId: editableSubmission.organizationId || "", hierarchy: false } })
  const selectedOrg = data?.org?.flatMap(org => org?.__typename === "Org" ? org : null)

  if (loading)
    return <SnapFundraiserDetailsSkeleton />
  return (
    <div>
      <div className="flex flex-col gap-5 font-medium text-gray-500 text-sm pb-3">
        <ReadonlyFormValue testId={"raise-name"} label={"Snap! Raise Fundraiser Name"} value="New Snap! Fundraiser" forceTwoLines />
        <ReadonlyFormValue testId={"form-type-name"} label={"Fundraiser Type"} value={editableSubmission.formType || ""} />

        <ReadonlyFormValue testId={"approval-first-name"} label={"Your First Name"} value={editableSubmission.firstName || ""} />
        <ReadonlyFormValue testId={"approval-Last-name"} label={"Your Last Name"} value={editableSubmission.lastName || ""} />
        <ReadonlyFormValue testId={"approval-organization"} label={"Organization"} value={(selectedOrg && selectedOrg[0]?.name) || ""} />
        <ReadonlyFormValue testId={"approval-program"} label={"Program"} value={editableSubmission.programId || ""} />
        <ReadonlyFormValue testId={"approval-group-size"} label={"Group Size"} value={`${editableSubmission.groupSize}`} />
        <ReadonlyFormValue testId={"approval-estimated-raise"} label={"Estimated Raise"} value={`${editableSubmission.estimatedTotalRaisedCents}`} />

        <ReadonlyFormValue testId={"approval-prices-fees"} label={"Snap! Raise Pricing and Fees"} value={editableSubmission.pricingAndFees || ""} forceTwoLines />
        <ReadonlyFormValue testId={"approval-how-snap-works"} label={"How Snap! Raise Works"} value={editableSubmission.howThisWorks || ""} forceTwoLines />
        <OpenText
          errorMessage={reasonError}
          fieldId={"reason"}
          testId={"test-reason"}
          label={"Reason for Fundraiser "}
          value={editableSubmission.reason || ""}
          onBlur={(reason) => fundraiserReasonOnBlur(reason)}
          onInput={(reason) => debouncedReasonOnInput(reason)}
          required
          displayCharCount
        />
        <ProposedStartDate
          testId="proposed-start-date"
          fieldId="proposed-start-date"
          className="flex-1 sm:mr-4 mb-3 sm:mb-0"
          value={proposedDateValue}
          onValueChange={proposedStartDateOnValueChange}
          errorMessage={proposedStartDateError}
        />
        <ReadonlyFormValue testId={"approval-fundraiser-duration"} label={"Fundraiser Duration"} value={editableSubmission.duration || ""} />
        <ReadonlyFormValue testId={"approval-services-website"} label={"Fundraiser Service's Website"} value={editableSubmission.fundraiserServiceWebsite || ""} />
        <ContractsAndDocuments
          testId="contracts-and-documents"
          className="pb-3"
        />
        <ReadonlyFormValue testId={"approval-safety-and-security-information"} label={"Safety and Security Information"} value={editableSubmission.safety || ""} />
        <ReadonlyFormValue testId={"approval-support-information"} label={"Customer Support Information"} value={editableSubmission.customerSupportInfo || ""} />
        <OpenText
          className="pb-3"
          fieldId={"additional-notes"}
          testId={"additional-notes"}
          label={"Additional Notes"}
          value={editableSubmission.additionalNotes || ""}
          onBlur={(additionalNotes) => {
            updateDraft("additionalNotes", additionalNotes, dispatch);
          }}
        />
        <Approvers
          locked={true}
          testId="approvers"
          onApproverChange={() => null}
        />
        <ApprovalFormActions
          testId="form-actions"
          className="py-4"
        />
      </div>
    </div >
  );
}

export default NewSnapFundraiserDetailsEdit
