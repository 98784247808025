import React, { useContext, useState } from "react";
import { SnapButton } from "../suit";
import { ApprovalsContext } from "../ApprovalsGlobalState";
import { FORM_STEPS } from "./constants";
import ConfirmAction from "../layout/confirm-action";
import { useDeleteFundraiserApprovalSubmissionDocumentMutation, useSubmitFundraiserApprovalSubmissionMutation } from "../graphql/generated";
import { isFormSubmittable } from "./approvals-data-helpers";
import { useNavigate } from "react-router-dom";

interface ApprovalFormActionsProps {
  testId: string;
  className?: string;
}

const ApprovalFormActions: React.FC<ApprovalFormActionsProps>  = ({testId, className = ""})=>{
  const navigate = useNavigate();
  const [deleteS3File] = useDeleteFundraiserApprovalSubmissionDocumentMutation();
  const [submitApprovalForm] = useSubmitFundraiserApprovalSubmissionMutation();
  const { appState, dispatch } = useContext(ApprovalsContext);
  const { fundraiserApprovalForm } = appState;
  const { editableSubmission } = fundraiserApprovalForm;
  const[confirmResetOpen, setConfirmResetOpen] = useState<boolean>(false);
  const[confirmSubmitOpen, setConfirmSubmitOpen] = useState<boolean>(false);

  const resetForm = () => {
    setConfirmResetOpen(false);
    setTimeout(async () => {
      await removeFiles();
      dispatch({
        type: "FUNDRAISER_EDITABLE_APPROVAL_SUBMISSION_RESET",
        payload: { formStep: FORM_STEPS.initialStep },
      });
    }, 1);
  }

  const removeFiles = async () => {
    const deletePromises = editableSubmission.fundraiserApprovalSubmissionDocs.map((doc) => {
      return deleteS3File({
        variables: {
          documentKey: doc.s3Filename,
          approvalSubmissionId: editableSubmission.id
        }
      })
    })
    await Promise.allSettled(deletePromises);
  };

  const submitForm = async () => {
    setConfirmSubmitOpen(false);
    const {errors} = await submitApprovalForm({
      variables: {
        submitFundraiserApprovalSubmissionId: editableSubmission.id
      }
    });
    if (!errors || errors.length === 0) {
      navigate("/approvals/confirmation");
    }
  }

  return (
    <div data-testid={testId} className={`flex flex-row sm:justify-end ${className}`}>
      <SnapButton
        data-testid={`${testId}-reset`}
        onClick={() => setConfirmResetOpen(true)}
        fullWidth
        className="flex-1 sm:flex-initial sm:min-w-36 mr-2"
      >
        Reset Form
      </SnapButton>
      <SnapButton
        data-testid={`${testId}-submit`}
        disabled={!isFormSubmittable(fundraiserApprovalForm)}
        onClick={() => setConfirmSubmitOpen(true)}
        variant="primary"
        fullWidth
        className="flex-1 sm:flex-initial sm:min-w-36"
      >
        Submit
      </SnapButton>
      <ConfirmAction
        testId="confirm-reset"
        showConfirmation={confirmResetOpen}
        message="Are you sure you want to reset all fields?"
        action={resetForm}
        onConfirmationClose={() => setConfirmResetOpen(false)}
      />
      <ConfirmAction
        testId="confirm-submit"
        showConfirmation={confirmSubmitOpen}
        message="Submit this form? Information will be sent for approval"
        action={submitForm}
        onConfirmationClose={() => setConfirmSubmitOpen(false)}
      />
    </div>
  )
};

export default ApprovalFormActions;
