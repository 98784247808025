import { SnapSelectMenu } from "../suit";
import { useContext } from "react";
import { FORM_STEPS, FundraiserTypes } from "./constants";
import { SnapSelectMenuCustomEvent } from "@snap-mobile/snap-ui/dist/types/components";
import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import { ApprovalsContext } from "../ApprovalsGlobalState";
import { FundraiserApprovalSubmissionFormType, useDeleteFundraiserApprovalSubmissionDocumentMutation } from "../graphql/generated";

const FundraiserTypeSelector = () => {
  const [deleteS3File] = useDeleteFundraiserApprovalSubmissionDocumentMutation();
  const { appState, dispatch } = useContext(ApprovalsContext);
  const {
    editing,
    fundraiserApprovalForm: { editableSubmission },
  } = appState;
  const { formType } = editableSubmission;

  const fundraiserOptions = Object.values(
    FundraiserApprovalSubmissionFormType
  ).map((name, index) => ({
    name: FundraiserTypes[name],
    value: name,
    selected: name === formType,
  }));

  const onFundraiserSelectChange = async (e: SnapSelectMenuCustomEvent<any>) => {
    const selectedOptions = e.detail;
    const [fundraiserType] = selectedOptions.filter(
      (option: SnapSelectMenuOption) => option.selected === true
    );
    if (
      editableSubmission.formType === FundraiserApprovalSubmissionFormType.Snap ||
      fundraiserType.value === FundraiserApprovalSubmissionFormType.Snap
    ) {
      await resetFundraiserApprovalForm();
    }
    dispatch({
      type: "FUNDRAISER_EDITABLE_APPROVAL_SUBMISSION_UPDATED",
      payload: {
        fields: { formType: fundraiserType.value },
        formStep: FORM_STEPS.formTypeSelection,
      },
    });
  };

  const resetFundraiserApprovalForm = async () => {
    dispatch({
      type: "FUNDRAISER_EDITABLE_APPROVAL_SUBMISSION_RESET",
      payload: { formStep: FORM_STEPS.initialStep },
    });
    await removeFiles();
  };

  const removeFiles = async () => {
    const deletePromises = editableSubmission.fundraiserApprovalSubmissionDocs.map((doc) => {
      return deleteS3File({
        variables: {
          documentKey: doc.s3Filename,
          approvalSubmissionId: editableSubmission.id
        }
      })
    })
    await Promise.allSettled(deletePromises);
  };

  return (
    <>
      <p className=" pb-4  text-base">
        Use this form to submit approval requests for all your fundraisers.
        Select your Snap! Raise fundraiser or enter details for another
        fundraising service.
      </p>
      <p className=" pb-4 text-sm text-gray-500">
        <span className=" text-red-500 text-sm">*</span>Indicates a required
        field.
      </p>
      <SnapSelectMenu
        disabled={editing}
        error={false}
        helpText={""}
        id="i-fundraiser-type"
        modalTitle="Fundraiser Type"
        selectMenuOptions={fundraiserOptions}
        onSnap-select-menu-updated={onFundraiserSelectChange}
        label="Fundraiser Type"
      />
    </>
  );
};
export default FundraiserTypeSelector;
