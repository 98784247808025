import React from "react";
import NoDashboardsImg from "../../assets/NoDashboards.png";

export const NoDashboards: React.FC = () => {
  const snapSupportEmail = "support@snapraise.com";
  return (
    <div className="grid grid-cols-6 justify-items-center">
      <div className="flex col-span-6 items-center justify-center pt-20 pb-5 w-96 justify-items-center">
        <img src={NoDashboardsImg} alt="No results" className="w-full" />
      </div>
      <div className="col-span-6 grid grid-cols-6 items-center justify-center pb-20 w-full justify-items-center">
        <h3 className="col-span-6 text-center font-semibold text-lg py-4">
          No Dashboards to Show Yet
        </h3>
        <p className={`col-span-6 text-center text-gray-800 pb-[32px]`}>
          Once your organization has been added you'll be able to view analytics
          here.
        </p>
        <p className={`col-span-6 text-center text-gray-800 text-wrap`}>
          Feel like this is an error? Please email{" "}
          <a rel="noreferrer" target="_blank" href={`mailto:${snapSupportEmail}`} className="text-blue-600">
            support@snapraise.com
          </a>{" "}
          or call (276) 531-9260
        </p>
      </div>
    </div>
  );
};
