import React, { useContext } from 'react';
import { UserNotifications } from './UserNotifications';
import { OrganizationNotifications } from './OrganizationNotifications';
import { usePermissionsQuery, useInsightsNotificationPreferencesQuery, UserNotificationPreference, OrgNotificationPreference } from '../../graphql/generated';
import { LoadingData } from "../../components/loading-empty-state/LoadingData"
import { AppContext } from '../../AppGlobalState';
interface NotificationsProps {
  some?: string;
}


const Notifications: React.FC<NotificationsProps> = ({ some }) => {
  const {appState} = useContext(AppContext);
  const { data: user, loading: userLoading } = usePermissionsQuery();
  const {data, loading: loadingNotificationsPref, refetch} = useInsightsNotificationPreferencesQuery({variables:
    {
      userId: user?.me?.id || ''
    }})
  
  return (
    <>
    { !loadingNotificationsPref && !userLoading && !appState.orgsLoading ?
      <>
      <UserNotifications userNotificationPreferences={data?.insightsNotificationPreferences.userNotificationPreferences as UserNotificationPreference[]} refetchUserNotifications={refetch} />
      <OrganizationNotifications className='mt-4' orgNotificationPreferences={data?.insightsNotificationPreferences.orgNotificationPreferences as OrgNotificationPreference[]}  refetchUserNotifications={refetch}/>
      </>
      : (
        <LoadingData className="h-screen" />
      )
  }
      
    </>
  );
};

export default Notifications;