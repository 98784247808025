import { useSearchParams } from "react-router-dom";
import { FundraiserApprovalSubmission, FundraiserApprovalSubmissionStatus, FundraiserApprovalSubmissionsOrgNames, useFundraiserApprovalSubmissionsOrgNamesLazyQuery, useInsightsApprovalSubmissionsLazyQuery } from "../graphql/generated";
import { useContext, useEffect, useState } from "react";
import ApprovalContactFooter from "./approval-contact-footer";
import WhiteCard from "../components/WhiteCard";
import { ApprovalsContext } from "../ApprovalsGlobalState";
import { isScreenSizeBelow } from "../utils/UIUtils";
import SnapFundraiserDetailsSkeleton from "./snap-fundraiser-details-skeleton";
import ApprovalDetailViewer from "./approval-detail-viewer";
import ApprovalRevisionButtons from "./approval-revision-buttons";
import { SnapDivider, SnapDividerLabel } from "../suit";


const DocumentsPage = () => {
  const { appState } = useContext(ApprovalsContext);
  const [fetchApprovalSubmission, { data: submissionData, loading: submissionLoading }] = useInsightsApprovalSubmissionsLazyQuery();
  const [fetchOrgNames, { data: orgNamesData }] = useFundraiserApprovalSubmissionsOrgNamesLazyQuery();
  const [searchParams] = useSearchParams();
  const[submissionsNotFound, setSubmissionsNotFound] = useState<boolean>(false);
  const[submission, setSubmission] = useState<FundraiserApprovalSubmission|null>(null);
  const[orgNames, setOrgNames] = useState<FundraiserApprovalSubmissionsOrgNames|null>(null);
  const formId = searchParams.get("f");
  const token = searchParams.get("t");
  const submitterId = searchParams.get("u");
  const subId = searchParams.get("s");
  const submissionId = parseInt(`${subId}`, 10);

  useEffect(() => {
    if (submissionId) {
      fetchOrgNames({
        variables: {
          submissionId: submissionId,
        },
      });
    }
  }, [submissionId, fetchOrgNames]);

  useEffect(() => {
    if (formId && submitterId) {
      fetchApprovalSubmission({
        variables: {
          submitterId: `${submitterId}`,
          formId: parseInt(formId, 10),
        },
      });
    }

  }, [submitterId, formId, fetchApprovalSubmission]);

  useEffect(() => {
    if (submissionData) {
      const allSubmission = submissionData?.fundraiserApprovalSubmissions ? submissionData?.fundraiserApprovalSubmissions : [];
      const submission = allSubmission.find(s => s?.id === submissionId);
      setSubmissionsNotFound(!submission);
      setSubmission(submission ? submission : null);
    }
  }, [submissionData, submissionId]);

  useEffect(() => {
    if (orgNamesData) {
      setOrgNames(orgNamesData.fundraiserApprovalSubmissionsOrgNames.__typename === "FundraiserApprovalSubmissionsOrgNames" ? orgNamesData.fundraiserApprovalSubmissionsOrgNames : null);
    }
  }, [orgNamesData]);

  if (submissionLoading) {
    return (
      <main data-testid="approval-confirmation">
        <WhiteCard
          fullPageWidth={isScreenSizeBelow(appState.windowSize.screen, "md")}
          borderRadius="large"
          className={"lg:w-[55%] mx-auto max-w-5xl"}
          paddingX="px-6"
        >
          <SnapFundraiserDetailsSkeleton />
        </WhiteCard>
      </main>
    );
  }

  if (submissionsNotFound) {
    return (
      <main data-testid="request-revision-not-found">
        <WhiteCard
          fullPageWidth={isScreenSizeBelow(appState.windowSize.screen, "md")}
          borderRadius="large"
          className={"lg:w-[55%] mx-auto max-w-5xl"}
          paddingX="px-6"
        >
          <p className="text-center py-12">There were problems finding approval submission information, please contact support.</p>
          <ApprovalContactFooter testId="footer" />
        </WhiteCard>
      </main>
    );
  }

  if (!submission) return null;

  return (
    <main data-testid="approval-confirmation">
      <WhiteCard
        fullPageWidth={isScreenSizeBelow(appState.windowSize.screen, "md")}
        borderRadius="large"
        className={"lg:w-[55%] mx-auto max-w-5xl"}
        paddingX="px-6"
      >
        <h2 className="text-center text-2xl text-gray-800 font-semibold mb-2">{submission.firstName} {submission.lastName} has {submission.previousRevision ? "revised" : "submitted"} the fundraiser request.</h2>
        { submission?.status === FundraiserApprovalSubmissionStatus.Submitted ?
          <p className="text-center text-base text-gray-800 font-medium pb-4">Please review the following information. Once reviewed, please use the options below to let {submission.firstName} know if the request is approved or needs further revisions.</p>
        :
          <>
          { submission?.status === FundraiserApprovalSubmissionStatus.Approved ?
            <p className="text-center text-base text-gray-800 font-medium pb-4">This submission is already approved. No more actions needs to be taken.</p>
          :
          <p className="text-center text-base text-gray-800 font-medium pb-4">This is a previous version of the submission. No actions can be processed in this revision.</p>
          }
          </>
        }
        { submission?.status === FundraiserApprovalSubmissionStatus.Submitted ?
          <ApprovalRevisionButtons token={`${token}`} className="py-5" />
        :
          null
        }
        { submission &&
          <ApprovalDetailViewer
            submission={submission}
            token={`${token}`}
            fundraiserName={`${orgNames?.fundraiserName}`}
            organizationName={`${orgNames?.organizationName}`}
            programName={`${orgNames?.programName}`}
            showDivider={false}
            showAccessToDocuments
          />
        }
        { submission?.status === FundraiserApprovalSubmissionStatus.Submitted ?
          <ApprovalRevisionButtons token={`${token}`} className="py-5" />
        :
          null
        }
        <SnapDivider>
          <SnapDividerLabel></SnapDividerLabel>
        </SnapDivider>
        <ApprovalContactFooter
          testId="footer"
          className="mt-5"
        />
      </WhiteCard>
    </main>
  );
};

export default DocumentsPage;
