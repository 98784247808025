import { useState } from "react";
import { SnapButton, SnapDivider, SnapDividerLabel } from "../suit";
import OpenText from "./open-text";
import ConfirmAction from "../layout/confirm-action";
import { RevisionFundraiserApprovalSubmissionData, useAddRevisionFundraiserApprovalSubmissionMutation } from "../graphql/generated";

interface ApprovalRevisionFormProps {
  token: string;
  submitterName: string;
  onFormSubmitted: (result: RevisionFundraiserApprovalSubmissionData) => void;
}

const ApprovalRevisionForm: React.FC<ApprovalRevisionFormProps>  = ({token, submitterName, onFormSubmitted}) => {
  const [addRevisionFundraiserApprovalSubmission] = useAddRevisionFundraiserApprovalSubmissionMutation();
  const[confirmSubmitOpen, setConfirmSubmitOpen] = useState<boolean>(false);
  const[requestRevisionText, setRequestRevisionText] = useState<string>("");

  const submitForm = async () => {
    setConfirmSubmitOpen(false);
    const {data, errors} = await addRevisionFundraiserApprovalSubmission({
      variables: {
        token: token,
        revision: requestRevisionText
      }
    });
    if ((!errors || errors.length === 0) && data?.addRevisionFundraiserApprovalSubmission.__typename === "RevisionFundraiserApprovalSubmissionData") {
      onFormSubmitted(data?.addRevisionFundraiserApprovalSubmission);
    }
  }

  return (
    <div className="mb-5">
      <h2 className="text-2xl text-gray-800 font-semibold text-center">Request Revisions</h2>
      <p className="text-base text-gray-800 font-medium text-center mb-2">Let {submitterName} know what updates need to be made to this fundraiser.</p>
      <p className="mb-2 text-sm text-gray-500">
        <span className=" text-red-500 text-sm">*</span>
        Indicates a required field.
      </p>
      <OpenText
        errorMessage={""}
        className="pb-3"
        fieldId={"requested-revisions"}
        testId={"requested-revisions"}
        label={"Requested Revisions"}
        value={""}
        onBlur={(revisionText) => {}}
        onInput={(revisionText) => setRequestRevisionText(revisionText)}
        required
        displayCharCount
        placeholder="PLease Specify Revisions"
      />
      <div className="flex flex-row sm:justify-end mb-4">
        <SnapButton
          data-testid="request-revision-submit"
          onClick={() => setConfirmSubmitOpen(true)}
          variant="primary"
          fullWidth
          disabled={requestRevisionText.trim().length === 0}
          className="flex-1 sm:flex-initial sm:min-w-36"
        >
          Send Request
        </SnapButton>
        <ConfirmAction
          testId="confirm-request-revision"
          showConfirmation={confirmSubmitOpen}
          message="Are you sure you want to request a revision?"
          action={submitForm}
          onConfirmationClose={() => setConfirmSubmitOpen(false)}
        />
      </div>
      <SnapDivider>
        <SnapDividerLabel></SnapDividerLabel>
      </SnapDivider>
    </div>
  );
};

export default ApprovalRevisionForm;
