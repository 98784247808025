import * as EmailValidator from "email-validator";
import {
  MAX_ESTIMATED_TOTAL_RAISED,
  MAX_GROUP_SIZE,
  MIN_ESTIMATED_TOTAL_RAISED,
  MIN_GROUP_SIZE,
  MIN_REASON_LENGTH,
} from "./constants";

export const validGroupSize = (value: string): boolean => {
  const parsedValue = parseInt(value, 10);
  return !(
    Number.isNaN(parsedValue) ||
    parsedValue > MAX_GROUP_SIZE ||
    parsedValue < MIN_GROUP_SIZE
  );
};

export const validEstimatedTotalRaised = (value: string): boolean => {
  const parsedValue = parseInt(value, 10);
  return !(
    Number.isNaN(parsedValue) ||
    parsedValue > MAX_ESTIMATED_TOTAL_RAISED ||
    parsedValue < MIN_ESTIMATED_TOTAL_RAISED
  );
}

export const validProposedStartDate = (value: string): string => {
  const date = new Date(value);
  if (isNaN(date.getTime())) {
    return "Please enter a valid date";
  }
  if (date.getTime() <= Date.now()) {
    return "Please enter a valid date";
  }
  return "";
};

export const validReason = (value: string): string => {
  return value.length > MIN_REASON_LENGTH
    ? ""
    : "Reason for fundraiser must be between 100 and 1500 characters length";
};

export const validUrl = (value: string): string => {
  let url;
  try {
    url = new URL(value);
    return (url.protocol === "http:" || url.protocol === "https:") ?
      ""
      :
      "Please enter a valid url"
  } catch (error) {
    return "Please enter a valid url";
  }
  ;
}

export const validName = (value: string): string => {
  return value.length > 0
    ? ""
    : "Please provide a name";
};

export const validEmail = (value: string): string => {
  return EmailValidator.validate(value)
    ? ""
    : "Please enter a valid email address";
};
