import { useParams } from "react-router-dom";
import ApprovedSSO from "./approved-sso";
import ApprovedNonSSO from "./approved-non-sso";
import { ApproveFundraiserApprovalSubmissionData, useApproveFundraiserApprovalSubmissionMutation } from "../graphql/generated";
import { useContext, useEffect, useState } from "react";
import ApprovalsPageLoading from "./approvals-page-loading";
import ApprovalContactFooter from "./approval-contact-footer";
import WhiteCard from "../components/WhiteCard";
import { ApprovalsContext } from "../ApprovalsGlobalState";
import { isScreenSizeBelow } from "../utils/UIUtils";


const ApprovedPage = () => {
  const { appState } = useContext(ApprovalsContext);
  const { token } = useParams();
  const [approveFundraiserApprovalSubmission] = useApproveFundraiserApprovalSubmissionMutation();
  const[approvingSubmission, setApprovingSubmission] = useState<boolean>(true);
  const[approveSubmissionWithErrors, setApproveSubmissionWithErrors] = useState<boolean>(false);
  const[isSsoApprover, setIsSsoApprover] = useState<boolean>(false);
  const[submitterName, setSubmitterName] = useState<string>("");

  useEffect(() => {
    const approveSubmission = async (token:string) => {
      const {data, errors} = await approveFundraiserApprovalSubmission({ variables: { token: token }});
      if ((errors && errors.length > 0) || data?.approveFundraiserApprovalSubmission.__typename === "InvalidToken") {
        setApproveSubmissionWithErrors(true);
        setApprovingSubmission(false);
        return;
      }
      const approveResult = data?.approveFundraiserApprovalSubmission as ApproveFundraiserApprovalSubmissionData;
      setIsSsoApprover(approveResult.isSsoApprover);
      setSubmitterName(approveResult.submitterName);
      setApprovingSubmission(false);
    };
    if (token) {
      approveSubmission(token);
    }
  }, [token, approveFundraiserApprovalSubmission]);

  if (approvingSubmission) {
    return (
      <ApprovalsPageLoading
        message="Validating and approving..."
        className="py-12"
      />
    );
  }

  if (approveSubmissionWithErrors) {
    return (
      <main data-testid="approved-page-error">
        <WhiteCard
          fullPageWidth={isScreenSizeBelow(appState.windowSize.screen, "md")}
          borderRadius="large"
          className={"lg:w-[55%] mx-auto max-w-5xl"}
          paddingX="px-6"
        >
          <p className="text-center py-12">There were problems approving this submission, please contact support.</p>
          <ApprovalContactFooter testId="footer" />
        </WhiteCard>
      </main>
    );
  }

  return (
    <>
      { isSsoApprover ?
        <ApprovedSSO submitterName={submitterName} />
      :
        <ApprovedNonSSO submitterName={submitterName} />
      }
    </>
  );
};

export default ApprovedPage;
