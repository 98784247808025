import Header from "./components/Header";
import { Outlet, useLocation } from "react-router-dom";
import {useApiStatusQuery} from "./graphql/generated";
import { noPaddingRoutes } from './App.router';
import AppGlobalState from "./AppGlobalState";
import InsightsNavigation from "./components/InsightsNavigation";
import { SplitTreatments } from "@splitsoftware/splitio-react";
import { SplitFeatureFlags } from "./constants/splitFeatureFlags";
import { ISplitTreatmentsChildProps } from "@splitsoftware/splitio-react/types/types";
import ImpersonationModeWrapper from "./components/ImpersonationModeWrapper";
import { isPublicPage } from "./utils/RouteUtils";

const InsightsApp = ()=>{
    const {data :apiStatus} = useApiStatusQuery();
    const location = useLocation();
    const noPadding = new RegExp(noPaddingRoutes.join('|')).test(location.pathname);
    const publicPage = isPublicPage(location.pathname);
    return (
        <AppGlobalState>
          <ImpersonationModeWrapper>
            <div className="App">
              {(!!apiStatus?.apiReady && !publicPage) ? (
                <>
                  <Header />
                  <SplitTreatments names={[SplitFeatureFlags.INS_462_settings_user_notifications_fe]} >
                    {({ treatments, isReady }: ISplitTreatmentsChildProps) => {
                      if(isReady) {
                        return (
                          <InsightsNavigation />
                        );
                      } else {
                        return (<></>);
                      }
                    }}
                  </SplitTreatments>
                  <main className={`overflow-x-hidden break-words md:ml-[91px] lg:ml-[285px] pb-[62px] md:pb-2 ${noPadding ? 'pt-12 md:pt-14' : 'p-3.5 pt-20'}`}>
                    {!!apiStatus?.apiReady && <Outlet />}
                  </main>
                </>
              ):(
                <>
                <Header />
                <main className="flex">
                  <Outlet />
                </main>
                </>
              )}

            </div>
          </ImpersonationModeWrapper>
        </AppGlobalState>
    );
  }

export default InsightsApp;
