import { useContext} from "react";
import { ApprovalsContext } from "../ApprovalsGlobalState";
import ApprovalRevisionConfirmationSSO from "./approval-revision-confirmation-sso";
import ApprovalRevisionConfirmationNonSSO from "./approval-revision-confirmation-non-sso";


const ApprovalRevisionConfirmation = () => {
  const { appState } = useContext(ApprovalsContext);
  const { revisionConfirmation } = appState;
  const { isSsoUser, submitterName } = revisionConfirmation;

  return (
    <>
      { isSsoUser ?
        <ApprovalRevisionConfirmationSSO submitterName={submitterName} />
      :
        <ApprovalRevisionConfirmationNonSSO submitterName={submitterName} />
      }
    </>
  );
};

export default ApprovalRevisionConfirmation;
