import { CSSTransition } from "react-transition-group";

interface DropPanelProps {
  testId: string;
  children: React.ReactNode;
  showPanel: boolean;
  className?: string;
}
const DropPanel : React.FC<DropPanelProps> = ({testId, children, showPanel, className = ""})=>{

  return (
    <CSSTransition
      in={showPanel}
      unmountOnExit
      timeout={{ enter: 0, exit: 150 }}
      classNames={{
        enterActive: 'opacity-0',
        enterDone: 'opacity-100',
        exit: 'opacity-0',
      }}
    >
      <div
        data-testid={testId}
        className={`absolute bg-white shadow-md w-full border border-gray-200 rounded-lg p-2 mt-1 z-[10001] duration-150 ease-in-out transition-[opacity] opacity-0 ${className}`}
      >
        {children}
      </div>
    </CSSTransition>
  );
}

export default DropPanel;