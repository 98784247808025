import { DistrictPreApprovalsIndex } from "../AppGlobalState";
import { ErrorCheckingInputs } from "../components/modals/EditPreApprovalContactModal"
import { EmailStatus } from "../components/preApprovals/preApprovalConstants";
import { InsPreApprovedContact } from "../graphql/generated";

export const groupPreApprovalsByAssociatedOrg = (contacts:InsPreApprovedContact[]): DistrictPreApprovalsIndex => {
  return contacts.reduce((groups:any, item) => {
    const groupName = item.associated_org_id || 'unknown';
    const group = groups[groupName] || [];
    group.push(item);
    groups[groupName] = group;
    return groups;
  }, {});
};

export const checkIfDashboardsEmpty = (districtDash:any, schoolDash:any, affiliateDash:any):boolean =>
  districtDash !== null && 
  schoolDash !== null && 
  affiliateDash !== null && 
  districtDash.length === 0 && 
  schoolDash.length === 0 && 
  affiliateDash.length === 0;

export const changePreApprovalInviteStatusToClientSide = (inviteStatus: string): string => {
  switch(inviteStatus){
    case EmailStatus.CREATED:
      return EmailStatus.PENDING;
    case EmailStatus.OPEN:
      return EmailStatus.OPEN;
    case EmailStatus.DELIVERED:
      return EmailStatus.DELIVERED;
    case EmailStatus.LINK_OPEN:
      return EmailStatus.CLICKED;
    case EmailStatus.BOUNCE:
    case EmailStatus.DEFERRED:
    case EmailStatus.DROPPED:
    case EmailStatus.UNDELIVERABLE:
      return EmailStatus.UNDELIVERABLE;
    case EmailStatus.RE_SENT:
      return EmailStatus.RE_SENT;
    default:
      return inviteStatus;
  }
}

export const isPreApprovalInviteUndeliverable = (status:string): boolean => {
  return [EmailStatus.BOUNCE, EmailStatus.DEFERRED, EmailStatus.DROPPED].indexOf(status) >= 0;
};

export const updatePreApprovalContact = ( listOfContacts: InsPreApprovedContact[], updatedContact: ErrorCheckingInputs, contactId: number, emailChanged?: boolean): InsPreApprovedContact[]  => {
  return listOfContacts.map(contact =>{
    if(contact.id === contactId){
      return {
        ...contact,
        first_name: updatedContact.firstName,
        last_name: updatedContact.lastName,
        activity: updatedContact.activity,
        email: updatedContact.email,
        invite_status: emailChanged ? EmailStatus.CREATED : contact.invite_status,
        invite_phone_number: updatedContact.phoneNumber
      }
    } else {
      return contact
    }
  });
}

export const updateReSendStatus = (listOfContacts: InsPreApprovedContact[], contactId: number): InsPreApprovedContact[] => {
  return listOfContacts.map(contact => {
    if(contact.id === contactId){
      return {...contact,
      invite_status: EmailStatus.RE_SENT
    }
    }else {
      return contact
    }
  })
}

export const deletePreApprovedContact = (listOfContacts: InsPreApprovedContact[], contactId: number): InsPreApprovedContact[] => {
  return listOfContacts.filter(contact => contact.id !== contactId);
}

export const getPreApprovalContactId = (listOfContacts:InsPreApprovedContact[], contactEmail: string): number => {
  return listOfContacts.filter(contact => contact.email === contactEmail).map(contact => contact.id as number)[0];
}
