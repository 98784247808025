import { FundraiserApprover } from "../graphql/generated";

export interface FundraiserApproverErrors {
  firstName?: string;
  lastName?: string;
  email?: string;
  roleId?: string;
  phone?: string;
}

interface ApproverState {
  approver: FundraiserApprover;
  errors: FundraiserApproverErrors;
}

interface ApproverReducerAction {
  type: string;
  payload: {
    approver?: FundraiserApprover,
    errors?: FundraiserApproverErrors
  };
}

export const approverReducerEmptyErrors = {
  firstName: "",
  lastName: "",
  email: "",
  roleId: "",
  phone: "",
};

export const approverReducerInitialState: ApproverState = {
  approver: {
    userId: "",
    firstName: "",
    lastName: "",
    email: "",
    roleId: "",
    phone: "",
  },
  errors: approverReducerEmptyErrors
}

export const approverReducer = (state: ApproverState, action: ApproverReducerAction)=>{
  const {payload} = action
  switch (action.type) {
    case "UPDATE_APPROVER":
      return {
        ...state,
        approver: { ...state.approver, ...payload.approver },
        errors: { ...state.errors, ...payload.errors },
      }
    default:
      return state;
  }
}
