import { Org, OrgNotificationPreference } from "../../graphql/generated";
import { SnapCheckboxButton, SnapCheckboxGroup } from "../../suit";



interface OrganizationNotificatinosSchoolListProps  {
    className?: string;
    schoolsOrAffiliate: Org[]
    notificationPreferences: OrgNotificationPreference[]
    isOrgRemoved: (orgId:string) => boolean | undefined
    addOrgs: (checkedOrgIds: string[]) => void
    removeOrgs: (unCheckedOrgIds: string[]) => void
  }
  
  export const OrganizationNotificatinosSchoolList: React.FC<OrganizationNotificatinosSchoolListProps> = ({ className,
    schoolsOrAffiliate,
    notificationPreferences,
    isOrgRemoved,
    addOrgs,
    removeOrgs
   }) => {    
    const onCheckBoxChange =  (event: any)=>{
      const {checked, value} = event.detail;
      if(checked){
        addOrgs([value])
      } else{
        removeOrgs([value])
      }
    }

    return (
        <>
        <SnapCheckboxGroup>
        {
          schoolsOrAffiliate.map((org)=>{
            return (
              <SnapCheckboxButton
                input-name={`input-${org.name}`} 
                label={org.name||""} 
                value={org.id} 
                key={'checkbox-' + org.id}
                checked={ !isOrgRemoved(org.id)}
                onSnap-checkbox-updated={onCheckBoxChange}
                >
              </SnapCheckboxButton>
            )
          })
        }
      </SnapCheckboxGroup>
        </>
    );
  }