import { useContext, useState } from "react";
import { isComingFromExternalUrl } from "../utils/RouteUtils";
import { AppContext } from "../AppGlobalState";
import VaultWelcomeModal from "./vault-welcome-modal";
import { ALLOWED_USER_CONFIGURATIONS, testUserConfiguration } from "../user-configurations/user-configurations-data-helper";
import { isVaultActionRequired } from "./vault-data-helper";
import { useMeQuery, useSetUserInsightsConfigurationsMutation } from "../graphql/generated";
import { useNavigate } from "react-router-dom";

interface VaultWelcomeProps{
}

const VaultWelcome: React.FC<VaultWelcomeProps> = () => {
  const {appState, dispatch} = useContext(AppContext);
  const { 
    userAssociationsFinancialAccIndex,
    userAssociationsFinancialUsersIndex,
    userAssociationsVaultStatusIndex
  } = appState;
  const navigate = useNavigate();
  const { data: user } = useMeQuery();
  const [showModal, setShowModal] = useState(true);
  const [setUserInsightsConfiguration] = useSetUserInsightsConfigurationsMutation();
  if(!user?.me?.id) {
    return null;
  }
  const isComingExternalUrl = isComingFromExternalUrl();
  const modalNotShownYet = !appState.vault.welcomeModalShown;
  const hasDirectAssociations = Object.keys(appState.userAssociationsIndex).length > 0;
  const vaultWelcomeDismissedConfig = testUserConfiguration(appState, ALLOWED_USER_CONFIGURATIONS.VAULT_WELCOME_DISMISSED, "YES");
  const financialAccActionRequired = isVaultActionRequired(userAssociationsFinancialAccIndex, userAssociationsFinancialUsersIndex, userAssociationsVaultStatusIndex  , user.me.id);
  const showVaultWelcomeModal:boolean = isComingExternalUrl && modalNotShownYet && hasDirectAssociations && !vaultWelcomeDismissedConfig && financialAccActionRequired;

  const onWelcomeModalClosed = () => {
    setShowModal(false);
    setTimeout(() =>  {
      dispatch({
        type: "SET_VAULT_WELCOME_MODAL_SHOWN",
        payload: true,
      });
    }, 300);
  }

  const onWelcomeModalGetStarted = () => {
    setShowModal(false);
    setTimeout(() =>  {
      dispatch({
        type: "SET_VAULT_WELCOME_MODAL_SHOWN",
        payload: true,
      });
      setUserInsightsConfiguration({
        variables: {
          config: [
            {
              name: ALLOWED_USER_CONFIGURATIONS.VAULT_WELCOME_DISMISSED,
              value: "YES",
            }
          ]
        }
      }).then(() => {
        dispatch({
          type: "SET_VAULT_PRESELECTED_ORG_ID",
          payload: null,
        });
        dispatch({
          type: "SET_VAULT_REFERRER_PATH",
          payload: null,
        });
        navigate("/vault");
      });
    }, 300);
  }

  if(!showVaultWelcomeModal) {
    return null;
  }

  return(
    <VaultWelcomeModal 
      showModal={showModal}
      onClose={onWelcomeModalClosed}
      onGetStarted={onWelcomeModalGetStarted}
    />
  )
  
}

export default VaultWelcome;
