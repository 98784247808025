import { useContext } from "react";
import {
  FundraiserApprovalSubmissionFormType,
} from "../graphql/generated";
import { ApprovalsContext } from "../ApprovalsGlobalState";
import { FORM_STEPS } from "./constants";
import SnapFundraiserDetails from "./snap-fundraiser-details";
import NewSnapFundraiserDetails from "./new-snap-fundraiser-details";
import OtherFundraiserDetails from "./other-fundraiser-details";

const FundraiserDetails = () => {
  const { appState } = useContext(ApprovalsContext);
  const { fundraiserApprovalForm: fundraiserApprovalFormState } = appState;
  const {
    formStep,
    editableSubmission:{formType, newFundraiser},
  } = fundraiserApprovalFormState;

  if (formStep >= FORM_STEPS.snapFundraiserSelection && formType === FundraiserApprovalSubmissionFormType.Snap && !newFundraiser) {
    return <SnapFundraiserDetails />;
  }

  if (formStep >= FORM_STEPS.snapFundraiserSelection && formType === FundraiserApprovalSubmissionFormType.Snap && newFundraiser) {
    return <NewSnapFundraiserDetails />;
  }

  if(formStep >= FORM_STEPS.formTypeSelection && formType !== FundraiserApprovalSubmissionFormType.Snap) {
    return <OtherFundraiserDetails />;
  }

  return null;
};

export default FundraiserDetails;
