import React, { useContext, useEffect, useMemo, useState } from 'react';
import { SnapIcon, SnapInput } from '../suit';
import { useDebounce } from '../hooks/useDebounce';
import DropPanel from '../layout/drop-panel';
import Skeleton from '../components/Skeleton';
import { ApprovalsContext } from '../ApprovalsGlobalState';
import { isScreenSizeBelow } from '../utils/UIUtils';
import Modal from '../layout/modal';
import { OrgType, useFundraiserOrgAndGroupNamesLazyQuery, useOrgSearchLazyQuery } from '../graphql/generated';
import notEmpty from '../utils/not-empty';
import { processQueryFetchFundraiserOrgAndGroupNames } from './approvals-data-helpers';

const ORGS_SEARCH_LIMIT:number = 8;

export interface OrgSearchItem {
  id: string;
  name: string;
  location: string;
}

interface OrgSearchSelectorProps {
  fieldId: string;
  testId: string;
  error: string;
  orgId?: string;
  onOrgSelected?: (org:OrgSearchItem) => void;
  className?: string;
}

const OrgSearchSelector: React.FC<OrgSearchSelectorProps> = ({ fieldId, testId, error, orgId, onOrgSelected, className = "" }) => {
  const { appState:{windowSize} } = useContext(ApprovalsContext);
  const[searchActive, setSearchActive] = useState<boolean>(true);
  const[searchTerm, setSearchTerm] = useState<string>("");
  const debouncedSearchTerm:string = useDebounce(searchTerm, 500);
  const[searchFocused, setSearchFocused] = useState<boolean>(false);
  const[searchModalOpen, setSearchModalOpen] = useState<boolean>(false);
  const[selectedOrgId, setSelectedOrgId] = useState<string>("");
  const [fetchOrgs, { data: orgsData, loading: orgsDataLoading }] = useOrgSearchLazyQuery();
  const [ fetchFundraiserOrgAndGroupNames ] = useFundraiserOrgAndGroupNamesLazyQuery();

  useEffect(() => {
    if (!!orgId && orgId !== selectedOrgId) {
      setSelectedOrgId(orgId);
      setSearchActive(false);
      fetchFundraiserOrgAndGroupNames({
        variables: {
          orgId: orgId,
          programId: "",
        },
      }).then((response) => {
        const { orgName } = processQueryFetchFundraiserOrgAndGroupNames(response);
        setSearchTerm(orgName);
      });
    }
  }, [orgId, selectedOrgId, fetchFundraiserOrgAndGroupNames]);

  useEffect(() => {
    if (debouncedSearchTerm.length > 2 && searchActive) {
      fetchOrgs({variables: {
        limit: ORGS_SEARCH_LIMIT,
        label: OrgType.School,
        name: debouncedSearchTerm
      }});
    }
  }, [debouncedSearchTerm, searchActive, fetchOrgs]);

  const searchedOrgs:OrgSearchItem[] = useMemo(
    () => {
      if (orgsDataLoading || !orgsData || !orgsData.orgSearch || orgsData.orgSearch.length === 0)
        return []
      const validOrgs = orgsData.orgSearch.filter(notEmpty);
      const orgSearchItems:OrgSearchItem[] = validOrgs.map((org) => {
        const location = org.fields.state_code + " • " + org.fields.zip_code;
        return {
          id: org.id,
          name: org.name || "",
          location: location,
        };
      });
      return orgSearchItems;
    },
    [orgsData, orgsDataLoading]
  );

  const handleSearchFieldFocus = () => {
    if(isScreenSizeBelow(windowSize.screen, "sm")) {
      setSearchModalOpen(true);
      return;
    }
    setSearchFocused(true);
  };

  const selectOrgSearchItem = (org:OrgSearchItem) => {
    setSearchActive(false);
    setSearchTerm(org.name);
    if(isScreenSizeBelow(windowSize.screen, "sm")) {
      setSearchModalOpen(false);
    }
    if (onOrgSelected) {
      onOrgSelected(org);
    }
  };

  const renderInsufficientSearchTermState = () => {
    return (
      <p className="text-sm font-normal text-gray-700 text-center py-7">Please type more than 2 letters...</p>
    );
  };

  const renderLoadingState = () => {
    return (
      <>
        <p className="text-sm font-normal text-gray-700 text-center py-2">Searching...</p>
        <Skeleton
          variant="text"
          height={12}
          lineCount={3}
          className="flex flex-col w-full justify-between h-20"
        />
      </>
    );
  };

  const renderNoResultsState = () => {
    return (
      <p className="text-sm font-normal text-gray-700 text-center py-7">No results match your search.</p>
    );
  };

  const renderResultsState = () => {
    return searchedOrgs.map((orgSearchItem:OrgSearchItem, index:number) => {
      return (
        <div
          key={index}
          className="flex flex-col py-1 hover:bg-gray-50 cursor-pointer"
          onClick={() => { selectOrgSearchItem(orgSearchItem) }}
        >
          <span className="text-sm font-medium text-gray-800">{orgSearchItem.name}</span>
          <span className="text-xs font-normal text-gray-500">{orgSearchItem.location}</span>
        </div>
      );
    });
  };

  const renderSearchPanelContent = () => {
    if (debouncedSearchTerm.length < 3) {
      return renderInsufficientSearchTermState();
    }
    if (orgsDataLoading) {
      return renderLoadingState();
    }
    if (searchedOrgs.length === 0) {
      return renderNoResultsState();
    }
    return renderResultsState();
  };

  const renderSearchPanel = () => {
    if(isScreenSizeBelow(windowSize.screen, "sm")) {
      return (
        <Modal
          testId={testId}
          showModal={searchModalOpen}
          onClose={() => { setSearchModalOpen(false) }}
          className="w-full h-full top-0 bottom-0"
        >
          <div className="flex flex-row justify-between items-center px-2 py-3 border-b border-gray-200">
            <span className="text-sm font-bold text-gray-800">Organization</span>
            <SnapIcon icon="x-solid" size="md" className="cursor-pointer text-gray-500" onClick={() => { setSearchModalOpen(false) }} />
          </div>
          <div className="p-2">
            <SnapInput
              _id={fieldId + "-modal"}
              _type="text"
              placeholder="Type your organization name"
              value={searchTerm}
              onInput={(e) => { setSearchTerm((e.target as HTMLInputElement).value) }}
              onKeyDown={(e) => { if(!searchActive) {setSearchActive(true)} }}
            />
          </div>
          <div className="px-2">
            { renderSearchPanelContent() }
          </div>
        </Modal>
      );
    }
    return (
      <DropPanel
        testId={`${testId}-drop-panel`}
        showPanel={searchFocused && searchTerm.length > 0}
      >
        { renderSearchPanelContent() }
      </DropPanel>
    );
  };

  return (
    <div data-testid={testId} className={`w-full relative ${className}`}>
      <SnapInput
        data-testid={`${testId}-input`}
        _id={fieldId}
        _type="text"
        name={fieldId}
        label="Organization"
        required
        placeholder="Type your organization name"
        icon={searchFocused ? "chevron-up-solid" : "chevron-down-solid"}
        iconPosition="right"
        value={searchTerm}
        error={!!error}
        helpText={!!error ? error : ""}
        onInput={(e) => { setSearchTerm((e.target as HTMLInputElement).value) }}
        onKeyDown={(e) => { if(!searchActive) {setSearchActive(true)} }}
        onFocus={(e) => { handleSearchFieldFocus() }}
        onBlur={(e) => { setSearchFocused(false) }}
      />
      { renderSearchPanel() }
    </div>
  );
};

export default OrgSearchSelector;

