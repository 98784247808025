import { SplitFactory, SplitTreatments } from '@splitsoftware/splitio-react';
import { ISplitTreatmentsChildProps } from "@splitsoftware/splitio-react/types/types";
import { SplitFeatureFlags } from '../constants/splitFeatureFlags';

interface FeatureToggleInitializerProps {
    id: string;
    children: React.ReactNode;
    featureList: Array<string>;
}

const FeatureToggleInitializer: React.FC<FeatureToggleInitializerProps> = ({ id, children, featureList }) => {
    
    const sdkConfig: SplitIO.IBrowserSettings = {
        core: {
          authorizationKey: process.env.REACT_APP_SPLITIO_CLIENT_KEY as string,
          key: id || 'key'
        }
      };

    if(!id || id.length === 0) {
      return null;
    }

    return (
        <SplitFactory config={sdkConfig} >
            <SplitTreatments names={[SplitFeatureFlags.USER_801_use_snap_impersonation_mode]} >
            {({ treatments, isReady }: ISplitTreatmentsChildProps) => {
              return (
                <>
                  {children}
                </>
              )
            }}
          </SplitTreatments>
        </SplitFactory> 
    );
};

export default FeatureToggleInitializer;
