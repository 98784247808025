import { useContext } from "react";
import { AppContext } from "../AppGlobalState";
import WhiteCard from "../components/WhiteCard";
import { Org, useMeQuery } from "../graphql/generated";
import { SnapBadge, SnapIcon } from "../suit";
import { getLabelForOrgStatus, getOrgVaultStatus } from "./vault-data-helper";

interface VautlOrgListitemProps{
  testId: string;
  org: Org;
  className?: string;
  onItemSelected?: () => void;
}

const VautlOrgListitem: React.FC<VautlOrgListitemProps> = ({
  testId,
  org,
  className = "",
  onItemSelected = () => {}
}) => {
  const { appState: {
    userAssociationsFinancialAccIndex,
    userAssociationsFinancialUsersIndex,
    userAssociationsVaultStatusIndex,
  }, } = useContext(AppContext);
  const { data: user } = useMeQuery();
  if(!user?.me?.id) {
    return null;
  }
  const status = getOrgVaultStatus(userAssociationsFinancialAccIndex,userAssociationsFinancialUsersIndex, userAssociationsVaultStatusIndex, org.id, user.me.id);

  return (
    <WhiteCard testId={testId} className={`flex flex-row mb-3 last:mb-0 items-start md:items-center py-2 md:py-4 ${className}`}>
      <div className="flex flex-col flex-1 w-full md:w-auto mb-2 md:mb-0">
        <h4 
          data-testid={`${testId}-orgname`} 
          className="flex-1 text-blue-600 text-base font-bold cursor-pointer"
          onClick={onItemSelected}
        >
          {org.name}
        </h4>
        <div className="flex flex-row items-start pt-1">
          <span className="text-gray-500 text-sm font-normal pr-2">Snap! Vault</span>
          <SnapBadge size="tiny" color="gray">{ getLabelForOrgStatus(status) }</SnapBadge>
        </div>
      </div>
      <SnapIcon icon="arrow-sm-right-line" size="md" className="ml-4 cursor-pointer text-blue-600" onClick={onItemSelected} />
    </WhiteCard>
  );
}

export default VautlOrgListitem;