import React, { createContext, useEffect, useReducer } from "react";
import { useWindowSize, WindowSize } from "./hooks/useWindowSize";
import OrgsDataLoader, {
  CampaignsReverseIndex,
  OrgsIndex,
  OrgsTree,
} from "./components/OrgsDataLoader";
import {
  FinancialAcctOrg,
  FinancialAcctUser,
  InsPreApprovedContact,
  Org,
  VaultAccountStatus,
  usePermissionsQuery,
  useUserInsightsConfigurationsQuery,
} from "./graphql/generated";

export type DistrictPreApprovalsIndex = Record<string, InsPreApprovedContact[]>; //TODO: relocate

export interface AppState {
  impersonateMode: boolean;
  userConfigs: Record<string, string>;
  orgsIndex: OrgsIndex;
  orgsTree: OrgsTree;
  userAssociationsIndex: Record<string, Org>;
  userAssociationsFinancialAccIndex: Record<string, FinancialAcctOrg[]>;
  userAssociationsFinancialUsersIndex: Record<string, FinancialAcctUser[]>;
  userAssociationsVaultStatusIndex: Record<string, VaultAccountStatus>;
  dashboardsIndex: string[];
  campaignsReverseIndex: CampaignsReverseIndex;
  orgsLoading: boolean;
  districtPreApprovals: DistrictPreApprovalsIndex;
  orgPreApprovals: InsPreApprovedContact[];
  preApprovalsOrgId: string;
  preApprovalsLoading: boolean;
  windowSize: { screen: string };
  dashboardTab: string;
  numInviteForms: number;
  dashboardName: string;
  showPreApprovalsModal: boolean;
  vault: {
    welcomeModalShown: boolean;
    preselectedOrgId: string | null;
    referrerPath: string | null;
    sucessModalShow: boolean;
  };
}

export interface ReducerAction {
  type: string;
  payload: any;
}

export const initialState: AppState = {
  impersonateMode: false,
  userConfigs: {},
  windowSize: {
    screen: "xs",
  },
  orgsIndex: {},
  orgsTree: {
    districts: [],
    schools: [],
    affiliates: [],
  },
  showPreApprovalsModal: false,
  dashboardsIndex: [],
  userAssociationsIndex: {},
  userAssociationsFinancialAccIndex: {},
  userAssociationsFinancialUsersIndex: {},
  userAssociationsVaultStatusIndex: {},
  campaignsReverseIndex: {},
  orgsLoading: true,
  districtPreApprovals: {},
  orgPreApprovals: [],
  preApprovalsOrgId: "",
  preApprovalsLoading: false,
  dashboardTab: "Overview",
  numInviteForms: 0,
  dashboardName: "",
  vault: {
    welcomeModalShown: false,
    preselectedOrgId: null,
    referrerPath: null,
    sucessModalShow: false,
  },
};

export const AppContext = createContext<{
  appState: AppState;
  dispatch: React.Dispatch<ReducerAction>;
}>({ appState: initialState, dispatch: () => null });

interface AppGlobalStateProps {
  children: React.ReactNode;
}

const reducer = (state: AppState, action: ReducerAction) => {
  switch (action.type) {
    case "SET_IMPERSONATE_MODE":
      return { ...state, impersonateMode: action.payload.mode };
    case "SET_USER_CONFIG":
      return { ...state, userConfigs: action.payload };
    case "PRE_APPROVALS_LOADING":
      return { ...state, preApprovalsLoading: true };
    case "UPDATE_PRE_APPROVALS_DISTRICT":
      return {
        ...state,
        districtPreApprovals: action.payload.contacts,
        orgPreApprovals: [],
        preApprovalsOrgId: action.payload.orgId,
        preApprovalsLoading: false,
      };
    case "UPDATE_PRE_APPROVALS_ORG":
      return {
        ...state,
        districtPreApprovals: {},
        orgPreApprovals: action.payload.contacts,
        preApprovalsOrgId: action.payload.orgId,
        preApprovalsLoading: false,
      };
    case "UPDATE_ORGS":
      return { ...state, ...action.payload };
    case "UPDATE_ORG_FINANCIAL_ACCOUNT_STATUS":
      return {
        ...state,
        userAssociationsFinancialAccIndex: {
          ...state.userAssociationsFinancialAccIndex,
          [action.payload.orgId]: action.payload.financialStatus,
        },
      };
    case "UPDATE_WINDOW_SIZE":
      return { ...state, windowSize: action.payload };
    case "SET_PREAPPROVALS_MODAL_OPEN":
      return { ...state, showPreApprovalsModal: action.payload };
    case "UPDATE_PREAPPROVALS_OPTIMISTIC":
      return { ...state, orgPreApprovals: action.payload.preApprovedContacts };
    case "UPDATE_DASHBOARD_TAB":
      return { ...state, dashboardTab: action.payload };
    case "UPDATE_PREAPPROVALS_ORGS_ID":
      return { ...state, preApprovalsOrgId: action.payload.orgId };
    case "UPDATE_NUM_INVITE_FORMS":
      return { ...state, numInviteForms: action.payload };
    case "UPDATE_DASHBOARD_NAME":
      return { ...state, dashboardName: action.payload };
    case "SET_VAULT_WELCOME_MODAL_SHOWN":
      return {
        ...state,
        vault: { ...state.vault, welcomeModalShown: action.payload },
      };
    case "SET_VAULT_PRESELECTED_ORG_ID":
      return {
        ...state,
        vault: { ...state.vault, preselectedOrgId: action.payload },
      };
    case "SET_VAULT_REFERRER_PATH":
      return {
        ...state,
        vault: { ...state.vault, referrerPath: action.payload },
      };
    case "SET_VAULT_SUCCESS_MODAL_SHOW":
      return {
        ...state,
        vault: { ...state.vault, sucessModalShow: action.payload },
      };
    case "ADD_FINANCIAL_USERS_INDEX":
      let financialUsersIndex = {...state.userAssociationsFinancialUsersIndex};
      financialUsersIndex[action.payload.orgId] = action.payload.financialUsers;
      return {...state, userAssociationsFinancialUsersIndex: financialUsersIndex};
    case "RESET_FINANCIAL_USERS_INDEX":
      return {...state, userAssociationsFinancialUsersIndex: {}};
    case "ADD_VAULT_STATUS_INDEX":
      let vaultStatusIndex = {...state.userAssociationsVaultStatusIndex};
      vaultStatusIndex[action.payload.orgId] = action.payload.vaultStatus;
      return {...state, userAssociationsVaultStatusIndex: vaultStatusIndex};
    case "RESET_VAULT_STATUS_INDEX":
      return {...state, userAssociationsVaultStatusIndex: {}};
    default:
      return state;
  }
};

const AppGlobalState: React.FC<AppGlobalStateProps> = ({ children }) => {
  const [appState, dispatch] = useReducer(reducer, initialState);

  const windowSize: WindowSize = useWindowSize();
  const { data: user } = usePermissionsQuery();
  const { data: userConfig, loading: userConfigLoading } =
    useUserInsightsConfigurationsQuery();

  const onOrgsDataLoaded = (
    orgsIndex: OrgsIndex,
    orgsTree: OrgsTree,
    dashboardsIndex: string[],
    campaignsReverseIndex: CampaignsReverseIndex,
    userAssociations: Org[],
    financialAccIndex: Record<string, FinancialAcctOrg[]>
  ) => {
    const userAssociationsIndex = userAssociations.reduce(
      (mem, userAssociation) => {
        return { ...mem, [userAssociation.id]: userAssociation };
      },
      {}
    );

    dispatch({
      type: "UPDATE_ORGS",
      payload: {
        orgsIndex: orgsIndex,
        orgsTree: orgsTree,
        dashboardsIndex: dashboardsIndex,
        campaignsReverseIndex: campaignsReverseIndex,
        orgsLoading: false,
        userAssociationsIndex,
        userAssociationsFinancialAccIndex: financialAccIndex,
      },
    });
  };

  useEffect(() => {
    if (appState.windowSize.screen !== windowSize.screen) {
      dispatch({
        type: "UPDATE_WINDOW_SIZE",
        payload: { screen: windowSize.screen || "" },
      });
    }
  }, [windowSize, appState]);

  useEffect(() => {
    if (userConfig && !userConfigLoading) {
      let initialMap:Record<string, string> = {};
      const configMap = userConfig.userInsightsConfigurations.reduce((map, config) => {
        map[config?.name] = config?.value;
        return map;
      }, initialMap);
      dispatch({
        type: "SET_USER_CONFIG",
        payload: configMap,
      });
    }
  }, [userConfig, userConfigLoading]);

  return (
    <AppContext.Provider value={{ appState, dispatch }}>
      <OrgsDataLoader
        userId={user?.me?.id}
        onOrgsDataLoaded={onOrgsDataLoaded}
      />
      {children}
    </AppContext.Provider>
  );
};

export default AppGlobalState;
