export const FundraiserTypes = {
  SNAP: "Snap! Raise Fundraiser",
  ONLINE: "Online",
  PRODUCT: "Product",
  EVENTS: "Events",
  ATHON: "A-thon",
  OTHER: "Other",
};

export const ORG_TYPES = {
  DISTRICT: "District",
  SCHOOL: "School",
  SHELLSCHOOL: "ShellSchool",
  PTA: "PTA",
  BOOSTER: "Booster",
  FOUNDATION: "Foundation",
  BUSINESS: "Business",
  CLUB: "Club",
  PROGRAM: "Program",
};

export const RAISE_PRICE_AND_FEES_COPY =
  "There is no initial start-up fee to begin a Snap! Raise campaign, so nothing comes out of pocket to start raising funds. Instead, we charge a percentage fee of the overall funds raised after the close of your campaign. Organizations that we partner with keep 80% of the total dollars raised.";
export const RAISE_HOW_IT_WORKS_COPY =
  "Snap! Raise utilizes tried and true technology to invite supporters to donate to your program while you, as a Group Leader, have full insight into your campaign's progress. Our secure Payment Processor collects all funds for safekeeping until your program is paid by check or Direct Deposit while protecting you and keeping everyone's data secure. Plus, you have full support from your direct Fundraising Specialist and our Customer Support team along the way.";
export const FUNDRAISER_DURATION = "28 days";
export const FUNDRAISER_SUPPORT_INFO = "You can reach out to us directly at support@snapraise.com or by phone at 276-531-9260.";
export const SAFETY_AND_SECURITY_INFORMATION_COPY =
  "We do not sell, or otherwise transfer to outside parties your personally identifiable information. No user information will be sold or shared in any way to any third party website for any reason whatsoever. We are in compliance with the requirements of COPPA(Children’s Online Privacy Protection Act).We do not collect any information from anyone under 13 years of age.Our website, products and services are all directed to people who are at least 13 years old or older. As a limited service - provider, Snap! Mobile, Inc.complies with all relevant provisions of the CCPA."
export const NEW_FUNDRAISER_SERVICE_URL = "http://www.snapraise.com";

export enum FORM_STEPS {
  initialStep = 0,
  formTypeSelection,
  snapFundraiserSelection,
  formDetails,
}

export enum PAGE_ERRORS {
  invalidSubmissionAccess = "invalid-access",
  invalidToken = "invalid-token",
}

export const MAX_GROUP_SIZE = 9999;
export const MIN_GROUP_SIZE = 1;
export const MAX_ESTIMATED_TOTAL_RAISED = 999999;
export const MIN_ESTIMATED_TOTAL_RAISED = 1;
export const MIN_REASON_LENGTH = 1;

export const DEFAULT_SUBMISSION_PROPERTIES = {
  additionalNotes: "",
  campaignRaiseId: null,
  customerSupportInfo: "",
  duration: "",
  estimatedTotalRaisedCents: null,
  formType: null,
  fundraiserApprovalSubmissionDocs: [],
  fundraiserApprovers: [],
  fundraiserServiceWebsite: "",
  groupSize: null,
  howThisWorks: "",
  latestRevision: true,
  newFundraiser: false,
  organizationId: "",
  previousRevision: null,
  pricingAndFees: "",
  programId: "",
  proposedStartDate: new Date(),
  reason: "",
  revisionRequest: "",
  safety: "",
  status: "DRAFT",
  submitterId: "",
}

export const APPROVER_ROLES = {
  athleticDirector: "Athletic Director",
  activitiesDirector: "Activities Director",
  coach: "Coach",
  assistantCoach: "Assistant Coach",
  administrativeAssistant: "Administrative Assistant",
  financialAdministrator: "Financial Administrator",
  schoolAdministrator: "School Administrator",
  boosterClubLeader: "Booster Club Leader",
  boosterClubMember: "Booster Club Member",
  fineArtsDirector: "Fine Arts Director",
  president: "President",
  districtAdministrator: "District Administrator",
  stateAdministrator: "State Administrator",
  businessOwner: "Business Owner",
  sponsor: "Sponsor",
  clubSportEmployee: "Club Sport Employee",
  parent: "Parent",
  volunteer: "Volunteer",
  other: "Other",
  student: "Student",
  teacherInstructor: "Teacher/Instructor",
  schoolClubAdvisor: "School Club Advisor",
  clubSportsDirector: "Club Sports Director",
  clubSportsAdministrator: "Club Sports Administrator",
};

export const NEW_APPROVER_KEY = "newApprover";

export const INSIGHTS_LEAD_GEN_FORM_URL = "https://snapraise.com/insights/#form";

export const FILE_VISUALIZER_MODE = {
  edit: "EDIT",
  visualize: "VISUALIZE",
} as const;

type FileVisualizerModeKeys  = keyof typeof FILE_VISUALIZER_MODE;
export type FileVisualizerMode = typeof FILE_VISUALIZER_MODE[FileVisualizerModeKeys];
