import useTreatment from "./useTreatment";

export default function useOnOffTreatment(treatmentName:string) {
  const treatment = useTreatment(treatmentName);
  const treatmentOn = treatment === "on";
  return {
    treatmentOn: treatmentOn, 
    treatmentOff: !treatmentOn,
  };
}
