import { SnapIcon } from "../suit";

interface ApprovalContactFooterProps {
  testId: string;
  className?: string;
}

const ApprovalContactFooter: React.FC<ApprovalContactFooterProps>  = ({testId, className = ""}) => {
  return (
    <div
      data-testid={testId}
      className={`${className}`}
    >
      <p className="text-base text-gray-800 font-normal mb-2">Reach out to the Snap! support team to get additional assistance with approving your fundraiser.</p>
      <a
        rel="noreferrer"
        target="_blank"
        href="mailto:support@snapraise.com"
        className="text-base text-blue-700 font-bold flex"
      >
        <SnapIcon icon="mail-solid" size="xs" className="text-blue-700" />
        <span className="ml-2">support@snapraise.com</span>
      </a>
      <p className="text-base text-blue-700 font-bold mt-2 flex">
        <SnapIcon icon="phone-solid" size="xs" className="text-blue-700" />
        <span className="ml-2">(206) 285-0906</span>
      </p>
    </div>
  );
};

export default ApprovalContactFooter;
