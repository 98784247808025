/*eslint-disable*/
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppGlobalState";
import { isScreenSizeBelow } from "../../utils/UIUtils";
import WhiteCardWithTitle from "../WhiteCardWithTitle";
import PendingActionItem from "./PendingActionItem";
import ContactModal from "../modals/ContactModal";
import {
  InsCampaignStat,
  InsDonorParticipantContact,
  useInsightsDonorParticipantContactsLazyQuery,
  useMeQuery,
} from "../../graphql/generated";
import * as fullstory from "@fullstory/browser";
import { PendingAction } from "./PendingActionTypes";
import { ContactInformation } from "../ContactTypes";
import PreApprovalsModal from "../modals/PreApprovalsModal";
import { LoadingData } from "../loading-empty-state/LoadingData";
import { useNavigate, useParams } from "react-router-dom";
import { getOrgById } from "../../data-helpers/OrgsDataHelper";
import useOnOffTreatment from "../../hooks/useOnOffTreatment";
import { SplitFeatureFlags } from "../../constants/splitFeatureFlags";
import { getOrgVaultStatus } from "../../vault/vault-data-helper";
import { ORG_VAULT_STATUS } from "../../vault/constants";
interface ActionCenterProps {
  campaigns: InsCampaignStat[];
  className?: string;
}

const createDonorContactsList = (
  donorParticipantContacts: InsDonorParticipantContact[] | undefined
): ContactInformation[] | undefined => {
  return (
    donorParticipantContacts &&
    donorParticipantContacts.map((contact, index) => {
      return {
        id: index,
        image: "---",
        name: contact?.donor_name,
        email: contact?.donor_email,
        program: "---",
      } as ContactInformation;
    })
  );
};

const ActionCenter: React.FC<ActionCenterProps> = ({
  campaigns,
  className = "",
}) => {
  const { appState, dispatch } = useContext(AppContext);
  const { 
    userAssociationsFinancialAccIndex,
    userAssociationsFinancialUsersIndex,
    userAssociationsVaultStatusIndex
  } = appState;
  const { treatmentOn: vaultTreatmentOn } = useOnOffTreatment(SplitFeatureFlags.INS_818_vault_integration);
  const navigate = useNavigate();
  const { data: user } = useMeQuery();
  const { dashboardId } = useParams();
  const [showContactModal, setShowContactModal] = useState(false);
  const [showPreApprovalsModal, setShowPreApprovalsModal] = useState(false);
  const [recentlyClosedCampaigns, setRecentlyClosedCampaigns] = useState<
    InsCampaignStat[]
  >([]);
  const [modalTitle, setModalTitle] = useState<string>("");
  const [modalDescription, setModalDescription] = useState<string>("");
  const [modalSubject, setModalSubject] = useState<string>("");
  const [modalMessage, setModalMessage] = useState<string>("");
  const [fetchContacts, { data: contactsData }] =
    useInsightsDonorParticipantContactsLazyQuery({
      variables: { campaignIds: [] },
    });
  if(!dashboardId || !user?.me?.id) {
    return null;
  }
  const org = getOrgById(appState, dashboardId);
  const vaultOrgStatus:ORG_VAULT_STATUS = getOrgVaultStatus(userAssociationsFinancialAccIndex, userAssociationsFinancialUsersIndex, userAssociationsVaultStatusIndex, dashboardId, user.me.id);
  const showVaultActionGetStarted:boolean = vaultTreatmentOn && vaultOrgStatus === ORG_VAULT_STATUS.start;
  const showVaultActionDocUpload:boolean = vaultTreatmentOn && vaultOrgStatus === ORG_VAULT_STATUS.uploadDocuments;

  useEffect(() => {
    if (campaigns) {
      const today = new Date();
      const recentDate = new Date(new Date().setDate(today.getDate() - 15));
      setRecentlyClosedCampaigns(
        campaigns.filter((campaign) => {
          const endDate = new Date(campaign.end_date.slice(0, -5));
          return (
            campaign.insights_status === "closed" &&
            recentDate <= endDate &&
            endDate <= today
          );
        })
      );
    }
  }, [campaigns]);

  const openContactModalForRecentlyClosedCampaigns = () => {
    setModalTitle("Recently Closed Campaigns");
    setModalDescription(
      `${recentlyClosedCampaigns.length} campaigns finished in the last 15 days....[needs copy]`
    );
    setModalSubject("Subject....[needs copy]");
    setModalMessage("Message....[needs copy]");

    // REAL LINE
    //fetchContacts({variables:{campaignIds: recentlyClosedCampaigns.map(c => { return c.id })}});

    // SOME CAMPAIGN IDS WITH DATA IN DEV
    fetchContacts({ variables: { campaignIds: [69813, 69812] } });

    setShowContactModal(true);
  };

  const openPreApprovalsModal = () => {
    fullstory.event("Button Clicked", {
      buttonText: "Send Pre-Approval Invites",
      url: `https://insights.snap.app/dashboards/${dashboardId}/overview`, //TODO: remove hardcoded url
      path: `/dashboards/${dashboardId}`,
      moduleId: "Send Pre-Approval Invites",
      value: null,
    });
    setModalTitle("Send Pre-Approval Invites");
    setTimeout(() =>{
      dispatch({
        type: "SET_PREAPPROVALS_MODAL_OPEN",
        payload: true
      })
    } );
  };

  const vaultItemCallback = () => {
    dispatch({
      type: "SET_VAULT_PRESELECTED_ORG_ID",
      payload: dashboardId,
    }); 
    dispatch({
      type: "SET_VAULT_REFERRER_PATH",
      payload: `/dashboards/${dashboardId}/overview`,
    }); 
    navigate("/vault");
  };

  const getPreApprovalsAction = (): PendingAction => {
    return {
      id: 1,
      icon: "clipboard-check-solid",
      color: "blue",
      name: "Send Pre-Approvals",
      description: "Pre-approve group leaders in your org to run a fundraiser",
      cta: "Send Pre-Approval Invites",
    };
  };

  const getVaultGetStartedAction = (): PendingAction => {
    return {
      id: 2,
      icon: "shield-check-solid",
      color: "blue",
      name: "Snap! Vault",
      description: "Securely save your financial information across all Snap! products.",
      cta: "Get Started",
    };
  };

  const getVaultDocumentsAction = (): PendingAction => {
    return {
      id: 3,
      icon: "shield-check-solid",
      color: "yellow",
      name: "Snap! Vault",
      description: "Snap! Vault requires additional verification for your financial profile.",
      cta: "Upload Documents",
    };
  };

  return (
    <WhiteCardWithTitle
      title="Actions Center"
      fullPageWidth={isScreenSizeBelow(appState.windowSize.screen, "sm")}
      className={`${className}`}
    >
      <ContactModal
        showModal={showContactModal}
        onClose={() => setShowContactModal(false)}
        title={modalTitle}
        description={modalDescription}
        subject={modalSubject}
        message={modalMessage}
        contacts={createDonorContactsList(
          contactsData?.insightsDonorParticipantContacts as InsDonorParticipantContact[]
        )}
      ></ContactModal>
      {org
        ?
        <>
          { showVaultActionGetStarted &&
            <PendingActionItem
              action={getVaultGetStartedAction()}
              ctaCallback={vaultItemCallback}
            />
          }
          { showVaultActionDocUpload &&
            <PendingActionItem
              action={getVaultDocumentsAction()}
              ctaCallback={vaultItemCallback}
            />
          }
          <PendingActionItem
            action={getPreApprovalsAction()}
            ctaCallback={openPreApprovalsModal}
          />
          <PreApprovalsModal
            org={org}
            showModal={appState.showPreApprovalsModal}
            onClose={() => dispatch({
              type: "SET_PREAPPROVALS_MODAL_OPEN",
              payload: false
            })}
            defaultTab="add"
          ></PreApprovalsModal>
        </>
        :
        <LoadingData />
      }
    </WhiteCardWithTitle>
  );
};

export default ActionCenter;
