
import noDonationData from '../../assets/NoDonationData.png';
import mapPlaceholder from '../../assets/MapPlaceholder.png';

interface NoDonationDataProps {
    unableToLoad?: boolean;
  }

export const NoDonationData: React.FC<NoDonationDataProps> = ({ unableToLoad = false }) => {
    return (
        <div className='flex justify-center bg-contain bg-no-repeat bg-center h-full' style={{backgroundImage: `url(${mapPlaceholder})`, height: 384}}>
            <div className='flex flex-col justify-center items-center w-96 h-full'>
                { unableToLoad
                ?
                    <p className='bg-slate-100 p-2.5 rounded-md font-bold'>Donation data cannot be displayed</p>
                :
                    <img src={noDonationData} alt="No results" className='w-3/4'/>
                }
            </div>
        </div>
    )
}
