import React, { useCallback, useContext, useEffect, useState } from 'react';
import DropFileBox from '../layout/drop-file-box';
import FileListVisualizer from '../layout/file-list-visualizer';
import useS3FileUpload from '../hooks/use-s3-file-upload';
import uploadFile, { FILE_STATUS, UploadResponse } from '../utils/upload-file';
import { S3PresignedInsightsApprovalsUrlMutation, useCompleteFundraiserApprovalSubmissionDocumentUploadMutation, useDeleteFundraiserApprovalSubmissionDocumentMutation, useS3PresignedInsightsApprovalsUrlMutation } from '../graphql/generated';
import { ApprovalsContext } from '../ApprovalsGlobalState';
import notEmpty from '../utils/not-empty';
import { filterFilesToUpload, getAvailableFileName, getFilesNameExtension, getS3FileDataHash, getUpdatedFileStatus, removeDocFromDocList } from './contracts-and-documents-helper';
import { updateDraftLocally } from './approvals-data-helpers';

interface ContractsAndDocumentsProps {
  testId: string;
  className?: string;
}
export interface FileStatus {
  file: File;
  progress: number;
  status: FILE_STATUS;
  s3Url: string;
  s3Filename: string;
}
export interface FileStatusMap {
  [key: string]: FileStatus;
}

const ContractsAndDocuments: React.FC<ContractsAndDocumentsProps> = ({ testId, className = "" }) => {

  const { appState, dispatch } = useContext(ApprovalsContext);
  const { fundraiserApprovalForm: fundraiserApprovalFormState } = appState;
  const {
    editableSubmission,
  } = fundraiserApprovalFormState;
  const {
    handleUpload,
    uploadProgress
  } = useS3FileUpload(uploadFile, false)

  const [fileStatus, setFileStatus] = useState<FileStatusMap>({})
  const [deleteS3File] = useDeleteFundraiserApprovalSubmissionDocumentMutation();
  const [completeUploadProcess] = useCompleteFundraiserApprovalSubmissionDocumentUploadMutation();
  const [getS3PresignedUrls] = useS3PresignedInsightsApprovalsUrlMutation()

  const updateFileStatus = useCallback((fileInfo: PromiseSettledResult<UploadResponse>[], S3FileData: S3PresignedInsightsApprovalsUrlMutation) => {
    const S3FIleHash = getS3FileDataHash(S3FileData);
    if (!S3FIleHash) return;
    const updatedFileStatus = getUpdatedFileStatus(fileInfo, fileStatus, S3FIleHash)
    setFileStatus(updatedFileStatus);
  }, [fileStatus])

  useEffect(() => {
    const filesToUpload = filterFilesToUpload(fileStatus, FILE_STATUS.inProgress)
    if (!filesToUpload.length) return;

    const getSignedUrlsAndUploadFiles = async () => {
      const s3FileUrlData = await getS3PresignedUrls({
        variables: {
          files: filesToUpload.map((file) => file.name),
          approvalsFormId: editableSubmission.formId,
          approvalsSubmissionId: editableSubmission.id
        }
      });
      if (!s3FileUrlData.data) return;
      const urls = s3FileUrlData.data.s3PresignedInsightsApprovalsUrl.map((s3FileData) => s3FileData.url);
      const uploadedFilesPromiseResponse = await handleUpload(filesToUpload, urls);

      const completeUploadPromises = uploadedFilesPromiseResponse.map((response) => {
        if (response.status === "fulfilled" && s3FileUrlData.data) {
          return completeUploadProcess({
            variables: {
              approvalSubmissionId: editableSubmission.id,
              s3FileName: s3FileUrlData.data.s3PresignedInsightsApprovalsUrl.filter((s3File) => s3File.filename === response.value.file.name)[0].s3Filename
            }
          })
        }
        return Promise.resolve();
      })
      await Promise.allSettled(completeUploadPromises);
      updateFileStatus(uploadedFilesPromiseResponse, s3FileUrlData.data)
    }
    getSignedUrlsAndUploadFiles();

  }, [fileStatus, updateFileStatus, handleUpload, getS3PresignedUrls, editableSubmission.formId, editableSubmission.id, completeUploadProcess])

  const appendFiles = (files: File[]) => {
    try {
      const updatedFileStatus = files.reduce((mem, file, idx) => {
        const fileName = getAvailableFileName(file, fileStatus);
        const currentFile = new File([file], fileName, { type: file.type, lastModified: file.lastModified, });
        return {
          ...mem,
          [currentFile.name]: {
            file: currentFile,
            status: FILE_STATUS.inProgress,
            progress: 0,
            s3Url: "",
            s3Filename: ""
          }
        }
      }, { ...fileStatus });
      setFileStatus(updatedFileStatus);
    } catch (error) {
      alert(error)
    }
  }

  const removeFile = useCallback(async (fileName: string, status: FILE_STATUS) => {
    const [{ name }] = getFilesNameExtension([fileName]);
    const noEmptySubmissionDocs = editableSubmission.fundraiserApprovalSubmissionDocs.filter(notEmpty);
    const [existingDoc] = noEmptySubmissionDocs.filter((doc) => doc.filename === name)
    const updatedFileStatus = { ...fileStatus };
    let fileKey = (existingDoc && status === FILE_STATUS.previousUpload) ?
      existingDoc.s3Filename : updatedFileStatus[fileName].s3Filename;

    if (status === FILE_STATUS.error || status === FILE_STATUS.inProgress) {
      delete updatedFileStatus[fileName];
      setFileStatus(updatedFileStatus);
      return;
    }
    const deleteResponse = await deleteS3File({
      variables: {
        documentKey: fileKey,
        approvalSubmissionId: editableSubmission.id
      }
    })
    if (deleteResponse.errors) return;
    if (status === FILE_STATUS.previousUpload) {
      updateDraftLocally("fundraiserApprovalSubmissionDocs", removeDocFromDocList(editableSubmission, fileKey), dispatch)
      return;
    }
    delete updatedFileStatus[fileName];
    setFileStatus(updatedFileStatus)
  }, [deleteS3File, dispatch, editableSubmission, fileStatus])

  const allFiles = Object.values(fileStatus).map((singleFileStatus) => singleFileStatus.file);
  return (
    <div data-testid={testId} className={`${className}`}>
      <p className="text-gray-700 text-sm font-medium pb-1">Contracts & Documents</p>
      <DropFileBox
        testId={testId}
        id="drop-documents"
        fileFormats={["png", "jpg", "pdf", "doc", "docx"]}
        maxFileSizeMB={5}
        className="mb-2"
        onFilesReceived={files => {
          appendFiles(files);
        }}
      />
      {!!allFiles.length || editableSubmission.fundraiserApprovalSubmissionDocs.length > 0 ?
        <>
          <div className="w-full h-px bg-gray-200 mb-2"></div>
          <p className="text-gray-700 text-base font-medium pb-2">Uploaded Documents</p>
          <FileListVisualizer
            testId="uploaded-files"
            files={allFiles}
            fileStatusMap={fileStatus}
            progress={uploadProgress}
            onFileDelete={removeFile}
            existingFiles={editableSubmission.fundraiserApprovalSubmissionDocs.filter(notEmpty)}
          />
        </>
        :
        null
      }
    </div>
  );
};

export default ContractsAndDocuments;
