import { useEffect, useMemo } from "react";
import { GroupLeaderCampaign, UserPublic, useGroupLeaderCampaignIdsLazyQuery, useInsightsUserAssociationIdsLazyQuery, useMeQuery, useOrgHierarchyLazyQuery, useUserPublicInfoLazyQuery } from '../graphql/generated';
import { intersection } from "underscore";

const useGroupLeaderAssociatedSchoolMembers = (schoolId:string|null) => {
  const { data: userData } = useMeQuery();
  const userId = userData?.me?.id;
  const [fetchCampaignIds, { data: groupLeaderCampaignIds, loading: groupLeaderCampaignIdsLoading }] = useGroupLeaderCampaignIdsLazyQuery();
  const [fetchInsightsUserAssociationIds, { data: userAssociationIds, loading: userAssociationIdsLoading }] = useInsightsUserAssociationIdsLazyQuery();
  const [fetchUserPublicInfo, { data: usersPublicInfo, loading: usersPublicInfoLoading }] = useUserPublicInfoLazyQuery();
  const [fetchOrgHierarchy, { data: orgHierarchy, loading: orgHierarchyLoading }] = useOrgHierarchyLazyQuery();

  const campaignIds:number[] = useMemo(
    () => {
      if (!groupLeaderCampaignIds || groupLeaderCampaignIdsLoading)
        return []
      return (groupLeaderCampaignIds.campaignMemberships as GroupLeaderCampaign[]).map(campaign => parseInt(campaign.id, 10)).filter(campaignId => !Number.isNaN(campaignId));
    },
    [groupLeaderCampaignIds, groupLeaderCampaignIdsLoading]
  );

  const schoolCampaignIds:number[] = useMemo(
    () => {
      if (!orgHierarchy || !orgHierarchy.org || orgHierarchyLoading)
        return []
      return orgHierarchy.org.filter(org => org?.type === "Campaign").map(campaign => campaign?.fields._deprecated_raise_id).filter(campaignId => !Number.isNaN(campaignId));
    },
    [orgHierarchy, orgHierarchyLoading]
  );

  const matchedCampaignIds:number[] = useMemo(
    () => intersection(schoolCampaignIds, campaignIds),
    [campaignIds, schoolCampaignIds]
  );

  const schoolMemberIds:string[] = useMemo(
    () => {
      if (!userAssociationIds || userAssociationIdsLoading)
        return [];
      return userAssociationIds.orgUserAssociations.map(user => user ? user.id : "").filter(id => id);
    },
    [userAssociationIds, userAssociationIdsLoading]
  );

  const schoolMembers:UserPublic[] = useMemo(
    () => {
      if (matchedCampaignIds.length === 0)
        return [];
      if (!usersPublicInfo || usersPublicInfoLoading)
        return [];
      const members:UserPublic[] = usersPublicInfo.userPublicInfo?.reduce((filtered:UserPublic[], userInfo) => {
        if (userInfo?.user && userInfo?.user.id !== userId) {
           filtered.push(userInfo?.user);
        }
        return filtered;
      }, []) || [];
      return members;
    },
    [usersPublicInfo, usersPublicInfoLoading, matchedCampaignIds, userId]
  );

  useEffect(() => {
    if (userId) {
      fetchCampaignIds();
    }
  }, [userId, fetchCampaignIds]);

  useEffect(() => {
    if (schoolId) {
      fetchOrgHierarchy({variables: {orgId: schoolId}});
    }
  }, [schoolId, fetchOrgHierarchy]);

  useEffect(() => {
    if (matchedCampaignIds.length > 0 && schoolId) {
      fetchInsightsUserAssociationIds({variables: {orgId: schoolId}});
    }
  }, [matchedCampaignIds, schoolId, fetchInsightsUserAssociationIds]);

  useEffect(() => {
    if (schoolMemberIds.length > 0) {
      fetchUserPublicInfo({variables: {userPublicInfoId: schoolMemberIds}});
    }
  }, [schoolMemberIds, fetchUserPublicInfo]);

  return schoolMembers;
}

export default useGroupLeaderAssociatedSchoolMembers;
