import { useContext, useEffect } from "react";
import SnapFundraiserSelector from "./snap-fundraiser-selector";
import { ApprovalsContext } from "../ApprovalsGlobalState";
import { isScreenSizeBelow } from "../utils/UIUtils";
import WhiteCard from "../components/WhiteCard";
import FundraiserTypeSelector from "./fundraiser-type-selector";
import {
  useEditFundraiserApprovalSubmissionMutation,
  useInsightsApprovalSubmissionsLazyQuery,
  useMeQuery,
} from "../graphql/generated";
import { useParams } from "react-router-dom";
import {
  approversSyncRequired,
  buildApproversUpdateObject,
  getFormStepFromSubmissionStatus,
  processQueryLatestSubmissionMutationResponse,
  updateDraftLocally,
} from "./approvals-data-helpers";
import { PAGE_ERRORS } from "./constants";
import ErrorPage from "./error-page";
import FundraiserDetails from "./fundraiser-details";
import { SnapSelectMenu } from "../suit";

const ApprovalSubmission = () => {
  const { appState, dispatch } = useContext(ApprovalsContext);
  const { data: user } = useMeQuery();
  const { currentSubmission, editing, fundraiserApprovalForm: { editableSubmission, resetting, requiresApiSync } } = appState;
  const [fetchApprovalSubmission] = useInsightsApprovalSubmissionsLazyQuery();
  const { formId } = useParams();
  const [editFundraiserApprovalSubmission] = useEditFundraiserApprovalSubmissionMutation();

  useEffect(() => {
    if (user?.me?.id && formId) {
      fetchApprovalSubmission({
        variables: {
          submitterId: user.me.id,
          latest: true,
          formId: parseInt(formId, 10),
        },
      }).then((response) => {
        const { submission } =
          processQueryLatestSubmissionMutationResponse(response);

        if (!submission) {
          dispatch({
            type: "FUNDRAISER_APPROVALS_SUBMISSION_INVALID_ACCESS",
            payload: { error: PAGE_ERRORS.invalidSubmissionAccess },
          });
          return;
        }
        dispatch({
          type: "FUNDRAISER_CURRENT_APPROVAL_SUBMISSION_UPDATED",
          payload: { submission },
        });
      });
    }

  }, [user, formId, fetchApprovalSubmission, dispatch]);

  useEffect(() => {
    if (currentSubmission) {
      const step = getFormStepFromSubmissionStatus(currentSubmission);
      dispatch({
        type: "FUNDRAISER_EDITABLE_APPROVAL_SUBMISSION_UPDATED",
        payload: { fields: { ...currentSubmission }, formStep: step },
      });
    }
  }, [currentSubmission, dispatch]);

  useEffect(() => {
    const editSubmission = async () => {
      const { data, errors } = await editFundraiserApprovalSubmission({
        variables: {
          ...editableSubmission,
          fundraiserApprovalSubmissionDocs: [],
          fundraiserApprovers: buildApproversUpdateObject(editableSubmission.fundraiserApprovers, resetting),
        }
      });
      const newSubmission = data?.editFundraiserApprovalSubmission.fundraiserApprovalSubmission;
      const newApprovers = newSubmission ? newSubmission.fundraiserApprovers : [];
      if (resetting) {
        dispatch({
          type: "FUNDRAISER_EDITABLE_APPROVAL_SUBMISSION_SYNCED",
          payload: {},
        });
      }
      if ((errors && errors.length > 0) || !approversSyncRequired(editableSubmission, newApprovers)) {
        return;
      }
      updateDraftLocally("fundraiserApprovers", newApprovers, dispatch);
    };
    if (requiresApiSync) {
      editSubmission();
    }
  }, [editFundraiserApprovalSubmission, dispatch, editableSubmission, resetting, requiresApiSync]);

  if (appState.pageError) return <ErrorPage />
  return (
    <WhiteCard
      fullPageWidth={isScreenSizeBelow(appState.windowSize.screen, "md")}
      borderRadius="large"
      className={"lg:w-[55%] mx-auto max-w-5xl"}
      paddingX="px-6"
    >
      <h1 className=" pb-4 font-semibold text-2xl">
        Fundraiser Approval Request
      </h1>
      {!editing ?
        <>
          <FundraiserTypeSelector />
          <SnapFundraiserSelector />
        </>
        :
        <SnapSelectMenu
          error={false}
          helpText={""}
          id="history"
          modalTitle="History"
          selectMenuOptions={[{
            name: "Revision History",
            value: "close",
            selected: true
          }]}
          onSnap-select-menu-updated={() => null}
          label=""
          required
          className="flex-1 mb-3"
        />
      }

      <FundraiserDetails />
    </WhiteCard>
  );
};
export default ApprovalSubmission;
