
import { useContext, useEffect, useMemo, useState } from "react";
import { isScreenSizeBelow } from "../utils/UIUtils";
import { ApprovalsContext } from "../ApprovalsGlobalState";
import { SnapIcon, SnapInput } from "../suit";
import DropPanel from "../layout/drop-panel";
import Modal from "../layout/modal";
import { ProgramActivities, programActivitiesAsArray } from "./program-activities";

const programs = programActivitiesAsArray();

interface ProgramSearchSelectorProps {
  fieldId: string;
  testId: string;
  error: string;
  programId?: string;
  onProgramSelected?: (programKey:string, programName:string) => void;
  className?: string;
}

const ProgramSearchSelector: React.FC<ProgramSearchSelectorProps> = ({ fieldId, testId, error, programId, onProgramSelected, className = "" }) => {
  const { appState:{windowSize} } = useContext(ApprovalsContext);
  const[searchFocused, setSearchFocused] = useState<boolean>(false);
  const[optionsModalOpen, setOptionsModalOpen] = useState<boolean>(false);
  const[resetSearch, setResetSearch] = useState<boolean>(true);
  const[searchTerm, setSearchTerm] = useState<string>("");
  const[selectedProgramKey, setSelectedProgramKey] = useState<string>("");

  const filteredPrograms:{key: string,text: ProgramActivities }[] = useMemo(
    () => {
      if (searchTerm.length === 0 || resetSearch)
        return programs;
      return programs.filter(program => {
        return program.key === 'OTHER' || program.text.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0;
      });
    },
    [searchTerm, resetSearch]
  );

  useEffect(() => {
    if (!!programId && programId !== selectedProgramKey) {
      setSelectedProgramKey(programId);
      setSearchTerm(programs.find(p => p.key === programId)?.text || "");
      setResetSearch(true);
    }
  }, [programId, selectedProgramKey]);

  const handleSearchFieldFocus = () => {
    if(isScreenSizeBelow(windowSize.screen, "sm")) {
      setOptionsModalOpen(true);
      return;
    }
    setSearchFocused(true);
  };

  const selectOrgSearchItem = (programKey:string, programName:string) => {
    setResetSearch(true);
    setSearchTerm(programName);
    setSelectedProgramKey(programKey);
    if(isScreenSizeBelow(windowSize.screen, "sm")) {
      setOptionsModalOpen(false);
    }
    if (onProgramSelected) {
      onProgramSelected(programKey, programName);
    }
  };

  const renderProgramActivities = () => {
    return filteredPrograms.map((program, index:number) => {
      return (
        <div
          key={index}
          className="py-2 hover:bg-gray-50 cursor-pointer"
          onClick={() => { selectOrgSearchItem(program.key, program.text) }}
        >
          <span className="text-sm font-medium text-gray-800">{program.text}</span>
        </div>
      );
    });
  };

  const renderOptionsPanel = () => {
    if(isScreenSizeBelow(windowSize.screen, "sm")) {
      return (
        <Modal
          testId={testId}
          showModal={optionsModalOpen}
          onClose={() => { setOptionsModalOpen(false) }}
          className="w-full h-full top-0 bottom-0 overflow-y-auto"
        >
          <div className="flex flex-row justify-between items-center px-2 py-3 border-b border-gray-200">
            <span className="text-sm font-bold text-gray-800">Organization</span>
            <SnapIcon icon="x-solid" size="md" className="cursor-pointer text-gray-500" onClick={() => { setOptionsModalOpen(false) }} />
          </div>
          <div className="p-2">
            <SnapInput
              _id={fieldId + "-modal"}
              _type="text"
              placeholder="Type your organization name"
              value={searchTerm}
              onInput={(e) => { setSearchTerm((e.target as HTMLInputElement).value) }}
              onKeyDown={(e) => { if(resetSearch) {setResetSearch(false)} }}
            />
          </div>
          <div className="px-2">
            { renderProgramActivities() }
          </div>
        </Modal>
      );
    }
    return (
      <DropPanel
        testId={`${testId}-drop-panel`}
        showPanel={searchFocused}
        className="max-h-60 overflow-y-auto"
      >
        <div className="h-full">
          { renderProgramActivities() }
        </div>
      </DropPanel>
    );
  };

  return (
    <div data-testid={testId} className={`w-full relative ${className}`}>
      <SnapInput
        data-testid={`${testId}-input`}
        _id={fieldId}
        _type="text"
        name={fieldId}
        label="Program"
        required
        placeholder="Type program name"
        icon={searchFocused ? "chevron-up-solid" : "chevron-down-solid"}
        iconPosition="right"
        value={searchTerm}
        error={!!error}
        helpText={!!error ? error : ""}
        onInput={(e) => { setSearchTerm((e.target as HTMLInputElement).value) }}
        onKeyDown={(e) => { if(resetSearch) {setResetSearch(false)} }}
        onFocus={(e) => { handleSearchFieldFocus() }}
        onBlur={(e) => { setSearchFocused(false) }}
      />
      { renderOptionsPanel() }
    </div>
  );
};

export default ProgramSearchSelector;
