import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { SnapIcon } from "../suit";
import FullWidthPageHeader from "../components/FullWidthPageHeader";
import { Tabs, Tab } from "../components/Tabs";
import * as fullstory from "@fullstory/browser";
import { getPathnameParts } from "../utils/RouteUtils";
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../AppGlobalState";
import { Org, useMeQuery, useSetUserInsightsConfigurationsMutation } from "../graphql/generated";
import { getOrgById } from "../data-helpers/OrgsDataHelper";
import { LoadingData } from "../components/loading-empty-state/LoadingData";
import PortalOutlet from "../components/portal-outlet";
import { isScreenSizeBelow } from "../utils/UIUtils";
import VaultNotificationBanner from "../vault/vault-notification-banner";
import useOnOffTreatment from "../hooks/useOnOffTreatment";
import { SplitFeatureFlags } from "../constants/splitFeatureFlags";
import { getOrgVaultStatus, setShowSubmitSuccessModal } from "../vault/vault-data-helper";
import { ORG_VAULT_STATUS } from "../vault/constants";
import VaultMessageModal from "../vault/vault-message-modal";
import { ALLOWED_USER_CONFIGURATIONS, appendToUserConfigurationValue, getUserConfiguration, testUserConfigurationContains } from "../user-configurations/user-configurations-data-helper";

const VAULT_BANNER_PORTAL_MOBILE_ID = "vault-banner-portal-mobile";
const VAULT_BANNER_PORTAL_ID = "vault-banner-portal";

const DashboardPage = () => {
  const { appState, dispatch } = useContext(AppContext);
  const { 
    userAssociationsFinancialAccIndex,
    userAssociationsFinancialUsersIndex,
    userAssociationsVaultStatusIndex
  } = appState;
  const { treatmentOn: vaultTreatmentOn } = useOnOffTreatment(SplitFeatureFlags.INS_818_vault_integration);
  const navigate = useNavigate();
  const { data: user } = useMeQuery();
  const userId = user?.me?.id;
  const { dashboardId } = useParams();
  const state = useLocation().state;
  const dashboardName = appState?.dashboardName;
  const [org, setOrg] = useState<Org | null>(
    state && state.org ? state.org : null
  );
  const [orgResolved, setOrgResolved] = useState<boolean>(org ? true : false);
  const location = useLocation();
  const pathParts = getPathnameParts(location.pathname);
  const defaultSelected = pathParts.length >= 2 ? pathParts[2] : undefined;
  const overviewTabRef = useRef<HTMLButtonElement>(null);
  const preApprovalsTabRef = useRef<HTMLButtonElement>(null);
  const [vaultBannerPortalId, setVaultBannerPortalId] = useState<string>(VAULT_BANNER_PORTAL_ID);
  const [setUserInsightsConfiguration] = useSetUserInsightsConfigurationsMutation();

  useEffect(() => {
    const storedTab = sessionStorage.getItem("selectedTab");
    if (storedTab) {
      dispatch({
        type: "UPDATE_DASHBOARD_TAB",
        payload: storedTab,
      });
    }
  }, [dispatch]);

  useEffect(() => {
    if (!appState.orgsLoading) {
      if (!org && dashboardId) {
        setOrg(getOrgById(appState, dashboardId));
        setOrgResolved(true);
      }
      if (
        (Object.keys(appState.orgsIndex).length === 0 ||
          org === undefined ||
          org === null ||
          appState.orgsIndex[org.id] === undefined) &&
        orgResolved
      ) {
        navigate(`/dashboards`);
        //TODO: improve this by adding a toast message to inform user the page does not exist/is not accessible
      }
    }
  }, [org, orgResolved, appState]); //eslint-disable-line

  useEffect(() => {
    appState.dashboardTab === "Overview"
      ? overviewTabRef.current?.click()
      : preApprovalsTabRef.current?.click();
  }, [appState.dashboardTab]);

  useEffect(() => {
    if (!location.pathname.includes("/preapprovals")) {
      dispatch({
        type: "UPDATE_DASHBOARD_TAB",
        payload: "Overview",
      });
      sessionStorage.setItem("selectedTab", "Overview");
    } else {
      sessionStorage.setItem("selectedTab", "Pre-Approvals");
    }
  }, [location.pathname, dispatch]);

  useEffect(() => {
    let newPortalId = isScreenSizeBelow(appState.windowSize.screen, "md") ? VAULT_BANNER_PORTAL_MOBILE_ID : VAULT_BANNER_PORTAL_ID;
    if(newPortalId !== vaultBannerPortalId) {
      setVaultBannerPortalId(newPortalId);
    }
  }, [appState.windowSize.screen, vaultBannerPortalId]);

  const onTabChange = (id: string) => {
    fullstory.event("Navigation Clicked", {
      navLevel: "subnav",
      buttonText: id === "overview" ? "overview" : "preapproval",
      url: `https://insights.snap.app/dashboards/${dashboardId}`, //TODO remove hardcoded url
      path: `/dashboards/${dashboardId}`,
    });

    navigate(`${id}`, { state: { org: org } }); //TODO remove state transfer
  };

  if(!dashboardId || !userId) {
    return null;
  }
  
  const vaultOrgStatus:ORG_VAULT_STATUS = getOrgVaultStatus(userAssociationsFinancialAccIndex, userAssociationsFinancialUsersIndex, userAssociationsVaultStatusIndex, dashboardId, userId);
  const orgVaultApprovedAndDismissed = vaultOrgStatus === ORG_VAULT_STATUS.approved && testUserConfigurationContains(appState, ALLOWED_USER_CONFIGURATIONS.VAULT_ORG_APPROVED_DISMISS_BANNER, dashboardId);
  const showVaultBanner:boolean = vaultTreatmentOn && vaultOrgStatus !== ORG_VAULT_STATUS.unknown && !orgVaultApprovedAndDismissed;
  const showVaultSuccess: boolean = vaultTreatmentOn && appState.vault.sucessModalShow;
  
  const navigateToDashboardsList = () => {
    navigate(`/dashboards`);
  };

  const updateBannerStatusOnApprovedDismissed = () => {
    const bannerConfig = getUserConfiguration(appState, ALLOWED_USER_CONFIGURATIONS.VAULT_ORG_APPROVED_DISMISS_BANNER);
    const bannerConfigNewValue = appendToUserConfigurationValue(bannerConfig, dashboardId);
    //update user configs optimistic
    const userConfigs = {...appState.userConfigs}
    userConfigs[ALLOWED_USER_CONFIGURATIONS.VAULT_ORG_APPROVED_DISMISS_BANNER] = bannerConfigNewValue;
    dispatch({
      type: "SET_USER_CONFIG",
      payload: userConfigs,
    });
    setUserInsightsConfiguration({
      variables: {
        config: [
          {
            name: ALLOWED_USER_CONFIGURATIONS.VAULT_ORG_APPROVED_DISMISS_BANNER,
            value: bannerConfigNewValue,
          }
        ]
      }
    });
  };

  const onVaultBannerActionCalled = () => {
    if(vaultOrgStatus === ORG_VAULT_STATUS.approved) {
      updateBannerStatusOnApprovedDismissed();
      return;
    }
    dispatch({
      type: "SET_VAULT_PRESELECTED_ORG_ID",
      payload: dashboardId,
    });
    dispatch({
      type: "SET_VAULT_REFERRER_PATH",
      payload: `/dashboards/${dashboardId}/overview`,
    });
    navigate("/vault");
  };

  const navigateToPreApprovals = () => {
    dispatch({
      type: "SET_PREAPPROVALS_MODAL_OPEN",
      payload: true,
    });
    setTimeout(() => {
     navigate(`/dashboards/${dashboardId}/preapprovals`);
    });
  };


  return (
    <>
      <PortalOutlet
        id={VAULT_BANNER_PORTAL_MOBILE_ID}
      />
      <FullWidthPageHeader className="pt-4 px-4">
        <div className="flex flex-row">
          <SnapIcon
            icon="arrow-sm-left-line"
            size="md"
            className="mr-2 sm:mr-4 cursor-pointer text-gray-700"
            onClick={navigateToDashboardsList}
          />
          <h4 className="flex-1 text-gray-800 text-base sm:text-xl font-medium">
            {dashboardName.length > 1 ? dashboardName : org && org.name}
          </h4>
        </div>
        <div className="flex flex-row pt-2 pb-0 pl-4">
          {org
            ?
            <Tabs onTabChange={onTabChange} defaultSelected={defaultSelected}>
              <Tab name="Overview" id="overview" tabRef={overviewTabRef} />
              <Tab
                name="Pre-Approvals"
                id="preapprovals"
                tabRef={preApprovalsTabRef}
              />
            </Tabs>
            :
            <LoadingData />
          }
        </div>
      </FullWidthPageHeader>
      <PortalOutlet
        id={VAULT_BANNER_PORTAL_ID}
      />
      <div className="pt-3.5 pb-[55px] sm:px-6">
        <Outlet />
        { showVaultBanner ? (
          <VaultNotificationBanner 
            testId="vault-notification-banner"
            portalId={vaultBannerPortalId}
            variant={vaultOrgStatus}
            isMobile={isScreenSizeBelow(appState.windowSize.screen, "md")}
            onActionCalled={onVaultBannerActionCalled}
          />
        ) : null
        }
        { showVaultSuccess ? (
          <VaultMessageModal
            testId="vault-submit-success"
            showModal={appState.vault.sucessModalShow}
            icon="trophy-solid"
            iconColor="text-blue-100"
            iconBgColor="#3B82F6"
            title="Congratulations!"
            copy="You just enabled your organization's leaders to get their programs funded even faster. Let them know they are ready to launch a great fundraiser through pre-approvals now!"
            primaryText="Send Pre-Approval Invites"
            secondaryText="Close"
            onClose={() => {
              setShowSubmitSuccessModal(false, dispatch);
            }}
            onPrimaryAction={() => {
              setShowSubmitSuccessModal(false, dispatch);
              navigateToPreApprovals();
            }}
            onSecondaryAction={()=>setShowSubmitSuccessModal(false,dispatch)}
          />
          ) : null
        }

      </div>
    </>
  );
};

export default DashboardPage;
