import React from "react";
import { SnapInput } from "../suit";

interface ServiceWebsiteProps {
  fieldId: string;
  testId: string;
  value: string;
  errorMessage: string;
  locked?: boolean;
  required?: boolean
  onBlur?: (value: string) => void;
  onInput?: (value: string) => void;
  className?: string;
}

const ServiceWebsite: React.FC<ServiceWebsiteProps> = ({ fieldId, testId, locked = false, required = false, value, errorMessage, onBlur, onInput, className }) => {
  return (
    <div data-testid={testId} className={className}>
      <SnapInput
        _id={fieldId}
        _type="text"
        label="Fundraiser Service’s Website"
        value={value}
        required={true}
        error={!!errorMessage}
        helpText={!!errorMessage ? errorMessage : ""}
        errorIcon={true}
        placeholder={"www.service.com"}
        name={"approval-etr"}
        _prepend="https://"
        onInput={(e) => { onInput && onInput((e.target as HTMLInputElement).value) }}
        onBlur={(e) => { onBlur && onBlur(e.target.value) }}
        locked={locked}
      />
    </div>
  )
};

export default ServiceWebsite; 
