import React from "react";
import '@snap-mobile/vault/dist/index.css';
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { getClient } from "./graphql/client";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./App.router";
import { createRoot } from "react-dom/client";
import { isPublicPage } from "./utils/RouteUtils";

const path = window.location.pathname;
const publicPage = isPublicPage(path);
const client = publicPage ? getClient(false) : getClient(true);
const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <ApolloProvider client={client[0]}>
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
