
export const VaultPaymentProfileUrl = 'https://snap-mobile.zendesk.com/hc/en-us/sections/22771728368795-Payment-Profile' as const;

export enum ORG_VAULT_STATUS {
  start = 'start',
  profile = 'profile',
  underReview = 'underReview',
  uploadDocuments = 'uploadDocuments',
  approved = 'approved',
  unknown = 'unknown',
}
