import { useContext, useState } from "react";
import { isScreenSizeBelow } from "../../utils/UIUtils";
import WhiteCard from "../../components/WhiteCard";
import { AppContext } from "../../AppGlobalState";
import { SnapAlert, SnapAlertTitle, SnapButton, SnapCheckboxButton, SnapCheckboxGroup } from "../../suit";
import { UseUserNotificationsSettings } from "./UseUserNotificationSettings";
import { Exact, InsSetNotificationPreferenceFlag, InsSetNotificationType, InsUserNotificationType, InsightsNotificationPreferencesQuery, UserNotificationPreference, useInsightsSetNotificationPreferencesMutation, usePermissionsQuery } from "../../graphql/generated";
import { ApolloQueryResult } from "@apollo/client";
import { AlertType } from "@snap-mobile/snap-ui/dist/types/utils";

interface UserNotificationsProps {
  className?: string;
  userNotificationPreferences: UserNotificationPreference[]
  refetchUserNotifications: (variables?: Partial<Exact<{
    userId: string;
}>> | undefined) => Promise<ApolloQueryResult<InsightsNotificationPreferencesQuery>>
}

export const UserNotifications: React.FC<UserNotificationsProps> = ({ className, userNotificationPreferences, refetchUserNotifications }) => {
  const {appState} = useContext(AppContext);
  const { data: user } = usePermissionsQuery();  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, ] = useState("Your email notifications have been updated.");
  const [alertType, ] = useState<AlertType>("success");
  const [setNotificationPreferences] = useInsightsSetNotificationPreferencesMutation();
  const {removeUserNotification, 
    isUserNotificationRemoved, 
    addUserNotifcation,
    affectedAddedNotifications,
    affectedRemovedNotifications,
    resetUserNotifications
  } = UseUserNotificationsSettings(userNotificationPreferences);

  const saveChanges = () => {
    //TODO: Assuming we will calculate the ids to update based on changed checks
    setNotificationPreferences({
      variables: {
        userId: user?.me?.id || '',
        preferences: [
          // to turn off / uncheck
          {
            setTo: InsSetNotificationPreferenceFlag.Off,
            type: InsSetNotificationType.User,
            notificationIds: affectedRemovedNotifications as InsUserNotificationType[]
          },
          // to turn on / check
          {
            setTo: InsSetNotificationPreferenceFlag.On,
            type: InsSetNotificationType.User,
            notificationIds: affectedAddedNotifications as InsUserNotificationType[]
          },
        ]
      }
    }).then((result) => {
      setShowAlert(true)
      refetchUserNotifications().then(()=>{
        resetUserNotifications();
      });
      setTimeout(()=>setShowAlert(false), 3000)
    });
  };
  
  const saveDisabled  = ()=>{
    return affectedAddedNotifications.length === 0 && affectedRemovedNotifications.length === 0
  }
  const onCheckBoxChange =  (event: any)=>{
    const {checked, value} = event.detail;
    if(checked){
      addUserNotifcation(value)
    } else{
      removeUserNotification(value)
    }
  }

  return (
    <WhiteCard fullPageWidth={isScreenSizeBelow(appState.windowSize.screen, 'sm')} className={`${className}`}>      {showAlert ? (
      <SnapAlert
        style={{ zIndex: 100 }}
        type={alertType}
        border={true}
        close={true}
        onSnap-alert-closed={() => setShowAlert(!showAlert)}
        
        className="opacity-100 fixed xs:pr-14 xs:w-full md:w-3/4 top-32"
      >
        <SnapAlertTitle>{alertTitle}</SnapAlertTitle>
      </SnapAlert>
    ) : null}

      <h4 className="font-semibold text-xl text-gray-800 pb-4">Email Notifications</h4>
      <p className="font-normal text-base text-gray-800 pb-4">Select the email notifications you would like to receive.</p>
      <SnapCheckboxGroup className="pb-6">
        <SnapCheckboxButton 
          checked={!isUserNotificationRemoved( InsUserNotificationType.FundraiserCreated)}
          input-name={InsUserNotificationType.FundraiserCreated} 
          label="A new fundraiser has been created" 
          value={InsUserNotificationType.FundraiserCreated}
          onSnap-checkbox-updated={onCheckBoxChange}  
          >
          </SnapCheckboxButton>

        <SnapCheckboxButton 
          checked={!isUserNotificationRemoved( InsUserNotificationType.FundraiserFinalized)}  
          input-name={InsUserNotificationType.FundraiserFinalized} 
          label="Fundraiser has been finalized" 
          value={InsUserNotificationType.FundraiserFinalized}
          onSnap-checkbox-updated={onCheckBoxChange}
          >
          </SnapCheckboxButton>
        <SnapCheckboxButton 
          checked={!isUserNotificationRemoved( InsUserNotificationType.CheckSent)} 
          input-name={InsUserNotificationType.CheckSent} 
          label="Checks have been sent" 
          value={InsUserNotificationType.CheckSent}
          onSnap-checkbox-updated={onCheckBoxChange}
          >  
          </SnapCheckboxButton>
        <SnapCheckboxButton 
          checked={!isUserNotificationRemoved( InsUserNotificationType.DepositMade)} 
          input-name={InsUserNotificationType.DepositMade} 
          label="Direct deposits have been made" 
          value={InsUserNotificationType.DepositMade}
          onSnap-checkbox-updated={onCheckBoxChange} 
          > 
          </SnapCheckboxButton>
      </SnapCheckboxGroup>
      <div className="flex flex-row justify-end">
        <SnapButton 
          disabled={saveDisabled()}
          size="md"
          variant="primary"
          fullWidth={true}
          className="w-full sm:w-auto"
          onClick={saveChanges}
        >
          Save Changes
        </SnapButton>
      </div>
    </WhiteCard>
  )
}
