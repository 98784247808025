import { PhoneRegEx } from "../components/preApprovals/preApprovalConstants";
import { Org } from "../graphql/generated";
import { formatAmount } from "./CurrencyUtils";

/* Render Data Utils */

export const displayFormattedValue = (value: any, format: string = 'text') : string => {
  switch(format) {
    case 'money':
      return formatAmount(parseFloat(value));
    default:
      return value;
  }
};

export const dateMMDDYY = (date: Date) : string => {
  return date.toLocaleDateString("en-US", {year: '2-digit', month: '2-digit', day: '2-digit'});
};

export const dateMMDDYYYY = (date: Date) : string => {
  return date.toLocaleDateString("en-US", {year: 'numeric', month: '2-digit', day: '2-digit'});
};

export const capitalize = (value: string) : string => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const formatPhoneNumber = (input:string) => {
  let newInput = input;
  if(typeof newInput === "number") {
    newInput = input.toString();
  }

  if(newInput){
    if (PhoneRegEx.test(newInput)) {
        var formattedPhoneNumber =
        newInput.replace(PhoneRegEx, "($1) $2-$3");
    } else {
        return newInput;
    }
    return formattedPhoneNumber;
  }
  return "";
};

export const sortOrgsByName = (orgs: Org[]) : Org[] => {
     const sortedOrg = orgs.sort((a, b) => {
      if( a.name && b.name) return  a.name?.localeCompare(b.name || '');
      return 0
    });
    return sortedOrg
}