import WhiteCard from "../components/WhiteCard";
import { SnapIcon } from "../suit";

interface VaultInfoDisclaimerProps{
  testId: string;
  className?: string;
}

const VaultInfoDisclaimer: React.FC<VaultInfoDisclaimerProps> = ({
  testId,
  className = ""
}) => {
  return (
    <WhiteCard testId={testId} className={className}>
      <SnapIcon
        icon="question-mark-circle-solid"
        size="sm"
        state="rounded"
        bgColor="#3B82F6"
        className=" text-white mb-2"
      />
      <h5 className="font-medium text-gray-800 text-sm mb-2">Why are we asking for this information?</h5>
      <p className="font-normal text-gray-800 text-sm">We collect this information in order to set up your account with our payment partner Stripe.</p>
    </WhiteCard>
  );
}

export default VaultInfoDisclaimer;
