import { omit } from "underscore";
import { FundraiserApprovalForm, ReducerAction } from "../ApprovalsGlobalState";
import {
  FundraiserApprovalSubmission,
  FundraiserApprovalSubmissionFormType,
  FundraiserApprovalSubmissionResult,
  FundraiserOrgAndGroupNamesQueryResult,
  FundraiserApprovalSubmissionStatus,
  FundraiserApprover,
  FundraiserApproverInput,
  FundraiserApproverInputAction,
  FundraiserApprovalSubmissionDocument,
  InsightsApprovalSubmissionsQueryResult
} from "../graphql/generated";
import { FORM_STEPS, NEW_APPROVER_KEY } from "./constants";
import { dateMMDDYYYY } from "../utils/RenderDataUtils";

export const processCreateSubmissionMutationResponse = (
  response: FundraiserApprovalSubmissionResult | undefined
) => {
  const errors = response ? response.errors : null;
  let submission = response ? response.fundraiserApprovalSubmission : null;

  return { submission, errors };
};

export const processQueryLatestSubmissionMutationResponse = (
  response: InsightsApprovalSubmissionsQueryResult | undefined
) => {
  const errors = response ? response.error : null;
  let submission = response ? response.data?.fundraiserApprovalSubmissions[0] : null;

  return { submission, errors };
};

export const processQueryFetchFundraiserOrgAndGroupNames = (response: FundraiserOrgAndGroupNamesQueryResult | undefined) => {
  const orgName = response && response.data?.orgName && response.data?.orgName[0] && !!response.data.orgName[0].name ? response.data.orgName[0].name : "";
  const programName = response && response.data?.programName ? response.data?.programName[0]?.name : "";

  return { orgName, programName }
}

export const getFormStepFromSubmissionStatus = (submission: FundraiserApprovalSubmission) => {
  if (!submission.formType) return 0;
  if (submission.campaignRaiseId) return FORM_STEPS.formDetails;
  if (submission.formType === FundraiserApprovalSubmissionFormType.Snap) { return FORM_STEPS.snapFundraiserSelection }

  return FORM_STEPS.formDetails;
}

export const emptySubmission = () => ({
  fundraiserApprovalSubmissionDocs: [],
  fundraiserApprovers: [],
  createdAt: undefined,
  formId: -1,
  id: 0,
  latestRevision: true,
  newFundraiser: false,
  status: "DRAFT" as FundraiserApprovalSubmissionStatus.Draft,
  updatedAt: undefined,
  proposedStartDate: new Date(),
});

export const updateDraft = (field:string, value:string|number|FundraiserApprover[]|FundraiserApprovalSubmissionDocument[], dispatch:React.Dispatch<ReducerAction>) => {
  dispatch({
    type: "FUNDRAISER_EDITABLE_APPROVAL_SUBMISSION_UPDATED",
    payload: {
      fields: { [field]: value }
    },
  });
};

export const updateDraftLocally = (field:string, value:string|number|FundraiserApprover[]|FundraiserApprovalSubmissionDocument[], dispatch:React.Dispatch<ReducerAction>) => {
  dispatch({
    type: "FUNDRAISER_EDITABLE_APPROVAL_SUBMISSION_SYNCED",
    payload: {
      fields: { [field]: value }
    },
  });
};

export const mergeApproverProps = (approvers:FundraiserApprover[], approver:FundraiserApprover): FundraiserApprover => {
  return approvers.length > 0 ? { ...approvers[0], ...approver } : approver;
};

export const buildApproversUpdateObject = (approvers:FundraiserApprover[], resetting:boolean): FundraiserApproverInput[] => {
  return approvers.map(approver => {
    const action = resetting ? FundraiserApproverInputAction.Remove : (approver.id ? FundraiserApproverInputAction.Update : FundraiserApproverInputAction.Add);
    return {
      action,
      approver: omit(approver, "createdAt", "updatedAt", "__typename"),
    };
  });
};

export const approversSyncRequired = (submission:FundraiserApprovalSubmission, newApprovers:FundraiserApprover[]): boolean => {
  return (submission.fundraiserApprovers.length > 0 && submission.fundraiserApprovers[0]?.id === undefined) ||
  submission.fundraiserApprovers.length !== newApprovers.length;
};

export const isFormSubmittable = (form:FundraiserApprovalForm): boolean => {
  const { editableSubmission:submission, docUploading } = form;
  if(isSnapApprovalForm(submission)) {
    return !snapApprovalFormRequiredFieldsInvalid(submission) && validFundraiserApprovalSubmissionApprover(submission) && !docUploading;
  } else {
    return !nonSnapApprovalFormRequiredFieldsInvalid(submission) && validFundraiserApprovalSubmissionApprover(submission) && !docUploading;
  }
};

const isSnapApprovalForm = (
  submission: FundraiserApprovalSubmission
): boolean => {
  return submission.formType === FundraiserApprovalSubmissionFormType.Snap;
};

const snapApprovalFormRequiredFieldsInvalid = (
  submission: FundraiserApprovalSubmission
): boolean => {
  return (
    !submission.firstName ||
    !submission.lastName ||
    !submission.organizationId ||
    !submission.programId ||
    !submission.groupSize ||
    !submission.estimatedTotalRaisedCents ||
    !submission.pricingAndFees ||
    !submission.howThisWorks ||
    !submission.reason ||
    !submission.proposedStartDate ||
    !submission.duration ||
    !submission.fundraiserServiceWebsite ||
    !submission.customerSupportInfo
  );
};

const nonSnapApprovalFormRequiredFieldsInvalid = (
  submission: FundraiserApprovalSubmission
): boolean => {
  return (
    !submission.firstName ||
    !submission.lastName ||
    !submission.organizationId ||
    !submission.programId ||
    !submission.groupSize ||
    !submission.estimatedTotalRaisedCents ||
    !submission.proposedStartDate ||
    !submission.duration ||
    !submission.fundraiserServiceWebsite
  );
};

const validFundraiserApprovalSubmissionApprover = (
  submission: FundraiserApprovalSubmission
): boolean => {
  if (submission.fundraiserApprovers.length === 0) return false;
  return submission.fundraiserApprovers.every(approver => {
    if (!approver.userId) return false;
    if (
      approver.userId === NEW_APPROVER_KEY &&
      (!approver.firstName ||
        !approver.lastName ||
        !approver.email ||
        !approver.roleId)
    ) {
      return false;
    }
    return true;
  });
};

export const getApproverName = (approver: FundraiserApprover): string => {
  return approver.firstName + " " + approver.lastName;
};

export const proposedDateInitialValue = (editableSubmission: FundraiserApprovalSubmission): string => {
  return new Date(editableSubmission.proposedStartDate).getTime() > new Date(Date.now()).getTime() ? dateMMDDYYYY(new Date(editableSubmission.proposedStartDate)) : "";
}
