import ReactDOM from "react-dom";
import { SnapIcon } from "../suit";
import { ORG_VAULT_STATUS } from "./constants";

const getApprovedComponents = (onActionCalled:() => void) => {
  const bannerContainerClasses:string[] = [];
  bannerContainerClasses.push("bg-green-100 border-green-500");
  const icon = (<SnapIcon icon="check-circle-solid" size="md" className="text-green-700" />);
  const message = "Your Snap! Vault Profile is Active";
  const cta = (
    <button 
      className="text-green-700 text-sm font-bold"
      onClick={onActionCalled}
    >
      Close
    </button>
  );
  return { bannerContainerClasses, icon, message, cta };
};

const getUploadDocsComponents = (onActionCalled:() => void) => {
  const bannerContainerClasses:string[] = [];
  bannerContainerClasses.push("bg-yellow-100 border-yellow-500");
  const icon = (<SnapIcon icon="exclamation-solid" size="md" className="text-yellow-700" />);
  const message = "Snap! Vault Requires Additional Verification";
  const cta = (
    <button 
      className="text-yellow-700 text-sm font-bold"
      onClick={onActionCalled}
    >
      Upload Documents
    </button>
  );
  return { bannerContainerClasses, icon, message, cta };
};

const getUnderReviewComponents = () => {
  const bannerContainerClasses:string[] = [];
  bannerContainerClasses.push("bg-blue-100 border-blue-500");
  const icon = (<SnapIcon icon="clock-solid" size="md" className="text-blue-700" />);
  const message = "Your Snap! Vault Profile is Under Review";
  return { bannerContainerClasses, icon, message, cta: null };
};

const getProfileComponents = (onActionCalled:() => void) => {
  const bannerContainerClasses:string[] = [];
  bannerContainerClasses.push("bg-yellow-100 border-yellow-500");
  const icon = (<SnapIcon icon="exclamation-solid" size="md" className="text-yellow-700" />);
  const message = "Please Verify Your Snap! Vault Profile";
  const cta = (
    <button 
      className="text-yellow-700 text-sm font-bold"
      onClick={onActionCalled}
    >
      Get Started
    </button>
  );
  return { bannerContainerClasses, icon, message, cta };
};

const getStartComponents = (onActionCalled:() => void) => {
  const bannerContainerClasses:string[] = [];
  bannerContainerClasses.push("bg-red-100 border-red-500");
  const icon = (<SnapIcon icon="exclamation-solid" size="md" className="text-red-700" />);
  const message = "Please Complete Your Snap! Vault Profile";
  const cta = (
    <button 
      className="text-red-700 text-sm font-bold"
      onClick={onActionCalled}
    >
      Get Started
    </button>
  );
  return { bannerContainerClasses, icon, message, cta };
};

const getVariantComponents = (variant:ORG_VAULT_STATUS, onActionCalled:() => void) => {
  switch(variant) {
    case ORG_VAULT_STATUS.approved:
      return getApprovedComponents(onActionCalled);
    case ORG_VAULT_STATUS.uploadDocuments:
      return getUploadDocsComponents(onActionCalled);
    case ORG_VAULT_STATUS.underReview:
      return getUnderReviewComponents();
    case ORG_VAULT_STATUS.profile:
      return getProfileComponents(onActionCalled);
    case ORG_VAULT_STATUS.start:
      return getStartComponents(onActionCalled);
    default:
      return { bannerContainerClasses: [], icon: null, message: "", cta: null };
  }
};

interface VaultNotificationBannerProps{
  testId: string;
  portalId: string;
  variant: ORG_VAULT_STATUS;
  isMobile?: boolean;
  onActionCalled?: () => void;
  className?: string;
}

const VaultNotificationBanner: React.FC<VaultNotificationBannerProps> = ({
  testId,
  portalId,
  variant,
  isMobile = false,
  onActionCalled = () => {},
  className
}) => {
  const domNode = document.getElementById(portalId) as Element;
  const { bannerContainerClasses, icon, message, cta } = getVariantComponents(variant, onActionCalled);
  const mobileClasses = isMobile ? "border-b-4 px-4" : "border-y-2 px-6 mt-1";

  if(!domNode) {
    return null;
  }

  return ReactDOM.createPortal(
    <div data-testid={testId} className={`w-full h-14 flex flex-row items-center ${mobileClasses} ${bannerContainerClasses.join(" ")} ${className}`}>
      <div className={`${isMobile ? "pr-2" : "pr-3"}`}>
        {icon}
      </div>
      <div className="flex flex-col items-start">
        <p className="text-gray-800 text-sm font-semibold">{message}</p>
        {cta}
      </div>
    </div>,
    domNode
  );
}

export default VaultNotificationBanner;
