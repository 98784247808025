import { ICON_TYPES } from "@snap-mobile/snap-ui/dist/types/utils";
import { SnapButton, SnapIcon } from "../suit";
import Modal from "../layout/modal";

interface VaultMessageModalProps{
  testId: string;
  showModal: boolean;
  icon: string;
  iconColor: string;
  iconBgColor: string;
  title: string;
  copy: string;
  primaryText: string;
  secondaryText: string;
  className?: string;
  onClose?: () => void;
  onPrimaryAction?: () => void;
  onSecondaryAction?: () => void;
}

const VaultMessageModal: React.FC<VaultMessageModalProps> = ({
  testId,
  showModal,
  icon,
  iconColor,
  iconBgColor,
  title,
  copy,
  primaryText,
  secondaryText,
  className = "",
  onClose = () => {},
  onPrimaryAction = () => {},
  onSecondaryAction = () => {},
}) => {

  return(
    <Modal
      testId={testId}
      showModal={showModal}
      onClose={onClose}
      className={`w-80 rounded-lg ${className}`}
    >
      <div data-testid={testId} className="w-full max-w-xs flex flex-col px-4 py-3 text-center">
        <div className="flex flex-row justify-between items-start w-full rounded-t-lg">
          <div className="w-6"></div>
          <SnapIcon
            icon={icon as ICON_TYPES}
            size="md"
            state="rounded"
            bgColor={iconBgColor}
            className={`${iconColor} mb-2`}
          />
          <SnapIcon icon="x-solid" size="sm" className="cursor-pointer text-gray-200" onClick={onClose} />
        </div>
        <h5 className="text-lg text-gray-900 font-medium mb-2">{title}</h5>
        <p className="text-sm text-gray-500 font-normal mb-4">{copy}</p>
        <SnapButton
          size="md"
          fullWidth
          variant="primary"
          className="mb-3"
          onClick={onPrimaryAction}
        >
          {primaryText}
        </SnapButton>
        <SnapButton
          size="md"
          fullWidth
          variant="tertiary"
          onClick={onSecondaryAction}
        >
          {secondaryText}
        </SnapButton>
      </div>
    </Modal>
  )
}

export default VaultMessageModal;
