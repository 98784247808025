import React from 'react';
import { SnapLink } from '../suit';
import { getFundraiserEditPageUrl, getFundraiserPageUrl } from '../utils/RouteUtils';

interface FundraiserLinksProps {
  testId: string;
  fundraiserId: number;
  className?: string;
}

const FundraiserLinks: React.FC<FundraiserLinksProps> = ({ testId, fundraiserId, className = ""}) => {

  const fundraiserPageUrl = getFundraiserPageUrl(`${fundraiserId}`);
  const fundraiserEditPageUrl = getFundraiserEditPageUrl(fundraiserId);

  return (
    <div className={`${className}`}>
      <p className="text-gray-800 text-lg font-semibold pb-2">Fundraiser Page</p>
      <div className="flex flex-col sm:flex-row sm:justify-start">
        <SnapLink
          text="View Fundraiser Page"
          href={fundraiserPageUrl}
          target="_blank"
          size="sm"
          iconPosition="right"
          icon="external-link-solid"
          className="sm:mr-5"
          data-testid={`${testId}-view`}
        />
        <SnapLink
          text="Edit Fundraiser Page"
          href={fundraiserEditPageUrl}
          target="_blank"
          size="sm"
          iconPosition="right"
          icon="external-link-solid"
          data-testid={`${testId}-edit`}
        />
      </div>
    </div>
  );
};

export default FundraiserLinks;
