import { FILE_VISUALIZER_MODE, FileVisualizerMode } from "../approvals/constants";
import { FileStatusMap } from "../approvals/contracts-and-documents";
import { FundraiserApprovalSubmissionDocument } from "../graphql/generated";
import { FILE_STATUS } from "../utils/upload-file";
import FileControl from "./file-control";

interface FileListVisualizerProps {
  testId: string;
  files: File[];
  existingFiles: FundraiserApprovalSubmissionDocument[]
  fileStatusMap: FileStatusMap;
  className?: string;
  progress?: { [id: string]: number }
  onFileDelete?: (fileName: string, status: FILE_STATUS) => Promise<void>;
  mode?: FileVisualizerMode;
}

const FileListVisualizer: React.FC<FileListVisualizerProps> = ({ testId, files, existingFiles, className = "", progress, fileStatusMap, onFileDelete = () => { }, mode = FILE_VISUALIZER_MODE.edit }) => {
  return (
    <div
      data-testid={testId}
      className={`${className}`}
    >
      {files.map((file, index) => <FileControl
        status={fileStatusMap[file.name].status}
        testId={`${testId}-file-${index}`}
        fileName={file.name}
        key={`file-${index}`}
        progress={progress && progress[file.name]}
        onFileDelete={onFileDelete}
        location={fileStatusMap[file.name].s3Url}
        mode={mode}
      />)}
      {existingFiles.map((file, index) => <FileControl
        status={FILE_STATUS.previousUpload}
        testId={`${testId}-file-${index}`}
        fileName={`${file.filename}.${file.filetype}`}
        key={`file-${index}`}
        onFileDelete={onFileDelete}
        location={file.location}
        mode={mode}
      />)}
    </div>
  );
}

export default FileListVisualizer;
