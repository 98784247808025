import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from "react-transition-group";
import { lockBodyScroll, unlockBodyScroll } from '../utils/UIUtils';

interface ModalProps {
  testId: string;
  showModal: boolean;
  children: React.ReactNode;
  onClose: () => void;
  verticalPosition?: "center" | "top" | "bottom";
  className?: string;
}

const Modal: React.FC<ModalProps> = ({ testId, showModal, children, onClose, verticalPosition = "center", className = ""}) => {
  const [show, setShow] = useState(false);
  const [verticalPositionClass, setVerticalPositionClass] = useState("items-center");

  const closeModal = () => {
    setShow(false);
    unlockBodyScroll();
    onClose()
  };

  useEffect(() => {
    const closeOnEscapeKeyDown = (e:KeyboardEvent)  => {
      if (e.key === 'Escape' || e.key === 'Esc') {
        closeModal();
      }
    };

    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return () => {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    switch (verticalPosition) {
      case "top":
        setVerticalPositionClass("items-start");
        break;
      case "bottom":
        setVerticalPositionClass("items-end");
        break;
      case "center":
      default:
        setVerticalPositionClass("items-center");
        break;
    }
  }, [verticalPosition]);

  useEffect(() => {
    if(showModal !== show) {
      if(showModal) { //opening modal
        setTimeout(() => { lockBodyScroll(); }, 300);
      } else {
        unlockBodyScroll();
      }
      setShow(showModal);
    }
  }, [showModal, show]);

  return ReactDOM.createPortal(
    <CSSTransition
      in={show}
      unmountOnExit
      timeout={{ enter: 0, exit: 300 }}
      classNames={{
        enterActive: 'opacity-0',
        enterDone: 'opacity-100',
        exit: 'opacity-0',
      }}
    >
      <div 
        data-testid={`${testId}-overlay`}
        className={`fixed top-0 bottom-0 left-0 right-0 ${verticalPositionClass} justify-center bg-gray-900/25 z-[10002] flex duration-300 ease-in-out transition-[opacity] opacity-0`} 
        onClick={closeModal}
      >
        <div data-testid={`${testId}-modal`} className={`bg-white shadow-md max-h-screen sm:max-h-[86vh] ${className}`} onClick={e => e.stopPropagation()}>
          {children}
        </div>
      </div>
    </CSSTransition>,
    document.getElementById("root") as Element
  );
};

export default Modal;
