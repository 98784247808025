import { OrgNotificationPreference, UserNotificationPreference } from "../../graphql/generated"

export const isOrgNotificationOffOnDB = (orgNotications: OrgNotificationPreference[], orgId: string)=>{
    
    return orgNotications.findIndex((orgNotication)=> orgNotication.org_id === orgId) >= 0
}

export const isUserNotificationOffOnDB = (userNotications: UserNotificationPreference[], name: string)=>{
    return userNotications.findIndex((userNotication)=> userNotication.name === name) >= 0
}
