import { useContext } from 'react';
import { SplitFeatureFlags } from '../constants/splitFeatureFlags';
import useOnOffTreatment from '../hooks/useOnOffTreatment';
import { SnapImpersonationMode } from '../suit';
import { AppContext } from '../AppGlobalState';
import { useNavigate } from 'react-router-dom';
import { ApprovalsContext } from '../ApprovalsGlobalState';

interface ImpersonationModeWrapperProps {
    children: React.ReactNode;
}

const ImpersonationModeWrapper: React.FC<ImpersonationModeWrapperProps> = ({ children }) => {
  const { treatmentOn:user801TreatmentOn } = useOnOffTreatment(SplitFeatureFlags.USER_801_use_snap_impersonation_mode);
  const { dispatch } = useContext(AppContext);
  const { dispatch: aprrovalsDispatch } = useContext(ApprovalsContext);
  const navigate = useNavigate();

  const onImpersonationModeChanged = (event:CustomEvent) => {
    dispatch({ type: "SET_IMPERSONATE_MODE", payload: {mode: event.detail} });
    aprrovalsDispatch({ type: "SET_IMPERSONATE_MODE", payload: {mode: event.detail} })
    if(event.detail === false){
      navigate(0);
    }
  }

  if(user801TreatmentOn) {
    return (
      <SnapImpersonationMode onSnap-impersonation-mode-changed={onImpersonationModeChanged}>
        {children}
      </SnapImpersonationMode>
    )
  } else {
    return (
      <>
        {children}
      </>
    )
  }

};

export default ImpersonationModeWrapper;
