
interface SkeletonProps {
  variant?: "rectangular" | "circular" | "text" ;
  height: number;
  lineCount?: number;
  className?: string;
}
interface LinesProps {
  height: number;
  lineCount?: number;
  className?: string;
}

const Lines = (props: LinesProps)=>{
  const {lineCount, className, height} = props
  if(lineCount){
    let printedLines = 0;
    const linesToPrint = []
    while (printedLines < lineCount){
      linesToPrint.push(<div data-testid="skl-line" key={`line-${printedLines}`} style={{height:`${height}px`}}  className= "skeleton-line animate-pulse rounded-lg w-full bg-slate-300  mb-1" ></div>)
      printedLines++;
    }
    return (
      <div data-testid="skl-text" className={className}>
        {linesToPrint}
      </div>
    )
  }
  return (<div data-testid="skl-text" style={{height:`${height}px`}}  className= {`${className} animate-pulse rounded-lg w-full bg-slate-300`} />)
}

const Skeleton = (props:SkeletonProps)=>{
  const {variant, height, lineCount, className} = props;

  const circularStyles = {height:`${height}px`, width:`${height}px`}
  if(variant === "rectangular"){
    return (
      <div data-testid={"skl-rectangular"} style={{height:`${height}px`}}   className= " animate-pulse rounded-lg w-full bg-slate-300" ></div>
    )
  }
  if(variant === "text"){
    return (<Lines lineCount={lineCount} className={className} height={height} />)
  }

  if(variant === "circular"){
    return (
      <div data-testid={"skl-circular"} style={circularStyles} className= "skeleton-circular animate-pulserounded-full  bg-slate-300" ></div>
    )
  }

  return (
    <div style={{height:`${height}px`}} data-testid={"skl-rectangular"}  className= "skeleton-rectangular animate-pulserounded-lg  w-full bg-slate-300" ></div>
  )

}

export default Skeleton;