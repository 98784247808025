import VaultIconImg from "../assets/VaultIcon.png";
import { SnapLink } from "../suit";
import { useContext } from "react";
import { AppContext } from "../AppGlobalState";
import { Org } from "../graphql/generated";
import WhiteCard from "../components/WhiteCard";
import { VaultPaymentProfileUrl } from "./constants";
import VautlOrgListitem from "./vault-org-listitem";
import { useNavigate } from "react-router-dom";

interface VautlOrgSelectorProps{
  className?: string;
}

const VautlOrgSelector: React.FC<VautlOrgSelectorProps> = ({
  className
}) => {
  const { appState } = useContext(AppContext);
  const navigate = useNavigate();
  const userAssociations:Org[] = Object.values(appState.userAssociationsIndex);
  let displayAssociations = userAssociations;
  if (appState.vault.preselectedOrgId !== null) {
    displayAssociations = [appState.userAssociationsIndex[appState.vault.preselectedOrgId]];
  } else {
    displayAssociations = userAssociations;
  }

  const onOrgListitemSelected = (orgId:string) => {
    navigate(`/vault/${orgId}`);
  };

  if(displayAssociations.length <= 0) {
    return (
      <WhiteCard testId="org-selector-no-org" className={`${className}`}>
        <p className="text-gray-800 text-sm md:text-base font-normal pb-2">
          We didn't found any Vault Financial Account data, please contact support
          for further assistance.
        </p>
      </WhiteCard>
    )
  }

  return (
    <WhiteCard className={`${className}`}>
      <div className="flex flex-col md:flex-row items-center pb-3 border-gray-200 border-b">
        <img src={VaultIconImg} alt="Snap! Vault" className="max-w-none w-20" />
        <h5 className="text-gray-800 text-xl md:text-2xl font-semibold pt-1 md:pt-0 md:pl-3">Welcome to Snap! Vault</h5>
      </div>
      <div className="py-4">
        <h6 className="text-gray-800 text-lg font-semibold pb-2">Select Your Organization</h6>
        <p className="text-gray-800 text-sm md:text-base font-normal pb-2">Snap! Vault can be set at a district or school level depending on your organization. Once processed and active all group leaders in your organization will be covered under this financial profile.</p>
        <SnapLink 
          text="Learn More"
          size="sm"
          target="_blank"
          href={VaultPaymentProfileUrl} 
          srOnly="srOnly"
          iconPosition="right"
          icon="external-link-solid"
        />
      </div>
      <div>
      {
        displayAssociations.map((associationOrg, index) => {
          return (
            <VautlOrgListitem
              testId={"vault-org-li-" + index}
              org={associationOrg}
              key={index}
              onItemSelected={() => onOrgListitemSelected(associationOrg.id)}
            />
          )
        })
      }
      </div>
    </WhiteCard>
  );
}

export default VautlOrgSelector;
