import { useContext, useEffect, useState } from "react";
import {
  BasicCampaignStatus,
  FundraiserApprovalSubmissionFormType,
  GroupLeaderCampaign,
  useGroupLeaderCampaignMembershipsLazyQuery,
  useInsightsApprovalSubmissionsLazyQuery,
  useMeQuery,
} from "../graphql/generated";
import { SnapCheckboxButton } from "../suit";
import {
  SnapCheckboxButtonCustomEvent,
  CheckboxButtonUpdatedEvent,
} from "@snap-mobile/snap-ui/dist/types/components";
import {
  findGroupLeaderCampaignById,
  getSnapSelectionChangeFields,
} from "./snap-fundraiser-selector-data-helper";
import { ApprovalsContext } from "../ApprovalsGlobalState";
import { FORM_STEPS } from "./constants";

interface submissionDetails { }
interface SnapFundraiserSelectorProps {
  submission?: submissionDetails;
}

const SnapFundraiserSelector = (
  SnapFundraiserProps: SnapFundraiserSelectorProps
) => {
  const { data: user } = useMeQuery();
  const id = user?.me?.id || "";
  const { appState, dispatch } = useContext(ApprovalsContext);
  const {
    editing, fundraiserApprovalForm: { formStep, editableSubmission },
  } = appState;

  const [fetchUserSubmissions, { data: submissions }] =
    useInsightsApprovalSubmissionsLazyQuery();
  const [fetchCampaigns, { data: upcomingCampaigns }] =
    useGroupLeaderCampaignMembershipsLazyQuery();

  const [eligibleFundraisers, setEligibleFundraisers] = useState<
    GroupLeaderCampaign[]
  >([]);

  useEffect(() => {
    if (id) {
      fetchUserSubmissions({
        variables: {
          submitterId: id,
        },
      });
      fetchCampaigns({
        variables: {
          basicStatus: BasicCampaignStatus.Upcoming,
        },
      });
    }
  }, [fetchCampaigns, fetchUserSubmissions, id, editableSubmission.formType]);

  useEffect(() => {
    if (upcomingCampaigns && submissions) {
      let submissionRaiseIds = submissions.fundraiserApprovalSubmissions.map(
        (submission) => submission?.campaignRaiseId
      );
      let filteredCampaigns: GroupLeaderCampaign[] = (
        upcomingCampaigns.campaignMemberships as GroupLeaderCampaign[]
      ).filter(
        (campaign) =>
          submissionRaiseIds.indexOf(parseInt(campaign.id, 10)) < 0 ||
          editableSubmission.campaignRaiseId === parseInt(campaign.id, 10)
      );
      setEligibleFundraisers([...filteredCampaigns]);
    }
  }, [upcomingCampaigns, submissions, editableSubmission.campaignRaiseId]);

  const onFundraiserCheckboxChange = (
    e: SnapCheckboxButtonCustomEvent<CheckboxButtonUpdatedEvent>
  ) => {
    const { value, checked, isTrusted } = e.detail;
    if (!isTrusted || !user || !user?.me) return;
    if (checked) {
      if (value === "NEW") {
        dispatch({
          type: "FUNDRAISER_EDITABLE_APPROVAL_SUBMISSION_UPDATED",
          payload: {
            fields: getSnapSelectionChangeFields(null, user.me, true),
            formStep: FORM_STEPS.snapFundraiserSelection,
          },
        });
      } else {
        const [selectedFundraiserTemp] = findGroupLeaderCampaignById(
          upcomingCampaigns?.campaignMemberships as GroupLeaderCampaign[],
          value
        );
        if (selectedFundraiserTemp) {
          dispatch({
            type: "FUNDRAISER_EDITABLE_APPROVAL_SUBMISSION_UPDATED",
            payload: {
              fields: getSnapSelectionChangeFields(
                selectedFundraiserTemp,
                user.me,
                false
              ),
              formStep: FORM_STEPS.snapFundraiserSelection,
            },
          });
        }
      }
    } else {
      dispatch({
        type: "FUNDRAISER_EDITABLE_APPROVAL_SUBMISSION_UPDATED",
        payload: {
          fields: {
            fields: getSnapSelectionChangeFields(null, user.me, false),
            formStep: FORM_STEPS.snapFundraiserSelection,
          },
        },
      });
    }
  };

  if (
    formStep < FORM_STEPS.formTypeSelection ||
    editableSubmission.formType !== FundraiserApprovalSubmissionFormType.Snap
  )
    return null;
  return (
    <div className="w-full my-4 p-2">
      <p className=" pb-4  text-base">
        {" "}
        Select Snap! Raise Fundraiser{" "}
        <span className=" text-red-500 text-sm">*</span>
      </p>

      <div className="max-h-[276px] overflow-auto">
        <div className="flex flex-wrap md:gap-6 md:gap-x-20">
          <SnapCheckboxButton
            disabled={editing}
            key={"new"}
            onSnap-checkbox-updated={onFundraiserCheckboxChange}
            className="basis-full sm:basis-2/5"
            input-name={`radio-new`}
            label="New Snap Fundraiser"
            value="NEW"
            checked={editableSubmission.newFundraiser ? true : false}
          />
          {eligibleFundraisers &&
            eligibleFundraisers.map(
              (fundraiser, idx) => {
                return (
                  <div key={`${fundraiser.id}-${idx}`} className="basis-full sm:basis-2/5 ">
                    <SnapCheckboxButton
                      disabled={editing && editableSubmission.campaignRaiseId !==
                        parseInt(fundraiser.id, 10)}
                      onSnap-checkbox-updated={onFundraiserCheckboxChange}
                      input-name={`radio-${fundraiser.name}`}
                      label={fundraiser.name}
                      value={fundraiser.id}
                      checked={
                        editableSubmission.campaignRaiseId ===
                        parseInt(fundraiser.id, 10)
                      }
                    />
                  </div>
                );
              }
            )
          }
        </div>
      </div>
    </div>
  );
};

export default SnapFundraiserSelector;
