import React from "react";
import { SnapInput } from "../suit";

interface EstimatedTotalRaisedProps {
  fieldId: string;
  testId: string;
  value: string;
  errorMessage: string;
  onValueChange?: (value: string) => void;
  onInput?: (value: string) => void;
  className?: string;
}

const EstimatedTotalRaised: React.FC<EstimatedTotalRaisedProps> = ({ fieldId, testId, value, errorMessage, onValueChange, onInput, className }) => {
  return (
    <div data-testid={testId} className={className}>
      <SnapInput
        _id={fieldId}
        _type="number"
        label="Estimated Total Raised"
        value={value}
        required={true}
        error={!!errorMessage}
        helpText={!!errorMessage ? errorMessage : ""}
        errorIcon={true}
        placeholder={"0"}
        name={"approval-etr"}
        _prepend="$"
        iconPosition="left"
        onInput={(e) => { onInput && onInput((e.target as HTMLInputElement).value) }}
        onSnap-input-change={(e) => { onValueChange && onValueChange((e.target as unknown as HTMLInputElement).value) }}
      />
    </div>
  )
};

export default EstimatedTotalRaised;
