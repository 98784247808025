export enum ProgramActivities {
  ACTION_SPORTS = "Action Sports",
  ARCHERY = "Archery",
  ART = "Art",
  ASB = "ASB",
  AVID = "Avid",
  BADMINTON = "Badminton",
  BAND = "Band",
  BASEBALL = "Baseball",
  BASKETBALL = "Basketball",
  BOWLING = "Bowling",
  BOXING = "Boxing",
  BUSINESS = "Business",
  CHEERLEADING = "Cheerleading",
  CHESS = "Chess",
  CHOIR = "Choir",
  CREW = "Crew",
  CROSS_COUNTRY = "Cross Country",
  CULTURE_AND_LANGUAGE = "Culture and Language",
  CYCLING = "Cycling",
  DANCE = "Dance",
  DEBATE = "Debate",
  DECA = "DECA",
  DRAMA = "Drama",
  EQUESTRIAN = "Equestrian",
  FASHION = "Fashion",
  FBLA = "FBLA",
  FCCLA = "FCCLA",
  FENCING = "Fencing",
  FIELD_HOCKEY = "Field Hockey",
  FILM_AND_TV_PRODUCTION = "Film and TV Production",
  FOOTBALL = "Football",
  FRESHMAN_CLASS = "Freshman Class",
  GOLF = "Golf",
  GSA_AND_LGBTQI = "GSA and LGBTQI",
  GYMNASTICS = "Gymnastics",
  HOCKEY = "Hockey",
  HOSA = "HOSA",
  JOURNALISM = "Journalism",
  JUNIOR_CLASS = "Junior Class",
  KEY_AND_LINK = "Key and Link",
  LACROSSE = "Lacrosse",
  MARTIAL_ARTS = "Martial Arts",
  MODEL_UN_AND_TRIAL = "Model UN and Trial",
  MUSIC = "Music",
  NATIONAL_HONORS_SOCIETY = "National Honors Society",
  NON_PROFIT = "Non Profit",
  ORCHESTRA_AND_SYMPHONY = "Orchestra and Symphony",
  OTHER = "Other",
  OUTDOORS = "Outdoors",
  PHOTOGRAPHY = "Photography",
  POLO = "Polo",
  POWERLIFTING = "Powerlifting",
  PROM_AND_HOMECOMING = "Prom and Homecoming",
  ROBOTICS = "Robotics",
  ROTC = "ROTC",
  RUGBY = "Rugby",
  SAILING = "Sailing",
  SCHOLARSHIP = "Scholarship",
  SENIOR_CLASS = "Senior Class",
  SHOOTING = "Shooting",
  SKIING = "Skiing",
  SOCCER = "Soccer",
  SOFTBALL = "Softball",
  SOPHOMORE_CLASS = "Sophomore Class",
  STEM = "Stem",
  STUDENT_GOVERNMENT = "Student Government",
  SURF = "Surf",
  SWIM_AND_DIVE = "Swim and Dive",
  TABLE_TENNIS = "Table Tennis",
  TENNIS = "Tennis",
  TRACK_AND_FIELD = "Track and Field",
  ULTIMATE_FRISBEE = "Ultimate Frisbee",
  VIDEO_GAME = "Video Game",
  VOLLEYBALL = "Volleyball",
  WATER_POLO = "Water Polo",
  WINTER_SPORTS = "Winter Sports",
  WRESTLING = "Wrestling",
  WRITING = "Writing",
  YEARBOOK = "Yearbook",
}

export const programActivitiesAsArray = () => {
  const keys = Object.keys(ProgramActivities);
  const values = Object.values(ProgramActivities);
  return keys.map((key, index) => {
    return {
      key,
      text: values[index]
    }
  });
};
