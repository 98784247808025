import React from 'react';
import { SnapInput } from '../suit';

interface DurationProps {
  fieldId: string;
  testId: string;
  value: string;
  locked?: boolean;
  errorMessage: string;
  onBlur?: (value:string)=>void;
  onInput?: (value:string)=>void;
  className?: string;
}

const Duration: React.FC<DurationProps> = ({ fieldId, testId, value, locked = false, errorMessage, onBlur, onInput, className = ""}) => {
  const icon = locked ? "lock-closed-solid" : undefined;
  return (
    <div data-testid={testId} className={`${className}`}>
      <SnapInput
        _id={fieldId}
        name={fieldId}
        _type="text"
        label="Fundraiser Duration"
        value={value}
        required
        error={!!errorMessage}
        helpText={!!errorMessage ? errorMessage : "" }
        locked={locked}
        iconPosition="right"
        icon={icon}
        onBlur={(e) => {
          onBlur && onBlur(e.target.value);
        }}
        onInput={(e) => {
          onInput && onInput((e.target as HTMLInputElement).value);
        }}
      />
    </div>
  );
};

export default Duration;
