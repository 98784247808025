import classNames from 'classnames';
import { useContext, useState } from 'react';
import { Outlet, useNavigate } from "react-router-dom";
import { AppContext } from '../AppGlobalState';
import FullWidthPageHeader from '../components/FullWidthPageHeader';
import { isScreenSizeBelow, isScreenSizeEqualOrAbove } from '../utils/UIUtils';
import { SnapIcon } from '../suit';
import VaultMessageModal from './vault-message-modal';
import Loading from '../layout/loading';

const VaultPage = () => {
  const {appState} = useContext(AppContext);
  const navigate = useNavigate();
  const [vaultExitConfirmation, setVaultExitConfirmation] = useState<boolean>(false);

  const exitVaultPage = () => {
    setVaultExitConfirmation(false);
    if (appState.vault.referrerPath !== null) {
      navigate(appState.vault.referrerPath);
      return;
    }
    navigate(`/dashboards`);
  };
  if(appState.orgsLoading){
    return (
    <div className="flex h-screen justify-center">
     <Loading testId="vault-page-loading" message="Loading Snap! Vault Financial Profile"/>
    </div>
 
    )
  }

  return (
    <>
      <FullWidthPageHeader  className={classNames('pt-4 pb-3 transition-all', {'px-6': isScreenSizeEqualOrAbove(appState.windowSize.screen, 'sm'), 'px-4': isScreenSizeBelow(appState.windowSize.screen, 'sm')})}>
        <h4 data-testid="vault-page" className="text-gray-800 font-medium text-2xl">Snap! Vault Financial Profile</h4>
        <button 
          className="flex text-blue-700 text-sm font-bold"
          onClick={() => { setVaultExitConfirmation(true); }}
        >
          <SnapIcon
            icon="arrow-sm-left-line"
            size="sm"
            className="mr-1 cursor-pointer text-blue-700"
          />
          <span className='hover:underline'>Exit Snap! Vault</span>
        </button>
      </FullWidthPageHeader>
      <div className={classNames({'py-3.5 px-6': isScreenSizeEqualOrAbove(appState.windowSize.screen, 'sm'), 'py-3.5 px-4': isScreenSizeBelow(appState.windowSize.screen, 'sm')})}>
        <Outlet />
        <VaultMessageModal 
          testId="vault-confirm-exit"
          showModal={vaultExitConfirmation}
          icon="exclamation-solid"
          iconColor="text-yellow-600"
          iconBgColor="#FEF3C7"
          title="Are you sure you want to exit your Snap! Vault Financial Profile?"
          copy="You’ll lose your progress in completing Snap! Vault. Your financial profile is required for all fundraising activities."
          primaryText="Continue Working on Snap! Vault"
          secondaryText="Exit and Lose My Progress"
          onClose={() => { setVaultExitConfirmation(false); }}
          onPrimaryAction={() => { setVaultExitConfirmation(false); }}
          onSecondaryAction={exitVaultPage}
        />
      </div>
    </>
  );
};

export default VaultPage;
