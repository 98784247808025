import { SnapIcon } from "../suit";

interface ViewDocumentsLinkProps {
  testId: string;
  formId: number;
  submissionId: number;
  submitterId: string;
  token: string;
  documentsCount: number;
  className?: string;
}

const ViewDocumentsLink : React.FC<ViewDocumentsLinkProps> = ({ testId, formId, submissionId, submitterId, token, documentsCount, className = "" }) => {

  const navigateToDocumentsPage = () => {
    window.open(`${window.location.origin}/approvals/documents?f=${formId}&u=${submitterId}&s=${submissionId}&t=${token}`, "_self");
  };

  return (
    <div
      data-testid={testId}
      className={`flex flex-col text-center cursor-pointer w-full py-3 bg-blue-50 border-blue-300 border rounded-md ${className}`}
      onClick={navigateToDocumentsPage}
    >
      <p className="flex flex-row items-center justify-center text-base font-bold text-blue-700">
        <SnapIcon icon="document-text-solid" size="sm" color="#1D4ED8" className="ml-2" />
        <span>Click here to view documents.</span>
      </p>
      <p className="text-sm font-medium text-gray-500">{documentsCount} {documentsCount > 1 ? "documents" : "document"}</p>
    </div>
  );
}

export default ViewDocumentsLink;
