import Skeleton from "../components/Skeleton";

const SnapFundraiserDetailsSkeleton = () => {
  return (
    <div className="flex flex-col gap-4 font-medium text-gray-500 text-sm pb-3">
      <div className="w-1/6 justify-between mb-3">
        <Skeleton variant="text" height={18} />
      </div>
      <Skeleton
        variant="text"
        height={14}
        lineCount={2}
        className="flex gap-[40%] w-full justify-between"
      />
      <Skeleton
        variant="text"
        height={14}
        lineCount={2}
        className="flex gap-[40%] w-full justify-between"
      />
      <Skeleton
        variant="text"
        height={14}
        lineCount={2}
        className="flex gap-[40%] w-full justify-between"
      />
      <div className=" mt-3">
        <Skeleton variant="text" height={16} className=" w-1/5 mb-4" />
        <Skeleton variant="text" height={14} lineCount={4} />
      </div>
      <div>
        <Skeleton variant="text" height={16} className=" w-1/5 mb-4" />
        <Skeleton variant="text" height={14} lineCount={4} />
      </div>
    </div>
  );
}

export default SnapFundraiserDetailsSkeleton;
