import { PhoneRegEx } from "../components/preApprovals/preApprovalConstants";

export const validatePhoneNumber = (input:string) => {
    let error = ""
    if(input){
      const trimmedInput = input.trim();
      if(trimmedInput !== ""){
        if (PhoneRegEx.test(trimmedInput)) {
          error = "";
        } else {
          error = "Please enter a valid area code and phone number.";
        }
      }
    }
    return error;
  }