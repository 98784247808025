import { AppState } from "../AppGlobalState";

export const ALLOWED_USER_CONFIGURATIONS = {
  "VAULT_WELCOME_DISMISSED": "VAULT_WELCOME_DISMISSED",
  "VAULT_ORG_APPROVED_DISMISS_BANNER": "VAULT_ORG_APPROVED_DISMISS_BANNER"
} as const;

export const testUserConfiguration = (appState:AppState, name:string, value:string): boolean => {
  return appState.userConfigs && appState.userConfigs[name] === value;
};

export const testUserConfigurationContains = (appState:AppState, name:string, value:string): boolean => {
  return appState.userConfigs && !!appState.userConfigs[name] && appState.userConfigs[name].includes(value);
};

export const getUserConfiguration = (appState:AppState, name:string): string => {
  const configFound = appState.userConfigs && appState.userConfigs[name];
  return configFound || "";
};

export const appendToUserConfigurationValue = (configValue:string, value:string, separator:string = ","): string => {
  const configValueArray:string[] = configValue.split(separator);
  const configValueArraySanitized:string[] = configValueArray.filter(str => str.length > 0);
  configValueArraySanitized.push(value);
  return configValueArraySanitized.join(separator);
};
