import { useContext, useState } from "react";
import { isScreenSizeBelow } from "../../utils/UIUtils";
import WhiteCard from "../../components/WhiteCard";
import { AppContext } from "../../AppGlobalState";
import { SnapButton, SnapAccordion, SnapAlert, SnapAlertTitle } from "../../suit";
import { getAffiliatesFromDistrict, getDashboards, getFloatingUserAssociations, getSchoolsFromDistrict } from '../../data-helpers/OrgsDataHelper';
import { OrganizationNotificatinosSchoolList } from "./OrganizationNotificatinosSchoolList";
import { Exact, InsSetNotificationPreferenceFlag, InsSetNotificationType, InsightsNotificationPreferencesQuery, Org, OrgNotificationPreference, useInsightsSetNotificationPreferencesMutation, usePermissionsQuery } from "../../graphql/generated";
import { sortOrgsByName } from "../../utils/RenderDataUtils";
import { UseOrgNotificationsSettings } from "./UseOrgNotificationSettings";
import { ApolloQueryResult } from "@apollo/client";
import { AlertType } from "@snap-mobile/snap-ui/dist/types/utils";

interface OrganizationNotificationsProps  {
  className?: string;
  orgNotificationPreferences: OrgNotificationPreference[];
  refetchUserNotifications: (variables?: Partial<Exact<{
    userId: string;
}>> | undefined) => Promise<ApolloQueryResult<InsightsNotificationPreferencesQuery>>
}

interface districtWithSchools {
  districtName: String
  schools: Org[]
  affilates: Org[]
}
interface districtsWithSchoolTree {
  [key:string]: districtWithSchools
}

export const OrganizationNotifications: React.FC<OrganizationNotificationsProps> = ({ className, orgNotificationPreferences, refetchUserNotifications }) => {
  const {appState} = useContext(AppContext);
  const [setNotificationPreferences] = useInsightsSetNotificationPreferencesMutation();
  const { data: user } = usePermissionsQuery();
  const allDistrricts = sortOrgsByName(getDashboards(appState, 'district'))

  const floatingSchoolsAndAffiliates = sortOrgsByName(getFloatingUserAssociations(appState));
 
  let districtsWithSchoolTrees: districtsWithSchoolTree = allDistrricts.reduce((mem, district)=>{
    const schools = sortOrgsByName(getSchoolsFromDistrict(appState, district))  
    const affilates = sortOrgsByName(getAffiliatesFromDistrict(appState, district))
    
    return {...mem, [district.id]:{districtName: district.name, schools, affilates}}
  },{});
  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, ] = useState("Your email notifications have been updated.");
  const [alertType, ] = useState<AlertType>("success");

  const {bulkAddRemoveOrg, addOrgs, removeOrgs,  isOrgRemoved, affectedAddedOrgs,  affectedRemovedOrgs, resetOrgNotifications} = UseOrgNotificationsSettings(orgNotificationPreferences)
  const saveChanges = () => {
    setNotificationPreferences({
      variables: {
        userId: user?.me?.id || '',
        preferences: [
          // to turn off / uncheck
          {
            setTo: InsSetNotificationPreferenceFlag.Off,
            type: InsSetNotificationType.Organization,
            organizationIds: affectedRemovedOrgs 
          },
          // to turn on / check
          {
            setTo: InsSetNotificationPreferenceFlag.On,
            type: InsSetNotificationType.Organization,
            organizationIds: affectedAddedOrgs 
          },
        ]
      }
    })
    .then((result) => {
      setShowAlert(true)
      refetchUserNotifications().then(()=>{
        resetOrgNotifications();
      });
      setTimeout(()=>setShowAlert(false), 3000)
    });    
  };
  const onCheckBoxChange =  (event: any)=>{
    bulkAddRemoveOrg(event.detail.values.checkedItems, event.detail.values.uncheckedItems, event.detail.values.currentTarget );
    
  } 

  const saveDisabled  = ()=>{
    return affectedAddedOrgs.length === 0 && affectedRemovedOrgs.length === 0
  }
  return (
    <WhiteCard fullPageWidth={isScreenSizeBelow(appState.windowSize.screen, 'sm')} className={`${className}`}>
      {showAlert ? (
        <SnapAlert
        style={{ zIndex: 100 }}
        type={alertType}
        border={true}
        close={true}
        onSnap-alert-closed={() => setShowAlert(!showAlert)}
        
        className="opacity-100 fixed xs:pr-14 xs:w-full md:w-3/4 top-32"
      >
        <SnapAlertTitle>{alertTitle}</SnapAlertTitle>
      </SnapAlert>
      ) : null}
      <h4 className="font-semibold text-xl text-gray-800 pb-4">Organization Notifications</h4>
      <p className="font-normal text-base text-gray-800 mb-4">Select the organizations you would like to receive notifications on.</p>
      {
        allDistrricts.map((district, index)=>{
          return (
            <div className={`${(index !== (allDistrricts.length - 1)) ? 'mb-4' : ''}`} key={'accord-' + district.id}>
              <SnapAccordion checkboxValue={district.id} key={`accordion-${district.name}`} onSnap-accordion-checkbox-group-updated={onCheckBoxChange} _title={district.name || ""} checkbox={true} >
                  { districtsWithSchoolTrees[district.id].schools.length > 0 &&
                    <>
                      <p className="font-medium text-sm text-gray-800 py-2">Schools</p>
                      <OrganizationNotificatinosSchoolList 
                        schoolsOrAffiliate={districtsWithSchoolTrees[district.id].schools} 
                        notificationPreferences={orgNotificationPreferences} 
                        isOrgRemoved={isOrgRemoved}
                        addOrgs={addOrgs}
                        removeOrgs={removeOrgs}
                      /> 
                    </>
                  }
                  { districtsWithSchoolTrees[district.id].affilates.length > 0 &&
                    <>
                      <p className="font-medium text-sm text-gray-800 py-2">Affiliates</p>
                        <OrganizationNotificatinosSchoolList 
                        schoolsOrAffiliate={districtsWithSchoolTrees[district.id].affilates} 
                        notificationPreferences={orgNotificationPreferences} 
                        isOrgRemoved={isOrgRemoved}
                        addOrgs={addOrgs}
                        removeOrgs={removeOrgs}
                        />
                    </>
                  }
              </SnapAccordion>
            </div>
          )})
      }
      {floatingSchoolsAndAffiliates.length > 0 && 
        ( 
          <div className="ml-2 py-4">
            <p className="font-medium text-sm text-gray-800 py-2">Other schools/affiliates</p>
            <OrganizationNotificatinosSchoolList 
              schoolsOrAffiliate={floatingSchoolsAndAffiliates} 
              notificationPreferences={orgNotificationPreferences} 
              isOrgRemoved={isOrgRemoved}
              addOrgs={addOrgs}
              removeOrgs={removeOrgs}
            />
          </div>
            
          
        )
      }
      <div className="flex flex-row justify-end">
        <SnapButton 
          size="md"
          variant="primary"
          fullWidth={true}
          className="w-full sm:w-auto"
          onClick={saveChanges}
          disabled={saveDisabled()}
        >
          Save Changes
        </SnapButton>
      </div>
    </WhiteCard>
  )
}

