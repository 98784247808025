import { useContext } from 'react';
import {SnapGlobalHeader, SnapHeaderWrapper, SnapLogo} from '../suit'
import { AppContext } from '../AppGlobalState';
import { ApprovalsContext } from '../ApprovalsGlobalState';

interface HeaderProps {
  product?: Text;
  theme?: "insights" | "mobile" | "connect" | "drive" | "manage" | "raise" | "sites" | "spend" | "sponsor" | "store" | "wallet" | undefined;
}

function Header(props: HeaderProps) {
  const {product, theme} = props;
  const {appState} = useContext(AppContext);
  const {appState: approvalsAppState} = useContext(ApprovalsContext);
 
  return (
    <SnapGlobalHeader theme={`${theme? theme : 'insights'}`} impersonationMode={appState.impersonateMode || approvalsAppState.impersonateMode}>
      <SnapLogo product={`${product ? product: "snap-logo-insights" }`} white data-testid="snap-logo"></SnapLogo>
      <SnapHeaderWrapper showLoginButtons={false}></SnapHeaderWrapper>
    </SnapGlobalHeader>
  );
}

export default Header
