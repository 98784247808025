
import { useCallback, useState } from "react"
import { UploadFunction } from "../utils/upload-file";

const useS3FileUpload = (
  uploadFile: UploadFunction,
  autoUpload: boolean = false,
  onFilesUploaded = Function
) => {
  const [uploadProgress, setUploadProgress] = useState<Record<number, number>>({});

  const handleUpload = useCallback(async (files: File[], urls: string[]) => {
    const uploadPromises = files.map((file, idx) => {
      const signedUrl = urls[idx];
      const progressRef = {
        upload: (event: ProgressEvent) => {
          setUploadProgress((previousProgress) => {
            return {
              ...previousProgress,
              [file.name]: Math.round((event.loaded * 100) / event.total)
            }
          })
        }
      }
      return uploadFile(signedUrl, file, progressRef)
    })
    const response = await Promise.allSettled(uploadPromises);
    return response;
  }, [uploadFile])

  return {
    handleUpload,
    uploadProgress
  }
}

export default useS3FileUpload;

