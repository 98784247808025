import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: any;
  Buffer: any;
  Date: any;
  DateTime: any;
  DateType: any;
  Decimal: any;
  /** The `Int32` scalar type represents non-fractional signed whole numeric values. Int32 can represent values between -(2^31) and 2^31 - 1.  */
  Int32: any;
  /** The `Int64` scalar type represents non-fractional signed whole numeric values. Int64 can represent values between -(2^63) and 2^63 - 1.  */
  Int64: any;
  JSON: any;
  JSONObject: any;
  NonEmptyString: any;
  Time: any;
  /** A `Timestamp` represents a point in time independent of any time zone or calendar, represented as seconds and fractions of seconds at nanosecond resolution in UTC Epoch time. */
  Timestamp: any;
  UUID: any;
  Uint64: any;
  Upload: any;
  Void: any;
  udid: any;
};

export type Account = ExternalBankAccount | StripeAccount;

/** Account balance information */
export type AccountBalanceReturn = {
  __typename?: 'AccountBalanceReturn';
  /** Available balance in cents */
  available?: Maybe<Scalars['Int']>;
  /** Pending balance in cents */
  pending?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

export type AccountManager = DriveUser & {
  __typename?: 'AccountManager';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type AccountTransactionsInput = {
  accountId: Scalars['String'];
  createdAfter?: InputMaybe<Scalars['Timestamp']>;
  createdBefore?: InputMaybe<Scalars['Timestamp']>;
  exclude?: InputMaybe<Array<InputMaybe<TransactionFilterType>>>;
  externalId?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['JSONObject']>;
  status?: InputMaybe<Status>;
};

export type AccountTransactionsPaginatedInput = {
  accountId: Scalars['String'];
  createdAfter?: InputMaybe<Scalars['Timestamp']>;
  createdBefore: Scalars['Timestamp'];
  cursor?: InputMaybe<Scalars['String']>;
  /** Currently only support 1000 */
  pageSize?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
};

export type AccountTransactionsPaginatedOutput = {
  __typename?: 'AccountTransactionsPaginatedOutput';
  nextCursor?: Maybe<Scalars['String']>;
  transactions: Array<Maybe<Transaction>>;
};

export enum AccountType {
  ExternalBank = 'EXTERNAL_BANK',
  /** more TK */
  Stripe = 'STRIPE'
}

export type AdhocGiftShopIntentInput = {
  fundraiserId: Scalars['Int'];
  fundraiserUserId?: InputMaybe<Scalars['Int']>;
  participantUserDirectoryId?: InputMaybe<Scalars['String']>;
  status: PurchaseIntentStatus;
  userId?: InputMaybe<Scalars['Int']>;
};

/** See CampaignMembership for details */
export type AdminCampaign = {
  __typename?: 'AdminCampaign';
  id: Scalars['ID'];
};

export enum Affiliate {
  Booster = 'BOOSTER',
  Foundation = 'FOUNDATION',
  Other = 'OTHER',
  ParentAssociation = 'PARENT_ASSOCIATION',
  ParentOrganization = 'PARENT_ORGANIZATION',
  ParentTeacherAssociation = 'PARENT_TEACHER_ASSOCIATION',
  ParentTeacherOrganization = 'PARENT_TEACHER_ORGANIZATION',
  ParentTeacherStudentAssociation = 'PARENT_TEACHER_STUDENT_ASSOCIATION',
  ParentTeacherStudentOrganization = 'PARENT_TEACHER_STUDENT_ORGANIZATION'
}

export enum AffiliationTitle {
  ActivitiesDirector = 'ACTIVITIES_DIRECTOR',
  AdministrativeAssistant = 'ADMINISTRATIVE_ASSISTANT',
  AssistantCoach = 'ASSISTANT_COACH',
  AthleticDirector = 'ATHLETIC_DIRECTOR',
  BoosterClubLeader = 'BOOSTER_CLUB_LEADER',
  BoosterClubMember = 'BOOSTER_CLUB_MEMBER',
  BusinessOwner = 'BUSINESS_OWNER',
  ClubSportsAdministrator = 'CLUB_SPORTS_ADMINISTRATOR',
  ClubSportsDirector = 'CLUB_SPORTS_DIRECTOR',
  ClubSportEmployee = 'CLUB_SPORT_EMPLOYEE',
  Coach = 'COACH',
  DistrictAdministrator = 'DISTRICT_ADMINISTRATOR',
  FinancialAdministrator = 'FINANCIAL_ADMINISTRATOR',
  FineArtsDirector = 'FINE_ARTS_DIRECTOR',
  Other = 'OTHER',
  Parent = 'PARENT',
  President = 'PRESIDENT',
  SchoolAdministrator = 'SCHOOL_ADMINISTRATOR',
  SchoolClubAdvisor = 'SCHOOL_CLUB_ADVISOR',
  Sponsor = 'SPONSOR',
  StateAdministrator = 'STATE_ADMINISTRATOR',
  Student = 'STUDENT',
  TeacherInstructor = 'TEACHER_INSTRUCTOR',
  Volunteer = 'VOLUNTEER'
}

export type ApiKey = {
  __typename?: 'ApiKey';
  createdAt: Scalars['Date'];
  expiresAt?: Maybe<Scalars['Date']>;
  id: Scalars['String'];
  label: Scalars['String'];
  maxRps: Scalars['Int'];
  name: Scalars['String'];
  permissions: Array<ApiKeyPermission>;
  secret: Scalars['String'];
  user: User;
};

export type ApiKeyPermission = {
  __typename?: 'ApiKeyPermission';
  permission: Permission;
};

export type ApproveFundraiserApprovalSubmissionData = {
  __typename?: 'ApproveFundraiserApprovalSubmissionData';
  isSsoApprover: Scalars['Boolean'];
  submitterName: Scalars['String'];
};

export type ApproveFundraiserApprovalSubmissionResult = ApproveFundraiserApprovalSubmissionData | InvalidToken;

export type AssignmentInput = {
  id: Scalars['String'];
  type: ChannelTypesEnum;
};

export type AuditLogEntry = {
  __typename?: 'AuditLogEntry';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  payload: Scalars['JSON'];
  service: AuditLogService;
  source: AuditLogSource;
  type: AuditLogType;
  userId?: Maybe<Scalars['String']>;
};

export type AuditLogList = {
  __typename?: 'AuditLogList';
  list: Array<Maybe<AuditLogEntry>>;
};

export enum AuditLogService {
  Connect = 'Connect',
  Donors = 'Donors',
  Drive = 'Drive',
  Home = 'Home',
  Insights = 'Insights',
  Manage = 'Manage',
  Orgs = 'Orgs',
  Raise = 'Raise',
  Spend = 'Spend',
  Sponsor = 'Sponsor',
  Store = 'Store',
  UserDirectory = 'UserDirectory',
  Vault = 'Vault',
  Wallet = 'Wallet'
}

export enum AuditLogSource {
  Backend = 'Backend',
  Db = 'Db',
  Frontend = 'Frontend'
}

export enum AuditLogType {
  Debug = 'Debug',
  Error = 'Error',
  Info = 'Info',
  Warning = 'Warning'
}

export type Auth = Tokens & {
  __typename?: 'Auth';
  accessToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
};

export type AuthChallenge = Tokens & {
  __typename?: 'AuthChallenge';
  accessToken?: Maybe<Scalars['String']>;
  challenges?: Maybe<Array<Maybe<UserChallenge>>>;
  refreshToken?: Maybe<Scalars['String']>;
};

export type AuthenticationFlow = {
  __typename?: 'AuthenticationFlow';
  name?: Maybe<Scalars['String']>;
  redirectUrl?: Maybe<Scalars['String']>;
};

export enum BasicCampaignStatus {
  Active = 'ACTIVE',
  Closed = 'CLOSED',
  Upcoming = 'UPCOMING'
}

/** BoolFilter is used for filtering based on boolean values. */
export type BoolFilter = {
  /** Checks if the boolean field is equal to the specified value. */
  equals?: InputMaybe<Scalars['Boolean']>;
  /** Nested filter for negating a condition. */
  not?: InputMaybe<NestedBoolFilter>;
};

/** Filtering options for nullable Boolean type */
export type BoolNullableFilter = {
  /** Equals operation */
  equals?: InputMaybe<Scalars['Boolean']>;
  /** Not Equal operation */
  not?: InputMaybe<Scalars['Boolean']>;
};

export type Brand = {
  __typename?: 'Brand';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
};

export type BudgetTransaction = {
  __typename?: 'BudgetTransaction';
  id: Scalars['UUID'];
  status?: Maybe<Status>;
};

export type BulkRewards = {
  __typename?: 'BulkRewards';
  id: Scalars['Int'];
  incentiveId?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['String']>;
  tier?: Maybe<Scalars['Int']>;
};

export enum Card_Status {
  Active = 'active',
  Canceled = 'canceled',
  Inactive = 'inactive'
}

export enum Card_Type {
  Physical = 'physical',
  Virtual = 'virtual'
}

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

/** @deprecated: will be merged with DriveCampaign instead This type is for Drive's Campaign Management. Users must have the Drive's permission to access any data. We are working on migrating this type to DriveCampaign. */
export type Campaign = {
  __typename?: 'Campaign';
  /** Account Manager of Campaign */
  accountManager?: Maybe<AccountManager>;
  /** Activity type of campaign. e.g. 'football', 'baseball', etc. */
  activityType?: Maybe<Scalars['String']>;
  approvedFunds?: Maybe<ApprovedFunds>;
  /** URL for Generated Report of (Active) Campaign */
  campaignActiveReportUrl?: Maybe<Scalars['String']>;
  /** City + State string of campaign. e.g. 'Dallas, TX' */
  campaignTerritory?: Maybe<Scalars['String']>;
  coachJoinCode?: Maybe<Scalars['String']>;
  /** Donations statistics of campaign. All values will be 0 if campaign is 'upcoming' */
  donations?: Maybe<Donations>;
  /** Participant email delivery info of campaigns */
  emails?: Maybe<EmailsInfo>;
  /** End date of fundraiser */
  endDate?: Maybe<Scalars['DateTime']>;
  /** Entity ID of campaign. This ID used to obtain financial/payment info */
  entityId?: Maybe<Scalars['Int']>;
  /** Date when campaign is finalized */
  finalizedDate?: Maybe<Scalars['DateTime']>;
  /** Shipping status for gear items. Values are 'delivered','pre_transit','in_transit','failure','available_for_pickup','unknown','out_for_delivery', 'return_to_sender','cancelled','error' */
  gearStatus?: Maybe<Scalars['String']>;
  /** Group Leader */
  groupLeader?: Maybe<GroupLeader>;
  /** Deal ID of campaign in Hubspot. */
  hubspotId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** Cover image of campaign */
  image?: Maybe<Scalars['String']>;
  /** Initial goal amount to fundraise in cents */
  initialGoalCents?: Maybe<Scalars['Int']>;
  /** Link to invite participants to the campaign */
  inviteParticipantUrl?: Maybe<Scalars['String']>;
  /** Unique join-code of campaign */
  joinCode?: Maybe<Scalars['Int']>;
  /** URL for the fundraiser KYC Form. */
  kycFormUrl?: Maybe<Scalars['String']>;
  /** Campaign kyc status */
  kycStatus?: Maybe<CampaignKycStatusResult>;
  /** Mailing address of campaign. */
  mailingAddress?: Maybe<Scalars['String']>;
  /** MDM Contact of Campaign */
  mdm?: Maybe<MdmDetails>;
  name?: Maybe<Scalars['String']>;
  /** Statistics info of campaign participants */
  participants?: Maybe<ParticipantsInfo>;
  /** Last withdrawal payment status. Values are 'Initializing', 'Processing', 'Transferred', 'Cut', 'Deposited', 'Expired' or 'Failed' */
  paymentStatus?: Maybe<Scalars['String']>;
  /** Payment type when campaign finalized. Values are 'Check to Office', 'Check to Salesrep', 'Check to Fundraiser', or 'Direct Deposit'. */
  paymentType?: Maybe<Scalars['String']>;
  /** Base64 generated CSV file for preload emails report of event */
  preloadReport?: Maybe<Scalars['String']>;
  /** Download URL for Final Receipt (PDF) of settled campaigns */
  receiptDownloadUrl?: Maybe<Scalars['String']>;
  /** Settlement Details for Closed Campaigns */
  settlementDetails?: Maybe<SettlementDetails>;
  /** Settlement Confirmation Status for Closed Campaigns. Values are 'UNSUBMITTED', 'IN_REVIEW' and 'VERIFIED' */
  settlementStatus?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  /** Start date of fundraiser */
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  /** Size of team/group of campaign */
  teamSize?: Maybe<Scalars['Int']>;
  /** Not in-use. Deprecated */
  testEmails?: Maybe<Scalars['String']>;
  /** Total amount raised in cents */
  totalRaisedCents?: Maybe<Scalars['Int']>;
  /** The total raised combined this organization has previously ran campaigns */
  totalRaisedHistory?: Maybe<Scalars['Int']>;
  /** Tracking link for shipping gear items. */
  trackingLink?: Maybe<Scalars['String']>;
};

export type CampaignDates = {
  __typename?: 'CampaignDates';
  endDateTime: Scalars['String'];
  isAllDay?: Maybe<Scalars['Boolean']>;
  startDateTime: Scalars['String'];
};

/** Resigned campaigns */
export type CampaignHistoryList = {
  __typename?: 'CampaignHistoryList';
  CampaignHistoryEndDate?: Maybe<Scalars['String']>;
  CampaignHistorySlug?: Maybe<Scalars['String']>;
  CampaignHistoryStartDate?: Maybe<Scalars['String']>;
  campaignName?: Maybe<Scalars['String']>;
  campaignTotalRaised?: Maybe<Scalars['Int']>;
};

export type CampaignKyc = {
  __typename?: 'CampaignKyc';
  status?: Maybe<CampaignKycStatusEnum>;
};

/** Fixed statuses for a campaigns kyc */
export enum CampaignKycStatus {
  ActionRequired = 'action_required',
  InReview = 'in_review',
  RequireDocs = 'require_docs',
  Unsubmitted = 'unsubmitted',
  Unverified = 'unverified',
  Verified = 'verified'
}

export enum CampaignKycStatusEnum {
  ActionNeeded = 'ACTION_NEEDED',
  Pending = 'PENDING',
  Unsubmitted = 'UNSUBMITTED',
  Verified = 'VERIFIED'
}

export type CampaignKycStatusResult = {
  __typename?: 'CampaignKycStatusResult';
  data?: Maybe<CampaignKycStatus>;
  type?: Maybe<CampaignKycType>;
};

/** Fixed type for a campaigns kyc */
export enum CampaignKycType {
  Stripe = 'stripe',
  Wepay = 'wepay'
}

export type CampaignList = {
  __typename?: 'CampaignList';
  count?: Maybe<Scalars['Int']>;
  list: Array<Maybe<Campaign>>;
  offset?: Maybe<Scalars['Int']>;
};

export enum CampaignMemberAssociation {
  Admin = 'ADMIN',
  Coach = 'COACH',
  Cocoach = 'COCOACH',
  Coparticipant = 'COPARTICIPANT',
  Participant = 'PARTICIPANT',
  Supporter = 'SUPPORTER'
}

/** CampaignMembership is our way of securing information based on a users membership type. When a user queries campaignMemberships they can get information on their memberships based on who they are as defined in the JWT. Example of how to use:https://www.notion.so/snap-mobile/Campaign-1b4c4a055bc84aaf8290f078f57a5193 */
export type CampaignMembership = AdminCampaign | GroupLeaderCampaign | ParticipantCampaign | SupporterCampaign;

export type CampaignPersonList = {
  __typename?: 'CampaignPersonList';
  id: Scalars['Int'];
};

export type CampaignPersonListData = {
  __typename?: 'CampaignPersonListData';
  email: Scalars['String'];
  id?: Maybe<Scalars['Int']>;
};

export type CampaignPersonListEntries = {
  __typename?: 'CampaignPersonListEntries';
  invalidEntries?: Maybe<Array<Maybe<InvalidCampaignPersonListData>>>;
  validEntries?: Maybe<Array<Maybe<CampaignPersonListData>>>;
};

export type CampaignPersonListEntryDelete = {
  __typename?: 'CampaignPersonListEntryDelete';
  id: Scalars['Int'];
};

export type CampaignSearchFilter = {
  __typename?: 'CampaignSearchFilter';
  dateCreated?: Maybe<Scalars['DateTime']>;
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** Collection (key/values) of saved search criteria. */
  filterCriteria?: Maybe<Scalars['JSON']>;
  /** Name of the saved search filter. */
  filterName?: Maybe<Scalars['String']>;
  /** ID of saved search filters. */
  id?: Maybe<Scalars['Int']>;
};

export type CampaignSmsDataResponse = {
  __typename?: 'CampaignSmsDataResponse';
  invalidEntries: Array<InvalidCampaignSmsInviteData>;
  status?: Maybe<CampaignSmsInviteStatus>;
  validEntries: Array<CampaignSmsInviteData>;
};

export type CampaignSmsInviteData = {
  __typename?: 'CampaignSmsInviteData';
  id: Scalars['Int'];
  phoneNumber: Scalars['String'];
};

export type CampaignSmsInviteDeleteResponse = {
  __typename?: 'CampaignSmsInviteDeleteResponse';
  status: Scalars['String'];
};

export enum CampaignSmsInviteStatus {
  Preloaded = 'PRELOADED',
  PreloadQueued = 'PRELOAD_QUEUED',
  Queued = 'QUEUED'
}

export enum CampaignStatus {
  Active = 'ACTIVE',
  Approved = 'APPROVED',
  Archive = 'ARCHIVE',
  Closed = 'CLOSED',
  Deleted = 'DELETED',
  Final = 'FINAL',
  PendingApproval = 'PENDING_APPROVAL',
  Settled = 'SETTLED'
}

/** Owned by vault Extended by wallet Card issued by vault via Stripe */
export type Card = {
  __typename?: 'Card';
  cardNumber?: Maybe<Scalars['String']>;
  cardType?: Maybe<VaultCardType>;
  cardholderId?: Maybe<Scalars['String']>;
  cvv?: Maybe<Scalars['String']>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  gatewayId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  last4?: Maybe<Scalars['String']>;
  metadata?: Maybe<VaultCardMetadata>;
  spendingLimitAmount?: Maybe<Scalars['Int']>;
  spendingLimitInterval?: Maybe<VaultSpendingLimitInterval>;
  status?: Maybe<VaultCardStatus>;
  walletEverActivated?: Maybe<Scalars['Boolean']>;
};

export type CarouselItem = {
  __typename?: 'CarouselItem';
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  media_type?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export enum CategoryFilterEnum {
  Name = 'name',
  Type = 'type'
}

export enum CategoryTypeEnum {
  Expense = 'expense',
  Income = 'income'
}

export type Channel = {
  __typename?: 'Channel';
  chatProviderId: Scalars['String'];
  createdAt: Scalars['Date'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  status: ChannelStatusEnum;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type ChannelCreateError = Error & {
  __typename?: 'ChannelCreateError';
  message: Scalars['String'];
};

export type ChannelNotFound = Error & {
  __typename?: 'ChannelNotFound';
  message: Scalars['String'];
};

export enum ChannelStatusEnum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Disabled = 'DISABLED',
  Frozen = 'FROZEN'
}

export enum ChannelTypesEnum {
  Fundraiser = 'fundraiser'
}

export type ChannelUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  status?: InputMaybe<ChannelStatusEnum>;
};

export type Channels = {
  __typename?: 'Channels';
  channels?: Maybe<Array<Maybe<Channel>>>;
};

export type ChatChannelAddModeratorsResult = Channel | ChannelNotFound | NotAuthorized;

export type ChatChannelAddUsersResult = Channel | ChannelNotFound | NotAuthorized;

export type ChatChannelCreateResult = Channel | ChannelCreateError | NotAuthorized;

export type ChatChannelResult = Channel | ChannelNotFound | MissingArguments | NotAuthenticated;

export type ChatChannelUpdateMembersResult = Channel | ChannelNotFound | MissingArguments | NotAuthorized;

export type ChatChannelUpdateResult = Channel | NotAuthorized;

export type ChatChannelsResult = Channels | NotAuthenticated;

export type ChatToken = {
  __typename?: 'ChatToken';
  accessToken: Scalars['String'];
};

export type ChatTokenResult = ChatToken | NotAuthenticated;

export type ChatUnreadMessagesResult = NotAuthenticated | UnreadCount;

export type Check = {
  __typename?: 'Check';
  amount: Scalars['Decimal'];
  campaignId: Scalars['Int'];
  checkNumber: Scalars['String'];
  created: Scalars['Timestamp'];
  destination: Account;
  effective: Scalars['Timestamp'];
  expiration: Scalars['Timestamp'];
  payee: Payee;
  status: CheckStatus;
  transactionId: Scalars['UUID'];
};

export enum CheckStatus {
  Deposited = 'DEPOSITED',
  /** TODO(SHR): update this once we get actual statuses from bill.com */
  Processing = 'PROCESSING',
  Sent = 'SENT'
}

export type Cheer = {
  __typename?: 'Cheer';
  anonymous?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  donorMessage?: Maybe<Scalars['String']>;
  donorName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastName?: Maybe<Scalars['String']>;
  subtotalCents?: Maybe<Scalars['Int']>;
};

/** @deprecated */
export type CheerWallData = {
  __typename?: 'CheerWallData';
  createdAt?: Maybe<Scalars['DateTime']>;
  donorMessage?: Maybe<Scalars['String']>;
  donorName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  participant?: Maybe<ParticipantData>;
  subtotalCents?: Maybe<Scalars['Int']>;
};

export type CombinedPermissions = {
  __typename?: 'CombinedPermissions';
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CommonCalendar = {
  __typename?: 'CommonCalendar';
  activity?: Maybe<Scalars['String']>;
  bus_count?: Maybe<Scalars['Int']>;
  comments?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['String']>;
  driver_name?: Maybe<Scalars['String']>;
  driver_phone?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['String']>;
  event_date?: Maybe<Scalars['String']>;
  facility?: Maybe<Scalars['String']>;
  g_s?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  home_field?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  levels?: Maybe<Scalars['String']>;
  opponent?: Maybe<Scalars['String']>;
  place?: Maybe<Scalars['String']>;
  season_id?: Maybe<Scalars['Int']>;
  sports_code?: Maybe<Scalars['String']>;
  sports_description?: Maybe<Scalars['String']>;
  sports_group?: Maybe<Scalars['String']>;
  sports_name?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['String']>;
  vehicle_id?: Maybe<Scalars['String']>;
  vehicle_type?: Maybe<Scalars['String']>;
};

export type CommonCalendarFiltersInput = {
  activity?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  facilities?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  gender?: InputMaybe<Scalars['String']>;
  group?: InputMaybe<Scalars['String']>;
  homeAway?: InputMaybe<Scalars['String']>;
  levels?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  opponents?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startDate?: InputMaybe<Scalars['Date']>;
  teams?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  vehicleType?: InputMaybe<Scalars['String']>;
};

export type CommonGrid = {
  __typename?: 'CommonGrid';
  activity?: Maybe<Scalars['String']>;
  bus_count?: Maybe<Scalars['Int']>;
  comments?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['String']>;
  driver_name?: Maybe<Scalars['String']>;
  driver_phone?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['String']>;
  event_date?: Maybe<Scalars['String']>;
  facility?: Maybe<Scalars['String']>;
  g_s?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  home_field?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  levels?: Maybe<Scalars['String']>;
  place?: Maybe<Scalars['String']>;
  season_id?: Maybe<Scalars['Int']>;
  sports_code?: Maybe<Scalars['String']>;
  sports_description?: Maybe<Scalars['String']>;
  sports_group?: Maybe<Scalars['String']>;
  sports_name?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['String']>;
  vehicle_id?: Maybe<Scalars['String']>;
  vehicle_type?: Maybe<Scalars['String']>;
};

export type CommonGridFiltersInput = {
  endDate?: InputMaybe<Scalars['Date']>;
  facilities?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  gender?: InputMaybe<Scalars['String']>;
  levels?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startDate?: InputMaybe<Scalars['Date']>;
  teams?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CommonSchedules = {
  __typename?: 'CommonSchedules';
  activity?: Maybe<Scalars['String']>;
  bus_count?: Maybe<Scalars['Int']>;
  bus_departure_location?: Maybe<Scalars['String']>;
  bus_early_dismissal_time?: Maybe<Scalars['String']>;
  bus_estimated_return_time?: Maybe<Scalars['String']>;
  bus_time?: Maybe<Scalars['String']>;
  cancellation_status?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['String']>;
  driver_name?: Maybe<Scalars['String']>;
  driver_phone?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['String']>;
  event_date?: Maybe<Scalars['String']>;
  facility?: Maybe<Scalars['String']>;
  g_s?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  home_field?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  levels?: Maybe<Scalars['String']>;
  opponent?: Maybe<Scalars['String']>;
  place?: Maybe<Scalars['String']>;
  season_id?: Maybe<Scalars['Int']>;
  sports_code?: Maybe<Scalars['String']>;
  sports_description?: Maybe<Scalars['String']>;
  sports_group?: Maybe<Scalars['String']>;
  sports_name?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['String']>;
  vehicle_id?: Maybe<Scalars['String']>;
  vehicle_type?: Maybe<Scalars['String']>;
};

export type CommonSchedulesFiltersInput = {
  activity?: InputMaybe<Scalars['String']>;
  activityName?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  endDate?: InputMaybe<Scalars['Date']>;
  facilities?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  gender?: InputMaybe<Scalars['String']>;
  group?: InputMaybe<Scalars['String']>;
  homeAway?: InputMaybe<Scalars['String']>;
  levels?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  opponents?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startDate?: InputMaybe<Scalars['Date']>;
  vehicleType?: InputMaybe<Scalars['String']>;
};

export type CommonSheet = {
  __typename?: 'CommonSheet';
  activity?: Maybe<Scalars['String']>;
  bus_count?: Maybe<Scalars['Int']>;
  comments?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['String']>;
  driver_name?: Maybe<Scalars['String']>;
  driver_phone?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['String']>;
  event_date?: Maybe<Scalars['String']>;
  facility?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['Float']>;
  g_s?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  home_field?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  levels?: Maybe<Scalars['String']>;
  place?: Maybe<Scalars['String']>;
  season_id?: Maybe<Scalars['Int']>;
  sports_code?: Maybe<Scalars['String']>;
  sports_description?: Maybe<Scalars['String']>;
  sports_group?: Maybe<Scalars['String']>;
  sports_name?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['String']>;
  vehicle_id?: Maybe<Scalars['String']>;
  vehicle_type?: Maybe<Scalars['String']>;
};

export type CommonSheetFiltersInput = {
  activity?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  facilities?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  gender?: InputMaybe<Scalars['String']>;
  group?: InputMaybe<Scalars['String']>;
  homeAway?: InputMaybe<Scalars['String']>;
  levels?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  opponents?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startDate?: InputMaybe<Scalars['Date']>;
  teams?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  vehicleType?: InputMaybe<Scalars['String']>;
};

export type CommsContactResponse = {
  __typename?: 'CommsContactResponse';
  contact?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<RequestStatus>;
};

export type CommsHtmlResponse = {
  __typename?: 'CommsHtmlResponse';
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  html?: Maybe<Scalars['String']>;
  mjml?: Maybe<Scalars['String']>;
};

export type CommsMessageResponse = {
  __typename?: 'CommsMessageResponse';
  contacts?: Maybe<Array<Maybe<MessageContactResponse>>>;
  execution?: Maybe<Array<Maybe<MessageExecutionResponse>>>;
  id: Scalars['ID'];
  sendAt: Scalars['Date'];
  templateAttributes?: Maybe<Scalars['JSON']>;
};

export type CommsRegisterResponse = {
  __typename?: 'CommsRegisterResponse';
  attributes?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt?: Maybe<Scalars['Date']>;
  emailProvider?: Maybe<SupportedEmailProviders>;
  from?: Maybe<Scalars['String']>;
  fromName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mjml?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  service: SnapService;
  smsProvider?: Maybe<SupportedSmsProviders>;
  subject?: Maybe<Scalars['String']>;
  testData: Scalars['JSON'];
  text?: Maybe<Scalars['String']>;
  transport: MessageTransportType;
  type: MessageType;
};

export type CommsSendResponse = {
  __typename?: 'CommsSendResponse';
  details?: Maybe<Array<CommsContactResponse>>;
  id: Scalars['ID'];
};

export type CommsTemplateResponse = {
  __typename?: 'CommsTemplateResponse';
  attributes?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt?: Maybe<Scalars['Date']>;
  emailProvider?: Maybe<SupportedEmailProviders>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  from?: Maybe<Scalars['String']>;
  fromName?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mjml?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  service: SnapService;
  smsProvider?: Maybe<SupportedSmsProviders>;
  subject?: Maybe<Scalars['String']>;
  testData: Scalars['JSON'];
  text?: Maybe<Scalars['String']>;
  transport: MessageTransportType;
  type: MessageType;
};

export type CommsTestResponse = {
  __typename?: 'CommsTestResponse';
  message?: Maybe<Scalars['String']>;
};

export type Consumer = {
  __typename?: 'Consumer';
  accessBy?: Maybe<Scalars['String']>;
  color: Scalars['String'];
  description: Scalars['String'];
  icon: Scalars['String'];
  id: Scalars['String'];
  inApps: Scalars['Boolean'];
  logo: Scalars['String'];
  mask: Scalars['String'];
  modal?: Maybe<ConsumerModal>;
  title: Scalars['String'];
  url: Scalars['String'];
};

export type ConsumerModal = {
  __typename?: 'ConsumerModal';
  descriptions: Array<Maybe<Scalars['String']>>;
  header: Scalars['String'];
};

export enum ContactTemplateMedium {
  Email = 'EMAIL',
  TextMessage = 'TEXT_MESSAGE'
}

export enum ContactTemplateType {
  GroupLeader = 'GROUP_LEADER',
  Participant = 'PARTICIPANT'
}

export type ContractItem = {
  __typename?: 'ContractItem';
  event_contract?: Maybe<EventContract>;
  event_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

export type ContractSignees = {
  __typename?: 'ContractSignees';
  auth_hash?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  email_id?: Maybe<Scalars['String']>;
  event_contract_number?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  original_contract?: Maybe<Scalars['String']>;
  sent_date?: Maybe<Scalars['Date']>;
  sign_contract?: Maybe<Scalars['String']>;
  sign_date?: Maybe<Scalars['Date']>;
  sign_status?: Maybe<Scalars['String']>;
};

/** Owned by Vault Only used in legacy createCard */
export type CoreCardFields = {
  __typename?: 'CoreCardFields';
  cardId: Scalars['String'];
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  gatewayId?: Maybe<Scalars['String']>;
  last4: Scalars['String'];
  name: Scalars['String'];
};

export type CreateChildFundraiserParticipantInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  fundraiserId: Scalars['ID'];
  groupId?: InputMaybe<Scalars['ID']>;
  joinType?: InputMaybe<JoinType>;
  lastName: Scalars['String'];
  udid: Scalars['String'];
};

export type CreateEventInput = {
  activity?: InputMaybe<Scalars['String']>;
  author?: InputMaybe<Scalars['String']>;
  bus_fee?: InputMaybe<Scalars['Float']>;
  bus_time?: InputMaybe<Scalars['String']>;
  cancellation_status?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  conference?: InputMaybe<Scalars['String']>;
  conference_event_id?: InputMaybe<Scalars['Int']>;
  conference_id?: InputMaybe<Scalars['Int']>;
  confirmed?: InputMaybe<Scalars['String']>;
  contract?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['Date']>;
  departure_location?: InputMaybe<Scalars['String']>;
  directions?: InputMaybe<Scalars['String']>;
  early_dismissal_required?: InputMaybe<Scalars['String']>;
  early_dismissal_time?: InputMaybe<Scalars['String']>;
  end_time?: InputMaybe<Scalars['String']>;
  estimated_return_time?: InputMaybe<Scalars['String']>;
  event_date?: InputMaybe<Scalars['Date']>;
  event_id?: InputMaybe<Scalars['Int']>;
  exists_in_mls?: InputMaybe<Scalars['Int']>;
  fee?: InputMaybe<Scalars['Float']>;
  g_s?: InputMaybe<Scalars['String']>;
  gate_revenue?: InputMaybe<Scalars['Float']>;
  headline?: InputMaybe<Scalars['String']>;
  impact_event?: InputMaybe<Scalars['String']>;
  lead?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  loss_points?: InputMaybe<Scalars['Int']>;
  num_buses?: InputMaybe<Scalars['Int']>;
  opponent?: InputMaybe<Scalars['String']>;
  opponent_code?: InputMaybe<Scalars['String']>;
  opponent_score?: InputMaybe<Scalars['String']>;
  picture?: InputMaybe<Scalars['String']>;
  place?: InputMaybe<Scalars['String']>;
  prep_setup?: InputMaybe<Scalars['String']>;
  promote?: InputMaybe<Scalars['String']>;
  results?: InputMaybe<Scalars['String']>;
  revenue?: InputMaybe<Scalars['Float']>;
  rollover?: InputMaybe<Scalars['String']>;
  season_team?: InputMaybe<Scalars['Int']>;
  start_time?: InputMaybe<Scalars['String']>;
  team_score?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  tournament?: InputMaybe<Scalars['String']>;
  trans_id?: InputMaybe<Scalars['Int']>;
  transport_comments?: InputMaybe<Scalars['String']>;
  transportation?: InputMaybe<Scalars['String']>;
  update_at?: InputMaybe<Scalars['Date']>;
  web_dir?: InputMaybe<Scalars['String']>;
  win_points?: InputMaybe<Scalars['Int']>;
  years?: InputMaybe<Scalars['String']>;
};

export type CreateEventParticipantsInput = {
  contract_received?: InputMaybe<Scalars['String']>;
  event_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  paid?: InputMaybe<Scalars['String']>;
  school_code?: InputMaybe<Scalars['String']>;
};

export type CreateEventPreparationInput = {
  comments?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  prep?: InputMaybe<Scalars['String']>;
  qty?: InputMaybe<Scalars['String']>;
};

export type CreateEventTransportDetailsInput = {
  driver_name?: InputMaybe<Scalars['String']>;
  driver_phone?: InputMaybe<Scalars['String']>;
  event_id?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  vehicle_id?: InputMaybe<Scalars['String']>;
  vehicle_type?: InputMaybe<Scalars['String']>;
};

export type CreateFacilityInput = {
  Address1: Scalars['String'];
  Address2?: InputMaybe<Scalars['String']>;
  City: Scalars['String'];
  State: Scalars['String'];
  ZipCode: Scalars['String'];
  directions?: InputMaybe<Scalars['String']>;
  indoor?: InputMaybe<Scalars['String']>;
  ml_site_id?: InputMaybe<Scalars['String']>;
  ml_space?: InputMaybe<Scalars['String']>;
  ml_space_id?: InputMaybe<Scalars['String']>;
  picture?: InputMaybe<Scalars['String']>;
  place_name: Scalars['String'];
  show?: InputMaybe<Scalars['String']>;
  web?: InputMaybe<Scalars['String']>;
};

export type CreateLevelInput = {
  Level?: InputMaybe<Scalars['String']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
};

export type CreateOfficialDutyInput = {
  duty?: InputMaybe<Scalars['String']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
};

export type CreateOfficialInput = {
  duty?: InputMaybe<Scalars['String']>;
  event_id?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  offic_id?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  paid?: InputMaybe<Scalars['String']>;
  pay_code?: InputMaybe<Scalars['String']>;
  received?: InputMaybe<Scalars['String']>;
  salary?: InputMaybe<Scalars['Float']>;
  ssn?: InputMaybe<Scalars['String']>;
  voucher_number?: InputMaybe<Scalars['String']>;
  worker_name?: InputMaybe<Scalars['String']>;
};

export type CreateOfficialPoolByIdInput = {
  Address?: InputMaybe<Scalars['String']>;
  City?: InputMaybe<Scalars['String']>;
  First: Scalars['String'];
  Home_Phone?: InputMaybe<Scalars['String']>;
  ID: Scalars['String'];
  Last: Scalars['String'];
  SSN?: InputMaybe<Scalars['String']>;
  State?: InputMaybe<Scalars['String']>;
  Work_Phone?: InputMaybe<Scalars['String']>;
  Zip?: InputMaybe<Scalars['String']>;
  cell_phone?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  vendor_number?: InputMaybe<Scalars['String']>;
};

export type CreateOpponentInput = {
  Address?: InputMaybe<Scalars['String']>;
  Phone?: InputMaybe<Scalars['String']>;
  SchoolCode: Scalars['String'];
  SchoolName?: InputMaybe<Scalars['String']>;
  State?: InputMaybe<Scalars['String']>;
  Zip?: InputMaybe<Scalars['String']>;
  ad_name?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  fax?: InputMaybe<Scalars['String']>;
  nces_id?: InputMaybe<Scalars['String']>;
  non_school?: InputMaybe<Scalars['Boolean']>;
};

export type CreatePreparationInput = {
  duty?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  qty?: InputMaybe<Scalars['String']>;
};

export type CreateSchoolInfoInput = {
  Schoolname?: InputMaybe<Scalars['String']>;
  ad?: InputMaybe<Scalars['String']>;
  ad_contract_signee?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['String']>;
  ccemail?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  conf_text_type?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  email_reminder?: InputMaybe<Scalars['String']>;
  email_reminder_officials?: InputMaybe<Scalars['String']>;
  email_reminder_workers?: InputMaybe<Scalars['String']>;
  enable_cc_email_as_origin?: InputMaybe<Scalars['String']>;
  enable_ml_updates?: InputMaybe<Scalars['String']>;
  fax?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  mascot?: InputMaybe<Scalars['String']>;
  message_board_read_at?: InputMaybe<Scalars['Date']>;
  ml_key?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  principal?: InputMaybe<Scalars['String']>;
  school_timezone?: InputMaybe<Scalars['String']>;
  secondary_ad_email?: InputMaybe<Scalars['String']>;
  secondary_ad_name?: InputMaybe<Scalars['String']>;
  signed_contract_notification?: InputMaybe<Scalars['String']>;
  ssn_on_file?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  state_org?: InputMaybe<Scalars['String']>;
  state_org_abbreviation?: InputMaybe<Scalars['String']>;
  use_security?: InputMaybe<Scalars['String']>;
  web_password?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type CreateVehicleInput = {
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['String']>;
  vehicle_type?: InputMaybe<Scalars['String']>;
};

export type CreateWorkerInput = {
  duty?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  event_id?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  organization?: InputMaybe<Scalars['String']>;
  paid?: InputMaybe<Scalars['String']>;
  pay_code?: InputMaybe<Scalars['String']>;
  pay_rate?: InputMaybe<Scalars['Float']>;
  salary?: InputMaybe<Scalars['Float']>;
  ssn?: InputMaybe<Scalars['String']>;
  woker_name?: InputMaybe<Scalars['String']>;
  worker_end_time?: InputMaybe<Scalars['String']>;
  worker_start_time?: InputMaybe<Scalars['String']>;
  worker_type?: InputMaybe<Scalars['String']>;
};

export type CreateWorkerPool = {
  Address?: InputMaybe<Scalars['String']>;
  City?: InputMaybe<Scalars['String']>;
  First: Scalars['String'];
  Home_Phone?: InputMaybe<Scalars['String']>;
  Last: Scalars['String'];
  SSN?: InputMaybe<Scalars['String']>;
  State?: InputMaybe<Scalars['String']>;
  Work_Phone?: InputMaybe<Scalars['String']>;
  Zip?: InputMaybe<Scalars['String']>;
  cell_phone?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  pay_rate?: InputMaybe<Scalars['Float']>;
  worker_type?: InputMaybe<Scalars['String']>;
};

export type CreatedFundraiserUser = {
  __typename?: 'CreatedFundraiserUser';
  id: Scalars['Int'];
};

export type CreatedOtkEntry = {
  __typename?: 'CreatedOtkEntry';
  id: Scalars['Int'];
};

export type CreatedParticipantRewards = {
  __typename?: 'CreatedParticipantRewards';
  id: Scalars['Int'];
};

export type CurrentCustomersDeals = {
  __typename?: 'CurrentCustomersDeals';
  activity?: Maybe<Scalars['String']>;
  dealName?: Maybe<Scalars['String']>;
  dealStage?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['String']>;
  fundraiserStatus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastLaunch?: Maybe<Scalars['String']>;
  leaderFirstName?: Maybe<Scalars['String']>;
  leaderLastName?: Maybe<Scalars['String']>;
  previousDealId?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  snapFundraiserId?: Maybe<Scalars['String']>;
  totalRaised?: Maybe<Scalars['Int']>;
};

/** Information of custom email templates */
export type CustomContactTemplates = {
  __typename?: 'CustomContactTemplates';
  dateCreated?: Maybe<Scalars['DateTime']>;
  dateUpdated?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
};

export type DailyCalendarBusScheduleEvents = {
  __typename?: 'DailyCalendarBusScheduleEvents';
  activity?: Maybe<Scalars['String']>;
  activityType?: Maybe<Scalars['String']>;
  bus_time?: Maybe<Scalars['String']>;
  calendarDate?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['String']>;
  departure_location?: Maybe<Scalars['String']>;
  early_dismissal_required?: Maybe<Scalars['String']>;
  early_dismissal_time?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['String']>;
  estimated_return_time?: Maybe<Scalars['String']>;
  event_comments?: Maybe<Scalars['String']>;
  event_date?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['Int']>;
  g_s?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  num_buses?: Maybe<Scalars['Int']>;
  num_buses_text?: Maybe<Scalars['Int']>;
  opponent?: Maybe<Scalars['String']>;
  place?: Maybe<Scalars['String']>;
  sport?: Maybe<Scalars['String']>;
  sportGender?: Maybe<Scalars['String']>;
  sportLevel?: Maybe<Scalars['String']>;
  sport_name?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['String']>;
};

export type DailyCalendarBusScheduleExportData = {
  __typename?: 'DailyCalendarBusScheduleExportData';
  activity?: Maybe<Scalars['String']>;
  bus_time?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['String']>;
  departure_location?: Maybe<Scalars['String']>;
  driver_name?: Maybe<Scalars['String']>;
  driver_phone?: Maybe<Scalars['String']>;
  early_dismissal_required?: Maybe<Scalars['String']>;
  early_dismissal_time?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['String']>;
  estimated_return_time?: Maybe<Scalars['String']>;
  event_date?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['Int']>;
  g_s?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  groupval?: Maybe<Scalars['String']>;
  level1?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  num_buses_text?: Maybe<Scalars['Int']>;
  opponent?: Maybe<Scalars['String']>;
  place?: Maybe<Scalars['String']>;
  sport_description?: Maybe<Scalars['String']>;
  sport_name?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['String']>;
  transport_comments?: Maybe<Scalars['String']>;
  vehicle_id?: Maybe<Scalars['String']>;
  vehicle_type?: Maybe<Scalars['String']>;
};

export type DailyCalendarEvent = {
  __typename?: 'DailyCalendarEvent';
  activity?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  departure_location?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  early_dismissal_time?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['String']>;
  estimated_return_time?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['Int']>;
  eventTransportDetails?: Maybe<Array<Maybe<EventTransportDetails>>>;
  event_date?: Maybe<Scalars['Date']>;
  location?: Maybe<Scalars['String']>;
  num_buses?: Maybe<Scalars['Int']>;
  officials?: Maybe<Array<Maybe<Official>>>;
  opponent?: Maybe<Scalars['String']>;
  preparations?: Maybe<Array<Maybe<Preparation>>>;
  schoolInfo?: Maybe<SchoolInfo>;
  season_team?: Maybe<Scalars['Int']>;
  season_years?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['String']>;
  workers?: Maybe<Array<Maybe<Worker>>>;
};

export type DailyCalendarEventTransportDetails = {
  __typename?: 'DailyCalendarEventTransportDetails';
  driver_name?: Maybe<Scalars['String']>;
  driver_phone?: Maybe<Scalars['String']>;
  event_transport_details_id?: Maybe<Scalars['Int']>;
  vehicle_id?: Maybe<Scalars['String']>;
  vehicle_type?: Maybe<Scalars['String']>;
};

export type DailyCalendarOfficialDuties = {
  __typename?: 'DailyCalendarOfficialDuties';
  Address?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Home_Phone?: Maybe<Scalars['String']>;
  SSN?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
  Work_Phone?: Maybe<Scalars['String']>;
  Zip?: Maybe<Scalars['String']>;
  cell_phone?: Maybe<Scalars['String']>;
  duty?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  offic_id?: Maybe<Scalars['String']>;
  paid?: Maybe<Scalars['String']>;
  salary?: Maybe<Scalars['String']>;
  vendor_number?: Maybe<Scalars['String']>;
  voucher_field?: Maybe<Scalars['Int']>;
  voucher_number?: Maybe<Scalars['String']>;
  woker_name?: Maybe<Scalars['String']>;
};

export type DailyCalendarOfficialExport = {
  __typename?: 'DailyCalendarOfficialExport';
  address?: Maybe<Scalars['String']>;
  cell_phone?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  duty?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['String']>;
  event_date?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['Int']>;
  groupVal?: Maybe<Scalars['String']>;
  home_phone?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  offic_id?: Maybe<Scalars['String']>;
  opponent?: Maybe<Scalars['String']>;
  salary?: Maybe<Scalars['String']>;
  sport_description?: Maybe<Scalars['String']>;
  ssn?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  vendor_number?: Maybe<Scalars['String']>;
  voucher_number?: Maybe<Scalars['String']>;
  woker_name?: Maybe<Scalars['String']>;
  work_phone?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type DailyCalendarOfficialMessage = {
  __typename?: 'DailyCalendarOfficialMessage';
  comments?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['String']>;
  event_date?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['Int']>;
  location?: Maybe<Scalars['String']>;
  official_duties?: Maybe<Array<Maybe<DailyCalendarOfficialDuties>>>;
  opponent?: Maybe<Scalars['String']>;
  sport?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['String']>;
};

export type DailyCalendarPreparation = {
  __typename?: 'DailyCalendarPreparation';
  comments?: Maybe<Scalars['String']>;
  prep?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['String']>;
};

export type DailyCalendarPreparationExportData = {
  __typename?: 'DailyCalendarPreparationExportData';
  comments?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['String']>;
  event_date?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['Int']>;
  groupval?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  opponent?: Maybe<Scalars['String']>;
  prep?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['String']>;
  sport_description?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['String']>;
};

export type DailyCalendarPreparationMessage = {
  __typename?: 'DailyCalendarPreparationMessage';
  comments?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['String']>;
  event_date?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['Int']>;
  groupval?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  opponent?: Maybe<Scalars['String']>;
  place?: Maybe<Scalars['String']>;
  preparations?: Maybe<Array<Maybe<DailyCalendarPreparation>>>;
  sport?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['String']>;
};

export type DailyCalendarWorkerDuties = {
  __typename?: 'DailyCalendarWorkerDuties';
  SSN?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  duty?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['String']>;
  event_date?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['Int']>;
  groupval?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  opponent?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  paid?: Maybe<Scalars['String']>;
  pay_code?: Maybe<Scalars['String']>;
  phones?: Maybe<Scalars['String']>;
  salary?: Maybe<Scalars['Float']>;
  sport_description?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['String']>;
  woker_name?: Maybe<Scalars['String']>;
};

export type DailyCalendarWorkerExportData = {
  __typename?: 'DailyCalendarWorkerExportData';
  comments?: Maybe<Scalars['String']>;
  duty?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['String']>;
  event_date?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['Int']>;
  groupval?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  opponent?: Maybe<Scalars['String']>;
  pay_code?: Maybe<Scalars['String']>;
  phones?: Maybe<Scalars['String']>;
  sport_description?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['String']>;
  woker_name?: Maybe<Scalars['String']>;
};

export type DailyCalendarWorkerMessage = {
  __typename?: 'DailyCalendarWorkerMessage';
  comments?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['String']>;
  event_date?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['Int']>;
  groupval?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  opponent?: Maybe<Scalars['String']>;
  sport?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['String']>;
  worker_duties?: Maybe<Array<Maybe<DailyCalendarWorkerDuties>>>;
};

export type DateRange = {
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
};

/** Filtering options for nullable DateTime type */
export type DateTimeNullableFilter = {
  /** Equals operation */
  equals?: InputMaybe<Scalars['String']>;
  /** Greater Than operation */
  gt?: InputMaybe<Scalars['String']>;
  /** Greater Than or Equal operation */
  gte?: InputMaybe<Scalars['String']>;
  /** In operation for multiple values */
  in?: InputMaybe<Array<Scalars['String']>>;
  /** Less Than operation */
  lt?: InputMaybe<Scalars['String']>;
  /** Less Than or Equal operation */
  lte?: InputMaybe<Scalars['String']>;
  /** Not Equal operation */
  not?: InputMaybe<Scalars['String']>;
  /** Not In operation for multiple values */
  notIn?: InputMaybe<Array<Scalars['String']>>;
};

export type DeleteCount = {
  __typename?: 'DeleteCount';
  count?: Maybe<Scalars['Int']>;
};

export type DeleteEventInput = {
  event_id: Scalars['Int'];
};

export type DeleteEventParticipantsInput = {
  id: Scalars['Int'];
};

export type DeleteEventPreparationInput = {
  id: Scalars['Int'];
};

export type DeleteEventPreparationsInput = {
  id: Scalars['Int'];
};

export type DeleteEventTransportDetailsInput = {
  id: Scalars['Int'];
};

export type DeleteFacilityInput = {
  place_name: Scalars['String'];
};

export type DeleteLevelInput = {
  ID: Scalars['Int'];
};

export type DeleteManyEventParticipantsInput = {
  ids: Array<InputMaybe<Scalars['Int']>>;
};

export type DeleteManyEventPreparationsInput = {
  ids: Array<InputMaybe<Scalars['Int']>>;
};

export type DeleteManyEventTransportDetailsInput = {
  ids: Array<InputMaybe<Scalars['Int']>>;
};

export type DeleteManyWorkerInput = {
  ids: Array<InputMaybe<Scalars['Int']>>;
};

export type DeleteOfficialDutiesInput = {
  ids: Array<Scalars['Int']>;
};

export type DeleteOfficialInput = {
  id: Scalars['Int'];
};

export type DeleteOfficialPoolInput = {
  ids: Array<InputMaybe<Scalars['String']>>;
};

export type DeleteOpponentInput = {
  SchoolCode: Scalars['String'];
};

export type DeletePreparationInput = {
  id: Scalars['Int'];
};

export type DeleteSchoolInfoInput = {
  id: Scalars['String'];
};

export type DeleteVehicleInput = {
  id: Scalars['Int'];
};

export type DeleteWorkerInput = {
  id: Scalars['Int'];
};

export type DeleteWorkerPoolInput = {
  ids: Array<InputMaybe<Scalars['Int']>>;
};

export type DeletedIncentiveRecord = {
  __typename?: 'DeletedIncentiveRecord';
  id: Scalars['ID'];
};

export type Deposit = {
  __typename?: 'Deposit';
  amount: Scalars['Decimal'];
  campaignId: Scalars['Int'];
  created: Scalars['Timestamp'];
  description: Scalars['String'];
  destination: Account;
  effective: Scalars['Timestamp'];
  externalId: Scalars['String'];
  source: Account;
  transactionId: Scalars['UUID'];
};

/** Documents uploaded for validation purposes */
export type Document = {
  __typename?: 'Document';
  documentBack?: Maybe<Scalars['String']>;
  documentFront?: Maybe<Scalars['String']>;
  gateway: VaultFinancialProvider;
  id: Scalars['String'];
  type: Scalars['String'];
  vaultKybId?: Maybe<Scalars['ID']>;
  vaultKycId?: Maybe<Scalars['ID']>;
};

export type DonateIntent = {
  __typename?: 'DonateIntent';
  /** Donation amount (without coverage fee) in cents */
  amount?: Maybe<Scalars['Int']>;
  anonymous?: Maybe<Scalars['Boolean']>;
  /** Flag to indicate if the Credit card fee is covered by the donor */
  ccFeeCovered?: Maybe<Scalars['Boolean']>;
  /** Credit card fee percentage, If leave null/undefined, it will be calculated at 0.03 (3%) */
  ccFeePercentage?: Maybe<Scalars['Float']>;
  /** Fee amount coverage by donor (without coverage fee) in cents */
  coverageAmount?: Maybe<Scalars['Int']>;
  /** Linked donation if existed */
  donation?: Maybe<DonorDonation>;
  donationId?: Maybe<Scalars['String']>;
  donorEmail?: Maybe<Scalars['String']>;
  donorFirstName?: Maybe<Scalars['String']>;
  donorLastName?: Maybe<Scalars['String']>;
  fundraiser: DonorFundraiser;
  history?: Maybe<Array<Maybe<DonateIntent>>>;
  id: Scalars['ID'];
  matchDon?: Maybe<Scalars['String']>;
  participantId?: Maybe<Scalars['Int']>;
  participantUserDirectoryId?: Maybe<Scalars['String']>;
  /** Payment Method used for Strip or Paypal payment */
  paymentMethodId?: Maybe<Scalars['String']>;
  paymentProvider?: Maybe<PaymentProvider>;
  previousStatus?: Maybe<DonateIntentStatus>;
  /** Share Type of donation */
  shareType?: Maybe<Scalars['String']>;
  /** The subtotal amount in cents which will be send to Snap's Stripe account snapAmount = (amount + coverageAmount - allocationAmount + tipAmount), where allocationAmount is the subtotalAmount will be sent to team's Stripe connected account, calculated through donation-allocation-amount helper. */
  snapAmount?: Maybe<Scalars['Int']>;
  status: DonateIntentStatus;
  tipAmount?: Maybe<Scalars['Int']>;
  /** Total amounts will be charged to donor's credit card. Equals amount + coverageAmount */
  totalAmount?: Maybe<Scalars['Int']>;
};

export enum DonateIntentStatus {
  Cancelled = 'CANCELLED',
  Failed = 'FAILED',
  Initiated = 'INITIATED',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Refunded = 'REFUNDED',
  Succeeded = 'SUCCEEDED'
}

/** Not used yet. Will be removed soon. */
export type DonationInvite = {
  __typename?: 'DonationInvite';
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastEmailSentAt?: Maybe<Scalars['String']>;
  senderName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type DonationInviteReleaseStatus = {
  __typename?: 'DonationInviteReleaseStatus';
  areDonationInviteEmailsReleased: Scalars['Boolean'];
};

export type DonationInviteRemoveData = {
  __typename?: 'DonationInviteRemoveData';
  email?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

export type DonationInviteSmsData = {
  __typename?: 'DonationInviteSMSData';
  id: Scalars['Int'];
  phoneNumber: Scalars['String'];
  status: Scalars['String'];
};

export type DonationLevel = {
  __typename?: 'DonationLevel';
  amount: Scalars['Int'];
  id: Scalars['ID'];
  level: DonationLevels;
  rewardDescription?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export enum DonationLevels {
  Five = 'FIVE',
  Four = 'FOUR',
  One = 'ONE',
  Six = 'SIX',
  Three = 'THREE',
  Two = 'TWO'
}

export type DonationTopEarner = {
  __typename?: 'DonationTopEarner';
  participantID?: Maybe<Scalars['Int']>;
};

/** Donation Statistics. Will change name to CampaignDonationStats later. */
export type Donations = {
  __typename?: 'Donations';
  /** Total of distinct donors in campaigns */
  AskedToShare?: Maybe<Scalars['Int']>;
  /** Total count of donations in campaign */
  count?: Maybe<Scalars['Int']>;
  /** Total donation amount in cents raised through email */
  emailTotal?: Maybe<Scalars['Int']>;
  /** Total donation amount in cents raised other methods outside text, email and social */
  extraTotal?: Maybe<Scalars['Int']>;
  /** Total of donation amount in cents raised through forward to friends. */
  fowardTotal?: Maybe<Scalars['Int']>;
  /** Total quantity of gear items purchased in campaign. */
  gearAdded?: Maybe<Scalars['Int']>;
  /** Total count of distinct donors that purchased gears. */
  gearPurchased?: Maybe<Scalars['Int']>;
  /** Total of purchase amount in cents of gear (OTK) items */
  gearTotal?: Maybe<Scalars['Int']>;
  /** Total donation amounts in cents through retaining supporters from previous closed campaign. Share Type is 'reisgn_a' or 'resign_b' */
  legacySupporters?: Maybe<Scalars['Int']>;
  /** Total count of distinct donors who are new and donated campaigns through share-type 'supporter_referral' */
  newSupporters?: Maybe<Scalars['Int']>;
  otherTotal?: Maybe<Scalars['Int']>;
  /** Total count of donors who are retaining supporters from previous closed campaign. Share Type is 'reisgn_a' or 'resign_b' */
  pastSupporters?: Maybe<Scalars['Int']>;
  /** Total donation amount in cents raised through social media */
  socialTotal?: Maybe<Scalars['Int']>;
  /** Total donation amount in cents raised through sms/text */
  textTotal?: Maybe<Scalars['Int']>;
};

export enum DonationsAndPurchasesEnum {
  Donation = 'donation',
  Purchase = 'purchase'
}

export type DonationsResponse = {
  __typename?: 'DonationsResponse';
  fundraiserTopDonation?: Maybe<TopDonation>;
  fundraiserTotalDonationsRaised?: Maybe<TotalDonationsRaised>;
  participantTopDonation?: Maybe<TopDonation>;
  participantTotalDonationsRaised?: Maybe<TotalDonationsRaised>;
};

export type DonorData = {
  __typename?: 'DonorData';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type DonorDonateIntentCreateInput = {
  amount: Scalars['Int'];
  /** This is preference of the donors to be anonymous */
  anonymous?: InputMaybe<Scalars['Boolean']>;
  /** Flag to indicate if the Credit card fee is covered by the donor */
  ccFeeCovered?: InputMaybe<Scalars['Boolean']>;
  ccFeePercentage?: InputMaybe<Scalars['Float']>;
  /** This is the Donation Level ID that will be passed thru the URL on Checkout. */
  donationLevelId?: InputMaybe<Scalars['String']>;
  donorEmail?: InputMaybe<Scalars['String']>;
  donorFirstName?: InputMaybe<Scalars['String']>;
  donorLastName?: InputMaybe<Scalars['String']>;
  fundraiserId: Scalars['String'];
  matchDon?: InputMaybe<Scalars['String']>;
  /** This is the IP address of the donor */
  originIp?: InputMaybe<Scalars['String']>;
  participantId?: InputMaybe<Scalars['Int']>;
  participantUserDirectoryId?: InputMaybe<Scalars['String']>;
  /** Share Type of donation will be passed as a search query to the URL on Checkout. Valid values can be found at https://www.notion.so/snap-mobile/508f9d741b434cef85cb2c5cbdf15eb7?v=804cce5f998a415b97d672037112b4ae&pvs=4 under the New Stack Share Types column */
  shareType?: InputMaybe<Scalars['String']>;
  tipAmount?: InputMaybe<Scalars['Int']>;
};

export type DonorDonateIntentUpdateInput = {
  amount?: InputMaybe<Scalars['Int']>;
  /** This is preference of the donors to be anonymous */
  anonymous?: InputMaybe<Scalars['Boolean']>;
  /** Flag to indicate if the Credit card fee is covered by the donor */
  ccFeeCovered?: InputMaybe<Scalars['Boolean']>;
  ccFeePercentage?: InputMaybe<Scalars['Float']>;
  cheerwallName?: InputMaybe<Scalars['String']>;
  /** This is the Donation Level ID that will be passed thru the URL on Checkout. */
  donationLevelId?: InputMaybe<Scalars['String']>;
  donorEmail?: InputMaybe<Scalars['String']>;
  donorFirstName?: InputMaybe<Scalars['String']>;
  donorLastName?: InputMaybe<Scalars['String']>;
  donorMessage?: InputMaybe<Scalars['String']>;
  matchDon?: InputMaybe<Scalars['String']>;
  participantId?: InputMaybe<Scalars['Int']>;
  participantUserDirectoryId?: InputMaybe<Scalars['String']>;
  paymentMethodId?: InputMaybe<Scalars['String']>;
  paymentProvider?: InputMaybe<PaymentProvider>;
  tipAmount?: InputMaybe<Scalars['Int']>;
};

/** A donation made by a donor to a fundraiser. */
export type DonorDonation = {
  __typename?: 'DonorDonation';
  anonymous?: Maybe<Scalars['Boolean']>;
  appFeeCents?: Maybe<Scalars['Int']>;
  authorizationCode?: Maybe<Scalars['String']>;
  averageDonationCents?: Maybe<Scalars['Int']>;
  ccFeePercentage?: Maybe<Scalars['Float']>;
  chargedBackAt?: Maybe<Scalars['DateTime']>;
  checkoutId?: Maybe<Scalars['String']>;
  checkoutState?: Maybe<DonorDonationCheckoutState>;
  cheerwallName?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  coverageAmountCents?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creditCardId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  disputeWonId?: Maybe<Scalars['String']>;
  donateIntent?: Maybe<DonateIntent>;
  donationLevel?: Maybe<DonorDonationLevel>;
  donationLevelId?: Maybe<Scalars['String']>;
  donor?: Maybe<DonorUser>;
  donorId?: Maybe<Scalars['String']>;
  donorMessage?: Maybe<Scalars['String']>;
  donorName?: Maybe<Scalars['String']>;
  extCcFeeCents?: Maybe<Scalars['Int']>;
  fbPostId?: Maybe<Scalars['String']>;
  fundraiser?: Maybe<DonorFundraiser>;
  fundraiserId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  intCcFeeCents?: Maybe<Scalars['Int']>;
  matchDon?: Maybe<Scalars['String']>;
  newsletterOptIn?: Maybe<Scalars['Boolean']>;
  origin?: Maybe<Scalars['String']>;
  originIp?: Maybe<Scalars['String']>;
  participant?: Maybe<DonorParticipantData>;
  participantId?: Maybe<Scalars['Int']>;
  participantUserDirectoryId?: Maybe<Scalars['String']>;
  paymentId?: Maybe<Scalars['String']>;
  paymentMethodId?: Maybe<Scalars['String']>;
  paymentProvider?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  raiseId?: Maybe<Scalars['Int']>;
  refundReason?: Maybe<Scalars['String']>;
  serviceFeeCents?: Maybe<Scalars['Int']>;
  /** Share Type of donation */
  shareType?: Maybe<Scalars['String']>;
  status?: Maybe<DonorDonationStatus>;
  subtotal?: Maybe<Scalars['String']>;
  subtotalCents?: Maybe<Scalars['Int']>;
  supporterId?: Maybe<Scalars['Int']>;
  tipAmountCents?: Maybe<Scalars['Int']>;
  totalPriceCents?: Maybe<Scalars['Int']>;
  transactionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum DonorDonationCheckoutState {
  Authorized = 'AUTHORIZED',
  Cancelled = 'CANCELLED',
  Captured = 'CAPTURED',
  ChargedBack = 'CHARGED_BACK',
  Expired = 'EXPIRED',
  Failed = 'FAILED',
  New = 'NEW',
  Refunded = 'REFUNDED',
  Released = 'RELEASED'
}

/** Donation levels that are displayed to donors on the donation form, suggesting donation specific amounts and detailing why a donor might select them. */
export type DonorDonationLevel = {
  __typename?: 'DonorDonationLevel';
  amount: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  fundraiserId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  impact?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['Int']>;
  rewardDescription?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DonorDonationOfflineInput = {
  /** The amount of the donation in cents */
  amount: Scalars['Int'];
  anonymous?: InputMaybe<Scalars['Boolean']>;
  donorName?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  fundraiserId: Scalars['String'];
  participantId?: InputMaybe<Scalars['Int']>;
  participantUserDirectoryId?: InputMaybe<Scalars['String']>;
};

export type DonorDonationRecordUpdate = {
  __typename?: 'DonorDonationRecordUpdate';
  donateIntentData?: Maybe<DonateIntent>;
  donationId?: Maybe<Scalars['String']>;
};

export enum DonorDonationStatus {
  Anonymous = 'ANONYMOUS',
  ChargedBack = 'CHARGED_BACK',
  Complete = 'COMPLETE',
  Offline = 'OFFLINE',
  Online = 'ONLINE',
  Paid = 'PAID',
  Refunded = 'REFUNDED',
  Refunding = 'REFUNDING',
  Released = 'RELEASED',
  Unpaid = 'UNPAID',
  Updated1 = 'UPDATED1',
  Updated2 = 'UPDATED2'
}

export type DonorDonationUpdateInput = {
  anonymous?: InputMaybe<Scalars['Boolean']>;
  cheerwallName?: InputMaybe<Scalars['String']>;
  donorId?: InputMaybe<Scalars['ID']>;
  donorMessage?: InputMaybe<Scalars['String']>;
  donorName?: InputMaybe<Scalars['String']>;
  participantId?: InputMaybe<Scalars['Int']>;
  participantUserDirectoryId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<DonorDonationStatus>;
  subtotalCents?: InputMaybe<Scalars['Int']>;
};

export type DonorEmailData = {
  __typename?: 'DonorEmailData';
  deliveryStatus?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  followUpNumber?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  personListEntryID?: Maybe<Scalars['Int']>;
};

/** A copy of the pertinent fields from the Fundraiser model in raise. */
export type DonorFundraiser = {
  __typename?: 'DonorFundraiser';
  ccFeeCovered?: Maybe<Scalars['Boolean']>;
  ccFeePayer?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  donationMinimumCents?: Maybe<Scalars['Int']>;
  donations?: Maybe<Array<Maybe<DonorDonation>>>;
  endDate: Scalars['DateTime'];
  feeType?: Maybe<Scalars['String']>;
  /** The amount of money to raise in dollars. */
  goal?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  logo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** Old ID field from raise */
  raiseId: Scalars['Int'];
  startDate: Scalars['DateTime'];
  status?: Maybe<Scalars['String']>;
  whyDonations?: Maybe<Scalars['String']>;
};

export type DonorFundraiserData = {
  __typename?: 'DonorFundraiserData';
  ccFeeCovered?: Maybe<Scalars['Boolean']>;
  ccFeePayer: SnapFeePayer;
  description?: Maybe<Scalars['String']>;
  donationMinimumCents?: Maybe<Scalars['Int']>;
  endDate: Scalars['DateTime'];
  feeType?: Maybe<Scalars['String']>;
  giftShop: Scalars['Boolean'];
  goal?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  legalName?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  participantGoal?: Maybe<Scalars['Int']>;
  personalMessage?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  programLeader?: Maybe<Scalars['String']>;
  programType?: Maybe<Scalars['String']>;
  raiseEntityId?: Maybe<Scalars['Int']>;
  raiseId: Scalars['Int'];
  redirect?: Maybe<Scalars['Boolean']>;
  region?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  status: FundraiserStatus;
  stripeConnectId?: Maybe<Scalars['String']>;
  subtotalDollarsSum?: Maybe<Scalars['Float']>;
  waCampaignSetup?: Maybe<Scalars['Boolean']>;
  whyDonations?: Maybe<Scalars['String']>;
};

export type DonorFundraiserPayable = DonorDonation | DonorFundraiserPurchase | DonorOfflineDonation;

export type DonorFundraiserPayableRefundInput = {
  /** The id of the donation or purchase */
  id: Scalars['ID'];
  /** Optional: The type of fundraiser payable. If not provided, the type will be determined by the id. */
  type?: InputMaybe<DonorFundraiserPayableTypeEnum>;
};

export enum DonorFundraiserPayableTypeEnum {
  Donation = 'donation',
  Purchase = 'purchase'
}

export type DonorFundraiserPayablesInput = {
  fundraiserId?: InputMaybe<Scalars['String']>;
  fundraiserRaiseId?: InputMaybe<Scalars['Int']>;
};

export type DonorFundraiserPurchase = {
  __typename?: 'DonorFundraiserPurchase';
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  donationId?: Maybe<Scalars['String']>;
  donor?: Maybe<DonorUser>;
  fundraiserUserId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  lineItems?: Maybe<Array<Maybe<DonorGiftShopItem>>>;
  participant?: Maybe<DonorParticipantData>;
  participantUserDirectoryId?: Maybe<Scalars['String']>;
  paymentMethodId?: Maybe<Scalars['String']>;
  paymentProvider?: Maybe<Scalars['String']>;
  purchaseIntentId?: Maybe<Scalars['String']>;
  raiseId?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  type?: Maybe<DonationsAndPurchasesEnum>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DonorGiftShopItem = {
  __typename?: 'DonorGiftShopItem';
  costCents?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  priceCents?: Maybe<Scalars['Int']>;
  purchasableId?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DonorOfflineDonation = {
  __typename?: 'DonorOfflineDonation';
  anonymous?: Maybe<Scalars['Boolean']>;
  cheerwallName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** This type is meant for admin only */
  donor?: Maybe<DonorUser>;
  donorId?: Maybe<Scalars['String']>;
  donorMessage?: Maybe<Scalars['String']>;
  donorName?: Maybe<Scalars['String']>;
  fundraiser?: Maybe<DonorFundraiser>;
  fundraiserId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  origin?: Maybe<Scalars['String']>;
  participant?: Maybe<DonorParticipantData>;
  participantId?: Maybe<Scalars['Int']>;
  participantUserDirectoryId?: Maybe<Scalars['String']>;
  raiseId?: Maybe<Scalars['Int']>;
  status?: Maybe<DonorDonationStatus>;
  subtotalCents?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DonorParticipantData = {
  __typename?: 'DonorParticipantData';
  raiseId?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
};

export type DonorPayableRefundResponse = {
  __typename?: 'DonorPayableRefundResponse';
  payable?: Maybe<DonorFundraiserPayable>;
  refundId?: Maybe<Scalars['String']>;
};

export type DonorPersonListEntry = {
  __typename?: 'DonorPersonListEntry';
  email?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** This type is meant for admin only */
export type DonorUser = {
  __typename?: 'DonorUser';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  /** Donor Id */
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  raiseUserId?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
};

export type DonorsCheerwall = {
  __typename?: 'DonorsCheerwall';
  createdAt?: Maybe<Scalars['DateTime']>;
  donorMessage?: Maybe<Scalars['String']>;
  donorName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  participant?: Maybe<DonorsParticipantPublic>;
  subtotalCents?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

export type DonorsCheerwallsResult = {
  __typename?: 'DonorsCheerwallsResult';
  cheers?: Maybe<Array<Maybe<DonorsCheerwall>>>;
  count?: Maybe<Scalars['Int']>;
};

export type DonorsDonationLevel = {
  __typename?: 'DonorsDonationLevel';
  amount: Scalars['String'];
  id: Scalars['ID'];
  impact?: Maybe<Scalars['String']>;
  rewardDescription?: Maybe<Scalars['String']>;
};

/** @deprecated */
export type DonorsFundraiserCheerWall = {
  __typename?: 'DonorsFundraiserCheerWall';
  cheers?: Maybe<Array<Maybe<CheerWallData>>>;
  count?: Maybe<Scalars['Int']>;
};

export type DonorsParticipantOtkIncentive = {
  __typename?: 'DonorsParticipantOTKIncentive';
  description?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  priceCents?: Maybe<Scalars['Int']>;
  purchaseLimit?: Maybe<Scalars['Int']>;
  purchased?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['String']>;
};

export type DonorsParticipantOtkIncentives = {
  __typename?: 'DonorsParticipantOTKIncentives';
  incentives?: Maybe<Array<Maybe<DonorsParticipantOtkIncentive>>>;
};

export type DonorsParticipantPublic = {
  __typename?: 'DonorsParticipantPublic';
  data?: Maybe<DonorsParticipantPublicData>;
  raiseId?: Maybe<Scalars['Int']>;
};

export type DonorsParticipantPublicData = {
  __typename?: 'DonorsParticipantPublicData';
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
};

export type DonorsParticipantsGiftShop = {
  __typename?: 'DonorsParticipantsGiftShop';
  fundraiserUserId?: Maybe<Scalars['Int']>;
  giftShop?: Maybe<Array<Maybe<DonorsParticipantsGiftShopData>>>;
  totalPurchased?: Maybe<Scalars['Int']>;
};

export type DonorsParticipantsGiftShopData = {
  __typename?: 'DonorsParticipantsGiftShopData';
  costCents?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  netEarnedPercentage?: Maybe<Scalars['Float']>;
  priceCents?: Maybe<Scalars['Int']>;
  promoLabel?: Maybe<Scalars['String']>;
  purchaseLimit?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['String']>;
};

/** Account User is a user that is registered in Raise's database. Deprecated: Use UserDirectory 's User instead instead */
export type DriveAccountUser = DriveUser & {
  __typename?: 'DriveAccountUser';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  snapRaiseId?: Maybe<Scalars['Int']>;
};

/** MDM Actor. e.g. Account Manager/Salereps/Sales Manager */
export type DriveAmSalesReps = {
  __typename?: 'DriveAmSalesReps';
  /** Primary contact email of salesrep */
  email?: Maybe<Scalars['String']>;
  /** End time of salesrep */
  endDate?: Maybe<Scalars['DateTime']>;
  /** First Name of Salesrep */
  firstName?: Maybe<Scalars['String']>;
  /** Last Name of Salesrep */
  lastName?: Maybe<Scalars['String']>;
  /** Primary phone number of salesrep */
  phone?: Maybe<Scalars['String']>;
  /** The sales manager of this salesrep */
  salesManager?: Maybe<DriveAmSalesReps>;
  /** Salesrep Id. This is Raise's account user id. */
  salesrepId?: Maybe<Scalars['Int']>;
  /** Start time of salesrep */
  startDate?: Maybe<Scalars['DateTime']>;
  /** actor type. e.g. 'salesrep', 'salesmanager', 'am' */
  type?: Maybe<Scalars['String']>;
};

export enum DriveAttendanceStatus {
  Attending = 'ATTENDING',
  NotAttending = 'NOT_ATTENDING'
}

/** Information of a single drive campaign */
export type DriveCampaign = {
  __typename?: 'DriveCampaign';
  /** The account manager of the campaign */
  accountManager?: Maybe<AccountManager>;
  /** @deprecated: not used, in favor of totalRaisedCents */
  donationRaisedCents?: Maybe<Scalars['Int']>;
  /** The date the campaign ends */
  endDate?: Maybe<Scalars['DateTime']>;
  /** The date the campaign has been finalized/closed */
  finalizedDate?: Maybe<Scalars['DateTime']>;
  /** Indicate whether the campaign has any incentives, such as OTK */
  hasIncentive?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  /** Name of campaign */
  name?: Maybe<Scalars['String']>;
  /** Notes for a campaign */
  notes?: Maybe<Scalars['String']>;
  /** Url-slug of campaign name which is a unique identifier */
  slug?: Maybe<Scalars['String']>;
  /** The date campaign starts */
  startDate?: Maybe<Scalars['DateTime']>;
  /** Current status of campaign */
  status?: Maybe<CampaignStatus>;
  /** The size of the team/group attached to campaign */
  teamSize?: Maybe<Scalars['Int']>;
  /** @deprecated: not used, in favor of `totalRaisedCents` */
  totalRaised?: Maybe<Scalars['BigInt']>;
  /** Total amount of donation of campaign in cents. */
  totalRaisedCents?: Maybe<Scalars['Int']>;
};

/** Campaign/Fundraiser details information. NOTE: In the future, we might merged with DriveCampaign or Campaign */
export type DriveCampaignDetails = {
  __typename?: 'DriveCampaignDetails';
  /** Account Balance of campaign. */
  accountBalance?: Maybe<AccountBalanceReturn>;
  /** The activity/sport of this campaign, e.g. 'football', 'wrestling', etc. */
  activityType?: Maybe<Scalars['String']>;
  /** The amount in cents that campaigns was advanced in Wallet. */
  advanceAmount?: Maybe<Scalars['Int']>;
  /** Bill type when settled/finalized. Values are 'invoice', 'regular' and 'default' */
  billType?: Maybe<Scalars['String']>;
  /** Credit Card Fee Payer. Values are 'customer' or 'snap'. */
  ccFeePayer?: Maybe<Scalars['String']>;
  /** Charge back fee in cents. The charge from payment processor when a donor cancels their donation through their bank instead of getting a refund from us directly. (usually $15 per) */
  chargebackFee?: Maybe<Scalars['Int']>;
  /** The statistic of a donation of campaigns */
  donationStats?: Maybe<DriveCampaignDonationStats>;
  /** The statistics of emails delivered/sent */
  emailDelivery?: Maybe<EmailsInfo>;
  /** The type of snap fee being collected. e.g. 'standard', '1+1', '20%/15%', etc. */
  feeType?: Maybe<Scalars['String']>;
  /** Total incentives price in cents. */
  gearCost?: Maybe<Scalars['Int']>;
  /** Group leader of a campaign */
  groupLeader?: Maybe<DriveGroupLeader>;
  id: Scalars['Int'];
  /** Cover Image for Campaign */
  image?: Maybe<Scalars['String']>;
  /** Goal of how much a fundraiser wants to raise */
  initialGoalCents?: Maybe<Scalars['Int']>;
  /** kyc processor. Values are 'wepay' or 'stripe' */
  kycProcessor?: Maybe<Scalars['String']>;
  /** kyc status of a campaign. Values are 'pending', 'active', 'inactive', etc. */
  kycStatus?: Maybe<Scalars['String']>;
  /** The address to ship the check for this campaign */
  mailingAddress?: Maybe<Scalars['String']>;
  /** The amount in cents that campaigns was manual-advanced in Raise. */
  manualAdvanceAmount?: Maybe<Scalars['Int']>;
  /** Net raised amount in cents. Equals totalRaised minus snapFee and adds the otkBonus. It is the amount client received in their check/deposit. */
  netRaisedTotal?: Maybe<Scalars['Int']>;
  /** Notes for settlement for this campaign */
  notes?: Maybe<Scalars['String']>;
  /** The legal name of a fundraisers organization */
  organizationLegalName?: Maybe<Scalars['String']>;
  /** The tin number of the organization */
  organizationTin?: Maybe<Scalars['String']>;
  /** Total bonus amount of otk in cents */
  otkBonus?: Maybe<Scalars['Int']>;
  /** The statistics of participants's activity of campaign */
  participantStats?: Maybe<DriveParticipantStats>;
  /** Processing Fee when settled/finalized. */
  processingFee?: Maybe<Scalars['Int']>;
  /** The date where the campaign has settle its payments from snap */
  settlementDate?: Maybe<Scalars['DateTime']>;
  /** How the funds has delivered to the organization. Values are 'check_fundraiser', 'check_salesrep', 'check_office' and 'direct_deposit' */
  settlementMethod?: Maybe<Scalars['String']>;
  /** The amount in cents collected as fee when a campaign ends based of the fee type */
  snapFee?: Maybe<Scalars['Float']>;
  /** Location of this fundraiser */
  territory?: Maybe<Scalars['String']>;
  /** The amount of purchase in cents. */
  totalPurchaseCents?: Maybe<Scalars['Int']>;
  /** Total raised of a fundraiser */
  totalRaisedCents?: Maybe<Scalars['Int']>;
  /** The total raised combined this organization has previously ran campaigns */
  totalRaisedHistory?: Maybe<Scalars['Int']>;
};

/** Pulls data on a campaigns donation broken down */
export type DriveCampaignDonationStats = {
  __typename?: 'DriveCampaignDonationStats';
  ccCoverageAmountCents?: Maybe<Scalars['Int']>;
  donationAppFeeCents?: Maybe<Scalars['Int']>;
  /** The amount that been raised */
  donationRaisedCents?: Maybe<Scalars['Int']>;
  /** The total count of donations */
  donationsCount?: Maybe<Scalars['Int']>;
  offlineTotalCents?: Maybe<Scalars['Int']>;
  onlineTotalCents?: Maybe<Scalars['Int']>;
  /** The count of participants */
  participantCount?: Maybe<Scalars['Int']>;
  /** The donated amount in cents that been raised by emails delivered */
  totalEmailDonations?: Maybe<Scalars['Int']>;
  /** The amount that been raised by miscellaneous forms */
  totalExtraDonations?: Maybe<Scalars['Int']>;
  totalOtherDonations?: Maybe<Scalars['Int']>;
  /** The donated amount that been raised by sharing through social media */
  totalSocialDonations?: Maybe<Scalars['Int']>;
  /** The donated amount in cents that been raised by text message */
  totalTextDonations?: Maybe<Scalars['Int']>;
};

/** Paginated list of campaigns */
export type DriveCampaignList = {
  __typename?: 'DriveCampaignList';
  /** Total campaigns */
  count?: Maybe<Scalars['Int']>;
  list?: Maybe<Array<Maybe<DriveCampaign>>>;
  offset?: Maybe<Scalars['Int']>;
};

/** Statistics for a set of campaigns in campaign-search. */
export type DriveCampaignSearchStatistics = {
  __typename?: 'DriveCampaignSearchStatistics';
  avgDonationDollars?: Maybe<Scalars['Float']>;
  avgEmailsDelivered?: Maybe<Scalars['Float']>;
  avgEmailsPerParticipant?: Maybe<Scalars['Float']>;
  avgRaisedDollarsPerCampaign?: Maybe<Scalars['Float']>;
  avgRaisedDollarsPerEmail?: Maybe<Scalars['Float']>;
  avgTeamSize?: Maybe<Scalars['Float']>;
  totalCampaign?: Maybe<Scalars['BigInt']>;
  totalCountDelivered?: Maybe<Scalars['BigInt']>;
  totalCountParticpants?: Maybe<Scalars['BigInt']>;
  totalDonations?: Maybe<Scalars['BigInt']>;
  totalRaisedByEmail?: Maybe<Scalars['Float']>;
  totalRaisedDollars?: Maybe<Scalars['Float']>;
};

export type DriveCampaignUpdateInput = {
  notes?: InputMaybe<Scalars['String']>;
};

export type DriveDateCompareInput = {
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
};

/** Represents a Event object and its attributes */
export type DriveEvent = {
  __typename?: 'DriveEvent';
  /** List of messages in the activity feed. Order by posted ascending by default. */
  activityFeed?: Maybe<Array<Maybe<DriveEventActivityFeed>>>;
  /** Activity types for an event. e.g. `football`, 'basketball', etc. */
  activityTypes?: Maybe<Array<Scalars['String']>>;
  /** Event Agenda which include start-time/end-time of event dates. Should be empty is **agendaConfig.isSameTime** is true */
  agenda?: Maybe<Array<DriveEventAgendaItem>>;
  /** The configuration for agenda. Format as follows ```JSON { isSameTime: true, startTime: "09:00AM", endTime: "07:00PM" } ``` where **isSameTime** means all event dates will be occured at same **startTime** and **endTime**. If **isSameTime**, **startTime** and **endTime** should be filled-in */
  agendaConfig?: Maybe<Scalars['JSON']>;
  /** List of attendees. */
  attendees?: Maybe<Array<DriveEventAttendee>>;
  /** Booth information for an event. */
  booth?: Maybe<DriveEventBooth>;
  /** The user who is the clinic leader. */
  clinicLeader?: Maybe<User>;
  /** The user who created the event. */
  createdBy?: Maybe<User>;
  /** The description of an event. */
  description?: Maybe<Scalars['String']>;
  /** Indicator that event requires a door prize. */
  doorPrizedRequired?: Maybe<Scalars['Boolean']>;
  /** The end date of an event. */
  endDate: Scalars['String'];
  /** The hotel address for the attendees */
  hotelAddress?: Maybe<Scalars['String']>;
  /** The hotel name for the attendees */
  hotelName?: Maybe<Scalars['String']>;
  /** Url for hubspot link. */
  hubspotTrackingLink?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Notes associated for the event. */
  notes?: Maybe<Scalars['String']>;
  /** A indicator whether the event had been completed planning phase. */
  planningCompleted?: Maybe<Scalars['Boolean']>;
  /** The time zone of an event */
  preferedTimezone?: Maybe<Scalars['String']>;
  /** Description for the sponsorship */
  sponsorDescription?: Maybe<Scalars['String']>;
  /** Indicator whether the door prize has been confirmed. */
  sponsorDoorPrized?: Maybe<Scalars['Boolean']>;
  /** The start date of an event. */
  startDate: Scalars['String'];
  /** The events status. */
  status: DriveEventStatus;
  /** The tier level for this event. Valid values is `1`, `2` and `3` */
  tier?: Maybe<Scalars['Int']>;
  /** The name of the event. */
  title: Scalars['String'];
  /** Venue location information for an event. */
  venue?: Maybe<DriveVenue>;
  /** Website link for an event. */
  websiteLink?: Maybe<Scalars['String']>;
};

/** Information of a activity feed message */
export type DriveEventActivityFeed = {
  __typename?: 'DriveEventActivityFeed';
  /** A list of replies the message */
  children?: Maybe<Array<Maybe<DriveEventActivityFeed>>>;
  /** The date this post was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The event id */
  eventId: Scalars['ID'];
  /** If the message is pinned to the top */
  featured?: Maybe<Scalars['Boolean']>;
  /** The ID of the message */
  id: Scalars['String'];
  /** The message posted */
  message?: Maybe<Scalars['String']>;
  /** The date when this post has been updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** A users first name and last and link to profile photo */
  user?: Maybe<User>;
  /** The id of the user who posted the message */
  userId: Scalars['ID'];
};

export type DriveEventActivityInput = {
  featured?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['String']>;
};

export type DriveEventAgendaInput = {
  description?: InputMaybe<Scalars['String']>;
  endTime?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  startTime?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/** Attributes for an events agenda */
export type DriveEventAgendaItem = {
  __typename?: 'DriveEventAgendaItem';
  description?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  location?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
};

/** Represents an events attendee attributes */
export type DriveEventAttendee = {
  __typename?: 'DriveEventAttendee';
  /** The department the attendee is in. */
  department?: Maybe<Scalars['String']>;
  /** The event ID */
  eventId: Scalars['ID'];
  /** The status of an attendee flight request. Default is `NO_REQUEST`. */
  flightRequest?: Maybe<EventAttendeeRequestStatus>;
  /** The status of an attendee hotel request. Default is `NO_REQUEST`. */
  hotelRequest?: Maybe<EventAttendeeRequestStatus>;
  /** @deprecated Use userId instead */
  id: Scalars['ID'];
  /** Indication whether this attendee a clinic leader. */
  isClinicLeader?: Maybe<Scalars['Boolean']>;
  /** The status of an attendee attendence. */
  status: DriveAttendanceStatus;
  /** User Information of attendee, includes `firstName`, `lastName`, `email`, etc. defined in UserDirectory. */
  user?: Maybe<User>;
  /** The udId of an attendee. e.g. `ud_abc123` */
  userId: Scalars['ID'];
};

export type DriveEventAttendeeInput = {
  department?: InputMaybe<Scalars['String']>;
  flightRequest?: InputMaybe<EventAttendeeRequestStatus>;
  hotelRequest?: InputMaybe<EventAttendeeRequestStatus>;
  userId?: InputMaybe<Scalars['ID']>;
};

/** Booth attributes */
export type DriveEventBooth = {
  __typename?: 'DriveEventBooth';
  /** Notes for the booth e.g. Electricity is paid for */
  boothNotes?: Maybe<Scalars['String']>;
  /** The time to end breakdown or clean up event equipment in Venue. */
  breakdownEndTime?: Maybe<Scalars['DateTime']>;
  /** The time to start breakdown or clean up event equipment in Venue. */
  breakdownStartTime?: Maybe<Scalars['DateTime']>;
  /** Indication whether electricity is provided. */
  electricityProvided?: Maybe<Scalars['Boolean']>;
  /** Indication whether flooring is provided. */
  flooringProvided?: Maybe<Scalars['Boolean']>;
  /** Indication whether internet is provided. */
  internetProvided?: Maybe<Scalars['Boolean']>;
  /** The time to end setup for event */
  setupEndTime?: Maybe<Scalars['DateTime']>;
  /** The time to begin setup for event */
  setupStartTime?: Maybe<Scalars['DateTime']>;
};

export type DriveEventBoothInput = {
  boothNotes?: InputMaybe<Scalars['String']>;
  breakdownEndTime?: InputMaybe<Scalars['String']>;
  breakdownStartTime?: InputMaybe<Scalars['String']>;
  electricityProvided?: InputMaybe<Scalars['Boolean']>;
  flooringProvided?: InputMaybe<Scalars['Boolean']>;
  internetProvided?: InputMaybe<Scalars['Boolean']>;
  setupEndTime?: InputMaybe<Scalars['String']>;
  setupStartTime?: InputMaybe<Scalars['String']>;
};

export type DriveEventInput = {
  activityTypes?: InputMaybe<Array<Scalars['String']>>;
  agenda?: InputMaybe<Array<DriveEventAgendaInput>>;
  agendaConfig?: InputMaybe<Scalars['JSON']>;
  attendees?: InputMaybe<Array<DriveEventAttendeeInput>>;
  booth?: InputMaybe<DriveEventBoothInput>;
  clinicLeaderId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  hotelAddress?: InputMaybe<Scalars['String']>;
  hotelName?: InputMaybe<Scalars['String']>;
  hubspotTrackingLink?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  planningCompleted?: InputMaybe<Scalars['Boolean']>;
  preferedTimezone?: InputMaybe<Scalars['String']>;
  sponsor?: InputMaybe<DriveEventSponsorInput>;
  startDate?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<DriveEventStatus>;
  tier?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  venue?: InputMaybe<DriveEventVenueInput>;
  websiteLink?: InputMaybe<Scalars['String']>;
};

/** Paginated list of drive events */
export type DriveEventResults = {
  __typename?: 'DriveEventResults';
  events?: Maybe<Array<DriveEvent>>;
  offset?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type DriveEventSearchInput = {
  activityTypes?: InputMaybe<Array<Scalars['String']>>;
  attendees?: InputMaybe<Array<Scalars['String']>>;
  boothOptions?: InputMaybe<DriveEventBoothInput>;
  dateRange?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  endDate?: InputMaybe<DriveDateCompareInput>;
  eventStatus?: InputMaybe<EventDateStatus>;
  id?: InputMaybe<Scalars['ID']>;
  sponsor?: InputMaybe<DriveEventSponsorInput>;
  startDate?: InputMaybe<DriveDateCompareInput>;
  states?: InputMaybe<Array<Scalars['String']>>;
  statuses?: InputMaybe<Array<DriveEventStatus>>;
  tiers?: InputMaybe<Array<Scalars['Int']>>;
  title?: InputMaybe<Scalars['String']>;
};

export type DriveEventSponsorInput = {
  doorPrized?: InputMaybe<Scalars['Boolean']>;
  doorPrizedRequired?: InputMaybe<Scalars['Boolean']>;
  sponsorDescription?: InputMaybe<Scalars['String']>;
};

export enum DriveEventStatus {
  Closed = 'CLOSED',
  EmailSent = 'EMAIL_SENT',
  NotAttending = 'NOT_ATTENDING',
  Pending = 'PENDING',
  Registered = 'REGISTERED'
}

export type DriveEventVenueInput = {
  buildingOrRoom?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

export type DriveFieldInput = {
  label?: InputMaybe<Scalars['String']>;
  linkText?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  type: DriveFieldType;
  value: Scalars['String'];
};

export enum DriveFieldType {
  Html = 'HTML',
  Link = 'LINK',
  Text = 'TEXT'
}

export type DriveFileInput = {
  content: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Organization Summary information used by Drive's Get List Application */
export type DriveGetListOrganizationSummaryReturn = {
  __typename?: 'DriveGetListOrganizationSummaryReturn';
  /**
   * Entity ID of the organization. Its purpose is for caching in Apollo Client.
   * @deprecated use id
   */
  _id?: Maybe<Scalars['Int']>;
  activitiesWithoutCampaigns?: Maybe<Scalars['Int']>;
  averageRaisedPerCampaign?: Maybe<Scalars['Float']>;
  campaignCompleted?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['Int']>;
  hubspotId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  /** Number of campaigns that completed of this Organization */
  name?: Maybe<Scalars['String']>;
  /** Total students of the organization. Inherited from NCES data and studentCount from orgs-api. */
  population?: Maybe<Scalars['Int']>;
  /** @deprecated use potentialRaisedDollars */
  potentialRaised?: Maybe<Scalars['Float']>;
  /** Total potential raised in dollars for this organization. Equals NCES total students multiply with National RPK. */
  potentialRaisedDollars?: Maybe<Scalars['Float']>;
  /** @deprecated use saturationPercentage */
  saturationPercent?: Maybe<Scalars['Float']>;
  /** Saturation percentage of organization. It is calculated by divide total logged-in students by NCES total students of this organization. */
  saturationPercentage?: Maybe<Scalars['Float']>;
  state?: Maybe<Scalars['String']>;
  /** Lifetime total raised in dollars of organization. Computed from all non-upcoming campaigns. */
  totalRaised?: Maybe<Scalars['Float']>;
  /** Lifetime total raised in cents of organization. */
  totalRaisedCents?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

export type DriveGetlistProspectsReturn = {
  __typename?: 'DriveGetlistProspectsReturn';
  offset?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<HubspotProspectsDeals>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type DriveGetlistWinbackReturn = {
  __typename?: 'DriveGetlistWinbackReturn';
  offset?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<WinbackDeals>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** Group Leader information in Drive */
export type DriveGroupLeader = {
  __typename?: 'DriveGroupLeader';
  /** The full name of the group leader */
  name?: Maybe<Scalars['String']>;
};

export type DriveOrgListResults = {
  __typename?: 'DriveOrgListResults';
  offset?: Maybe<Scalars['Int']>;
  orgs?: Maybe<Array<Maybe<DriveGetListOrganizationSummaryReturn>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type DriveOrgUserTrackingSearchInput = {
  trackingIdStartsWith?: InputMaybe<Scalars['String']>;
  trackingType?: InputMaybe<Scalars['String']>;
};

/** Organization used by Drive */
export type DriveOrganization = {
  __typename?: 'DriveOrganization';
  activity?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  legalName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

/** List of organizations used by Drive */
export type DriveOrganizationList = {
  __typename?: 'DriveOrganizationList';
  list: Array<Maybe<DriveOrganization>>;
  offset?: Maybe<Scalars['Int']>;
};

/** Statistics information related to Participants in one campaign */
export type DriveParticipantStats = {
  __typename?: 'DriveParticipantStats';
  /** Count of participants who had logged in */
  loggedInCount?: Maybe<Scalars['Int']>;
  /** Count of participants with 20 emails sent */
  participantsWithAtLeastTwentyEmails?: Maybe<Scalars['Int']>;
  /** Percentage of active participants of campaign. */
  participationPercent?: Maybe<Scalars['Int']>;
  /** Count of participants with at least one donation */
  withAtLeastOneDonation?: Maybe<Scalars['Int']>;
};

/** Information of a sms scheduled for release */
export type DrivePreloadSmsSchedule = {
  __typename?: 'DrivePreloadSmsSchedule';
  /** The date for release. Valid format is YYYY-MM-DD */
  date?: Maybe<Scalars['String']>;
  /** The campaign id */
  fundraiserId?: Maybe<Scalars['Int']>;
  /** The timeframe for release. */
  timeframe?: Maybe<DriveScheduleTimeFrame>;
  /** The timezone for scheduled release */
  timezone?: Maybe<Scalars['String']>;
};

/** Input use for create schedule for preload email */
export type DriveScheduleInput = {
  /** Date to send out. Valid format is YYYY-MM-DD */
  date: Scalars['String'];
  /** Timeframe of schedule release. */
  timeframe: DriveScheduleTimeFrame;
  /** Timezone of datetime to send out. e.g. American/New_York */
  timezone?: InputMaybe<Scalars['String']>;
};

export enum DriveScheduleTimeFrame {
  Afternoon = 'AFTERNOON',
  Evening = 'EVENING',
  Morning = 'MORNING'
}

export type DriveScheduleUpdateInput = {
  /** Date to send out. Valid format is YYYY-MM-DD */
  date?: InputMaybe<Scalars['String']>;
  /** Timeframe of schedule release. */
  timeframe?: InputMaybe<DriveScheduleTimeFrame>;
  /** Timezone of datetime to send out. e.g. American/New_York */
  timezone?: InputMaybe<Scalars['String']>;
};

export type DriveTrackingInput = {
  trackingId: Scalars['String'];
  trackingType?: InputMaybe<Scalars['String']>;
  trackingValue: Scalars['String'];
  trackingValueType?: InputMaybe<Scalars['String']>;
};

export type DriveUser = {
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type DriveUserSearchInput = {
  isInternal?: InputMaybe<Scalars['Boolean']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type DriveUsersResult = {
  __typename?: 'DriveUsersResult';
  nextPage?: Maybe<Scalars['Boolean']>;
  users?: Maybe<Array<Maybe<DriveAccountUser>>>;
};

/** Venue or location for Event used by Event Clinic */
export type DriveVenue = {
  __typename?: 'DriveVenue';
  /** Building name or Room number. e.g. `Room 330` */
  buildingOrRoom?: Maybe<Scalars['String']>;
  /** City of Venue location, e.g. `Dallas` */
  city?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Venue Name. e.g. `Hillton Convention Center`, e.g. */
  name: Scalars['String'];
  /** The short-code for US States. e.g. `TX`, 'CA' */
  state?: Maybe<Scalars['String']>;
};

export type DriveVenueSearchInput = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

export type EditSubmissionDocumentResultObject = {
  __typename?: 'EditSubmissionDocumentResultObject';
  s3FileName: Scalars['String'];
};

/** Information of a emails for a camapgin */
export type EmailsInfo = {
  __typename?: 'EmailsInfo';
  /** Total emails bounced back due to failed delivery */
  bounced?: Maybe<Scalars['Int']>;
  /** Total emails loaded by particpants */
  loaded?: Maybe<Scalars['Int']>;
  /** The schedule (timezone-sensitive) thatthe emails are scheduled to release */
  scheduledReleaseDate?: Maybe<ScheduledReleaseInfo>;
  /** Total emails sent */
  sent?: Maybe<Scalars['Int']>;
  /** The datetime the emails were sent out */
  sentAt?: Maybe<Scalars['DateTime']>;
};

export type Error = {
  message: Scalars['String'];
};

export type Event = {
  __typename?: 'Event';
  activity?: Maybe<Scalars['String']>;
  activityLevel?: Maybe<Scalars['String']>;
  activityType?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  bus_fee?: Maybe<Scalars['Float']>;
  bus_time?: Maybe<Scalars['String']>;
  cancellation_status?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  conference?: Maybe<Scalars['String']>;
  conference_event_id?: Maybe<Scalars['Int']>;
  conference_id?: Maybe<Scalars['Int']>;
  confirmed?: Maybe<Scalars['String']>;
  confirmedStatusBoolean?: Maybe<Scalars['Boolean']>;
  contract?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['Date']>;
  departure_location?: Maybe<Scalars['String']>;
  directions?: Maybe<Scalars['String']>;
  early_dismissal_required?: Maybe<Scalars['String']>;
  early_dismissal_time?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['String']>;
  estimated_return_time?: Maybe<Scalars['String']>;
  event?: Maybe<Scalars['String']>;
  eventOfficials?: Maybe<Array<Maybe<Official>>>;
  eventTiming?: Maybe<Scalars['String']>;
  event_date?: Maybe<Scalars['Date']>;
  event_id: Scalars['Int'];
  exists_in_mls?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Float']>;
  formattedEventDate?: Maybe<Scalars['String']>;
  formattedEventDateSystem?: Maybe<Scalars['String']>;
  formattedEventDay?: Maybe<Scalars['String']>;
  g_s?: Maybe<Scalars['String']>;
  gate_revenue?: Maybe<Scalars['Float']>;
  groupval?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  impact_event?: Maybe<Scalars['String']>;
  isDuplicate?: Maybe<Scalars['Boolean']>;
  lead?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  loss_points?: Maybe<Scalars['Int']>;
  num_buses?: Maybe<Scalars['Int']>;
  opponent?: Maybe<Scalars['String']>;
  opponent_code?: Maybe<Scalars['String']>;
  opponent_score?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  place?: Maybe<Scalars['String']>;
  prep_setup?: Maybe<Scalars['String']>;
  promote?: Maybe<Scalars['String']>;
  results?: Maybe<Scalars['String']>;
  revenue?: Maybe<Scalars['Float']>;
  rollover?: Maybe<Scalars['String']>;
  rolloverStatusBoolean?: Maybe<Scalars['Boolean']>;
  seasonInfo?: Maybe<Scalars['String']>;
  seasonSportCode?: Maybe<Scalars['String']>;
  season_team?: Maybe<Scalars['Int']>;
  sportCode?: Maybe<Scalars['String']>;
  sportDescription?: Maybe<Scalars['String']>;
  sportGender?: Maybe<Scalars['String']>;
  sportLevel?: Maybe<Scalars['String']>;
  sportName?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['String']>;
  team_score?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  tournament?: Maybe<Scalars['String']>;
  trans_id?: Maybe<Scalars['Int']>;
  transportDetails?: Maybe<Scalars['String']>;
  transport_comments?: Maybe<Scalars['String']>;
  transportation?: Maybe<Scalars['String']>;
  update_at?: Maybe<Scalars['Date']>;
  vehicle_count?: Maybe<Scalars['Int']>;
  web_dir?: Maybe<Scalars['String']>;
  weekdayname?: Maybe<Scalars['String']>;
  win_points?: Maybe<Scalars['Int']>;
  years?: Maybe<Scalars['String']>;
};

export enum EventAttendeeRequestStatus {
  NoRequest = 'NO_REQUEST',
  Processed = 'PROCESSED',
  Request = 'REQUEST'
}

export type EventCalendarFiltersInput = {
  activity?: InputMaybe<Scalars['String']>;
  activityName?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  endDate?: InputMaybe<Scalars['Date']>;
  facilities?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  gender?: InputMaybe<Scalars['String']>;
  group?: InputMaybe<Scalars['String']>;
  homeAway?: InputMaybe<Scalars['String']>;
  levels?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  opponents?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startDate?: InputMaybe<Scalars['Date']>;
  teams?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  vehicleType?: InputMaybe<Scalars['String']>;
};

export type EventContract = {
  __typename?: 'EventContract';
  comments?: Maybe<Scalars['String']>;
  event_contract_number?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  main_event_id?: Maybe<Scalars['Int']>;
  participant?: Maybe<Scalars['String']>;
  signee_name?: Maybe<Scalars['String']>;
  signees?: Maybe<Array<Maybe<ContractSignees>>>;
  years?: Maybe<Scalars['String']>;
};

export enum EventDateStatus {
  Active = 'Active',
  Closed = 'Closed',
  Upcoming = 'Upcoming'
}

export type EventOfficialReport = {
  __typename?: 'EventOfficialReport';
  comments?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['String']>;
  facility?: Maybe<Scalars['String']>;
  officials?: Maybe<Array<Maybe<EventOfficials>>>;
  opponent?: Maybe<Scalars['String']>;
  team?: Maybe<Scalars['String']>;
};

export type EventOfficialReportFilter = {
  event_date: Scalars['Date'];
};

export type EventOfficials = {
  __typename?: 'EventOfficials';
  address?: Maybe<Scalars['String']>;
  cell_phone?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  duty?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  home_phone?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  official_id?: Maybe<Scalars['String']>;
  paid?: Maybe<Scalars['String']>;
  pay_code?: Maybe<Scalars['String']>;
  received?: Maybe<Scalars['String']>;
  salary?: Maybe<Scalars['Float']>;
  ssn?: Maybe<Scalars['String']>;
  work_phone?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type EventParticipants = {
  __typename?: 'EventParticipants';
  SchoolName?: Maybe<Scalars['String']>;
  contract_received?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  notes?: Maybe<Scalars['String']>;
  paid?: Maybe<Scalars['String']>;
  school_code?: Maybe<Scalars['String']>;
};

export type EventParticipantsInput = {
  contract_received?: InputMaybe<Scalars['String']>;
  event_id?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  notes?: InputMaybe<Scalars['String']>;
  paid?: InputMaybe<Scalars['String']>;
  school_code?: InputMaybe<Scalars['String']>;
};

export type EventPreparations = {
  __typename?: 'EventPreparations';
  comments?: Maybe<Scalars['String']>;
  event?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  prep?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['String']>;
};

export type EventPreparationsInput = {
  comments?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  prep?: InputMaybe<Scalars['String']>;
  qty?: InputMaybe<Scalars['String']>;
};

export type EventTransportDetails = {
  __typename?: 'EventTransportDetails';
  driver_name?: Maybe<Scalars['String']>;
  driver_phone?: Maybe<Scalars['String']>;
  event_date?: Maybe<Scalars['Date']>;
  event_id?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  vehicle_id?: Maybe<Scalars['String']>;
  vehicle_type?: Maybe<Scalars['String']>;
};

export type EventTransportDetailsDeleteManyCount = {
  __typename?: 'EventTransportDetailsDeleteManyCount';
  count?: Maybe<Scalars['Int']>;
};

export type EventTransportDetailsInput = {
  driver_name?: InputMaybe<Scalars['String']>;
  driver_phone?: InputMaybe<Scalars['String']>;
  event_id?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  vehicle_id?: InputMaybe<Scalars['String']>;
  vehicle_type?: InputMaybe<Scalars['String']>;
};

export type EventsOpponent = {
  __typename?: 'EventsOpponent';
  activity?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['Int']>;
  event_date?: Maybe<Scalars['Date']>;
  eventsOpponentId?: Maybe<Scalars['Int']>;
  season_years?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['String']>;
};

export type ExportFile = {
  __typename?: 'ExportFile';
  content?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
};

export type ExternalBankAccount = {
  __typename?: 'ExternalBankAccount';
  accountId: Scalars['String'];
  accountType?: Maybe<AccountType>;
  bankName: Scalars['String'];
  campaignId: Scalars['String'];
  last4: Scalars['String'];
};

export enum ExternalTransferDirectionEnum {
  Credit = 'Credit',
  Debit = 'Debit'
}

/** Owned by Vault Only used in legacy createCard */
export enum Financial_Gateway {
  Stripe = 'stripe'
}

export enum Fundraiser_Status {
  Active = 'active',
  Approved = 'approved',
  Archive = 'archive',
  Closed = 'closed',
  Deleted = 'deleted',
  Final = 'final',
  PendingApproval = 'pending_approval',
  Settled = 'settled'
}

export type Facility = {
  __typename?: 'Facility';
  Address1?: Maybe<Scalars['String']>;
  Address2?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['Date']>;
  Facility_id?: Maybe<Scalars['Int']>;
  State?: Maybe<Scalars['String']>;
  ZipCode?: Maybe<Scalars['String']>;
  directions?: Maybe<Scalars['String']>;
  indoor?: Maybe<Scalars['String']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  location_id?: Maybe<Scalars['Int']>;
  ml_site_id?: Maybe<Scalars['Int']>;
  ml_space?: Maybe<Scalars['String']>;
  ml_space_id?: Maybe<Scalars['Int']>;
  picture?: Maybe<Scalars['String']>;
  place_name: Scalars['String'];
  show?: Maybe<Scalars['String']>;
  specialDirections?: Maybe<Scalars['String']>;
  web?: Maybe<Scalars['String']>;
};

export type FacilityLocation = {
  __typename?: 'FacilityLocation';
  Address1?: Maybe<Scalars['String']>;
  Address2?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
  ZipCode?: Maybe<Scalars['String']>;
  directions?: Maybe<Scalars['String']>;
  location_id?: Maybe<Scalars['Int']>;
  place_name?: Maybe<Scalars['String']>;
};

export type Family = {
  __typename?: 'Family';
  children?: Maybe<Array<Maybe<User>>>;
  parents?: Maybe<Array<Maybe<User>>>;
};

export enum FinAcctStatus {
  ActionRequired = 'ACTION_REQUIRED',
  Active = 'ACTIVE',
  Approved = 'APPROVED',
  AwaitingReapproval = 'AWAITING_REAPPROVAL',
  Declined = 'DECLINED',
  Pending = 'PENDING',
  Terminated = 'TERMINATED',
  UnderReview = 'UNDER_REVIEW'
}

export enum FinAcctStripeEnv {
  Raise = 'RAISE',
  Sponsor = 'SPONSOR'
}

export type FinancialAccount = {
  __typename?: 'FinancialAccount';
  acctId?: Maybe<Scalars['ID']>;
  applicationId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['DateTime'];
  customerId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  kybId: Scalars['ID'];
  paymentMethodId?: Maybe<Scalars['ID']>;
  processor: Processor;
  status: FinAcctStatus;
  stripeEnv: FinAcctStripeEnv;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialAcctOrg = {
  __typename?: 'FinancialAcctOrg';
  acctId?: Maybe<Scalars['ID']>;
  applicationId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['ID']>;
  finAcctId?: Maybe<Scalars['ID']>;
  kybId?: Maybe<Scalars['ID']>;
  orgEin?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['ID']>;
  orgName?: Maybe<Scalars['String']>;
  orgType?: Maybe<OrgType>;
  paymentMethodId?: Maybe<Scalars['ID']>;
  processor?: Maybe<Processor>;
  status?: Maybe<FinAcctStatus>;
  stripeEnv?: Maybe<FinAcctStripeEnv>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialAcctUser = {
  __typename?: 'FinancialAcctUser';
  acctId?: Maybe<Scalars['ID']>;
  applicationId?: Maybe<Scalars['ID']>;
  authorizationCreatedAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['ID']>;
  finAcctId?: Maybe<Scalars['ID']>;
  isBeneficialOwner?: Maybe<Scalars['Boolean']>;
  isContact?: Maybe<Scalars['Boolean']>;
  isPrincipal?: Maybe<Scalars['Boolean']>;
  isRepresentative?: Maybe<Scalars['Boolean']>;
  kybId?: Maybe<Scalars['ID']>;
  kycId?: Maybe<Scalars['ID']>;
  orgId?: Maybe<Scalars['ID']>;
  personId?: Maybe<Scalars['ID']>;
  processor?: Maybe<Processor>;
  status?: Maybe<FinAcctStatus>;
  userId?: Maybe<Scalars['ID']>;
};

export type FinancialAddress = {
  __typename?: 'FinancialAddress';
  city?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type FinancialAddressInput = {
  city?: InputMaybe<Scalars['String']>;
  line1?: InputMaybe<Scalars['String']>;
  line2?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

/** Filtering options for nullable Float type */
export type FloatNullableFilter = {
  /** Equals operation */
  equals?: InputMaybe<Scalars['Float']>;
  /** Greater Than operation */
  gt?: InputMaybe<Scalars['Float']>;
  /** Greater Than or Equal operation */
  gte?: InputMaybe<Scalars['Float']>;
  /** In operation for multiple values */
  in?: InputMaybe<Array<Scalars['Float']>>;
  /** Less Than operation */
  lt?: InputMaybe<Scalars['Float']>;
  /** Less Than or Equal operation */
  lte?: InputMaybe<Scalars['Float']>;
  /** Not Equal operation */
  not?: InputMaybe<Scalars['Float']>;
  /** Not In operation for multiple values */
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type Fundraiser = {
  __typename?: 'Fundraiser';
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  goal?: Maybe<Scalars['Int']>;
  groupName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  netRaised?: Maybe<Scalars['Int']>;
  organization?: Maybe<Organization>;
  prefund?: Maybe<Prefund>;
  salesRep?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  status?: Maybe<Fundraiser_Status>;
  totalRaised?: Maybe<Scalars['Int']>;
};

export type FundraiserApprovalSubmission = {
  __typename?: 'FundraiserApprovalSubmission';
  additionalNotes?: Maybe<Scalars['String']>;
  campaignRaiseId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  customerSupportInfo?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  estimatedTotalRaisedCents?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['String']>;
  formId: Scalars['Int'];
  formType?: Maybe<FundraiserApprovalSubmissionFormType>;
  fundraiserApprovalSubmissionDocs: Array<FundraiserApprovalSubmissionDocument>;
  fundraiserApprovers: Array<FundraiserApprover>;
  fundraiserServiceWebsite?: Maybe<Scalars['String']>;
  groupSize?: Maybe<Scalars['Int']>;
  howThisWorks?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lastName?: Maybe<Scalars['String']>;
  latestRevision: Scalars['Boolean'];
  newFundraiser: Scalars['Boolean'];
  organizationId?: Maybe<Scalars['String']>;
  previousRevision?: Maybe<Scalars['Int']>;
  pricingAndFees?: Maybe<Scalars['String']>;
  programId?: Maybe<Scalars['String']>;
  proposedStartDate?: Maybe<Scalars['DateTime']>;
  reason?: Maybe<Scalars['String']>;
  revisionRequest?: Maybe<Scalars['String']>;
  safety?: Maybe<Scalars['String']>;
  status: FundraiserApprovalSubmissionStatus;
  submitterId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type FundraiserApprovalSubmissionDocDataInput = {
  filename: Scalars['NonEmptyString'];
  filetype: Scalars['NonEmptyString'];
  id?: InputMaybe<Scalars['Int']>;
  location: Scalars['NonEmptyString'];
};

export type FundraiserApprovalSubmissionDocument = {
  __typename?: 'FundraiserApprovalSubmissionDocument';
  createdAt?: Maybe<Scalars['DateTime']>;
  filename?: Maybe<Scalars['NonEmptyString']>;
  filetype?: Maybe<Scalars['NonEmptyString']>;
  id?: Maybe<Scalars['Int']>;
  location?: Maybe<Scalars['NonEmptyString']>;
  s3Filename?: Maybe<Scalars['NonEmptyString']>;
  status?: Maybe<Scalars['NonEmptyString']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FundraiserApprovalSubmissionDocumentInput = {
  action: FundraiserApprovalSubmissionDocumentInputAction;
  document: FundraiserApprovalSubmissionDocDataInput;
};

export enum FundraiserApprovalSubmissionDocumentInputAction {
  Add = 'ADD',
  Remove = 'REMOVE'
}

export enum FundraiserApprovalSubmissionFormType {
  Athon = 'ATHON',
  Events = 'EVENTS',
  Online = 'ONLINE',
  Other = 'OTHER',
  Product = 'PRODUCT',
  Snap = 'SNAP'
}

export type FundraiserApprovalSubmissionResult = {
  __typename?: 'FundraiserApprovalSubmissionResult';
  errors: Array<Maybe<Scalars['String']>>;
  fundraiserApprovalSubmission?: Maybe<FundraiserApprovalSubmission>;
  status: Scalars['String'];
};

export enum FundraiserApprovalSubmissionStatus {
  Approved = 'APPROVED',
  Draft = 'DRAFT',
  RevisionRequested = 'REVISION_REQUESTED',
  Submitted = 'SUBMITTED'
}

export type FundraiserApprovalSubmissionsOrgNames = {
  __typename?: 'FundraiserApprovalSubmissionsOrgNames';
  fundraiserName: Scalars['String'];
  organizationName: Scalars['String'];
  programName: Scalars['String'];
};

export type FundraiserApprovalSubmissionsOrgNamesResult = FundraiserApprovalSubmissionsOrgNames | InvalidToken;

export type FundraiserApprover = {
  __typename?: 'FundraiserApprover';
  approverType?: Maybe<FundraiserApproverType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  roleId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type FundraiserApproverDataInput = {
  approverType?: InputMaybe<FundraiserApproverType>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  roleId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type FundraiserApproverInput = {
  action: FundraiserApproverInputAction;
  approver: FundraiserApproverDataInput;
};

export enum FundraiserApproverInputAction {
  Add = 'ADD',
  Remove = 'REMOVE',
  Update = 'UPDATE'
}

export enum FundraiserApproverType {
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY'
}

export type FundraiserCheerwall = {
  __typename?: 'FundraiserCheerwall';
  cheers?: Maybe<Array<Maybe<Cheer>>>;
  count?: Maybe<Scalars['Int']>;
};

export type FundraiserCustomRewardsByLevel = {
  __typename?: 'FundraiserCustomRewardsByLevel';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  sizes?: Maybe<Scalars['String']>;
};

export type FundraiserCustomRewardsData = {
  __typename?: 'FundraiserCustomRewardsData';
  rewards?: Maybe<Array<Maybe<FundraiserCustomRewardsByLevel>>>;
  rewardsLevel: Scalars['Int'];
};

export type FundraiserDataByCoachJoinCode = {
  __typename?: 'FundraiserDataByCoachJoinCode';
  coachJoinCode?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  joinCode?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type FundraiserEntityResource = {
  __typename?: 'FundraiserEntityResource';
  entityId?: Maybe<Scalars['Int']>;
};

export type FundraiserGroup = {
  __typename?: 'FundraiserGroup';
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
};

export type FundraiserList = {
  __typename?: 'FundraiserList';
  hasMore?: Maybe<Scalars['Boolean']>;
  list: Array<Fundraiser>;
};

export enum FundraiserMediaType {
  Image = 'IMAGE',
  Vimeo = 'VIMEO',
  Youtube = 'YOUTUBE'
}

export type FundraiserParticipantPublic = {
  __typename?: 'FundraiserParticipantPublic';
  /** Raise Participant Id */
  id: Scalars['Int'];
  participant?: Maybe<ParticipantPublic>;
};

/** @deprecated(reason: "Use participantsPublic instead") */
export type FundraiserParticipants = {
  __typename?: 'FundraiserParticipants';
  id: Scalars['Int'];
  participant?: Maybe<User>;
};

export type FundraiserRaisedAmount = {
  __typename?: 'FundraiserRaisedAmount';
  subtotalCents?: Maybe<Scalars['Int']>;
};

export type FundraiserRewardLevelsCount = {
  __typename?: 'FundraiserRewardLevelsCount';
  levelsCount?: Maybe<Scalars['Int']>;
};

export type FundraiserRewardsProduct = {
  __typename?: 'FundraiserRewardsProduct';
  default?: Maybe<Scalars['Boolean']>;
  fitting?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sizes?: Maybe<Scalars['String']>;
  tier?: Maybe<Scalars['Int']>;
};

export type FundraiserSearchParams = {
  name?: InputMaybe<Scalars['String']>;
};

export type FundraiserSetupProgress = {
  __typename?: 'FundraiserSetupProgress';
  nextIncompletedSection?: Maybe<Scalars['String']>;
  sections: Array<FundraiserSetupProgressSection>;
};

export type FundraiserSetupProgressInput = {
  currentStep: Scalars['Int'];
  id: Scalars['ID'];
  section?: InputMaybe<Scalars['String']>;
  status: FundraiserSetupProgressStatus;
};

export type FundraiserSetupProgressSection = {
  __typename?: 'FundraiserSetupProgressSection';
  currentStep: Scalars['Int'];
  id: Scalars['ID'];
  section: Scalars['String'];
  status: FundraiserSetupProgressStatus;
};

export enum FundraiserSetupProgressStatus {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  NotApplicable = 'NOT_APPLICABLE',
  NotStarted = 'NOT_STARTED'
}

export enum FundraiserStatus {
  Active = 'ACTIVE',
  Approved = 'APPROVED',
  Archive = 'ARCHIVE',
  Closed = 'CLOSED',
  Deleted = 'DELETED',
  Final = 'FINAL',
  PendingApproval = 'PENDING_APPROVAL',
  Settled = 'SETTLED'
}

export type FundraiserTopDonation = {
  __typename?: 'FundraiserTopDonation';
  donorName?: Maybe<Scalars['String']>;
  subtotalCents?: Maybe<Scalars['Int']>;
};

export type FundraiserUser = {
  __typename?: 'FundraiserUser';
  currentStep?: Maybe<Scalars['String']>;
  fundraiserId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  joinCode?: Maybe<Scalars['String']>;
  participantGroupPersonListId?: Maybe<Scalars['String']>;
  participantQuote?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  supporterId?: Maybe<Scalars['String']>;
  udid?: Maybe<Scalars['udid']>;
};

export enum FundraiserUserImportType {
  CsvImport = 'csv_import',
  Rollover = 'rollover'
}

export type FundraiserUserIncentiveId = {
  __typename?: 'FundraiserUserIncentiveID';
  id?: Maybe<Scalars['Int']>;
};

export type FundraiserUserRole = {
  __typename?: 'FundraiserUserRole';
  isGroupLeader?: Maybe<Scalars['Boolean']>;
  isParticipant?: Maybe<Scalars['Boolean']>;
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type GalleryItems = {
  __typename?: 'GalleryItems';
  campaignLogo?: Maybe<Scalars['String']>;
  campaignLogoThumb?: Maybe<Scalars['String']>;
  carouselItems?: Maybe<Array<Maybe<CarouselItem>>>;
};

export type GetCompetitionListInput = {
  from_date?: InputMaybe<Scalars['String']>;
  opponents?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  to_date?: InputMaybe<Scalars['String']>;
};

export type GetDailyCalendarBusScheduleByDateInput = {
  from_date?: InputMaybe<Scalars['String']>;
  to_date?: InputMaybe<Scalars['String']>;
};

export type GetDailyCalendarEventsByDateInput = {
  from_date?: InputMaybe<Scalars['String']>;
  group?: InputMaybe<Scalars['String']>;
  to_date?: InputMaybe<Scalars['String']>;
};

export type GetDailyCalendarOfficialsByDateInput = {
  from_date?: InputMaybe<Scalars['String']>;
  to_date?: InputMaybe<Scalars['String']>;
};

export type GetDailyCalendarPreparationsByDateInput = {
  from_date?: InputMaybe<Scalars['String']>;
  to_date?: InputMaybe<Scalars['String']>;
};

export type GetDailyCalendarWorkersByDateInput = {
  from_date?: InputMaybe<Scalars['String']>;
  to_date?: InputMaybe<Scalars['String']>;
};

export type GetEventByIdInput = {
  event_id: Scalars['Int'];
};

export type GetEventParticipantsByIdInput = {
  id: Scalars['Int'];
};

export type GetEventPreparationsByEventIdInput = {
  event_id: Scalars['Int'];
};

export type GetEventPreparationsByIdInput = {
  id: Scalars['Int'];
};

export type GetEventTransportDetailsByEventOrDatesInput = {
  event_date_end?: InputMaybe<Scalars['Date']>;
  event_date_start?: InputMaybe<Scalars['Date']>;
  event_id?: InputMaybe<Scalars['Int']>;
};

export type GetEventsFilteredByOpponentStartAndEndDate = {
  endDate: Scalars['String'];
  opponent: Scalars['String'];
  startDate: Scalars['String'];
};

export type GetFacilityByPlaceNameInput = {
  place_name: Scalars['String'];
};

export type GetLevelByIdInput = {
  ID: Scalars['Int'];
};

export type GetOfficialAssignmentsInput = {
  from_date?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  levels?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  official_name?: InputMaybe<Scalars['String']>;
  teams?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  to_date?: InputMaybe<Scalars['String']>;
};

export type GetOfficialByIdInput = {
  id: Scalars['Int'];
};

export type GetOfficialDutyByIdInput = {
  id: Scalars['Int'];
};

export type GetOfficialPoolByIdInput = {
  ID?: InputMaybe<Scalars['String']>;
};

export type GetOpponentBySchoolCodeInput = {
  SchoolCode: Scalars['String'];
};

export type GetPreparationByIdInput = {
  id: Scalars['Int'];
};

export type GetSchoolInfoByIdInput = {
  id: Scalars['String'];
};

export type GetVehicleByIdInput = {
  id: Scalars['Int'];
};

export type GetWorkerByIdInput = {
  id: Scalars['Int'];
};

export type GetWorkerPoolByIdInput = {
  ID: Scalars['Int'];
};

export type GetWorkersByEventIdInput = {
  event_id: Scalars['Int'];
};

export type GiftShopIntentInput = {
  donateIntentId: Scalars['String'];
  fundraiserId: Scalars['Int'];
  fundraiserUserId: Scalars['Int'];
  participantUserDirectoryId: Scalars['String'];
  purchaseIntentId?: InputMaybe<Scalars['String']>;
  status: PurchaseIntentStatus;
  userId: Scalars['Int'];
};

export type GiftShopIntentUpdateInput = {
  donorEmail?: InputMaybe<Scalars['String']>;
  donorFirstName?: InputMaybe<Scalars['String']>;
  donorLastName?: InputMaybe<Scalars['String']>;
  fundraiserUserId?: InputMaybe<Scalars['Int']>;
  participantUserDirectoryId?: InputMaybe<Scalars['String']>;
  paymentProvider?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['Int']>;
};

export type GiftShopPurchaseIntentData = {
  __typename?: 'GiftShopPurchaseIntentData';
  donateIntentId?: Maybe<Scalars['String']>;
  donorEmail?: Maybe<Scalars['String']>;
  donorFirstName?: Maybe<Scalars['String']>;
  donorId?: Maybe<Scalars['String']>;
  donorLastName?: Maybe<Scalars['String']>;
  fundraiserUserId?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  lineItems: Array<DonorGiftShopItem>;
  participantUserDirectoryId?: Maybe<Scalars['String']>;
  purchaseIntentId?: Maybe<Scalars['String']>;
  raiseFundraiserId?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
};

export type GiftShopPurchaseIntentId = {
  __typename?: 'GiftShopPurchaseIntentId';
  id: Scalars['String'];
};

export type GiftShopPurchaseItemUpdate = {
  id: Scalars['String'];
  size: Scalars['String'];
};

export type GroupLeader = {
  __typename?: 'GroupLeader';
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

/** See CampaignMembership for details */
export type GroupLeaderCampaign = {
  __typename?: 'GroupLeaderCampaign';
  basicStatus: BasicCampaignStatus;
  donationLink: Scalars['String'];
  entityId?: Maybe<Scalars['Int']>;
  goalInDollars?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  isCocoach: Scalars['Boolean'];
  joinCode?: Maybe<Scalars['String']>;
  /** Getting Kyc status adds time to your query **expensive** */
  kyc?: Maybe<CampaignKyc>;
  name: Scalars['String'];
  /** Primary group leader */
  primary?: Maybe<PrimaryGroupLeader>;
  raiseUserJoinedAt?: Maybe<Scalars['String']>;
  /** Getting roster adds time to your query */
  roster?: Maybe<Array<Maybe<Roster>>>;
  status: CampaignStatus;
  teamSize?: Maybe<Scalars['Int']>;
  totalCentsRaised?: Maybe<Scalars['Int']>;
  whyDonateText?: Maybe<Scalars['String']>;
};

export type GroupLeaderDonationLevels = {
  __typename?: 'GroupLeaderDonationLevels';
  amount: Scalars['Float'];
  id: Scalars['ID'];
  impact?: Maybe<Scalars['String']>;
  rewardDescription?: Maybe<Scalars['String']>;
};

export type GroupLeaderDonationLevelsInput = {
  id: Scalars['ID'];
  impact?: InputMaybe<Scalars['String']>;
  rewardDescription?: InputMaybe<Scalars['String']>;
};

export type GroupLeaderEditFundraiserInput = {
  howDonationsAreUsed?: InputMaybe<Scalars['String']>;
  /** will always be logo.${extension}, the full url will be constructed in the resolver */
  logo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  personalMessage?: InputMaybe<Scalars['String']>;
  primaryColor?: InputMaybe<Scalars['String']>;
  secondaryColor?: InputMaybe<Scalars['String']>;
  /** remove setup progress and calculate in the backend */
  setupCompleted?: InputMaybe<Scalars['Boolean']>;
  whyDonations?: InputMaybe<Scalars['String']>;
};

export type GroupLeaderFundraiser = {
  __typename?: 'GroupLeaderFundraiser';
  activity?: Maybe<Scalars['String']>;
  activityTier?: Maybe<Scalars['String']>;
  donationLevels?: Maybe<Array<GroupLeaderDonationLevels>>;
  endDate?: Maybe<Scalars['Date']>;
  goal?: Maybe<Scalars['Float']>;
  howDonationsAreUsed?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  logo?: Maybe<Scalars['String']>;
  mediaGallery?: Maybe<Array<GroupLeaderMediaGallery>>;
  name?: Maybe<Scalars['String']>;
  participantsCount?: Maybe<Scalars['Int']>;
  personalMessage?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  setupProgress?: Maybe<FundraiserSetupProgress>;
  slug?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  status?: Maybe<FundraiserStatus>;
  whyDonations?: Maybe<Scalars['String']>;
};

export type GroupLeaderFundraiserMediaGalleryUpdateInput = {
  add?: InputMaybe<Array<GroupLeaderMediaGalleryInput>>;
  remove?: InputMaybe<Array<Scalars['ID']>>;
  update?: InputMaybe<GroupLeaderMediaGalleryUpdateInput>;
};

export type GroupLeaderFundraisersPaginated = {
  __typename?: 'GroupLeaderFundraisersPaginated';
  fundraisers: Array<Maybe<GroupLeaderFundraiser>>;
  pagination?: Maybe<GroupLeadersPagination>;
};

export type GroupLeaderList = {
  __typename?: 'GroupLeaderList';
  count?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
  list: Array<Maybe<GroupLeader>>;
};

export type GroupLeaderMediaGallery = {
  __typename?: 'GroupLeaderMediaGallery';
  fundraiserId: Scalars['String'];
  id: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  mediaType?: Maybe<FundraiserMediaType>;
  position?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
};

export type GroupLeaderMediaGalleryInput = {
  fundraiserId: Scalars['ID'];
  id?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<Scalars['String']>;
  mediaType: FundraiserMediaType;
  position?: InputMaybe<Scalars['Int']>;
  url?: InputMaybe<Scalars['String']>;
};

export type GroupLeaderMediaGalleryUpdateInput = {
  items?: InputMaybe<Array<GroupLeaderMediaGalleryInput>>;
  setupCompleted?: InputMaybe<Scalars['Boolean']>;
};

export enum GroupLeadersFundraiserUserRole {
  GroupLeader = 'GROUP_LEADER',
  Participant = 'PARTICIPANT'
}

export type GroupLeadersPagination = {
  __typename?: 'GroupLeadersPagination';
  currentPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
};

export enum GroupRosterFilterEnum {
  GroupId = 'groupId',
  Id = 'id',
  MemberId = 'memberId',
  RosterId = 'rosterId',
  SeasonId = 'seasonId'
}

export type GroupsOverviewDashboardResponse = {
  __typename?: 'GroupsOverviewDashboardResponse';
  count: Scalars['Int'];
  overview?: Maybe<Array<Maybe<SpendGroupsOverview>>>;
};

export type HelpDocumentDeleteInput = {
  key: Scalars['String'];
};

export type HelpDocumentUploadInput = {
  base64File: Scalars['String'];
};

export type HmAccountRole = {
  __typename?: 'HmAccountRole';
  account_id?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  role_id?: Maybe<Scalars['Int']>;
};

export type HmGraphSales = {
  __typename?: 'HmGraphSales';
  soldDuringWeek?: Maybe<Scalars['Int']>;
  weekName?: Maybe<Scalars['String']>;
};

export type HmSalesRep = {
  __typename?: 'HmSalesRep';
  rep_email?: Maybe<Scalars['String']>;
  sales_rep?: Maybe<Scalars['String']>;
};

export type HmSpendAccount = {
  __typename?: 'HmSpendAccount';
  id?: Maybe<Scalars['Int']>;
  orgTeamMembers?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type HmSpendData = {
  __typename?: 'HmSpendData';
  credited?: Maybe<Scalars['Int']>;
  inactiveCards?: Maybe<Scalars['Int']>;
  paid?: Maybe<Scalars['Int']>;
  pastDue?: Maybe<Scalars['Int']>;
  pending?: Maybe<Scalars['Int']>;
  upcoming?: Maybe<Scalars['Int']>;
};

export type HmSponsorData = {
  __typename?: 'HmSponsorData';
  activeSponsorships?: Maybe<Scalars['Int']>;
  assetCount?: Maybe<Scalars['Int']>;
  available?: Maybe<Scalars['Int']>;
  sponsorShipValue?: Maybe<Scalars['Int']>;
  totalAssets?: Maybe<Scalars['Int']>;
};

export type HmStore = {
  __typename?: 'HmStore';
  active_stores?: Maybe<Scalars['String']>;
  monthly_sales?: Maybe<Array<Maybe<HmGraphSales>>>;
  total_orders?: Maybe<Scalars['String']>;
  total_points?: Maybe<Scalars['String']>;
  total_sales?: Maybe<Scalars['String']>;
};

export type HmUser = {
  __typename?: 'HmUser';
  email?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};

/** Hubspot Engagement entity */
export type HubspotCallEngagement = {
  __typename?: 'HubspotCallEngagement';
  contactIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  dealIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  engagementId?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type HubspotProspectsDeals = {
  __typename?: 'HubspotProspectsDeals';
  activity?: Maybe<Scalars['String']>;
  dealName?: Maybe<Scalars['String']>;
  dealStage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isProspectPinned?: Maybe<Scalars['Boolean']>;
  lastActivityDate?: Maybe<Scalars['String']>;
  leaderFirstName?: Maybe<Scalars['String']>;
  leaderLastName?: Maybe<Scalars['String']>;
  projectedLaunchDate?: Maybe<Scalars['String']>;
};

export enum HubspotSortDirection {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING'
}

/** Common payload for mutations. */
export type IMutationResult = {
  __typename?: 'IMutationResult';
  data?: Maybe<Scalars['JSON']>;
  message?: Maybe<Scalars['String']>;
};

export type ImageInput = {
  url: Scalars['String'];
};

export type InsAddPreApprovedContactApproverInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type InsAddPreApprovedContactOrgInput = {
  id?: InputMaybe<Scalars['String']>;
  modId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  raiseId?: InputMaybe<Scalars['Int']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type InsAddPreApprovedContactsResult = {
  __typename?: 'InsAddPreApprovedContactsResult';
  contactsResult?: Maybe<Array<Maybe<InsPreApprovedContactResult>>>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  status: Scalars['String'];
};

export type InsAnalyticsSummaryStat = {
  __typename?: 'InsAnalyticsSummaryStat';
  amount_raised_cents: Scalars['Int'];
  campaign_id: Scalars['Int'];
  month: Scalars['Int'];
  year: Scalars['Int'];
};

export type InsCampaignDonation = {
  __typename?: 'InsCampaignDonation';
  amount_cents?: Maybe<Scalars['Int']>;
  campaign_id: Scalars['Int'];
  campaign_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  donor_name?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  participant_name?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
};

export type InsCampaignRaiseEntityInfo = {
  __typename?: 'InsCampaignRaiseEntityInfo';
  campaign_id: Scalars['Int'];
  entity_id?: Maybe<Scalars['Int']>;
};

export type InsCampaignSettlement = {
  __typename?: 'InsCampaignSettlement';
  amount_cents?: Maybe<Scalars['Int']>;
  campaign_id: Scalars['Int'];
  campaign_name?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  last_updated?: Maybe<Scalars['DateTime']>;
  method?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  status?: Maybe<Scalars['String']>;
};

export type InsCampaignStat = {
  __typename?: 'InsCampaignStat';
  donations_count?: Maybe<Scalars['Int']>;
  end_date?: Maybe<Scalars['DateTime']>;
  forecasted_amount_cents?: Maybe<Scalars['Int']>;
  group_leader_email?: Maybe<Scalars['String']>;
  group_leader_name?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  insights_status?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  participants?: Maybe<Scalars['Int']>;
  participation?: Maybe<Scalars['Float']>;
  preloading?: Maybe<Scalars['Float']>;
  slug: Scalars['String'];
  start_date?: Maybe<Scalars['DateTime']>;
  total_raised_cents?: Maybe<Scalars['Int']>;
};

export type InsCampaignsData = {
  __typename?: 'InsCampaignsData';
  analyticsSummaryStats?: Maybe<Array<Maybe<InsAnalyticsSummaryStat>>>;
  campaignStats?: Maybe<Array<Maybe<InsCampaignStat>>>;
  donationMapStats?: Maybe<Array<Maybe<InsDonationMapStat>>>;
  inviteStats?: Maybe<Array<Maybe<InsInviteStat>>>;
  lTRChart?: Maybe<InsLtrChartData>;
  monthlyCampaignStats?: Maybe<Array<Maybe<InsMonthlyCampaignStat>>>;
};

export type InsDeletePreApprovedContactResult = {
  __typename?: 'InsDeletePreApprovedContactResult';
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  status: Scalars['String'];
};

export type InsDonationMapStat = {
  __typename?: 'InsDonationMapStat';
  campaign_id?: Maybe<Scalars['Int']>;
  campaign_ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
  donations_amount_cents?: Maybe<Scalars['Int']>;
  donations_count?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  postal_code?: Maybe<Scalars['String']>;
};

export type InsDonorParticipantContact = {
  __typename?: 'InsDonorParticipantContact';
  campaign_id: Scalars['Int'];
  donor_email?: Maybe<Scalars['String']>;
  donor_id?: Maybe<Scalars['Int']>;
  donor_name?: Maybe<Scalars['String']>;
  participant_email?: Maybe<Scalars['String']>;
  participant_id?: Maybe<Scalars['Int']>;
  participant_name?: Maybe<Scalars['String']>;
};

export type InsEditPreApprovedContactInput = {
  activity?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type InsEditPreApprovedContactResult = {
  __typename?: 'InsEditPreApprovedContactResult';
  error?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type InsEditPreApprovedInvite = {
  __typename?: 'InsEditPreApprovedInvite';
  activity: Scalars['String'];
  createdBy: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  inviteStatus: Scalars['String'];
  lastName: Scalars['String'];
  orgName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
};

export type InsEditPreApprovedInviteResult = {
  __typename?: 'InsEditPreApprovedInviteResult';
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  status: Scalars['String'];
  updatedInvite?: Maybe<InsEditPreApprovedInvite>;
};

export type InsEmailTestPreApprovedContactResult = {
  __typename?: 'InsEmailTestPreApprovedContactResult';
  email: Scalars['String'];
  status: Scalars['String'];
};

export type InsInviteStat = {
  __typename?: 'InsInviteStat';
  campaign_id?: Maybe<Scalars['Int']>;
  campaign_ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
  invite_count: Scalars['Int'];
  invite_type: Scalars['String'];
  total_amount_cents: Scalars['Int'];
};

export type InsLtrChartData = {
  __typename?: 'InsLTRChartData';
  activeCampaignsCount?: Maybe<Scalars['Int']>;
  activeCampaignsParticipation?: Maybe<Scalars['Float']>;
  activeCampaignsTotalCents?: Maybe<Scalars['Int']>;
  avgDonationCents?: Maybe<Scalars['Int']>;
  closedCampaignsCount?: Maybe<Scalars['Int']>;
  closedCampaignsParticipation?: Maybe<Scalars['Float']>;
  closedCampaignsTotalCents?: Maybe<Scalars['Int']>;
  ltr?: Maybe<Scalars['Int']>;
  upcomingCampaignsCount?: Maybe<Scalars['Int']>;
  upcomingCampaignsForecastedAmountCents?: Maybe<Scalars['Int']>;
  upcomingCampaignsParticipantSignIn?: Maybe<Scalars['Float']>;
};

export type InsMonthlyCampaignStat = {
  __typename?: 'InsMonthlyCampaignStat';
  campaign_ids: Array<Maybe<Scalars['Int']>>;
  month: Scalars['Int'];
  participation?: Maybe<Scalars['Float']>;
  preloading?: Maybe<Scalars['Float']>;
  year: Scalars['Int'];
};

export type InsOrg = {
  __typename?: 'InsOrg';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};

export type InsOrgCampaignSummary = {
  __typename?: 'InsOrgCampaignSummary';
  campaignsCount: Scalars['Int'];
  orgId?: Maybe<Scalars['String']>;
  totalRaisedCents: Scalars['Int'];
};

export type InsOrgCampaignSummaryInput = {
  campaignIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  orgId?: InputMaybe<Scalars['String']>;
};

export type InsPreApprovedContact = {
  __typename?: 'InsPreApprovedContact';
  activity?: Maybe<Scalars['String']>;
  associated_org_id?: Maybe<Scalars['String']>;
  associated_org_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  invite_phone_number?: Maybe<Scalars['String']>;
  invite_sent_at?: Maybe<Scalars['DateTime']>;
  invite_status?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
};

export type InsPreApprovedContactInput = {
  activity: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type InsPreApprovedContactResult = {
  __typename?: 'InsPreApprovedContactResult';
  email: Scalars['String'];
  status: Scalars['String'];
};

export type InsResendPreApprovedContactResult = {
  __typename?: 'InsResendPreApprovedContactResult';
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  status: Scalars['String'];
};

export type InsSalesRepInfo = {
  __typename?: 'InsSalesRepInfo';
  email: Scalars['String'];
  featured_image?: Maybe<Scalars['String']>;
  hubspot_calendar_link?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  post_title?: Maybe<Scalars['String']>;
};

export enum InsSetNotificationPreferenceFlag {
  Off = 'OFF',
  On = 'ON'
}

export type InsSetNotificationPreferences = {
  notificationIds?: InputMaybe<Array<InputMaybe<InsUserNotificationType>>>;
  organizationIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  setTo: InsSetNotificationPreferenceFlag;
  type: InsSetNotificationType;
};

export type InsSetNotificationPreferencesResult = {
  __typename?: 'InsSetNotificationPreferencesResult';
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  messages?: Maybe<Array<Maybe<Scalars['String']>>>;
  status: Scalars['String'];
};

export enum InsSetNotificationType {
  Organization = 'ORGANIZATION',
  User = 'USER'
}

export type InsSetUserPreferencesResult = {
  __typename?: 'InsSetUserPreferencesResult';
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  messages?: Maybe<Array<Maybe<Scalars['String']>>>;
  status: Scalars['String'];
};

export type InsUser = {
  __typename?: 'InsUser';
  id: Scalars['Int'];
};

export enum InsUserNotificationType {
  CheckSent = 'CHECK_SENT',
  DepositMade = 'DEPOSIT_MADE',
  FundraiserCreated = 'FUNDRAISER_CREATED',
  FundraiserFinalized = 'FUNDRAISER_FINALIZED'
}

export type InsightsGetUserPreference = {
  __typename?: 'InsightsGetUserPreference';
  campaign_raise_id?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  org_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** Filtering options for Int type */
export type IntFilter = {
  /** Equals operation */
  equals?: InputMaybe<Scalars['Int']>;
  /** Greater Than operation */
  gt?: InputMaybe<Scalars['Int']>;
  /** Greater Than or Equal operation */
  gte?: InputMaybe<Scalars['Int']>;
  /** In operation for multiple values */
  in?: InputMaybe<Array<Scalars['Int']>>;
  /** Less Than operation */
  lt?: InputMaybe<Scalars['Int']>;
  /** Less Than or Equal operation */
  lte?: InputMaybe<Scalars['Int']>;
  /** Not Equal operation */
  not?: InputMaybe<Scalars['Int']>;
  /** Not In operation for multiple values */
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

/** Filtering options for nullable Int type */
export type IntNullableFilter = {
  /** Equals operation */
  equals?: InputMaybe<Scalars['Int']>;
  /** Greater Than operation */
  gt?: InputMaybe<Scalars['Int']>;
  /** Greater Than or Equal operation */
  gte?: InputMaybe<Scalars['Int']>;
  /** In operation for multiple values */
  in?: InputMaybe<Array<Scalars['Int']>>;
  /** Less Than operation */
  lt?: InputMaybe<Scalars['Int']>;
  /** Less Than or Equal operation */
  lte?: InputMaybe<Scalars['Int']>;
  /** Not Equal operation */
  not?: InputMaybe<Scalars['Int']>;
  /** Not In operation for multiple values */
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type InvalidCampaignPersonListData = {
  __typename?: 'InvalidCampaignPersonListData';
  email: Scalars['String'];
  errorMessage?: Maybe<Scalars['String']>;
};

export type InvalidCampaignSmsInviteData = {
  __typename?: 'InvalidCampaignSmsInviteData';
  errorMessage: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
};

export type InvalidToken = Error & {
  __typename?: 'InvalidToken';
  message: Scalars['String'];
};

export type InviteError = Error & {
  __typename?: 'InviteError';
  message: Scalars['String'];
};

export enum InviteFilterEnum {
  Group = 'group',
  Program = 'program',
  Status = 'status',
  Type = 'type'
}

export type InviteInfo = {
  __typename?: 'InviteInfo';
  email?: Maybe<Scalars['String']>;
  flow?: Maybe<Scalars['String']>;
  flowParams?: Maybe<Scalars['JSONObject']>;
  joincode?: Maybe<Scalars['String']>;
  params?: Maybe<UserInviteParams>;
  phoneNumber?: Maybe<Scalars['String']>;
  requester?: Maybe<User>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<InviteType>;
};

export type InviteList = {
  __typename?: 'InviteList';
  acceptedAt?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  joincode?: Maybe<Scalars['String']>;
  params?: Maybe<UserInviteParams>;
  phoneNumber?: Maybe<Scalars['String']>;
  requester?: Maybe<User>;
  status?: Maybe<UserInviteStatus>;
  type?: Maybe<InviteType>;
};

export type InviteParentArguments = {
  joincode?: InputMaybe<Scalars['String']>;
};

export type InviteSendResult = InviteError | InviteInfo;

export enum InviteType {
  Org = 'org',
  Parent = 'parent'
}

export type InviteUserArguments = {
  apps?: InputMaybe<Array<InputMaybe<UserApps>>>;
  consumer?: InputMaybe<UserApps>;
  fundraiserId?: InputMaybe<Scalars['Int']>;
  occupation?: InputMaybe<UserOccupation>;
  orgsAffiliation?: InputMaybe<Array<UserOrgAffiliationPayload>>;
  permissionIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  redirectUrl?: InputMaybe<Scalars['String']>;
  roleIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type InviteUserOrgsArguments = {
  orgId?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<UserApps>;
  roleId?: InputMaybe<Scalars['String']>;
};

export type InvitesListResponse = {
  __typename?: 'InvitesListResponse';
  invites?: Maybe<Array<InviteList>>;
  pagination: Pagination;
};

export enum InvoiceFilterEnum {
  Date = 'date',
  GroupId = 'groupId',
  GroupName = 'groupName',
  GroupRosterId = 'groupRosterId',
  InviteId = 'inviteId',
  PlayerName = 'playerName',
  SeasonId = 'seasonId',
  Status = 'status',
  Unreconciled = 'unreconciled'
}

export enum InvoiceGuardianFilterEnum {
  GroupId = 'groupId',
  RosterId = 'rosterId'
}

export enum InvoiceReportFilterEnum {
  DateAfter = 'dateAfter',
  DateBefore = 'dateBefore',
  ExcludeArchived = 'excludeArchived',
  GroupId = 'groupId',
  GroupRosterId = 'groupRosterId',
  InviteId = 'inviteId',
  OrgId = 'orgId',
  PastDueRange = 'pastDueRange',
  PlayerName = 'playerName',
  Status = 'status'
}

/** Owned by Vault Only used in legacy createCard */
export type IssueCardInput = {
  altName?: InputMaybe<Scalars['String']>;
  cardType: Card_Type;
  metadata?: InputMaybe<VaultCardMetadataInput>;
  recipientName?: InputMaybe<Scalars['String']>;
  shippingAddress?: InputMaybe<FinancialAddressInput>;
  shippingService?: InputMaybe<Shipping_Service>;
  spendingLimitAmount: Scalars['Int'];
  spendingLimitInterval: Spending_Limit_Interval;
};

export enum JoinCodeType {
  GroupLeader = 'GROUP_LEADER',
  Invalid = 'INVALID',
  Participant = 'PARTICIPANT'
}

export type JoinFundraiserUserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export enum JoinType {
  Fanx = 'FANX',
  Sma = 'SMA'
}

export type JoinedFundraiserId = {
  __typename?: 'JoinedFundraiserID';
  /** Returns the ID of the fundraiser */
  id?: Maybe<Scalars['Int']>;
};

export enum Kyb_Structure {
  FreeZoneEstablishment = 'free_zone_establishment',
  FreeZoneLlc = 'free_zone_llc',
  GovernmentInstrumentality = 'government_instrumentality',
  GovernmentalUnit = 'governmental_unit',
  IncorporatedNonProfit = 'incorporated_non_profit',
  LimitedLiabilityPartnership = 'limited_liability_partnership',
  Llc = 'llc',
  MultiMemberLlc = 'multi_member_llc',
  PrivateCompany = 'private_company',
  PrivateCorporation = 'private_corporation',
  PrivatePartnership = 'private_partnership',
  PublicCompany = 'public_company',
  PublicCorporation = 'public_corporation',
  PublicPartnership = 'public_partnership',
  SingleMemberLlc = 'single_member_llc',
  SoleEstablishment = 'sole_establishment',
  SoleProprietorship = 'sole_proprietorship',
  TaxExemptGovernmentInstrumentality = 'tax_exempt_government_instrumentality',
  UnincorporatedAssociation = 'unincorporated_association',
  UnincorporatedNonProfit = 'unincorporated_non_profit'
}

export enum Kyb_Type {
  Company = 'company',
  GovernmentEntity = 'government_entity',
  Individual = 'individual',
  NonProfit = 'non_profit'
}

export type Leader = {
  __typename?: 'Leader';
  createdAt: Scalars['DateTime'];
  isConfirmed?: Maybe<Scalars['Boolean']>;
  title: TeamTitle;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LegacyTransactionsInput = {
  accountId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
};

export type LegacyTransactionsOutput = {
  __typename?: 'LegacyTransactionsOutput';
  count: Scalars['Int'];
  transactions: Array<Maybe<Transaction>>;
};

export type Level = {
  __typename?: 'Level';
  ID: Scalars['Int'];
  Level?: Maybe<Scalars['String']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
};

export enum LogoPosition {
  FrontCenter = 'FRONT_CENTER',
  FrontLeftArm = 'FRONT_LEFT_ARM',
  FrontLeftChest = 'FRONT_LEFT_CHEST',
  FrontLeftLeg = 'FRONT_LEFT_LEG',
  FrontRightArm = 'FRONT_RIGHT_ARM',
  FrontRightChest = 'FRONT_RIGHT_CHEST',
  FrontRightLeg = 'FRONT_RIGHT_LEG'
}

export type MdmDetails = DriveUser & {
  __typename?: 'MDMDetails';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type MagentoOrder = {
  __typename?: 'MagentoOrder';
  baseDiscountAmount?: Maybe<Scalars['Float']>;
  baseGrandTotal?: Maybe<Scalars['Float']>;
  baseShippingAmount?: Maybe<Scalars['Float']>;
  baseSubtotal?: Maybe<Scalars['Float']>;
  baseTaxAmount?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['ID']>;
  customerEmail?: Maybe<Scalars['String']>;
  customerFirstName?: Maybe<Scalars['String']>;
  customerLastName?: Maybe<Scalars['String']>;
  discountAmount?: Maybe<Scalars['Float']>;
  grandTotal?: Maybe<Scalars['Float']>;
  incrementId?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<OrderItem>>>;
  mageworxRewardPointsAmount?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['BigInt']>;
  scopeId?: Maybe<Scalars['BigInt']>;
  shippingAmount?: Maybe<Scalars['Float']>;
  state?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  storeDashbordStatus?: Maybe<Scalars['String']>;
  subtotal?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  totalPaid?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type MagentoOrderUnionType = MagentoOrders | OnException;

export type MagentoOrders = {
  __typename?: 'MagentoOrders';
  orders?: Maybe<Array<Maybe<MagentoOrder>>>;
};

export type MagentoPointActivities = {
  __typename?: 'MagentoPointActivities';
  activities?: Maybe<Array<Maybe<MagentoPointActivity>>>;
};

export type MagentoPointActivity = {
  __typename?: 'MagentoPointActivity';
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['BigInt']>;
  customerName?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['String']>;
  orderIncrementId?: Maybe<Scalars['String']>;
  pointsBalance?: Maybe<Scalars['Float']>;
  pointsDelta?: Maybe<Scalars['Float']>;
  receiver?: Maybe<MagentoPointReceiver>;
  scopeId?: Maybe<Scalars['BigInt']>;
  sender?: Maybe<MagentoPointSender>;
  status?: Maybe<Scalars['String']>;
  storeCode?: Maybe<Scalars['String']>;
  storeName?: Maybe<Scalars['String']>;
  teamName?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['BigInt']>;
  transactionType?: Maybe<Scalars['String']>;
};

export type MagentoPointActivityUnionType = MagentoPointActivities | OnException;

export type MagentoPointReceiver = {
  __typename?: 'MagentoPointReceiver';
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  receiverId?: Maybe<Scalars['Int']>;
};

export type MagentoPointSender = {
  __typename?: 'MagentoPointSender';
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  senderId?: Maybe<Scalars['Int']>;
};

export type MagentoStore = {
  __typename?: 'MagentoStore';
  accountManagerId?: Maybe<Scalars['String']>;
  activityType?: Maybe<Scalars['String']>;
  baseTotalSales?: Maybe<Scalars['Float']>;
  brands?: Maybe<Array<Maybe<Brand>>>;
  campaignId?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  digitalLogo?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  earnedPoints?: Maybe<Scalars['Float']>;
  earnedPointsBalance?: Maybe<Scalars['Float']>;
  earnedSharedPoints?: Maybe<Scalars['Float']>;
  email?: Maybe<Scalars['String']>;
  embroideryLogo?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Int']>;
  entityId?: Maybe<Scalars['BigInt']>;
  expiringPoints?: Maybe<Scalars['Float']>;
  favicon?: Maybe<Scalars['String']>;
  givenPoints?: Maybe<Scalars['Float']>;
  givenSharedPoints?: Maybe<Scalars['Float']>;
  groupLeaderEmail?: Maybe<Scalars['String']>;
  hatLogo?: Maybe<Scalars['String']>;
  headerLogo?: Maybe<Scalars['String']>;
  heroSliderVariableId?: Maybe<Scalars['BigInt']>;
  homepagePageId?: Maybe<Scalars['String']>;
  managerId?: Maybe<Scalars['BigInt']>;
  name?: Maybe<Scalars['String']>;
  ncesId?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['Int']>;
  organizationName?: Maybe<Scalars['String']>;
  parentStoreId?: Maybe<Scalars['Int']>;
  pointsPercentage?: Maybe<Scalars['Int']>;
  primaryColor?: Maybe<Scalars['String']>;
  productColors?: Maybe<Array<Maybe<ScopeProductColor>>>;
  productPricePercentage?: Maybe<Scalars['Int']>;
  programId?: Maybe<Scalars['Int']>;
  raiseId?: Maybe<Scalars['Int']>;
  salesRepId?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  storeId?: Maybe<Scalars['BigInt']>;
  storevariablesId?: Maybe<Scalars['BigInt']>;
  teamName?: Maybe<Scalars['String']>;
  totalDiscountAmount?: Maybe<Scalars['Float']>;
  totalOrders?: Maybe<Scalars['BigInt']>;
  totalPoints?: Maybe<Scalars['Float']>;
  totalSales?: Maybe<Scalars['Float']>;
  zipCode?: Maybe<Scalars['Int']>;
};

export type MagentoStoreManager = {
  __typename?: 'MagentoStoreManager';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['Int']>;
  lastName?: Maybe<Scalars['String']>;
  managerId?: Maybe<Scalars['BigInt']>;
  points?: Maybe<Scalars['Float']>;
  scopeId?: Maybe<Scalars['BigInt']>;
};

export type MagentoStorePointsEarned = {
  __typename?: 'MagentoStorePointsEarned';
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  totalPoints?: Maybe<Scalars['Float']>;
};

export type MagentoStorePointsEarnedUnionType = MagentoStoresPointsEarned | OnException;

export type MagentoStoreUnionType = MagentoStore | OnException;

export type MagentoStoresPointsEarned = {
  __typename?: 'MagentoStoresPointsEarned';
  points?: Maybe<Array<Maybe<MagentoStorePointsEarned>>>;
};

export enum ManageAnnouncementDistinctOld {
  Announcement = 'announcement',
  SportName = 'sportName'
}

export type ManageAnnouncementFilterOld = {
  distinct?: InputMaybe<Array<InputMaybe<ManageAnnouncementDistinctOld>>>;
  orderBy?: InputMaybe<ManageAnnouncementOrderByOld>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ManageAnnouncementWhereOld>;
};

export type ManageAnnouncementListOld = {
  __typename?: 'ManageAnnouncementListOld';
  count?: Maybe<Scalars['Int']>;
  list?: Maybe<Array<Maybe<ManageAnnouncementOld>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

/** The object which contains all the input data for the ManageResource announcement query */
export type ManageAnnouncementOld = {
  __typename?: 'ManageAnnouncementOld';
  active?: Maybe<Scalars['String']>;
  announcement?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<Scalars['Int']>;
  emailFilter?: Maybe<Scalars['String']>;
  emailRequest?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Int']>;
  imageId?: Maybe<Scalars['Int']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  onFront?: Maybe<Scalars['Int']>;
  recordStatus?: Maybe<Scalars['Int']>;
  schoolId?: Maybe<Scalars['Int']>;
  showUrgentFrom?: Maybe<Scalars['Date']>;
  showUrgentUntil?: Maybe<Scalars['Date']>;
  sortVal?: Maybe<Scalars['Int']>;
  sport?: Maybe<Scalars['String']>;
  sportName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<Scalars['Int']>;
  urgent?: Maybe<Scalars['Boolean']>;
};

export type ManageAnnouncementOrderByOld = {
  endDate?: InputMaybe<SortOrderEnum>;
  id?: InputMaybe<SortOrderEnum>;
  sortVal?: InputMaybe<SortOrderEnum>;
  startDate?: InputMaybe<SortOrderEnum>;
  title?: InputMaybe<SortOrderEnum>;
};

export type ManageAnnouncementWhereOld = {
  id?: InputMaybe<Scalars['Int']>;
  isApproved?: InputMaybe<Scalars['Boolean']>;
  onFront?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  urgent?: InputMaybe<Scalars['Boolean']>;
};

export enum ManageCoachDistinctOld {
  AdId = 'adId',
  FirstName = 'firstName',
  LastName = 'lastName',
  SchoolId = 'schoolId',
  SeasonId = 'seasonId',
  Summary = 'summary',
  Title = 'title'
}

export type ManageCoachListFilterOld = {
  distinct?: InputMaybe<Array<InputMaybe<ManageCoachDistinctOld>>>;
  /** Defines how the coach list should be ordered. */
  orderBy?: InputMaybe<ManageCoachListOrderByOld>;
  /** Number of coaches to skip before starting to fetch the result set. */
  skip?: InputMaybe<Scalars['Int']>;
  /** Limits the number of coaches to fetch from the result set. */
  take?: InputMaybe<Scalars['Int']>;
  /** Conditions to filter the coach list. */
  where?: InputMaybe<ManageCoachListWhereOld>;
};

export type ManageCoachListOld = {
  __typename?: 'ManageCoachListOld';
  /** Total count of coaches that match the criteria. */
  count?: Maybe<Scalars['Int']>;
  /** The list of coaches. */
  list?: Maybe<Array<Maybe<ManageCoachOld>>>;
  /** Number of coaches skipped. */
  skip?: Maybe<Scalars['Int']>;
  /** Number of coaches fetched. */
  take?: Maybe<Scalars['Int']>;
};

export type ManageCoachListOrderByOld = {
  /** Orders coaches based on their address. */
  address?: InputMaybe<SortOrderEnum>;
  /** Orders coaches based on their city. */
  city?: InputMaybe<SortOrderEnum>;
  /** Orders coaches based on their first name. */
  firstName?: InputMaybe<SortOrderEnum>;
  /** Orders coaches based on their last name. */
  lastName?: InputMaybe<SortOrderEnum>;
  /** Orders coaches based on their postal code. */
  postalCode?: InputMaybe<SortOrderEnum>;
};

export type ManageCoachListWhereOld = {
  /** Associated Ad ID for the coach. */
  adId?: InputMaybe<Scalars['Int']>;
  /** Unique ID of the coach. */
  coachId?: InputMaybe<Scalars['Int']>;
  /** Timestamp when the coach was created. */
  createdAt?: InputMaybe<Scalars['Date']>;
  /** ID of the user who created the coach entry. */
  createdBy?: InputMaybe<Scalars['Int']>;
  /** First name of the coach. */
  firstName?: InputMaybe<Scalars['String']>;
  /** Indicates if the coach is a head coach. */
  headCoach?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the coach is approved. */
  isApproved?: InputMaybe<Scalars['Boolean']>;
  /** Last name of the coach. */
  lastName?: InputMaybe<Scalars['String']>;
  /** ID for the coach's photo. */
  photoId?: InputMaybe<Scalars['Int']>;
  /** School's unique ID associated with the coach. */
  schoolId?: InputMaybe<Scalars['Int']>;
  /** ID of the coaching season. */
  seasonId?: InputMaybe<Scalars['Int']>;
  /** Brief description or profile of the coach. */
  summary?: InputMaybe<Scalars['String']>;
  /** Title or position of the coach. */
  title?: InputMaybe<Scalars['String']>;
  /** Timestamp when the coach was last updated. */
  updatedAt?: InputMaybe<Scalars['Date']>;
  /** ID of the user who last updated the coach entry. */
  updatedBy?: InputMaybe<Scalars['Int']>;
};

export type ManageCoachOld = {
  __typename?: 'ManageCoachOld';
  /** Associated Ad ID for the coach. */
  adId?: Maybe<Scalars['Int']>;
  /** Unique ID of the coach. */
  coachId?: Maybe<Scalars['Int']>;
  /** Timestamp when the coach was created. */
  createdAt?: Maybe<Scalars['Date']>;
  /** ID of the user who created the coach entry. */
  createdBy?: Maybe<Scalars['Int']>;
  /** First name of the coach. */
  firstName?: Maybe<Scalars['String']>;
  /** Indicates if the coach is a head coach. */
  headCoach?: Maybe<Scalars['Boolean']>;
  /** Indicates if the coach is approved. */
  isApproved?: Maybe<Scalars['Boolean']>;
  /** Last name of the coach. */
  lastName?: Maybe<Scalars['String']>;
  /** ID for the coach's photo. */
  photoId?: Maybe<Scalars['Int']>;
  /** School's unique ID associated with the coach. */
  schoolId?: Maybe<Scalars['Int']>;
  /** ID of the coaching season. */
  seasonId?: Maybe<Scalars['Int']>;
  /** Brief description or profile of the coach. */
  summary?: Maybe<Scalars['String']>;
  /** Title or position of the coach. */
  title?: Maybe<Scalars['String']>;
  /** Timestamp when the coach was last updated. */
  updatedAt?: Maybe<Scalars['Date']>;
  /** ID of the user who last updated the coach entry. */
  updatedBy?: Maybe<Scalars['Int']>;
};

export enum ManageEventListDistinctOld {
  Gs = 'gs',
  Opponent = 'opponent',
  Place = 'place'
}

/** Criteria to filter and sort the list of events. */
export type ManageEventListFilterOld = {
  distinct?: InputMaybe<Array<InputMaybe<ManageEventListDistinctOld>>>;
  /** Defines how the list should be ordered. */
  orderBy?: InputMaybe<ManageEventListOrderByOld>;
  /** Number of items to skip before starting to fetch the result set. */
  skip?: InputMaybe<Scalars['Int']>;
  /** Limits the number of items to fetch from the result set. */
  take?: InputMaybe<Scalars['Int']>;
  /** Conditions to filter the list. */
  where?: InputMaybe<ManageEventListWhereOld>;
  /** Conditions to filter not the list. */
  whereNot?: InputMaybe<ManageEventListWhereNotOld>;
};

/** Represents a paginated list of events. */
export type ManageEventListOld = {
  __typename?: 'ManageEventListOld';
  /** Total count of events that match the criteria. */
  count?: Maybe<Scalars['Int']>;
  /** The list of events. */
  list?: Maybe<Array<Maybe<ManageEventOld>>>;
  /** Number of items skipped. */
  skip?: Maybe<Scalars['Int']>;
  /** Number of items fetched. */
  take?: Maybe<Scalars['Int']>;
};

/** Specifies the order in which the event list should be sorted. */
export type ManageEventListOrderByOld = {
  /** Orders events based on their confirmation status. */
  confirmed?: InputMaybe<SortOrderEnum>;
  event_date?: InputMaybe<SortOrderEnum>;
  /** Orders events based on the opponent's name. */
  opponent?: InputMaybe<SortOrderEnum>;
};

/** Conditions to filter the event list. */
export type ManageEventListWhereNotOld = {
  /** Filters events based on their activity or sport type. */
  activity?: InputMaybe<Scalars['String']>;
  /** Filters events based on their author or writer. */
  author?: InputMaybe<Scalars['String']>;
  /** Filters events based on their cancellation status. */
  cancellationStatus?: InputMaybe<Scalars['String']>;
  /** Filters events based on their conference details or name. */
  conference?: InputMaybe<Scalars['String']>;
  /** Filters events based on their related conference event ID. */
  conferenceEventId?: InputMaybe<Scalars['Int']>;
  /** Filters events based on their confirmation status. */
  confirmed?: InputMaybe<Scalars['String']>;
  /** Filters events based on the timestamp when they were created. */
  createdAt?: InputMaybe<Scalars['Date']>;
  /** Filters events based on their location for departure. */
  departureLocation?: InputMaybe<Scalars['String']>;
  /** Filters events based on their direction or map link. */
  directionLink?: InputMaybe<Scalars['String']>;
  /** Filters events based on their estimated time of return. */
  estimatedReturnTime?: InputMaybe<Scalars['String']>;
  /** Filters events based on their date. */
  eventDate?: InputMaybe<Scalars['Date']>;
  /** Filters events based on their title or main heading. */
  eventTitle?: InputMaybe<Scalars['String']>;
  /** Filters events based on whether they exist in the MLS system. */
  existsInMls?: InputMaybe<Scalars['Int']>;
  /** Filters events based on their gender specification. */
  gender?: InputMaybe<Scalars['String']>;
  /** Specifies the list of gs in which the scores list should be filter. */
  gs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Filters events based on their level. */
  level?: InputMaybe<Scalars['String']>;
  /** Filters events based on the opponent's name. */
  opponent?: InputMaybe<Scalars['String']>;
  /** Filters events based on the score achieved by the opponent team. */
  opponentScore?: InputMaybe<Scalars['String']>;
  /** Filters events based on their location or venue. */
  place?: InputMaybe<Scalars['String']>;
  /** Filters events based on their associated team for the season. */
  seasonTeam?: InputMaybe<Scalars['Int']>;
  /** Filters events based on their start time. */
  startTime?: InputMaybe<Scalars['String']>;
  /** Filters events based on the score achieved by the home team. */
  teamScore?: InputMaybe<Scalars['String']>;
  /** Filters events based on their transport comments. */
  transportComments?: InputMaybe<Scalars['String']>;
  /** Filters events based on their transportation details. */
  transportation?: InputMaybe<Scalars['String']>;
  /** Filters events based on the last update timestamp. */
  updatedAt?: InputMaybe<Scalars['Date']>;
  years?: InputMaybe<Scalars['String']>;
};

/** Conditions to filter the event list. */
export type ManageEventListWhereOld = {
  /** Filters events based on their activity or sport type. */
  activity?: InputMaybe<Scalars['String']>;
  /** Filters events based on their author or writer. */
  author?: InputMaybe<Scalars['String']>;
  /** Filters events based on their cancellation status. */
  cancellationStatus?: InputMaybe<Scalars['String']>;
  /** Filters events based on their conference details or name. */
  conference?: InputMaybe<Scalars['String']>;
  /** Filters events based on their related conference event ID. */
  conferenceEventId?: InputMaybe<Scalars['Int']>;
  /** Filters events based on their confirmation status. */
  confirmed?: InputMaybe<Scalars['String']>;
  /** Filters events based on the timestamp when they were created. */
  createdAt?: InputMaybe<Scalars['Date']>;
  /** Filters events based on their location for departure. */
  departureLocation?: InputMaybe<Scalars['String']>;
  /** Filters events based on their direction or map link. */
  directionLink?: InputMaybe<Scalars['String']>;
  /** Filters events based on the end date */
  endDate?: InputMaybe<Scalars['String']>;
  /** Filters events based on their estimated time of return. */
  estimatedReturnTime?: InputMaybe<Scalars['String']>;
  /** Filters events based on their date. */
  eventDate?: InputMaybe<Scalars['Date']>;
  /** Filters events based on their title or main heading. */
  eventTitle?: InputMaybe<Scalars['String']>;
  /** Filters events based on whether they exist in the MLS system. */
  existsInMls?: InputMaybe<Scalars['Int']>;
  /** Filters events based on their gender specification. */
  gender?: InputMaybe<Scalars['String']>;
  /** Specifies the list of gs in which the scores list should be filter. */
  gs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Filters events based on their level. */
  level?: InputMaybe<Scalars['String']>;
  /** Filters events based on the opponent's name. */
  opponent?: InputMaybe<Scalars['String']>;
  /** Filters events based on the score achieved by the opponent team. */
  opponentScore?: InputMaybe<Scalars['String']>;
  /** Filters events based on their location or venue. */
  place?: InputMaybe<Scalars['String']>;
  /** Filters events based on their associated team for the season. */
  seasonTeam?: InputMaybe<Scalars['Int']>;
  /** Filters events based on the start date */
  startDate?: InputMaybe<Scalars['String']>;
  /** Filters events based on their start time. */
  startTime?: InputMaybe<Scalars['String']>;
  /** Filters events based on the score achieved by the home team. */
  teamScore?: InputMaybe<Scalars['String']>;
  /** Filters events based on their transport comments. */
  transportComments?: InputMaybe<Scalars['String']>;
  /** Filters events based on their transportation details. */
  transportation?: InputMaybe<Scalars['String']>;
  /** Filters events based on the last update timestamp. */
  updatedAt?: InputMaybe<Scalars['Date']>;
  years?: InputMaybe<Scalars['String']>;
};

export type ManageEventOld = {
  __typename?: 'ManageEventOld';
  /** Type of activity or sport. */
  activity?: Maybe<Scalars['String']>;
  /** Author or writer of the event story. */
  author?: Maybe<Scalars['String']>;
  /** Fee associated with transportation or bus. */
  busFee?: Maybe<Scalars['Int']>;
  /** Scheduled time for the bus or transportation. */
  busTime?: Maybe<Scalars['String']>;
  /** Status indicating event's cancellation. */
  cancellationStatus?: Maybe<Scalars['String']>;
  /** Additional comments or notes. */
  comments?: Maybe<Scalars['String']>;
  /** Conference details or name. */
  conference?: Maybe<Scalars['String']>;
  /** Event ID related to the conference. */
  conferenceEventId?: Maybe<Scalars['Int']>;
  /** ID associated with the conference. */
  conferenceId?: Maybe<Scalars['Int']>;
  /** Confirmation status of the event. */
  confirmed?: Maybe<Scalars['String']>;
  /** Contract details or identifier. */
  contract?: Maybe<Scalars['String']>;
  /** Timestamp when the event was created. */
  createdAt?: Maybe<Scalars['Date']>;
  /** Timestamp when the event result was created. */
  createdResultAt?: Maybe<Scalars['String']>;
  /** User ID of the creator of the event result. */
  createdResultBy?: Maybe<Scalars['Int']>;
  /** Location for event departure. */
  departureLocation?: Maybe<Scalars['String']>;
  /** Link for directions or map. */
  directionLink?: Maybe<Scalars['String']>;
  /** Directions or map details for the event. */
  directions?: Maybe<Scalars['String']>;
  /** Indication if early dismissal is required. */
  earlyDismissalRequired?: Maybe<Scalars['String']>;
  /** Scheduled time for early dismissal. */
  earlyDismissalTime?: Maybe<Scalars['String']>;
  /** End time of the event. */
  endTime?: Maybe<Scalars['String']>;
  /** Estimated time of return post event. */
  estimatedReturnTime?: Maybe<Scalars['String']>;
  /** Indication if the event is complete. */
  eventComplete?: Maybe<Scalars['Int']>;
  /** Date of the event. */
  eventDate?: Maybe<Scalars['Date']>;
  /** Date and Time for start of event. */
  eventDateTime?: Maybe<Scalars['String']>;
  /** Calculated field from event date indicating day of the week. */
  eventDay?: Maybe<Scalars['String']>;
  /** Calculated field from event ID for event details. */
  eventDetailsPath?: Maybe<Scalars['String']>;
  /** The unique ID of the event. */
  eventId?: Maybe<Scalars['Int']>;
  /** Lead or main summary of the event. */
  eventLead?: Maybe<Scalars['String']>;
  /** Unique ID for the event result. */
  eventResultID?: Maybe<Scalars['Int']>;
  /** Detailed story or description of the event. */
  eventStory?: Maybe<Scalars['String']>;
  /** Title or main heading of the event story. */
  eventTitle?: Maybe<Scalars['String']>;
  /** Type or category of the event. */
  eventType?: Maybe<Scalars['String']>;
  /** Indication if event exists in MLS system. */
  existsInMls?: Maybe<Scalars['Int']>;
  /** Fee or cost associated with the event. */
  fee?: Maybe<Scalars['Int']>;
  /** Revenue generated from gate or entry fee. */
  gateRevenue?: Maybe<Scalars['Int']>;
  /** Gender specification for the event. */
  gender?: Maybe<Scalars['String']>;
  /** Value grouping multiple events. */
  groupVal?: Maybe<Scalars['String']>;
  /** General status of the event. */
  gs?: Maybe<Scalars['String']>;
  /** Main headline or caption for the event. */
  headline?: Maybe<Scalars['String']>;
  /** Indication if the event has major impact. */
  impactEvent?: Maybe<Scalars['String']>;
  /** Indication if event details are approved. */
  isApproved?: Maybe<Scalars['Boolean']>;
  /** Lead information or summary. */
  lead?: Maybe<Scalars['String']>;
  /** Level of the event. */
  level?: Maybe<Scalars['String']>;
  /** Location description or address. */
  location?: Maybe<Scalars['String']>;
  /** Points deducted for a loss. */
  lossPoints?: Maybe<Scalars['Int']>;
  /** Number of buses or transportation units. */
  numBuses?: Maybe<Scalars['Int']>;
  /** Name or description of the opponent. */
  opponent?: Maybe<Scalars['String']>;
  /** Code identifier for the opponent. */
  opponentCode?: Maybe<Scalars['String']>;
  /** Opponent details or description. */
  opponentForEvent?: Maybe<ManageOpponentOld>;
  /** Score achieved by the opponent team. */
  opponentScore?: Maybe<Scalars['String']>;
  /** Outcome or result of the event. */
  outcome?: Maybe<Scalars['String']>;
  /** URL or path to event picture. */
  picture?: Maybe<Scalars['String']>;
  /** Location or venue of the event. */
  place?: Maybe<Scalars['String']>;
  /** Details for event setup or preparation. */
  prepSetup?: Maybe<Scalars['String']>;
  /** Program associated with the event. */
  programForEvent?: Maybe<ProgramForEventOld>;
  /** Indication if event should be promoted. */
  promote?: Maybe<Scalars['String']>;
  /** Indication if score should be reported. */
  reportScore?: Maybe<Scalars['Int']>;
  /** Indication if story related to event should be reported. */
  reportStory?: Maybe<Scalars['Int']>;
  /** Results or outcomes from the event. */
  results?: Maybe<Scalars['String']>;
  /** Total revenue generated from the event. */
  revenue?: Maybe<Scalars['Int']>;
  /** Indication if event rolls over to next season. */
  rollover?: Maybe<Scalars['String']>;
  /** Season details or year. */
  season?: Maybe<Scalars['String']>;
  /** Associated team for the season. */
  seasonTeam?: Maybe<Scalars['Int']>;
  /** Indication if event should be showcased on front page. */
  showFrontPage?: Maybe<Scalars['Int']>;
  /** Start time used for sorting. */
  sortStartTime?: Maybe<Scalars['String']>;
  /** Start time of the event. */
  startTime?: Maybe<Scalars['String']>;
  /** Name of the associated team. */
  teamName?: Maybe<Scalars['String']>;
  /** Score achieved by the home team. */
  teamScore?: Maybe<Scalars['String']>;
  /** Title or heading of the event. */
  title?: Maybe<Scalars['String']>;
  /** Indication if the event is part of a tournament. */
  tournament?: Maybe<Scalars['String']>;
  /** Unique ID for transportation details. */
  transId?: Maybe<Scalars['Int']>;
  /** Comments related to transportation. */
  transportComments?: Maybe<Scalars['String']>;
  /** Transportation details for the event. */
  transportation?: Maybe<Scalars['String']>;
  /** Date when event result was last updated. */
  updateResultDate?: Maybe<Scalars['String']>;
  /** Timestamp of the last update to the event. */
  updatedAt?: Maybe<Scalars['Date']>;
  /** Timestamp of the last update to the event result. */
  updatedResultAt?: Maybe<Scalars['String']>;
  /** User ID of the last updater of the event result. */
  updatedResultBy?: Maybe<Scalars['Int']>;
  /** Web directory or path for the event. */
  webDir?: Maybe<Scalars['String']>;
  /** Points awarded for a win. */
  winPoints?: Maybe<Scalars['Int']>;
  /** Year(s) associated with the event. */
  years?: Maybe<Scalars['String']>;
};

export enum ManageFacilityDistinctOld {
  Directions = 'directions',
  PlaceName = 'placeName'
}

export type ManageFacilityFilterOld = {
  distinct?: InputMaybe<Array<InputMaybe<ManageFacilityDistinctOld>>>;
  orderBy?: InputMaybe<ManageFacilityOrderByOld>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ManageFacilityWhereOld>;
  whereNot?: InputMaybe<ManageFacilityWhereNotOld>;
};

export type ManageFacilityListOld = {
  __typename?: 'ManageFacilityListOld';
  count?: Maybe<Scalars['Int']>;
  list?: Maybe<Array<Maybe<ManageFacilityOld>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

/** The object which contains all the input data for the ManageFacility query */
export type ManageFacilityOld = {
  __typename?: 'ManageFacilityOld';
  directions?: Maybe<Scalars['String']>;
  indoor?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  locationId?: Maybe<Scalars['Int']>;
  mlSiteId?: Maybe<Scalars['Int']>;
  mlSpace?: Maybe<Scalars['String']>;
  mlSpaceId?: Maybe<Scalars['Int']>;
  picture?: Maybe<Scalars['String']>;
  placeName?: Maybe<Scalars['String']>;
  show?: Maybe<Scalars['String']>;
  web?: Maybe<Scalars['String']>;
};

export type ManageFacilityOrderByOld = {
  directions?: InputMaybe<SortOrderEnum>;
  indoor?: InputMaybe<SortOrderEnum>;
  isDeleted?: InputMaybe<SortOrderEnum>;
  locationId?: InputMaybe<SortOrderEnum>;
  mlSiteId?: InputMaybe<SortOrderEnum>;
  mlSpace?: InputMaybe<SortOrderEnum>;
  mlSpaceId?: InputMaybe<SortOrderEnum>;
  picture?: InputMaybe<SortOrderEnum>;
  placeName?: InputMaybe<SortOrderEnum>;
  show?: InputMaybe<SortOrderEnum>;
  web?: InputMaybe<SortOrderEnum>;
};

export type ManageFacilityWhereNotOld = {
  directions?: InputMaybe<Scalars['String']>;
  indoor?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['Int']>;
  mlSiteId?: InputMaybe<Scalars['Int']>;
  mlSpace?: InputMaybe<Scalars['String']>;
  mlSpaceId?: InputMaybe<Scalars['Int']>;
  picture?: InputMaybe<Scalars['String']>;
  show?: InputMaybe<Scalars['String']>;
  web?: InputMaybe<Scalars['String']>;
};

export type ManageFacilityWhereOld = {
  directions?: InputMaybe<Scalars['String']>;
  indoor?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['Int']>;
  mlSiteId?: InputMaybe<Scalars['Int']>;
  mlSpace?: InputMaybe<Scalars['String']>;
  mlSpaceId?: InputMaybe<Scalars['Int']>;
  picture?: InputMaybe<Scalars['String']>;
  placeName?: InputMaybe<Scalars['String']>;
  show?: InputMaybe<Scalars['String']>;
  web?: InputMaybe<Scalars['String']>;
};

export type ManageHeadlineFilterOld = {
  orderBy?: InputMaybe<ManageHeadlineOrderByOld>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ManageHeadlineWhereOld>;
};

export type ManageHeadlineListOld = {
  __typename?: 'ManageHeadlineListOld';
  count?: Maybe<Scalars['Int']>;
  list?: Maybe<Array<Maybe<ManageHeadlineOld>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

/** The object which contains all the input data for the ManageHeadline query */
export type ManageHeadlineOld = {
  __typename?: 'ManageHeadlineOld';
  eventResultId?: Maybe<Scalars['Int']>;
  eventStory?: Maybe<Scalars['String']>;
  eventTitle?: Maybe<Scalars['String']>;
  refId?: Maybe<Scalars['Int']>;
};

export type ManageHeadlineOrderByOld = {
  EventResultId?: InputMaybe<SortOrderEnum>;
  EventStory?: InputMaybe<SortOrderEnum>;
  EventTitle?: InputMaybe<SortOrderEnum>;
  RefId?: InputMaybe<SortOrderEnum>;
};

export type ManageHeadlineWhereOld = {
  EventResultId?: InputMaybe<Scalars['Int']>;
  EventStory?: InputMaybe<Scalars['String']>;
  EventTitle?: InputMaybe<Scalars['String']>;
  RefId?: InputMaybe<Scalars['Int']>;
};

/** A type representing details about an opponent in the context of school management. */
export type ManageOpponentOld = {
  __typename?: 'ManageOpponentOld';
  /** The name of the athletic director (AD) associated with the school/opponent. */
  adName?: Maybe<Scalars['String']>;
  /** The physical address of the school. */
  address?: Maybe<Scalars['String']>;
  /** The city where the school is located. */
  city?: Maybe<Scalars['String']>;
  /** The primary contact email address for the school. */
  email?: Maybe<Scalars['String']>;
  /** The fax number for the school, if available. */
  fax?: Maybe<Scalars['String']>;
  /** A flag indicating if the opponent's record has been marked as deleted. */
  isDeleted?: Maybe<Scalars['Boolean']>;
  /** The National Center for Education Statistics (NCES) identifier for the school. */
  ncesId?: Maybe<Scalars['String']>;
  /** A flag indicating if the entity is not an actual school. */
  nonSchool?: Maybe<Scalars['Boolean']>;
  /** The primary contact phone number for the school. */
  phone?: Maybe<Scalars['String']>;
  /** The unique identifier for the school. This is mandatory. */
  schoolCode?: Maybe<Scalars['String']>;
  /** The official name of the school. */
  schoolName?: Maybe<Scalars['String']>;
  /** The state or province where the school is located. */
  state?: Maybe<Scalars['String']>;
  /** The webFolder of the opponent */
  webFolder?: Maybe<Scalars['String']>;
  /** The postal code for the school's address. */
  zip?: Maybe<Scalars['String']>;
};

export type ManageOrganizationListOld = {
  __typename?: 'ManageOrganizationListOld';
  /** Total count of organizations that match the criteria. */
  count?: Maybe<Scalars['Int']>;
  /** The list of organizations. */
  list?: Maybe<Array<Maybe<ManageOrganizationOld>>>;
  /** Number of organizations skipped. */
  skip?: Maybe<Scalars['Int']>;
  /** Number of organizations fetched. */
  take?: Maybe<Scalars['Int']>;
};

/** The object which contains all the input data for the manage organization query */
export type ManageOrganizationOld = {
  __typename?: 'ManageOrganizationOld';
  /** The ad of the school */
  ad?: Maybe<Scalars['String']>;
  /** AD Program ID */
  adProgramID?: Maybe<Scalars['Int']>;
  /** Ad report */
  adReport?: Maybe<Scalars['Int']>;
  /** The ad school of the school */
  adSchool?: Maybe<Scalars['Int']>;
  /** The address of the school */
  address?: Maybe<Scalars['String']>;
  /** Announcements for the organization */
  announcementsForOrganizationOld?: Maybe<ManageAnnouncementListOld>;
  /** AOTM Spotlight */
  aotmSpotlight?: Maybe<Scalars['Int']>;
  /** Auto Approve Score Only */
  autoApproveScoreOnly?: Maybe<Scalars['Int']>;
  /** Camp Registration Confirmation Text */
  campRegConfirmationTxt?: Maybe<Scalars['String']>;
  /** The city of the school */
  city?: Maybe<Scalars['String']>;
  /** The list of coaches for this organization */
  coachForOrganizationOld?: Maybe<ManageCoachListOld>;
  /** The First Color of the school */
  color1?: Maybe<Scalars['String']>;
  /** The Second Color of the school */
  color2?: Maybe<Scalars['String']>;
  /** Conference Name */
  conferenceName?: Maybe<Scalars['String']>;
  /** Conference URL */
  conferenceURL?: Maybe<Scalars['String']>;
  /** Custom Label */
  customLabel?: Maybe<Scalars['String']>;
  /** Custom Label 2 */
  customLabel2?: Maybe<Scalars['String']>;
  /** Custom Link */
  customLink?: Maybe<Scalars['String']>;
  /** Custom Link 2 */
  customLink2?: Maybe<Scalars['String']>;
  /** The Database folder of the school */
  dbFolder?: Maybe<Scalars['String']>;
  /** Email Blast Enabled */
  emailBlastEnabled?: Maybe<Scalars['Int']>;
  /** Email Spotlight */
  emailSpotlight?: Maybe<Scalars['Int']>;
  /** Equipment Enabled */
  equipmentEnabled?: Maybe<Scalars['Boolean']>;
  /** Event Locations for the organization */
  eventLocationsForOrganizationOld?: Maybe<ManageResourceLinkListOld>;
  eventsForOrganization?: Maybe<ManageEventListOld>;
  /** Facebook Url */
  facebookUrl?: Maybe<Scalars['String']>;
  /** Facilities for the Organization */
  facilitiesForOrganizationOld?: Maybe<ManageFacilityListOld>;
  /** The fax number of the school */
  fax?: Maybe<Scalars['String']>;
  /** The featured of the school */
  featured?: Maybe<Scalars['Int']>;
  /** Feeders Towns for the school */
  feederTowns?: Maybe<Scalars['String']>;
  /** School FuelMyClubLink */
  fuelMyClubLink?: Maybe<Scalars['String']>;
  /** Gallery Spotlight */
  gallerySpotlight?: Maybe<Scalars['Int']>;
  /** Has Activities */
  hasActivities?: Maybe<Scalars['Boolean']>;
  /** Has Access OTM */
  hasAotm?: Maybe<Scalars['Boolean']>;
  /** Has Athletics */
  hasAthletics?: Maybe<Scalars['Boolean']>;
  /** Has Email Blast */
  hasEmailBlast?: Maybe<Scalars['String']>;
  /** Has Facebook Url */
  hasFacebookUrl?: Maybe<Scalars['Boolean']>;
  /** Has Instagram Url */
  hasInstagramUrl?: Maybe<Scalars['Boolean']>;
  /** Has Migrated From Access To Sql Server */
  hasMigratedFromAccessToSqlServer?: Maybe<Scalars['Boolean']>;
  /** Has Registration */
  hasRegistration?: Maybe<Scalars['Boolean']>;
  /** Has Twitter Url */
  hasTwitterUrl?: Maybe<Scalars['Boolean']>;
  /** Headlines for the Organization */
  headlinesForOrganizationOld?: Maybe<ManageHeadlineListOld>;
  /** The icon of the school */
  iCon?: Maybe<Scalars['String']>;
  /** Instagram Url */
  instagramUrl?: Maybe<Scalars['String']>;
  /** League Keeper school id */
  lKschoolId?: Maybe<Scalars['Int']>;
  /** The latitude of the school */
  lat?: Maybe<Scalars['Float']>;
  /** The id of the link to school database */
  linkSchoolId?: Maybe<Scalars['Int']>;
  /** The logo of the school */
  logo?: Maybe<Scalars['String']>;
  /** The longitude of the school */
  long?: Maybe<Scalars['Float']>;
  /** The mascot of the school */
  mascot?: Maybe<Scalars['String']>;
  /** Does the school have email updates */
  massUpdate?: Maybe<Scalars['Int']>;
  /** Master School Id */
  masterSchoolId?: Maybe<Scalars['Int']>;
  /** The message of the school */
  message?: Maybe<Scalars['String']>;
  /** Motto */
  motto?: Maybe<Scalars['String']>;
  /** NCES ID */
  ncesId?: Maybe<Scalars['String']>;
  /** Old School Id */
  oldSchoolId?: Maybe<Scalars['Int']>;
  /** Pages for the organization */
  pagesForOrganizationOld?: Maybe<ManagePagesListOld>;
  /** The url path for the school */
  path?: Maybe<Scalars['String']>;
  /** The phone number of the school */
  phone?: Maybe<Scalars['String']>;
  /** Photos for the Organization */
  photosForOrganizationOld?: Maybe<ManagePhotosListOld>;
  /** The list of players for this organization */
  playersForOrganizationOld?: Maybe<ManagePlayerListOld>;
  /** The principal of the school */
  principal?: Maybe<Scalars['String']>;
  programsForOrganizationOld?: Maybe<ManageProgramListOld>;
  /** PS School Id */
  psSchoolId?: Maybe<Scalars['String']>;
  /** PS Store Active */
  psStoreActive?: Maybe<Scalars['Int']>;
  /** The Registration Email of the school */
  registrationEmail?: Maybe<Scalars['String']>;
  /** The Registration Enabled of the school */
  registrationEnabled?: Maybe<Scalars['Int']>;
  /** The Registration Policy of the school */
  registrationPolicy?: Maybe<Scalars['String']>;
  /** The Registration Type of the school */
  registrationType?: Maybe<Scalars['Int']>;
  /** Registration Url */
  registrationUrl?: Maybe<Scalars['String']>;
  /** The id of the school */
  schoolId?: Maybe<Scalars['Int']>;
  /** The name of the school */
  schoolName?: Maybe<Scalars['String']>;
  /** School Website Url */
  schoolWebsiteUrl?: Maybe<Scalars['String']>;
  scoresForOrganization?: Maybe<ManageEventListOld>;
  /** The show of the school */
  show?: Maybe<Scalars['Int']>;
  /** Show Ad */
  showAd?: Maybe<Scalars['Boolean']>;
  /** Show School Name And Motto */
  showSchoolNameAndMotto?: Maybe<Scalars['Boolean']>;
  /** Do you want to show the teams pages first on the site */
  showTeamPagesFirst?: Maybe<Scalars['Int']>;
  /** A Sign up code for the school */
  signUpCode?: Maybe<Scalars['String']>;
  /** The site type of the school */
  siteType?: Maybe<Scalars['String']>;
  /** Sport Registration Confirmation Text */
  sportRegConfirmationTxt?: Maybe<Scalars['String']>;
  /** The id of the sql database */
  sqlBase?: Maybe<Scalars['Int']>;
  /** Staff Calendar Enabled */
  staffCalendarEnabled?: Maybe<Scalars['Int']>;
  /** The state of the school (abbreviation) */
  state?: Maybe<Scalars['String']>;
  /** The state organization of the school */
  stateOrg?: Maybe<Scalars['String']>;
  /** Time Zone */
  timeZone?: Maybe<Scalars['String']>;
  /** Twitter Url */
  twitterUrl?: Maybe<Scalars['String']>;
  /** Urgent Announcements for the organization */
  urgentAnnouncementsForOrganizationOld?: Maybe<ManageAnnouncementListOld>;
  /** The web folder of the school that you have chosen */
  webFolder?: Maybe<Scalars['String']>;
  /** Web Password */
  webPassword?: Maybe<Scalars['String']>;
  /** The website of the school */
  webSite?: Maybe<Scalars['String']>;
  /** The zip code of the school */
  zip?: Maybe<Scalars['String']>;
};


/** The object which contains all the input data for the manage organization query */
export type ManageOrganizationOldAnnouncementsForOrganizationOldArgs = {
  filter?: InputMaybe<ManageAnnouncementFilterOld>;
};


/** The object which contains all the input data for the manage organization query */
export type ManageOrganizationOldCoachForOrganizationOldArgs = {
  filter?: InputMaybe<ManageCoachListFilterOld>;
};


/** The object which contains all the input data for the manage organization query */
export type ManageOrganizationOldEventLocationsForOrganizationOldArgs = {
  filter?: InputMaybe<ManageResourceLinkFilterOld>;
};


/** The object which contains all the input data for the manage organization query */
export type ManageOrganizationOldEventsForOrganizationArgs = {
  filter?: InputMaybe<ManageEventListFilterOld>;
};


/** The object which contains all the input data for the manage organization query */
export type ManageOrganizationOldFacilitiesForOrganizationOldArgs = {
  filter?: InputMaybe<ManageFacilityFilterOld>;
};


/** The object which contains all the input data for the manage organization query */
export type ManageOrganizationOldHeadlinesForOrganizationOldArgs = {
  filter?: InputMaybe<ManageHeadlineFilterOld>;
};


/** The object which contains all the input data for the manage organization query */
export type ManageOrganizationOldPagesForOrganizationOldArgs = {
  filter?: InputMaybe<ManagePagesFilterOld>;
};


/** The object which contains all the input data for the manage organization query */
export type ManageOrganizationOldPhotosForOrganizationOldArgs = {
  filter?: InputMaybe<ManagePhotosFilterOld>;
};


/** The object which contains all the input data for the manage organization query */
export type ManageOrganizationOldPlayersForOrganizationOldArgs = {
  filter?: InputMaybe<ManagePlayerListFilterOld>;
};


/** The object which contains all the input data for the manage organization query */
export type ManageOrganizationOldProgramsForOrganizationOldArgs = {
  filter?: InputMaybe<ManageProgramListFilterOld>;
};


/** The object which contains all the input data for the manage organization query */
export type ManageOrganizationOldScoresForOrganizationArgs = {
  filter?: InputMaybe<ScoreForOrganizationFilterOld>;
};


/** The object which contains all the input data for the manage organization query */
export type ManageOrganizationOldUrgentAnnouncementsForOrganizationOldArgs = {
  filter?: InputMaybe<UrgentAnnouncementFilterOld>;
};

export enum ManageOrganizationOldDistinct {
  Address = 'address',
  City = 'city',
  Name = 'name',
  State = 'state'
}

export type ManageOrganizationOldFilter = {
  distinct?: InputMaybe<Array<InputMaybe<ManageOrganizationOldDistinct>>>;
  /** Defines how the organization list should be ordered. */
  orderBy?: InputMaybe<ManageOrganizationOldOrderBy>;
  /** Number of organizations to skip before starting to fetch the result set. */
  skip?: InputMaybe<Scalars['Int']>;
  /** Limits the number of organizations to fetch from the result set. */
  take?: InputMaybe<Scalars['Int']>;
  /** Conditions to filter the organization list. */
  where?: InputMaybe<ManageOrganizationOldWhere>;
};

/** The object which contains all the input data for the manage organization query without extends */
export type ManageOrganizationOldInterface = {
  /** The ad of the school */
  ad?: Maybe<Scalars['String']>;
  /** AD Program ID */
  adProgramID?: Maybe<Scalars['Int']>;
  /** Ad report */
  adReport?: Maybe<Scalars['Int']>;
  /** The ad school of the school */
  adSchool?: Maybe<Scalars['Int']>;
  /** The address of the school */
  address?: Maybe<Scalars['String']>;
  /** AOTM Spotlight */
  aotmSpotlight?: Maybe<Scalars['Int']>;
  /** Auto Approve Score Only */
  autoApproveScoreOnly?: Maybe<Scalars['Int']>;
  /** Camp Registration Confirmation Text */
  campRegConfirmationTxt?: Maybe<Scalars['String']>;
  /** The city of the school */
  city?: Maybe<Scalars['String']>;
  /** The First Color of the school */
  color1?: Maybe<Scalars['String']>;
  /** The Second Color of the school */
  color2?: Maybe<Scalars['String']>;
  /** Conference Name */
  conferenceName?: Maybe<Scalars['String']>;
  /** Conference URL */
  conferenceURL?: Maybe<Scalars['String']>;
  /** Custom Label */
  customLabel?: Maybe<Scalars['String']>;
  /** Custom Label 2 */
  customLabel2?: Maybe<Scalars['String']>;
  /** Custom Link */
  customLink?: Maybe<Scalars['String']>;
  /** Custom Link 2 */
  customLink2?: Maybe<Scalars['String']>;
  /** The Database folder of the school */
  dbFolder?: Maybe<Scalars['String']>;
  /** Email Blast Enabled */
  emailBlastEnabled?: Maybe<Scalars['Int']>;
  /** Email Spotlight */
  emailSpotlight?: Maybe<Scalars['Int']>;
  /** Equipment Enabled */
  equipmentEnabled?: Maybe<Scalars['Boolean']>;
  /** Facebook Url */
  facebookUrl?: Maybe<Scalars['String']>;
  /** The fax number of the school */
  fax?: Maybe<Scalars['String']>;
  /** The featured of the school */
  featured?: Maybe<Scalars['Int']>;
  /** Feeders Towns for the school */
  feederTowns?: Maybe<Scalars['String']>;
  /** School FuelMyClubLink */
  fuelMyClubLink?: Maybe<Scalars['String']>;
  /** Gallery Spotlight */
  gallerySpotlight?: Maybe<Scalars['Int']>;
  /** Has Activities */
  hasActivities?: Maybe<Scalars['Boolean']>;
  /** Has Access OTM */
  hasAotm?: Maybe<Scalars['Boolean']>;
  /** Has Athletics */
  hasAthletics?: Maybe<Scalars['Boolean']>;
  /** Has Email Blast */
  hasEmailBlast?: Maybe<Scalars['String']>;
  /** Has Facebook Url */
  hasFacebookUrl?: Maybe<Scalars['Boolean']>;
  /** Has Instagram Url */
  hasInstagramUrl?: Maybe<Scalars['Boolean']>;
  /** Has Migrated From Access To Sql Server */
  hasMigratedFromAccessToSqlServer?: Maybe<Scalars['Boolean']>;
  /** Has Registration */
  hasRegistration?: Maybe<Scalars['Boolean']>;
  /** Has Twitter Url */
  hasTwitterUrl?: Maybe<Scalars['Boolean']>;
  /** The icon of the school */
  iCon?: Maybe<Scalars['String']>;
  /** Instagram Url */
  instagramUrl?: Maybe<Scalars['String']>;
  /** League Keeper school id */
  lKschoolId?: Maybe<Scalars['Int']>;
  /** The latitude of the school */
  lat?: Maybe<Scalars['Float']>;
  /** The id of the link to school database */
  linkSchoolId?: Maybe<Scalars['Int']>;
  /** The logo of the school */
  logo?: Maybe<Scalars['String']>;
  /** The longitude of the school */
  long?: Maybe<Scalars['Float']>;
  /** The mascot of the school */
  mascot?: Maybe<Scalars['String']>;
  /** Does the school have email updates */
  massUpdate?: Maybe<Scalars['Int']>;
  /** Master School Id */
  masterSchoolId?: Maybe<Scalars['Int']>;
  /** The message of the school */
  message?: Maybe<Scalars['String']>;
  /** Motto */
  motto?: Maybe<Scalars['String']>;
  /** NCES ID */
  ncesId?: Maybe<Scalars['String']>;
  /** Old School Id */
  oldSchoolId?: Maybe<Scalars['Int']>;
  /** The url path for the school */
  path?: Maybe<Scalars['String']>;
  /** The phone number of the school */
  phone?: Maybe<Scalars['String']>;
  /** The principal of the school */
  principal?: Maybe<Scalars['String']>;
  /** PS School Id */
  psSchoolId?: Maybe<Scalars['String']>;
  /** PS Store Active */
  psStoreActive?: Maybe<Scalars['Int']>;
  /** The Registration Email of the school */
  registrationEmail?: Maybe<Scalars['String']>;
  /** The Registration Enabled of the school */
  registrationEnabled?: Maybe<Scalars['Int']>;
  /** The Registration Policy of the school */
  registrationPolicy?: Maybe<Scalars['String']>;
  /** The Registration Type of the school */
  registrationType?: Maybe<Scalars['Int']>;
  /** Registration Url */
  registrationUrl?: Maybe<Scalars['String']>;
  /** The id of the school */
  schoolId?: Maybe<Scalars['Int']>;
  /** The name of the school */
  schoolName?: Maybe<Scalars['String']>;
  /** School Website Url */
  schoolWebsiteUrl?: Maybe<Scalars['String']>;
  /** The show of the school */
  show?: Maybe<Scalars['Int']>;
  /** Show Ad */
  showAd?: Maybe<Scalars['Boolean']>;
  /** Show School Name And Motto */
  showSchoolNameAndMotto?: Maybe<Scalars['Boolean']>;
  /** Do you want to show the teams pages first on the site */
  showTeamPagesFirst?: Maybe<Scalars['Int']>;
  /** A Sign up code for the school */
  signUpCode?: Maybe<Scalars['String']>;
  /** The site type of the school */
  siteType?: Maybe<Scalars['String']>;
  /** Sport Registration Confirmation Text */
  sportRegConfirmationTxt?: Maybe<Scalars['String']>;
  /** The id of the sql database */
  sqlBase?: Maybe<Scalars['Int']>;
  /** Staff Calendar Enabled */
  staffCalendarEnabled?: Maybe<Scalars['Int']>;
  /** The state of the school (abbreviation) */
  state?: Maybe<Scalars['String']>;
  /** The state organization of the school */
  stateOrg?: Maybe<Scalars['String']>;
  /** Time Zone */
  timeZone?: Maybe<Scalars['String']>;
  /** Twitter Url */
  twitterUrl?: Maybe<Scalars['String']>;
  /** The web folder of the school that you have chosen */
  webFolder?: Maybe<Scalars['String']>;
  /** Web Password */
  webPassword?: Maybe<Scalars['String']>;
  /** The website of the school */
  webSite?: Maybe<Scalars['String']>;
  /** The zip code of the school */
  zip?: Maybe<Scalars['String']>;
};

export type ManageOrganizationOldOrderBy = {
  /** Orders organizations based on their name. */
  name?: InputMaybe<SortOrderEnum>;
  /** Orders organizations based on their state. */
  state?: InputMaybe<SortOrderEnum>;
};

export type ManageOrganizationOldWhere = {
  /** The name of the organization. */
  name?: InputMaybe<Scalars['String']>;
  /** Condition to determine visibility of the organization (e.g., might represent a specific view mode). */
  show?: InputMaybe<Scalars['Int']>;
  /** The state or province of the organization. */
  state?: InputMaybe<Scalars['String']>;
};

export type ManagePagesFilterOld = {
  orderBy?: InputMaybe<ManagePagesOrderByOld>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ManagePagesWhereOld>;
};

export type ManagePagesListOld = {
  __typename?: 'ManagePagesListOld';
  count?: Maybe<Scalars['Int']>;
  list?: Maybe<Array<Maybe<ManagePagesOld>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

/** The object which contains all the input data for the ManageResource Pages query */
export type ManagePagesOld = {
  __typename?: 'ManagePagesOld';
  createdAt?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  pageContent?: Maybe<Scalars['String']>;
  pageTitle?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<Scalars['Int']>;
};

export type ManagePagesOrderByOld = {
  name?: InputMaybe<SortOrderEnum>;
  pageTitle?: InputMaybe<SortOrderEnum>;
};

export type ManagePagesWhereOld = {
  name?: InputMaybe<PagesNameEnum>;
};

export enum ManagePhotosDistinctOld {
  Group = 'group',
  Sport = 'sport',
  Src = 'src'
}

export type ManagePhotosFilterOld = {
  distinct?: InputMaybe<Array<InputMaybe<ManagePhotosDistinctOld>>>;
  orderBy?: InputMaybe<ManagePhotosOrderByOld>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ManagePhotosWhereOld>;
  whereNot?: InputMaybe<ManagePhotosWhereOld>;
};

export type ManagePhotosListOld = {
  __typename?: 'ManagePhotosListOld';
  count?: Maybe<Scalars['Int']>;
  list?: Maybe<Array<Maybe<ManagePhotosOld>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

/** The object which contains all the input data for the ManageResource photos query */
export type ManagePhotosOld = {
  __typename?: 'ManagePhotosOld';
  batch?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<Scalars['Int']>;
  filename?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['Buffer']>;
  layout?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  needsApproval?: Maybe<Scalars['Int']>;
  projectId?: Maybe<Scalars['Int']>;
  recordStatus?: Maybe<Scalars['Int']>;
  rphoto?: Maybe<Scalars['Int']>;
  school?: Maybe<Scalars['Int']>;
  sport?: Maybe<Scalars['String']>;
  src?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<Scalars['Int']>;
};

export type ManagePhotosOrderByOld = {
  caption?: InputMaybe<SortOrderEnum>;
  filename?: InputMaybe<SortOrderEnum>;
  id?: InputMaybe<SortOrderEnum>;
  title?: InputMaybe<SortOrderEnum>;
};

export type ManagePhotosWhereOld = {
  group?: InputMaybe<Scalars['String']>;
  image1?: InputMaybe<Scalars['Buffer']>;
  needsApproval?: InputMaybe<Scalars['Int']>;
  rphoto?: InputMaybe<Scalars['Int']>;
  sport?: InputMaybe<Scalars['String']>;
};

export enum ManagePlayerDistinctOld {
  City = 'city',
  FName = 'fName',
  Gender = 'gender',
  GradYear = 'gradYear',
  Height = 'height',
  Jersey = 'jersey',
  LName = 'lName',
  Position = 'position',
  SchoolId = 'schoolId',
  SeasonId = 'seasonId',
  State = 'state',
  Status = 'status',
  Weight = 'weight',
  Zip = 'zip'
}

export type ManagePlayerListFilterOld = {
  distinct?: InputMaybe<Array<InputMaybe<ManagePlayerDistinctOld>>>;
  orderBy?: InputMaybe<ManagePlayerListOrderByOld>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ManagePlayerListWhereOld>;
};

export type ManagePlayerListOld = {
  __typename?: 'ManagePlayerListOld';
  count?: Maybe<Scalars['Int']>;
  list?: Maybe<Array<Maybe<ManagePlayerOld>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type ManagePlayerListOrderByOld = {
  city?: InputMaybe<SortOrderEnum>;
  dob?: InputMaybe<SortOrderEnum>;
  fName?: InputMaybe<SortOrderEnum>;
  gender?: InputMaybe<SortOrderEnum>;
  gradYear?: InputMaybe<SortOrderEnum>;
  height?: InputMaybe<SortOrderEnum>;
  jersey?: InputMaybe<SortOrderEnum>;
  lName?: InputMaybe<SortOrderEnum>;
  participantId?: InputMaybe<SortOrderEnum>;
  permission?: InputMaybe<SortOrderEnum>;
  position?: InputMaybe<SortOrderEnum>;
  rosterId?: InputMaybe<SortOrderEnum>;
  schoolId?: InputMaybe<SortOrderEnum>;
  seasonId?: InputMaybe<SortOrderEnum>;
  state?: InputMaybe<SortOrderEnum>;
  status?: InputMaybe<SortOrderEnum>;
  weight?: InputMaybe<SortOrderEnum>;
  zip?: InputMaybe<SortOrderEnum>;
};

export type ManagePlayerListWhereOld = {
  ch1?: InputMaybe<Scalars['Int']>;
  ch2?: InputMaybe<Scalars['Int']>;
  ch3?: InputMaybe<Scalars['Int']>;
  city?: InputMaybe<Scalars['String']>;
  custodyCode?: InputMaybe<Scalars['Int']>;
  dayPhone?: InputMaybe<Scalars['String']>;
  dob?: InputMaybe<Scalars['Date']>;
  fName?: InputMaybe<Scalars['String']>;
  feePaid?: InputMaybe<Scalars['Int']>;
  gender?: InputMaybe<Scalars['String']>;
  gradYear?: InputMaybe<Scalars['String']>;
  hatsize?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['String']>;
  homePhone?: InputMaybe<Scalars['String']>;
  hospitalPhone?: InputMaybe<Scalars['String']>;
  insuranceCompany?: InputMaybe<Scalars['String']>;
  insurancePolicyNum?: InputMaybe<Scalars['String']>;
  jersey?: InputMaybe<Scalars['String']>;
  lName?: InputMaybe<Scalars['String']>;
  livesWithCode?: InputMaybe<Scalars['Int']>;
  noPress?: InputMaybe<Scalars['Int']>;
  participantId?: InputMaybe<Scalars['Int']>;
  permission?: InputMaybe<Scalars['Int']>;
  physical?: InputMaybe<Scalars['Int']>;
  physicalDate?: InputMaybe<Scalars['Date']>;
  physician?: InputMaybe<Scalars['String']>;
  physicianTelephone?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['String']>;
  preferredHospital?: InputMaybe<Scalars['String']>;
  rosterId?: InputMaybe<Scalars['Int']>;
  schoolId?: InputMaybe<Scalars['Int']>;
  seasonId?: InputMaybe<Scalars['Int']>;
  shirtsize?: InputMaybe<Scalars['String']>;
  shortsize?: InputMaybe<Scalars['String']>;
  stAddress?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  studentId?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

/** The object which contains all the input data for each player */
export type ManagePlayerOld = {
  __typename?: 'ManagePlayerOld';
  ch1?: Maybe<Scalars['Int']>;
  ch2?: Maybe<Scalars['Int']>;
  ch3?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  custodyCode?: Maybe<Scalars['Int']>;
  dayPhone?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['Date']>;
  fName?: Maybe<Scalars['String']>;
  feePaid?: Maybe<Scalars['Int']>;
  gender?: Maybe<Scalars['String']>;
  gradYear?: Maybe<Scalars['String']>;
  hatsize?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  homePhone?: Maybe<Scalars['String']>;
  hospitalPhone?: Maybe<Scalars['String']>;
  insuranceCompany?: Maybe<Scalars['String']>;
  insurancePolicyNum?: Maybe<Scalars['String']>;
  jersey?: Maybe<Scalars['String']>;
  lName?: Maybe<Scalars['String']>;
  livesWithCode?: Maybe<Scalars['Int']>;
  noPress?: Maybe<Scalars['Int']>;
  participantId?: Maybe<Scalars['Int']>;
  permission?: Maybe<Scalars['Int']>;
  physical?: Maybe<Scalars['Int']>;
  physicalDate?: Maybe<Scalars['Date']>;
  physician?: Maybe<Scalars['String']>;
  physicianTelephone?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  preferredHospital?: Maybe<Scalars['String']>;
  rosterId?: Maybe<Scalars['Int']>;
  schoolId?: Maybe<Scalars['Int']>;
  seasonId?: Maybe<Scalars['Int']>;
  shirtsize?: Maybe<Scalars['String']>;
  shortsize?: Maybe<Scalars['String']>;
  sortJersey?: Maybe<Scalars['Int']>;
  stAddress?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  studentId?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export enum ManageProgramListDistinctOld {
  Gender = 'gender',
  GroupVal = 'groupVal',
  HomeField = 'homeField',
  Id = 'id',
  Level1 = 'level1',
  SportCode = 'sportCode',
  SportDescription = 'sportDescription',
  SportName = 'sportName'
}

export type ManageProgramListFilterOld = {
  distinct?: InputMaybe<Array<InputMaybe<ManageProgramListDistinctOld>>>;
  orderBy?: InputMaybe<ManageProgramListOrderByOld>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ManageProgramListWhereOld>;
  whereNot?: InputMaybe<ManageProgramListWhereNotOld>;
};

export type ManageProgramListOld = {
  __typename?: 'ManageProgramListOld';
  count?: Maybe<Scalars['Int']>;
  list?: Maybe<Array<Maybe<ManageProgramOld>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type ManageProgramListOrderByOld = {
  gender?: InputMaybe<SortOrderEnum>;
  level1?: InputMaybe<SortOrderEnum>;
  sportName?: InputMaybe<SortOrderEnum>;
};

export type ManageProgramListWhereNotOld = {
  gender?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  groupVal?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  level1?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sportName?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ManageProgramListWhereOld = {
  gender?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  groupVal?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  level1?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sportName?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ManageProgramOld = {
  __typename?: 'ManageProgramOld';
  /** Announcements for the program */
  announcementsForProgramsOld?: Maybe<ManageAnnouncementListOld>;
  gender?: Maybe<Scalars['String']>;
  genderSegment?: Maybe<Scalars['String']>;
  groupVal?: Maybe<Scalars['String']>;
  homeField?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  level1?: Maybe<Scalars['String']>;
  levelName?: Maybe<Scalars['String']>;
  levelSegment?: Maybe<Scalars['String']>;
  /** Photos for the program */
  photosForProgramsOld?: Maybe<ManagePhotosListOld>;
  /** Links for the program */
  resourceLinksForProgramsOld?: Maybe<ManageResourceLinkListOld>;
  seasonsForProgramOld?: Maybe<ManageSeasonListOld>;
  sportCode?: Maybe<Scalars['String']>;
  sportDescription?: Maybe<Scalars['String']>;
  sportDetailsForProgramOld?: Maybe<ManageSportDetailsListOld>;
  sportName?: Maybe<Scalars['String']>;
  sportSegment?: Maybe<Scalars['String']>;
  /** Team Stats for the program */
  teamStatsForProgramsOld?: Maybe<ManageTeamStatsListOld>;
  yearsForProgramOld?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type ManageProgramOldAnnouncementsForProgramsOldArgs = {
  filter?: InputMaybe<ManageAnnouncementFilterOld>;
};


export type ManageProgramOldPhotosForProgramsOldArgs = {
  filter?: InputMaybe<ManagePhotosFilterOld>;
};


export type ManageProgramOldResourceLinksForProgramsOldArgs = {
  filter?: InputMaybe<ManageResourceLinkFilterOld>;
};


export type ManageProgramOldSeasonsForProgramOldArgs = {
  filter?: InputMaybe<ManageSeasonListFilterOld>;
};


export type ManageProgramOldSportDetailsForProgramOldArgs = {
  filter?: InputMaybe<ManageSportDetailsListFilterOld>;
};


export type ManageProgramOldTeamStatsForProgramsOldArgs = {
  filter?: InputMaybe<ManageTeamStatsFilterOld>;
};

export type ManageResourceLinkFilterOld = {
  orderBy?: InputMaybe<ManageResourceLinkOrderByOld>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ManageResourceLinkWhereOld>;
  whereNot?: InputMaybe<ManageResourceLinkWhereOld>;
};

export type ManageResourceLinkListOld = {
  __typename?: 'ManageResourceLinkListOld';
  count?: Maybe<Scalars['Int']>;
  list?: Maybe<Array<Maybe<ManageResourceLinkOld>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

/** The object which contains all the input data for the ManageResource event location query */
export type ManageResourceLinkOld = {
  __typename?: 'ManageResourceLinkOld';
  /** The fileName for the event location */
  fileName?: Maybe<Scalars['String']>;
  /** The filename link for the event location */
  fileNameLink?: Maybe<Scalars['String']>;
  /** The sport gender and level for the event location */
  gVal?: Maybe<Scalars['String']>;
  /** The id for the event location */
  id?: Maybe<Scalars['Int']>;
  /** The isApproved for the event location */
  isApproved?: Maybe<Scalars['Int']>;
  /** The type for the event location */
  lType?: Maybe<Scalars['String']>;
  /** The link for the event location */
  link?: Maybe<Scalars['String']>;
  /** The name for the event location */
  name?: Maybe<Scalars['String']>;
  /** The onFornt for the event location */
  onFront?: Maybe<Scalars['Int']>;
  /** The recordStatus for the event location */
  recordStatus?: Maybe<Scalars['Int']>;
  /** The school id for the event location */
  schoolNum?: Maybe<Scalars['Int']>;
  /** The sortOrder for the event location */
  sortOrder?: Maybe<Scalars['Int']>;
};

export type ManageResourceLinkOrderByOld = {
  fileName?: InputMaybe<SortOrderEnum>;
  name?: InputMaybe<SortOrderEnum>;
};

export type ManageResourceLinkWhereOld = {
  fileName?: InputMaybe<Scalars['String']>;
  isApproved?: InputMaybe<Scalars['Int']>;
  lType?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  onFront?: InputMaybe<Scalars['Int']>;
};

export enum ManageRosterDistinctOld {
  City = 'city',
  FName = 'fName',
  Gender = 'gender',
  GradYear = 'gradYear',
  Height = 'height',
  Jersey = 'jersey',
  LName = 'lName',
  Position = 'position',
  SchoolId = 'schoolId',
  SeasonId = 'seasonId',
  State = 'state',
  Status = 'status',
  Weight = 'weight',
  Zip = 'zip'
}

export type ManageRosterListFilterOld = {
  distinct?: InputMaybe<Array<InputMaybe<ManageRosterDistinctOld>>>;
  orderBy?: InputMaybe<ManageRosterListOrderByOld>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ManageRosterListWhereOld>;
};

export type ManageRosterListOld = {
  __typename?: 'ManageRosterListOld';
  count?: Maybe<Scalars['Int']>;
  list?: Maybe<Array<Maybe<ManageRosterOld>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type ManageRosterListOrderByOld = {
  city?: InputMaybe<SortOrderEnum>;
  dob?: InputMaybe<SortOrderEnum>;
  fName?: InputMaybe<SortOrderEnum>;
  gender?: InputMaybe<SortOrderEnum>;
  gradYear?: InputMaybe<SortOrderEnum>;
  height?: InputMaybe<SortOrderEnum>;
  jersey?: InputMaybe<SortOrderEnum>;
  lName?: InputMaybe<SortOrderEnum>;
  participantId?: InputMaybe<SortOrderEnum>;
  permission?: InputMaybe<SortOrderEnum>;
  position?: InputMaybe<SortOrderEnum>;
  rosterId?: InputMaybe<SortOrderEnum>;
  schoolId?: InputMaybe<SortOrderEnum>;
  seasonId?: InputMaybe<SortOrderEnum>;
  state?: InputMaybe<SortOrderEnum>;
  status?: InputMaybe<SortOrderEnum>;
  weight?: InputMaybe<SortOrderEnum>;
  zip?: InputMaybe<SortOrderEnum>;
};

export type ManageRosterListWhereOld = {
  ch1?: InputMaybe<Scalars['Int']>;
  ch2?: InputMaybe<Scalars['Int']>;
  ch3?: InputMaybe<Scalars['Int']>;
  city?: InputMaybe<Scalars['String']>;
  custodyCode?: InputMaybe<Scalars['Int']>;
  dayPhone?: InputMaybe<Scalars['String']>;
  dob?: InputMaybe<Scalars['Date']>;
  fName?: InputMaybe<Scalars['String']>;
  feePaid?: InputMaybe<Scalars['Int']>;
  gender?: InputMaybe<Scalars['String']>;
  gradYear?: InputMaybe<Scalars['String']>;
  hatsize?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['String']>;
  homePhone?: InputMaybe<Scalars['String']>;
  hospitalPhone?: InputMaybe<Scalars['String']>;
  insuranceCompany?: InputMaybe<Scalars['String']>;
  insurancePolicyNum?: InputMaybe<Scalars['String']>;
  jersey?: InputMaybe<Scalars['String']>;
  lName?: InputMaybe<Scalars['String']>;
  livesWithCode?: InputMaybe<Scalars['Int']>;
  noPress?: InputMaybe<Scalars['Int']>;
  participantId?: InputMaybe<Scalars['Int']>;
  permission?: InputMaybe<Scalars['Int']>;
  physical?: InputMaybe<Scalars['Int']>;
  physicalDate?: InputMaybe<Scalars['Date']>;
  physician?: InputMaybe<Scalars['String']>;
  physicianTelephone?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['String']>;
  preferredHospital?: InputMaybe<Scalars['String']>;
  rosterId?: InputMaybe<Scalars['Int']>;
  schoolId?: InputMaybe<Scalars['Int']>;
  seasonId?: InputMaybe<Scalars['Int']>;
  shirtsize?: InputMaybe<Scalars['String']>;
  shortsize?: InputMaybe<Scalars['String']>;
  stAddress?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  studentId?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

/** The object which contains all the input data for each player */
export type ManageRosterOld = {
  __typename?: 'ManageRosterOld';
  ch1?: Maybe<Scalars['Int']>;
  ch2?: Maybe<Scalars['Int']>;
  ch3?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  custodyCode?: Maybe<Scalars['Int']>;
  dayPhone?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['Date']>;
  fName?: Maybe<Scalars['String']>;
  feePaid?: Maybe<Scalars['Int']>;
  gender?: Maybe<Scalars['String']>;
  gradYear?: Maybe<Scalars['String']>;
  hatsize?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  homePhone?: Maybe<Scalars['String']>;
  hospitalPhone?: Maybe<Scalars['String']>;
  insuranceCompany?: Maybe<Scalars['String']>;
  insurancePolicyNum?: Maybe<Scalars['String']>;
  jersey?: Maybe<Scalars['String']>;
  lName?: Maybe<Scalars['String']>;
  livesWithCode?: Maybe<Scalars['Int']>;
  noPress?: Maybe<Scalars['Int']>;
  participantId?: Maybe<Scalars['Int']>;
  permission?: Maybe<Scalars['Int']>;
  physical?: Maybe<Scalars['Int']>;
  physicalDate?: Maybe<Scalars['Date']>;
  physician?: Maybe<Scalars['String']>;
  physicianTelephone?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  preferredHospital?: Maybe<Scalars['String']>;
  rosterId?: Maybe<Scalars['Int']>;
  schoolId?: Maybe<Scalars['Int']>;
  seasonId?: Maybe<Scalars['Int']>;
  shirtsize?: Maybe<Scalars['String']>;
  shortsize?: Maybe<Scalars['String']>;
  sortJersey?: Maybe<Scalars['Int']>;
  stAddress?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  studentId?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export enum ManageSeasonListDistinctOld {
  Budget = 'budget',
  DefaultTimeForEvent = 'defaultTimeForEvent',
  DefaultTimeForPractice = 'defaultTimeForPractice',
  HomeField = 'homeField',
  IsDeleted = 'isDeleted',
  Preview = 'preview',
  Season = 'season',
  SeasonId = 'seasonId',
  SportCode = 'sportCode',
  WebPassword = 'webPassword',
  Year = 'year'
}

export type ManageSeasonListFilterOld = {
  distinct?: InputMaybe<Array<InputMaybe<ManageSeasonListDistinctOld>>>;
  orderBy?: InputMaybe<ManageSeasonListOrderByOld>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ManageSeasonListWhereOld>;
};

export type ManageSeasonListOld = {
  __typename?: 'ManageSeasonListOld';
  count?: Maybe<Scalars['Int']>;
  list?: Maybe<Array<Maybe<ManageSeasonOld>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type ManageSeasonListOrderByOld = {
  sport_code?: InputMaybe<SortOrderEnum>;
  year?: InputMaybe<SortOrderEnum>;
};

export type ManageSeasonListWhereOld = {
  sport_code?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['String']>;
};

export type ManageSeasonOld = {
  __typename?: 'ManageSeasonOld';
  budget?: Maybe<Scalars['Float']>;
  coachForProgramSeasonOld?: Maybe<ManageCoachListOld>;
  defaultTimeForEvent?: Maybe<Scalars['String']>;
  defaultTimeForPractice?: Maybe<Scalars['String']>;
  /** The events for the season. */
  eventsForSeason?: Maybe<ManageEventListOld>;
  homeField?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  playersForProgramSeasonOld?: Maybe<ManagePlayerListOld>;
  preview?: Maybe<Scalars['String']>;
  rosterForProgramSeasonOld?: Maybe<ManageRosterListOld>;
  season?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['Int']>;
  sportCode?: Maybe<Scalars['String']>;
  webPassword?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};


export type ManageSeasonOldCoachForProgramSeasonOldArgs = {
  filter?: InputMaybe<ManageCoachListFilterOld>;
};


export type ManageSeasonOldEventsForSeasonArgs = {
  filter?: InputMaybe<ManageEventListFilterOld>;
};


export type ManageSeasonOldPlayersForProgramSeasonOldArgs = {
  filter?: InputMaybe<ManagePlayerListFilterOld>;
};

export enum ManageSportDetailsListDistinctOld {
  DefaultToSchedulePage = 'defaultToSchedulePage',
  Gender = 'gender',
  GroupVal = 'groupVal',
  HomeField = 'homeField',
  IntroHtml = 'introHtml',
  IntroImageId = 'introImageID',
  Level1 = 'level1',
  NeedsApproval = 'needsApproval',
  RecordStatus = 'recordStatus',
  SchoolId = 'schoolId',
  SiteSchoolSport = 'siteSchoolSport',
  SportCode = 'sportCode',
  SportDescription = 'sportDescription',
  SportName = 'sportName',
  SportStatus = 'sportStatus'
}

export type ManageSportDetailsListFilterOld = {
  distinct?: InputMaybe<Array<InputMaybe<ManageSportDetailsListDistinctOld>>>;
  orderBy?: InputMaybe<ManageSportDetailsListOrderByOld>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ManageSportDetailsListWhereOld>;
};

export type ManageSportDetailsListOld = {
  __typename?: 'ManageSportDetailsListOld';
  count?: Maybe<Scalars['Int']>;
  list?: Maybe<Array<Maybe<ManageSportDetailsOld>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type ManageSportDetailsListOrderByOld = {
  createdAt?: InputMaybe<SortOrderEnum>;
  createdBy?: InputMaybe<SortOrderEnum>;
  defaultToSchedulePage?: InputMaybe<SortOrderEnum>;
  gender?: InputMaybe<SortOrderEnum>;
  groupVal?: InputMaybe<SortOrderEnum>;
  homeField?: InputMaybe<SortOrderEnum>;
  introHtml?: InputMaybe<SortOrderEnum>;
  introImageID?: InputMaybe<SortOrderEnum>;
  level1?: InputMaybe<SortOrderEnum>;
  needsApproval?: InputMaybe<SortOrderEnum>;
  recordStatus?: InputMaybe<SortOrderEnum>;
  schoolId?: InputMaybe<SortOrderEnum>;
  siteSchoolSport?: InputMaybe<SortOrderEnum>;
  sportCode?: InputMaybe<SortOrderEnum>;
  sportDescription?: InputMaybe<SortOrderEnum>;
  sportName?: InputMaybe<SortOrderEnum>;
  sportStatus?: InputMaybe<SortOrderEnum>;
  updatedAt?: InputMaybe<SortOrderEnum>;
  updatedBy?: InputMaybe<SortOrderEnum>;
};

export type ManageSportDetailsListWhereOld = {
  createdAt?: InputMaybe<Scalars['Date']>;
  createdBy?: InputMaybe<Scalars['Int']>;
  defaultToSchedulePage?: InputMaybe<Scalars['Int']>;
  introHtml?: InputMaybe<Scalars['String']>;
  introImageID?: InputMaybe<Scalars['Int']>;
  needsApproval?: InputMaybe<Scalars['Int']>;
  recordStatus?: InputMaybe<Scalars['Int']>;
  siteSchoolSport?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
  updatedBy?: InputMaybe<Scalars['Int']>;
};

export type ManageSportDetailsOld = {
  __typename?: 'ManageSportDetailsOld';
  createdAt?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<Scalars['Int']>;
  defaultToSchedulePage?: Maybe<Scalars['Int']>;
  gender?: Maybe<Scalars['String']>;
  groupVal?: Maybe<Scalars['String']>;
  homeField?: Maybe<Scalars['String']>;
  introHtml?: Maybe<Scalars['String']>;
  introImageID?: Maybe<Scalars['Int']>;
  level1?: Maybe<Scalars['String']>;
  needsApproval?: Maybe<Scalars['Int']>;
  recordStatus?: Maybe<Scalars['Int']>;
  schoolId?: Maybe<Scalars['Int']>;
  siteSchoolSport?: Maybe<Scalars['Int']>;
  sportCode?: Maybe<Scalars['String']>;
  sportDescription?: Maybe<Scalars['String']>;
  sportName?: Maybe<Scalars['String']>;
  sportStatus?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<Scalars['Int']>;
};

export type ManageTeamStatsFilterOld = {
  orderBy?: InputMaybe<ManageTeamStatsOrderByOld>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ManageTeamStatsWhereOld>;
};

export type ManageTeamStatsListOld = {
  __typename?: 'ManageTeamStatsListOld';
  count?: Maybe<Scalars['Int']>;
  list?: Maybe<Array<Maybe<ManageTeamStatsOld>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

/** The object which contains all the input data for the ManageTeamStats query */
export type ManageTeamStatsOld = {
  __typename?: 'ManageTeamStatsOld';
  createdAt?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  isApproved?: Maybe<Scalars['String']>;
  pageHtml?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['Int']>;
  sport?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<Scalars['Int']>;
};

export type ManageTeamStatsOrderByOld = {
  pageHtml?: InputMaybe<SortOrderEnum>;
  sport?: InputMaybe<SortOrderEnum>;
};

export type ManageTeamStatsWhereOld = {
  pageHtml?: InputMaybe<Scalars['String']>;
  sport?: InputMaybe<Scalars['String']>;
};

export type ManageUser = {
  __typename?: 'ManageUser';
  contracts?: Maybe<Scalars['String']>;
  events?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['Int']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  is_super_user?: Maybe<Scalars['String']>;
  maintenance?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  seasons?: Maybe<Scalars['String']>;
  teams?: Maybe<Scalars['String']>;
  user_email?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  user_level?: Maybe<Scalars['String']>;
};

export type ManageUserCreateInput = {
  contracts: Scalars['String'];
  events: Scalars['String'];
  groups?: InputMaybe<Array<Scalars['String']>>;
  maintenance: Scalars['String'];
  password: Scalars['String'];
  seasons: Scalars['String'];
  teams: Scalars['String'];
  user_email: Scalars['String'];
  user_id: Scalars['String'];
  user_level: Scalars['String'];
};

export type ManageUserDeleteInput = {
  id: Scalars['Int'];
};

export type ManageUserInput = {
  id: Scalars['Int'];
};

export type ManageUserUpdateInput = {
  contracts?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  id: Scalars['Int'];
  maintenance?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  seasons?: InputMaybe<Scalars['String']>;
  teams?: InputMaybe<Scalars['String']>;
  user_email?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
  user_level?: InputMaybe<Scalars['String']>;
};

export type ManageUsersList = {
  __typename?: 'ManageUsersList';
  items?: Maybe<Array<Maybe<ManageUser>>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  totalFilteredItems?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
  totalRows?: Maybe<Scalars['Int']>;
};

export type ManageUsersListOptions = {
  keyword?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export enum MatchStyle {
  Exact = 'EXACT',
  Fuzzy = 'FUZZY',
  Predictive = 'PREDICTIVE'
}

export type MessageContact = {
  attributes?: InputMaybe<Scalars['JSON']>;
  to: Scalars['String'];
};

export type MessageContactResponse = {
  __typename?: 'MessageContactResponse';
  attributes?: Maybe<Scalars['JSON']>;
  id?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
};

export type MessageExecutionResponse = {
  __typename?: 'MessageExecutionResponse';
  createdAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export enum MessageTransportType {
  Email = 'EMAIL',
  Sms = 'SMS'
}

export enum MessageType {
  Promotional = 'PROMOTIONAL',
  Transactional = 'TRANSACTIONAL'
}

export type MessagesChannelsFilterInput = {
  orgId: Scalars['String'];
};

export type MessagesMyChannelsResult = MyChannels | NotAuthenticated;

export type MessagesUpsertUsersResult = MessagesUsers | NotAuthorized;

export type MessagesUser = {
  __typename?: 'MessagesUser';
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type MessagesUsers = {
  __typename?: 'MessagesUsers';
  users?: Maybe<Array<Maybe<MessagesUser>>>;
};

export type MissingArguments = Error & {
  __typename?: 'MissingArguments';
  message: Scalars['String'];
};

export enum ModType {
  Affiliate = 'AFFILIATE',
  Business = 'BUSINESS',
  Club = 'CLUB',
  District = 'DISTRICT',
  Individual = 'INDIVIDUAL',
  Other = 'OTHER',
  School = 'SCHOOL',
  Team = 'TEAM'
}

export type ModifyDeleteCount = {
  __typename?: 'ModifyDeleteCount';
  count?: Maybe<Scalars['Int']>;
};

export type ModifyEventInput = {
  activity?: InputMaybe<Scalars['String']>;
  author?: InputMaybe<Scalars['String']>;
  bus_fee?: InputMaybe<Scalars['Float']>;
  bus_time?: InputMaybe<Scalars['String']>;
  cancellation_status?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  conference?: InputMaybe<Scalars['String']>;
  conference_event_id?: InputMaybe<Scalars['Int']>;
  conference_id?: InputMaybe<Scalars['Int']>;
  confirmed?: InputMaybe<Scalars['String']>;
  contract?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['Date']>;
  departure_location?: InputMaybe<Scalars['String']>;
  directions?: InputMaybe<Scalars['String']>;
  early_dismissal_required?: InputMaybe<Scalars['String']>;
  early_dismissal_time?: InputMaybe<Scalars['String']>;
  end_time?: InputMaybe<Scalars['String']>;
  estimated_return_time?: InputMaybe<Scalars['String']>;
  event_date?: InputMaybe<Scalars['Date']>;
  event_id: Scalars['Int'];
  exists_in_mls?: InputMaybe<Scalars['Int']>;
  fee?: InputMaybe<Scalars['Float']>;
  g_s?: InputMaybe<Scalars['String']>;
  gate_revenue?: InputMaybe<Scalars['Float']>;
  headline?: InputMaybe<Scalars['String']>;
  impact_event?: InputMaybe<Scalars['String']>;
  lead?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  loss_points?: InputMaybe<Scalars['Int']>;
  num_buses?: InputMaybe<Scalars['Int']>;
  opponent?: InputMaybe<Scalars['String']>;
  opponent_code?: InputMaybe<Scalars['String']>;
  opponent_score?: InputMaybe<Scalars['String']>;
  picture?: InputMaybe<Scalars['String']>;
  place?: InputMaybe<Scalars['String']>;
  prep_setup?: InputMaybe<Scalars['String']>;
  promote?: InputMaybe<Scalars['String']>;
  results?: InputMaybe<Scalars['String']>;
  revenue?: InputMaybe<Scalars['Float']>;
  rollover?: InputMaybe<Scalars['String']>;
  season_team?: InputMaybe<Scalars['Int']>;
  start_time?: InputMaybe<Scalars['String']>;
  team_score?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  tournament?: InputMaybe<Scalars['String']>;
  trans_id?: InputMaybe<Scalars['Int']>;
  transport_comments?: InputMaybe<Scalars['String']>;
  transportation?: InputMaybe<Scalars['String']>;
  update_at?: InputMaybe<Scalars['Date']>;
  web_dir?: InputMaybe<Scalars['String']>;
  win_points?: InputMaybe<Scalars['Int']>;
  years?: InputMaybe<Scalars['String']>;
};

export type ModifyEventParticipantsInput = {
  contract_received?: InputMaybe<Scalars['String']>;
  event_id?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  notes?: InputMaybe<Scalars['String']>;
  paid?: InputMaybe<Scalars['String']>;
  school_code?: InputMaybe<Scalars['String']>;
};

export type ModifyEventPreparationInput = {
  comments?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  prep?: InputMaybe<Scalars['String']>;
  qty?: InputMaybe<Scalars['String']>;
};

export type ModifyEventPreparationsInput = {
  comments?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  prep?: InputMaybe<Scalars['String']>;
  qty?: InputMaybe<Scalars['String']>;
};

export type ModifyEventTransportDetailsInput = {
  driver_name?: InputMaybe<Scalars['String']>;
  driver_phone?: InputMaybe<Scalars['String']>;
  event_id?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  vehicle_id?: InputMaybe<Scalars['String']>;
  vehicle_type?: InputMaybe<Scalars['String']>;
};

export type ModifyFacilityInput = {
  Address1?: InputMaybe<Scalars['String']>;
  Address2?: InputMaybe<Scalars['String']>;
  City?: InputMaybe<Scalars['String']>;
  State?: InputMaybe<Scalars['String']>;
  ZipCode?: InputMaybe<Scalars['String']>;
  directions?: InputMaybe<Scalars['String']>;
  indoor?: InputMaybe<Scalars['String']>;
  ml_site_id?: InputMaybe<Scalars['String']>;
  ml_space?: InputMaybe<Scalars['String']>;
  ml_space_id?: InputMaybe<Scalars['String']>;
  picture?: InputMaybe<Scalars['String']>;
  place_name: Scalars['String'];
  place_name_old: Scalars['String'];
  show?: InputMaybe<Scalars['String']>;
  web?: InputMaybe<Scalars['String']>;
};

export type ModifyLevelInput = {
  ID: Scalars['Int'];
  Level?: InputMaybe<Scalars['String']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
};

export type ModifyManyEventPreparationsByEventInput = {
  comments?: InputMaybe<Scalars['String']>;
  event: Scalars['Int'];
  prep?: InputMaybe<Scalars['String']>;
  qty?: InputMaybe<Scalars['String']>;
};

export type ModifyOfficialDutyInput = {
  duty?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  is_deleted?: InputMaybe<Scalars['Boolean']>;
};

export type ModifyOfficialInput = {
  duty?: InputMaybe<Scalars['String']>;
  event_id?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  offic_id?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  paid?: InputMaybe<Scalars['String']>;
  pay_code?: InputMaybe<Scalars['String']>;
  received?: InputMaybe<Scalars['String']>;
  salary?: InputMaybe<Scalars['Float']>;
  ssn?: InputMaybe<Scalars['String']>;
  voucher_number?: InputMaybe<Scalars['String']>;
  worker_name?: InputMaybe<Scalars['String']>;
};

export type ModifyOfficialPoolByIdInput = {
  Address?: InputMaybe<Scalars['String']>;
  City?: InputMaybe<Scalars['String']>;
  First?: InputMaybe<Scalars['String']>;
  Home_Phone?: InputMaybe<Scalars['String']>;
  ID: Scalars['String'];
  ID_old: Scalars['String'];
  Last?: InputMaybe<Scalars['String']>;
  SSN?: InputMaybe<Scalars['String']>;
  State?: InputMaybe<Scalars['String']>;
  Work_Phone?: InputMaybe<Scalars['String']>;
  Zip?: InputMaybe<Scalars['String']>;
  cell_phone?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  vendor_number?: InputMaybe<Scalars['String']>;
};

export type ModifyOpponentInput = {
  Address?: InputMaybe<Scalars['String']>;
  Phone?: InputMaybe<Scalars['String']>;
  SchoolCode: Scalars['String'];
  SchoolName?: InputMaybe<Scalars['String']>;
  State?: InputMaybe<Scalars['String']>;
  Zip?: InputMaybe<Scalars['String']>;
  ad_name?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  fax?: InputMaybe<Scalars['String']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  nces_id?: InputMaybe<Scalars['String']>;
  non_school?: InputMaybe<Scalars['Boolean']>;
};

export type ModifyPreparationInput = {
  duty?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  qty?: InputMaybe<Scalars['String']>;
};

export type ModifySchoolInfoInput = {
  Schoolname?: InputMaybe<Scalars['String']>;
  ad?: InputMaybe<Scalars['String']>;
  ad_contract_signee?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['String']>;
  ccemail?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  conf_text_type?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  email_reminder?: InputMaybe<Scalars['String']>;
  email_reminder_officials?: InputMaybe<Scalars['String']>;
  email_reminder_workers?: InputMaybe<Scalars['String']>;
  enable_cc_email_as_origin?: InputMaybe<Scalars['String']>;
  enable_ml_updates?: InputMaybe<Scalars['String']>;
  fax?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  mascot?: InputMaybe<Scalars['String']>;
  message_board_read_at?: InputMaybe<Scalars['Date']>;
  ml_key?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  principal?: InputMaybe<Scalars['String']>;
  school_timezone?: InputMaybe<Scalars['String']>;
  secondary_ad_email?: InputMaybe<Scalars['String']>;
  secondary_ad_name?: InputMaybe<Scalars['String']>;
  signed_contract_notification?: InputMaybe<Scalars['String']>;
  ssn_on_file?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  state_org?: InputMaybe<Scalars['String']>;
  state_org_abbreviation?: InputMaybe<Scalars['String']>;
  use_security?: InputMaybe<Scalars['String']>;
  web_password?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type ModifyVehicleInput = {
  id: Scalars['Int'];
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['String']>;
  vehicle_type?: InputMaybe<Scalars['String']>;
};

export type ModifyWorkerInput = {
  duty?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  event_id?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  organization?: InputMaybe<Scalars['String']>;
  paid?: InputMaybe<Scalars['String']>;
  pay_code?: InputMaybe<Scalars['String']>;
  pay_rate?: InputMaybe<Scalars['Float']>;
  salary?: InputMaybe<Scalars['Float']>;
  ssn?: InputMaybe<Scalars['String']>;
  woker_name?: InputMaybe<Scalars['String']>;
  worker_end_time?: InputMaybe<Scalars['String']>;
  worker_start_time?: InputMaybe<Scalars['String']>;
  worker_type?: InputMaybe<Scalars['String']>;
};

export type ModifyWorkerPoolInput = {
  Address?: InputMaybe<Scalars['String']>;
  City?: InputMaybe<Scalars['String']>;
  First?: InputMaybe<Scalars['String']>;
  Home_Phone?: InputMaybe<Scalars['String']>;
  ID: Scalars['Int'];
  Last?: InputMaybe<Scalars['String']>;
  SSN?: InputMaybe<Scalars['String']>;
  State?: InputMaybe<Scalars['String']>;
  Work_Phone?: InputMaybe<Scalars['String']>;
  Zip?: InputMaybe<Scalars['String']>;
  cell_phone?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  pay_rate?: InputMaybe<Scalars['Float']>;
  worker_type?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  activateWalletCard?: Maybe<Scalars['String']>;
  activateWalletVirtualCard?: Maybe<Scalars['Boolean']>;
  activateWalletVirtualCardConfirm?: Maybe<Scalars['Boolean']>;
  addParticipantGuardianDonationInviteEmailsV2: Array<ParticipantGuardianReturn>;
  /** Add revision request. New submission will be created. Email will be sent to submitter. */
  addRevisionFundraiserApprovalSubmission: RevisionFundraiserApprovalSubmissionResult;
  /** Approve submission using approver's token. Email will be sent to submitter. */
  approveFundraiserApprovalSubmission: ApproveFundraiserApprovalSubmissionResult;
  campaignPersonListBulkCreate?: Maybe<CampaignPersonListEntries>;
  /** @deprecated Will be using campaignPersonListBulkCreate instead. */
  campaignPersonListCreate?: Maybe<CampaignPersonList>;
  campaignPersonListEntryDelete?: Maybe<CampaignPersonListEntryDelete>;
  /** Create saved search filters for campaigns for current user. */
  campaignSearchFilterCreate?: Maybe<CampaignSearchFilter>;
  /** Remove saved search filters for campaigns for current user. */
  campaignSearchFilterDelete?: Maybe<CampaignSearchFilter>;
  campaignSmsInviteAdd?: Maybe<CampaignSmsDataResponse>;
  campaignSmsInviteDelete: CampaignSmsInviteDeleteResponse;
  /** Create and update content */
  commsRegister: CommsRegisterResponse;
  /** Send message */
  commsSend: CommsSendResponse;
  /** Send Test Message */
  commsTest: CommsTestResponse;
  /** Edit document Status associated to a Fundraiser Approval Submission Form */
  completeFundraiserApprovalSubmissionDocumentUpload: CompleteFundraiserApprovalSubmissionDocumentUploadResult;
  completeWalletRegister?: Maybe<Scalars['Boolean']>;
  confirmPassword?: Maybe<Scalars['String']>;
  confirmWalletOrganizationInfo?: Maybe<WalletConfirmOrganizationInfoResult>;
  /** Deletes a custom template based off ID */
  contactTemplateDelete?: Maybe<CustomContactTemplates>;
  /** Saves custom contact-template for current user */
  contactTemplateSave?: Maybe<CustomContactTemplates>;
  /** @deprecated This will be replaced with latestCreateCard after data has been migrated from Spend and Raise */
  createCard: CoreCardFields;
  /** @deprecated(reason: "Use createChildFundraiserParticipantV2 instead") */
  createChildFundraiserParticipant: JoinedFundraiserId;
  createChildFundraiserParticipantV2: JoinedFundraiserId;
  createFundraiserApprovalSubmission: FundraiserApprovalSubmissionResult;
  createFundraiserUser?: Maybe<CreatedFundraiserUser>;
  createOtkParticipantEntry?: Maybe<CreatedOtkEntry>;
  createPrefund?: Maybe<Prefund>;
  createPrefundTopup?: Maybe<Scalars['Boolean']>;
  createProduct?: Maybe<ProductResult>;
  createStoreOrder?: Maybe<OrderResult>;
  /** Create the log record with type DEBUG */
  debug?: Maybe<Scalars['Boolean']>;
  /** Delete documents from fundraiser approval submission. If document is not linked to previous submission (history) it will be removed from S3 as well. */
  deleteFundraiserApprovalSubmissionDocument: Scalars['String'];
  /** Create new donate intent. The status with created always INITIATED */
  donorDonateIntentCreate?: Maybe<DonateIntent>;
  /** Update the status of donate intent to be PROCESSING. This is called when the donation was successfully sent to Stripe, but we are waiting for an charge.succeeded event from Stripe to finalize the payment. */
  donorDonateIntentMarkProcessing?: Maybe<DonateIntent>;
  /** Update donate intent as user update the payment form. The status with created always become PENDING. */
  donorDonateIntentUpdate?: Maybe<DonateIntent>;
  donorsAdhocGiftShopIntentAdd: GiftShopPurchaseIntentId;
  /** create an offline donation */
  donorsDonationCreateOffline?: Maybe<DonorOfflineDonation>;
  donorsDonationDelete?: Maybe<DonorDonation>;
  donorsDonationUpdate?: Maybe<DonorDonation>;
  donorsFundraiserPayableRefund?: Maybe<DonorPayableRefundResponse>;
  donorsGiftShopIntentAdd: GiftShopPurchaseIntentId;
  donorsGiftShopIntentAddMany: GiftShopPurchaseIntentId;
  donorsGiftShopIntentMarkProcessing: GiftShopPurchaseIntentData;
  donorsGiftShopIntentRemove: GiftShopPurchaseIntentId;
  donorsGiftShopIntentUpdate: GiftShopPurchaseIntentId;
  donorsGiftShopIntentUpdateIntent: GiftShopPurchaseIntentData;
  donorsGiftShopPurchaseUpdate?: Maybe<DonorFundraiserPurchase>;
  donorsReceiptsResend: ResendReceiptsResult;
  driveAddHubspotProspectPin?: Maybe<Scalars['String']>;
  driveAddWinbackPin?: Maybe<Scalars['String']>;
  /** Finalize the (closed) fundraiser after campaign settled. */
  driveCampaignFinalize?: Maybe<IMutationResult>;
  /** Settle the fundraiser when campaigns start to be closed campaigns. */
  driveCampaignSettle?: Maybe<IMutationResult>;
  /** Update the fundraiser. Right now only fundraiser notes can be updated. */
  driveCampaignUpdate?: Maybe<IMutationResult>;
  /** @deprecated use driveDisableOrgActivity */
  driveDisableActivity?: Maybe<Scalars['String']>;
  driveDisableOrgActivity?: Maybe<Scalars['String']>;
  /** @deprecated use driveEnableOrgActivity */
  driveEnableActivity?: Maybe<Scalars['String']>;
  driveEnableOrgActivity?: Maybe<Scalars['String']>;
  /** Posts a message in the activity feed */
  driveEventActivityCreate?: Maybe<DriveEventActivityFeed>;
  /** Removeds an activity feed message */
  driveEventActivityRemove?: Maybe<Scalars['String']>;
  /** Updates the message and feature toggle in a activity message */
  driveEventActivityUpdate?: Maybe<DriveEventActivityFeed>;
  /** Takes in 2 arguments to post the agendas */
  driveEventAgendaCreate?: Maybe<DriveEventAgendaItem>;
  /** Adds an attendee to event. */
  driveEventAttendeeAdd?: Maybe<DriveEventAttendee>;
  /** Updates attendance information of attendee. */
  driveEventAttendeeUpdate?: Maybe<DriveEventAttendee>;
  /** Removes an attendee from event. */
  driveEventAttendeesRemove?: Maybe<Scalars['Int']>;
  /** Create Event (Drive Event-Tracker) */
  driveEventCreate?: Maybe<DriveEvent>;
  /** Delete Event (Drive Event-Tracker) */
  driveEventRemove?: Maybe<Scalars['String']>;
  /** Update Event (Drive Event-Tracker) */
  driveEventUpdate?: Maybe<DriveEvent>;
  /** Set tracking by specific organization current user. */
  driveOrgUserTrackingSet?: Maybe<DriveOrgUserTrackingReturn>;
  /** Cancel the scheduled preload sms */
  drivePreloadSmsScheduleCancel?: Maybe<Scalars['String']>;
  /** Send out preload sms to all participants */
  drivePreloadSmsSend?: Maybe<Scalars['String']>;
  drivePreloadSmsSendScheduleUpdate?: Maybe<Scalars['String']>;
  driveRemoveHubspotProspectPin?: Maybe<Scalars['String']>;
  driveRemoveWinbackPin?: Maybe<Scalars['String']>;
  /** Resizes the uploaded attachment in S3 in Drive. */
  driveS3ImageResize?: Maybe<Scalars['String']>;
  driveShareProjectedRaisedResults?: Maybe<IMutationResult>;
  /** Generate S3 generate pre-signed url for Drive to upload. This is used for uploading big attachments to the support ticket */
  driveUploadUrlCreate: Scalars['String'];
  editFundraiserApprovalSubmission: FundraiserApprovalSubmissionResult;
  /** Create the log record with type ERROR */
  error?: Maybe<Scalars['Boolean']>;
  eventCreate?: Maybe<Event>;
  eventDelete?: Maybe<Event>;
  eventModify?: Maybe<Event>;
  eventParticipantsDeleteMany?: Maybe<DeleteCount>;
  eventParticipantsUpsert?: Maybe<UpsertEventParticipantsCount>;
  eventPreparationCreate?: Maybe<DailyCalendarPreparation>;
  eventPreparationDelete?: Maybe<DailyCalendarPreparation>;
  eventPreparationModify?: Maybe<DailyCalendarPreparation>;
  eventPreparationsCreateMany?: Maybe<Array<Maybe<EventPreparations>>>;
  eventPreparationsDeleteMany?: Maybe<ModifyDeleteCount>;
  eventPreparationsModifyMany?: Maybe<ModifyDeleteCount>;
  eventPreparationsUpsert?: Maybe<UpsertEventPreparationsCount>;
  eventTransportDetailsDeleteMany?: Maybe<EventTransportDetailsDeleteManyCount>;
  eventTransportDetailsUpsertMany?: Maybe<UpsertEventTransportDetailsCount>;
  facilitiesUpsert?: Maybe<Array<Maybe<FacilityLocation>>>;
  facilityCreate?: Maybe<Facility>;
  facilityDelete?: Maybe<Facility>;
  facilityModify?: Maybe<Facility>;
  /** Associate a User with a FinancialAccount. This will also create a User Node only if they exist in User Directory but not Orgs. */
  finAcctUserAssociationCreate: MutationResponse;
  /** Add a deleted_at property to a User's FinancialAccount association. This will NOT delete the relationship. */
  finAcctUserAssociationDelete: MutationResponse;
  /** Update a User's FinancialAccount association properties. This mutation will re-activate a user that has been deleted by default. **Note** There is a known bug preventing status Booleans from updating from True to False. */
  finAcctUserAssociationUpdate: MutationResponse;
  /** Create a processor specific FinancialAccount Node that is associated with an Org. This requires a valid Org id and a valid KYB id */
  financialAcctCreate: MutationResponse;
  /** Change a FinancialAccount status to "TERMINATED". This will NOT delete the node. */
  financialAcctDelete: MutationResponse;
  /** Update a FinancialAccount properties that is already associated with an Org. */
  financialAcctUpdate: MutationResponse;
  fundraiserStoreUrlUpdate?: Maybe<UpdatedFundraiserStoreUrl>;
  /** Send out email to group-leader. Support CC emails */
  groupLeaderEmailSend?: Maybe<IMutationResult>;
  groupLeaderFundraiserDonationLevelsUpdate?: Maybe<Array<GroupLeaderDonationLevels>>;
  groupLeaderFundraiserMediaGalleryUpdate?: Maybe<Array<Maybe<GroupLeaderMediaGallery>>>;
  /** edit a fundraiser associated with the group leader */
  groupLeaderFundraiserUpdate?: Maybe<GroupLeaderFundraiser>;
  helpDocumentDelete?: Maybe<Scalars['String']>;
  helpDocumentUpload?: Maybe<Scalars['String']>;
  /** Update hubspot call engagement for fundraiser */
  hubspotCallEngagementToDealAdd?: Maybe<HubspotCallEngagement>;
  /** Update hubspot note engagement for fundraiser */
  hubspotNoteEngagementToDealAdd?: Maybe<HubspotCallEngagement>;
  /** Create the log record with type INFO */
  info?: Maybe<Scalars['Boolean']>;
  insightsAddPreApprovedContacts: InsAddPreApprovedContactsResult;
  insightsDeletePreApprovedContact: InsDeletePreApprovedContactResult;
  insightsEditPreApprovedContact: InsEditPreApprovedContactResult;
  insightsEditPreApprovedContactInvite: InsEditPreApprovedInviteResult;
  insightsResendInvitePreApprovedContact: InsResendPreApprovedContactResult;
  insightsSetNotificationPreferences: InsSetNotificationPreferencesResult;
  insightsSetUserPreferences: InsSetUserPreferencesResult;
  inviteAccept?: Maybe<UserInviteResponse>;
  inviteCreate: Scalars['String'];
  inviteDelete?: Maybe<Scalars['String']>;
  issuePendingCards?: Maybe<Array<Scalars['ID']>>;
  joinFundraiserUser: JoinedFundraiserId;
  joinFundraiserUserById: JoinedFundraiserId;
  latestVaultCardCreate: Scalars['ID'];
  levelCreate?: Maybe<Level>;
  levelDelete?: Maybe<Level>;
  levelModify?: Maybe<Level>;
  /** Create the log record. The most universal and agnostic request. */
  log?: Maybe<Scalars['Boolean']>;
  login?: Maybe<AuthChallenge>;
  logout?: Maybe<Scalars['Boolean']>;
  manageUserCreate?: Maybe<ManageUser>;
  manageUserDelete?: Maybe<ManageUser>;
  manageUserUpdate?: Maybe<ManageUser>;
  messagesChannelAddModerators?: Maybe<ChatChannelAddModeratorsResult>;
  messagesChannelAddUsers?: Maybe<ChatChannelAddUsersResult>;
  messagesChannelCreate?: Maybe<ChatChannelCreateResult>;
  messagesChannelUpdate?: Maybe<ChatChannelUpdateResult>;
  messagesChannelUpdateMembers?: Maybe<ChatChannelUpdateMembersResult>;
  messagesToken?: Maybe<ChatTokenResult>;
  messagesUpsertUsers?: Maybe<MessagesUpsertUsersResult>;
  netSuiteWebhook?: Maybe<NetSuiteWebhookResponse>;
  noticeCreate?: Maybe<Notice>;
  noticeDelete?: Maybe<Array<Maybe<Notice>>>;
  noticeModify?: Maybe<Notice>;
  notifyIncorrectWalletInfo?: Maybe<Scalars['Boolean']>;
  officialCreate?: Maybe<Official>;
  officialDelete?: Maybe<Official>;
  officialDutiesDelete?: Maybe<Array<Maybe<OfficialDuty>>>;
  officialDutiesUpsert?: Maybe<Array<Maybe<OfficialDuty>>>;
  officialDutyCreate?: Maybe<OfficialDuty>;
  officialDutyModify?: Maybe<OfficialDuty>;
  officialModify?: Maybe<Official>;
  officialPoolCreate?: Maybe<OfficialPool>;
  officialPoolDelete?: Maybe<Array<Maybe<OfficialPool>>>;
  officialPoolModify?: Maybe<OfficialPool>;
  officialsUpsert?: Maybe<Array<Maybe<OfficialPool>>>;
  opponentCreate?: Maybe<Opponent>;
  opponentDelete?: Maybe<Opponent>;
  opponentModify?: Maybe<Opponent>;
  opponentsUpsert?: Maybe<Array<Maybe<Opponent>>>;
  /** Create a new Affiliate. Affiliates have a parent Org, and this mutation will connect the two. */
  orgAffiliateCreate: MutationResponse;
  /** Create a new Club. Clubs do not have a parent Org. */
  orgClubCreate: MutationResponse;
  /** Create a new District. Districts do not have a parent Org. */
  orgDistrictCreate: MutationResponse;
  /** Update an Org Node's label in parallel with changes being made in MOD. This mutation is intended to preserve User Associations and Financial Account Associations. */
  orgLabelUpdate: MutationResponse;
  /** Create a new Program named after a given activity and connect it to a given Org. */
  orgProgramCreate: MutationResponse;
  /** Create a new School. Schools usually have a parent District, and this mutation will optionally connect the two. */
  orgSchoolCreate: MutationResponse;
  /** Create a new Team for a given activity and connect it to a Program of the same activity under a given Org. */
  orgTeamCreate: MutationResponse;
  participantDonationInviteEdit?: Maybe<DonorEmailData>;
  participantDonationInviteEmailAdd?: Maybe<DonorEmailData>;
  participantDonationInviteRemove?: Maybe<Array<Maybe<DonationInviteRemoveData>>>;
  participantDonationInviteSMSAdd?: Maybe<DonationInviteSmsData>;
  /** This will update the emails of a Participant's Guardian Email field */
  participantGuardianEmailUpdate: ParticipantGuardianEmailUpdate;
  participantIncentiveSelectionDelete?: Maybe<DeletedIncentiveRecord>;
  participantRewardsBulkCreate?: Maybe<Array<Maybe<BulkRewards>>>;
  participantRewardsCreate?: Maybe<CreatedParticipantRewards>;
  participantSignup: Array<TransportType>;
  /** Send out emails to particpants */
  participantsEmailSend?: Maybe<IMutationResult>;
  /** Sends out texts to particpants */
  participantsTextSend?: Maybe<IMutationResult>;
  paymentsApiCustomerGetOrCreate: PaymentsApiCustomerResponse;
  paymentsApiDetachPaymentMethod?: Maybe<PaymentsApiDetachPaymentMethodResponse>;
  paymentsApiPayment?: Maybe<PaymentsApiPaymentResponse>;
  paymentsApiRefund?: Maybe<PaymentsApiCreateRefundResponse>;
  /** Reschedule time (timezone-sensitive) to send out preload email to all participants */
  preloadEmailRescheduleSend?: Maybe<PreloadEmailSchedule>;
  preloadEmailScheduleCancel?: Maybe<Scalars['Boolean']>;
  /** Schedule time (timezone-sensitive) to send out preload email to all participants */
  preloadEmailScheduleSend?: Maybe<PreloadEmailSchedule>;
  /** Send out preload email to all participants */
  preloadEmailSend?: Maybe<Scalars['String']>;
  preparationCreate?: Maybe<Preparation>;
  preparationDelete?: Maybe<Preparation>;
  preparationModify?: Maybe<Preparation>;
  /** Create multiple fundraiser users and return total number of fundraiser users created */
  raiseAdminFundraiserUsersCreateMany: Scalars['Int'];
  registerWalletUser?: Maybe<WalletUserRegisterResult>;
  roleCreate?: Maybe<Role>;
  roleDelete?: Maybe<Role>;
  rolePermissionsUpdate?: Maybe<Role>;
  roleUpdate?: Maybe<Role>;
  /** Request one or more S3 pre-signed urls to upload files for approval submissions. */
  s3PresignedInsightsApprovalsUrl: Array<S3PresignedInsightsApprovalsUrlResult>;
  samlCallback?: Maybe<Auth>;
  schoolInfoCreate?: Maybe<SchoolInfo>;
  schoolInfoDelete?: Maybe<SchoolInfo>;
  schoolInfoModify?: Maybe<SchoolInfo>;
  seasonCreate?: Maybe<Season>;
  seasonDelete?: Maybe<Array<Maybe<Season>>>;
  seasonModify?: Maybe<Season>;
  seasonPostponeModify?: Maybe<PostponeUnpostponeResponse>;
  seasonScheduleTeamsCreate?: Maybe<Array<Maybe<Season>>>;
  seasonUnpostponeModify?: Maybe<PostponeUnpostponeResponse>;
  /** Add, update or remove Insights configurations for current user Allowed configurations: - VAULT_WELCOME_DISMISSED (YES | default=NO) - VAULT_ORG_APPROVED_DISMISS_BANNER (value should be org_id list) Note: value=null to unset */
  setUserInsightsConfigurations: UserInsightsConfigResult;
  /** Update settlement note for a fundraiser */
  settlementIssuesRecord?: Maybe<Scalars['String']>;
  /** Update settlement status for a fundraiser */
  settlementStatusUpdate?: Maybe<IMutationResult>;
  spendArchiveSeasonMembers?: Maybe<SpendArchiveSeasonMembersResponse>;
  spendBudgetCreate?: Maybe<SpendBudgetId>;
  spendBudgetDelete?: Maybe<SpendBudgetId>;
  spendBudgetUpdate?: Maybe<SpendBudgetId>;
  spendCategoryCreate?: Maybe<SpendCategoryId>;
  spendCategoryUpdate?: Maybe<SpendCategoryId>;
  spendCategoryUpsertBulk?: Maybe<Array<Maybe<SpendCategoryId>>>;
  spendCreditMemoCreate?: Maybe<SpendCreditMemoCreateResponse>;
  spendCreditMemoUpdate?: Maybe<SpendCreditMemoUpdateResponse>;
  spendGroupArchive?: Maybe<SpendGroupId>;
  spendGroupBankAccessTokenCreate?: Maybe<SpendBankAccessCreateResponse>;
  spendGroupBankAccessTokenDelete?: Maybe<SpendBankAccessDeleteResponse>;
  spendGroupCheckCancel?: Maybe<SpendCancelCheckId>;
  spendGroupCheckDepositPatch?: Maybe<SpendCheckDepositTagsResponse>;
  spendGroupCheckSend?: Maybe<SpendCheckSendResponse>;
  spendGroupCreate?: Maybe<SpendGroupId>;
  spendGroupDebitCardCreate?: Maybe<SpendDebitCardId>;
  spendGroupDuplicate?: Maybe<SpendGroupId>;
  spendGroupExternalTransfer?: Maybe<SpendExternalTransferResponse>;
  spendGroupPayeeCreate?: Maybe<SpendPayeeCreateResponse>;
  spendGroupPayeeUpdate?: Maybe<SpendPayeeCreateResponse>;
  spendGroupRosterCreate?: Maybe<SpendGroupRosterId>;
  spendGroupRosterUpdate?: Maybe<SpendRosterUpdate>;
  spendGroupRostersCreate?: Maybe<Array<Maybe<SpendGroupRosterId>>>;
  spendGroupUpdate?: Maybe<SpendGroupId>;
  spendGuardianSignup?: Maybe<SpendUserId>;
  spendInvoiceArchive?: Maybe<SpendInvoiceId>;
  spendInvoiceChangeRequest?: Maybe<SpendInvoiceId>;
  spendInvoiceCreate?: Maybe<SpendInvoiceId>;
  spendInvoicePaymentDeauthorize?: Maybe<SpendDeauthorizeResponse>;
  spendInvoiceRefund?: Maybe<SpendInvoiceRefundResponse>;
  spendInvoiceReminderCreate?: Maybe<SpendNotificationId>;
  spendInvoiceUpdate?: Maybe<SpendInvoiceId>;
  spendInvoiceUpdatePaymentMethod?: Maybe<SpendInvoiceIDs>;
  spendNotificationCreate?: Maybe<SpendNotificationStatus>;
  spendOrganizationAccountTransfer: SpendAccountId;
  spendOrganizationAdminUpdate?: Maybe<SpendOrgAdminUpdateResponse>;
  spendOrganizationBankAccessTokenCreate?: Maybe<SpendBankAccessCreateResponse>;
  spendOrganizationBankAccessTokenDelete?: Maybe<SpendBankAccessDeleteResponse>;
  spendOrganizationCheckCancel?: Maybe<SpendCancelCheckId>;
  spendOrganizationCheckDepositPatch?: Maybe<SpendCheckDepositTagsResponse>;
  spendOrganizationCheckSend?: Maybe<SpendCheckSendResponse>;
  spendOrganizationCreate?: Maybe<SpendOrganizationId>;
  spendOrganizationDebitCardCreate?: Maybe<SpendDebitCardId>;
  spendOrganizationExternalTransfer?: Maybe<SpendExternalTransferResponse>;
  spendOrganizationPayeeCreate?: Maybe<SpendPayeeCreateResponse>;
  spendOrganizationPayeeUpdate?: Maybe<SpendPayeeCreateResponse>;
  spendOrganizationSignup?: Maybe<SpendSignupFormResponse>;
  spendOrganizationUpdate?: Maybe<SpendOrganization>;
  spendPaymentMethodCreate?: Maybe<SpendPaymentMethodResponse>;
  spendPaymentScheduleCreate?: Maybe<SpendPaymentScheduleId>;
  spendPaymentScheduleRevert?: Maybe<SpendPaymentScheduleId>;
  spendPaymentScheduleRevertMany?: Maybe<Array<Maybe<SpendPaymentScheduleId>>>;
  spendPaymentScheduleUpdate?: Maybe<SpendPaymentScheduleId>;
  spendPaymentScheduleUpdateBySeason?: Maybe<Array<Maybe<SpendPaymentScheduleId>>>;
  spendRemoveParticipants?: Maybe<SpendRemoveSeasonMemberResponse>;
  spendRoleChange?: Maybe<SpendRoleId>;
  spendSeasonCreate?: Maybe<SpendSeasonId>;
  spendSeasonUpdate?: Maybe<SpendSeasonId>;
  spendSessionCreate?: Maybe<SpendSession>;
  spendSessionDelete?: Maybe<SpendSessionId>;
  spendSessionRefresh?: Maybe<SpendSessionId>;
  spendSettingsUpdate?: Maybe<SpendOrganizationId>;
  spendSignupAgreementCreate?: Maybe<SpendAgreementId>;
  spendTransactionAttachmentCreate?: Maybe<SpendTransactionAttachmentId>;
  spendTransactionAttachmentDelete?: Maybe<SpendTransactionAttachmentId>;
  spendTransactionInvoiceUnreconcile?: Maybe<SpendReconcileTransactionId>;
  spendTransactionNoteCreate?: Maybe<SpendTransactionNoteId>;
  spendTransactionNoteUpdate?: Maybe<SpendTransactionNoteId>;
  spendTransactionReconcile?: Maybe<SpendReconcileTransactionId>;
  spendTransactionsCreate?: Maybe<SpendTransactionsIdList>;
  spendUserAchPayment?: Maybe<SpendAchPaymentResponse>;
  spendUserAchPaymentCancel?: Maybe<SpendAchPaymentId>;
  spendUserBankAccessTokenCreate?: Maybe<SpendBankAccessCreateResponse>;
  spendUserBankAccessTokenDelete?: Maybe<SpendBankAccessDeleteResponse>;
  spendUserInviteArchive?: Maybe<SpendInviteId>;
  spendUserInviteCreate?: Maybe<SpendInviteId>;
  spendUserInviteDelete?: Maybe<SpendInviteId>;
  spendUserInviteResend?: Maybe<SpendInviteId>;
  spendUserInviteStatusUpdate?: Maybe<SpendInviteId>;
  spendUserInviteUpdate?: Maybe<SpendInviteId>;
  spendUserLeaveGroup?: Maybe<SpendUpdateResponse>;
  spendUserPaymentsCardDetach?: Maybe<SpendPaymentMethodDetachResponse>;
  spendUserRoleArchive?: Maybe<SpendUserId>;
  spendUserRoleSet?: Maybe<SpendUserRoleId>;
  spendUserRoleUpdate?: Maybe<SpendUserRoleId>;
  spendUserSignUp?: Maybe<SpendSignUpResponse>;
  spendUserSignupUpdate?: Maybe<SpendUserRoleId>;
  storeBuildRequest?: Maybe<StoreBuildRequestUnionType>;
  storeEditInfo?: Maybe<StoreEditInfoUnionType>;
  storeManagerPointsUpdate?: Maybe<StoreManagerUpdatePointsUnionType>;
  storePaymentIntentCreate?: Maybe<StorePaymantIntentUnionType>;
  storePointsWithdrawalRequest?: Maybe<StorePointsWithdrawalRequestUnionType>;
  storeProfitPercentageUpdate?: Maybe<StoreProfitPercentageUpdated>;
  storeTicketCreate?: Maybe<StoreTicketsUnionType>;
  storeTransactionSave?: Maybe<StoreTransactionUnionType>;
  storeTransferToCustomer?: Maybe<StoreTransferPointsUnionType>;
  storeTransferToGL?: Maybe<StoreTransferPointsUnionType>;
  storeTransferToParticipant?: Maybe<StoreTransferPointsUnionType>;
  storeUpdatePayable?: Maybe<StorePayableInfoUnionType>;
  storeUpdateStatus?: Maybe<MagentoStoreUnionType>;
  storeUserPreferenceUpsert?: Maybe<UserPreference>;
  /** Submits submission draft to be reviewed by approver. Email will be sent to approver. */
  submitFundraiserApprovalSubmission: FundraiserApprovalSubmission;
  /** Send support-ticket email to the support team. */
  supportTicketSend?: Maybe<IMutationResult>;
  teamCreate?: Maybe<Team>;
  teamDelete?: Maybe<Team>;
  teamModify?: Maybe<Team>;
  teamOfficialCreate?: Maybe<TemplateOfficial>;
  teamOfficialDelete?: Maybe<TemplateOfficial>;
  teamOfficialModify?: Maybe<TemplateOfficial>;
  teamPreparationCreate?: Maybe<TemplatePreparation>;
  teamPreparationDelete?: Maybe<TemplatePreparation>;
  teamPreparationModify?: Maybe<TemplatePreparation>;
  teamWorkerCreate?: Maybe<TemplateWorker>;
  teamWorkerDelete?: Maybe<TemplateWorker>;
  teamWorkerModify?: Maybe<TemplateWorker>;
  toggleCardActivation: Scalars['String'];
  updateCurrentOrganizationMailingAddress?: Maybe<Organization>;
  updateFundraiserUserTypeConfig?: Maybe<Scalars['Boolean']>;
  updateParticipantCampaignConfiguration: ParticipantCampaignConfiguration;
  updateParticipantGroup?: Maybe<UpdatedParticipantGroupCount>;
  /** This will update the size of a Participant's OTK item */
  updateParticipantOtkSize: FundraiserUserIncentiveId;
  updatePrefund?: Maybe<Scalars['Boolean']>;
  updateWalletCardStatus?: Maybe<Scalars['String']>;
  updateWalletOrganization?: Maybe<Organization>;
  upgradeToManageUser?: Maybe<Scalars['String']>;
  upgradeToWalletUser?: Maybe<User>;
  uploadLogoPNG?: Maybe<OrderResult>;
  /** Create a relationship between a User and a Program with a given activity under a given Organization. This will also create a User Node only if they exist in User Directory but not Orgs. */
  userActivityLeadsCreate: MutationResponse;
  /** Delete a relationship between a User and a Program with a given activity under a given Organization. */
  userActivityLeadsDelete: MutationResponse;
  /** Update a relationship between a User and a Program with a given activity under a given Organization. */
  userActivityLeadsUpdate: MutationResponse;
  userApiKeyCreate?: Maybe<ApiKey>;
  userApiKeyDelete: Scalars['String'];
  userApiKeyUpdate?: Maybe<ApiKey>;
  /** @deprecated Please use userAssociationCreate instead */
  userAssociate: MutationResponse;
  /** Create first_seen and last_seen properties for a User and their product specific Org association. */
  userAssociationAccess: MutationResponse;
  /** Associate a User with an Org and a specific product. This will also create a User Node only if they exist in User Directory but not Orgs. */
  userAssociationCreate: MutationResponse;
  /** Add a deleted_at property to a User and their product specific Org association. This will NOT delete the relationship. */
  userAssociationDelete: MutationResponse;
  userBulkInviteSend: UserOrgInvitationResult;
  userChallengeUpdate?: Maybe<UserChallenge>;
  userChildCreate?: Maybe<User>;
  userChildUnassign: Scalars['String'];
  userChildUpdate?: Maybe<User>;
  userCreate?: Maybe<User>;
  userEmailConfirm?: Maybe<Scalars['String']>;
  userFittingAdd?: Maybe<UserFitting>;
  userImpersonate?: Maybe<Auth>;
  userImpersonateLogout?: Maybe<Auth>;
  /** If it is confirmed that a Group Leader is no longer affiliated with their former Org, mark their relationship with a campaign as 'no_longer_affiliated'. */
  userImpliedAffiliationDelete: MutationResponse;
  /** @deprecated use inviteCreate instead */
  userInvite: Scalars['String'];
  /** @deprecated use inviteAccept instead */
  userInviteAccept?: Maybe<UserInviteResponse>;
  userInviteSend?: Maybe<InviteSendResult>;
  /** Connect a User to an Org with a specific title. This will also create a User Node only if they exist in User Directory but not Orgs. */
  userOrgAffiliationCreate: MutationResponse;
  /** Add a deleted_at timestamp to a User's title specific Org affiliation. */
  userOrgAffiliationDelete: MutationResponse;
  /** Update the descripton, or isConfirmed on the affiliation between a User and an Org. If you want to change the title, you will need to delete the existing affiliation and create a new one. */
  userOrgAffiliationUpdate: MutationResponse;
  userPermissionsUpdate?: Maybe<UserPermissionsList>;
  userPhoneNumberChallengeConfirm?: Maybe<PhoneNumberChallengeConfirmation>;
  userPhoneNumberConfirm?: Maybe<Scalars['String']>;
  userRefreshSession?: Maybe<Auth>;
  userResetPassword?: Maybe<UserResetPasswordResponseWithAvailableTransport>;
  userResetPasswordByAdmin?: Maybe<UserResetPasswordByAdminResponse>;
  userSignup?: Maybe<SignUpResponseWithAvailableTransport>;
  userSignupConfirm?: Maybe<Auth>;
  userSignupConfirmationResend: Array<TransportType>;
  /** Create a relationship between a User and a specific Team. This will also create a User Node only if they exist in User Directory but not Orgs. */
  userTeamLeadsCreate: MutationResponse;
  /** Add a deleted_at property to a title specific relationship between a User and Team. */
  userTeamLeadsDelete: MutationResponse;
  /** Update the isConfirmed boolean on a title specific relationship between a User and Team. */
  userTeamLeadsUpdate: MutationResponse;
  userUnassignParent?: Maybe<Scalars['String']>;
  userUpdate?: Maybe<User>;
  userUpdatePassword?: Maybe<User>;
  vaultCreateKybKycSubmit: VaultFormMutationResponse;
  vaultCustomerCreate: Scalars['String'];
  vaultDocumentGatewayUpload?: Maybe<VaultMutationResponse>;
  vaultIncorrectKybSupportReport?: Maybe<VaultMutationResponse>;
  vaultKybCreate: Scalars['ID'];
  vaultKybUpdate: Scalars['ID'];
  vaultKycCreate: Scalars['ID'];
  vaultKycUpdate: Scalars['ID'];
  vaultOrgConfirmationSubmit: VaultFormMutationResponse;
  vaultSetupIntentCreate: Scalars['String'];
  vaultStripeConnectAccountBankAccountDefaultSet: VaultMutationResponse;
  vaultStripeConnectAccountBankAccountDelete: VaultMutationResponse;
  vaultStripeConnectAccountCreate: Scalars['String'];
  vaultStripeConnectAccountOwnersProvided: Scalars['String'];
  vaultStripeConnectAccountPaymentMethodAttach: VaultMutationResponse;
  vaultStripeConnectAccountPaymentMethodDefaultSet: VaultMutationResponse;
  vaultStripeConnectAccountPayoutFrequencySet: VaultMutationResponse;
  vaultStripeConnectAccountRemoveRepresentative?: Maybe<VaultMutationResponse>;
  vaultStripeConnectAccountUpdate: Scalars['String'];
  vaultStripePersonCreate: Scalars['String'];
  vaultStripePersonDelete: Scalars['String'];
  vaultStripePersonUpdate: Scalars['String'];
  vaultUpdateVaultKybAutomated?: Maybe<Scalars['String']>;
  vehicleCreate?: Maybe<Vehicle>;
  vehicleDelete?: Maybe<Vehicle>;
  vehicleModify?: Maybe<Vehicle>;
  /** Create the log record with type WARNING */
  warning?: Maybe<Scalars['Boolean']>;
  /** Deprecated: use cron-jobs instead */
  weeklyEventEmailScheduleSend?: Maybe<IMutationResult>;
  workerDeleteMany?: Maybe<WorkerDeleteManyCount>;
  workerPoolCreate?: Maybe<WorkerPool>;
  workerPoolDelete?: Maybe<Array<Maybe<WorkerPool>>>;
  workerPoolModify?: Maybe<WorkerPool>;
  workerUpsertMany?: Maybe<WorkerUpsertManyCount>;
  workersUpsert?: Maybe<Array<Maybe<WorkerPool>>>;
};


export type MutationActivateWalletCardArgs = {
  last4: Scalars['String'];
};


export type MutationActivateWalletVirtualCardArgs = {
  id: Scalars['String'];
};


export type MutationActivateWalletVirtualCardConfirmArgs = {
  code: Scalars['String'];
  id: Scalars['String'];
};


export type MutationAddParticipantGuardianDonationInviteEmailsV2Args = {
  emails?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationAddRevisionFundraiserApprovalSubmissionArgs = {
  revision: Scalars['NonEmptyString'];
  token: Scalars['String'];
};


export type MutationApproveFundraiserApprovalSubmissionArgs = {
  token: Scalars['String'];
};


export type MutationCampaignPersonListBulkCreateArgs = {
  emails: Array<Scalars['String']>;
  fundraiserId: Scalars['Int'];
  participantUserId: Scalars['Int'];
};


export type MutationCampaignPersonListCreateArgs = {
  contextableId: Scalars['Int'];
  contextableType: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  participantUserId: Scalars['Int'];
  personListType: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
};


export type MutationCampaignPersonListEntryDeleteArgs = {
  personListEntryId: Scalars['Int'];
};


export type MutationCampaignSearchFilterCreateArgs = {
  filterCriteria?: InputMaybe<Scalars['JSON']>;
  filterName?: InputMaybe<Scalars['String']>;
};


export type MutationCampaignSearchFilterDeleteArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type MutationCampaignSmsInviteAddArgs = {
  fundraiserId: Scalars['Int'];
  participantUserId: Scalars['Int'];
  phoneNumbers: Array<Scalars['String']>;
};


export type MutationCampaignSmsInviteDeleteArgs = {
  fundraiserId: Scalars['Int'];
  smsInviteId: Scalars['Int'];
};


export type MutationCommsRegisterArgs = {
  code: Scalars['String'];
  emailProvider?: InputMaybe<SupportedEmailProviders>;
  from?: InputMaybe<Scalars['String']>;
  fromName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  mjml?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  service?: InputMaybe<SnapService>;
  serviceUserId?: InputMaybe<Scalars['String']>;
  smsProvider?: InputMaybe<SupportedSmsProviders>;
  subject?: InputMaybe<Scalars['String']>;
  testData?: InputMaybe<Scalars['JSON']>;
  text?: InputMaybe<Scalars['String']>;
  transport?: InputMaybe<MessageTransportType>;
  type?: InputMaybe<MessageType>;
};


export type MutationCommsSendArgs = {
  contacts: Array<InputMaybe<MessageContact>>;
  sendAt?: InputMaybe<Scalars['Date']>;
  templateAttributes?: InputMaybe<Scalars['JSON']>;
  templateId?: InputMaybe<Scalars['String']>;
  templateName?: InputMaybe<Scalars['String']>;
  workflowId?: InputMaybe<Scalars['String']>;
};


export type MutationCommsTestArgs = {
  attributes?: InputMaybe<Scalars['JSON']>;
  challenge?: InputMaybe<Scalars['Boolean']>;
  mjml?: InputMaybe<Scalars['String']>;
  templateId?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};


export type MutationCompleteFundraiserApprovalSubmissionDocumentUploadArgs = {
  approvalSubmissionId: Scalars['Int'];
  s3FileName: Scalars['String'];
};


export type MutationCompleteWalletRegisterArgs = {
  altName?: InputMaybe<Scalars['String']>;
};


export type MutationConfirmPasswordArgs = {
  email: Scalars['String'];
  newPassword?: InputMaybe<Scalars['String']>;
  verificationCode: Scalars['String'];
};


export type MutationConfirmWalletOrganizationInfoArgs = {
  altName?: InputMaybe<Scalars['String']>;
};


export type MutationContactTemplateDeleteArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type MutationContactTemplateSaveArgs = {
  templateMessage?: InputMaybe<Scalars['String']>;
  templateName?: InputMaybe<Scalars['String']>;
  templateSubject?: InputMaybe<Scalars['String']>;
};


export type MutationCreateCardArgs = {
  card: IssueCardInput;
  gateway?: InputMaybe<Financial_Gateway>;
  organizationId?: InputMaybe<Scalars['String']>;
  raiseOrganizationId?: InputMaybe<Scalars['String']>;
};


export type MutationCreateChildFundraiserParticipantArgs = {
  input: CreateChildFundraiserParticipantInput;
};


export type MutationCreateChildFundraiserParticipantV2Args = {
  input: CreateChildFundraiserParticipantInput;
};


export type MutationCreateFundraiserApprovalSubmissionArgs = {
  additionalNotes?: InputMaybe<Scalars['String']>;
  campaignRaiseId?: InputMaybe<Scalars['Int']>;
  customerSupportInfo?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['String']>;
  estimatedTotalRaisedCents?: InputMaybe<Scalars['Int']>;
  firstName?: InputMaybe<Scalars['String']>;
  formType?: InputMaybe<FundraiserApprovalSubmissionFormType>;
  fundraiserApprovalSubmissionDocs?: InputMaybe<Array<InputMaybe<FundraiserApprovalSubmissionDocumentInput>>>;
  fundraiserApprovers?: InputMaybe<Array<InputMaybe<FundraiserApproverInput>>>;
  fundraiserServiceWebsite?: InputMaybe<Scalars['String']>;
  groupSize?: InputMaybe<Scalars['Int']>;
  howThisWorks?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  newFundraiser?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['String']>;
  pricingAndFees?: InputMaybe<Scalars['String']>;
  programId?: InputMaybe<Scalars['String']>;
  proposedStartDate?: InputMaybe<Scalars['DateTime']>;
  reason?: InputMaybe<Scalars['String']>;
  revisionRequest?: InputMaybe<Scalars['String']>;
  safety?: InputMaybe<Scalars['String']>;
  submitterId: Scalars['NonEmptyString'];
};


export type MutationCreateFundraiserUserArgs = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
};


export type MutationCreateOtkParticipantEntryArgs = {
  fundraiserId: Scalars['Int'];
  incentiveId: Scalars['Int'];
  size: Scalars['String'];
};


export type MutationCreatePrefundArgs = {
  amount: Scalars['Int'];
  cardType?: InputMaybe<Card_Type>;
  email: Scalars['String'];
  fundraiserId: Scalars['Int'];
  nameOnCard?: InputMaybe<Scalars['String']>;
  shippingService?: InputMaybe<Shipping_Service>;
};


export type MutationCreatePrefundTopupArgs = {
  amount: Scalars['Int'];
  fundraiserId: Scalars['Int'];
};


export type MutationCreateProductArgs = {
  product?: InputMaybe<ProductInput>;
};


export type MutationCreateStoreOrderArgs = {
  order?: InputMaybe<OrderInput>;
};


export type MutationDebugArgs = {
  payload: Scalars['JSON'];
  service: AuditLogService;
  source: AuditLogSource;
};


export type MutationDeleteFundraiserApprovalSubmissionDocumentArgs = {
  approvalSubmissionId: Scalars['Int'];
  documentKey: Scalars['String'];
};


export type MutationDonorDonateIntentCreateArgs = {
  input: DonorDonateIntentCreateInput;
};


export type MutationDonorDonateIntentMarkProcessingArgs = {
  data?: InputMaybe<DonorDonateIntentUpdateInput>;
  id: Scalars['ID'];
};


export type MutationDonorDonateIntentUpdateArgs = {
  id: Scalars['ID'];
  input: DonorDonateIntentUpdateInput;
};


export type MutationDonorsAdhocGiftShopIntentAddArgs = {
  intentInput: AdhocGiftShopIntentInput;
  item: PurchaseItemInput;
};


export type MutationDonorsDonationCreateOfflineArgs = {
  offlineDonationData: DonorDonationOfflineInput;
};


export type MutationDonorsDonationDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationDonorsDonationUpdateArgs = {
  data: DonorDonationUpdateInput;
  id: Scalars['ID'];
};


export type MutationDonorsFundraiserPayableRefundArgs = {
  input: DonorFundraiserPayableRefundInput;
};


export type MutationDonorsGiftShopIntentAddArgs = {
  intentInput: GiftShopIntentInput;
  item: PurchaseItemInput;
};


export type MutationDonorsGiftShopIntentAddManyArgs = {
  intentInput: GiftShopIntentInput;
  items: Array<PurchaseItemInput>;
};


export type MutationDonorsGiftShopIntentMarkProcessingArgs = {
  purchaseIntentId: Scalars['String'];
};


export type MutationDonorsGiftShopIntentRemoveArgs = {
  incentiveId: Scalars['Int'];
  purchaseIntentId: Scalars['String'];
};


export type MutationDonorsGiftShopIntentUpdateArgs = {
  item: PurchaseItemInput;
  purchaseIntentId: Scalars['String'];
  status: PurchaseIntentStatus;
};


export type MutationDonorsGiftShopIntentUpdateIntentArgs = {
  intentInput: GiftShopIntentUpdateInput;
  purchaseIntentId: Scalars['String'];
};


export type MutationDonorsGiftShopPurchaseUpdateArgs = {
  data: PurchaseUpdateInput;
  purchaseId: Scalars['String'];
};


export type MutationDonorsReceiptsResendArgs = {
  transactions: Array<TransactionInput>;
};


export type MutationDriveAddHubspotProspectPinArgs = {
  dealId?: InputMaybe<Scalars['String']>;
  hubspotOrgId?: InputMaybe<Scalars['String']>;
};


export type MutationDriveAddWinbackPinArgs = {
  dealId?: InputMaybe<Scalars['String']>;
  hubspotOrgId?: InputMaybe<Scalars['String']>;
};


export type MutationDriveCampaignFinalizeArgs = {
  fundraiserId: Scalars['Int'];
};


export type MutationDriveCampaignSettleArgs = {
  fundraiserIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type MutationDriveCampaignUpdateArgs = {
  data: DriveCampaignUpdateInput;
  id: Scalars['Int'];
};


export type MutationDriveDisableActivityArgs = {
  dealId?: InputMaybe<Scalars['String']>;
  hubspotOrgId?: InputMaybe<Scalars['String']>;
};


export type MutationDriveDisableOrgActivityArgs = {
  activityId?: InputMaybe<Scalars['String']>;
  activityType?: InputMaybe<Scalars['String']>;
  campaignsCount?: InputMaybe<Scalars['Int']>;
  curricularType?: InputMaybe<Scalars['String']>;
  hubspotOrgId?: InputMaybe<Scalars['String']>;
};


export type MutationDriveEnableActivityArgs = {
  dealId?: InputMaybe<Scalars['String']>;
  hubspotOrgId?: InputMaybe<Scalars['String']>;
};


export type MutationDriveEnableOrgActivityArgs = {
  activityId?: InputMaybe<Scalars['String']>;
  hubspotOrgId?: InputMaybe<Scalars['String']>;
};


export type MutationDriveEventActivityCreateArgs = {
  eventId: Scalars['String'];
  input?: InputMaybe<DriveEventActivityInput>;
};


export type MutationDriveEventActivityRemoveArgs = {
  id: Scalars['ID'];
};


export type MutationDriveEventActivityUpdateArgs = {
  id: Scalars['ID'];
  input: DriveEventActivityInput;
};


export type MutationDriveEventAgendaCreateArgs = {
  eventId: Scalars['ID'];
  input: DriveEventAgendaInput;
};


export type MutationDriveEventAttendeeAddArgs = {
  eventId: Scalars['ID'];
  input: DriveEventAttendeeInput;
  update?: InputMaybe<Scalars['Boolean']>;
};


export type MutationDriveEventAttendeeUpdateArgs = {
  eventId: Scalars['ID'];
  input: DriveEventAttendeeInput;
  userId: Scalars['ID'];
};


export type MutationDriveEventAttendeesRemoveArgs = {
  eventId: Scalars['ID'];
  userIds: Array<Scalars['String']>;
};


export type MutationDriveEventCreateArgs = {
  input: DriveEventInput;
};


export type MutationDriveEventRemoveArgs = {
  id: Scalars['ID'];
};


export type MutationDriveEventUpdateArgs = {
  id: Scalars['ID'];
  input: DriveEventInput;
};


export type MutationDriveOrgUserTrackingSetArgs = {
  input?: InputMaybe<DriveTrackingInput>;
  orgId: Scalars['String'];
};


export type MutationDrivePreloadSmsScheduleCancelArgs = {
  fundraiserId: Scalars['Int'];
};


export type MutationDrivePreloadSmsSendArgs = {
  fundraiserId: Scalars['Int'];
  schedule?: InputMaybe<DriveScheduleInput>;
};


export type MutationDrivePreloadSmsSendScheduleUpdateArgs = {
  fundraiserId: Scalars['Int'];
  schedule: DriveScheduleUpdateInput;
};


export type MutationDriveRemoveHubspotProspectPinArgs = {
  dealId?: InputMaybe<Scalars['String']>;
};


export type MutationDriveRemoveWinbackPinArgs = {
  dealId?: InputMaybe<Scalars['String']>;
};


export type MutationDriveS3ImageResizeArgs = {
  fileUploaded?: InputMaybe<Scalars['String']>;
};


export type MutationDriveShareProjectedRaisedResultsArgs = {
  activityType: Scalars['String'];
  earlyAccessApprovedFundsMax: Scalars['Int'];
  earlyAccessApprovedFundsMin: Scalars['Int'];
  location: Scalars['String'];
  participantCount: Scalars['Int'];
  projectedRaisedMax: Scalars['Int'];
  projectedRaisedMin: Scalars['Int'];
  to: Scalars['String'];
};


export type MutationDriveUploadUrlCreateArgs = {
  fileName: Scalars['String'];
};


export type MutationEditFundraiserApprovalSubmissionArgs = {
  additionalNotes?: InputMaybe<Scalars['String']>;
  campaignRaiseId?: InputMaybe<Scalars['Int']>;
  customerSupportInfo?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['String']>;
  estimatedTotalRaisedCents?: InputMaybe<Scalars['Int']>;
  firstName?: InputMaybe<Scalars['String']>;
  formType?: InputMaybe<FundraiserApprovalSubmissionFormType>;
  fundraiserApprovalSubmissionDocs: Array<InputMaybe<FundraiserApprovalSubmissionDocumentInput>>;
  fundraiserApprovers: Array<InputMaybe<FundraiserApproverInput>>;
  fundraiserServiceWebsite?: InputMaybe<Scalars['String']>;
  groupSize?: InputMaybe<Scalars['Int']>;
  howThisWorks?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  lastName?: InputMaybe<Scalars['String']>;
  newFundraiser?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['String']>;
  pricingAndFees?: InputMaybe<Scalars['String']>;
  programId?: InputMaybe<Scalars['String']>;
  proposedStartDate?: InputMaybe<Scalars['DateTime']>;
  reason?: InputMaybe<Scalars['String']>;
  revisionRequest?: InputMaybe<Scalars['String']>;
  safety?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<FundraiserApprovalSubmissionStatus>;
};


export type MutationErrorArgs = {
  payload: Scalars['JSON'];
  service: AuditLogService;
  source: AuditLogSource;
};


export type MutationEventCreateArgs = {
  input?: InputMaybe<CreateEventInput>;
};


export type MutationEventDeleteArgs = {
  input?: InputMaybe<DeleteEventInput>;
};


export type MutationEventModifyArgs = {
  input?: InputMaybe<ModifyEventInput>;
};


export type MutationEventParticipantsDeleteManyArgs = {
  input?: InputMaybe<DeleteManyEventParticipantsInput>;
};


export type MutationEventParticipantsUpsertArgs = {
  input?: InputMaybe<UpsertEventParticipantsInput>;
};


export type MutationEventPreparationCreateArgs = {
  input?: InputMaybe<CreateEventPreparationInput>;
};


export type MutationEventPreparationDeleteArgs = {
  input?: InputMaybe<DeleteEventPreparationInput>;
};


export type MutationEventPreparationModifyArgs = {
  input?: InputMaybe<ModifyEventPreparationInput>;
};


export type MutationEventPreparationsCreateManyArgs = {
  input?: InputMaybe<Array<InputMaybe<EventPreparationsInput>>>;
};


export type MutationEventPreparationsDeleteManyArgs = {
  input?: InputMaybe<DeleteManyEventPreparationsInput>;
};


export type MutationEventPreparationsModifyManyArgs = {
  input?: InputMaybe<ModifyManyEventPreparationsByEventInput>;
};


export type MutationEventPreparationsUpsertArgs = {
  input?: InputMaybe<UpsertEventPreparationsInput>;
};


export type MutationEventTransportDetailsDeleteManyArgs = {
  input?: InputMaybe<DeleteManyEventTransportDetailsInput>;
};


export type MutationEventTransportDetailsUpsertManyArgs = {
  input?: InputMaybe<UpsertEventTransportDetailsInput>;
};


export type MutationFacilitiesUpsertArgs = {
  input?: InputMaybe<Array<InputMaybe<UpsertFacilitiesInput>>>;
};


export type MutationFacilityCreateArgs = {
  input?: InputMaybe<CreateFacilityInput>;
};


export type MutationFacilityDeleteArgs = {
  input?: InputMaybe<DeleteFacilityInput>;
};


export type MutationFacilityModifyArgs = {
  input?: InputMaybe<ModifyFacilityInput>;
};


export type MutationFinAcctUserAssociationCreateArgs = {
  finAcctId: Scalars['ID'];
  isBeneficialOwner?: InputMaybe<Scalars['Boolean']>;
  isContact?: InputMaybe<Scalars['Boolean']>;
  isPrincipal?: InputMaybe<Scalars['Boolean']>;
  isRepresentative?: InputMaybe<Scalars['Boolean']>;
  kycId?: InputMaybe<Scalars['ID']>;
  personId?: InputMaybe<Scalars['ID']>;
  userId: Scalars['ID'];
};


export type MutationFinAcctUserAssociationDeleteArgs = {
  finAcctId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationFinAcctUserAssociationUpdateArgs = {
  finAcctId: Scalars['ID'];
  isBeneficialOwner?: InputMaybe<Scalars['Boolean']>;
  isContact?: InputMaybe<Scalars['Boolean']>;
  isPrincipal?: InputMaybe<Scalars['Boolean']>;
  isRepresentative?: InputMaybe<Scalars['Boolean']>;
  kycId?: InputMaybe<Scalars['ID']>;
  personId?: InputMaybe<Scalars['ID']>;
  userId: Scalars['ID'];
};


export type MutationFinancialAcctCreateArgs = {
  acctId?: InputMaybe<Scalars['ID']>;
  applicationId?: InputMaybe<Scalars['ID']>;
  customerId?: InputMaybe<Scalars['ID']>;
  kybId: Scalars['ID'];
  orgId: Scalars['ID'];
  processor: Processor;
  status?: FinAcctStatus;
  stripeEnv?: FinAcctStripeEnv;
};


export type MutationFinancialAcctDeleteArgs = {
  finAcctId: Scalars['ID'];
};


export type MutationFinancialAcctUpdateArgs = {
  acctId?: InputMaybe<Scalars['ID']>;
  applicationId?: InputMaybe<Scalars['ID']>;
  customerId?: InputMaybe<Scalars['ID']>;
  finAcctId: Scalars['ID'];
  kybId?: InputMaybe<Scalars['ID']>;
  orgId: Scalars['ID'];
  paymentMethodId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<FinAcctStatus>;
};


export type MutationFundraiserStoreUrlUpdateArgs = {
  fundraiserId: Scalars['Int'];
  storeUrl?: InputMaybe<Scalars['String']>;
};


export type MutationGroupLeaderEmailSendArgs = {
  ccEmails?: InputMaybe<Array<Scalars['String']>>;
  fundraiserId: Scalars['Int'];
  groupLeaderEmail: Scalars['String'];
  message: Scalars['String'];
  subject: Scalars['String'];
};


export type MutationGroupLeaderFundraiserDonationLevelsUpdateArgs = {
  fundraiserId: Scalars['ID'];
  input: Array<GroupLeaderDonationLevelsInput>;
};


export type MutationGroupLeaderFundraiserMediaGalleryUpdateArgs = {
  fundraiserId: Scalars['ID'];
  input: GroupLeaderFundraiserMediaGalleryUpdateInput;
};


export type MutationGroupLeaderFundraiserUpdateArgs = {
  fundraiserId: Scalars['ID'];
  input: GroupLeaderEditFundraiserInput;
};


export type MutationHelpDocumentDeleteArgs = {
  input: HelpDocumentDeleteInput;
};


export type MutationHelpDocumentUploadArgs = {
  input: HelpDocumentUploadInput;
};


export type MutationHubspotCallEngagementToDealAddArgs = {
  fundraiserId: Scalars['Int'];
};


export type MutationHubspotNoteEngagementToDealAddArgs = {
  fundraiserId: Scalars['Int'];
};


export type MutationInfoArgs = {
  payload: Scalars['JSON'];
  service: AuditLogService;
  source: AuditLogSource;
};


export type MutationInsightsAddPreApprovedContactsArgs = {
  approver: InsAddPreApprovedContactApproverInput;
  contacts: Array<InputMaybe<InsPreApprovedContactInput>>;
  dashboardUrl: Scalars['String'];
  org: InsAddPreApprovedContactOrgInput;
};


export type MutationInsightsDeletePreApprovedContactArgs = {
  contactId: Scalars['Int'];
  userId: Scalars['String'];
};


export type MutationInsightsEditPreApprovedContactArgs = {
  contactId: Scalars['Int'];
  orgId: Scalars['String'];
  senderName: Scalars['String'];
  updatedContact: InsEditPreApprovedContactInput;
};


export type MutationInsightsEditPreApprovedContactInviteArgs = {
  token?: InputMaybe<Scalars['String']>;
};


export type MutationInsightsResendInvitePreApprovedContactArgs = {
  contactId: Scalars['Int'];
  senderName: Scalars['String'];
};


export type MutationInsightsSetNotificationPreferencesArgs = {
  preferences: Array<InputMaybe<InsSetNotificationPreferences>>;
  userId: Scalars['String'];
};


export type MutationInsightsSetUserPreferencesArgs = {
  campaignIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  orgIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  setTo: SetUserPreferenceFlag;
  userId: Scalars['String'];
};


export type MutationInviteAcceptArgs = {
  inviteId?: InputMaybe<Scalars['String']>;
};


export type MutationInviteCreateArgs = {
  email: Scalars['String'];
  parent?: InputMaybe<InviteParentArguments>;
  sendEmail?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<InviteType>;
  user?: InputMaybe<InviteUserArguments>;
};


export type MutationInviteDeleteArgs = {
  inviteId?: InputMaybe<Scalars['String']>;
};


export type MutationJoinFundraiserUserArgs = {
  joinCode: Scalars['String'];
  joinType?: InputMaybe<JoinType>;
  role: CampaignMemberAssociation;
};


export type MutationJoinFundraiserUserByIdArgs = {
  fundraiserId: Scalars['Int'];
  importType?: InputMaybe<FundraiserUserImportType>;
  role: CampaignMemberAssociation;
  ssoId: Scalars['String'];
  user: JoinFundraiserUserInput;
};


export type MutationLatestVaultCardCreateArgs = {
  altName?: InputMaybe<Scalars['String']>;
  attachCardToKybBalance: Scalars['Boolean'];
  cardType: VaultCardType;
  kybId: Scalars['ID'];
  metadata?: InputMaybe<VaultCardMetadataInput>;
  recipientName?: InputMaybe<Scalars['String']>;
  shippingAddress?: InputMaybe<VaultAddressInput>;
  shippingService?: InputMaybe<VaultShippingService>;
  spendingLimitAmount: Scalars['Int'];
  spendingLimitInterval: VaultSpendingLimitInterval;
};


export type MutationLevelCreateArgs = {
  input?: InputMaybe<CreateLevelInput>;
};


export type MutationLevelDeleteArgs = {
  input?: InputMaybe<DeleteLevelInput>;
};


export type MutationLevelModifyArgs = {
  input?: InputMaybe<ModifyLevelInput>;
};


export type MutationLogArgs = {
  payload: Scalars['JSON'];
  service: AuditLogService;
  source: AuditLogSource;
  type: AuditLogType;
};


export type MutationLoginArgs = {
  consumer?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationLogoutArgs = {
  consumer?: InputMaybe<Scalars['String']>;
};


export type MutationManageUserCreateArgs = {
  input?: InputMaybe<ManageUserCreateInput>;
};


export type MutationManageUserDeleteArgs = {
  input?: InputMaybe<ManageUserDeleteInput>;
};


export type MutationManageUserUpdateArgs = {
  input?: InputMaybe<ManageUserUpdateInput>;
};


export type MutationMessagesChannelAddModeratorsArgs = {
  id: Scalars['String'];
  moderatorIds: Array<Scalars['String']>;
};


export type MutationMessagesChannelAddUsersArgs = {
  id: Scalars['String'];
  userIds: Array<Scalars['String']>;
};


export type MutationMessagesChannelCreateArgs = {
  assignmentId?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  moderatorIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name: Scalars['String'];
  type: ChannelTypesEnum;
  userIds: Array<InputMaybe<Scalars['String']>>;
};


export type MutationMessagesChannelUpdateArgs = {
  channel?: InputMaybe<ChannelUpdateInput>;
  id: Scalars['String'];
};


export type MutationMessagesChannelUpdateMembersArgs = {
  assignment?: InputMaybe<AssignmentInput>;
  id?: InputMaybe<Scalars['String']>;
  moderatorIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationMessagesUpsertUsersArgs = {
  userIds: Array<Scalars['String']>;
};


export type MutationNetSuiteWebhookArgs = {
  netSuiteEvent?: InputMaybe<NetSuiteEventInput>;
};


export type MutationNoticeCreateArgs = {
  input?: InputMaybe<NoticeCreateInput>;
};


export type MutationNoticeDeleteArgs = {
  input?: InputMaybe<NoticeDeleteInput>;
};


export type MutationNoticeModifyArgs = {
  input?: InputMaybe<NoticeModifyInput>;
};


export type MutationNotifyIncorrectWalletInfoArgs = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  orgId: Scalars['Int'];
};


export type MutationOfficialCreateArgs = {
  input?: InputMaybe<CreateOfficialInput>;
};


export type MutationOfficialDeleteArgs = {
  input?: InputMaybe<DeleteOfficialInput>;
};


export type MutationOfficialDutiesDeleteArgs = {
  input?: InputMaybe<DeleteOfficialDutiesInput>;
};


export type MutationOfficialDutiesUpsertArgs = {
  input?: InputMaybe<Array<InputMaybe<UpsertOfficialDutiesInput>>>;
};


export type MutationOfficialDutyCreateArgs = {
  input?: InputMaybe<CreateOfficialDutyInput>;
};


export type MutationOfficialDutyModifyArgs = {
  input?: InputMaybe<ModifyOfficialDutyInput>;
};


export type MutationOfficialModifyArgs = {
  input?: InputMaybe<ModifyOfficialInput>;
};


export type MutationOfficialPoolCreateArgs = {
  input?: InputMaybe<CreateOfficialPoolByIdInput>;
};


export type MutationOfficialPoolDeleteArgs = {
  input?: InputMaybe<DeleteOfficialPoolInput>;
};


export type MutationOfficialPoolModifyArgs = {
  input?: InputMaybe<ModifyOfficialPoolByIdInput>;
};


export type MutationOfficialsUpsertArgs = {
  input?: InputMaybe<Array<InputMaybe<UpsertOfficialsInput>>>;
};


export type MutationOpponentCreateArgs = {
  input?: InputMaybe<CreateOpponentInput>;
};


export type MutationOpponentDeleteArgs = {
  input?: InputMaybe<DeleteOpponentInput>;
};


export type MutationOpponentModifyArgs = {
  input?: InputMaybe<ModifyOpponentInput>;
};


export type MutationOpponentsUpsertArgs = {
  input?: InputMaybe<Array<InputMaybe<UpsertOpponentsInput>>>;
};


export type MutationOrgAffiliateCreateArgs = {
  city: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  einNumber: Scalars['String'];
  formationYear?: InputMaybe<Scalars['Int']>;
  hubspotId?: InputMaybe<Scalars['String']>;
  legalName: Scalars['String'];
  name: Scalars['String'];
  parentOrgId?: InputMaybe<Scalars['ID']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  stateCode: StateCode;
  streetAddress: Scalars['String'];
  type: OrgType;
  url?: InputMaybe<Scalars['String']>;
  zipCode: Scalars['String'];
};


export type MutationOrgClubCreateArgs = {
  city: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  einNumber: Scalars['String'];
  formationYear?: InputMaybe<Scalars['Int']>;
  hubspotId?: InputMaybe<Scalars['String']>;
  legalName: Scalars['String'];
  name: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  stateCode: StateCode;
  streetAddress: Scalars['String'];
  url?: InputMaybe<Scalars['String']>;
  zipCode: Scalars['String'];
};


export type MutationOrgDistrictCreateArgs = {
  city: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  einNumber: Scalars['String'];
  formationYear?: InputMaybe<Scalars['Int']>;
  hubspotId?: InputMaybe<Scalars['String']>;
  legalName: Scalars['String'];
  name: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  stateCode: StateCode;
  streetAddress: Scalars['String'];
  url?: InputMaybe<Scalars['String']>;
  zipCode: Scalars['String'];
};


export type MutationOrgLabelUpdateArgs = {
  auxiliary_type?: InputMaybe<ModType>;
  label: OrgType;
  modId: Scalars['ID'];
  parentModId?: InputMaybe<Scalars['ID']>;
};


export type MutationOrgProgramCreateArgs = {
  activity: ProgramActivity;
  orgId: Scalars['ID'];
};


export type MutationOrgSchoolCreateArgs = {
  city: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  einNumber: Scalars['String'];
  formationYear?: InputMaybe<Scalars['Int']>;
  hubspotId?: InputMaybe<Scalars['String']>;
  legalName: Scalars['String'];
  name: Scalars['String'];
  ncesId?: InputMaybe<Scalars['String']>;
  parentDistrictId?: InputMaybe<Scalars['ID']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  stateCode: StateCode;
  streetAddress: Scalars['String'];
  url?: InputMaybe<Scalars['String']>;
  zipCode: Scalars['String'];
};


export type MutationOrgTeamCreateArgs = {
  activity: ProgramActivity;
  name: Scalars['String'];
  orgId: Scalars['ID'];
  teamAge: TeamAge;
  teamGender: TeamGender;
  teamGroupType: TeamGroupType;
  teamSize?: InputMaybe<Scalars['Int']>;
  teamTier: TeamTier;
};


export type MutationParticipantDonationInviteEditArgs = {
  campaignID: Scalars['String'];
  donationInviteID: Scalars['String'];
  newEmail: Scalars['String'];
};


export type MutationParticipantDonationInviteEmailAddArgs = {
  campaignID: Scalars['String'];
  email: Scalars['String'];
};


export type MutationParticipantDonationInviteRemoveArgs = {
  email: Scalars['String'];
  fundraiserId: Scalars['String'];
  personListEntryId: Scalars['String'];
};


export type MutationParticipantDonationInviteSmsAddArgs = {
  campaignID: Scalars['String'];
  phoneNumber: Scalars['String'];
};


export type MutationParticipantGuardianEmailUpdateArgs = {
  guardianEmail: Scalars['String'];
  secondGuardianEmail?: InputMaybe<Scalars['String']>;
};


export type MutationParticipantIncentiveSelectionDeleteArgs = {
  fundraiserId: Scalars['ID'];
  fundraiserUserIncentiveId: Scalars['ID'];
};


export type MutationParticipantRewardsBulkCreateArgs = {
  fundraiserId: Scalars['Int'];
  rewards: Array<ParticipantRewardsInput>;
};


export type MutationParticipantRewardsCreateArgs = {
  fundraiserId: Scalars['Int'];
  incentiveId: Scalars['Int'];
  size: Scalars['String'];
  tier: Scalars['Int'];
};


export type MutationParticipantSignupArgs = {
  apps?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  email: Scalars['String'];
  password: Scalars['String'];
  phoneNumber: Scalars['String'];
};


export type MutationParticipantsEmailSendArgs = {
  fundraiserId: Scalars['Int'];
  message: Scalars['String'];
  subject: Scalars['String'];
};


export type MutationParticipantsTextSendArgs = {
  fundraiserId: Scalars['Int'];
  message: Scalars['String'];
};


export type MutationPaymentsApiCustomerGetOrCreateArgs = {
  stripeEnv?: InputMaybe<StripeEnv>;
};


export type MutationPaymentsApiDetachPaymentMethodArgs = {
  input?: InputMaybe<PaymentsApiDetachPaymentMethodInput>;
};


export type MutationPaymentsApiPaymentArgs = {
  input?: InputMaybe<PaymentsApiPaymentIput>;
};


export type MutationPaymentsApiRefundArgs = {
  input?: InputMaybe<PaymentsApiRefundInput>;
};


export type MutationPreloadEmailRescheduleSendArgs = {
  dateTime?: InputMaybe<Scalars['DateTime']>;
  fundraiserId: Scalars['Int'];
  offsetInSeconds?: InputMaybe<Scalars['Int']>;
  timezone?: InputMaybe<Scalars['String']>;
};


export type MutationPreloadEmailScheduleCancelArgs = {
  fundraiserId: Scalars['Int'];
};


export type MutationPreloadEmailScheduleSendArgs = {
  dateTime: Scalars['DateTime'];
  fundraiserId: Scalars['Int'];
  timezone: Scalars['String'];
};


export type MutationPreloadEmailSendArgs = {
  fundraiserId: Scalars['Int'];
};


export type MutationPreparationCreateArgs = {
  input?: InputMaybe<CreatePreparationInput>;
};


export type MutationPreparationDeleteArgs = {
  input?: InputMaybe<DeletePreparationInput>;
};


export type MutationPreparationModifyArgs = {
  input?: InputMaybe<ModifyPreparationInput>;
};


export type MutationRaiseAdminFundraiserUsersCreateManyArgs = {
  input: RaiseFundraiserUsersCreateManyInput;
};


export type MutationRoleCreateArgs = {
  data: RoleArguments;
};


export type MutationRoleDeleteArgs = {
  id: Scalars['String'];
};


export type MutationRolePermissionsUpdateArgs = {
  permissionIds: Array<Scalars['String']>;
  roleId: Scalars['String'];
};


export type MutationRoleUpdateArgs = {
  data: RoleArguments;
  id: Scalars['String'];
};


export type MutationS3PresignedInsightsApprovalsUrlArgs = {
  approvalsFormId: Scalars['Int'];
  approvalsSubmissionId: Scalars['Int'];
  files: Array<InputMaybe<Scalars['NonEmptyString']>>;
};


export type MutationSamlCallbackArgs = {
  actualEmail?: InputMaybe<Scalars['String']>;
  apps?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  consumer?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};


export type MutationSchoolInfoCreateArgs = {
  input?: InputMaybe<CreateSchoolInfoInput>;
};


export type MutationSchoolInfoDeleteArgs = {
  input?: InputMaybe<DeleteSchoolInfoInput>;
};


export type MutationSchoolInfoModifyArgs = {
  input?: InputMaybe<ModifySchoolInfoInput>;
};


export type MutationSeasonCreateArgs = {
  input?: InputMaybe<SeasonCreateInput>;
};


export type MutationSeasonDeleteArgs = {
  input?: InputMaybe<SeasonDeleteInput>;
};


export type MutationSeasonModifyArgs = {
  input?: InputMaybe<SeasonModifyInput>;
};


export type MutationSeasonPostponeModifyArgs = {
  input?: InputMaybe<SeasonPostponeUnpostponeInput>;
};


export type MutationSeasonScheduleTeamsCreateArgs = {
  input?: InputMaybe<SeasonScheduleTeamsCreateInput>;
};


export type MutationSeasonUnpostponeModifyArgs = {
  input?: InputMaybe<SeasonPostponeUnpostponeInput>;
};


export type MutationSetUserInsightsConfigurationsArgs = {
  config: Array<InputMaybe<UserInsightsConfigInput>>;
};


export type MutationSettlementIssuesRecordArgs = {
  fundraiserId: Scalars['Int'];
  note?: InputMaybe<Scalars['String']>;
};


export type MutationSettlementStatusUpdateArgs = {
  fundraiserId: Scalars['Int'];
  settlementStatus?: InputMaybe<SettlementStatus>;
};


export type MutationSpendArchiveSeasonMembersArgs = {
  input: SpendArchiveSeasonMembersInput;
};


export type MutationSpendBudgetCreateArgs = {
  input: SpendBudgetInput;
};


export type MutationSpendBudgetDeleteArgs = {
  id: Scalars['String'];
};


export type MutationSpendBudgetUpdateArgs = {
  id: Scalars['String'];
  input: SpendBudgetInput;
};


export type MutationSpendCategoryCreateArgs = {
  input: SpendCategoryInput;
};


export type MutationSpendCategoryUpdateArgs = {
  id: Scalars['String'];
  input: SpendCategoryInput;
};


export type MutationSpendCategoryUpsertBulkArgs = {
  input?: InputMaybe<Array<InputMaybe<SpendUpsertCategoryInput>>>;
};


export type MutationSpendCreditMemoCreateArgs = {
  input: SpendCreditMemoInput;
};


export type MutationSpendCreditMemoUpdateArgs = {
  input: SpendCreditMemoUpdateInput;
};


export type MutationSpendGroupArchiveArgs = {
  id: Scalars['String'];
};


export type MutationSpendGroupBankAccessTokenCreateArgs = {
  id?: InputMaybe<Scalars['String']>;
  publicToken: Scalars['String'];
  status?: InputMaybe<Scalars['String']>;
};


export type MutationSpendGroupBankAccessTokenDeleteArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type MutationSpendGroupCheckCancelArgs = {
  checkId: Scalars['String'];
};


export type MutationSpendGroupCheckDepositPatchArgs = {
  input: SpendGroupCheckDepositTagsInput;
};


export type MutationSpendGroupCheckSendArgs = {
  input?: InputMaybe<SpendCheckInput>;
};


export type MutationSpendGroupCreateArgs = {
  input: SpendGroupInput;
};


export type MutationSpendGroupDebitCardCreateArgs = {
  id?: InputMaybe<Scalars['String']>;
  input: SpendDebitCardInput;
};


export type MutationSpendGroupDuplicateArgs = {
  id: Scalars['String'];
};


export type MutationSpendGroupExternalTransferArgs = {
  input: SpendGroupExternalTransferInput;
};


export type MutationSpendGroupPayeeCreateArgs = {
  input: SpendPayeeInput;
};


export type MutationSpendGroupPayeeUpdateArgs = {
  input: SpendPayeeUpdateInput;
};


export type MutationSpendGroupRosterCreateArgs = {
  input: SpendGroupRosterInput;
};


export type MutationSpendGroupRosterUpdateArgs = {
  input: SpendRosterUserUpdate;
};


export type MutationSpendGroupRostersCreateArgs = {
  input: SpendGroupRostersInput;
};


export type MutationSpendGroupUpdateArgs = {
  id?: InputMaybe<Scalars['String']>;
  input: SpendGroupInput;
};


export type MutationSpendGuardianSignupArgs = {
  input: SpendGuardianSignupInput;
};


export type MutationSpendInvoiceArchiveArgs = {
  id: Scalars['String'];
};


export type MutationSpendInvoiceChangeRequestArgs = {
  input: SpendInvoiceRequestChangeInput;
};


export type MutationSpendInvoiceCreateArgs = {
  input: SpendInvoiceInput;
};


export type MutationSpendInvoicePaymentDeauthorizeArgs = {
  input: SpendInvoicePaymentDeauthorizeInput;
};


export type MutationSpendInvoiceRefundArgs = {
  input: SpendInvoiceRefundInput;
};


export type MutationSpendInvoiceReminderCreateArgs = {
  input: SpendInvoiceReminderInput;
};


export type MutationSpendInvoiceUpdateArgs = {
  id: Scalars['String'];
  input: SpendInvoiceInput;
};


export type MutationSpendInvoiceUpdatePaymentMethodArgs = {
  input: SpendInvoicePaymentMethodUpdate;
};


export type MutationSpendNotificationCreateArgs = {
  input: SpendNotificationInput;
};


export type MutationSpendOrganizationAccountTransferArgs = {
  input: SpendAccountTransferInput;
};


export type MutationSpendOrganizationAdminUpdateArgs = {
  input: SpendOrgAdminUpdateInput;
};


export type MutationSpendOrganizationBankAccessTokenCreateArgs = {
  publicToken: Scalars['String'];
  status?: InputMaybe<Scalars['String']>;
};


export type MutationSpendOrganizationCheckCancelArgs = {
  checkId: Scalars['String'];
};


export type MutationSpendOrganizationCheckDepositPatchArgs = {
  input: SpendOrganizationCheckDepositTagsInput;
};


export type MutationSpendOrganizationCheckSendArgs = {
  input?: InputMaybe<SpendCheckInput>;
};


export type MutationSpendOrganizationCreateArgs = {
  externalId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationSpendOrganizationDebitCardCreateArgs = {
  input: SpendDebitCardInput;
};


export type MutationSpendOrganizationExternalTransferArgs = {
  input: SpendOrganizationExternalTransferInput;
};


export type MutationSpendOrganizationPayeeCreateArgs = {
  input: SpendPayeeInput;
};


export type MutationSpendOrganizationPayeeUpdateArgs = {
  input: SpendPayeeUpdateInput;
};


export type MutationSpendOrganizationUpdateArgs = {
  data: SpendOrganizationInput;
  where: Scalars['ID'];
};


export type MutationSpendPaymentMethodCreateArgs = {
  input: SpendPaymentMethodInput;
};


export type MutationSpendPaymentScheduleCreateArgs = {
  input: SpendPaymentScheduleInput;
};


export type MutationSpendPaymentScheduleRevertArgs = {
  id: Scalars['String'];
};


export type MutationSpendPaymentScheduleRevertManyArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationSpendPaymentScheduleUpdateArgs = {
  id: Scalars['String'];
  input: SpendPaymentScheduleInput;
  rosterIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationSpendPaymentScheduleUpdateBySeasonArgs = {
  input: SpendPaymentScheduleBySeasonInput;
};


export type MutationSpendRemoveParticipantsArgs = {
  input: SpendRemoveSeasonMemberInput;
};


export type MutationSpendRoleChangeArgs = {
  roleId: Scalars['String'];
  sessionId: Scalars['String'];
};


export type MutationSpendSeasonCreateArgs = {
  input: SpendSeasonInput;
};


export type MutationSpendSeasonUpdateArgs = {
  id: Scalars['String'];
  input: SpendSeasonInput;
};


export type MutationSpendSessionCreateArgs = {
  inviteId?: InputMaybe<Scalars['String']>;
};


export type MutationSpendSessionRefreshArgs = {
  sessionId: Scalars['String'];
};


export type MutationSpendSettingsUpdateArgs = {
  input?: InputMaybe<SpendSettingsInput>;
};


export type MutationSpendSignupAgreementCreateArgs = {
  input: SpendSignupAgreementInput;
};


export type MutationSpendTransactionAttachmentCreateArgs = {
  input: SpendTransactionAttachmentInput;
};


export type MutationSpendTransactionAttachmentDeleteArgs = {
  id: Scalars['String'];
};


export type MutationSpendTransactionInvoiceUnreconcileArgs = {
  input: SpendTransactionInvoiceUnreconcileInput;
};


export type MutationSpendTransactionNoteCreateArgs = {
  input: SpendTranscationNoteInput;
};


export type MutationSpendTransactionNoteUpdateArgs = {
  id: Scalars['String'];
  input: SpendTranscationNoteInput;
};


export type MutationSpendTransactionReconcileArgs = {
  input: SpendTransactionReconcileInput;
};


export type MutationSpendTransactionsCreateArgs = {
  input: SpendTransactionInput;
};


export type MutationSpendUserAchPaymentArgs = {
  input: SpendAchPaymentInput;
};


export type MutationSpendUserAchPaymentCancelArgs = {
  input: SpendAchPaymentCancelInput;
};


export type MutationSpendUserBankAccessTokenCreateArgs = {
  groupId: Scalars['String'];
  publicToken: Scalars['String'];
  status?: InputMaybe<Scalars['String']>;
};


export type MutationSpendUserInviteArchiveArgs = {
  id: Scalars['String'];
};


export type MutationSpendUserInviteCreateArgs = {
  input: SpendInviteInput;
};


export type MutationSpendUserInviteDeleteArgs = {
  id: Scalars['String'];
};


export type MutationSpendUserInviteResendArgs = {
  input: SpendInviteResendInput;
};


export type MutationSpendUserInviteStatusUpdateArgs = {
  id: Scalars['String'];
  status: Scalars['String'];
};


export type MutationSpendUserInviteUpdateArgs = {
  id: Scalars['String'];
};


export type MutationSpendUserLeaveGroupArgs = {
  groupId: Scalars['String'];
};


export type MutationSpendUserPaymentsCardDetachArgs = {
  input?: InputMaybe<SpendPaymentMethodDetach>;
};


export type MutationSpendUserRoleArchiveArgs = {
  groupId?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};


export type MutationSpendUserRoleSetArgs = {
  roleId: Scalars['String'];
};


export type MutationSpendUserRoleUpdateArgs = {
  groupId?: InputMaybe<Scalars['String']>;
  roleName: SpendRoleNameEnum;
  userId: Scalars['String'];
};


export type MutationSpendUserSignUpArgs = {
  input: SpendSignUpInput;
};


export type MutationSpendUserSignupUpdateArgs = {
  input?: InputMaybe<SpendUserSignupInput>;
};


export type MutationStoreBuildRequestArgs = {
  input: StoreBuildRequestInput;
};


export type MutationStoreEditInfoArgs = {
  input: StoreEditInfoInput;
};


export type MutationStoreManagerPointsUpdateArgs = {
  input?: InputMaybe<StoreManagerUpdatePoints>;
};


export type MutationStorePaymentIntentCreateArgs = {
  input: StoreCreatePaymentIntent;
};


export type MutationStorePointsWithdrawalRequestArgs = {
  input?: InputMaybe<StorePointsWithdrawalRequestInput>;
};


export type MutationStoreProfitPercentageUpdateArgs = {
  store_id: Scalars['Int'];
  updated_product_price_percentage: Scalars['Int'];
};


export type MutationStoreTicketCreateArgs = {
  input: StoreTicketInput;
};


export type MutationStoreTransactionSaveArgs = {
  input: StoreSaveTransaction;
};


export type MutationStoreTransferToCustomerArgs = {
  input?: InputMaybe<StoreTransferCustomer>;
};


export type MutationStoreTransferToGlArgs = {
  input?: InputMaybe<StoreTransferGl>;
};


export type MutationStoreTransferToParticipantArgs = {
  input?: InputMaybe<StoreTransferParticipant>;
};


export type MutationStoreUpdatePayableArgs = {
  input?: InputMaybe<StoreUpdatePayableInput>;
  scopeId: Scalars['Int'];
};


export type MutationStoreUpdateStatusArgs = {
  input: StoreStatusInput;
};


export type MutationStoreUserPreferenceUpsertArgs = {
  featureTourCompleted?: InputMaybe<Scalars['Boolean']>;
  userId: Scalars['String'];
};


export type MutationSubmitFundraiserApprovalSubmissionArgs = {
  id: Scalars['Int'];
};


export type MutationSupportTicketSendArgs = {
  attachments?: InputMaybe<Array<DriveFileInput>>;
  ccEmails?: InputMaybe<Array<Scalars['String']>>;
  deviceInfo?: InputMaybe<Scalars['String']>;
  fundraiserId?: InputMaybe<Scalars['Int']>;
  message?: InputMaybe<Scalars['String']>;
  subject: Scalars['String'];
  supportTicketInfo?: InputMaybe<Array<DriveFieldInput>>;
  templateId?: InputMaybe<Scalars['String']>;
  to: Support_Ticket_Department;
};


export type MutationTeamCreateArgs = {
  input?: InputMaybe<TeamCreateInput>;
};


export type MutationTeamDeleteArgs = {
  input?: InputMaybe<TeamDeleteInput>;
};


export type MutationTeamModifyArgs = {
  input?: InputMaybe<TeamModifyInput>;
};


export type MutationTeamOfficialCreateArgs = {
  input?: InputMaybe<TeamOfficialCreateInput>;
};


export type MutationTeamOfficialDeleteArgs = {
  input?: InputMaybe<TeamOfficialDeleteInput>;
};


export type MutationTeamOfficialModifyArgs = {
  input?: InputMaybe<TeamOfficialModifyInput>;
};


export type MutationTeamPreparationCreateArgs = {
  input?: InputMaybe<TeamPreparationCreateInput>;
};


export type MutationTeamPreparationDeleteArgs = {
  input?: InputMaybe<TeamPreparationDeleteInput>;
};


export type MutationTeamPreparationModifyArgs = {
  input?: InputMaybe<TeamPreparationModifyInput>;
};


export type MutationTeamWorkerCreateArgs = {
  input?: InputMaybe<TeamWorkerCreateInput>;
};


export type MutationTeamWorkerDeleteArgs = {
  input?: InputMaybe<TeamWorkerDeleteInput>;
};


export type MutationTeamWorkerModifyArgs = {
  input?: InputMaybe<TeamWorkerModifyInput>;
};


export type MutationToggleCardActivationArgs = {
  cardId: Scalars['String'];
  status: Card_Status;
};


export type MutationUpdateCurrentOrganizationMailingAddressArgs = {
  address: FinancialAddressInput;
};


export type MutationUpdateFundraiserUserTypeConfigArgs = {
  id: Scalars['Int'];
  userTypeConfig: UserTypeConfiguration;
};


export type MutationUpdateParticipantCampaignConfigurationArgs = {
  field?: InputMaybe<ParticipantCampaignConfigInput>;
  id: Scalars['ID'];
};


export type MutationUpdateParticipantGroupArgs = {
  fundraiserId: Scalars['Int'];
  personListsId: Scalars['String'];
  userId: Scalars['Int'];
};


export type MutationUpdateParticipantOtkSizeArgs = {
  fundraiserUserIncentiveId: Scalars['Int'];
  size: Scalars['String'];
};


export type MutationUpdatePrefundArgs = {
  id: Scalars['String'];
  prefundAmount?: InputMaybe<Scalars['Int']>;
  shippingService?: InputMaybe<Shipping_Service>;
};


export type MutationUpdateWalletCardStatusArgs = {
  id: Scalars['String'];
  status: Card_Status;
};


export type MutationUpdateWalletOrganizationArgs = {
  altName?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  mailingAddress?: InputMaybe<FinancialAddressInput>;
};


export type MutationUpgradeToWalletUserArgs = {
  email?: InputMaybe<Scalars['String']>;
};


export type MutationUploadLogoPngArgs = {
  image?: InputMaybe<ImageInput>;
};


export type MutationUserActivityLeadsCreateArgs = {
  activity: ProgramActivity;
  isConfirmed?: InputMaybe<Scalars['Boolean']>;
  orgId: Scalars['ID'];
  title: TeamTitle;
  userId: Scalars['ID'];
};


export type MutationUserActivityLeadsDeleteArgs = {
  activity: ProgramActivity;
  orgId: Scalars['ID'];
  title: TeamTitle;
  userId: Scalars['ID'];
};


export type MutationUserActivityLeadsUpdateArgs = {
  activity: ProgramActivity;
  isConfirmed: Scalars['Boolean'];
  orgId: Scalars['ID'];
  title: TeamTitle;
  userId: Scalars['ID'];
};


export type MutationUserApiKeyCreateArgs = {
  expiresPeriod?: InputMaybe<Scalars['String']>;
  maxRps?: Scalars['Int'];
  name: Scalars['String'];
  permissionIds: Array<Scalars['String']>;
};


export type MutationUserApiKeyDeleteArgs = {
  id: Scalars['String'];
};


export type MutationUserApiKeyUpdateArgs = {
  id: Scalars['String'];
  maxRps?: Scalars['Int'];
  name: Scalars['String'];
  permissionIds: Array<Scalars['String']>;
};


export type MutationUserAssociateArgs = {
  orgId: Scalars['ID'];
  product: Product;
  roleId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationUserAssociationAccessArgs = {
  orgId: Scalars['ID'];
  product: Product;
  userId: Scalars['ID'];
};


export type MutationUserAssociationCreateArgs = {
  orgId: Scalars['ID'];
  product: Product;
  roleId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationUserAssociationDeleteArgs = {
  orgId: Scalars['ID'];
  product: Product;
  userId: Scalars['ID'];
};


export type MutationUserBulkInviteSendArgs = {
  inviteType: InviteType;
  orgPayload: Array<UserOrgInvitationPayload>;
};


export type MutationUserChallengeUpdateArgs = {
  id: Scalars['String'];
  status: UserChallengeStatus;
};


export type MutationUserChildCreateArgs = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};


export type MutationUserChildUnassignArgs = {
  id: Scalars['String'];
};


export type MutationUserChildUpdateArgs = {
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
  profilePicture?: InputMaybe<Scalars['String']>;
};


export type MutationUserCreateArgs = {
  apps?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  occupation?: InputMaybe<UserOccupation>;
  parentId?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  phoneNumber: Scalars['String'];
  profilePicture?: InputMaybe<Scalars['String']>;
  snapRaiseId?: InputMaybe<Scalars['Int']>;
};


export type MutationUserEmailConfirmArgs = {
  code: Scalars['String'];
};


export type MutationUserFittingAddArgs = {
  fitting: UserFittingPreference;
};


export type MutationUserImpersonateArgs = {
  userId: Scalars['String'];
};


export type MutationUserImpliedAffiliationDeleteArgs = {
  orgId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationUserInviteArgs = {
  email?: InputMaybe<Scalars['String']>;
  joincode?: InputMaybe<Scalars['String']>;
};


export type MutationUserInviteAcceptArgs = {
  inviteId?: InputMaybe<Scalars['String']>;
};


export type MutationUserInviteSendArgs = {
  parentInvite?: InputMaybe<ParentInviteInput>;
  type: InviteType;
};


export type MutationUserOrgAffiliationCreateArgs = {
  description?: InputMaybe<Scalars['String']>;
  isConfirmed?: InputMaybe<Scalars['Boolean']>;
  orgId: Scalars['ID'];
  title: AffiliationTitle;
  userId: Scalars['ID'];
};


export type MutationUserOrgAffiliationDeleteArgs = {
  orgId: Scalars['ID'];
  title: AffiliationTitle;
  userId: Scalars['ID'];
};


export type MutationUserOrgAffiliationUpdateArgs = {
  description?: InputMaybe<Scalars['String']>;
  isConfirmed?: InputMaybe<Scalars['Boolean']>;
  orgId: Scalars['ID'];
  title: AffiliationTitle;
  userId: Scalars['ID'];
};


export type MutationUserPermissionsUpdateArgs = {
  negativePermissions: Array<UserPermissionAssignment>;
  permissions: Array<UserPermissionAssignment>;
  roleIds: Array<Scalars['String']>;
  userId: Scalars['String'];
};


export type MutationUserPhoneNumberChallengeConfirmArgs = {
  challengeId: Scalars['String'];
  code: Scalars['String'];
};


export type MutationUserPhoneNumberConfirmArgs = {
  code: Scalars['String'];
};


export type MutationUserRefreshSessionArgs = {
  refreshToken?: InputMaybe<Scalars['String']>;
};


export type MutationUserResetPasswordArgs = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  transport?: InputMaybe<TransportEnum>;
  withLink?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUserResetPasswordByAdminArgs = {
  forcePasswordResetChallenge?: InputMaybe<Scalars['Boolean']>;
  password: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationUserSignupArgs = {
  apps?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  consumer?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  inviteId?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  occupation?: InputMaybe<UserOccupation>;
  password: Scalars['String'];
  phoneNumber: Scalars['String'];
  profilePicture?: InputMaybe<Scalars['String']>;
};


export type MutationUserSignupConfirmArgs = {
  code: Scalars['String'];
  email: Scalars['String'];
  skip?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUserSignupConfirmationResendArgs = {
  email: Scalars['String'];
  transport?: InputMaybe<TransportEnum>;
};


export type MutationUserTeamLeadsCreateArgs = {
  isConfirmed?: InputMaybe<Scalars['Boolean']>;
  teamId: Scalars['ID'];
  title: TeamTitle;
  userId: Scalars['ID'];
};


export type MutationUserTeamLeadsDeleteArgs = {
  teamId: Scalars['ID'];
  title: TeamTitle;
  userId: Scalars['ID'];
};


export type MutationUserTeamLeadsUpdateArgs = {
  isConfirmed: Scalars['Boolean'];
  teamId: Scalars['ID'];
  title: TeamTitle;
  userId: Scalars['ID'];
};


export type MutationUserUnassignParentArgs = {
  email?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['String']>;
};


export type MutationUserUpdateArgs = {
  apps?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  hsGradYear?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isConfirmed?: InputMaybe<Scalars['Boolean']>;
  isDisabled?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  occupation?: InputMaybe<UserOccupation>;
  password?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['String']>;
};


export type MutationUserUpdatePasswordArgs = {
  email: Scalars['String'];
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};


export type MutationVaultCreateKybKycSubmitArgs = {
  beneficialOwners?: InputMaybe<Array<VaultKycCreateInput>>;
  gateway: VaultFinancialProvider;
  orgData: VaultKybCreateInput;
  representativeData: VaultRepresentativeCreateInput;
  saveToPayableOrgId?: InputMaybe<Scalars['ID']>;
  stripeData?: InputMaybe<VaultCreateKybKycStripeInput>;
};


export type MutationVaultCustomerCreateArgs = {
  payableOrgName: Scalars['String'];
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};


export type MutationVaultDocumentGatewayUploadArgs = {
  documentBack?: InputMaybe<Scalars['String']>;
  documentFront: Scalars['String'];
  fileType: Scalars['String'];
  gateway: VaultFinancialProvider;
  stripeConnectAccountId?: InputMaybe<Scalars['String']>;
  stripeEnv?: InputMaybe<VaultStripeEnv>;
  stripePersonId?: InputMaybe<Scalars['String']>;
  type: VaultRequiredDocument;
  unitApplicationId?: InputMaybe<Scalars['String']>;
  vaultId: Scalars['ID'];
};


export type MutationVaultIncorrectKybSupportReportArgs = {
  comment?: InputMaybe<Scalars['String']>;
  orgAddressCity: Scalars['String'];
  orgAddressState: Scalars['String'];
  orgAddressStreet: Scalars['String'];
  orgAddressZip: Scalars['String'];
  orgEin: Scalars['String'];
  orgLegalName: Scalars['String'];
  raiseCampaignId: Scalars['String'];
  raiseUserId: Scalars['String'];
};


export type MutationVaultKybCreateArgs = {
  address: VaultAddressInput;
  customerFacingName: Scalars['String'];
  description: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  legalName: Scalars['String'];
  phoneNumber: Scalars['String'];
  structure: Kyb_Structure;
  taxId: Scalars['String'];
  type: Kyb_Type;
  url: Scalars['String'];
};


export type MutationVaultKybUpdateArgs = {
  address?: InputMaybe<VaultAddressUpdateInput>;
  customerFacingName?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  legalName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  structure?: InputMaybe<Kyb_Structure>;
  taxId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Kyb_Type>;
  url?: InputMaybe<Scalars['String']>;
};


export type MutationVaultKycCreateArgs = {
  address: VaultAddressInput;
  director?: InputMaybe<Scalars['Boolean']>;
  dob: VaultDobInput;
  email: Scalars['String'];
  executive?: InputMaybe<Scalars['Boolean']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  owner?: InputMaybe<Scalars['Boolean']>;
  percentOwnership?: InputMaybe<Scalars['Int']>;
  phoneNumber: Scalars['String'];
  representative?: InputMaybe<Scalars['Boolean']>;
  ssn?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};


export type MutationVaultKycUpdateArgs = {
  address?: InputMaybe<VaultAddressUpdateInput>;
  director?: InputMaybe<Scalars['Boolean']>;
  dob?: InputMaybe<VaultDobInput>;
  email?: InputMaybe<Scalars['String']>;
  executive?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<Scalars['Boolean']>;
  percentOwnership?: InputMaybe<Scalars['Int']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  representative?: InputMaybe<Scalars['Boolean']>;
  ssn?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};


export type MutationVaultOrgConfirmationSubmitArgs = {
  gateway: VaultFinancialProvider;
  orgData: VaultFormKybInput;
  primaryRepresentative: VaultRepresentativeCreateInput;
  representativeToReplace?: InputMaybe<VaultRepresentativeCreateInput>;
  stripeData?: InputMaybe<VaultFormStripeInput>;
  submittedOnYourBehalf: Scalars['Boolean'];
};


export type MutationVaultSetupIntentCreateArgs = {
  customerId?: InputMaybe<Scalars['String']>;
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};


export type MutationVaultStripeConnectAccountBankAccountDefaultSetArgs = {
  bankAccountId: Scalars['String'];
  stripeConnectAccountId: Scalars['String'];
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};


export type MutationVaultStripeConnectAccountBankAccountDeleteArgs = {
  bankAccountId: Scalars['String'];
  stripeConnectAccountId: Scalars['String'];
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};


export type MutationVaultStripeConnectAccountCreateArgs = {
  metadata?: InputMaybe<StripeMetadataInput>;
  statementDescriptor?: InputMaybe<Scalars['String']>;
  stripeEnv?: InputMaybe<VaultStripeEnv>;
  vaultKybId: Scalars['ID'];
};


export type MutationVaultStripeConnectAccountOwnersProvidedArgs = {
  ownersProvided: Scalars['Boolean'];
  stripeConnectAccountId: Scalars['String'];
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};


export type MutationVaultStripeConnectAccountPaymentMethodAttachArgs = {
  customerId?: InputMaybe<Scalars['String']>;
  paymentMethodId: Scalars['String'];
  stripeConnectAccountId: Scalars['String'];
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};


export type MutationVaultStripeConnectAccountPaymentMethodDefaultSetArgs = {
  customerId: Scalars['String'];
  paymentMethodId: Scalars['String'];
  stripeConnectAccountId: Scalars['String'];
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};


export type MutationVaultStripeConnectAccountPayoutFrequencySetArgs = {
  payoutInterval: VaultPayoutInterval;
  stripeConnectAccountId: Scalars['String'];
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};


export type MutationVaultStripeConnectAccountRemoveRepresentativeArgs = {
  stripeConnectAccountId: Scalars['String'];
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};


export type MutationVaultStripeConnectAccountUpdateArgs = {
  metadata?: InputMaybe<StripeMetadataInput>;
  statementDescriptor?: InputMaybe<Scalars['String']>;
  stripeConnectAccountId: Scalars['String'];
  stripeEnv?: InputMaybe<VaultStripeEnv>;
  vaultKybId: Scalars['ID'];
};


export type MutationVaultStripePersonCreateArgs = {
  stripeConnectAccountId: Scalars['String'];
  stripeEnv?: InputMaybe<VaultStripeEnv>;
  vaultKycId: Scalars['ID'];
};


export type MutationVaultStripePersonDeleteArgs = {
  stripeConnectAccountId: Scalars['String'];
  stripeEnv?: InputMaybe<VaultStripeEnv>;
  stripePersonId: Scalars['String'];
};


export type MutationVaultStripePersonUpdateArgs = {
  stripeConnectAccountId: Scalars['String'];
  stripeEnv?: InputMaybe<VaultStripeEnv>;
  stripePersonId: Scalars['String'];
  vaultKycId: Scalars['ID'];
};


export type MutationVaultUpdateVaultKybAutomatedArgs = {
  raiseFundraiserId: Scalars['Int'];
};


export type MutationVehicleCreateArgs = {
  input?: InputMaybe<CreateVehicleInput>;
};


export type MutationVehicleDeleteArgs = {
  input?: InputMaybe<DeleteVehicleInput>;
};


export type MutationVehicleModifyArgs = {
  input?: InputMaybe<ModifyVehicleInput>;
};


export type MutationWarningArgs = {
  payload: Scalars['JSON'];
  service: AuditLogService;
  source: AuditLogSource;
};


export type MutationWorkerDeleteManyArgs = {
  input?: InputMaybe<DeleteManyWorkerInput>;
};


export type MutationWorkerPoolCreateArgs = {
  input?: InputMaybe<CreateWorkerPool>;
};


export type MutationWorkerPoolDeleteArgs = {
  input?: InputMaybe<DeleteWorkerPoolInput>;
};


export type MutationWorkerPoolModifyArgs = {
  input?: InputMaybe<ModifyWorkerPoolInput>;
};


export type MutationWorkerUpsertManyArgs = {
  input?: InputMaybe<UpsertManyWorkersInput>;
};


export type MutationWorkersUpsertArgs = {
  input?: InputMaybe<Array<InputMaybe<UpsertWorkersInput>>>;
};

export type MutationError = {
  __typename?: 'MutationError';
  message: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type MutationResponse = {
  __typename?: 'MutationResponse';
  errors: Array<Maybe<MutationError>>;
  properties?: Maybe<OrgsProperties>;
  success: Scalars['Boolean'];
};

export type MyChannels = {
  __typename?: 'MyChannels';
  channels?: Maybe<Array<Maybe<Channel>>>;
  count?: Maybe<Scalars['Int']>;
};

/** NestedBoolFilter is used for nested filtering on boolean fields. */
export type NestedBoolFilter = {
  /** Checks if the boolean field is equal to the specified value. */
  equals?: InputMaybe<Scalars['Boolean']>;
  /** Nested filter for negating a condition. */
  not?: InputMaybe<NestedBoolFilter>;
};

export type NetSuiteEventInput = {
  deploymentId?: InputMaybe<Scalars['String']>;
  form?: InputMaybe<Scalars['String']>;
  newRecord?: InputMaybe<Scalars['JSON']>;
  oldRecord?: InputMaybe<Scalars['JSON']>;
  scriptId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['JSON']>;
};

export type NetSuiteWebhookResponse = {
  __typename?: 'NetSuiteWebhookResponse';
  error?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type NotAuthenticated = Error & {
  __typename?: 'NotAuthenticated';
  message: Scalars['String'];
};

export type NotAuthorized = Error & {
  __typename?: 'NotAuthorized';
  message: Scalars['String'];
};

export type Notice = {
  __typename?: 'Notice';
  body?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  isUrgent?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

export type NoticeCreateInput = {
  body?: InputMaybe<Scalars['String']>;
  isUrgent?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};

export type NoticeDeleteInput = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type NoticeModifyInput = {
  body?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  isUrgent?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};

export type Official = {
  __typename?: 'Official';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  duty?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  event?: Maybe<Event>;
  event_id?: Maybe<Scalars['Int']>;
  first_name?: Maybe<Scalars['String']>;
  home_phone?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  last_name?: Maybe<Scalars['String']>;
  offic_id?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  paid?: Maybe<Scalars['String']>;
  pay_code?: Maybe<Scalars['String']>;
  received?: Maybe<Scalars['String']>;
  salary?: Maybe<Scalars['Float']>;
  ssn?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  voucher_number?: Maybe<Scalars['String']>;
  work_phone?: Maybe<Scalars['String']>;
  worker_name?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type OfficialAssignmentsReturn = {
  __typename?: 'OfficialAssignmentsReturn';
  grand_total?: Maybe<Scalars['String']>;
  official_names?: Maybe<Array<Maybe<Scalars['String']>>>;
  officials?: Maybe<Array<Maybe<TransformedOfficialAssignment>>>;
  total?: Maybe<Array<Maybe<Total>>>;
};

export type OfficialByIdInput = {
  id: Scalars['Int'];
};

export type OfficialDuty = {
  __typename?: 'OfficialDuty';
  duty?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
};

export type OfficialPool = {
  __typename?: 'OfficialPool';
  Address?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  First?: Maybe<Scalars['String']>;
  Home_Phone?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  Last?: Maybe<Scalars['String']>;
  SSN?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
  Work_Phone?: Maybe<Scalars['String']>;
  Zip?: Maybe<Scalars['String']>;
  cell_phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  formattedSSN?: Maybe<Scalars['String']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  vendor_number?: Maybe<Scalars['String']>;
};

export type OfficialSchedules = {
  __typename?: 'OfficialSchedules';
  activity?: Maybe<Scalars['String']>;
  bus_count?: Maybe<Scalars['Int']>;
  bus_departure_location?: Maybe<Scalars['String']>;
  bus_early_dismissal_time?: Maybe<Scalars['String']>;
  bus_estimated_return_time?: Maybe<Scalars['String']>;
  bus_time?: Maybe<Scalars['String']>;
  cancellation_status?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['String']>;
  driver_name?: Maybe<Scalars['String']>;
  driver_phone?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['String']>;
  event_date?: Maybe<Scalars['String']>;
  facility?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  home_field?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  levels?: Maybe<Scalars['String']>;
  official_address?: Maybe<Scalars['String']>;
  official_address_city?: Maybe<Scalars['String']>;
  official_address_state?: Maybe<Scalars['String']>;
  official_address_zip?: Maybe<Scalars['String']>;
  official_duty?: Maybe<Scalars['String']>;
  official_email?: Maybe<Scalars['String']>;
  official_first_name?: Maybe<Scalars['String']>;
  official_home_phone?: Maybe<Scalars['String']>;
  official_id?: Maybe<Scalars['String']>;
  official_last_name?: Maybe<Scalars['String']>;
  official_paid?: Maybe<Scalars['String']>;
  official_received?: Maybe<Scalars['String']>;
  official_row_id?: Maybe<Scalars['String']>;
  official_salary?: Maybe<Scalars['String']>;
  official_work_phone?: Maybe<Scalars['String']>;
  opponent?: Maybe<Scalars['String']>;
  place?: Maybe<Scalars['String']>;
  season_id?: Maybe<Scalars['Int']>;
  sports_code?: Maybe<Scalars['String']>;
  sports_description?: Maybe<Scalars['String']>;
  sports_group?: Maybe<Scalars['String']>;
  sports_name?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['String']>;
  vehicle_id?: Maybe<Scalars['String']>;
  vehicle_type?: Maybe<Scalars['String']>;
};

export type OnException = {
  __typename?: 'OnException';
  exceptionCode?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type Opponent = {
  __typename?: 'Opponent';
  Address?: Maybe<Scalars['String']>;
  Phone?: Maybe<Scalars['String']>;
  SchoolCode?: Maybe<Scalars['String']>;
  SchoolName?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
  Zip?: Maybe<Scalars['String']>;
  ad_name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  nces_id?: Maybe<Scalars['String']>;
  non_school?: Maybe<Scalars['Boolean']>;
};

export type OrderFilterInput = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderBySort?: InputMaybe<Scalars['String']>;
  scopeId: Scalars['Int'];
  searchBy?: InputMaybe<Scalars['String']>;
  searchValue?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type OrderGraphInput = {
  interval?: InputMaybe<Scalars['Int']>;
  scopeId: Scalars['Int'];
};

export type OrderInput = {
  carrier: ShippingProvider;
  city: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  netsuiteId: Scalars['String'];
  packingSlipId: Scalars['String'];
  packingSlipTitle: Scalars['String'];
  products: Array<OrderProductInput>;
  scheduleAt?: InputMaybe<Scalars['String']>;
  shipTo: Scalars['String'];
  state: Scalars['String'];
  street: Scalars['String'];
  update?: InputMaybe<Scalars['Boolean']>;
  vendor: Vendor;
  zipCode: Scalars['String'];
};

export type OrderItem = {
  __typename?: 'OrderItem';
  itemId?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['Int']>;
  parentItemId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Int']>;
  productName?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  sku?: Maybe<Scalars['String']>;
};

export type OrderProductInput = {
  logo: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  netsuiteId: Scalars['String'];
  receiverName: Scalars['String'];
  sku?: InputMaybe<Scalars['String']>;
};

export type OrderResult = {
  __typename?: 'OrderResult';
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<OrderStatus>;
};

export enum OrderStatus {
  Failure = 'FAILURE',
  Success = 'SUCCESS'
}

export enum OrderType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type OrdersFilter = {
  __typename?: 'OrdersFilter';
  orders?: Maybe<Array<Maybe<MagentoOrder>>>;
  pagination?: Maybe<SdPagination>;
};

export type OrdersFilterUnionType = OnException | OrdersFilter;

export type OrdersSummary = {
  __typename?: 'OrdersSummary';
  baseSales?: Maybe<Scalars['Float']>;
  complete?: Maybe<Scalars['BigInt']>;
  discountAmount?: Maybe<Scalars['Float']>;
  processing?: Maybe<Scalars['BigInt']>;
  sales?: Maybe<Scalars['Float']>;
  scopeId?: Maybe<Scalars['BigInt']>;
  shipped?: Maybe<Scalars['BigInt']>;
};

export type OrdersSummaryUnionType = OnException | OrdersSummary;

export type Org = {
  __typename?: 'Org';
  createdAt?: Maybe<Scalars['DateTime']>;
  fields?: Maybe<Scalars['JSONObject']>;
  financialAccounts?: Maybe<Array<FinancialAccount>>;
  id: Scalars['ID'];
  lastMigrated?: Maybe<Scalars['DateTime']>;
  /** @deprecated Please use lastMigrated instead */
  migrated?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  parent?: Maybe<Org>;
  /** @deprecated Please use parent.id instead */
  parentId?: Maybe<Scalars['String']>;
  stores?: Maybe<Array<Store>>;
  type: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type OrgFieldsArgs = {
  names?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type OrgAffiliation = {
  __typename?: 'OrgAffiliation';
  affiliations: Array<UserAffiliation>;
  org: Org;
};

export type OrgCampaignAffiliation = {
  __typename?: 'OrgCampaignAffiliation';
  campaigns: Array<Org>;
  org: Org;
};

export type OrgCampaignIds = {
  __typename?: 'OrgCampaignIds';
  campaignIds: Array<Scalars['ID']>;
  legacyRaiseIds: Array<Scalars['Int']>;
  role?: Maybe<Scalars['ID']>;
  rootOrgId: Scalars['ID'];
};

export type OrgInviteArguments = {
  orgId: Scalars['String'];
  title: Scalars['String'];
};

export type OrgStaffMember = {
  __typename?: 'OrgStaffMember';
  orgAffiliations: Array<UserAffiliation>;
  orgId: Scalars['ID'];
  programsLed: Array<ProgramWithTitle>;
  teamsLed: Array<TeamWithTitle>;
  user: UserNode;
};

export enum OrgType {
  Booster = 'BOOSTER',
  Business = 'BUSINESS',
  Club = 'CLUB',
  District = 'DISTRICT',
  Foundation = 'FOUNDATION',
  Program = 'PROGRAM',
  Pta = 'PTA',
  School = 'SCHOOL',
  Shellschool = 'SHELLSCHOOL',
  Team = 'TEAM'
}

export type Organization = {
  __typename?: 'Organization';
  address?: Maybe<Scalars['String']>;
  altName?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  inviteEmail?: Maybe<Scalars['String']>;
  last4Tin?: Maybe<Scalars['String']>;
  mailingAddress?: Maybe<FinancialAddress>;
  modId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nces?: Maybe<Scalars['String']>;
  physicalAddress?: Maybe<FinancialAddress>;
  region?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export enum OrganizationFilterEnum {
  Pending = 'pending'
}

export type OrganizationSearchParams = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

export type OrgsProperties = {
  __typename?: 'OrgsProperties';
  acctId?: Maybe<Scalars['ID']>;
  applicationId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['ID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  finAcctId?: Maybe<Scalars['ID']>;
  firstSeen?: Maybe<Scalars['DateTime']>;
  internal?: Maybe<Scalars['Boolean']>;
  isBeneficialOwner?: Maybe<Scalars['Boolean']>;
  isContact?: Maybe<Scalars['Boolean']>;
  isPrincipal?: Maybe<Scalars['Boolean']>;
  isRepresentative?: Maybe<Scalars['Boolean']>;
  kybId?: Maybe<Scalars['ID']>;
  kycId?: Maybe<Scalars['ID']>;
  lastMigrated?: Maybe<Scalars['DateTime']>;
  lastSeen?: Maybe<Scalars['DateTime']>;
  /** @deprecated Please use lastMigrated instead */
  migrated?: Maybe<Scalars['DateTime']>;
  orgId?: Maybe<Scalars['ID']>;
  parentId?: Maybe<Scalars['ID']>;
  personId?: Maybe<Scalars['ID']>;
  processor?: Maybe<Processor>;
  programId?: Maybe<Scalars['ID']>;
  roleId?: Maybe<Scalars['ID']>;
  status?: Maybe<FinAcctStatus>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['ID']>;
};

export type OrgsSearchPaginationInput = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type OrgsSearchResponse = {
  __typename?: 'OrgsSearchResponse';
  hasNext?: Maybe<Scalars['Boolean']>;
  hasPrevious?: Maybe<Scalars['Boolean']>;
  orgs?: Maybe<Array<Maybe<Org>>>;
  total?: Maybe<Scalars['Int']>;
};

export type OrgsSearchWhereInput = {
  /** List of organization ids to filter from. */
  ids?: InputMaybe<Array<Scalars['ID']>>;
  /** The string to search in name of organization. */
  nameIncludes?: InputMaybe<Scalars['String']>;
  /** Organization type. Valid values are "Booster" | "Club" | "District" | "Program" | "School". */
  orgTypes?: InputMaybe<Array<OrgType>>;
  /** Allow to search any properties via JSON query. The key will auto convert to snake_case. */
  properties?: InputMaybe<Scalars['JSON']>;
};

export type OtkFundraiserProduct = {
  __typename?: 'OtkFundraiserProduct';
  /** This otk item image will include the fundraiser logo. */
  dynamicImage?: Maybe<Scalars['String']>;
  incentives?: Maybe<OtkProduct>;
};

export type OtkParticipantData = {
  __typename?: 'OtkParticipantData';
  id: Scalars['ID'];
  incentives?: Maybe<OtkProduct>;
  size?: Maybe<Scalars['String']>;
};

export type OtkProduct = {
  __typename?: 'OtkProduct';
  description?: Maybe<Scalars['String']>;
  fitting?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** This is the base image for the otk item. It will not include the fundraiser logo. */
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  priceCents?: Maybe<Scalars['Int']>;
  productType?: Maybe<Scalars['String']>;
  sizes?: Maybe<Scalars['String']>;
};

export enum Prefund_Status {
  Active = 'Active',
  Closed = 'Closed',
  Pending = 'Pending'
}

export enum PagesNameEnum {
  AboutUs = 'AboutUs',
  Mission = 'Mission',
  Recruitment = 'Recruitment',
  SchoolDirections = 'SchoolDirections',
  Sponsors = 'Sponsors',
  Staff = 'Staff'
}

export type Pagination = {
  __typename?: 'Pagination';
  currentPage: Scalars['Int'];
  itemCount: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type PaginationInfo = {
  __typename?: 'PaginationInfo';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  totalItems: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type ParentInviteInput = {
  email?: InputMaybe<Scalars['String']>;
  joincode: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type Participant = {
  __typename?: 'Participant';
  campaignId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastLogin?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

/** See CampaignMembership for details */
export type ParticipantCampaign = {
  __typename?: 'ParticipantCampaign';
  areDonationInviteEmailsReleased: Scalars['Boolean'];
  basicStatus: BasicCampaignStatus;
  campaignDates?: Maybe<CampaignDates>;
  campaignGoal?: Maybe<Scalars['Int']>;
  donationsRaised?: Maybe<ParticipantDonationsRaised>;
  /** Getting donor email addresses adds time to your query */
  donorEmailData?: Maybe<Array<Maybe<DonorEmailData>>>;
  donorPersonListEntries?: Maybe<Array<Maybe<DonorPersonListEntry>>>;
  /** Getting donor phone numbers adds time to your query */
  donorPhoneNumbers?: Maybe<Array<Maybe<DonationInviteSmsData>>>;
  fundraiserRewardLevelsCount?: Maybe<FundraiserRewardLevelsCount>;
  galleryItems?: Maybe<GalleryItems>;
  group?: Maybe<ParticipantGroup>;
  hasCustomRewards?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isCoparticipant?: Maybe<Scalars['Boolean']>;
  isGuardianLedCampaign: Scalars['Boolean'];
  isOTKEnabled?: Maybe<Scalars['Boolean']>;
  isRewardsEnabled?: Maybe<Scalars['Boolean']>;
  isTopEarner: Scalars['Boolean'];
  joinCode?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  newStack_participantFundraiserLink?: Maybe<Scalars['String']>;
  otk?: Maybe<Array<Maybe<OtkParticipantData>>>;
  participantGoal?: Maybe<Scalars['Int']>;
  participantGuardianEmails?: Maybe<ParticipantGuardianEmail>;
  participantRewardsData?: Maybe<Array<Maybe<ParticipantRewardsData>>>;
  primaryColor: Scalars['String'];
  raiseUserId?: Maybe<Scalars['Int']>;
  raiseUserJoinedAt?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  /** Datetime-ISO format for sms invites released */
  smsInvitesReleasedAt?: Maybe<Scalars['String']>;
  status: CampaignStatus;
  userFitting?: Maybe<UserFitting>;
};

export type ParticipantCampaignConfigInput = {
  autoImportEmails?: InputMaybe<ParticipantCampaignConfigStates>;
  autoImportTexts?: InputMaybe<ParticipantCampaignConfigStates>;
  giftShop?: InputMaybe<ParticipantCampaignConfigStates>;
  guardianSetup?: InputMaybe<ParticipantCampaignConfigStates>;
  profileSetup?: InputMaybe<ParticipantCampaignConfigStates>;
  rewards?: InputMaybe<ParticipantCampaignConfigStates>;
};

export enum ParticipantCampaignConfigStates {
  Completed = 'COMPLETED',
  NotApplicable = 'NOT_APPLICABLE',
  NotCompleted = 'NOT_COMPLETED',
  Skipped = 'SKIPPED'
}

export type ParticipantCheer = {
  __typename?: 'ParticipantCheer';
  anonymous?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  donorMessage?: Maybe<Scalars['String']>;
  donorName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastName?: Maybe<Scalars['String']>;
  subtotalCents?: Maybe<Scalars['Int']>;
};

export type ParticipantCheerwall = {
  __typename?: 'ParticipantCheerwall';
  cheers?: Maybe<Array<Maybe<ParticipantCheer>>>;
  count?: Maybe<Scalars['Int']>;
};

export type ParticipantData = {
  __typename?: 'ParticipantData';
  data?: Maybe<User>;
  raiseId?: Maybe<Scalars['Int']>;
};

export type ParticipantDonationsRaised = {
  __typename?: 'ParticipantDonationsRaised';
  numberOfDonations: Scalars['Int'];
  subtotalCents: Scalars['Int'];
};

/** This is just a Fundraiser, but specifically for a participant */
export type ParticipantFundraiser = {
  __typename?: 'ParticipantFundraiser';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  raiseId?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
};

export type ParticipantFundraiserUser = {
  __typename?: 'ParticipantFundraiserUser';
  id?: Maybe<Scalars['ID']>;
};

export type ParticipantGroup = {
  __typename?: 'ParticipantGroup';
  id?: Maybe<Scalars['ID']>;
  label?: Maybe<Scalars['String']>;
};

export type ParticipantGuardianDonorEntry = {
  __typename?: 'ParticipantGuardianDonorEntry';
  email?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  fundraiserId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

export type ParticipantGuardianEmail = {
  __typename?: 'ParticipantGuardianEmail';
  guardianEmail?: Maybe<Scalars['String']>;
  secondaryGuardianEmail?: Maybe<Scalars['String']>;
};

export type ParticipantGuardianEmailUpdate = {
  __typename?: 'ParticipantGuardianEmailUpdate';
  id?: Maybe<Scalars['Int']>;
};

export type ParticipantGuardianFundraiser = {
  __typename?: 'ParticipantGuardianFundraiser';
  id: Scalars['ID'];
  logo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  participants?: Maybe<Array<Maybe<ParticipantUser>>>;
  slug: Scalars['String'];
  status: BasicCampaignStatus;
};

export type ParticipantGuardianReturn = {
  __typename?: 'ParticipantGuardianReturn';
  entry?: Maybe<Array<ParticipantGuardianDonorEntry>>;
};

export type ParticipantList = {
  __typename?: 'ParticipantList';
  count?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
  list: Array<Maybe<Participant>>;
};

export type ParticipantPublic = {
  __typename?: 'ParticipantPublic';
  firstName?: Maybe<Scalars['String']>;
  /** User directory id */
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
};

/** A 'tremendous' reward. The presence means that a reward has been sent to a participant */
export type ParticipantReward = {
  __typename?: 'ParticipantReward';
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<RewardStatus>;
};

export type ParticipantRewardsData = {
  __typename?: 'ParticipantRewardsData';
  id?: Maybe<Scalars['Int']>;
  incentives?: Maybe<FundraiserRewardsProduct>;
  size?: Maybe<Scalars['String']>;
  tier?: Maybe<Scalars['Int']>;
};

export type ParticipantRewardsInput = {
  incentiveId: Scalars['Int'];
  size: Scalars['String'];
  tier: Scalars['Int'];
};

export type ParticipantSsoid = {
  __typename?: 'ParticipantSSOID';
  userDirectoryId?: Maybe<Scalars['String']>;
};

export type ParticipantTextTemplate = {
  __typename?: 'ParticipantTextTemplate';
  guardianTemplate?: Maybe<Scalars['String']>;
  participantTemplate?: Maybe<Scalars['String']>;
};

export type ParticipantTopDonation = {
  __typename?: 'ParticipantTopDonation';
  donorName?: Maybe<Scalars['String']>;
  subtotalCents?: Maybe<Scalars['Int']>;
};

export type ParticipantUser = {
  __typename?: 'ParticipantUser';
  fundraiserUserId: Scalars['Int'];
  user: User;
};

export type ParticipantsInfo = {
  __typename?: 'ParticipantsInfo';
  count?: Maybe<Scalars['Int']>;
  loggedInCount?: Maybe<Scalars['Int']>;
  withAtLeastOneDonation?: Maybe<Scalars['Int']>;
  withAtLeastTwentyInvites?: Maybe<Scalars['Int']>;
};

export enum PastDueSortOptions {
  Amount = 'amount',
  DueDate = 'dueDate',
  Groups = 'groups',
  ParentStatus = 'parentStatus',
  ParticipantName = 'participantName'
}

export type PayableInfo = {
  __typename?: 'PayableInfo';
  campaignId?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  ein?: Maybe<Scalars['Int']>;
  fullAddressOne?: Maybe<Scalars['String']>;
  fullAddressTwo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  payableName?: Maybe<Scalars['String']>;
  payableType?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  scopeId?: Maybe<Scalars['Int']>;
  street?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['Int']>;
};

export type Payee = {
  __typename?: 'Payee';
  address: PayeeAddress;
  name: Scalars['String'];
};

export type PayeeAddress = {
  __typename?: 'PayeeAddress';
  apartment?: Maybe<Scalars['String']>;
  attention?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  street: Scalars['String'];
  zip: Scalars['String'];
};

export enum PaymentProvider {
  Paypal = 'PAYPAL',
  Stripe = 'STRIPE'
}

export type PaymentsApiCreateRefundResponse = {
  __typename?: 'PaymentsApiCreateRefundResponse';
  applicationFeeRefundAmount?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  reason?: Maybe<Scalars['String']>;
  refundApplicationFee?: Maybe<Scalars['Boolean']>;
  transactionId?: Maybe<Scalars['String']>;
};

export type PaymentsApiCustomerInput = {
  email: Scalars['String'];
  stripeEnv?: InputMaybe<StripeEnv>;
};

export type PaymentsApiCustomerPaymentMethod = {
  __typename?: 'PaymentsApiCustomerPaymentMethod';
  billingAddress?: Maybe<PaymentsApiPmAddress>;
  brand?: Maybe<Scalars['String']>;
  expiration?: Maybe<PaymentsApiPmExpiration>;
  id: Scalars['String'];
  identifier: Scalars['String'];
  type: PaymentsApiCustomerPaymentMethodType;
  zipCode?: Maybe<Scalars['String']>;
};

export enum PaymentsApiCustomerPaymentMethodType {
  Bank = 'BANK',
  Card = 'CARD',
  Other = 'OTHER'
}

export type PaymentsApiCustomerResponse = {
  __typename?: 'PaymentsApiCustomerResponse';
  customerId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  paymentMethods: Array<Maybe<PaymentsApiCustomerPaymentMethod>>;
};

export type PaymentsApiDetachPaymentMethodInput = {
  paymentMethodId: Scalars['String'];
  stripeEnv?: InputMaybe<StripeEnv>;
};

export type PaymentsApiDetachPaymentMethodResponse = {
  __typename?: 'PaymentsApiDetachPaymentMethodResponse';
  paymentMethodId: Scalars['String'];
  stripeEnv: StripeEnv;
};

export type PaymentsApiPmAddress = {
  __typename?: 'PaymentsApiPMAddress';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type PaymentsApiPmExpiration = {
  __typename?: 'PaymentsApiPMExpiration';
  month: Scalars['Int'];
  year: Scalars['Int'];
};

export type PaymentsApiPaymentIput = {
  amount: Scalars['Int'];
  description?: InputMaybe<Scalars['String']>;
  finalDestination: Scalars['String'];
  idempotencyKey?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['JSONObject']>;
  paymentMethodId: Scalars['String'];
  snapAmount?: InputMaybe<Scalars['Int']>;
  snapId: Scalars['String'];
  stripeEnv?: InputMaybe<StripeEnv>;
};

export type PaymentsApiPaymentResponse = {
  __typename?: 'PaymentsApiPaymentResponse';
  amount: Scalars['Int'];
  id: Scalars['String'];
  paymentMethodId: Scalars['String'];
  snapAmount: Scalars['Int'];
  snapId: Scalars['String'];
  stripeEnv: StripeEnv;
};

export type PaymentsApiRefundInput = {
  amount?: InputMaybe<Scalars['Int']>;
  idempotencyKey?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['JSONObject']>;
  refundApplicationFee?: InputMaybe<Scalars['Boolean']>;
  snapAmount?: InputMaybe<Scalars['Int']>;
  stripeEnv?: InputMaybe<StripeEnv>;
  transactionId: Scalars['String'];
};

export type Payout = Check | Deposit;

export type Permission = {
  __typename?: 'Permission';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type PhoneNumberChallengeConfirmation = Tokens & {
  __typename?: 'PhoneNumberChallengeConfirmation';
  accessToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
};

export type PointActivityFilter = {
  __typename?: 'PointActivityFilter';
  activities?: Maybe<Array<Maybe<MagentoPointActivity>>>;
  pagination?: Maybe<SdPagination>;
};

export type PointActivityFilterUnionType = OnException | PointActivityFilter;

export type PointsActivityFilterInput = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderBySort?: InputMaybe<Scalars['String']>;
  scopeId: Scalars['Int'];
  searchBy?: InputMaybe<Scalars['String']>;
  searchValue?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type PostponeUnpostponeResponse = {
  __typename?: 'PostponeUnpostponeResponse';
  affected_events?: Maybe<Scalars['Int']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type PotentialCustomersDeals = {
  __typename?: 'PotentialCustomersDeals';
  activity?: Maybe<Scalars['String']>;
  campaignsCount?: Maybe<Scalars['Int']>;
  curricularType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type Prefund = {
  __typename?: 'Prefund';
  card?: Maybe<Card>;
  cardType?: Maybe<Card_Type>;
  createdAt?: Maybe<Scalars['String']>;
  fundraiser: Fundraiser;
  id: Scalars['ID'];
  organization: Organization;
  prefundAmount: Scalars['Int'];
  shippingService?: Maybe<Shipping_Service>;
  status: Prefund_Status;
};

export type PrefundList = {
  __typename?: 'PrefundList';
  list: Array<Prefund>;
  pageInfo?: Maybe<PaginationInfo>;
};

export type PrefundSearchParams = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Array<InputMaybe<Prefund_Status>>>;
};

/** Information of a emails scheduled for release */
export type PreloadEmailSchedule = {
  __typename?: 'PreloadEmailSchedule';
  /** The date and time for release */
  dateTime?: Maybe<Scalars['DateTime']>;
  /** The campaign id */
  fundraiserId?: Maybe<Scalars['Int']>;
  /** The timezone for scheduled release */
  timezone?: Maybe<Scalars['String']>;
};

/** Information of premade email templates */
export type PremadeContactTemplate = {
  __typename?: 'PremadeContactTemplate';
  /** The message of the template. Plain text only */
  message?: Maybe<Scalars['String']>;
  /** The name of the template */
  name?: Maybe<Scalars['String']>;
  /** The subject of the template */
  subject?: Maybe<Scalars['String']>;
  templateMedium?: Maybe<ContactTemplateMedium>;
  /** The type of template */
  templateType?: Maybe<ContactTemplateType>;
};

export type Preparation = {
  __typename?: 'Preparation';
  duty?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  is_deleted?: Maybe<Scalars['Boolean']>;
  qty?: Maybe<Scalars['String']>;
};

export type PreparationByIdInput = {
  id: Scalars['Int'];
};

export type PreparationReport = {
  __typename?: 'PreparationReport';
  comments?: Maybe<Scalars['String']>;
  event?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  prep?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['String']>;
};

export type PreparationSheet = {
  __typename?: 'PreparationSheet';
  activity?: Maybe<Scalars['String']>;
  bus_count?: Maybe<Scalars['Int']>;
  comments?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['String']>;
  driver_name?: Maybe<Scalars['String']>;
  driver_phone?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['String']>;
  event_date?: Maybe<Scalars['String']>;
  facility?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['Float']>;
  g_s?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  home_field?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  levels?: Maybe<Scalars['String']>;
  place?: Maybe<Scalars['String']>;
  preparations?: Maybe<Array<Maybe<PreparationReport>>>;
  season_id?: Maybe<Scalars['Int']>;
  sports_code?: Maybe<Scalars['String']>;
  sports_description?: Maybe<Scalars['String']>;
  sports_group?: Maybe<Scalars['String']>;
  sports_name?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['String']>;
  vehicle_id?: Maybe<Scalars['String']>;
  vehicle_type?: Maybe<Scalars['String']>;
};

/** Primary Group Leader The primary group leader is the main contact for a fundraiser The association is not direct with a user but made through email */
export type PrimaryGroupLeader = {
  __typename?: 'PrimaryGroupLeader';
  /** Signifies user is primary group leader. Extra query. */
  currentUserIsPrimary: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export enum Processor {
  Stripe = 'STRIPE',
  Unit = 'UNIT'
}

export enum Product {
  Connect = 'CONNECT',
  Insights = 'INSIGHTS',
  Manage = 'MANAGE',
  Raise = 'RAISE',
  Spend = 'SPEND',
  Sponsor = 'SPONSOR',
  Store = 'STORE'
}

export type ProductInput = {
  logoPosition: LogoPosition;
  name: Scalars['String'];
  netsuiteId?: InputMaybe<Scalars['String']>;
  size: ProductSize;
  sku: Scalars['String'];
};

export type ProductResult = {
  __typename?: 'ProductResult';
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['String']>;
};

export enum ProductSize {
  L = 'L',
  M = 'M',
  NoSize = 'NO_SIZE',
  Osfa = 'OSFA',
  S = 'S',
  Xl = 'XL',
  Xs = 'XS',
  Xxl = 'XXL',
  Xxxl = 'XXXL',
  Xxxxl = 'XXXXL',
  Yl = 'YL',
  Ym = 'YM',
  Ys = 'YS',
  Yxl = 'YXL',
  Yxs = 'YXS',
  Yxxl = 'YXXL',
  Yxxxl = 'YXXXL'
}

export enum ProductStatus {
  Failure = 'FAILURE',
  Success = 'SUCCESS'
}

export type ProfitPercentage = {
  __typename?: 'ProfitPercentage';
  points_percentage?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  updated_product_price_percentage?: Maybe<Scalars['String']>;
};

export enum ProgramActivity {
  ActionSports = 'ACTION_SPORTS',
  Archery = 'ARCHERY',
  Art = 'ART',
  Asb = 'ASB',
  Avid = 'AVID',
  Badminton = 'BADMINTON',
  Band = 'BAND',
  Baseball = 'BASEBALL',
  Basketball = 'BASKETBALL',
  Bowling = 'BOWLING',
  Boxing = 'BOXING',
  Business = 'BUSINESS',
  Cheerleading = 'CHEERLEADING',
  Chess = 'CHESS',
  Choir = 'CHOIR',
  Crew = 'CREW',
  CrossCountry = 'CROSS_COUNTRY',
  CultureAndLanguage = 'CULTURE_AND_LANGUAGE',
  Cycling = 'CYCLING',
  Dance = 'DANCE',
  Debate = 'DEBATE',
  Deca = 'DECA',
  Drama = 'DRAMA',
  Equestrian = 'EQUESTRIAN',
  Fashion = 'FASHION',
  Fbla = 'FBLA',
  Fccla = 'FCCLA',
  Fencing = 'FENCING',
  FieldHockey = 'FIELD_HOCKEY',
  FilmAndTvProduction = 'FILM_AND_TV_PRODUCTION',
  Football = 'FOOTBALL',
  FreshmanClass = 'FRESHMAN_CLASS',
  Golf = 'GOLF',
  GsaAndLgbtqi = 'GSA_AND_LGBTQI',
  Gymnastics = 'GYMNASTICS',
  Hockey = 'HOCKEY',
  Hosa = 'HOSA',
  Journalism = 'JOURNALISM',
  JuniorClass = 'JUNIOR_CLASS',
  KeyAndLink = 'KEY_AND_LINK',
  Lacrosse = 'LACROSSE',
  MartialArts = 'MARTIAL_ARTS',
  ModelUnAndTrial = 'MODEL_UN_AND_TRIAL',
  Music = 'MUSIC',
  NationalHonorsSociety = 'NATIONAL_HONORS_SOCIETY',
  NonProfit = 'NON_PROFIT',
  OrchestraAndSymphony = 'ORCHESTRA_AND_SYMPHONY',
  Other = 'OTHER',
  Outdoors = 'OUTDOORS',
  Photography = 'PHOTOGRAPHY',
  Polo = 'POLO',
  Powerlifting = 'POWERLIFTING',
  PromAndHomecoming = 'PROM_AND_HOMECOMING',
  Robotics = 'ROBOTICS',
  Rotc = 'ROTC',
  Rugby = 'RUGBY',
  Sailing = 'SAILING',
  Scholarship = 'SCHOLARSHIP',
  SeniorClass = 'SENIOR_CLASS',
  Shooting = 'SHOOTING',
  Skiing = 'SKIING',
  Soccer = 'SOCCER',
  Softball = 'SOFTBALL',
  SophomoreClass = 'SOPHOMORE_CLASS',
  Stem = 'STEM',
  StudentGovernment = 'STUDENT_GOVERNMENT',
  Surf = 'SURF',
  SwimAndDive = 'SWIM_AND_DIVE',
  TableTennis = 'TABLE_TENNIS',
  Tennis = 'TENNIS',
  TrackAndField = 'TRACK_AND_FIELD',
  UltimateFrisbee = 'ULTIMATE_FRISBEE',
  VideoGame = 'VIDEO_GAME',
  Volleyball = 'VOLLEYBALL',
  WaterPolo = 'WATER_POLO',
  WinterSports = 'WINTER_SPORTS',
  Wrestling = 'WRESTLING',
  Writing = 'WRITING',
  Yearbook = 'YEARBOOK'
}

export type ProgramForEventOld = {
  __typename?: 'ProgramForEventOld';
  /** The gender of the activity or sport. */
  gender?: Maybe<Scalars['String']>;
  /** The sport code associated with the event. */
  groupVal?: Maybe<Scalars['String']>;
  /** The level of the activity or sport. */
  level?: Maybe<Scalars['String']>;
  /** The season ID associated with the event. */
  seasonId?: Maybe<Scalars['Int']>;
  /** The sport code associated with the event. */
  sportCode?: Maybe<Scalars['String']>;
  /** The sport or activity associated with the event. */
  sportName?: Maybe<Scalars['String']>;
  /** The season year associated with the event. */
  year?: Maybe<Scalars['String']>;
};

export type ProgramLeadership = {
  __typename?: 'ProgramLeadership';
  org: Org;
  programs: Array<ProgramWithTitle>;
};

export type ProgramWithTitle = {
  __typename?: 'ProgramWithTitle';
  program: Org;
  titles: Array<Leader>;
};

export type Providers = {
  email?: InputMaybe<SupportedEmailProviders>;
  sms?: InputMaybe<SupportedSmsProviders>;
};

export type PublicFundraiserData = {
  __typename?: 'PublicFundraiserData';
  alternateColor?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  donationMinDollars?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['DateTime']>;
  fanStore?: Maybe<Scalars['Boolean']>;
  goal?: Maybe<Scalars['Int']>;
  hasCustomRewards?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  incStore?: Maybe<Scalars['Boolean']>;
  isGuardianLedCampaign?: Maybe<Scalars['Boolean']>;
  joinCode?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  participantGoal?: Maybe<Scalars['Int']>;
  personalMessage?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  programLeader?: Maybe<Scalars['String']>;
  programType?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  whyDonations?: Maybe<Scalars['String']>;
};

export type PublicGroup = {
  __typename?: 'PublicGroup';
  id: Scalars['String'];
  name: Scalars['String'];
  seasons?: Maybe<Array<PublicSeason>>;
};

export type PublicGroupResponse = {
  __typename?: 'PublicGroupResponse';
  groups: Array<PublicGroup>;
};

export type PublicSeason = {
  __typename?: 'PublicSeason';
  id: Scalars['String'];
  isLinkEnabled: Scalars['Boolean'];
  name: Scalars['String'];
  paymentScheduleStatus: Scalars['Boolean'];
};

export enum PurchaseIntentStatus {
  Cart = 'CART',
  Purchased = 'PURCHASED',
  Refunded = 'REFUNDED',
  Removed = 'REMOVED',
  Supermarket = 'SUPERMARKET'
}

export type PurchaseItemInput = {
  costCents: Scalars['Int'];
  image: Scalars['String'];
  incentiveId: Scalars['Int'];
  name: Scalars['String'];
  netEarnedCents: Scalars['Float'];
  priceCents: Scalars['Int'];
  size: Scalars['String'];
};

export type PurchaseParticipantUpdateData = {
  fundraiserUserId: Scalars['Int'];
  participantUserDirectoryId: Scalars['String'];
};

export enum PurchaseStatus {
  Cancelled = 'CANCELLED',
  Failed = 'FAILED',
  Initiated = 'INITIATED',
  Processing = 'PROCESSING',
  Refunded = 'REFUNDED',
  Refunding = 'REFUNDING',
  Succeeded = 'SUCCEEDED'
}

export type PurchaseUpdateInput = {
  /** Update donor full name */
  donorName?: InputMaybe<Scalars['String']>;
  /** Update purchased gift shop item sizes */
  lineItems?: InputMaybe<Array<GiftShopPurchaseItemUpdate>>;
  /** Update with different Participant Data */
  participant?: InputMaybe<PurchaseParticipantUpdateData>;
};

export type Query = {
  __typename?: 'Query';
  accountTransactions: Array<Maybe<Transaction>>;
  apiReady?: Maybe<Scalars['Boolean']>;
  auditLogs?: Maybe<AuditLogList>;
  busSchedules?: Maybe<Array<Maybe<BusSchedules>>>;
  /** Return startDateTime and endDateTime as formatted UTC strings */
  campaignDates?: Maybe<CampaignDates>;
  campaignHistoryListGet?: Maybe<Array<Maybe<CampaignHistoryList>>>;
  campaignKyc?: Maybe<CampaignKyc>;
  /** Query uses Users JWT to find their Memberships See CampaignMembership type for details */
  campaignMemberships: Array<CampaignMembership>;
  /** List of saved search filters for campaigns of current user. */
  campaignSearchFiltersGet?: Maybe<Array<Maybe<CampaignSearchFilter>>>;
  /** List campaigns based on logged-in user */
  campaigns?: Maybe<CampaignList>;
  /** Retrieve contact details */
  commsContact?: Maybe<Array<CommsContactResponse>>;
  /** Retrieve Content */
  commsHtml?: Maybe<CommsHtmlResponse>;
  /** Retrieve message details */
  commsMessage: CommsMessageResponse;
  /** Retrieve template details */
  commsTemplate?: Maybe<CommsTemplateResponse>;
  /** Retrieve all templates for a service */
  commsTemplates?: Maybe<Array<CommsRegisterResponse>>;
  competitionList?: Maybe<Array<Maybe<TransformedEventReturn>>>;
  consumers?: Maybe<Array<Maybe<Consumer>>>;
  currentPrefunds?: Maybe<PrefundList>;
  currentWalletOrganization?: Maybe<Organization>;
  /** List of contact templates based on current user */
  customContactTemplatesGet?: Maybe<Array<Maybe<CustomContactTemplates>>>;
  dailyCalendarBusSchedules?: Maybe<TransformedDailyCalendarBusSchedule>;
  dailyCalendarEvents?: Maybe<Array<Maybe<TransformedDailyCalendarEventReturn>>>;
  dailyCalendarOfficials?: Maybe<TransformedDailyCalendarOfficials>;
  dailyCalendarPreparations?: Maybe<TransformedDailyCalendarPreparation>;
  dailyCalendarWorkers?: Maybe<TransformedDailyCalendarWorkers>;
  daySchedules?: Maybe<Array<Maybe<CommonSchedules>>>;
  donationLevels: Array<DonorsDonationLevel>;
  donationLevelsByFundraiserId: Array<DonationLevel>;
  /** @deprecated This can be queried for specific personas via CampaignMemberships. */
  donationTopEarner?: Maybe<DonationTopEarner>;
  donationsQuery?: Maybe<DonationsResponse>;
  donorDonateIntent?: Maybe<DonateIntent>;
  donorFundraiserData: DonorFundraiserData;
  donorPersonListEntries?: Maybe<Array<Maybe<DonorPersonListEntry>>>;
  /** @deprecated Use `donorsCheerwalls` query instead */
  donorsCheerwall?: Maybe<DonorsFundraiserCheerWall>;
  donorsCheerwalls?: Maybe<DonorsCheerwallsResult>;
  donorsDonation?: Maybe<DonorDonation>;
  donorsFundraiserPayables?: Maybe<Array<Maybe<DonorFundraiserPayable>>>;
  donorsGiftShopIntent: GiftShopPurchaseIntentData;
  donorsGiftShopPurchase?: Maybe<DonorFundraiserPurchase>;
  donorsParticipantOTKIncentives?: Maybe<DonorsParticipantOtkIncentives>;
  donorsParticipantsGiftShop?: Maybe<DonorsParticipantsGiftShop>;
  /** List all activity types of campaigns. Used by Drive */
  driveActivityTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Get details of campaigns. This endpoint need to considered to combined with searchDriveCampaign endpoint */
  driveCampaignDetails?: Maybe<DriveCampaignDetails>;
  /** Get statistics of the set of campaigns returned by the filter. This endpoint are meant to be used with the searchDriveCampaigns endpoint. Arguments should be the same. */
  driveCampaignSearchStats?: Maybe<DriveCampaignSearchStatistics>;
  /** Show details of a specific event */
  driveEvent?: Maybe<DriveEvent>;
  /** List events */
  driveEvents?: Maybe<DriveEventResults>;
  driveGetEarlyAccessFunds?: Maybe<EarlyAccess>;
  driveGetListActivitiesAvailableList?: Maybe<DriveGetListPotentialCustomersReturn>;
  driveGetListActivitiesNotAvailableList?: Maybe<DriveGetListPotentialCustomersReturn>;
  driveGetListCampaignHistoryList?: Maybe<DriveGetListCurrentCustomersReturn>;
  driveGetListCurrentCustomersList?: Maybe<DriveGetListCurrentCustomersReturn>;
  driveGetListHubspotProspectsList?: Maybe<DriveGetlistProspectsReturn>;
  driveGetListOrgList?: Maybe<DriveOrgListResults>;
  /** Returns Organization Summary information of single organization */
  driveGetListOrganizationSummary?: Maybe<DriveGetListOrganizationSummaryReturn>;
  /** @deprecated changed name to driveGetListActivitiesAvailableList */
  driveGetListPotentialCustomersList?: Maybe<DriveGetListPotentialCustomersReturn>;
  driveGetListWinbackList?: Maybe<DriveGetlistWinbackReturn>;
  driveGetProjectedRaised?: Maybe<EarlyAccess>;
  /** Return all tracking of current user to specific organization in Drive's Get List app. */
  driveOrgUserTrackings?: Maybe<Array<Maybe<DriveOrgUserTrackingReturn>>>;
  /** List organization types used by Drive */
  driveOrganizationTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** List all salesreps */
  driveSalesreps?: Maybe<Array<Maybe<DriveAmSalesReps>>>;
  /** List all salesreps which had re-assigned any campaigns to current user. This endpoint is applicable for account-manager only. */
  driveSalesrepsByAccountManager?: Maybe<Array<Maybe<DriveAmSalesReps>>>;
  /** List all salesreps which managed by current user. This endpoint is applicable for sales manager only. */
  driveSalesrepsByManager?: Maybe<Array<Maybe<DriveAmSalesReps>>>;
  driveSmsInviteData?: Maybe<SmsInviteData>;
  /**
   * Search Raise users by UserDirectory 's id
   * @deprecated Use UserDirectory 's users query instead
   */
  driveUsers?: Maybe<DriveUsersResult>;
  /** List Event-Tracker's venues */
  driveVenues?: Maybe<Array<Maybe<DriveVenue>>>;
  event: Event;
  eventCalendar?: Maybe<Array<Maybe<CommonCalendar>>>;
  eventContractItems?: Maybe<Array<Maybe<ContractItem>>>;
  eventOfficial: Official;
  eventOfficials?: Maybe<Array<Maybe<Official>>>;
  eventParticipants: EventParticipants;
  eventPreparations: EventPreparations;
  eventPreparationsByEvent?: Maybe<Array<Maybe<EventPreparations>>>;
  eventTransportDetailsByEventOrDates?: Maybe<Array<Maybe<EventTransportDetails>>>;
  eventWorker: Worker;
  eventWorkers?: Maybe<Array<Maybe<Worker>>>;
  eventWorkersByEvent?: Maybe<Array<Maybe<Worker>>>;
  events?: Maybe<Array<Maybe<Event>>>;
  eventsParticipants?: Maybe<Array<Maybe<EventParticipants>>>;
  eventsPreparations?: Maybe<Array<Maybe<EventPreparations>>>;
  eventsTransportDetails?: Maybe<Array<Maybe<EventTransportDetails>>>;
  facilities?: Maybe<Array<Maybe<Facility>>>;
  facility: Facility;
  facilityCalendar?: Maybe<Array<Maybe<CommonCalendar>>>;
  facilityGrid?: Maybe<Array<Maybe<CommonGrid>>>;
  facilitySchedules?: Maybe<Array<Maybe<CommonSchedules>>>;
  family?: Maybe<Family>;
  feeReportSheets?: Maybe<Array<Maybe<CommonSheet>>>;
  /** Get all Financial Accounts that are associated with a Stripe Connect Account Id If you are looking for Financial Accounts associated with a parent Org, please use the 'financialAcctRoot' query. */
  financialAcctAcctId: Array<FinancialAcctOrg>;
  /** Get all Financial Accounts that are associated with an Org using the Org Id. If you are looking for Financial Accounts associated with a parent Org, please use the 'financialAcctRoot' query. */
  financialAcctOrg: Array<FinancialAcctOrg>;
  /** Get all Financial Accounts that are associated with an Org using the Kyb Id. If you are looking for Financial Accounts associated with a parent Org, please use the 'financialAcctRoot' query. */
  financialAcctOrgByKyb: Array<FinancialAcctOrg>;
  /** Get all Financial Accounts that are associated with a parent Org. This includes parents of parents and all the way up the tree. This will NOT return the Financial Accounts for the target Org, please use the 'financialAcctOrg' query for specific Orgs. */
  financialAcctRoot: Array<FinancialAcctOrg>;
  /** Get all User data associated with a single Financial Account with an Orgs Platform Id. Eg: 'finacct_stripe_abcd1234' */
  financialAcctUsers: Array<FinancialAcctUser>;
  /** Get all User and Financial Account data associated with a single Kyc Id. Eg: 'vkyc_abcd1234' */
  financialAcctUsersByKyc: Array<FinancialAcctUser>;
  fundraiser?: Maybe<Fundraiser>;
  fundraiserApprovalSubmissions: Array<Maybe<FundraiserApprovalSubmission>>;
  /** Get fundraiser approval submissions authenticating request by approver's token */
  fundraiserApprovalSubmissionsHistory: Array<FundraiserApprovalSubmission>;
  /** Get fundraiser approval submission related org names authenticating request by approver's token */
  fundraiserApprovalSubmissionsOrgNames: FundraiserApprovalSubmissionsOrgNamesResult;
  fundraiserCheerwall?: Maybe<FundraiserCheerwall>;
  fundraiserCustomRewardsDetails?: Maybe<Array<Maybe<FundraiserCustomRewardsData>>>;
  fundraiserDataByCoachJoinCode?: Maybe<FundraiserDataByCoachJoinCode>;
  /** resourceId is the fundraiserId */
  fundraiserEntityResources?: Maybe<FundraiserEntityResource>;
  fundraiserGroups?: Maybe<Array<Maybe<FundraiserGroup>>>;
  fundraiserRaisedAmount?: Maybe<FundraiserRaisedAmount>;
  fundraiserRewardsDetails?: Maybe<Array<Maybe<FundraiserRewardsProduct>>>;
  fundraiserTopDonation?: Maybe<FundraiserTopDonation>;
  fundraiserUserRole?: Maybe<FundraiserUserRole>;
  galleryItems?: Maybe<GalleryItems>;
  getCampaignRoster?: Maybe<Array<Maybe<Roster>>>;
  getFundraiserUserID?: Maybe<Scalars['Int']>;
  getInsightsUserOrgs: Array<Maybe<InsOrg>>;
  getSalesRepHome: HmSalesRep;
  getUserSavedSalesRep: UserSavedRep;
  /** return the most recently created fundraiser associated with the group leader */
  groupLeaderDefaultFundraiser?: Maybe<GroupLeaderFundraiser>;
  /** return a fundraiser by id */
  groupLeaderFundraiser?: Maybe<GroupLeaderFundraiser>;
  /** return a list of fundraisers associated with the group leader, paginated, and filtered by role and status */
  groupLeaderFundraisers?: Maybe<GroupLeaderFundraisersPaginated>;
  /** List of group leaders */
  groupLeaders?: Maybe<GroupLeaderList>;
  helpDocumentsList?: Maybe<Array<Maybe<Scalars['String']>>>;
  infoSheets?: Maybe<Array<Maybe<CommonSheet>>>;
  insightsCampaignDonations: Array<Maybe<InsCampaignDonation>>;
  insightsCampaignRaiseEntityInfo: InsCampaignRaiseEntityInfo;
  insightsCampaignSettlements: Array<Maybe<InsCampaignSettlement>>;
  insightsCampaignsData: InsCampaignsData;
  insightsDonorParticipantContacts: Array<Maybe<InsDonorParticipantContact>>;
  insightsNotificationPreferences: InsightsUserOrgsNotificationPreferencesResponse;
  insightsOrgsCampaignsSummary: Array<Maybe<InsOrgCampaignSummary>>;
  insightsPreApprovedContacts: Array<Maybe<InsPreApprovedContact>>;
  insightsPreApprovedContactsEmailTest: Array<Maybe<InsEmailTestPreApprovedContactResult>>;
  insightsSalesRepInfo?: Maybe<InsSalesRepInfo>;
  insightsUserPreferences?: Maybe<Array<Maybe<InsightsGetUserPreference>>>;
  invite?: Maybe<InviteInfo>;
  invites?: Maybe<Array<Maybe<InviteList>>>;
  invitesList?: Maybe<InvitesListResponse>;
  /** joinCode is a string that is used to join a fundraiser */
  joinCodeType: JoinCodeType;
  legacyTransactions: LegacyTransactionsOutput;
  level: Level;
  levelGrid?: Maybe<Array<Maybe<CommonGrid>>>;
  levels?: Maybe<Array<Maybe<Level>>>;
  /** Retrieves a list of coaches based on the provided filter. */
  manageCoachListOld?: Maybe<ManageCoachListOld>;
  /** Retrieves a specific coach by its ID. */
  manageCoachOld?: Maybe<ManageCoachOld>;
  /** Retrieves a list of events based on the provided filter. */
  manageEventListOld?: Maybe<ManageEventListOld>;
  /** Retrieves a specific event by its ID. */
  manageEventOld?: Maybe<ManageEventOld>;
  /** Get a list of all manage organizations */
  manageOrganizationListOld?: Maybe<ManageOrganizationListOld>;
  /** Get a manage organization by web folder */
  manageOrganizationOld?: Maybe<ManageOrganizationOld>;
  managePlayerListOld?: Maybe<ManagePlayerListOld>;
  manageProgramListOld?: Maybe<ManageProgramListOld>;
  manageProgramOld?: Maybe<ManageProgramOld>;
  manageSeasonListOld?: Maybe<ManageSeasonListOld>;
  manageSeasonOld?: Maybe<ManageSeasonOld>;
  manageSportDetailOld?: Maybe<ManageSportDetailsOld>;
  manageSportDetailsListOld?: Maybe<ManageSportDetailsListOld>;
  manageUser?: Maybe<ManageUser>;
  manageUsersList?: Maybe<ManageUsersList>;
  me?: Maybe<UserWithPermissions>;
  messagesChannel?: Maybe<ChatChannelResult>;
  messagesChannels?: Maybe<ChatChannelsResult>;
  messagesMyChannels?: Maybe<MessagesMyChannelsResult>;
  messagesUnreadCount?: Maybe<ChatUnreadMessagesResult>;
  notices?: Maybe<Array<Maybe<Notice>>>;
  officialAssignments?: Maybe<OfficialAssignmentsReturn>;
  officialDuties?: Maybe<Array<Maybe<OfficialDuty>>>;
  officialDuty?: Maybe<OfficialDuty>;
  officialListsReport?: Maybe<Array<Maybe<EventOfficialReport>>>;
  officialPoolById: OfficialPool;
  officialPools?: Maybe<Array<Maybe<OfficialPool>>>;
  officialSchedules?: Maybe<Array<Maybe<OfficialSchedules>>>;
  opponent: Opponent;
  opponentEventsApplyFilters?: Maybe<Array<Maybe<EventsOpponent>>>;
  opponentSchedules?: Maybe<Array<Maybe<CommonSchedules>>>;
  opponents?: Maybe<Array<Maybe<Opponent>>>;
  /** Get Org Node properties by its Org id. If hierarchy is set to true, it will return all the children of the organization, but NOT the target Org. 'fields' returns JSON of all remaining properties. You can optionally limit this to specific fields by passing in an array of keys. Example: "names": [ "country", "zip_code" ] */
  org?: Maybe<Array<Maybe<Org>>>;
  /** Get Org Node properties from the campaign's Org id (eg: "cam_abc123") You can request a specifc Org type by passing in a label. If hierarchy is set to true, this query will return every Org all the way to the root */
  orgCampaignId?: Maybe<Array<Maybe<Org>>>;
  /** Get an Organization's Org platform id by its Raise entities.id OR Get a Campaign's Org platform id by its Raise fundraisers.id This ONLY returns an Org id, not the full Org Node. */
  orgId?: Maybe<Scalars['ID']>;
  /** Get the Org paid by a given campaign id. This required an Org Platform Campaign Id (eg: "cam_abc123") If you only have a Raise Fundraiser Id, you can use the 'orgId' query to find it. */
  orgPayable?: Maybe<Array<Maybe<Org>>>;
  /** Fuzzy search for Orgs by their name property, returned in order of match quality. Optionally accepts label, ein, city, two letter state code, or zip code. This search has a default limit of 25 and a maximim limit of 100 responses. */
  orgSearch: Array<Org>;
  /** Get all Users that are associated with a specific Org. */
  orgStaffRoster: Array<OrgStaffMember>;
  /** Get all Users that are associated with an Org and a specific product. This includes role and a boolean if the user is internal (snap-raise or snapraise email). */
  orgUserAssociations: Array<Maybe<UserNode>>;
  /** Search for Orgs by their name property, with an option to specify label. This will only return the first 100 orgs. */
  orgs: Array<Org>;
  /**
   * If you are simply searching by name property, please use the 'orgs' query. This advanced search searches by type or specific properties. Default limit is 25 items.
   * @deprecated Please use orgSearch instead
   */
  orgsSearch?: Maybe<OrgsSearchResponse>;
  /** This query will return OTK Fundraiser Product details */
  otkFundraiserData?: Maybe<Array<Maybe<OtkFundraiserProduct>>>;
  /** This query will return OTK Participant details */
  otkParticipantDetails?: Maybe<Array<Maybe<OtkParticipantData>>>;
  /** This query will return the most recent OTK Participant details */
  otkPastParticipantDetails?: Maybe<Array<Maybe<OtkParticipantData>>>;
  participantCampaignConfigurationData?: Maybe<ParticipantCampaignConfiguration>;
  participantCheerwall?: Maybe<ParticipantCheerwall>;
  participantDonationsRaised: ParticipantDonationsRaised;
  /** This query will return the incentives a Participant earned for a given fundraiser */
  participantEarnedRewardsDetails?: Maybe<Array<Maybe<ParticipantRewardsData>>>;
  /** Accepts a udid and returns a participantFundraiser object */
  participantFundraiser?: Maybe<ParticipantFundraiser>;
  participantFundraiserReward?: Maybe<ParticipantReward>;
  participantFundraisers: Array<Maybe<ParticipantFundraiser>>;
  participantGuardianFundraisers?: Maybe<Array<Maybe<ParticipantGuardianFundraiser>>>;
  participantPublic?: Maybe<ParticipantPublic>;
  /** This query will return Participant Rewards details */
  participantRewardsDetails?: Maybe<Array<Maybe<ParticipantRewardsData>>>;
  participantSSOId?: Maybe<ParticipantSsoid>;
  participantShareMessage: Scalars['String'];
  participantTextTemplate?: Maybe<ParticipantTextTemplate>;
  participantTopDonation?: Maybe<ParticipantTopDonation>;
  /** List of participants */
  participants?: Maybe<ParticipantList>;
  participantsListV2?: Maybe<Array<Maybe<FundraiserParticipants>>>;
  participantsPublic?: Maybe<Array<Maybe<FundraiserParticipantPublic>>>;
  paymentsApiCustomerByEmail: PaymentsApiCustomerResponse;
  payouts: Array<Maybe<Payout>>;
  permissions?: Maybe<Array<Maybe<Permission>>>;
  prefund?: Maybe<Prefund>;
  prefunds?: Maybe<PrefundList>;
  /** List of pre-made contact templates */
  premadeContactTemplatesGet?: Maybe<Array<Maybe<PremadeContactTemplate>>>;
  preparation: Preparation;
  preparationReportSheets?: Maybe<Array<Maybe<PreparationSheet>>>;
  preparations?: Maybe<Array<Maybe<Preparation>>>;
  /** This resolver is only for testing purposes at this time */
  primaryGroupLeader?: Maybe<PrimaryGroupLeader>;
  /** Identifier uses fundraiser join_code or slug */
  publicFundraiserData?: Maybe<PublicFundraiserData>;
  /** Get user public data from User Directory by userID */
  publicUserData?: Maybe<User>;
  /** A query to get last year's fundraiser by the id of this year's fundraiser */
  raiseAdminFundraiserPast?: Maybe<RaiseAdminFundraiserPast>;
  role?: Maybe<Role>;
  roles?: Maybe<Array<Maybe<Role>>>;
  schoolInfo: SchoolInfo;
  schoolInfos?: Maybe<Array<Maybe<SchoolInfo>>>;
  /** Search campaigns */
  searchDriveCampaigns?: Maybe<DriveCampaignList>;
  /** Search Raise's Organization. @Deprecated: should be replaced by Orgs API */
  searchDriveOrganizationsByName?: Maybe<DriveOrganizationList>;
  season: Season;
  seasonByYearAndTeam?: Maybe<Array<Maybe<Season>>>;
  seasonEventWizard?: Maybe<Array<Maybe<Event>>>;
  seasons?: Maybe<Array<Maybe<Season>>>;
  shipments: ShipmentResponse;
  spendAccountExport?: Maybe<ExportFile>;
  spendBudget?: Maybe<SpendBudgetResponse>;
  spendBudgetExport?: Maybe<ExportFile>;
  spendBudgetTransactions: SpendBudgetTransactionsOutput;
  spendBudgets?: Maybe<SpendBudgetsResponse>;
  spendBudgetsProgramSummary?: Maybe<SpendBudgetSummaryResponse>;
  spendBudgetsSummary?: Maybe<SpendBudgetSummaryResponse>;
  spendCategories?: Maybe<SpendCategoryResponse>;
  spendDataForHome: HmSpendData;
  spendDebitCardInvites?: Maybe<SpendInviteResponse>;
  spendGroup?: Maybe<SpendGroup>;
  spendGroupBankAccounts?: Maybe<SpendBankAccountsResponse>;
  spendGroupBankLinkTokenCreate?: Maybe<SpendBankLinkCreateResponse>;
  spendGroupById?: Maybe<SpendGroup>;
  spendGroupDebitCards?: Maybe<SpendDebitCardResponse>;
  spendGroupPayees?: Maybe<SpendPayeeResponse>;
  spendGroupRosterById?: Maybe<SpendRoster>;
  spendGroupRosters?: Maybe<SpendGroupRosterResponse>;
  spendGroupRostersBySeason?: Maybe<SpendGroupRosterResponse>;
  spendGroups?: Maybe<SpendGroupResponse>;
  /** List groups with pagination/filtering and sorting. Valid sort.field: name, seasonStartDate, seasonEndDate, playerCount, paid, upcoming, pastDue, dueToday, processing, paymentScheduleStatus */
  spendGroupsFiltered?: Maybe<SpendGroupsResponse>;
  spendGroupsOverviewDashboard?: Maybe<GroupsOverviewDashboardResponse>;
  spendGroupsPublic?: Maybe<PublicGroupResponse>;
  spendGuardianHighlight?: Maybe<SpendGuardianHighlight>;
  spendGuardianInvoices?: Maybe<SpendGuardianInvoiceResponse>;
  spendInvite?: Maybe<SpendInviteResponse>;
  spendInvites?: Maybe<SpendInviteResponse>;
  spendInvitesFiltered?: Maybe<SpendInviteResponse>;
  spendInvoiceById?: Maybe<SpendInvoice>;
  spendInvoiceHistory?: Maybe<SendEmailResponse>;
  spendInvoices?: Maybe<SpendInvoiceResponse>;
  spendInvoicesExport?: Maybe<ExportFile>;
  spendInvoicesExportV2?: Maybe<ExportFile>;
  spendInvoicesFiltered?: Maybe<SpendInvoiceResponse>;
  spendOrganization?: Maybe<SpendOrganization>;
  spendOrganizationAccountLimits?: Maybe<SpendAccountLimitsResponse>;
  spendOrganizationAccounts?: Maybe<SpendAccountResponse>;
  spendOrganizationBankAccounts?: Maybe<SpendBankAccountsResponse>;
  spendOrganizationBankLinkTokenCreate?: Maybe<SpendBankLinkCreateResponse>;
  spendOrganizationById?: Maybe<SpendOrganization>;
  spendOrganizationDebitCards?: Maybe<SpendDebitCardResponse>;
  spendOrganizationPayees?: Maybe<SpendPayeeResponse>;
  spendOrganizations?: Maybe<SpendOrganizationsResponse>;
  spendPaginatedInvoices?: Maybe<SpendInvoiceResponse>;
  spendPastDueInvoices?: Maybe<SpendPastDueInvoicesOutput>;
  spendPaymentScheduleById?: Maybe<SpendPaymentSchedule>;
  spendPaymentSchedules?: Maybe<Array<Maybe<SpendPaymentSchedule>>>;
  spendPolicy?: Maybe<SpendPolicy>;
  spendRoleCurrent?: Maybe<SpendRole>;
  spendRoles?: Maybe<SpendRoleResponse>;
  spendRosters?: Maybe<SpendRosterResponse>;
  spendRostersFiltered?: Maybe<SpendRosterResponse>;
  spendSeason?: Maybe<SpendSeason>;
  spendSettings?: Maybe<SpendSettings>;
  spendTransaction?: Maybe<SpendBankTransactionResponse>;
  spendTransactionAttachment?: Maybe<TransactionAttachmentsResponse>;
  spendTransactionAttachmentsByPaymentId?: Maybe<TransactionAttachmentsResponse>;
  spendTransactionNotes?: Maybe<TransactionNotesResponse>;
  spendTransactionNotesByPaymentId?: Maybe<TransactionNotesResponse>;
  /** @deprecated Use spendTransactionsFiltered */
  spendTransactions?: Maybe<SpendBankTransactionsResponse>;
  spendTransactionsExport?: Maybe<ExportFile>;
  spendTransactionsFiltered?: Maybe<SpendBankTransactionsResponse>;
  spendTransactionsLegacy?: Maybe<SpendLegacyTransactionsResponse>;
  spendUserAccountBearerToken?: Maybe<SpendAccountBearerTokenResponse>;
  spendUserBankAccounts?: Maybe<SpendBankAccountsResponse>;
  spendUserBankLinkTokenCreate?: Maybe<SpendBankLinkCreateResponse>;
  spendUserExportEmails?: Maybe<ExportFile>;
  spendUserVerificationToken?: Maybe<SpendVerificationTokenResponse>;
  sponsorDataForHome: HmSponsorData;
  storeAllBrands?: Maybe<StoreBrandsUnionType>;
  storeAllColors?: Maybe<StoreColorsUnionType>;
  storeBestSeller?: Maybe<StoreBaseSellerUnionType>;
  storeByScopeId?: Maybe<MagentoStoreUnionType>;
  storeCampaignPayableInfo?: Maybe<StoreCampaignPayableInfoUnionType>;
  storeDataForHome: HmStore;
  storeEarnedPointsGraph?: Maybe<MagentoStorePointsEarnedUnionType>;
  storeGLCampaigns?: Maybe<StoreUserCampaignUnionType>;
  storeGLParticipants?: Maybe<StoreUserParticipantUnionType>;
  storeOrderFilter?: Maybe<OrdersFilterUnionType>;
  storeOrderItemImages?: Maybe<StoreOrderItemsUnionType>;
  storeOrderSalesGraph?: Maybe<StoreOrderSalesGraphUnionType>;
  storeOrderSummary?: Maybe<OrdersSummaryUnionType>;
  storeOrdersCsv?: Maybe<MagentoOrderUnionType>;
  storePayableFilter?: Maybe<StorePayableFilterUnionType>;
  /** @deprecated Use storePointActivityFilterAdvance Query */
  storePointActivityFilter?: Maybe<PointActivityFilterUnionType>;
  storePointActivityFilterAdvance?: Maybe<PointActivityFilterUnionType>;
  /** @deprecated Use storePointsActivityCsvAdvance Query */
  storePointsActivityCsv?: Maybe<MagentoPointActivityUnionType>;
  storePointsActivityCsvAdvance?: Maybe<MagentoPointActivityUnionType>;
  storePointsWithdrawalRequestFilter?: Maybe<StorePointsWithdrawalFilterUnionType>;
  storeProfitPercentage?: Maybe<ProfitPercentage>;
  storeScopePayableInfo?: Maybe<StorePayableInfoUnionType>;
  storeSubscribedUser?: Maybe<StoreSubscribedUserUnionType>;
  storeTickets?: Maybe<StoreTicketUnionType>;
  storeUserDetails?: Maybe<StoreUserInfoUnionType>;
  storeUserPreference?: Maybe<UserPreference>;
  storesByCampaignIds?: Maybe<StoreFilterUnionType>;
  storesByGLEmail?: Maybe<StoreFilterUnionType>;
  storesSummaryByCampaignIds?: Maybe<StoresSummaryUnionType>;
  storesSummaryByGLEmail?: Maybe<StoresSummaryUnionType>;
  team: Team;
  teamOfficials?: Maybe<Array<Maybe<TemplateOfficial>>>;
  teamOrgSchedules?: Maybe<Array<Maybe<CommonSchedules>>>;
  teamPreparations?: Maybe<Array<Maybe<TemplatePreparation>>>;
  teamWorkers?: Maybe<Array<Maybe<TemplateWorker>>>;
  teams?: Maybe<Array<Maybe<Team>>>;
  transactionDetail?: Maybe<TransactionDetail>;
  transactions: Array<Maybe<Transaction>>;
  transactionsByAccount: Array<Maybe<Transaction>>;
  transactionsByAccountPaginated: AccountTransactionsPaginatedOutput;
  transactionsBySpendDestination: Array<Maybe<Transaction>>;
  transactionsBySpendSource: Array<Maybe<Transaction>>;
  twispCardBalances?: Maybe<Array<Maybe<TwispCardBalances>>>;
  twispCards?: Maybe<Array<Maybe<TwispCards>>>;
  twispStripeHooks?: Maybe<Array<Maybe<TwispStripeHooks>>>;
  twispTransactions?: Maybe<Array<Maybe<TwispTransactions>>>;
  unconfirmedEvents?: Maybe<UnconfirmedEventsList>;
  updateSavedSalesRep: UserSavedRep;
  user?: Maybe<User>;
  /** Get all Programs/Activities that a User leads, grouped by parent Org. */
  userActivityLeadership: Array<ProgramLeadership>;
  /** Get all Orgs that are affiliated with a User. */
  userAffiliations: Array<OrgAffiliation>;
  userApiKey?: Maybe<ApiKey>;
  userApiKeyBySecret?: Maybe<ApiKey>;
  userApiKeys?: Maybe<Array<Maybe<ApiKey>>>;
  /** Get all Org ids that are associated with a single User and a specific product, as well all the Campaign ids in each hierarchy. */
  userAssociationCampaigns: Array<OrgCampaignIds>;
  /** Get all Orgs that are associated with a User and a specific product. */
  userAssociations: Array<Maybe<Org>>;
  userBySecret?: Maybe<UserWithPermissions>;
  /** Get all Financial Account data associated with a single User Directory ID. */
  userFinancialAccounts: Array<FinancialAcctUser>;
  userFitting?: Maybe<UserFitting>;
  /** Get a list of Campaigns grouped by Org that User has been a Group Leader for. */
  userGroupLeadership: Array<OrgCampaignAffiliation>;
  /** Get a list of implied affiliations based on GroupLeader relationships. */
  userImpliedAffiliations: Array<OrgCampaignAffiliation>;
  /** Get all Insights configurations for current user */
  userInsightsConfigurations: Array<Maybe<UserInsightsConfig>>;
  /** Get all titles that are associated with a User and a specific Org. */
  userOrgAffiliation: Array<UserAffiliation>;
  /** Get a list Campaigns grouped by Org that User has been a Participant in. */
  userParticipation: Array<OrgCampaignAffiliation>;
  userPermissions?: Maybe<UserPermissionsList>;
  /** @deprecated use userPublicInfo instead */
  userPublic?: Maybe<UserPublic>;
  userPublicInfo?: Maybe<Array<Maybe<UserPublicInfoResult>>>;
  /** Get all Teams that a User leads. */
  userTeamLeadership: Array<TeamLeadership>;
  userToken: Auth;
  /** Get counts of unconfirmed LEADS, AFFILIATIONS, and IMPLIED affiliations for a User. */
  userUnconfirmedAffiliations: UserAffiliationCounts;
  users?: Maybe<Users>;
  vaultAccountStatus: VaultAccountStatus;
  vaultCard: Card;
  vaultKyb: VaultKyb;
  vaultKyc: VaultKyc;
  vaultStripeConnectAccountBankAccountListFetch?: Maybe<Array<Maybe<VaultBankAccount>>>;
  vaultStripeConnectAccountFetch?: Maybe<Scalars['JSONObject']>;
  vaultStripeConnectAccountsBankAccountListFetch?: Maybe<Scalars['JSONObject']>;
  vaultStripeInvalidRepresentative?: Maybe<Scalars['ID']>;
  vaultVgsValue: Scalars['String'];
  vehicle: Vehicle;
  vehicles?: Maybe<Array<Maybe<Vehicle>>>;
  workerPoolById: WorkerPool;
  workerPools?: Maybe<Array<Maybe<WorkerPool>>>;
};


export type QueryAccountTransactionsArgs = {
  input: AccountTransactionsInput;
};


export type QueryAuditLogsArgs = {
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
};


export type QueryBusSchedulesArgs = {
  input?: InputMaybe<CommonSchedulesFiltersInput>;
};


export type QueryCampaignDatesArgs = {
  campaignId: Scalars['String'];
};


export type QueryCampaignHistoryListGetArgs = {
  fundraiserId?: InputMaybe<Scalars['Int']>;
};


export type QueryCampaignKycArgs = {
  campaignId: Scalars['String'];
};


export type QueryCampaignMembershipsArgs = {
  basicStatus?: InputMaybe<BasicCampaignStatus>;
  fundraiserId?: InputMaybe<Scalars['ID']>;
};


export type QueryCampaignsArgs = {
  accountManagerIds?: InputMaybe<Array<Scalars['Int']>>;
  daysClosed?: InputMaybe<Scalars['String']>;
  daysToLaunch?: InputMaybe<Scalars['String']>;
  fetchAllCampaigns?: InputMaybe<Scalars['Boolean']>;
  getTeamSelling?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  salesrepIds?: InputMaybe<Array<Scalars['Int']>>;
  searchTerm?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<SortDirection>;
  sortField?: InputMaybe<SortField>;
  status?: InputMaybe<Scalars['String']>;
};


export type QueryCommsContactArgs = {
  id: Scalars['ID'];
};


export type QueryCommsHtmlArgs = {
  data?: InputMaybe<Scalars['JSON']>;
  mjml: Scalars['String'];
};


export type QueryCommsMessageArgs = {
  id: Scalars['ID'];
};


export type QueryCommsTemplateArgs = {
  id: Scalars['ID'];
};


export type QueryCommsTemplatesArgs = {
  name?: InputMaybe<Scalars['String']>;
  service?: InputMaybe<SnapService>;
  transport?: InputMaybe<MessageTransportType>;
};


export type QueryCompetitionListArgs = {
  input?: InputMaybe<GetCompetitionListInput>;
};


export type QueryConsumersArgs = {
  hidden?: InputMaybe<Scalars['Boolean']>;
};


export type QueryDailyCalendarBusSchedulesArgs = {
  input?: InputMaybe<GetDailyCalendarBusScheduleByDateInput>;
};


export type QueryDailyCalendarEventsArgs = {
  input?: InputMaybe<GetDailyCalendarEventsByDateInput>;
};


export type QueryDailyCalendarOfficialsArgs = {
  input?: InputMaybe<GetDailyCalendarOfficialsByDateInput>;
};


export type QueryDailyCalendarPreparationsArgs = {
  input?: InputMaybe<GetDailyCalendarPreparationsByDateInput>;
};


export type QueryDailyCalendarWorkersArgs = {
  input?: InputMaybe<GetDailyCalendarWorkersByDateInput>;
};


export type QueryDaySchedulesArgs = {
  input?: InputMaybe<CommonSchedulesFiltersInput>;
};


export type QueryDonationLevelsArgs = {
  raiseFundraiserId: Scalars['Int'];
};


export type QueryDonationLevelsByFundraiserIdArgs = {
  fundraiserId: Scalars['String'];
};


export type QueryDonationTopEarnerArgs = {
  fundraiserId: Scalars['String'];
};


export type QueryDonationsQueryArgs = {
  fundraiserId: Scalars['Int'];
  participantId?: InputMaybe<Scalars['Int']>;
};


export type QueryDonorDonateIntentArgs = {
  id: Scalars['ID'];
};


export type QueryDonorFundraiserDataArgs = {
  fundraiserId?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
};


export type QueryDonorPersonListEntriesArgs = {
  fundraiserId: Scalars['Int'];
  userId: Scalars['Int'];
};


export type QueryDonorsCheerwallArgs = {
  fundraiserId: Scalars['Int'];
  participantId?: InputMaybe<Scalars['Int']>;
  take: Scalars['Int'];
};


export type QueryDonorsCheerwallsArgs = {
  fundraiserId: Scalars['Int'];
  participantId?: InputMaybe<Scalars['Int']>;
  take: Scalars['Int'];
};


export type QueryDonorsDonationArgs = {
  donateIntentId?: InputMaybe<Scalars['String']>;
  donationId?: InputMaybe<Scalars['String']>;
};


export type QueryDonorsFundraiserPayablesArgs = {
  input: DonorFundraiserPayablesInput;
};


export type QueryDonorsGiftShopIntentArgs = {
  purchaseIntentId: Scalars['String'];
};


export type QueryDonorsGiftShopPurchaseArgs = {
  purchaseId: Scalars['String'];
};


export type QueryDonorsParticipantOtkIncentivesArgs = {
  fundraiserId: Scalars['String'];
  participantId: Scalars['String'];
};


export type QueryDonorsParticipantsGiftShopArgs = {
  fundraiserId: Scalars['String'];
  participantId: Scalars['String'];
};


export type QueryDriveCampaignDetailsArgs = {
  fundraiserId: Scalars['Int'];
};


export type QueryDriveCampaignSearchStatsArgs = {
  activityTypes?: InputMaybe<Array<Scalars['String']>>;
  campaignName?: InputMaybe<Scalars['String']>;
  campaignStatuses?: InputMaybe<Array<CampaignStatus>>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  fundraiserId?: InputMaybe<Scalars['Int']>;
  hasIncentive?: InputMaybe<Scalars['Boolean']>;
  maxTeamSize?: InputMaybe<Scalars['Int']>;
  maxTotalRaisedCents?: InputMaybe<Scalars['Int']>;
  minTeamSize?: InputMaybe<Scalars['Int']>;
  minTotalRaisedCents?: InputMaybe<Scalars['Int']>;
  organizationIds?: InputMaybe<Array<Scalars['Int']>>;
  organizationTypes?: InputMaybe<Array<Scalars['String']>>;
  salesrepIds?: InputMaybe<Array<Scalars['Int']>>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  territories?: InputMaybe<Array<Scalars['String']>>;
  usStates?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryDriveEventArgs = {
  id: Scalars['ID'];
};


export type QueryDriveEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<SortDirection>;
  sortField?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<DriveEventSearchInput>;
};


export type QueryDriveGetEarlyAccessFundsArgs = {
  activityType: Scalars['String'];
  campaignStatus?: InputMaybe<Scalars['String']>;
  fundraiserId: Scalars['Int'];
  participantCount: Scalars['Int'];
  state: Scalars['String'];
};


export type QueryDriveGetListActivitiesAvailableListArgs = {
  hubspotId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
};


export type QueryDriveGetListActivitiesNotAvailableListArgs = {
  hubspotId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<SortDirection>;
  sortField?: InputMaybe<Scalars['String']>;
};


export type QueryDriveGetListCampaignHistoryListArgs = {
  hubspotId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
};


export type QueryDriveGetListCurrentCustomersListArgs = {
  hubspotId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
};


export type QueryDriveGetListHubspotProspectsListArgs = {
  hubspotId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
};


export type QueryDriveGetListOrgListArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orgName?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<SortDirection>;
  sortField?: InputMaybe<Scalars['String']>;
};


export type QueryDriveGetListOrganizationSummaryArgs = {
  hubspotId?: InputMaybe<Scalars['String']>;
  orgId: Scalars['Int'];
};


export type QueryDriveGetListPotentialCustomersListArgs = {
  hubspotId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
};


export type QueryDriveGetListWinbackListArgs = {
  hubspotId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
};


export type QueryDriveGetProjectedRaisedArgs = {
  activityType: Scalars['String'];
  participantCount: Scalars['Int'];
  state: Scalars['String'];
};


export type QueryDriveOrgUserTrackingsArgs = {
  orgId: Scalars['String'];
  where?: InputMaybe<DriveOrgUserTrackingSearchInput>;
};


export type QueryDriveSalesrepsByManagerArgs = {
  type?: InputMaybe<SalesrepType>;
};


export type QueryDriveSmsInviteDataArgs = {
  fundraiserId: Scalars['Int'];
};


export type QueryDriveUsersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DriveUserSearchInput>;
};


export type QueryDriveVenuesArgs = {
  where?: InputMaybe<DriveVenueSearchInput>;
};


export type QueryEventArgs = {
  input?: InputMaybe<GetEventByIdInput>;
};


export type QueryEventCalendarArgs = {
  input?: InputMaybe<EventCalendarFiltersInput>;
};


export type QueryEventContractItemsArgs = {
  input?: InputMaybe<EventContractItemInput>;
};


export type QueryEventOfficialArgs = {
  input?: InputMaybe<GetOfficialByIdInput>;
};


export type QueryEventParticipantsArgs = {
  input?: InputMaybe<GetEventParticipantsByIdInput>;
};


export type QueryEventPreparationsArgs = {
  input?: InputMaybe<GetEventPreparationsByIdInput>;
};


export type QueryEventPreparationsByEventArgs = {
  input?: InputMaybe<GetEventPreparationsByEventIdInput>;
};


export type QueryEventTransportDetailsByEventOrDatesArgs = {
  input?: InputMaybe<GetEventTransportDetailsByEventOrDatesInput>;
};


export type QueryEventWorkerArgs = {
  input?: InputMaybe<GetWorkerByIdInput>;
};


export type QueryEventWorkersByEventArgs = {
  input?: InputMaybe<GetWorkersByEventIdInput>;
};


export type QueryFacilityArgs = {
  input?: InputMaybe<GetFacilityByPlaceNameInput>;
};


export type QueryFacilityCalendarArgs = {
  input?: InputMaybe<CommonCalendarFiltersInput>;
};


export type QueryFacilityGridArgs = {
  input?: InputMaybe<CommonGridFiltersInput>;
};


export type QueryFacilitySchedulesArgs = {
  input?: InputMaybe<CommonSchedulesFiltersInput>;
};


export type QueryFeeReportSheetsArgs = {
  input?: InputMaybe<CommonSheetFiltersInput>;
};


export type QueryFinancialAcctAcctIdArgs = {
  acctId: Scalars['ID'];
};


export type QueryFinancialAcctOrgArgs = {
  orgId: Scalars['ID'];
};


export type QueryFinancialAcctOrgByKybArgs = {
  kybId: Scalars['ID'];
};


export type QueryFinancialAcctRootArgs = {
  orgId: Scalars['ID'];
};


export type QueryFinancialAcctUsersArgs = {
  finAcctId: Scalars['ID'];
};


export type QueryFinancialAcctUsersByKycArgs = {
  kycId: Scalars['ID'];
};


export type QueryFundraiserArgs = {
  fundraiserId: Scalars['String'];
};


export type QueryFundraiserApprovalSubmissionsArgs = {
  approverId?: InputMaybe<Scalars['String']>;
  campaignIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  formId?: InputMaybe<Scalars['Int']>;
  latest?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<FundraiserApprovalSubmissionStatus>;
  submitterId: Scalars['String'];
};


export type QueryFundraiserApprovalSubmissionsHistoryArgs = {
  token: Scalars['String'];
};


export type QueryFundraiserApprovalSubmissionsOrgNamesArgs = {
  submissionId?: InputMaybe<Scalars['Int']>;
  token?: InputMaybe<Scalars['String']>;
};


export type QueryFundraiserCheerwallArgs = {
  fundraiserId: Scalars['String'];
  take: Scalars['Int'];
};


export type QueryFundraiserCustomRewardsDetailsArgs = {
  fundraiserId: Scalars['String'];
};


export type QueryFundraiserDataByCoachJoinCodeArgs = {
  coachJoinCode: Scalars['String'];
};


export type QueryFundraiserEntityResourcesArgs = {
  resourceId: Scalars['Int'];
};


export type QueryFundraiserGroupsArgs = {
  joinCode: Scalars['String'];
};


export type QueryFundraiserRaisedAmountArgs = {
  fundraiserId: Scalars['String'];
};


export type QueryFundraiserRewardsDetailsArgs = {
  fundraiserId: Scalars['String'];
};


export type QueryFundraiserTopDonationArgs = {
  fundraiserId: Scalars['String'];
};


export type QueryFundraiserUserRoleArgs = {
  fundraiserId: Scalars['Int'];
};


export type QueryGalleryItemsArgs = {
  fundraiserId: Scalars['String'];
};


export type QueryGetCampaignRosterArgs = {
  campaignId: Scalars['String'];
};


export type QueryGetInsightsUserOrgsArgs = {
  userId: Scalars['Int'];
};


export type QueryGetSalesRepHomeArgs = {
  zip: Scalars['String'];
};


export type QueryGetUserSavedSalesRepArgs = {
  udId: Scalars['String'];
};


export type QueryGroupLeaderFundraiserArgs = {
  fundraiserId: Scalars['String'];
};


export type QueryGroupLeaderFundraisersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<GroupLeadersFundraiserUserRole>;
  status?: InputMaybe<Array<FundraiserStatus>>;
};


export type QueryGroupLeadersArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  groupLeaderId?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryInfoSheetsArgs = {
  input?: InputMaybe<CommonSheetFiltersInput>;
};


export type QueryInsightsCampaignDonationsArgs = {
  campaignIds: Array<InputMaybe<Scalars['Int']>>;
};


export type QueryInsightsCampaignRaiseEntityInfoArgs = {
  campaignId: Scalars['Int'];
};


export type QueryInsightsCampaignSettlementsArgs = {
  campaignIds: Array<InputMaybe<Scalars['Int']>>;
};


export type QueryInsightsCampaignsDataArgs = {
  campaignIds: Array<InputMaybe<Scalars['Int']>>;
};


export type QueryInsightsDonorParticipantContactsArgs = {
  campaignIds: Array<InputMaybe<Scalars['Int']>>;
};


export type QueryInsightsNotificationPreferencesArgs = {
  userId: Scalars['String'];
};


export type QueryInsightsOrgsCampaignsSummaryArgs = {
  orgCampaignIds: Array<InputMaybe<InsOrgCampaignSummaryInput>>;
};


export type QueryInsightsPreApprovedContactsArgs = {
  orgIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryInsightsPreApprovedContactsEmailTestArgs = {
  emails: Array<InputMaybe<Scalars['String']>>;
  orgId: Scalars['String'];
};


export type QueryInsightsSalesRepInfoArgs = {
  salesRepEmail: Scalars['String'];
};


export type QueryInsightsUserPreferencesArgs = {
  campaignIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  orgIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  queryType: UserPreferenceQueryType;
  userId: Scalars['String'];
};


export type QueryInviteArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryInvitesArgs = {
  limit?: Scalars['Int'];
  offset?: InputMaybe<Scalars['Int']>;
  showAll?: InputMaybe<Scalars['Boolean']>;
};


export type QueryInvitesListArgs = {
  currentPage?: Scalars['Int'];
  pageSize?: Scalars['Int'];
  search?: InputMaybe<Scalars['String']>;
  showAll?: InputMaybe<Scalars['Boolean']>;
};


export type QueryJoinCodeTypeArgs = {
  joinCode: Scalars['String'];
};


export type QueryLegacyTransactionsArgs = {
  input: LegacyTransactionsInput;
};


export type QueryLevelArgs = {
  input?: InputMaybe<GetLevelByIdInput>;
};


export type QueryLevelGridArgs = {
  input?: InputMaybe<CommonGridFiltersInput>;
};


export type QueryManageCoachListOldArgs = {
  filter?: InputMaybe<ManageCoachListFilterOld>;
};


export type QueryManageCoachOldArgs = {
  id: Scalars['Int'];
};


export type QueryManageEventListOldArgs = {
  filter?: InputMaybe<ManageEventListFilterOld>;
};


export type QueryManageEventOldArgs = {
  id: Scalars['Int'];
};


export type QueryManageOrganizationListOldArgs = {
  filter?: InputMaybe<ManageOrganizationOldFilter>;
};


export type QueryManagePlayerListOldArgs = {
  filter?: InputMaybe<ManagePlayerListFilterOld>;
};


export type QueryManageProgramListOldArgs = {
  filter?: InputMaybe<ManageProgramListFilterOld>;
};


export type QueryManageProgramOldArgs = {
  id: Scalars['Int'];
};


export type QueryManageSeasonListOldArgs = {
  filter: ManageSeasonListFilterOld;
};


export type QueryManageSeasonOldArgs = {
  seasonId: Scalars['Int'];
};


export type QueryManageSportDetailOldArgs = {
  id: Scalars['Int'];
};


export type QueryManageSportDetailsListOldArgs = {
  filter?: InputMaybe<ManageSportDetailsListFilterOld>;
};


export type QueryManageUserArgs = {
  input?: InputMaybe<ManageUserInput>;
};


export type QueryManageUsersListArgs = {
  input?: InputMaybe<ManageUsersListOptions>;
};


export type QueryMessagesChannelArgs = {
  assignment?: InputMaybe<AssignmentInput>;
  id?: InputMaybe<Scalars['String']>;
};


export type QueryMessagesChannelsArgs = {
  filters?: InputMaybe<MessagesChannelsFilterInput>;
};


export type QueryOfficialAssignmentsArgs = {
  input?: InputMaybe<GetOfficialAssignmentsInput>;
};


export type QueryOfficialDutyArgs = {
  input?: InputMaybe<GetOfficialDutyByIdInput>;
};


export type QueryOfficialListsReportArgs = {
  input?: InputMaybe<EventOfficialReportFilter>;
};


export type QueryOfficialPoolByIdArgs = {
  input?: InputMaybe<GetOfficialPoolByIdInput>;
};


export type QueryOfficialSchedulesArgs = {
  input?: InputMaybe<CommonSchedulesFiltersInput>;
};


export type QueryOpponentArgs = {
  input?: InputMaybe<GetOpponentBySchoolCodeInput>;
};


export type QueryOpponentEventsApplyFiltersArgs = {
  input?: InputMaybe<GetEventsFilteredByOpponentStartAndEndDate>;
};


export type QueryOpponentSchedulesArgs = {
  input?: InputMaybe<CommonSchedulesFiltersInput>;
};


export type QueryOrgArgs = {
  hierarchy?: Scalars['Boolean'];
  id: Scalars['ID'];
};


export type QueryOrgCampaignIdArgs = {
  campaignId: Scalars['ID'];
  hierarchy?: Scalars['Boolean'];
  label?: OrgType;
};


export type QueryOrgIdArgs = {
  raiseId: Scalars['Int'];
};


export type QueryOrgPayableArgs = {
  campaignId: Scalars['ID'];
};


export type QueryOrgSearchArgs = {
  city?: InputMaybe<Scalars['String']>;
  ein?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<OrgType>;
  limit?: InputMaybe<Scalars['Int']>;
  matchStyle?: InputMaybe<MatchStyle>;
  name: Scalars['String'];
  offset?: InputMaybe<Scalars['Int']>;
  stateCode?: InputMaybe<StateCode>;
  zipCode?: InputMaybe<Scalars['String']>;
};


export type QueryOrgStaffRosterArgs = {
  orgId: Scalars['ID'];
};


export type QueryOrgUserAssociationsArgs = {
  orgId: Scalars['ID'];
  product: Product;
};


export type QueryOrgsArgs = {
  label?: InputMaybe<OrgType>;
  nameIncludes: Scalars['String'];
};


export type QueryOrgsSearchArgs = {
  pagination?: InputMaybe<OrgsSearchPaginationInput>;
  where?: InputMaybe<OrgsSearchWhereInput>;
};


export type QueryOtkFundraiserDataArgs = {
  fundraiserId: Scalars['ID'];
};


export type QueryOtkParticipantDetailsArgs = {
  fundraiserId: Scalars['ID'];
};


export type QueryOtkPastParticipantDetailsArgs = {
  fundraiserId: Scalars['ID'];
};


export type QueryParticipantCampaignConfigurationDataArgs = {
  campaignID: Scalars['ID'];
};


export type QueryParticipantCheerwallArgs = {
  fundraiserId: Scalars['String'];
  participantId: Scalars['String'];
  take: Scalars['Int'];
};


export type QueryParticipantDonationsRaisedArgs = {
  fundraiserId: Scalars['Int'];
  userId: Scalars['Int'];
};


export type QueryParticipantEarnedRewardsDetailsArgs = {
  fundraiserId: Scalars['String'];
  userId: Scalars['Int'];
};


export type QueryParticipantFundraiserArgs = {
  fundraiserId: Scalars['String'];
};


export type QueryParticipantFundraiserRewardArgs = {
  raiseId: Scalars['Int'];
};


export type QueryParticipantFundraisersArgs = {
  udid: Scalars['String'];
};


export type QueryParticipantPublicArgs = {
  fundraiserId: Scalars['Int'];
  userId: Scalars['Int'];
};


export type QueryParticipantRewardsDetailsArgs = {
  fundraiserId: Scalars['String'];
  userId: Scalars['Int'];
};


export type QueryParticipantSsoIdArgs = {
  fundraiserId: Scalars['Int'];
  userId: Scalars['Int'];
};


export type QueryParticipantShareMessageArgs = {
  fundraiserId: Scalars['String'];
  msgType: Scalars['String'];
};


export type QueryParticipantTextTemplateArgs = {
  fundraiserId: Scalars['Int'];
  userId: Scalars['Int'];
};


export type QueryParticipantTopDonationArgs = {
  fundraiserId: Scalars['String'];
  participantId: Scalars['String'];
};


export type QueryParticipantsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  participantId?: InputMaybe<Scalars['Int']>;
};


export type QueryParticipantsListV2Args = {
  fundraiserId: Scalars['Int'];
};


export type QueryParticipantsPublicArgs = {
  fundraiserId: Scalars['Int'];
};


export type QueryPaymentsApiCustomerByEmailArgs = {
  input: PaymentsApiCustomerInput;
};


export type QueryPayoutsArgs = {
  campaignId: Scalars['Int'];
};


export type QueryPrefundArgs = {
  fundraiserId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
};


export type QueryPrefundsArgs = {
  fundraiser?: InputMaybe<FundraiserSearchParams>;
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  organization?: InputMaybe<OrganizationSearchParams>;
  status?: InputMaybe<Array<InputMaybe<Fundraiser_Status>>>;
};


export type QueryPreparationArgs = {
  input?: InputMaybe<GetPreparationByIdInput>;
};


export type QueryPreparationReportSheetsArgs = {
  input?: InputMaybe<CommonSheetFiltersInput>;
};


export type QueryPrimaryGroupLeaderArgs = {
  campaignId: Scalars['String'];
};


export type QueryPublicFundraiserDataArgs = {
  fundraiserId?: InputMaybe<Scalars['Int']>;
  identifier?: InputMaybe<Scalars['String']>;
};


export type QueryPublicUserDataArgs = {
  userId: Scalars['Int'];
};


export type QueryRaiseAdminFundraiserPastArgs = {
  id: Scalars['Int'];
};


export type QueryRoleArgs = {
  id: Scalars['String'];
};


export type QueryRolesArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QuerySchoolInfoArgs = {
  input?: InputMaybe<GetSchoolInfoByIdInput>;
};


export type QuerySearchDriveCampaignsArgs = {
  activityTypes?: InputMaybe<Array<Scalars['String']>>;
  campaignName?: InputMaybe<Scalars['String']>;
  campaignStatuses?: InputMaybe<Array<CampaignStatus>>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  fundraiserId?: InputMaybe<Scalars['Int']>;
  hasIncentive?: InputMaybe<Scalars['Boolean']>;
  isPendingSettlement?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  maxTeamSize?: InputMaybe<Scalars['Int']>;
  maxTotalRaisedCents?: InputMaybe<Scalars['Int']>;
  minTeamSize?: InputMaybe<Scalars['Int']>;
  minTotalRaisedCents?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  organizationIds?: InputMaybe<Array<Scalars['Int']>>;
  organizationTypes?: InputMaybe<Array<Scalars['String']>>;
  salesrepIds?: InputMaybe<Array<Scalars['Int']>>;
  sortDirection?: InputMaybe<SortDirection>;
  sortField?: InputMaybe<SortField>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  territories?: InputMaybe<Array<Scalars['String']>>;
  usStates?: InputMaybe<Array<Scalars['String']>>;
};


export type QuerySearchDriveOrganizationsByNameArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  organizationName?: InputMaybe<Scalars['String']>;
};


export type QuerySeasonArgs = {
  input?: InputMaybe<SeasonByIdInput>;
};


export type QuerySeasonByYearAndTeamArgs = {
  input?: InputMaybe<SeasonByYearTeamInput>;
};


export type QuerySeasonEventWizardArgs = {
  input?: InputMaybe<SeasonEventWizardInput>;
};


export type QueryShipmentsArgs = {
  externalId?: InputMaybe<ShipmentInput>;
};


export type QuerySpendAccountExportArgs = {
  status: Scalars['String'];
};


export type QuerySpendBudgetArgs = {
  id: Scalars['String'];
};


export type QuerySpendBudgetTransactionsArgs = {
  input?: InputMaybe<SpendBudgetTransactionInput>;
};


export type QuerySpendBudgetsArgs = {
  groupId?: InputMaybe<Scalars['String']>;
};


export type QuerySpendBudgetsSummaryArgs = {
  groupId: Scalars['String'];
  seasonId: Scalars['String'];
};


export type QuerySpendCategoriesArgs = {
  filterBy?: InputMaybe<CategoryFilterEnum>;
  filterValue?: InputMaybe<Scalars['String']>;
  groupId?: InputMaybe<Scalars['String']>;
};


export type QuerySpendDataForHomeArgs = {
  udid: Scalars['String'];
};


export type QuerySpendDebitCardInvitesArgs = {
  groupId?: InputMaybe<Scalars['String']>;
};


export type QuerySpendGroupBankAccountsArgs = {
  groupId?: InputMaybe<Scalars['String']>;
};


export type QuerySpendGroupBankLinkTokenCreateArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QuerySpendGroupByIdArgs = {
  id: Scalars['String'];
};


export type QuerySpendGroupDebitCardsArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QuerySpendGroupPayeesArgs = {
  groupId: Scalars['String'];
};


export type QuerySpendGroupRosterByIdArgs = {
  id: Scalars['String'];
};


export type QuerySpendGroupRostersArgs = {
  filterBy?: InputMaybe<GroupRosterFilterEnum>;
  filterValue?: InputMaybe<Scalars['String']>;
};


export type QuerySpendGroupRostersBySeasonArgs = {
  pagination?: InputMaybe<SpendPaginationInput>;
  seasonId: Scalars['String'];
};


export type QuerySpendGroupsFilteredArgs = {
  pagination?: InputMaybe<SpendPaginationInput>;
  sort?: InputMaybe<SpendSortInput>;
  where?: InputMaybe<SpendGroupsWhereInput>;
};


export type QuerySpendGroupsOverviewDashboardArgs = {
  pagination?: InputMaybe<SpendPaginationInput>;
};


export type QuerySpendGroupsPublicArgs = {
  orgId: Scalars['String'];
};


export type QuerySpendGuardianInvoicesArgs = {
  filters?: InputMaybe<Array<InputMaybe<SpendGuardianInvoiceFilter>>>;
};


export type QuerySpendInviteArgs = {
  id: Scalars['String'];
};


export type QuerySpendInvitesArgs = {
  filterBy?: InputMaybe<InviteFilterEnum>;
  filterValue?: InputMaybe<Scalars['String']>;
};


export type QuerySpendInvitesFilteredArgs = {
  pagination?: InputMaybe<SpendPaginationInput>;
  sort?: InputMaybe<SpendSortInput>;
  where?: InputMaybe<SpendInviteWhereInput>;
};


export type QuerySpendInvoiceByIdArgs = {
  id: Scalars['String'];
};


export type QuerySpendInvoiceHistoryArgs = {
  input?: InputMaybe<SpendInvoiceHistoryInput>;
};


export type QuerySpendInvoicesArgs = {
  filterBy?: InputMaybe<InvoiceFilterEnum>;
  filterValue?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  parentSignUp?: InputMaybe<Scalars['Boolean']>;
};


export type QuerySpendInvoicesExportArgs = {
  filters?: InputMaybe<Array<InputMaybe<SpendInvoiceFilter>>>;
  limit?: InputMaybe<Scalars['Int']>;
};


export type QuerySpendInvoicesExportV2Args = {
  filters?: InputMaybe<Array<InputMaybe<SpendInvoiceFilter>>>;
  sort?: InputMaybe<SpendSortInput>;
};


export type QuerySpendInvoicesFilteredArgs = {
  filters?: InputMaybe<Array<InputMaybe<SpendInvoiceFilter>>>;
  limit?: InputMaybe<Scalars['Int']>;
};


export type QuerySpendOrganizationAccountLimitsArgs = {
  groupId?: InputMaybe<Scalars['String']>;
};


export type QuerySpendOrganizationByIdArgs = {
  id: Scalars['String'];
};


export type QuerySpendOrganizationPayeesArgs = {
  orgId: Scalars['String'];
};


export type QuerySpendOrganizationsArgs = {
  pagination?: InputMaybe<SpendPaginationInput>;
  where?: InputMaybe<SpendOrganizationsWhereInput>;
};


export type QuerySpendPaginatedInvoicesArgs = {
  filters?: InputMaybe<Array<InputMaybe<SpendInvoiceFilter>>>;
  pagination?: InputMaybe<SpendPaginationInput>;
  sort?: InputMaybe<SpendSortInput>;
};


export type QuerySpendPastDueInvoicesArgs = {
  interval?: InputMaybe<SpendPastDueInterval>;
  pagination?: InputMaybe<SpendPaginationInput>;
  sort?: InputMaybe<SpendPastDueSort>;
};


export type QuerySpendPaymentScheduleByIdArgs = {
  id: Scalars['String'];
};


export type QuerySpendPaymentSchedulesArgs = {
  groupIdOrSeasonId?: InputMaybe<Scalars['String']>;
};


export type QuerySpendRoleCurrentArgs = {
  sessionId: Scalars['String'];
};


export type QuerySpendRostersArgs = {
  filterBy?: InputMaybe<RosterFilterEnum>;
  filterValue?: InputMaybe<Scalars['String']>;
};


export type QuerySpendRostersFilteredArgs = {
  pagination?: InputMaybe<SpendPaginationInput>;
  sort?: InputMaybe<SpendSortInput>;
  where?: InputMaybe<SpendGroupRostersWhereInput>;
};


export type QuerySpendSeasonArgs = {
  id: Scalars['String'];
};


export type QuerySpendTransactionArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QuerySpendTransactionAttachmentArgs = {
  id: Scalars['String'];
};


export type QuerySpendTransactionAttachmentsByPaymentIdArgs = {
  id: Scalars['String'];
};


export type QuerySpendTransactionNotesArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QuerySpendTransactionNotesByPaymentIdArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QuerySpendTransactionsArgs = {
  filterBy?: InputMaybe<TransactionFilterEnum>;
  filterValue?: InputMaybe<Scalars['String']>;
  groupId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
};


export type QuerySpendTransactionsExportArgs = {
  filters?: InputMaybe<Array<InputMaybe<SpendTransactionFilter>>>;
  limit?: InputMaybe<Scalars['Int']>;
};


export type QuerySpendTransactionsFilteredArgs = {
  allAccounts?: InputMaybe<Scalars['Boolean']>;
  disableCache?: InputMaybe<Scalars['Boolean']>;
  filters?: InputMaybe<Array<InputMaybe<SpendTransactionFilter>>>;
  pagination?: InputMaybe<SpendPaginationInput>;
  where?: InputMaybe<SpendTransactionWhereInput>;
};


export type QuerySpendTransactionsLegacyArgs = {
  groupId?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<SpendPaginationInput>;
};


export type QuerySpendUserAccountBearerTokenArgs = {
  input?: InputMaybe<SpendAccountBearerToken>;
};


export type QuerySpendUserExportEmailsArgs = {
  input: SpendExportEmailsInput;
};


export type QuerySponsorDataForHomeArgs = {
  orgId: Scalars['String'];
};


export type QueryStoreBestSellerArgs = {
  scopeId: Scalars['Int'];
};


export type QueryStoreByScopeIdArgs = {
  scopeId: Scalars['Int'];
};


export type QueryStoreCampaignPayableInfoArgs = {
  input?: InputMaybe<StoreCampaignPayableInfoInput>;
};


export type QueryStoreDataForHomeArgs = {
  fundraiserId?: InputMaybe<Array<Scalars['Int']>>;
  uEmail: Scalars['String'];
};


export type QueryStoreEarnedPointsGraphArgs = {
  input?: InputMaybe<StorePointEarnedGraphInput>;
};


export type QueryStoreGlCampaignsArgs = {
  input?: InputMaybe<StoreUserCampaignsInput>;
};


export type QueryStoreGlParticipantsArgs = {
  input?: InputMaybe<StoreUserParticipantsInput>;
};


export type QueryStoreOrderFilterArgs = {
  input?: InputMaybe<OrderFilterInput>;
};


export type QueryStoreOrderItemImagesArgs = {
  itemIds: Array<Scalars['Int']>;
};


export type QueryStoreOrderSalesGraphArgs = {
  input?: InputMaybe<OrderGraphInput>;
};


export type QueryStoreOrderSummaryArgs = {
  scopeId: Scalars['Int'];
};


export type QueryStoreOrdersCsvArgs = {
  scopeId: Scalars['Int'];
};


export type QueryStorePayableFilterArgs = {
  input?: InputMaybe<StoreBaseFilterInput>;
};


export type QueryStorePointActivityFilterArgs = {
  input?: InputMaybe<PointsActivityFilterInput>;
};


export type QueryStorePointActivityFilterAdvanceArgs = {
  input?: InputMaybe<PointsActivityFilterInput>;
};


export type QueryStorePointsActivityCsvArgs = {
  scopeId: Scalars['Int'];
};


export type QueryStorePointsActivityCsvAdvanceArgs = {
  scopeId: Scalars['Int'];
};


export type QueryStorePointsWithdrawalRequestFilterArgs = {
  input?: InputMaybe<StoreBaseFilterInput>;
};


export type QueryStoreProfitPercentageArgs = {
  storeId: Scalars['Int'];
};


export type QueryStoreScopePayableInfoArgs = {
  scopeId: Scalars['Int'];
};


export type QueryStoreTicketsArgs = {
  scopeId: Scalars['Int'];
};


export type QueryStoreUserDetailsArgs = {
  email: Scalars['String'];
};


export type QueryStoreUserPreferenceArgs = {
  userId: Scalars['String'];
};


export type QueryStoresByCampaignIdsArgs = {
  input?: InputMaybe<StoreByCampaignIdsInput>;
};


export type QueryStoresByGlEmailArgs = {
  input?: InputMaybe<StoreByGlEmailInput>;
};


export type QueryStoresSummaryByCampaignIdsArgs = {
  input?: InputMaybe<StoreSummaryByCampaignIdsInput>;
};


export type QueryStoresSummaryByGlEmailArgs = {
  input?: InputMaybe<StoresSummaryByGlEmailInput>;
};


export type QueryTeamArgs = {
  input?: InputMaybe<TeamByCodeInput>;
};


export type QueryTeamOfficialsArgs = {
  input?: InputMaybe<OfficialByIdInput>;
};


export type QueryTeamOrgSchedulesArgs = {
  input?: InputMaybe<CommonSchedulesFiltersInput>;
};


export type QueryTeamPreparationsArgs = {
  input?: InputMaybe<PreparationByIdInput>;
};


export type QueryTeamWorkersArgs = {
  input?: InputMaybe<WorkerByIdInput>;
};


export type QueryTransactionDetailArgs = {
  transactionId: Scalars['UUID'];
};


export type QueryTransactionsArgs = {
  input: TransactionsInput;
};


export type QueryTransactionsByAccountArgs = {
  input: AccountTransactionsInput;
};


export type QueryTransactionsByAccountPaginatedArgs = {
  input: AccountTransactionsPaginatedInput;
};


export type QueryTransactionsBySpendDestinationArgs = {
  input: TransactionsInput;
};


export type QueryTransactionsBySpendSourceArgs = {
  input: TransactionsInput;
};


export type QueryTwispCardBalancesArgs = {
  index?: InputMaybe<TwispCardBalances_Indexes>;
  limit?: InputMaybe<Scalars['Int32']>;
  scope?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Sort>;
  where?: InputMaybe<Where>;
};


export type QueryTwispCardsArgs = {
  index?: InputMaybe<TwispCards_Indexes>;
  limit?: InputMaybe<Scalars['Int32']>;
  scope?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Sort>;
  where?: InputMaybe<Where>;
};


export type QueryTwispStripeHooksArgs = {
  index?: InputMaybe<TwispStripeHooks_Indexes>;
  limit?: InputMaybe<Scalars['Int32']>;
  scope?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Sort>;
  where?: InputMaybe<Where>;
};


export type QueryTwispTransactionsArgs = {
  index?: InputMaybe<TwispTransactions_Indexes>;
  limit?: InputMaybe<Scalars['Int32']>;
  scope?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Sort>;
  where?: InputMaybe<Where>;
};


export type QueryUnconfirmedEventsArgs = {
  input?: InputMaybe<UnconfirmedEventsFiltersInput>;
};


export type QueryUpdateSavedSalesRepArgs = {
  snapRepEmail: Scalars['String'];
  udId: Scalars['String'];
};


export type QueryUserArgs = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};


export type QueryUserActivityLeadershipArgs = {
  userId: Scalars['ID'];
};


export type QueryUserAffiliationsArgs = {
  userId: Scalars['ID'];
};


export type QueryUserApiKeyArgs = {
  id: Scalars['String'];
};


export type QueryUserApiKeyBySecretArgs = {
  secret: Scalars['String'];
};


export type QueryUserAssociationCampaignsArgs = {
  product: Product;
  userId: Scalars['ID'];
};


export type QueryUserAssociationsArgs = {
  id: Scalars['ID'];
  product: Product;
};


export type QueryUserBySecretArgs = {
  secret: Scalars['String'];
};


export type QueryUserFinancialAccountsArgs = {
  userId: Scalars['ID'];
};


export type QueryUserGroupLeadershipArgs = {
  userId: Scalars['ID'];
};


export type QueryUserImpliedAffiliationsArgs = {
  userId: Scalars['ID'];
};


export type QueryUserOrgAffiliationArgs = {
  orgId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type QueryUserParticipationArgs = {
  userId: Scalars['ID'];
};


export type QueryUserPermissionsArgs = {
  userId?: InputMaybe<Scalars['String']>;
};


export type QueryUserPublicArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryUserPublicInfoArgs = {
  id?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type QueryUserTeamLeadershipArgs = {
  userId: Scalars['ID'];
};


export type QueryUserTokenArgs = {
  expirationTime?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryUserUnconfirmedAffiliationsArgs = {
  userId: Scalars['ID'];
};


export type QueryUsersArgs = {
  isInternal?: InputMaybe<Scalars['Boolean']>;
  limit?: Scalars['Int'];
  offset?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  snapRaiseId?: InputMaybe<Scalars['Int']>;
  snapSpendId?: InputMaybe<Scalars['Int']>;
};


export type QueryVaultAccountStatusArgs = {
  accountId?: InputMaybe<Scalars['ID']>;
  accountRepresentativeGatewayId?: InputMaybe<Scalars['String']>;
  applicationId?: InputMaybe<Scalars['ID']>;
  beneficialOwnersGatewayIds?: InputMaybe<Array<Scalars['String']>>;
  provider: VaultFinancialProvider;
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};


export type QueryVaultCardArgs = {
  id: Scalars['String'];
};


export type QueryVaultKybArgs = {
  id: Scalars['ID'];
};


export type QueryVaultKycArgs = {
  id: Scalars['ID'];
};


export type QueryVaultStripeConnectAccountBankAccountListFetchArgs = {
  stripeConnectAccountId: Scalars['String'];
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};


export type QueryVaultStripeConnectAccountFetchArgs = {
  stripeConnectAccountId: Scalars['ID'];
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};


export type QueryVaultStripeConnectAccountsBankAccountListFetchArgs = {
  stripeConnectAccountIds?: InputMaybe<Array<Scalars['String']>>;
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};


export type QueryVaultStripeInvalidRepresentativeArgs = {
  stripeConnectAccountId: Scalars['ID'];
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};


export type QueryVaultVgsValueArgs = {
  value: Scalars['String'];
};


export type QueryVehicleArgs = {
  input?: InputMaybe<GetVehicleByIdInput>;
};


export type QueryWorkerPoolByIdArgs = {
  input?: InputMaybe<GetWorkerPoolByIdInput>;
};

export type RaiseAdminFundraiser = {
  __typename?: 'RaiseAdminFundraiser';
  /** End date of upcoming fundraiser */
  endDate?: Maybe<Scalars['DateTime']>;
  /** Raise Resource Entity ID of the fundraiser */
  entityId?: Maybe<Scalars['Int']>;
  /** Int of participants from previous year */
  groupSize?: Maybe<Scalars['Int']>;
  /** Fundraiser ID */
  id?: Maybe<Scalars['Int']>;
  /** Fundraiser Image URL */
  image?: Maybe<Scalars['String']>;
  /** Fundraiser name */
  name?: Maybe<Scalars['String']>;
  /** A query for raise admin users to get upcoming resign participants */
  participantListForFundraiser?: Maybe<RaiseAdminParticipantList>;
  /** Has the fundraiser been rolled over? */
  rollover?: Maybe<Scalars['Boolean']>;
  /** Start date of upcoming fundraiser */
  startDate?: Maybe<Scalars['DateTime']>;
};


export type RaiseAdminFundraiserParticipantListForFundraiserArgs = {
  filter?: InputMaybe<RaiseAdminParticipantFilter>;
};

export type RaiseAdminFundraiserPast = {
  __typename?: 'RaiseAdminFundraiserPast';
  /** Current year's fundraiser */
  current?: Maybe<RaiseAdminFundraiser>;
  /** Last year's fundraiser */
  past?: Maybe<RaiseAdminFundraiser>;
};

export type RaiseAdminParticipant = {
  __typename?: 'RaiseAdminParticipant';
  /** Participant email */
  email?: Maybe<Scalars['String']>;
  /** Participant first name */
  firstName?: Maybe<Scalars['String']>;
  /** Participant ID */
  id?: Maybe<Scalars['Int']>;
  /** Participant last name */
  lastName?: Maybe<Scalars['String']>;
  /** Participant's Profile Image */
  profileImage?: Maybe<Scalars['String']>;
};

export type RaiseAdminParticipantFilter = {
  /** Conditions to order by */
  orderBy?: InputMaybe<RaiseAdminParticipantOrderInput>;
  /** Conditions to filter by */
  where?: InputMaybe<RaiseAdminParticipantWhereInput>;
};

export type RaiseAdminParticipantList = {
  __typename?: 'RaiseAdminParticipantList';
  /** Total number of fundraisers */
  count: Scalars['Int'];
  /** List of fundraisers */
  list?: Maybe<Array<Maybe<RaiseAdminParticipant>>>;
};

export type RaiseAdminParticipantOrderInput = {
  email?: InputMaybe<SortOrderEnum>;
  id?: InputMaybe<SortOrderEnum>;
  name?: InputMaybe<SortOrderEnum>;
};

export type RaiseAdminParticipantWhereInput = {
  /** Nullable String Filter for Participant email */
  email?: InputMaybe<Scalars['String']>;
  /** Nullable String Filter for Participant name */
  name?: InputMaybe<Scalars['String']>;
  /** Nullable Boolean Filter for Participant Rollover */
  rollover?: InputMaybe<Scalars['Boolean']>;
};

export type RaiseFundraiserUsersCreateManyInput = {
  fundraiser_id: Scalars['Int'];
  user_ids: Array<Scalars['Int']>;
};

export type RaiseUser = {
  __typename?: 'RaiseUser';
  email?: Maybe<Scalars['String']>;
  /** SSO User ID */
  id?: Maybe<Scalars['String']>;
};

export type ReceiptResult = {
  __typename?: 'ReceiptResult';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  transactionId: Scalars['ID'];
};

export type ReconcileBudget = {
  amount: Scalars['Int'];
  budgetItemId: Scalars['String'];
};

export type ReconcileInvoice = {
  amount: Scalars['Int'];
  budgetItemId?: InputMaybe<Scalars['String']>;
  invoiceId: Scalars['String'];
};

export enum RequestStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Delivered = 'DELIVERED',
  DeliveredWithFailed = 'DELIVERED_WITH_FAILED',
  Failed = 'FAILED',
  Pending = 'PENDING'
}

export type ResendReceiptsResult = {
  __typename?: 'ResendReceiptsResult';
  results: Array<ReceiptResult>;
};

export enum ResponseStatus {
  Failure = 'FAILURE',
  Success = 'SUCCESS'
}

export type RevisionFundraiserApprovalSubmissionData = {
  __typename?: 'RevisionFundraiserApprovalSubmissionData';
  isSsoApprover: Scalars['Boolean'];
  submitterName: Scalars['String'];
};

export type RevisionFundraiserApprovalSubmissionResult = InvalidToken | RevisionFundraiserApprovalSubmissionData | UnprocessableSubmission;

export enum RewardStatus {
  Delivered = 'DELIVERED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Sent = 'SENT'
}

export type Role = {
  __typename?: 'Role';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<RolePermission>>>;
  scope?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type RoleArguments = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  scope?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type RolePermission = {
  __typename?: 'RolePermission';
  id?: Maybe<Scalars['String']>;
  permission?: Maybe<Permission>;
  permissionId?: Maybe<Scalars['String']>;
  roleId?: Maybe<Scalars['String']>;
};

export type Roster = {
  __typename?: 'Roster';
  id?: Maybe<Scalars['ID']>;
  members?: Maybe<Array<Maybe<RosterMember>>>;
  name?: Maybe<Scalars['String']>;
};

export enum RosterFilterEnum {
  GroupName = 'groupName',
  MemberName = 'memberName',
  RosterName = 'rosterName',
  SeasonName = 'seasonName'
}

export type RosterMember = {
  __typename?: 'RosterMember';
  association?: Maybe<CampaignMemberAssociation>;
  id?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['String']>;
  wasDeleted?: Maybe<Scalars['Boolean']>;
};

export type S3PresignedInsightsApprovalsUrlResult = {
  __typename?: 'S3PresignedInsightsApprovalsUrlResult';
  expires: Scalars['DateTime'];
  filename: Scalars['String'];
  s3Filename: Scalars['String'];
  status: Scalars['String'];
  url: Scalars['String'];
};

export type SdPagination = {
  __typename?: 'SDPagination';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['BigInt']>;
};

export enum Shipping_Service {
  Express = 'express',
  Priority = 'priority',
  Standard = 'standard'
}

/** Owned by Vault Only used in legacy createCard */
export enum Spending_Limit_Interval {
  AllTime = 'all_time',
  Daily = 'daily',
  Monthly = 'monthly',
  PerAuthorization = 'per_authorization',
  Weekly = 'weekly',
  Yearly = 'yearly'
}

export enum Support_Ticket_Department {
  Accounts = 'ACCOUNTS',
  Hubspot = 'HUBSPOT',
  It = 'IT',
  Pep = 'PEP',
  Sales = 'SALES',
  Sponsor = 'SPONSOR',
  Store = 'STORE',
  Wallet = 'WALLET',
  Warehouse = 'WAREHOUSE'
}

export enum SalesrepType {
  AccountManager = 'ACCOUNT_MANAGER',
  SalesRep = 'SALES_REP'
}

/** Information of a emails scheduled for cron jobs */
export type ScheduledReleaseInfo = {
  __typename?: 'ScheduledReleaseInfo';
  /** The date and time for release */
  date?: Maybe<Scalars['DateTime']>;
  /** The timezone for scheduled release */
  timezone?: Maybe<Scalars['String']>;
};

export enum SchoolCategory {
  Charter = 'CHARTER',
  Magnet = 'MAGNET',
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type SchoolInfo = {
  __typename?: 'SchoolInfo';
  Schoolname?: Maybe<Scalars['String']>;
  ad?: Maybe<Scalars['String']>;
  ad_contract_signee?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  ccemail?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  conf_text_type?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  email_reminder?: Maybe<Scalars['String']>;
  email_reminder_officials?: Maybe<Scalars['String']>;
  email_reminder_workers?: Maybe<Scalars['String']>;
  enable_cc_email_as_origin?: Maybe<Scalars['String']>;
  enable_ml_updates?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  mascot?: Maybe<Scalars['String']>;
  message_board_read_at?: Maybe<Scalars['Date']>;
  ml_key?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  principal?: Maybe<Scalars['String']>;
  school_timezone?: Maybe<Scalars['String']>;
  secondary_ad_email?: Maybe<Scalars['String']>;
  secondary_ad_name?: Maybe<Scalars['String']>;
  signed_contract_notification?: Maybe<Scalars['String']>;
  ssn_on_file?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  state_org?: Maybe<Scalars['String']>;
  state_org_abbreviation?: Maybe<Scalars['String']>;
  use_security?: Maybe<Scalars['String']>;
  web_password?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type ScopeProductColor = {
  __typename?: 'ScopeProductColor';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
  visual?: Maybe<Scalars['String']>;
};

export type ScoreForOrganizationFilterOld = {
  orderBy?: InputMaybe<ScoreForOrganizationOrderByOld>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScoreForOrganizationWhereOld>;
};

/** Specifies the order in which the scores list should be sorted. */
export type ScoreForOrganizationOrderByOld = {
  /** Orders scores based on their confirmation status. */
  event_date?: InputMaybe<SortOrderEnum>;
};

export type ScoreForOrganizationWhereOld = {
  /** Specifies the list of gs in which the scores list should be filter. */
  gs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specifies the years which the scores list should be filter. */
  years?: InputMaybe<Scalars['String']>;
};

export type Season = {
  __typename?: 'Season';
  budget?: Maybe<Scalars['Float']>;
  created_at?: Maybe<Scalars['Date']>;
  default_time_for_event?: Maybe<Scalars['String']>;
  default_time_for_practice?: Maybe<Scalars['String']>;
  home_field?: Maybe<Scalars['String']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  preview?: Maybe<Scalars['String']>;
  season?: Maybe<Scalars['String']>;
  season_id: Scalars['Int'];
  sport?: Maybe<Team>;
  sport_code?: Maybe<Scalars['String']>;
  web_password?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

export type SeasonByIdInput = {
  season_id: Scalars['Int'];
};

export type SeasonByYearTeamInput = {
  sport_code?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['String']>;
};

export type SeasonCreateInput = {
  budget?: InputMaybe<Scalars['Float']>;
  created_at?: InputMaybe<Scalars['Date']>;
  default_time_for_event?: InputMaybe<Scalars['String']>;
  default_time_for_practice?: InputMaybe<Scalars['String']>;
  home_field?: InputMaybe<Scalars['String']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  preview?: InputMaybe<Scalars['String']>;
  season?: InputMaybe<Scalars['String']>;
  season_id: Scalars['Int'];
  sport_code?: InputMaybe<Scalars['String']>;
  web_password?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['String']>;
};

export type SeasonDeleteInput = {
  season_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type SeasonEventWizardInput = {
  season_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type SeasonModifyInput = {
  budget?: InputMaybe<Scalars['Float']>;
  created_at?: InputMaybe<Scalars['Date']>;
  default_time_for_event?: InputMaybe<Scalars['String']>;
  default_time_for_practice?: InputMaybe<Scalars['String']>;
  home_field?: InputMaybe<Scalars['String']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  preview?: InputMaybe<Scalars['String']>;
  season?: InputMaybe<Scalars['String']>;
  season_id: Scalars['Int'];
  sport_code?: InputMaybe<Scalars['String']>;
  web_password?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['String']>;
};

export type SeasonPostponeUnpostponeInput = {
  event_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  season_id?: InputMaybe<Scalars['Int']>;
};

export type SeasonScheduleTeamsCreateInput = {
  sport_codes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  year?: InputMaybe<Scalars['String']>;
};

export type SendEmailResponse = {
  __typename?: 'SendEmailResponse';
  success: Scalars['Boolean'];
};

export enum SetUserPreferenceFlag {
  Off = 'OFF',
  On = 'ON'
}

/** Settlement details for closed campaign. */
export type SettlementDetails = {
  __typename?: 'SettlementDetails';
  /** The amount in cents that campaigns was advanced in Wallet. */
  advanceAmount?: Maybe<Scalars['Int']>;
  /** Address to send out check to */
  checkingAddress?: Maybe<Scalars['String']>;
  /** The type of snap fee being collected. e.g. 'standard', '1+1', '20%/15%', etc. */
  feeType?: Maybe<Scalars['String']>;
  /** Total cost of incentives in cents */
  gearCost?: Maybe<Scalars['Int']>;
  /** Code to build logo */
  incLogo?: Maybe<Scalars['String']>;
  /** Primary color to build logo */
  incLogoPrimaryColor?: Maybe<Scalars['String']>;
  /** Script to build logo */
  incLogoScript?: Maybe<Scalars['String']>;
  /** Secondary color to build logo */
  incLogoSecondaryColor?: Maybe<Scalars['String']>;
  /** Filename of Logo of Group of Campaign */
  logoImage?: Maybe<Scalars['String']>;
  /** The amount in cents that campaigns was manual-advanced in Raise. */
  manualAdvanceAmount?: Maybe<Scalars['Int']>;
  /** Net raised amount in cents. Equals totalRaised minus snapFee and adds the otkBonus. It is the amount client received in their check/deposit. */
  netRaisedTotal?: Maybe<Scalars['Int']>;
  /** Notes of campaign */
  notes?: Maybe<Scalars['String']>;
  /** Legal Name of Organization */
  organizationLegalName?: Maybe<Scalars['String']>;
  /** TIN of Organization */
  organizationTin?: Maybe<Scalars['String']>;
  /** Total bonus amount that team will receive through otk purchase cost (usually 10%) in cents. */
  otkBonus?: Maybe<Scalars['Int']>;
  /** Link to OTK Logo */
  otkLogoUrl?: Maybe<Scalars['String']>;
  /** Percentage of participation of campaign */
  participationPercent?: Maybe<Scalars['Int']>;
  /** Notes for settlement for this campaign */
  settlementNotes?: Maybe<Scalars['String']>;
  /** Default Shipping address to send out gears/merchandise/check */
  shippingAddress?: Maybe<Scalars['String']>;
  /** Total Fee in cents that Raise collects from campaigns */
  snapFee?: Maybe<Scalars['Int']>;
};

/** Fixed statuses for a campaigns settlement status */
export enum SettlementStatus {
  InReview = 'IN_REVIEW',
  Unsubmitted = 'UNSUBMITTED',
  Verified = 'VERIFIED'
}

/** Attributes for settlement status */
export type SettlementStatusUpdate = {
  __typename?: 'SettlementStatusUpdate';
  fundraiserId?: Maybe<Scalars['Int']>;
  settlementStatus?: Maybe<SettlementStatus>;
};

export type Shipment = {
  __typename?: 'Shipment';
  carrier?: Maybe<ShipmentCarrier>;
  customer: Scalars['String'];
  destination?: Maybe<Scalars['String']>;
  externalId: ShipmentExternalId;
  netsuiteId: Scalars['String'];
  netsuiteSoTranId?: Maybe<Scalars['String']>;
  netsuiteTranId?: Maybe<Scalars['String']>;
  shippedDate?: Maybe<Scalars['Timestamp']>;
  source?: Maybe<ShipmentSource>;
  status?: Maybe<ShipmentStatus>;
  trackingNumbers: Array<Maybe<Scalars['String']>>;
  updated: Scalars['Timestamp'];
  workflowStage: Scalars['String'];
};

export type ShipmentCampaignId = {
  __typename?: 'ShipmentCampaignId';
  campaignId: Scalars['Int'];
};

export enum ShipmentCarrier {
  Fedex = 'FEDEX',
  Unknown = 'UNKNOWN',
  Usps = 'USPS'
}

export type ShipmentExternalId = ShipmentCampaignId | ShipmentOtherId;

export type ShipmentInput = {
  campaignId?: InputMaybe<Scalars['Int']>;
  externalId?: InputMaybe<Scalars['String']>;
};

export type ShipmentOtherId = {
  __typename?: 'ShipmentOtherId';
  externalId: Scalars['UUID'];
};

export type ShipmentResponse = {
  __typename?: 'ShipmentResponse';
  shipments: Array<Maybe<Shipment>>;
  status: ResponseStatus;
};

export enum ShipmentSource {
  Otf = 'OTF',
  OtkIncentive = 'OTK_INCENTIVE',
  Store = 'STORE'
}

export enum ShipmentStatus {
  Processing = 'PROCESSING',
  Shipped = 'SHIPPED'
}

export enum ShippingProvider {
  Dhl = 'DHL',
  FedEx = 'FedEx',
  Osm = 'OSM',
  Ups = 'UPS',
  Usps = 'USPS'
}

export type SignUpResponseWithAvailableTransport = {
  __typename?: 'SignUpResponseWithAvailableTransport';
  availableTransport: Array<TransportEnum>;
  result: Array<TransportType>;
};

export type SignupAgreement = {
  __typename?: 'SignupAgreement';
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type SmsInviteData = {
  __typename?: 'SmsInviteData';
  count?: Maybe<Scalars['Int']>;
  isReleased?: Maybe<Scalars['Boolean']>;
  smsInviteReleasedAt?: Maybe<Scalars['DateTime']>;
};

export enum SnapFeePayer {
  Customer = 'CUSTOMER',
  Snap = 'SNAP'
}

export enum SnapService {
  Campaigns = 'campaigns',
  Comms = 'comms',
  Connect = 'connect',
  Donors = 'donors',
  Drive = 'drive',
  Fanx = 'fanx',
  Fundraisers = 'fundraisers',
  Home = 'home',
  Insights = 'insights',
  Manage = 'manage',
  Orgs = 'orgs',
  Raise = 'raise',
  Spend = 'spend',
  Sponsor = 'sponsor',
  Store = 'store',
  Userdirectory = 'userdirectory',
  Vault = 'vault',
  Wallet = 'wallet'
}

export enum Sort {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum SortField {
  AdvancedFunds = 'ADVANCED_FUNDS',
  ClosedStatus = 'CLOSED_STATUS',
  DaysLeft = 'DAYS_LEFT',
  EndDate = 'END_DATE',
  KycStatus = 'KYC_STATUS',
  Name = 'NAME',
  Participation = 'PARTICIPATION',
  Preload = 'PRELOAD',
  SettlementDetails = 'SETTLEMENT_DETAILS',
  StartDate = 'START_DATE',
  Status = 'STATUS',
  TotalRaised = 'TOTAL_RAISED'
}

export enum SortOrderEnum {
  Asc = 'asc',
  Desc = 'desc'
}

export type SpendAccount = {
  __typename?: 'SpendAccount';
  /** @deprecated removed later */
  account?: Maybe<Scalars['String']>;
  available?: Maybe<Scalars['Int']>;
  balance?: Maybe<Scalars['Int']>;
  groupId?: Maybe<Scalars['String']>;
  groupStatus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  /** @deprecated removed later */
  routing?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type SpendAccountBearerToken = {
  verificationCode?: InputMaybe<Scalars['String']>;
  verificationToken?: InputMaybe<Scalars['String']>;
};

export type SpendAccountBearerTokenResponse = {
  __typename?: 'SpendAccountBearerTokenResponse';
  expiresIn?: Maybe<Scalars['Int']>;
  token?: Maybe<Scalars['String']>;
};

export type SpendAccountId = {
  __typename?: 'SpendAccountID';
  id: Scalars['String'];
  reason?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type SpendAccountLimitsResponse = {
  __typename?: 'SpendAccountLimitsResponse';
  ach?: Maybe<SpendAchLimitsData>;
  bookTransfer?: Maybe<SpendBookTransferLimitsData>;
  card?: Maybe<SpendCardLimitsData>;
  checkDeposit?: Maybe<SpendCheckDepositLimitsData>;
  checkPayment?: Maybe<SpendCheckPaymentLimitData>;
  wire?: Maybe<SpendWireLimitsData>;
};

export type SpendAccountResponse = {
  __typename?: 'SpendAccountResponse';
  accounts?: Maybe<Array<Maybe<SpendAccount>>>;
  count?: Maybe<Scalars['Int']>;
  groupsBalance?: Maybe<SpendGroupAccountBalances>;
  totalBalance?: Maybe<Scalars['Int']>;
};

export type SpendAccountTransferInput = {
  amount: Scalars['Int'];
  description: Scalars['String'];
  fromGroupIdOrOrgId: Scalars['String'];
  toGroupIdOrOrgId: Scalars['String'];
};

export type SpendAchLimits = {
  __typename?: 'SpendAchLimits';
  dailyCredit?: Maybe<Scalars['Int']>;
  dailyDebit?: Maybe<Scalars['Int']>;
  monthlyCredit?: Maybe<Scalars['Int']>;
  monthlyDebit?: Maybe<Scalars['Int']>;
};

export type SpendAchLimitsData = {
  __typename?: 'SpendAchLimitsData';
  limits: SpendAchLimits;
};

export type SpendAchPaymentCancelInput = {
  externalId: Scalars['String'];
  id: Scalars['String'];
};

export type SpendAchPaymentId = {
  __typename?: 'SpendAchPaymentID';
  id?: Maybe<Scalars['String']>;
};

export type SpendAchPaymentInput = {
  authorizedAt: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  externalId: Scalars['String'];
  groupId: Scalars['String'];
  invoiceIds: Array<InputMaybe<Scalars['String']>>;
  paymentAmount: Scalars['Int'];
};

export type SpendAchPaymentResponse = {
  __typename?: 'SpendAchPaymentResponse';
  amount: Scalars['Int'];
  descriptor: Scalars['String'];
  id: Scalars['String'];
  status: Scalars['String'];
  transactionIdList?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SpendAddress = {
  __typename?: 'SpendAddress';
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type SpendAddressInput = {
  city: Scalars['String'];
  state: Scalars['String'];
  street: Scalars['String'];
  street2?: InputMaybe<Scalars['String']>;
  zip: Scalars['String'];
};

export type SpendAgreementId = {
  __typename?: 'SpendAgreementID';
  id?: Maybe<Scalars['String']>;
};

export type SpendAppliedInvoices = {
  amount: Scalars['Int'];
  invoiceId: Scalars['String'];
};

export type SpendArchiveSeasonMembersInput = {
  groupRosterIds: Array<InputMaybe<Scalars['String']>>;
  isArchived: Scalars['Boolean'];
};

export type SpendArchiveSeasonMembersResponse = {
  __typename?: 'SpendArchiveSeasonMembersResponse';
  success: Scalars['Boolean'];
};

export type SpendBankAccessCreateResponse = {
  __typename?: 'SpendBankAccessCreateResponse';
  id?: Maybe<Scalars['String']>;
};

export type SpendBankAccessDeleteResponse = {
  __typename?: 'SpendBankAccessDeleteResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type SpendBankAccount = {
  __typename?: 'SpendBankAccount';
  accountId?: Maybe<Scalars['String']>;
  availableBalance?: Maybe<Scalars['Float']>;
  currentBalance?: Maybe<Scalars['Float']>;
  mask?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  officialName?: Maybe<Scalars['String']>;
  subtype?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type SpendBankAccountsResponse = {
  __typename?: 'SpendBankAccountsResponse';
  externalAccounts?: Maybe<Array<Maybe<SpendBankAccount>>>;
  status?: Maybe<Scalars['String']>;
};

export type SpendBankLinkCreateResponse = {
  __typename?: 'SpendBankLinkCreateResponse';
  expiration?: Maybe<Scalars['String']>;
  linkToken?: Maybe<Scalars['String']>;
  requestId?: Maybe<Scalars['String']>;
};

export type SpendBankTransaction = {
  __typename?: 'SpendBankTransaction';
  amount?: Maybe<Scalars['Int']>;
  attachments?: Maybe<Array<TransactionAttachment>>;
  canCancel?: Maybe<Scalars['Boolean']>;
  correlationId?: Maybe<Scalars['String']>;
  creditMemo?: Maybe<SpendMemo>;
  /** @deprecated Can be found in metadata */
  description?: Maybe<Scalars['String']>;
  destination?: Maybe<Scalars['String']>;
  direction?: Maybe<Scalars['String']>;
  effective?: Maybe<Scalars['String']>;
  external?: Maybe<SpendJoinedExternal>;
  externalId?: Maybe<Scalars['String']>;
  groupRoster?: Maybe<SpendGroupRoster>;
  hasAttachments?: Maybe<Scalars['Boolean']>;
  history?: Maybe<Array<Maybe<SpendBankTransactionHistory>>>;
  id?: Maybe<Scalars['String']>;
  isReconciled?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<SpendBankTransactionMetaData>;
  payee?: Maybe<SpendJoinedPayee>;
  processor?: Maybe<Scalars['String']>;
  reconciliation?: Maybe<SpendReconciledTransaction>;
  snapAmount?: Maybe<Scalars['Int']>;
  source?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  totalReconciled?: Maybe<Scalars['Int']>;
  transactionNote?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<Scalars['String']>;
  transactionType?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  typeReconciled?: Maybe<Scalars['String']>;
};

export type SpendBankTransactionDetail = {
  __typename?: 'SpendBankTransactionDetail';
  amount?: Maybe<Scalars['Int']>;
  canCancel?: Maybe<Scalars['Boolean']>;
  correlationId?: Maybe<Scalars['String']>;
  /** @deprecated Can be found in metadata */
  description?: Maybe<Scalars['String']>;
  destination?: Maybe<Scalars['String']>;
  effective?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  groupRoster?: Maybe<SpendGroupRoster>;
  history?: Maybe<Array<Maybe<SpendBankTransactionHistory>>>;
  id?: Maybe<Scalars['String']>;
  metadata?: Maybe<SpendBankTransactionMetaData>;
  paymentStatus?: Maybe<Scalars['String']>;
  processor?: Maybe<Scalars['String']>;
  reconciliation?: Maybe<SpendReconciledTransaction>;
  snapAmount?: Maybe<Scalars['Int']>;
  source?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type SpendBankTransactionHistory = {
  __typename?: 'SpendBankTransactionHistory';
  date?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
};

export type SpendBankTransactionMetaData = {
  __typename?: 'SpendBankTransactionMetaData';
  account?: Maybe<Scalars['String']>;
  addenda?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['String']>;
  customer?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  destination?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  /** @deprecated Can be found in tags as SpendInvoiceIds */
  invoiceId?: Maybe<Scalars['String']>;
  payment?: Maybe<Scalars['String']>;
  processor?: Maybe<Scalars['String']>;
  /** @deprecated Can be found in tags */
  product?: Maybe<Scalars['String']>;
  snapAmount?: Maybe<Scalars['Int']>;
  source?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  tags?: Maybe<SpendTransactionTags>;
};

export type SpendBankTransactionResponse = {
  __typename?: 'SpendBankTransactionResponse';
  transactionDetail?: Maybe<SpendBankTransactionDetail>;
};

export type SpendBankTransactionsResponse = {
  __typename?: 'SpendBankTransactionsResponse';
  count?: Maybe<Scalars['Int']>;
  transactions?: Maybe<Array<Maybe<SpendBankTransaction>>>;
};

export type SpendBookTransferLimitsData = {
  __typename?: 'SpendBookTransferLimitsData';
  limits: SpendAchLimits;
};

export type SpendBudget = {
  __typename?: 'SpendBudget';
  category: SpendCategory;
  createdAt: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['String'];
  invoices?: Maybe<Array<SpendInvoice>>;
  isDefault?: Maybe<Scalars['Boolean']>;
  reconciledBudgetTotal?: Maybe<Scalars['Int']>;
  reconciledInvoicesTotal?: Maybe<Scalars['Int']>;
  reconciledTotal?: Maybe<Scalars['Int']>;
  reconciledTransactions?: Maybe<Array<SpendReconciledBudgetTransaction>>;
  season?: Maybe<SpendSeason>;
  targetAmount: Scalars['Int'];
  targetDateAt: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
  vaultId?: Maybe<Scalars['String']>;
};

export type SpendBudgetId = {
  __typename?: 'SpendBudgetID';
  id?: Maybe<Scalars['String']>;
};

export type SpendBudgetInput = {
  categoryId: Scalars['String'];
  description: Scalars['String'];
  isDefault?: InputMaybe<Scalars['Boolean']>;
  seasonId?: InputMaybe<Scalars['String']>;
  targetAmount: Scalars['Int'];
  targetDateAt: Scalars['String'];
  vaultId?: InputMaybe<Scalars['String']>;
};

export type SpendBudgetResponse = {
  __typename?: 'SpendBudgetResponse';
  budget?: Maybe<SpendBudget>;
};

export type SpendBudgetSummaryResponse = {
  __typename?: 'SpendBudgetSummaryResponse';
  summaryByCategory?: Maybe<Array<Maybe<SpendCategory>>>;
  summaryUnreconciled?: Maybe<SpendUnreconciledSummary>;
};

export type SpendBudgetTransactionInput = {
  transactionIds: Array<InputMaybe<Scalars['String']>>;
};

export type SpendBudgetTransactionsOutput = {
  __typename?: 'SpendBudgetTransactionsOutput';
  budgetTransactions: Array<Maybe<BudgetTransaction>>;
};

export type SpendBudgetsResponse = {
  __typename?: 'SpendBudgetsResponse';
  budgets?: Maybe<Array<Maybe<SpendBudget>>>;
  count?: Maybe<Scalars['Int']>;
};

export type SpendCancelCheckId = {
  __typename?: 'SpendCancelCheckID';
  id: Scalars['String'];
};

export type SpendCardLimits = {
  __typename?: 'SpendCardLimits';
  dailyCardTransaction: Scalars['Int'];
  dailyDeposit: Scalars['Int'];
  dailyPurchase: Scalars['Int'];
  dailyWithdrawal: Scalars['Int'];
};

export type SpendCardLimitsData = {
  __typename?: 'SpendCardLimitsData';
  limits: SpendCardLimits;
};

export type SpendCategory = {
  __typename?: 'SpendCategory';
  budgets?: Maybe<Array<Maybe<SpendBudget>>>;
  createdAt: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isDefault: Scalars['Boolean'];
  isHidden: Scalars['Boolean'];
  name: Scalars['String'];
  organizationId: Scalars['String'];
  type: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
};

export type SpendCategoryId = {
  __typename?: 'SpendCategoryID';
  id?: Maybe<Scalars['String']>;
};

export type SpendCategoryInput = {
  isDefault?: InputMaybe<Scalars['Boolean']>;
  isHidden?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<CategoryTypeEnum>;
};

export type SpendCategoryResponse = {
  __typename?: 'SpendCategoryResponse';
  categories?: Maybe<Array<Maybe<SpendCategory>>>;
  count?: Maybe<Scalars['Int']>;
};

export type SpendCheckDepositLimits = {
  __typename?: 'SpendCheckDepositLimits';
  daily: Scalars['Int'];
  monthly: Scalars['Int'];
};

export type SpendCheckDepositLimitsData = {
  __typename?: 'SpendCheckDepositLimitsData';
  limits: SpendCheckDepositLimits;
};

export type SpendCheckDepositTagsResponse = {
  __typename?: 'SpendCheckDepositTagsResponse';
  success: Scalars['Boolean'];
};

export type SpendCheckInput = {
  address: SpendAddressInput;
  amount: Scalars['Int'];
  description: Scalars['String'];
  name: Scalars['String'];
  note?: InputMaybe<Scalars['String']>;
  payeeId: Scalars['String'];
  referenceId: Scalars['String'];
  sendAt?: InputMaybe<Scalars['String']>;
};

export type SpendCheckPaymentLimit = {
  __typename?: 'SpendCheckPaymentLimit';
  dailySent: Scalars['Int'];
  monthlySent: Scalars['Int'];
};

export type SpendCheckPaymentLimitData = {
  __typename?: 'SpendCheckPaymentLimitData';
  limits?: Maybe<SpendCheckPaymentLimit>;
};

export type SpendCheckSendResponse = {
  __typename?: 'SpendCheckSendResponse';
  id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type SpendCreditMemoCreateResponse = {
  __typename?: 'SpendCreditMemoCreateResponse';
  ids: Array<Scalars['String']>;
};

export type SpendCreditMemoInput = {
  creditAmount: Scalars['Int'];
  dateToApply: Scalars['String'];
  invoicesApplied: Array<SpendAppliedInvoices>;
  note?: InputMaybe<Scalars['String']>;
};

export type SpendCreditMemoUpdateInput = {
  creditApplied: Scalars['Int'];
  dateToApply: Scalars['String'];
  id: Scalars['String'];
  invoiceId: Scalars['String'];
  isArchived: Scalars['Boolean'];
  note?: InputMaybe<Scalars['String']>;
};

export type SpendCreditMemoUpdateResponse = {
  __typename?: 'SpendCreditMemoUpdateResponse';
  id: Scalars['String'];
};

export type SpendDeauthorizeResponse = {
  __typename?: 'SpendDeauthorizeResponse';
  id?: Maybe<Scalars['String']>;
  notifSuccess?: Maybe<Scalars['Boolean']>;
};

export type SpendDebitCard = {
  __typename?: 'SpendDebitCard';
  expiration?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastFour?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type SpendDebitCardId = {
  __typename?: 'SpendDebitCardID';
  id?: Maybe<Scalars['String']>;
};

export type SpendDebitCardInput = {
  dateOfBirth: Scalars['String'];
  shipping?: InputMaybe<SpendDebitCardShippingInput>;
  userId: Scalars['String'];
};

export type SpendDebitCardResponse = {
  __typename?: 'SpendDebitCardResponse';
  cards?: Maybe<Array<Maybe<SpendDebitCard>>>;
  count?: Maybe<Scalars['Int']>;
};

export type SpendDebitCardShippingInput = {
  city: Scalars['String'];
  postalCode: Scalars['String'];
  state: Scalars['String'];
  street: Scalars['String'];
};

export type SpendExportEmailsInput = {
  emails: Array<InputMaybe<Scalars['String']>>;
};

export type SpendExternalTransferResponse = {
  __typename?: 'SpendExternalTransferResponse';
  amount?: Maybe<Scalars['Int']>;
  descriptor?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type SpendGroup = {
  __typename?: 'SpendGroup';
  accountId?: Maybe<Scalars['String']>;
  archivedAt?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  discountAmount?: Maybe<Scalars['Int']>;
  discountCutOffDate?: Maybe<Scalars['String']>;
  enableDiscount?: Maybe<Scalars['Boolean']>;
  hasAccount?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  isRequireAgreement?: Maybe<Scalars['Boolean']>;
  latestSeason?: Maybe<SpendSeason>;
  legacyAccountId?: Maybe<Scalars['String']>;
  minimumDiscountPurchase?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  organizationFees?: Maybe<SpendOrganizationFee>;
  organizationId?: Maybe<Scalars['String']>;
  programId?: Maybe<Scalars['String']>;
  seasons?: Maybe<Array<Maybe<SpendSeason>>>;
  seasonsList?: Maybe<Array<Maybe<SpendSeason>>>;
  sharedAccount?: Maybe<Scalars['Boolean']>;
  unitAmount?: Maybe<Scalars['Int']>;
};

export type SpendGroupAccountBalances = {
  __typename?: 'SpendGroupAccountBalances';
  active?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Int']>;
};

export type SpendGroupCheckDepositTagsInput = {
  checkDepositId: Scalars['String'];
  groupId?: InputMaybe<Scalars['String']>;
};

export type SpendGroupExternalTransferInput = {
  accountId: Scalars['String'];
  amount: Scalars['Int'];
  direction: ExternalTransferDirectionEnum;
  id: Scalars['String'];
  note: Scalars['String'];
};

export type SpendGroupId = {
  __typename?: 'SpendGroupID';
  id?: Maybe<Scalars['String']>;
};

export type SpendGroupInput = {
  discountAmount?: InputMaybe<Scalars['Int']>;
  discountCutOffDate?: InputMaybe<Scalars['String']>;
  enableDiscount: Scalars['Boolean'];
  hasAccount?: InputMaybe<Scalars['Boolean']>;
  isBudgetShared?: InputMaybe<Scalars['Boolean']>;
  isLinkEnabled: Scalars['Boolean'];
  isRequireAgreement?: InputMaybe<Scalars['Boolean']>;
  minimumDiscountPurchase?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  seasonEndAt: Scalars['String'];
  seasonName?: InputMaybe<Scalars['String']>;
  seasonStartAt: Scalars['String'];
  shareAccount: Scalars['Boolean'];
};

export type SpendGroupResponse = {
  __typename?: 'SpendGroupResponse';
  count?: Maybe<Scalars['Int']>;
  groups?: Maybe<Array<Maybe<SpendGroup>>>;
};

export type SpendGroupRoster = {
  __typename?: 'SpendGroupRoster';
  archivedAt?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  group?: Maybe<SpendGroup>;
  groupId?: Maybe<Scalars['String']>;
  guardianName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invite?: Maybe<SpendInvite>;
  inviteId?: Maybe<Scalars['String']>;
  invoices?: Maybe<Array<Maybe<SpendInvoice>>>;
  isArchived?: Maybe<Scalars['Boolean']>;
  joinedAt?: Maybe<Scalars['String']>;
  pastDueDays?: Maybe<Scalars['Int']>;
  paymentScheduleStatus?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  roster?: Maybe<SpendRoster>;
  rosterId?: Maybe<Scalars['String']>;
  season?: Maybe<SpendSeason>;
  seasonId?: Maybe<Scalars['String']>;
  settings?: Maybe<SpendGroupRosterSettings>;
  status?: Maybe<SpendGroupRosterStatusEnum>;
  total?: Maybe<SpendTransactionTotals>;
  userId?: Maybe<Scalars['String']>;
};

export type SpendGroupRosterId = {
  __typename?: 'SpendGroupRosterID';
  id?: Maybe<Scalars['String']>;
};

export type SpendGroupRosterInput = {
  email: Scalars['String'];
  groupId: Scalars['String'];
  name: Scalars['String'];
  seasonId: Scalars['String'];
};

export type SpendGroupRosterResponse = {
  __typename?: 'SpendGroupRosterResponse';
  count?: Maybe<Scalars['Int']>;
  groupRosters?: Maybe<Array<Maybe<SpendGroupRoster>>>;
};

export type SpendGroupRosterSettings = {
  __typename?: 'SpendGroupRosterSettings';
  isAutoPayAuthorized?: Maybe<Scalars['Boolean']>;
};

export type SpendGroupRosterSettingsResponse = {
  __typename?: 'SpendGroupRosterSettingsResponse';
  groupRosterId?: Maybe<Scalars['String']>;
  settingsId?: Maybe<Scalars['String']>;
};

export enum SpendGroupRosterStatusEnum {
  AutopayStop = 'autopay_stop',
  AwaitingApproval = 'awaiting_approval',
  NoInvite = 'no_invite',
  NotSignedUp = 'not_signed_up',
  Paid = 'paid',
  PastDue = 'past_due'
}

export type SpendGroupRostersInput = {
  groupId: Scalars['String'];
  rosters?: InputMaybe<Array<InputMaybe<SpendRosterUserInput>>>;
  seasonId: Scalars['String'];
};

export type SpendGroupRostersWhereInput = {
  groupNameIncludes?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  nameIncludes?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<SpendGroupRosterStatusEnum>;
};

export type SpendGroupsOverview = {
  __typename?: 'SpendGroupsOverview';
  balance?: Maybe<Scalars['Int']>;
  cards?: Maybe<Array<Maybe<SpendDebitCard>>>;
  collected?: Maybe<Scalars['Int']>;
  groupId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pastDue?: Maybe<Scalars['Int']>;
  paymentScheduleInvoices?: Maybe<Array<Maybe<SpendPaymentSchedule>>>;
  seasonEndAt?: Maybe<Scalars['String']>;
  seasonName?: Maybe<Scalars['String']>;
  seasonStartAt?: Maybe<Scalars['String']>;
  sharedAccount?: Maybe<Scalars['Boolean']>;
};

export type SpendGroupsResponse = {
  __typename?: 'SpendGroupsResponse';
  count?: Maybe<Scalars['Int']>;
  groups?: Maybe<Array<Maybe<SpendGroup>>>;
  hasNext?: Maybe<Scalars['Boolean']>;
};

export type SpendGroupsWhereInput = {
  /** Search archived groups only */
  archived?: InputMaybe<Scalars['Boolean']>;
  /** Search by group name only */
  groupNameIncludes?: InputMaybe<Scalars['String']>;
  /** Search by list of group id */
  ids?: InputMaybe<Array<Scalars['String']>>;
  /** Search by group name or latest season name in group */
  nameIncludes?: InputMaybe<Scalars['String']>;
  orgId?: InputMaybe<Scalars['String']>;
  /** Search by payment schedule status of latest season in group. Support multiple values. (OR operation) */
  paymentScheduleStatus?: InputMaybe<Array<Scalars['String']>>;
};

export type SpendGuardianActiveGroup = {
  __typename?: 'SpendGuardianActiveGroup';
  /** @deprecated No longer required */
  dueDate?: Maybe<Scalars['String']>;
  group?: Maybe<SpendGuardianGroup>;
  /** @deprecated No longer required */
  roster?: Maybe<Array<Maybe<SpendRoster>>>;
};

export type SpendGuardianComingSoonInvoice = {
  __typename?: 'SpendGuardianComingSoonInvoice';
  groupId?: Maybe<Scalars['String']>;
  groupName?: Maybe<Scalars['String']>;
  groupRosterId?: Maybe<Scalars['String']>;
  invoiceAmountDue?: Maybe<Scalars['String']>;
  invoiceAutoPayAuthorized?: Maybe<Scalars['Boolean']>;
  invoiceAutoPayStopped?: Maybe<Scalars['Boolean']>;
  invoiceDescription?: Maybe<Scalars['String']>;
  invoiceDueDate?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  invoicePaymentMethod?: Maybe<Scalars['String']>;
  invoiceStatus?: Maybe<Scalars['String']>;
  isAuthorized?: Maybe<Scalars['Boolean']>;
  isOptional?: Maybe<Scalars['Boolean']>;
  optedIn?: Maybe<Scalars['Boolean']>;
  rosterName?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  seasonName?: Maybe<Scalars['String']>;
};

export type SpendGuardianCounts = {
  __typename?: 'SpendGuardianCounts';
  activeGroups?: Maybe<Scalars['Int']>;
  comingSoonInvoices?: Maybe<Scalars['Int']>;
  recentTransactions?: Maybe<Scalars['Int']>;
  unauthorizedInvoices?: Maybe<Scalars['Int']>;
};

export type SpendGuardianGroup = {
  __typename?: 'SpendGuardianGroup';
  accountId?: Maybe<Scalars['String']>;
  archivedAt?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  hasAccount?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  latestSeason?: Maybe<SpendSeason>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  programId?: Maybe<Scalars['String']>;
  seasons?: Maybe<Array<Maybe<SpendGuardianSeason>>>;
};

export type SpendGuardianHighlight = {
  __typename?: 'SpendGuardianHighlight';
  activeGroups?: Maybe<Array<Maybe<SpendGuardianActiveGroup>>>;
  comingSoonInvoices?: Maybe<Array<Maybe<SpendGuardianComingSoonInvoice>>>;
  counts?: Maybe<SpendGuardianCounts>;
  recentTransactions?: Maybe<Array<Maybe<SpendGuardianRecentTransaction>>>;
  unauthorizedInvoices?: Maybe<Array<Maybe<SpendGuardianComingSoonInvoice>>>;
};

export type SpendGuardianInvoice = {
  __typename?: 'SpendGuardianInvoice';
  amount: Scalars['Int'];
  authorizedAt?: Maybe<Scalars['String']>;
  balanceDue: Scalars['Int'];
  creditMemos?: Maybe<Array<SpendMemo>>;
  description: Scalars['String'];
  discountAmount: Scalars['Int'];
  dueDate: Scalars['String'];
  groupId: Scalars['String'];
  groupName: Scalars['String'];
  groupRosterId: Scalars['String'];
  id: Scalars['String'];
  isAutoPayAuthorized: Scalars['Boolean'];
  isAutoPayStopped: Scalars['Boolean'];
  isOptional: Scalars['Boolean'];
  isRefunded: Scalars['Boolean'];
  optedIn: Scalars['Boolean'];
  paid: Scalars['Boolean'];
  paidDate?: Maybe<Scalars['String']>;
  paymentMethodId?: Maybe<Scalars['String']>;
  paymentMethodSource?: Maybe<Scalars['String']>;
  refundDate?: Maybe<Scalars['String']>;
  rosterId: Scalars['String'];
  rosterName: Scalars['String'];
  seasonId: Scalars['String'];
  seasonName: Scalars['String'];
  status: Scalars['String'];
};

export type SpendGuardianInvoiceFilter = {
  by: InvoiceGuardianFilterEnum;
  value: Scalars['String'];
};

export type SpendGuardianInvoiceResponse = {
  __typename?: 'SpendGuardianInvoiceResponse';
  count: Scalars['Int'];
  invoices?: Maybe<Array<SpendGuardianInvoice>>;
};

export type SpendGuardianRecentTransaction = {
  __typename?: 'SpendGuardianRecentTransaction';
  amountDue?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['String']>;
  groupName?: Maybe<Scalars['String']>;
  invoiceDescription?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  rosterName?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
};

export type SpendGuardianSeason = {
  __typename?: 'SpendGuardianSeason';
  endDateAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isBudgetShared?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  roster?: Maybe<Array<Maybe<SpendRoster>>>;
  startDateAt?: Maybe<Scalars['String']>;
};

export type SpendGuardianSignupInput = {
  groupId: Scalars['String'];
  rosterName: Scalars['String'];
  seasonId: Scalars['String'];
};

export type SpendInvite = {
  __typename?: 'SpendInvite';
  createdAt?: Maybe<Scalars['String']>;
  debitCard?: Maybe<Scalars['String']>;
  debitCards?: Maybe<Array<SpendDebitCard>>;
  email?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  group?: Maybe<SpendGroup>;
  groupId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  isDeliverable?: Maybe<Scalars['Boolean']>;
  isUser?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  orgName?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
};

export type SpendInviteId = {
  __typename?: 'SpendInviteID';
  id?: Maybe<Scalars['String']>;
};

export type SpendInviteInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  groupId?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  seasonId?: InputMaybe<Scalars['String']>;
  type: SpendInviteType;
  userId?: InputMaybe<Scalars['String']>;
};

export type SpendInviteResendInput = {
  email: Scalars['String'];
  groupId: Scalars['String'];
  groupRosterId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  seasonId: Scalars['String'];
  userId: Scalars['String'];
};

export type SpendInviteResponse = {
  __typename?: 'SpendInviteResponse';
  count?: Maybe<Scalars['Int']>;
  invites?: Maybe<Array<Maybe<SpendInvite>>>;
};

export enum SpendInviteType {
  GroupObserver = 'group_observer',
  GroupStaff = 'group_staff',
  Guardian = 'guardian',
  ProgramAdmin = 'program_admin',
  ProgramObserver = 'program_observer',
  ProgramStaff = 'program_staff'
}

export type SpendInviteWhereInput = {
  /** Search invitation which is archived */
  archived?: InputMaybe<Scalars['Boolean']>;
  /** Search by group id */
  groupId?: InputMaybe<Scalars['String']>;
  /** Search by invite name */
  nameIncludes?: InputMaybe<Scalars['String']>;
  /** Search by roster name only */
  rosterNameIncludes?: InputMaybe<Scalars['String']>;
  /** Search by invite status: accepted, pending, undeliverable */
  status?: InputMaybe<Scalars['String']>;
  /** Search by invite types. Valid values: guardian,group_staff,program_staff, program_observer,program_admin */
  type?: InputMaybe<Array<SpendInviteType>>;
  /** Search by invite type context: group or program. This will be affect invite type filter. */
  typeContext?: InputMaybe<Scalars['String']>;
};

export type SpendInvoice = {
  __typename?: 'SpendInvoice';
  amount?: Maybe<Scalars['Int']>;
  authorizedAt?: Maybe<Scalars['String']>;
  balanceDue?: Maybe<Scalars['Int']>;
  budgetItem?: Maybe<SpendBudget>;
  budgetItemId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  creditMemos?: Maybe<Array<SpendMemo>>;
  description?: Maybe<Scalars['String']>;
  discountAmount?: Maybe<Scalars['Int']>;
  dueDate?: Maybe<Scalars['String']>;
  feesAmount?: Maybe<Scalars['Int']>;
  groupRoster?: Maybe<SpendGroupRoster>;
  groupRosterId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  isAutoPayAuthorized?: Maybe<Scalars['Boolean']>;
  isAutoPayStopped?: Maybe<Scalars['Boolean']>;
  isOptional?: Maybe<Scalars['Boolean']>;
  isReconciled?: Maybe<Scalars['Boolean']>;
  isRefunded?: Maybe<Scalars['Boolean']>;
  lastNotifyDate?: Maybe<Scalars['String']>;
  lastNotifyId?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  notificationAttempts?: Maybe<Scalars['Int']>;
  optedIn?: Maybe<Scalars['Boolean']>;
  paid?: Maybe<Scalars['Boolean']>;
  paidDate?: Maybe<Scalars['String']>;
  paymentMethodId?: Maybe<Scalars['String']>;
  paymentMethodSource?: Maybe<Scalars['String']>;
  paymentScheduleInvoiceId?: Maybe<Scalars['String']>;
  paymentScheduleStatus?: Maybe<Scalars['String']>;
  reconciledTransactions?: Maybe<Array<Maybe<SpendReconciledInvoiceTransaction>>>;
  refundDate?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  transaction?: Maybe<SpendTransaction>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type SpendInvoiceDiscountInput = {
  discountAmount: Scalars['Int'];
  invoiceId: Scalars['String'];
};

export type SpendInvoiceFilter = {
  by: InvoiceReportFilterEnum;
  value: Scalars['String'];
};

export type SpendInvoiceHistoryInput = {
  email: Scalars['String'];
  userId: Scalars['String'];
};

export type SpendInvoiceId = {
  __typename?: 'SpendInvoiceID';
  id?: Maybe<Scalars['String']>;
};

export type SpendInvoiceIDs = {
  __typename?: 'SpendInvoiceIDs';
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SpendInvoiceInput = {
  amount?: InputMaybe<Scalars['Int']>;
  balanceDue?: InputMaybe<Scalars['Int']>;
  budgetItemId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['String']>;
  groupRosterId?: InputMaybe<Scalars['String']>;
  isOptional?: InputMaybe<Scalars['Boolean']>;
  note?: InputMaybe<Scalars['String']>;
  optedIn?: InputMaybe<Scalars['Boolean']>;
  paid?: InputMaybe<Scalars['Boolean']>;
  paidDate?: InputMaybe<Scalars['String']>;
  paymentScheduleInvoiceId?: InputMaybe<Scalars['String']>;
};

export type SpendInvoicePaymentDeauthorizeInput = {
  invoiceId?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
};

export type SpendInvoicePaymentMethodUpdate = {
  ids: Array<Scalars['String']>;
  isAutoPayAuthorized?: InputMaybe<Scalars['Boolean']>;
  paymentMethodId: Scalars['String'];
  paymentMethodSource: Scalars['String'];
};

export type SpendInvoiceRefundInput = {
  amount: Scalars['Int'];
  invoiceId: Scalars['String'];
};

export type SpendInvoiceRefundResponse = {
  __typename?: 'SpendInvoiceRefundResponse';
  invoiceId?: Maybe<Scalars['String']>;
  spendTransactionId?: Maybe<Scalars['String']>;
};

export type SpendInvoiceReminderInput = {
  email?: InputMaybe<Scalars['String']>;
  failedPayment?: InputMaybe<Scalars['Boolean']>;
  groupName?: InputMaybe<Scalars['String']>;
  invoiceId?: InputMaybe<Scalars['String']>;
  pastDue?: InputMaybe<Scalars['Boolean']>;
};

export type SpendInvoiceRequestChangeInput = {
  invoiceId: Scalars['String'];
  note: Scalars['String'];
};

export type SpendInvoiceResponse = {
  __typename?: 'SpendInvoiceResponse';
  count?: Maybe<Scalars['Int']>;
  hasNext?: Maybe<Scalars['Boolean']>;
  invoices?: Maybe<Array<Maybe<SpendInvoice>>>;
};

export type SpendJoinedExternal = {
  __typename?: 'SpendJoinedExternal';
  externalId: Scalars['String'];
  groupName: Scalars['String'];
  invoiceDescription: Scalars['String'];
  playerName: Scalars['String'];
  seasonName: Scalars['String'];
};

export type SpendJoinedPayee = {
  __typename?: 'SpendJoinedPayee';
  groupName?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  payeeId: Scalars['String'];
  payeeName: Scalars['String'];
  referenceId: Scalars['String'];
};

export type SpendLegacyTransaction = {
  __typename?: 'SpendLegacyTransaction';
  amount?: Maybe<Scalars['Int']>;
  correlationId?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['Timestamp']>;
  description?: Maybe<Scalars['String']>;
  destination?: Maybe<Scalars['String']>;
  effective: Scalars['Timestamp'];
  externalId: Scalars['String'];
  id: Scalars['UUID'];
  metadata?: Maybe<Scalars['JSON']>;
  processor?: Maybe<Scalars['String']>;
  snapAmount?: Maybe<Scalars['Int']>;
  source?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type SpendLegacyTransactionsResponse = {
  __typename?: 'SpendLegacyTransactionsResponse';
  count?: Maybe<Scalars['Int']>;
  transactions?: Maybe<Array<Maybe<SpendLegacyTransaction>>>;
};

export type SpendMember = {
  __typename?: 'SpendMember';
  child?: Maybe<SpendMemberChild>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  signedUp?: Maybe<Scalars['Boolean']>;
};

export type SpendMemberChild = {
  __typename?: 'SpendMemberChild';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type SpendMemo = {
  __typename?: 'SpendMemo';
  creditAmount: Scalars['Int'];
  creditApplied: Scalars['Int'];
  dateToApply: Scalars['String'];
  groupName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isArchived: Scalars['Boolean'];
  note?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type SpendNotificationId = {
  __typename?: 'SpendNotificationID';
  id?: Maybe<Scalars['String']>;
};

export type SpendNotificationInput = {
  email: Array<Scalars['String']>;
  groupId?: InputMaybe<Scalars['String']>;
  isEmailCCed: Scalars['Boolean'];
  message: Scalars['String'];
  subject?: InputMaybe<Scalars['String']>;
};

export type SpendNotificationStatus = {
  __typename?: 'SpendNotificationStatus';
  status?: Maybe<Scalars['String']>;
};

export type SpendOrgAdminUpdateInput = {
  email: Scalars['String'];
  keepAsAuthorizedUser: Scalars['Boolean'];
  organizationId: Scalars['String'];
};

export type SpendOrgAdminUpdateResponse = {
  __typename?: 'SpendOrgAdminUpdateResponse';
  createdAuthorizedUser?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
};

export type SpendOrganization = {
  __typename?: 'SpendOrganization';
  accountId?: Maybe<Scalars['String']>;
  achBaseFee?: Maybe<Scalars['Int']>;
  achPercent?: Maybe<Scalars['Float']>;
  budgets?: Maybe<Array<Maybe<SpendBudget>>>;
  cardBaseFee?: Maybe<Scalars['Int']>;
  cardPercent?: Maybe<Scalars['Float']>;
  city: Scalars['String'];
  debitCards?: Maybe<SpendOrganizationDebitCardCount>;
  email: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  externalTransferOutEnabled?: Maybe<Scalars['Boolean']>;
  groupBanksEnabled?: Maybe<Scalars['Boolean']>;
  groups?: Maybe<Array<Maybe<SpendGroup>>>;
  hasLinkedAccount?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  legacyAccountId?: Maybe<Scalars['String']>;
  legacyExternalId?: Maybe<Scalars['String']>;
  legalName: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['String']>;
  owner?: Maybe<User>;
  phone: Scalars['String'];
  spendBaseFee?: Maybe<Scalars['Int']>;
  spendPercent?: Maybe<Scalars['Float']>;
  state: Scalars['String'];
  street: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  zip: Scalars['String'];
};

export type SpendOrganizationCheckDepositTagsInput = {
  checkDepositId: Scalars['String'];
};

export type SpendOrganizationDebitCardCount = {
  __typename?: 'SpendOrganizationDebitCardCount';
  activated?: Maybe<Scalars['Int']>;
  assigned?: Maybe<Scalars['Int']>;
  shipped?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type SpendOrganizationExternalTransferInput = {
  accountId: Scalars['String'];
  amount: Scalars['Int'];
  direction: ExternalTransferDirectionEnum;
  note: Scalars['String'];
};

export type SpendOrganizationFee = {
  __typename?: 'SpendOrganizationFee';
  achBaseFee?: Maybe<Scalars['Int']>;
  achPercent?: Maybe<Scalars['Float']>;
  cardBaseFee?: Maybe<Scalars['Int']>;
  cardPercent?: Maybe<Scalars['Float']>;
  spendBaseFee?: Maybe<Scalars['Int']>;
  spendPercent?: Maybe<Scalars['Float']>;
};

export type SpendOrganizationId = {
  __typename?: 'SpendOrganizationID';
  id?: Maybe<Scalars['String']>;
};

export type SpendOrganizationInput = {
  achBaseFee: Scalars['Int'];
  achPercent: Scalars['Float'];
  cardBaseFee: Scalars['Int'];
  cardPercent: Scalars['Float'];
  email: Scalars['String'];
  externalTransferOutEnabled?: InputMaybe<Scalars['Boolean']>;
  groupBanksEnabled?: InputMaybe<Scalars['Boolean']>;
  isVerified: Scalars['Boolean'];
  legalName: Scalars['String'];
  logo?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  spendBaseFee: Scalars['Int'];
  spendPercent: Scalars['Float'];
  website?: InputMaybe<Scalars['String']>;
};

export type SpendOrganizationOwner = {
  __typename?: 'SpendOrganizationOwner';
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type SpendOrganizationStatus = {
  __typename?: 'SpendOrganizationStatus';
  status?: Maybe<Scalars['Boolean']>;
};

export type SpendOrganizationsResponse = {
  __typename?: 'SpendOrganizationsResponse';
  count?: Maybe<Scalars['Int']>;
  hasNext?: Maybe<Scalars['Boolean']>;
  organizations?: Maybe<Array<SpendOrganization>>;
};

export type SpendOrganizationsWhereInput = {
  ids?: InputMaybe<Array<Scalars['String']>>;
  nameIncludes?: InputMaybe<Scalars['String']>;
  /** orgId is equivalent to externalId or id from orgs-api */
  orgIds?: InputMaybe<Array<Scalars['String']>>;
  states?: InputMaybe<Array<Scalars['String']>>;
  status?: InputMaybe<OrganizationFilterEnum>;
};

export type SpendPaginationInput = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export enum SpendPastDueInterval {
  Days1_15 = 'days1_15',
  Days16_30 = 'days16_30',
  Days31plus = 'days31plus'
}

export type SpendPastDueInvoice = {
  __typename?: 'SpendPastDueInvoice';
  amount: Scalars['Int'];
  creditMemos?: Maybe<Array<Maybe<CreditMemoAmounts>>>;
  description: Scalars['String'];
  discountAmount: Scalars['Int'];
  dueDate: Scalars['String'];
  email: Scalars['String'];
  groupId: Scalars['String'];
  groupName: Scalars['String'];
  guardianStatus: Scalars['String'];
  name: Scalars['String'];
  rosterId: Scalars['String'];
  seasonId: Scalars['String'];
  seasonName: Scalars['String'];
  status: Scalars['String'];
};

export type SpendPastDueInvoicesOutput = {
  __typename?: 'SpendPastDueInvoicesOutput';
  count: Scalars['Int'];
  invoices: Array<Maybe<SpendPastDueInvoice>>;
};

export type SpendPastDueSort = {
  order?: InputMaybe<SpendSortOrderEnum>;
  sortBy?: InputMaybe<PastDueSortOptions>;
};

export type SpendPayNowResponse = {
  __typename?: 'SpendPayNowResponse';
  paymentId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type SpendPayeeCreateResponse = {
  __typename?: 'SpendPayeeCreateResponse';
  payeeId?: Maybe<Scalars['String']>;
};

export type SpendPayeeInput = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  name: Scalars['String'];
  referenceId: Scalars['String'];
  state: Scalars['String'];
  zipCode: Scalars['String'];
};

export type SpendPayeeResponse = {
  __typename?: 'SpendPayeeResponse';
  payees?: Maybe<Array<SpendPayees>>;
};

export type SpendPayeeUpdateInput = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  id: Scalars['String'];
  isArchived?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  referenceId: Scalars['String'];
  state: Scalars['String'];
  zipCode: Scalars['String'];
};

export type SpendPayees = {
  __typename?: 'SpendPayees';
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  state: Scalars['String'];
  zipCode: Scalars['String'];
};

export type SpendPaymentMethodDetach = {
  paymentMethodId: Scalars['String'];
};

export type SpendPaymentMethodDetachResponse = {
  __typename?: 'SpendPaymentMethodDetachResponse';
  paymentMethodId?: Maybe<Scalars['String']>;
};

export type SpendPaymentMethodInput = {
  amount?: InputMaybe<Scalars['Int']>;
  authorizedAt?: InputMaybe<Scalars['String']>;
  discountAmounts?: InputMaybe<Array<SpendInvoiceDiscountInput>>;
  hasApprovedAgreement?: InputMaybe<Scalars['Boolean']>;
  inviteId?: InputMaybe<Scalars['String']>;
  invoiceIds?: InputMaybe<Array<Scalars['String']>>;
  isAutoPayAuthorized?: InputMaybe<Scalars['Boolean']>;
  paymentMethodId?: InputMaybe<Scalars['String']>;
  paymentMethodSource?: InputMaybe<Scalars['String']>;
  paymentMethodTiming?: InputMaybe<Scalars['String']>;
};

export type SpendPaymentMethodResponse = {
  __typename?: 'SpendPaymentMethodResponse';
  groupRosterSetting?: Maybe<SpendGroupRosterSettingsResponse>;
  payment?: Maybe<SpendPayNowResponse>;
  updatedInvoiceIds: Array<Maybe<Scalars['String']>>;
};

export type SpendPaymentSchedule = {
  __typename?: 'SpendPaymentSchedule';
  amountDue?: Maybe<Scalars['Int']>;
  budgetItem?: Maybe<SpendBudget>;
  budgetItemId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['String']>;
  group?: Maybe<SpendGroup>;
  groupId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  isOptional?: Maybe<Scalars['Boolean']>;
  lastPublishedState?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  season?: Maybe<SpendSeason>;
  seasonId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type SpendPaymentScheduleBySeasonInput = {
  seasonId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type SpendPaymentScheduleId = {
  __typename?: 'SpendPaymentScheduleID';
  id?: Maybe<Scalars['String']>;
};

export type SpendPaymentScheduleInput = {
  amountDue?: InputMaybe<Scalars['Int']>;
  budgetItemId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['String']>;
  groupId?: InputMaybe<Scalars['String']>;
  isOptional?: InputMaybe<Scalars['Boolean']>;
  note?: InputMaybe<Scalars['String']>;
  seasonId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type SpendPendingInvite = {
  __typename?: 'SpendPendingInvite';
  groupId?: Maybe<Scalars['String']>;
  groupName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  organizationId: Scalars['String'];
  organizationName: Scalars['String'];
  rosterName?: Maybe<Scalars['String']>;
  seasonName?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type SpendPolicy = {
  __typename?: 'SpendPolicy';
  createdAt?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type SpendReconcileTransactionId = {
  __typename?: 'SpendReconcileTransactionID';
  id?: Maybe<Scalars['String']>;
};

export type SpendReconciledBudget = {
  __typename?: 'SpendReconciledBudget';
  amount: Scalars['Int'];
  budgetId: Scalars['String'];
  budgetName: Scalars['String'];
  categoryId: Scalars['String'];
  categoryName: Scalars['String'];
  description: Scalars['String'];
  groupId?: Maybe<Scalars['String']>;
  groupName: Scalars['String'];
  invoiceId?: Maybe<Scalars['String']>;
  seasonId: Scalars['String'];
  seasonName: Scalars['String'];
  type: Scalars['String'];
};

export type SpendReconciledBudgetTransaction = {
  __typename?: 'SpendReconciledBudgetTransaction';
  amount?: Maybe<Scalars['Int']>;
  budgetItemId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  reconciledTransaction?: Maybe<SpendReconciledTransaction>;
  transactionId?: Maybe<Scalars['String']>;
};

export type SpendReconciledInvoiceTransaction = {
  __typename?: 'SpendReconciledInvoiceTransaction';
  amount?: Maybe<Scalars['Int']>;
  creditMemos?: Maybe<Array<Maybe<SpendMemo>>>;
  id?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  reconciledTransaction?: Maybe<SpendReconciledTransaction>;
  rosterId?: Maybe<Scalars['String']>;
  rosterName?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
};

export type SpendReconciledTransaction = {
  __typename?: 'SpendReconciledTransaction';
  amount?: Maybe<Scalars['Int']>;
  budgetTransactions?: Maybe<Array<Maybe<SpendReconciledBudgetTransaction>>>;
  createdAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceTransactions?: Maybe<Array<Maybe<SpendReconciledInvoiceTransaction>>>;
  paymentId?: Maybe<Scalars['String']>;
  reconciledTo?: Maybe<Array<Maybe<SpendReconciledBudget>>>;
  transaction?: Maybe<SpendBankTransaction>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type SpendRemoveSeasonMemberInput = {
  groupRosterIds: Array<InputMaybe<Scalars['String']>>;
};

export type SpendRemoveSeasonMemberResponse = {
  __typename?: 'SpendRemoveSeasonMemberResponse';
  success: Scalars['Boolean'];
};

export type SpendRole = {
  __typename?: 'SpendRole';
  groupId?: Maybe<Scalars['String']>;
  groupName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isArchived?: Maybe<Scalars['Boolean']>;
  isNotSignedUp?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  organizationId?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  permissions: Array<Maybe<Scalars['String']>>;
  seasonId?: Maybe<Scalars['String']>;
};

export type SpendRoleId = {
  __typename?: 'SpendRoleID';
  id?: Maybe<Scalars['String']>;
};

export enum SpendRoleNameEnum {
  GroupObserver = 'group_observer',
  GroupStaff = 'group_staff',
  Guardian = 'guardian',
  ProgramObserver = 'program_observer',
  ProgramStaff = 'program_staff'
}

export type SpendRoleResponse = {
  __typename?: 'SpendRoleResponse';
  count?: Maybe<Scalars['Int']>;
  roles?: Maybe<Array<Maybe<SpendRole>>>;
};

export type SpendRoster = {
  __typename?: 'SpendRoster';
  email?: Maybe<Scalars['String']>;
  groupRosters?: Maybe<Array<Maybe<SpendGroupRoster>>>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  total?: Maybe<SpendTransactionTotals>;
};

export type SpendRosterResponse = {
  __typename?: 'SpendRosterResponse';
  count?: Maybe<Scalars['Int']>;
  rosters?: Maybe<Array<Maybe<SpendRoster>>>;
};

export type SpendRosterUpdate = {
  __typename?: 'SpendRosterUpdate';
  id?: Maybe<Scalars['String']>;
  invitesUpdated?: Maybe<Array<Scalars['String']>>;
};

export type SpendRosterUserInput = {
  email: Scalars['String'];
  name: Scalars['String'];
};

export type SpendRosterUserUpdate = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  rosterId: Scalars['String'];
};

export type SpendSeason = {
  __typename?: 'SpendSeason';
  budgets?: Maybe<Array<Maybe<SpendBudget>>>;
  endDateAt?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['String']>;
  groupRoster?: Maybe<Array<Maybe<SpendGroupRoster>>>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isBudgetShared?: Maybe<Scalars['Boolean']>;
  isLinkEnabled?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  paymentScheduleInvoices?: Maybe<Array<Maybe<SpendPaymentSchedule>>>;
  paymentScheduleStatus?: Maybe<Scalars['String']>;
  playerCount?: Maybe<Scalars['Int']>;
  startDateAt?: Maybe<Scalars['String']>;
  transactionTotals?: Maybe<SpendTransactionTotals>;
};

export type SpendSeasonId = {
  __typename?: 'SpendSeasonID';
  id?: Maybe<Scalars['String']>;
};

export type SpendSeasonInput = {
  endDateAt?: InputMaybe<Scalars['String']>;
  groupId?: InputMaybe<Scalars['String']>;
  isBudgetShared?: InputMaybe<Scalars['Boolean']>;
  isLinkEnabled?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  startDateAt?: InputMaybe<Scalars['String']>;
};

export type SpendSession = {
  __typename?: 'SpendSession';
  expiresAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  inviteId?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  newInvite?: Maybe<Scalars['Boolean']>;
  pendingInvites?: Maybe<Array<SpendPendingInvite>>;
  role?: Maybe<SpendRole>;
  status?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type SpendSessionId = {
  __typename?: 'SpendSessionID';
  id?: Maybe<Scalars['String']>;
};

export type SpendSettings = {
  __typename?: 'SpendSettings';
  /** @deprecated duplicate of SpendOrganization.groupBanksEnabled */
  enableGroupBanks?: Maybe<Scalars['Boolean']>;
  enableProgramAgreements?: Maybe<Scalars['Boolean']>;
  notifyBankActivityAdmins?: Maybe<Scalars['Boolean']>;
  notifyDueFrequencies?: Maybe<Array<Scalars['String']>>;
  notifyFailedAchAdmins?: Maybe<Scalars['Boolean']>;
  notifyPastDueAdmins?: Maybe<Scalars['Boolean']>;
  notifyPastDueNonUsers?: Maybe<Scalars['Boolean']>;
  notifyUpcomingNonUsers?: Maybe<Scalars['Boolean']>;
  /** @deprecated replaced by notifyDueFrequencies */
  pastDueFrequency?: Maybe<Scalars['Int']>;
  signUpAgreement?: Maybe<SignupAgreement>;
  signUpLink?: Maybe<Scalars['String']>;
};

export type SpendSettingsInput = {
  enableGroupBanks?: InputMaybe<Scalars['Boolean']>;
  enableProgramAgreements?: InputMaybe<Scalars['Boolean']>;
  notifyBankActivityAdmins?: InputMaybe<Scalars['Boolean']>;
  notifyDueFrequencies?: InputMaybe<Array<Scalars['String']>>;
  notifyFailedAchAdmins?: InputMaybe<Scalars['Boolean']>;
  notifyPastDueAdmins?: InputMaybe<Scalars['Boolean']>;
  notifyPastDueNonUsers?: InputMaybe<Scalars['Boolean']>;
  notifyUpcomingNonUsers?: InputMaybe<Scalars['Boolean']>;
  /** @deprecated: pastDueFrequency is replaced with notifyDueFrequencies */
  pastDueFrequency?: InputMaybe<Scalars['Int']>;
};

export type SpendSignUpInput = {
  groupId: Scalars['String'];
  orgId: Scalars['String'];
  participantName: Scalars['String'];
  seasonId: Scalars['String'];
};

export type SpendSignUpResponse = {
  __typename?: 'SpendSignUpResponse';
  id?: Maybe<Scalars['String']>;
};

export type SpendSignupAgreementInput = {
  content: Scalars['String'];
  name: Scalars['String'];
};

export type SpendSignupFormResponse = {
  __typename?: 'SpendSignupFormResponse';
  id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type SpendSortInput = {
  field: Scalars['String'];
  order?: InputMaybe<SpendSortOrderEnum>;
};

export enum SpendSortOrderEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SpendTransaction = {
  __typename?: 'SpendTransaction';
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
};

export type SpendTransactionAttachmentId = {
  __typename?: 'SpendTransactionAttachmentID';
  id?: Maybe<Scalars['String']>;
};

export type SpendTransactionAttachmentInput = {
  /** Base-64 encoded file */
  content: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  /** Filename with extension */
  name: Scalars['String'];
  paymentId: Scalars['String'];
};

export type SpendTransactionFilter = {
  by: TransactionReportFilterEnum;
  value: Scalars['String'];
};

export type SpendTransactionInput = {
  authorizedAt?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<Scalars['String']>;
  discountAmounts?: InputMaybe<Array<SpendInvoiceDiscountInput>>;
  externalId?: InputMaybe<Scalars['String']>;
  externalPaymentId?: InputMaybe<Scalars['String']>;
  invoiceIdList?: InputMaybe<Array<Scalars['String']>>;
  source?: InputMaybe<Scalars['String']>;
};

export type SpendTransactionInvoiceUnreconcileInput = {
  reconciledInvoiceTransactionIds: Array<Scalars['String']>;
  reconciledTransactionId: Scalars['String'];
};

export type SpendTransactionNoteId = {
  __typename?: 'SpendTransactionNoteID';
  id?: Maybe<Scalars['String']>;
};

export type SpendTransactionReconcileInput = {
  budgets?: InputMaybe<Array<ReconcileBudget>>;
  invoices?: InputMaybe<Array<ReconcileInvoice>>;
  ledgerTransactionAmount: Scalars['Int'];
  ledgerTransactionId: Scalars['String'];
};

export type SpendTransactionResponse = {
  __typename?: 'SpendTransactionResponse';
  count?: Maybe<Scalars['Int']>;
  transactions?: Maybe<Array<Maybe<SpendTransaction>>>;
};

export type SpendTransactionTags = {
  __typename?: 'SpendTransactionTags';
  spendAutoPay?: Maybe<Scalars['Boolean']>;
  spendDestinationId?: Maybe<Scalars['String']>;
  spendExternalId?: Maybe<Scalars['String']>;
  spendGroupRosterId?: Maybe<Scalars['String']>;
  /** @deprecated No longer used due to being too long */
  spendInvoiceIds?: Maybe<Scalars['String']>;
  spendOriginalPaymentId?: Maybe<Scalars['String']>;
  spendPaymentType?: Maybe<Scalars['String']>;
  spendSourceId?: Maybe<Scalars['String']>;
  spendUserId?: Maybe<Scalars['String']>;
};

export type SpendTransactionTotals = {
  __typename?: 'SpendTransactionTotals';
  credited?: Maybe<Scalars['Int']>;
  dueToday?: Maybe<Scalars['Int']>;
  paid?: Maybe<Scalars['Int']>;
  pastDue?: Maybe<Scalars['Int']>;
  pastDueDays?: Maybe<Scalars['Int']>;
  processing?: Maybe<Scalars['Int']>;
  statuses?: Maybe<Array<Maybe<Scalars['String']>>>;
  upcoming?: Maybe<Scalars['Int']>;
};

export type SpendTransactionWhereInput = {
  dateAfter?: InputMaybe<Scalars['String']>;
  dateBefore?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<ExternalTransferDirectionEnum>;
  groupIdOrgId: Scalars['String'];
  hasAttachment?: InputMaybe<Scalars['Boolean']>;
  method?: InputMaybe<Array<Scalars['String']>>;
  nameIncludes?: InputMaybe<Scalars['String']>;
  reconciled?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Array<Scalars['String']>>;
  type?: InputMaybe<Array<Scalars['String']>>;
};

export type SpendTransactionsIdList = {
  __typename?: 'SpendTransactionsIdList';
  transactionIdList?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SpendTranscationNoteInput = {
  content: Scalars['String'];
  paymentId: Scalars['String'];
};

export type SpendUnreconciledSummary = {
  __typename?: 'SpendUnreconciledSummary';
  credits?: Maybe<TransactionTotalCount>;
  debits?: Maybe<TransactionTotalCount>;
};

export type SpendUpdateResponse = {
  __typename?: 'SpendUpdateResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type SpendUpsertCategoryInput = {
  id?: InputMaybe<Scalars['String']>;
  isDefault: Scalars['Boolean'];
  isHidden: Scalars['Boolean'];
  name: Scalars['String'];
  type: CategoryTypeEnum;
};

export type SpendUserId = {
  __typename?: 'SpendUserID';
  id?: Maybe<Scalars['String']>;
};

export type SpendUserRoleId = {
  __typename?: 'SpendUserRoleID';
  id?: Maybe<Scalars['String']>;
};

export type SpendUserSignupInput = {
  groupId: Scalars['String'];
  seasonId: Scalars['String'];
  status: Scalars['String'];
};

export type SpendVerificationTokenResponse = {
  __typename?: 'SpendVerificationTokenResponse';
  verificationToken?: Maybe<Scalars['String']>;
};

export type SpendWireLimits = {
  __typename?: 'SpendWireLimits';
  dailyTransfer: Scalars['Int'];
  dailyTransferSoft: Scalars['Int'];
  monthlyTransfer: Scalars['Int'];
  monthlyTransferSoft: Scalars['Int'];
};

export type SpendWireLimitsData = {
  __typename?: 'SpendWireLimitsData';
  limits?: Maybe<SpendWireLimits>;
};

export enum State {
  Alabama = 'ALABAMA',
  Alaska = 'ALASKA',
  Arizona = 'ARIZONA',
  Arkansas = 'ARKANSAS',
  California = 'CALIFORNIA',
  Colorado = 'COLORADO',
  Connecticut = 'CONNECTICUT',
  Delaware = 'DELAWARE',
  DistrictOfColumbia = 'DISTRICT_OF_COLUMBIA',
  Florida = 'FLORIDA',
  Georgia = 'GEORGIA',
  Hawaii = 'HAWAII',
  Idaho = 'IDAHO',
  Illinois = 'ILLINOIS',
  Indiana = 'INDIANA',
  Iowa = 'IOWA',
  Kansas = 'KANSAS',
  Kentucky = 'KENTUCKY',
  Louisiana = 'LOUISIANA',
  Maine = 'MAINE',
  Maryland = 'MARYLAND',
  Massachusetts = 'MASSACHUSETTS',
  Michigan = 'MICHIGAN',
  Minnesota = 'MINNESOTA',
  Mississippi = 'MISSISSIPPI',
  Missouri = 'MISSOURI',
  Montana = 'MONTANA',
  Nebraska = 'NEBRASKA',
  Nevada = 'NEVADA',
  NewHampshire = 'NEW_HAMPSHIRE',
  NewJersey = 'NEW_JERSEY',
  NewMexico = 'NEW_MEXICO',
  NewYork = 'NEW_YORK',
  NorthCarolina = 'NORTH_CAROLINA',
  NorthDakota = 'NORTH_DAKOTA',
  Ohio = 'OHIO',
  Oklahoma = 'OKLAHOMA',
  Oregon = 'OREGON',
  Pennsylvania = 'PENNSYLVANIA',
  RhodeIsland = 'RHODE_ISLAND',
  SouthCarolina = 'SOUTH_CAROLINA',
  SouthDakota = 'SOUTH_DAKOTA',
  Tennessee = 'TENNESSEE',
  Texas = 'TEXAS',
  Utah = 'UTAH',
  Vermont = 'VERMONT',
  Virginia = 'VIRGINIA',
  Washington = 'WASHINGTON',
  WestVirginia = 'WEST_VIRGINIA',
  Wisconsin = 'WISCONSIN',
  Wyoming = 'WYOMING'
}

export enum StateCode {
  Ak = 'AK',
  Al = 'AL',
  Ar = 'AR',
  As = 'AS',
  Az = 'AZ',
  Ca = 'CA',
  Co = 'CO',
  Ct = 'CT',
  Dc = 'DC',
  De = 'DE',
  Fl = 'FL',
  Ga = 'GA',
  Gu = 'GU',
  Hi = 'HI',
  Ia = 'IA',
  Id = 'ID',
  Il = 'IL',
  In = 'IN',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  Ma = 'MA',
  Md = 'MD',
  Me = 'ME',
  Mi = 'MI',
  Mn = 'MN',
  Mo = 'MO',
  Mp = 'MP',
  Ms = 'MS',
  Mt = 'MT',
  Nc = 'NC',
  Nd = 'ND',
  Ne = 'NE',
  Nh = 'NH',
  Nj = 'NJ',
  Nm = 'NM',
  Nv = 'NV',
  Ny = 'NY',
  Oh = 'OH',
  Ok = 'OK',
  Or = 'OR',
  Pa = 'PA',
  Pr = 'PR',
  Ri = 'RI',
  Sc = 'SC',
  Sd = 'SD',
  Tn = 'TN',
  Tx = 'TX',
  Um = 'UM',
  Ut = 'UT',
  Va = 'VA',
  Vi = 'VI',
  Vt = 'VT',
  Wa = 'WA',
  Wi = 'WI',
  Wv = 'WV',
  Wy = 'WY'
}

export enum Status {
  Canceled = 'Canceled',
  Clearing = 'Clearing',
  Pending = 'PENDING',
  PendingReview = 'PendingReview',
  Rejected = 'Rejected',
  Returned = 'Returned',
  Settled = 'SETTLED'
}

export type Store = {
  __typename?: 'Store';
  code?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type StoreBaseFilterInput = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  searchBy?: InputMaybe<Scalars['String']>;
  searchValue?: InputMaybe<Scalars['ID']>;
};

export type StoreBaseSellerUnionType = OnException | StoreScopeBestSeller;

export type StoreBestSeller = {
  __typename?: 'StoreBestSeller';
  productId?: Maybe<Scalars['Int']>;
  productImage?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  productPrice?: Maybe<Scalars['Float']>;
  productQuantity?: Maybe<Scalars['Int']>;
  productType?: Maybe<Scalars['String']>;
};

export type StoreBrands = {
  __typename?: 'StoreBrands';
  brands?: Maybe<Array<Maybe<Brand>>>;
};

export type StoreBrandsUnionType = OnException | StoreBrands;

export type StoreBuildRequest = {
  __typename?: 'StoreBuildRequest';
  email?: Maybe<Scalars['String']>;
  referenceTicket?: Maybe<Scalars['Int']>;
  storeName?: Maybe<Scalars['String']>;
  storeRequest?: Maybe<Scalars['Boolean']>;
  userSsoId?: Maybe<Scalars['String']>;
};

export type StoreBuildRequestUnionType = OnException | StoreBuildRequest;

export type StoreBuildRequester = {
  email?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type StoreByCampaignIdsInput = {
  all?: InputMaybe<Scalars['Boolean']>;
  campaignIds: Array<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderBySort?: InputMaybe<Scalars['String']>;
  scopeIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type StoreByGlEmailInput = {
  all?: InputMaybe<Scalars['Boolean']>;
  email: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderBySort?: InputMaybe<Scalars['String']>;
  scopeIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type StoreCampaign = {
  __typename?: 'StoreCampaign';
  accountManagerId?: Maybe<Scalars['Int']>;
  commissionEligibleSalesrepId?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['Int']>;
  fullAddress?: Maybe<Scalars['String']>;
  gool?: Maybe<Scalars['Int']>;
  groupLeaderEmail?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  initialGoalCents?: Maybe<Scalars['Int']>;
  joinCode?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  originalSalesrepId?: Maybe<Scalars['Int']>;
  salesrepId?: Maybe<Scalars['Int']>;
  settlementConfirmationStatus?: Maybe<Scalars['String']>;
  settlementMethod?: Maybe<Scalars['String']>;
  settlementStatus?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  teamSize?: Maybe<Scalars['Int']>;
  totalRaisedCents?: Maybe<Scalars['Int']>;
  userDirectoryId?: Maybe<Scalars['String']>;
};

export type StoreCampaignPayableInfoInput = {
  campaignId: Scalars['Int'];
  primary?: InputMaybe<Scalars['Boolean']>;
};

export type StoreCampaignPayableInfoUnionType = OnException | PayableInfo;

export type StoreColors = {
  __typename?: 'StoreColors';
  colors?: Maybe<Array<Maybe<ScopeProductColor>>>;
};

export type StoreColorsUnionType = OnException | StoreColors;

export type StoreCreatePaymentIntent = {
  amount: Scalars['Float'];
  email: Scalars['String'];
  paymentMethod?: InputMaybe<Scalars['String']>;
  userSsoId: Scalars['String'];
};

export type StoreCustomerInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type StoreEditInfo = {
  __typename?: 'StoreEditInfo';
  code?: Maybe<Scalars['String']>;
  scopeId?: Maybe<Scalars['Int']>;
};

export type StoreEditInfoInput = {
  activityType?: InputMaybe<Scalars['String']>;
  brands?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  productColors?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  scopeId: Scalars['Int'];
  storeCode: Scalars['String'];
  storeName?: InputMaybe<Scalars['String']>;
  themeColor?: InputMaybe<Scalars['String']>;
};

export type StoreEditInfoUnionType = OnException | StoreEditInfo;

export type StoreFilterUnionType = OnException | StoresFilter;

export type StoreGlInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type StoreManagerUpdatePoints = {
  managerEmail: Scalars['String'];
  managerId: Scalars['Int'];
  points: Scalars['Float'];
  scopeId: Scalars['Int'];
};

export type StoreManagerUpdatePointsUnionType = MagentoStoreManager | OnException;

export type StoreOrderItemImage = {
  __typename?: 'StoreOrderItemImage';
  imageUrl?: Maybe<Scalars['String']>;
  itemId?: Maybe<Scalars['BigInt']>;
  selectedLogo?: Maybe<Scalars['String']>;
};

export type StoreOrderItems = {
  __typename?: 'StoreOrderItems';
  items?: Maybe<Array<Maybe<StoreOrderItemImage>>>;
};

export type StoreOrderItemsUnionType = OnException | StoreOrderItems;

export type StoreOrderSalesGraphs = {
  __typename?: 'StoreOrderSalesGraphs';
  graphs?: Maybe<Array<Maybe<StoreOrderSalesGraph>>>;
};

export type StoreParticipant = {
  __typename?: 'StoreParticipant';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type StoreParticipantInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type StorePayableFilter = {
  __typename?: 'StorePayableFilter';
  pagination?: Maybe<SdPagination>;
  payables?: Maybe<Array<Maybe<PayableInfo>>>;
};

export type StorePayableFilterUnionType = OnException | StorePayableFilter;

export type StorePayableInfoUnionType = OnException | PayableInfo;

export type StorePaymantIntentUnionType = OnException | StorePaymentIntent;

export type StorePaymentIntent = {
  __typename?: 'StorePaymentIntent';
  clientSecret?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  userSsoId?: Maybe<Scalars['String']>;
};

export type StorePointEarnedGraphInput = {
  interval?: InputMaybe<Scalars['Int']>;
  managerId: Scalars['Int'];
  scopeId: Scalars['Int'];
};

export type StorePointsWithdrawalFilter = {
  __typename?: 'StorePointsWithdrawalFilter';
  pagination?: Maybe<SdPagination>;
  withdrawalRequests?: Maybe<Array<Maybe<StorePointsWithdrawalRequest>>>;
};

export type StorePointsWithdrawalFilterUnionType = OnException | StorePointsWithdrawalFilter;

export type StorePointsWithdrawalRequest = {
  __typename?: 'StorePointsWithdrawalRequest';
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  expactedWithdrawalDate?: Maybe<Scalars['String']>;
  payableInfo?: Maybe<PayableInfo>;
  pointsType?: Maybe<Scalars['String']>;
  requesterInfo?: Maybe<StorePointsWithdrawalRequester>;
  scopeInfo?: Maybe<StoresWithdrawalPointsScope>;
  ticketId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type StorePointsWithdrawalRequestInput = {
  changeCampaignPayableDetails: Scalars['Boolean'];
  payableDetails: StoreWithdrawalPointsPayableInput;
  payableType: Scalars['String'];
  pointsType: Scalars['String'];
  requester: StorePointsWithdrawalRequesterInput;
  scope: StoresWithdrawalPointsScopeInput;
  taxDocument?: InputMaybe<Scalars['String']>;
  withdrawalPointsAmount: Scalars['Int'];
};

export type StorePointsWithdrawalRequestUnionType = OnException | StorePointsWithdrawalRequest;

export type StorePointsWithdrawalRequester = {
  __typename?: 'StorePointsWithdrawalRequester';
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  userSsoId?: Maybe<Scalars['String']>;
};

export type StorePointsWithdrawalRequesterInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  userSsoId: Scalars['String'];
};

export type StoreProfitPercentageUpdated = {
  __typename?: 'StoreProfitPercentageUpdated';
  updated_at?: Maybe<Scalars['String']>;
  updated_product_price_percentage?: Maybe<Scalars['String']>;
};

export type StoreReceiverData = {
  __typename?: 'StoreReceiverData';
  email?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['Float']>;
  receiverId?: Maybe<Scalars['BigInt']>;
};

export type StoreSaveTransaction = {
  amount: Scalars['Float'];
  email: Scalars['String'];
  points: Scalars['Float'];
  scopeId: Scalars['Int'];
  transactionInfo: Scalars['String'];
  userSsoId: Scalars['String'];
};

export type StoreScopeBestSeller = {
  __typename?: 'StoreScopeBestSeller';
  bestSellers?: Maybe<Array<Maybe<StoreBestSeller>>>;
};

export type StoreSender = {
  email: Scalars['String'];
  scopeId: Scalars['Int'];
  senderId: Scalars['Int'];
};

export type StoreSenderData = {
  __typename?: 'StoreSenderData';
  email?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['Float']>;
  senderId?: Maybe<Scalars['BigInt']>;
};

export type StoreStatusInput = {
  reasonWhy?: InputMaybe<StoreStatusReasonWhy>;
  scopeId: Scalars['Int'];
  status: Scalars['Boolean'];
};

export type StoreStatusReasonWhy = {
  comment?: InputMaybe<Scalars['String']>;
  commentOption?: InputMaybe<Scalars['String']>;
};

export type StoreSubscribedUser = {
  __typename?: 'StoreSubscribedUser';
  isSubscribed?: Maybe<Scalars['Boolean']>;
};

export type StoreSubscribedUserUnionType = OnException | StoreSubscribedUser;

export type StoreSummaryByCampaignIdsInput = {
  campaignIds: Array<Scalars['Int']>;
  scopeIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  timeline?: InputMaybe<Scalars['Int']>;
};

export type StoreTicket = {
  __typename?: 'StoreTicket';
  createdAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  scopeId?: Maybe<Scalars['Int']>;
  subject?: Maybe<Scalars['String']>;
  ticketId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type StoreTicketInput = {
  message: Scalars['String'];
  requester: StoreTicketRequester;
  scopeId: Scalars['Int'];
  storeCode: Scalars['String'];
  subject: Scalars['String'];
};

export type StoreTicketRequester = {
  email: Scalars['String'];
  name: Scalars['String'];
};

export type StoreTicketUnionType = OnException | StoreTickets;

export type StoreTickets = {
  __typename?: 'StoreTickets';
  tickets?: Maybe<Array<Maybe<StoreTicket>>>;
};

export type StoreTicketsUnionType = OnException | StoreTicket;

export type StoreTransaction = {
  __typename?: 'StoreTransaction';
  amount?: Maybe<Scalars['Float']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['Float']>;
  scopeId?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  userSsoId?: Maybe<Scalars['String']>;
};

export type StoreTransactionUnionType = OnException | StoreTransaction;

export type StoreTransferCustomer = {
  customer?: InputMaybe<StoreCustomerInput>;
  points: Scalars['Float'];
  sender?: InputMaybe<StoreSender>;
};

export type StoreTransferGl = {
  groupLeader?: InputMaybe<StoreGlInput>;
  points: Scalars['Float'];
  sender?: InputMaybe<StoreSender>;
};

export type StoreTransferParticipant = {
  participant?: InputMaybe<StoreParticipantInput>;
  points: Scalars['Float'];
  sender?: InputMaybe<StoreSender>;
};

export type StoreTransferPointsUnionType = OnException | StoreTransferReturn;

export type StoreTransferReturn = {
  __typename?: 'StoreTransferReturn';
  points?: Maybe<Scalars['Float']>;
  receiver?: Maybe<StoreReceiverData>;
  sender?: Maybe<StoreSenderData>;
  status?: Maybe<Scalars['String']>;
};

export type StoreUpdatePayableInput = {
  ein: Scalars['Int'];
  fullAddressOne: Scalars['String'];
  fullAddressTwo?: InputMaybe<Scalars['String']>;
  payableName: Scalars['String'];
};

export type StoreUser = {
  __typename?: 'StoreUser';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['BigInt']>;
};

export type StoreUserCampaignUnionType = OnException | StoreUserCampaigns;

export type StoreUserCampaigns = {
  __typename?: 'StoreUserCampaigns';
  campaigns?: Maybe<Array<Maybe<StoreCampaign>>>;
  pagination?: Maybe<SdPagination>;
};

export type StoreUserCampaignsInput = {
  all?: InputMaybe<Scalars['Boolean']>;
  demo?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderBySort?: InputMaybe<Scalars['String']>;
  searchBy?: InputMaybe<Scalars['String']>;
  searchValue?: InputMaybe<Scalars['ID']>;
};

export type StoreUserInfoUnionType = OnException | StoreUser;

export type StoreUserParticipantUnionType = OnException | StoreUserParticipants;

export type StoreUserParticipants = {
  __typename?: 'StoreUserParticipants';
  pagination?: Maybe<SdPagination>;
  participants?: Maybe<Array<Maybe<StoreParticipant>>>;
};

export type StoreUserParticipantsInput = {
  all?: InputMaybe<Scalars['Boolean']>;
  campaignIds: Array<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type StoreWithdrawalPointsPayableInput = {
  city: Scalars['String'];
  country: Scalars['String'];
  ein: Scalars['Int'];
  fullAddressOne: Scalars['String'];
  fullAddressTwo?: InputMaybe<Scalars['String']>;
  identityId: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  payableName: Scalars['String'];
  region: Scalars['String'];
  street?: InputMaybe<Scalars['String']>;
  zip: Scalars['Int'];
};

export type StoresFilter = {
  __typename?: 'StoresFilter';
  pagination?: Maybe<SdPagination>;
  stores?: Maybe<Array<Maybe<MagentoStore>>>;
};

export type StoresSummary = {
  __typename?: 'StoresSummary';
  baseSales?: Maybe<Scalars['Float']>;
  discountAmount?: Maybe<Scalars['Float']>;
  orders?: Maybe<Scalars['BigInt']>;
  points?: Maybe<Scalars['Float']>;
  sales?: Maybe<Scalars['Float']>;
  stores?: Maybe<Scalars['BigInt']>;
};

export type StoresSummaryByGlEmailInput = {
  email: Scalars['String'];
  scopeIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  timeline?: InputMaybe<Scalars['Int']>;
};

export type StoresSummaryUnionType = OnException | StoresSummary;

export type StoresWithdrawalPointsScope = {
  __typename?: 'StoresWithdrawalPointsScope';
  managerEmail?: Maybe<Scalars['String']>;
  managerId?: Maybe<Scalars['Int']>;
  scopeCode?: Maybe<Scalars['String']>;
  scopeId?: Maybe<Scalars['Int']>;
  teamName?: Maybe<Scalars['String']>;
};

export type StoresWithdrawalPointsScopeInput = {
  scopeCode: Scalars['String'];
  scopeId: Scalars['Int'];
  teamName: Scalars['String'];
};

/** Filtering options for nullable String type */
export type StringNullableFilter = {
  /** Contains operation */
  contains?: InputMaybe<Scalars['String']>;
  /** Ends With operation */
  endsWith?: InputMaybe<Scalars['String']>;
  /** Equals operation */
  equals?: InputMaybe<Scalars['String']>;
  /** Greater Than operation */
  gt?: InputMaybe<Scalars['String']>;
  /** Greater Than or Equal operation */
  gte?: InputMaybe<Scalars['String']>;
  /** In operation for multiple values */
  in?: InputMaybe<Array<Scalars['String']>>;
  /** Less Than operation */
  lt?: InputMaybe<Scalars['String']>;
  /** Less Than or Equal operation */
  lte?: InputMaybe<Scalars['String']>;
  /** Not Equal operation */
  not?: InputMaybe<Scalars['String']>;
  /** Not In operation for multiple values */
  notIn?: InputMaybe<Array<Scalars['String']>>;
  /** Starts With operation */
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StripeAccount = {
  __typename?: 'StripeAccount';
  accountId: Scalars['String'];
  accountType?: Maybe<AccountType>;
  campaignId: Scalars['String'];
};

export enum StripeEnv {
  Raise = 'RAISE',
  Spend = 'SPEND',
  Sponsor = 'SPONSOR'
}

/** metadata passed to Stripe API */
export type StripeMetadata = {
  __typename?: 'StripeMetadata';
  activityType?: Maybe<Scalars['String']>;
  entity?: Maybe<Scalars['String']>;
  fundraiserId?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
};

/** metadata to be passed to Stripe API */
export type StripeMetadataInput = {
  activityType?: InputMaybe<Scalars['String']>;
  entity?: InputMaybe<Scalars['String']>;
  fundraiserId?: InputMaybe<Scalars['String']>;
  paymentType?: InputMaybe<Scalars['String']>;
  transactionId?: InputMaybe<Scalars['String']>;
};

export enum SupportedEmailProviders {
  Sendgrid = 'sendgrid',
  Test = 'test'
}

export enum SupportedSmsProviders {
  Test = 'test',
  Twilio = 'twilio'
}

/** See CampaignMembership for details */
export type SupporterCampaign = {
  __typename?: 'SupporterCampaign';
  id: Scalars['ID'];
};

export type Team = {
  __typename?: 'Team';
  facility?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  groupval?: Maybe<Scalars['String']>;
  home_field?: Maybe<Scalars['String']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  level1?: Maybe<Scalars['String']>;
  sport_code: Scalars['String'];
  sport_description?: Maybe<Scalars['String']>;
  sport_name: Scalars['String'];
};

export enum TeamAge {
  Adult = 'ADULT',
  Preteen = 'PRETEEN',
  Teen = 'TEEN',
  Youth = 'YOUTH'
}

export type TeamByCodeInput = {
  sport_code: Scalars['String'];
};

export type TeamCreateInput = {
  facility?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  groupval?: InputMaybe<Scalars['String']>;
  home_field?: InputMaybe<Scalars['String']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  level1?: InputMaybe<Scalars['String']>;
  sport_code?: InputMaybe<Scalars['String']>;
  sport_description?: InputMaybe<Scalars['String']>;
  sport_name?: InputMaybe<Scalars['String']>;
};

export type TeamDeleteInput = {
  sport_code: Scalars['String'];
};

export enum TeamGender {
  Coed = 'COED',
  Female = 'FEMALE',
  Male = 'MALE'
}

export enum TeamGroupType {
  Club = 'CLUB',
  Sport = 'SPORT'
}

export type TeamLeadership = {
  __typename?: 'TeamLeadership';
  org: Org;
  teams: Array<TeamWithTitle>;
};

export type TeamModifyInput = {
  facility?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  groupval?: InputMaybe<Scalars['String']>;
  home_field?: InputMaybe<Scalars['String']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  level1?: InputMaybe<Scalars['String']>;
  sport_code?: InputMaybe<Scalars['String']>;
  sport_description?: InputMaybe<Scalars['String']>;
  sport_name?: InputMaybe<Scalars['String']>;
};

export type TeamOfficialCreateInput = {
  id: Scalars['Int'];
  pay?: InputMaybe<Scalars['Float']>;
  pay_code?: InputMaybe<Scalars['String']>;
  sport: Scalars['String'];
  worker_duty?: InputMaybe<Scalars['String']>;
};

export type TeamOfficialDeleteInput = {
  id: Scalars['Int'];
};

export type TeamOfficialModifyInput = {
  id: Scalars['Int'];
  pay?: InputMaybe<Scalars['Float']>;
  pay_code?: InputMaybe<Scalars['String']>;
  worker_duty?: InputMaybe<Scalars['String']>;
};

export type TeamPreparationCreateInput = {
  id: Scalars['Int'];
  prep: Scalars['Int'];
  qty?: InputMaybe<Scalars['String']>;
  sport: Scalars['String'];
};

export type TeamPreparationDeleteInput = {
  id: Scalars['Int'];
};

export type TeamPreparationModifyInput = {
  id: Scalars['Int'];
  prep?: InputMaybe<Scalars['Int']>;
  qty?: InputMaybe<Scalars['String']>;
};

export enum TeamTier {
  First = 'FIRST',
  Freshman = 'FRESHMAN',
  FullProgram = 'FULL_PROGRAM',
  JuniorVarsity = 'JUNIOR_VARSITY',
  JuniorVarsityC = 'JUNIOR_VARSITY_C',
  Second = 'SECOND',
  Varsity = 'VARSITY'
}

export enum TeamTitle {
  AssistantCoach = 'ASSISTANT_COACH',
  BoosterClubLeader = 'BOOSTER_CLUB_LEADER',
  BoosterClubMember = 'BOOSTER_CLUB_MEMBER',
  ClubSportsAdministrator = 'CLUB_SPORTS_ADMINISTRATOR',
  ClubSportsDirector = 'CLUB_SPORTS_DIRECTOR',
  ClubSportsEmployee = 'CLUB_SPORTS_EMPLOYEE',
  Coach = 'COACH',
  FineArtsDirector = 'FINE_ARTS_DIRECTOR',
  SchoolClubAdvisor = 'SCHOOL_CLUB_ADVISOR',
  TeacherInstructor = 'TEACHER_INSTRUCTOR'
}

export type TeamWithTitle = {
  __typename?: 'TeamWithTitle';
  team: Org;
  titles: Array<Leader>;
};

export type TeamWorkerCreateInput = {
  home_field?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  pay?: InputMaybe<Scalars['Float']>;
  pay_code?: InputMaybe<Scalars['String']>;
  sport: Scalars['String'];
  worker_duty?: InputMaybe<Scalars['String']>;
  worker_name?: InputMaybe<Scalars['String']>;
};

export type TeamWorkerDeleteInput = {
  id: Scalars['Int'];
};

export type TeamWorkerModifyInput = {
  home_field?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  pay?: InputMaybe<Scalars['Float']>;
  pay_code?: InputMaybe<Scalars['String']>;
  worker_duty?: InputMaybe<Scalars['String']>;
  worker_name?: InputMaybe<Scalars['String']>;
};

export type TemplateOfficial = {
  __typename?: 'TemplateOfficial';
  id: Scalars['Int'];
  pay?: Maybe<Scalars['Float']>;
  pay_code?: Maybe<Scalars['String']>;
  sport?: Maybe<Scalars['String']>;
  worker_duty?: Maybe<Scalars['String']>;
};

export type TemplatePreparation = {
  __typename?: 'TemplatePreparation';
  id: Scalars['Int'];
  prep?: Maybe<Scalars['Int']>;
  preparation_id?: Maybe<Scalars['String']>;
  preparation_name?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['String']>;
  sport?: Maybe<Scalars['String']>;
};

export type TemplateWorker = {
  __typename?: 'TemplateWorker';
  home_field?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  pay?: Maybe<Scalars['Float']>;
  pay_code?: Maybe<Scalars['String']>;
  sport?: Maybe<Scalars['String']>;
  worker_duty?: Maybe<Scalars['String']>;
  worker_name?: Maybe<Scalars['String']>;
};

export type Tokens = {
  accessToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
};

export type TopDonation = {
  __typename?: 'TopDonation';
  donorName?: Maybe<Scalars['String']>;
  subtotalCents?: Maybe<Scalars['Float']>;
};

export type Total = {
  __typename?: 'Total';
  salary?: Maybe<Scalars['String']>;
  woker_name?: Maybe<Scalars['String']>;
};

export type TotalDonationsRaised = {
  __typename?: 'TotalDonationsRaised';
  numberOfDonations?: Maybe<Scalars['Int']>;
  subtotalCents?: Maybe<Scalars['Float']>;
};

export type Transaction = {
  __typename?: 'Transaction';
  amount?: Maybe<Scalars['Decimal']>;
  correlationId?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['Timestamp']>;
  description?: Maybe<Scalars['String']>;
  destination?: Maybe<Scalars['String']>;
  direction?: Maybe<Scalars['String']>;
  effective: Scalars['Timestamp'];
  externalId: Scalars['String'];
  id: Scalars['UUID'];
  metadata?: Maybe<Scalars['JSON']>;
  processor?: Maybe<Scalars['String']>;
  snapAmount?: Maybe<Scalars['Decimal']>;
  source?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type TransactionAttachment = {
  __typename?: 'TransactionAttachment';
  createdAt?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastUpdatedByUserId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  paymentId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type TransactionAttachmentsResponse = {
  __typename?: 'TransactionAttachmentsResponse';
  attachments?: Maybe<Array<Maybe<TransactionAttachment>>>;
};

export type TransactionDetail = {
  __typename?: 'TransactionDetail';
  amount?: Maybe<Scalars['Decimal']>;
  description: Scalars['String'];
  destination?: Maybe<Scalars['String']>;
  effective: Scalars['Timestamp'];
  externalId: Scalars['String'];
  history: Array<Maybe<TransactionHistoryEvent>>;
  id: Scalars['UUID'];
  metadata?: Maybe<Scalars['JSON']>;
  processor?: Maybe<Scalars['String']>;
  snapAmount?: Maybe<Scalars['Decimal']>;
  source?: Maybe<Scalars['String']>;
  status: Status;
  type?: Maybe<Scalars['String']>;
};

export enum TransactionFilterEnum {
  Date = 'date',
  GroupName = 'groupName',
  Method = 'method',
  ReconciledStatus = 'reconciledStatus',
  SeasonName = 'seasonName',
  Status = 'status',
  Type = 'type'
}

export enum TransactionFilterType {
  ApplicationFee = 'ApplicationFee',
  Transfer = 'Transfer',
  UserPayment = 'UserPayment'
}

export type TransactionHistoryEvent = {
  __typename?: 'TransactionHistoryEvent';
  date: Scalars['Timestamp'];
  status: Scalars['String'];
  transactionId: Scalars['UUID'];
};

export type TransactionInput = {
  emailOverride?: InputMaybe<Scalars['String']>;
  /** It's either going to be a donationId or a purchaseId */
  transactionId: Scalars['ID'];
  transactionType: TransactionType;
};

export type TransactionNotes = {
  __typename?: 'TransactionNotes';
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastUpdatedByUserId?: Maybe<Scalars['String']>;
  paymentId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type TransactionNotesResponse = {
  __typename?: 'TransactionNotesResponse';
  notes?: Maybe<Array<Maybe<TransactionNotes>>>;
};

export enum TransactionReportFilterEnum {
  DateAfter = 'dateAfter',
  DateBefore = 'dateBefore',
  GroupIdOrgId = 'groupIdOrgId',
  Method = 'method',
  NameIncludes = 'nameIncludes',
  Status = 'status',
  Type = 'type'
}

export type TransactionTotalCount = {
  __typename?: 'TransactionTotalCount';
  count?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export enum TransactionType {
  Donation = 'Donation',
  Purchase = 'Purchase'
}

export type TransactionsInput = {
  createdAfter?: InputMaybe<Scalars['Timestamp']>;
  createdBefore?: InputMaybe<Scalars['Timestamp']>;
  destination?: InputMaybe<Scalars['String']>;
  exclude?: InputMaybe<Array<InputMaybe<TransactionFilterType>>>;
  externalId?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['JSONObject']>;
  source?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
};

export type TransformedDailyCalendarBusSchedule = {
  __typename?: 'TransformedDailyCalendarBusSchedule';
  calendar?: Maybe<Array<Maybe<Scalars['String']>>>;
  events?: Maybe<Array<Maybe<DailyCalendarBusScheduleEvents>>>;
  exportdata?: Maybe<Array<Maybe<DailyCalendarBusScheduleExportData>>>;
};

export type TransformedDailyCalendarEventReturn = {
  __typename?: 'TransformedDailyCalendarEventReturn';
  activity?: Maybe<Scalars['String']>;
  activityLevel?: Maybe<Scalars['String']>;
  activityType?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  bus_fee?: Maybe<Scalars['Int']>;
  bus_time?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  conference?: Maybe<Scalars['String']>;
  conference_event_id?: Maybe<Scalars['Int']>;
  conference_id?: Maybe<Scalars['Int']>;
  confirmed?: Maybe<Scalars['String']>;
  confirmedStatusBoolean?: Maybe<Scalars['Boolean']>;
  contract?: Maybe<Scalars['String']>;
  departure_location?: Maybe<Scalars['String']>;
  directions?: Maybe<Scalars['String']>;
  duty?: Maybe<Scalars['String']>;
  early_dismissal_required?: Maybe<Scalars['String']>;
  early_dismissal_time?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['String']>;
  estimated_return_time?: Maybe<Scalars['String']>;
  event?: Maybe<Scalars['String']>;
  eventOfficial?: Maybe<Scalars['String']>;
  eventOfficialCell?: Maybe<Scalars['String']>;
  eventOfficialEmail?: Maybe<Scalars['String']>;
  eventTiming?: Maybe<Scalars['String']>;
  event_date?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['Int']>;
  exists_in_mls?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  formatedEventDate?: Maybe<Scalars['String']>;
  formatedEventDateSystem?: Maybe<Scalars['String']>;
  formatedEventDay?: Maybe<Scalars['String']>;
  g_s?: Maybe<Scalars['String']>;
  gate_revenue?: Maybe<Scalars['Int']>;
  groupval?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  impact_event?: Maybe<Scalars['String']>;
  isDuplicate?: Maybe<Scalars['String']>;
  lead?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  loss_points?: Maybe<Scalars['Int']>;
  noofgames?: Maybe<Scalars['String']>;
  num_buses?: Maybe<Scalars['Int']>;
  num_buses_text?: Maybe<Scalars['String']>;
  opponent?: Maybe<Scalars['String']>;
  opponent_code?: Maybe<Scalars['String']>;
  opponent_score?: Maybe<Scalars['String']>;
  pay_code?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  place?: Maybe<Scalars['String']>;
  prep_setup?: Maybe<Scalars['String']>;
  promote?: Maybe<Scalars['String']>;
  results?: Maybe<Scalars['String']>;
  revenue?: Maybe<Scalars['Int']>;
  rollover?: Maybe<Scalars['String']>;
  rolloverStatusBoolean?: Maybe<Scalars['Boolean']>;
  salary?: Maybe<Scalars['Int']>;
  seasonInfo?: Maybe<Scalars['String']>;
  seasonSportCode?: Maybe<Scalars['String']>;
  season_team?: Maybe<Scalars['Int']>;
  serialnumber?: Maybe<Scalars['String']>;
  sportCode?: Maybe<Scalars['String']>;
  sportDescription?: Maybe<Scalars['String']>;
  sportGender?: Maybe<Scalars['String']>;
  sportLevel?: Maybe<Scalars['String']>;
  sportName?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['String']>;
  team_score?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  tournament?: Maybe<Scalars['String']>;
  trans_id?: Maybe<Scalars['Int']>;
  transportDetails?: Maybe<Scalars['String']>;
  transport_comments?: Maybe<Scalars['String']>;
  transportation?: Maybe<Scalars['String']>;
  vehicle_count?: Maybe<Scalars['String']>;
  vehiclesTransportDetails?: Maybe<Array<Maybe<DailyCalendarEventTransportDetails>>>;
  web_dir?: Maybe<Scalars['String']>;
  weekdayname?: Maybe<Scalars['String']>;
  win_points?: Maybe<Scalars['Int']>;
  years?: Maybe<Scalars['String']>;
};

export type TransformedDailyCalendarOfficials = {
  __typename?: 'TransformedDailyCalendarOfficials';
  exportdata?: Maybe<Array<Maybe<DailyCalendarOfficialExport>>>;
  message?: Maybe<Array<Maybe<DailyCalendarOfficialMessage>>>;
};

export type TransformedDailyCalendarPreparation = {
  __typename?: 'TransformedDailyCalendarPreparation';
  exportdata?: Maybe<Array<Maybe<DailyCalendarPreparationExportData>>>;
  message?: Maybe<Array<Maybe<DailyCalendarPreparationMessage>>>;
};

export type TransformedDailyCalendarWorkers = {
  __typename?: 'TransformedDailyCalendarWorkers';
  exportdata?: Maybe<Array<Maybe<DailyCalendarWorkerExportData>>>;
  message?: Maybe<Array<Maybe<DailyCalendarWorkerMessage>>>;
};

export type TransformedEventReturn = {
  __typename?: 'TransformedEventReturn';
  activity?: Maybe<Scalars['String']>;
  activityLevel?: Maybe<Scalars['String']>;
  activityType?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  bus_fee?: Maybe<Scalars['Int']>;
  bus_time?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  conference?: Maybe<Scalars['String']>;
  conference_event_id?: Maybe<Scalars['Int']>;
  conference_id?: Maybe<Scalars['Int']>;
  confirmed?: Maybe<Scalars['String']>;
  confirmedStatusBoolean?: Maybe<Scalars['Boolean']>;
  contract?: Maybe<Scalars['String']>;
  departure_location?: Maybe<Scalars['String']>;
  directions?: Maybe<Scalars['String']>;
  duty?: Maybe<Scalars['String']>;
  early_dismissal_required?: Maybe<Scalars['String']>;
  early_dismissal_time?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['String']>;
  estimated_return_time?: Maybe<Scalars['String']>;
  event?: Maybe<Scalars['String']>;
  eventOfficial?: Maybe<Scalars['String']>;
  eventOfficialCell?: Maybe<Scalars['String']>;
  eventOfficialEmail?: Maybe<Scalars['String']>;
  eventTiming?: Maybe<Scalars['String']>;
  event_date?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['Int']>;
  exists_in_mls?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  formatedEventDate?: Maybe<Scalars['String']>;
  formatedEventDateSystem?: Maybe<Scalars['String']>;
  formatedEventDay?: Maybe<Scalars['String']>;
  g_s?: Maybe<Scalars['String']>;
  gate_revenue?: Maybe<Scalars['Int']>;
  groupval?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  impact_event?: Maybe<Scalars['String']>;
  isDuplicate?: Maybe<Scalars['String']>;
  lead?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  loss_points?: Maybe<Scalars['Int']>;
  noofgames?: Maybe<Scalars['String']>;
  num_buses?: Maybe<Scalars['Int']>;
  num_buses_text?: Maybe<Scalars['String']>;
  opponent?: Maybe<Scalars['String']>;
  opponent_code?: Maybe<Scalars['String']>;
  opponent_score?: Maybe<Scalars['String']>;
  pay_code?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  place?: Maybe<Scalars['String']>;
  prep_setup?: Maybe<Scalars['String']>;
  promote?: Maybe<Scalars['String']>;
  results?: Maybe<Scalars['String']>;
  revenue?: Maybe<Scalars['Int']>;
  rollover?: Maybe<Scalars['String']>;
  rolloverStatusBoolean?: Maybe<Scalars['Boolean']>;
  salary?: Maybe<Scalars['Int']>;
  seasonInfo?: Maybe<Scalars['String']>;
  seasonSportCode?: Maybe<Scalars['String']>;
  season_team?: Maybe<Scalars['Int']>;
  serialnumber?: Maybe<Scalars['String']>;
  sportCode?: Maybe<Scalars['String']>;
  sportDescription?: Maybe<Scalars['String']>;
  sportGender?: Maybe<Scalars['String']>;
  sportLevel?: Maybe<Scalars['String']>;
  sportName?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['String']>;
  team_score?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  tournament?: Maybe<Scalars['String']>;
  trans_id?: Maybe<Scalars['Int']>;
  transportDetails?: Maybe<Scalars['String']>;
  transport_comments?: Maybe<Scalars['String']>;
  transportation?: Maybe<Scalars['String']>;
  vehicle_count?: Maybe<Scalars['String']>;
  web_dir?: Maybe<Scalars['String']>;
  weekdayname?: Maybe<Scalars['String']>;
  win_points?: Maybe<Scalars['Int']>;
  years?: Maybe<Scalars['String']>;
};

export type TransformedOfficialAssignment = {
  __typename?: 'TransformedOfficialAssignment';
  duty?: Maybe<Scalars['String']>;
  eventActivity?: Maybe<Scalars['String']>;
  eventDate?: Maybe<Scalars['String']>;
  eventHomeAway?: Maybe<Scalars['String']>;
  eventLocation?: Maybe<Scalars['String']>;
  eventOpponent?: Maybe<Scalars['String']>;
  eventTime?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  paid?: Maybe<Scalars['String']>;
  pay_code?: Maybe<Scalars['String']>;
  salary?: Maybe<Scalars['Int']>;
  seasonSportCode?: Maybe<Scalars['String']>;
  sportDescription?: Maybe<Scalars['String']>;
  ssn?: Maybe<Scalars['String']>;
};

export enum TransportEnum {
  Email = 'email',
  Sms = 'sms'
}

export type TransportType = {
  __typename?: 'TransportType';
  recipient?: Maybe<Scalars['String']>;
  transport?: Maybe<TransportEnum>;
};

export type UnconfirmedEvents = {
  __typename?: 'UnconfirmedEvents';
  activity?: Maybe<Scalars['String']>;
  bus_count?: Maybe<Scalars['Int']>;
  comments?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['String']>;
  driver_name?: Maybe<Scalars['String']>;
  driver_phone?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['String']>;
  event_date?: Maybe<Scalars['String']>;
  facility?: Maybe<Scalars['String']>;
  g_s?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  home_field?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  levels?: Maybe<Scalars['String']>;
  opponent?: Maybe<Scalars['String']>;
  place?: Maybe<Scalars['String']>;
  season_id?: Maybe<Scalars['Int']>;
  sports_code?: Maybe<Scalars['String']>;
  sports_description?: Maybe<Scalars['String']>;
  sports_group?: Maybe<Scalars['String']>;
  sports_name?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['String']>;
  vehicle_id?: Maybe<Scalars['String']>;
  vehicle_type?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

export type UnconfirmedEventsFiltersInput = {
  activity?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  levels?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  opponents?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  teams?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  year?: InputMaybe<Scalars['String']>;
};

export type UnconfirmedEventsList = {
  __typename?: 'UnconfirmedEventsList';
  items?: Maybe<Array<Maybe<UnconfirmedEvents>>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  totalFilteredItems?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
  totalRows?: Maybe<Scalars['Int']>;
};

export type UnprocessableSubmission = Error & {
  __typename?: 'UnprocessableSubmission';
  message: Scalars['String'];
};

export type UnreadCount = {
  __typename?: 'UnreadCount';
  totalUnreadCount: Scalars['Int'];
};

export type UpdatedFundraiserStoreUrl = {
  __typename?: 'UpdatedFundraiserStoreUrl';
  id?: Maybe<Scalars['Int']>;
};

export type UpdatedParticipantGroupCount = {
  __typename?: 'UpdatedParticipantGroupCount';
  count?: Maybe<Scalars['Int']>;
};

export type UpsertEventParticipantsCount = {
  __typename?: 'UpsertEventParticipantsCount';
  count?: Maybe<Scalars['Int']>;
};

export type UpsertEventParticipantsInput = {
  eventParticipantsArray: Array<InputMaybe<EventParticipantsInput>>;
};

export type UpsertEventPreparationsCount = {
  __typename?: 'UpsertEventPreparationsCount';
  count?: Maybe<Scalars['Int']>;
};

export type UpsertEventPreparationsInput = {
  eventPreparationsArray: Array<InputMaybe<EventPreparationsInput>>;
};

export type UpsertEventTransportDetailsCount = {
  __typename?: 'UpsertEventTransportDetailsCount';
  count?: Maybe<Scalars['Int']>;
};

export type UpsertEventTransportDetailsInput = {
  eventTransportDetailsArray: Array<InputMaybe<EventTransportDetailsInput>>;
};

export type UpsertFacilitiesInput = {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  directions?: InputMaybe<Scalars['String']>;
  facility_name: Scalars['String'];
  location_id?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type UpsertManyWorkersInput = {
  workersArray: Array<InputMaybe<CreateWorkerInput>>;
};

export type UpsertOfficialDutiesInput = {
  duty?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
};

export type UpsertOfficialsInput = {
  address?: InputMaybe<Scalars['String']>;
  cell_phone?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  home_phone?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  official_id: Scalars['String'];
  ssn?: InputMaybe<Scalars['String']>;
  work_phone?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type UpsertOpponentsInput = {
  ad_name?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  fax?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  school_code: Scalars['String'];
  school_name?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type UpsertWorkersInput = {
  cell_phone?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  home_phone?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  last_name?: InputMaybe<Scalars['String']>;
  pay_rate?: InputMaybe<Scalars['Float']>;
  ssn?: InputMaybe<Scalars['String']>;
  worker_type?: InputMaybe<WorkerTypes>;
};

export type UrgentAnnouncementFilterOld = {
  orderBy?: InputMaybe<ManageAnnouncementOrderByOld>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ManageAnnouncementWhereOld>;
};

export type User = {
  __typename?: 'User';
  apps?: Maybe<Array<Maybe<Scalars['String']>>>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isConfirmed?: Maybe<Scalars['Boolean']>;
  isDisabled?: Maybe<Scalars['Boolean']>;
  kyc?: Maybe<VaultKyc>;
  language?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  occupation?: Maybe<UserOccupation>;
  phoneNumber?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  /** @deprecated will be removed in the next release */
  snapRaiseId?: Maybe<Scalars['String']>;
  /** @deprecated will be removed in the next release */
  snapSpendId?: Maybe<Scalars['String']>;
  walletOrganization?: Maybe<Organization>;
  walletTerms?: Maybe<Scalars['Boolean']>;
};

export type UserAffiliation = {
  __typename?: 'UserAffiliation';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  isConfirmed?: Maybe<Scalars['Boolean']>;
  title: AffiliationTitle;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserAffiliationCounts = {
  __typename?: 'UserAffiliationCounts';
  affiliatedCount: Scalars['Int'];
  impliedCount: Scalars['Int'];
  leadsCount: Scalars['Int'];
  total: Scalars['Int'];
};

export enum UserApps {
  Connect = 'connect',
  Drive = 'drive',
  Home = 'home',
  Insights = 'insights',
  Manage = 'manage',
  Raise = 'raise',
  Spend = 'spend',
  SpendNew = 'spend_new',
  Sponsor = 'sponsor',
  Store = 'store',
  Wallet = 'wallet'
}

export type UserChallenge = {
  __typename?: 'UserChallenge';
  completedAt?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  params?: Maybe<Scalars['JSONObject']>;
  skippedAt?: Maybe<Scalars['String']>;
  status: UserChallengeStatus;
};

export enum UserChallengeStatus {
  Awaiting = 'AWAITING',
  Completed = 'COMPLETED',
  Skipped = 'SKIPPED'
}

export type UserFitting = {
  __typename?: 'UserFitting';
  fitting?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export enum UserFittingPreference {
  Mens = 'MENS',
  Womens = 'WOMENS'
}

export type UserInsightsConfig = {
  __typename?: 'UserInsightsConfig';
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  name: Scalars['NonEmptyString'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['NonEmptyString'];
  value: Scalars['NonEmptyString'];
};

export type UserInsightsConfigInput = {
  name: Scalars['NonEmptyString'];
  value?: InputMaybe<Scalars['NonEmptyString']>;
};

export type UserInsightsConfigResult = {
  __typename?: 'UserInsightsConfigResult';
  messages: Array<Maybe<Scalars['String']>>;
  status: Scalars['String'];
};

export type UserInviteParams = {
  __typename?: 'UserInviteParams';
  occupation?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type UserInviteResponse = {
  __typename?: 'UserInviteResponse';
  redirect?: Maybe<Scalars['String']>;
};

export enum UserInviteStatus {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Pending = 'PENDING'
}

export type UserNode = {
  __typename?: 'UserNode';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  internal?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Scalars['ID']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum UserOccupation {
  DistrictAdministrator = 'district_administrator',
  FinancialAdministrator = 'financial_administrator',
  GroupLeader = 'group_leader',
  Other = 'other',
  Parent = 'parent',
  SchoolDirector = 'school_director',
  StateAdministrator = 'state_administrator',
  StudentOrParticipant = 'student_or_participant',
  Unknown = 'unknown'
}

export type UserOrgAffiliationPayload = {
  orgId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UserOrgInvitationPayload = {
  email?: InputMaybe<Scalars['String']>;
  orgId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UserOrgInvitationResult = {
  __typename?: 'UserOrgInvitationResult';
  id?: Maybe<Scalars['String']>;
  info?: Maybe<Scalars['JSON']>;
  meta?: Maybe<Scalars['JSON']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UserPermission = {
  __typename?: 'UserPermission';
  id: Scalars['String'];
  scope?: Maybe<Scalars['String']>;
};

export type UserPermissionAssignment = {
  id: Scalars['String'];
  scope?: InputMaybe<Scalars['String']>;
};

export type UserPermissionsList = {
  __typename?: 'UserPermissionsList';
  id?: Maybe<Scalars['String']>;
  negativePermissions?: Maybe<Array<Maybe<UserPermission>>>;
  permissions?: Maybe<Array<Maybe<UserPermission>>>;
  roleIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserPreference = {
  __typename?: 'UserPreference';
  featureTourCompleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export enum UserPreferenceQueryType {
  All = 'ALL',
  Campaign = 'CAMPAIGN',
  Org = 'ORG'
}

export type UserPublic = {
  __typename?: 'UserPublic';
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isInternal?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  notMigrated?: Maybe<Scalars['Boolean']>;
  profilePicture?: Maybe<Scalars['String']>;
};

export type UserPublicInfo = {
  __typename?: 'UserPublicInfo';
  isInternal?: Maybe<Scalars['Boolean']>;
  notMigrated?: Maybe<Scalars['Boolean']>;
};

export type UserPublicInfoResult = {
  __typename?: 'UserPublicInfoResult';
  info?: Maybe<UserPublicInfo>;
  user?: Maybe<UserPublic>;
};

export type UserResetPasswordByAdminResponse = {
  __typename?: 'UserResetPasswordByAdminResponse';
  user?: Maybe<User>;
};

export type UserResetPasswordResponseWithAvailableTransport = {
  __typename?: 'UserResetPasswordResponseWithAvailableTransport';
  availableTransport: Array<TransportEnum>;
  info?: Maybe<Scalars['String']>;
  result: Array<TransportType>;
};

export type UserSavedRep = {
  __typename?: 'UserSavedRep';
  snapRepEmail?: Maybe<Scalars['String']>;
  uuId?: Maybe<Scalars['String']>;
};

export enum UserTypeConfiguration {
  Default = 'DEFAULT',
  GuardianLead = 'GUARDIAN_LEAD'
}

export type UserWithPermissions = {
  __typename?: 'UserWithPermissions';
  apps?: Maybe<Array<Maybe<Scalars['String']>>>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  hasFamily?: Maybe<Scalars['Boolean']>;
  hsGradYear?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isInternal?: Maybe<Scalars['Boolean']>;
  kyc?: Maybe<VaultKyc>;
  language?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  occupation?: Maybe<UserOccupation>;
  /** @deprecated use parents instead of parent */
  parent?: Maybe<Scalars['String']>;
  parents?: Maybe<Array<Maybe<Scalars['String']>>>;
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  phoneNumber?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  /** @deprecated will be removed in the next release */
  snapRaiseId?: Maybe<Scalars['String']>;
  /** @deprecated will be removed in the next release */
  snapSpendId?: Maybe<Scalars['String']>;
  walletOrganization?: Maybe<Organization>;
  walletTerms?: Maybe<Scalars['Boolean']>;
};

export type Users = {
  __typename?: 'Users';
  nextPage?: Maybe<Scalars['Boolean']>;
  users?: Maybe<Array<Maybe<User>>>;
};

export type VaultAccountStatus = {
  __typename?: 'VaultAccountStatus';
  accountRepresentativeRequirements?: Maybe<VaultEntityRequirements>;
  beneficialOwnersRequirements?: Maybe<Array<VaultEntityRequirements>>;
  kybRequirements?: Maybe<VaultEntityRequirements>;
  valid: Scalars['Boolean'];
};

/** Used for addresses in VaultKyb and VaultKyc */
export type VaultAddress = {
  __typename?: 'VaultAddress';
  city: Scalars['String'];
  postalCode: Scalars['String'];
  region: Scalars['String'];
  street: Scalars['String'];
  unit?: Maybe<Scalars['String']>;
};

/** Used for addresses in VaultKyb, VaultKyc and latestCreateCard */
export type VaultAddressInput = {
  city: Scalars['String'];
  postalCode: Scalars['String'];
  region: Scalars['String'];
  street: Scalars['String'];
  unit?: InputMaybe<Scalars['String']>;
};

/** used in vaultKybUpdate and vaultKycUpdate */
export type VaultAddressUpdateInput = {
  city?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<Scalars['String']>;
};

export type VaultBankAccount = {
  __typename?: 'VaultBankAccount';
  account_holder_name: Scalars['String'];
  account_holder_type: Scalars['String'];
  bank_name: Scalars['String'];
  country: Scalars['String'];
  currency: Scalars['String'];
  default_for_currency: Scalars['Boolean'];
  fingerprint: Scalars['String'];
  id: Scalars['ID'];
  last4: Scalars['String'];
  routing_number: Scalars['String'];
  status: Scalars['String'];
};

/** Additional data passed into the Stripe api Can be read in Stripe dashboard */
export type VaultCardMetadata = {
  __typename?: 'VaultCardMetadata';
  description?: Maybe<Scalars['String']>;
  walletId?: Maybe<Scalars['String']>;
};

/** Additional data passed into the Stripe api Can be read in Stripe dashboard */
export type VaultCardMetadataInput = {
  description?: InputMaybe<Scalars['String']>;
  walletId?: InputMaybe<Scalars['String']>;
};

/** Current status of card */
export enum VaultCardStatus {
  Active = 'active',
  /** To deactivate a card set it to canceled */
  Canceled = 'canceled',
  /** Cards ship inactive */
  Inactive = 'inactive'
}

/** Is the card physical (needs to mailed out) or virtual */
export enum VaultCardType {
  Physical = 'physical',
  Virtual = 'virtual'
}

export type VaultCreateKybKycStripeInput = {
  metadata?: InputMaybe<StripeMetadataInput>;
  statementDescriptor?: InputMaybe<Scalars['String']>;
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};

/** Day, Month and Year of birth */
export type VaultDob = {
  __typename?: 'VaultDob';
  day?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

/** Day, Month and Year of birth */
export type VaultDobInput = {
  day: Scalars['String'];
  month: Scalars['String'];
  year: Scalars['String'];
};

export enum VaultDocumentProvider {
  Stripe = 'stripe',
  Unit = 'unit'
}

export type VaultEntityRequirements = {
  __typename?: 'VaultEntityRequirements';
  missingFields: Array<Scalars['String']>;
  requiredDocuments: Array<VaultRequiredDocument>;
};

/** Third party financial providers */
export enum VaultFinancialProvider {
  Stripe = 'stripe',
  Unit = 'unit'
}

export type VaultFormKybInput = {
  accountId: Scalars['ID'];
  financialAccountNodeId?: InputMaybe<Scalars['ID']>;
  kybId: Scalars['ID'];
  orgId: Scalars['ID'];
};

export type VaultFormMutationError = {
  __typename?: 'VaultFormMutationError';
  details?: Maybe<Scalars['JSONObject']>;
  field?: Maybe<Scalars['String']>;
  fieldMessage?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  retryable: Scalars['Boolean'];
  type: Scalars['String'];
};

export type VaultFormMutationResponse = {
  __typename?: 'VaultFormMutationResponse';
  errors?: Maybe<Array<VaultFormMutationError>>;
  success: Scalars['Boolean'];
  successData?: Maybe<VaultFormSuccessData>;
};

export type VaultFormStripeInput = {
  metadata?: InputMaybe<StripeMetadataInput>;
  statementDescriptor?: InputMaybe<Scalars['String']>;
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};

export type VaultFormSuccessData = {
  __typename?: 'VaultFormSuccessData';
  beneficialOwners?: Maybe<Array<VaultKycSuccessData>>;
  kybData: VaultKybSuccessData;
  payableOrgNode?: Maybe<VaultPayableOrgSuccessData>;
  representative: VaultKycSuccessData;
  stripeEnv?: Maybe<VaultStripeEnv>;
};

/** Organizations/Companies */
export type VaultKyb = {
  __typename?: 'VaultKyb';
  address: VaultAddress;
  customerFacingName: Scalars['String'];
  description: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  entityDocument?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  legalName: Scalars['String'];
  phoneNumber: Scalars['String'];
  structure: Kyb_Structure;
  taxId: Scalars['String'];
  type: Kyb_Type;
  url: Scalars['String'];
};

export type VaultKybCreateInput = {
  address: VaultAddressInput;
  customerFacingName: Scalars['String'];
  description: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  legalName: Scalars['String'];
  orgId: Scalars['ID'];
  phoneNumber: Scalars['String'];
  structure: Kyb_Structure;
  taxId: Scalars['String'];
  type: Kyb_Type;
  url: Scalars['String'];
};

export type VaultKybInput = {
  accountId: Scalars['ID'];
  financialAccountNodeId?: InputMaybe<Scalars['ID']>;
  kybId: Scalars['ID'];
  orgId: Scalars['ID'];
};

export type VaultKybSuccessData = {
  __typename?: 'VaultKybSuccessData';
  financialAccountId?: Maybe<Scalars['ID']>;
  kybId: Scalars['ID'];
  metadata?: Maybe<StripeMetadata>;
  orgId?: Maybe<Scalars['ID']>;
  stripeConnectAccountId?: Maybe<Scalars['String']>;
};

/** Individuals */
export type VaultKyc = {
  __typename?: 'VaultKyc';
  address: VaultAddress;
  director?: Maybe<Scalars['Boolean']>;
  dob: VaultDob;
  email: Scalars['String'];
  executive?: Maybe<Scalars['Boolean']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  owner?: Maybe<Scalars['Boolean']>;
  /** If owner=true, percentOwnership is required */
  percentOwnership?: Maybe<Scalars['Int']>;
  phoneNumber: Scalars['String'];
  representative?: Maybe<Scalars['Boolean']>;
  ssn?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type VaultKycCreateInput = {
  address: VaultAddressInput;
  director?: InputMaybe<Scalars['Boolean']>;
  dob: VaultDobInput;
  email: Scalars['String'];
  executive?: InputMaybe<Scalars['Boolean']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  owner?: InputMaybe<Scalars['Boolean']>;
  percentOwnership?: InputMaybe<Scalars['Int']>;
  phoneNumber: Scalars['String'];
  representative?: InputMaybe<Scalars['Boolean']>;
  ssn?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type VaultKycSuccessData = {
  __typename?: 'VaultKycSuccessData';
  kycId: Scalars['ID'];
  stripePersonId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
};

export type VaultMutationError = {
  __typename?: 'VaultMutationError';
  message: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type VaultMutationResponse = {
  __typename?: 'VaultMutationResponse';
  errors?: Maybe<Array<Maybe<VaultMutationError>>>;
  success: Scalars['Boolean'];
};

export type VaultPayableOrgSuccessData = {
  __typename?: 'VaultPayableOrgSuccessData';
  beneficialOwners?: Maybe<Array<VaultKycSuccessData>>;
  kybData: VaultKybSuccessData;
  representative: VaultKycSuccessData;
};

export enum VaultPayoutInterval {
  Daily = 'daily',
  Manual = 'manual',
  Monthly = 'monthly',
  Weekly = 'weekly'
}

export type VaultRepresentativeCreateInput = {
  kycData?: InputMaybe<VaultKycCreateInput>;
  kycId?: InputMaybe<Scalars['ID']>;
  stripePersonId?: InputMaybe<Scalars['ID']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export enum VaultRequiredDocument {
  /** Utility bill, bank statement, lease agreement or current pay stub */
  AddressVerification = 'address_verification',
  /** Bank Statement or Void Check */
  BankAccountDocument = 'bank_account_document',
  /** Certificate of incorporation or certificate of assumed name */
  CertificateOfIncorporation = 'certificate_of_incorporation',
  /** IRS form 147c or IRS form CP-575 */
  EinConfirmation = 'ein_confirmation',
  /** Passport or Drivers License */
  IdDocument = 'id_document',
  /** Passport, Drivers License or State ID */
  IdDocumentOrPassport = 'id_document_or_passport',
  /** 501C3 or SS4 */
  IrsDeterminationLetter = 'irs_determination_letter',
  /** Passport */
  Passport = 'passport',
  /** Power of attorney */
  PowerOfAttorney = 'power_of_attorney',
  /** SSN Card */
  SsnCard = 'ssn_card'
}

/** How to send a physical VaultCard */
export enum VaultShippingService {
  Express = 'express',
  Priority = 'priority',
  Standard = 'standard'
}

/** The interval that the spending limit interval of a card is reset */
export enum VaultSpendingLimitInterval {
  AllTime = 'all_time',
  Daily = 'daily',
  Monthly = 'monthly',
  PerAuthorization = 'per_authorization',
  Weekly = 'weekly',
  Yearly = 'yearly'
}

export enum VaultStripeEnv {
  Raise = 'RAISE',
  Sponsor = 'SPONSOR'
}

/** ip and date of Stripe ToS acceptance */
export type VaultTosAcceptance = {
  date: Scalars['DateTime'];
  ip: Scalars['String'];
};

export enum VaultWeeklyAnchor {
  Friday = 'friday',
  Monday = 'monday',
  Saturday = 'saturday',
  Sunday = 'sunday',
  Thursday = 'thursday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday'
}

export type Vehicle = {
  __typename?: 'Vehicle';
  id: Scalars['Int'];
  is_deleted?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  vehicle_type?: Maybe<Scalars['String']>;
};

export enum Vendor {
  Marco = 'MARCO',
  Raise = 'RAISE',
  RaiseMarco = 'RAISE_MARCO',
  Store = 'STORE'
}

export type WalletConfirmOrganizationInfoResult = {
  __typename?: 'WalletConfirmOrganizationInfoResult';
  organization?: Maybe<Organization>;
  user?: Maybe<UserWithPermissions>;
};

export type WalletUserRegisterResult = {
  __typename?: 'WalletUserRegisterResult';
  error?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

/** Input object for index query filters. */
export type Where = {
  and?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  eq?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  gt?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  gte?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  lt?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  lte?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  partition?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startsWith?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type WinbackDeals = {
  __typename?: 'WinbackDeals';
  activity?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  closedLost?: Maybe<Scalars['String']>;
  dealName?: Maybe<Scalars['String']>;
  dealStage?: Maybe<Scalars['String']>;
  hubspotId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isWinbackPinned?: Maybe<Scalars['Boolean']>;
  lastLaunch?: Maybe<Scalars['String']>;
  leaderFirstName?: Maybe<Scalars['String']>;
  leaderLastName?: Maybe<Scalars['String']>;
  previousDealId?: Maybe<Scalars['String']>;
  previousFundId?: Maybe<Scalars['String']>;
};

export type Worker = {
  __typename?: 'Worker';
  duty?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  event?: Maybe<Event>;
  event_id?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  organization?: Maybe<Scalars['String']>;
  paid?: Maybe<Scalars['String']>;
  pay_code?: Maybe<Scalars['String']>;
  pay_rate?: Maybe<Scalars['Float']>;
  salary?: Maybe<Scalars['Float']>;
  ssn?: Maybe<Scalars['String']>;
  woker_name?: Maybe<Scalars['String']>;
  worker_end_time?: Maybe<Scalars['String']>;
  worker_start_time?: Maybe<Scalars['String']>;
  worker_type?: Maybe<Scalars['String']>;
};

export type WorkerByIdInput = {
  id: Scalars['Int'];
};

export type WorkerDeleteManyCount = {
  __typename?: 'WorkerDeleteManyCount';
  count?: Maybe<Scalars['Int']>;
};

export type WorkerPool = {
  __typename?: 'WorkerPool';
  Address?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  First?: Maybe<Scalars['String']>;
  Home_Phone?: Maybe<Scalars['String']>;
  ID: Scalars['Int'];
  Last?: Maybe<Scalars['String']>;
  SSN?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
  Work_Phone?: Maybe<Scalars['String']>;
  Zip?: Maybe<Scalars['String']>;
  cell_phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  is_deleted?: Maybe<Scalars['String']>;
  pay_rate?: Maybe<Scalars['Float']>;
  worker_type?: Maybe<Scalars['String']>;
};

export enum WorkerTypes {
  C = 'c',
  H = 'h'
}

export type WorkerUpsertManyCount = {
  __typename?: 'WorkerUpsertManyCount';
  count?: Maybe<Scalars['Int']>;
};

export type ApprovedFunds = {
  __typename?: 'approvedFunds';
  approvedFundsCents?: Maybe<Scalars['Int']>;
  isPaidBack?: Maybe<Scalars['Boolean']>;
};

export type BusSchedules = {
  __typename?: 'busSchedules';
  activity?: Maybe<Scalars['String']>;
  bus_count?: Maybe<Scalars['Int']>;
  bus_departure_location?: Maybe<Scalars['String']>;
  bus_early_dismissal_time?: Maybe<Scalars['String']>;
  bus_estimated_return_time?: Maybe<Scalars['String']>;
  bus_time?: Maybe<Scalars['String']>;
  cancellation_status?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['String']>;
  event_date?: Maybe<Scalars['String']>;
  event_transport_details?: Maybe<Array<Maybe<DailyCalendarEventTransportDetails>>>;
  facility?: Maybe<Scalars['String']>;
  g_s?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  home_field?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  levels?: Maybe<Scalars['String']>;
  opponent?: Maybe<Scalars['String']>;
  place?: Maybe<Scalars['String']>;
  season_id?: Maybe<Scalars['Int']>;
  sports_code?: Maybe<Scalars['String']>;
  sports_description?: Maybe<Scalars['String']>;
  sports_group?: Maybe<Scalars['String']>;
  sports_name?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['String']>;
  vehicle_type?: Maybe<Scalars['String']>;
};

export type CompleteFundraiserApprovalSubmissionDocumentUploadResult = {
  __typename?: 'completeFundraiserApprovalSubmissionDocumentUploadResult';
  filename: Scalars['String'];
  s3FileName: Scalars['String'];
};

export type CreditMemoAmounts = {
  __typename?: 'creditMemoAmounts';
  creditApplied?: Maybe<Scalars['Int']>;
};

export type DriveGetListCurrentCustomersReturn = {
  __typename?: 'driveGetListCurrentCustomersReturn';
  offset?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<CurrentCustomersDeals>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type DriveGetListPotentialCustomersReturn = {
  __typename?: 'driveGetListPotentialCustomersReturn';
  offset?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<PotentialCustomersDeals>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** Last clicked tracking information used by Drive's Get List Application */
export type DriveOrgUserTrackingReturn = {
  __typename?: 'driveOrgUserTrackingReturn';
  /** ID of the organization. */
  orgId?: Maybe<Scalars['String']>;
  /** A unique tracking id */
  trackingId?: Maybe<Scalars['String']>;
  trackingType?: Maybe<Scalars['String']>;
  /** The value of the tracking type */
  trackingValue?: Maybe<Scalars['String']>;
  trackingValueType?: Maybe<Scalars['String']>;
  /** ID of the user. */
  userId?: Maybe<Scalars['String']>;
};

export type EarlyAccess = {
  __typename?: 'earlyAccess';
  activityType?: Maybe<Scalars['String']>;
  earlyAccessApprovedFundsMax?: Maybe<Scalars['Int']>;
  earlyAccessApprovedFundsMin?: Maybe<Scalars['Int']>;
  isNationalRpk?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Scalars['String']>;
  participantCount?: Maybe<Scalars['Int']>;
  projectedRaisedMax?: Maybe<Scalars['Int']>;
  projectedRaisedMin?: Maybe<Scalars['Int']>;
};

export type EventContractItemInput = {
  event_id: Scalars['Int'];
};

export type InsightsUserOrgsNotificationPreferencesInput = {
  __typename?: 'insightsUserOrgsNotificationPreferencesInput';
  userId?: Maybe<Scalars['String']>;
};

export type InsightsUserOrgsNotificationPreferencesResponse = {
  __typename?: 'insightsUserOrgsNotificationPreferencesResponse';
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  orgNotificationPreferences: Array<Maybe<OrgNotificationPreference>>;
  status: Scalars['String'];
  userNotificationPreferences: Array<Maybe<UserNotificationPreference>>;
};

export type OrgNotificationPreference = {
  __typename?: 'orgNotificationPreference';
  id?: Maybe<Scalars['Int']>;
  org_id?: Maybe<Scalars['String']>;
};

export type ParticipantCampaignConfiguration = {
  __typename?: 'participantCampaignConfiguration';
  autoImportEmails: ParticipantCampaignConfigStates;
  autoImportTexts: ParticipantCampaignConfigStates;
  campaignID: Scalars['String'];
  giftShop: ParticipantCampaignConfigStates;
  guardianSetup: ParticipantCampaignConfigStates;
  id: Scalars['ID'];
  profileSetup: ParticipantCampaignConfigStates;
  rewards: ParticipantCampaignConfigStates;
  updatedAt: Scalars['DateTime'];
  userID: Scalars['String'];
};

export enum Responses {
  Failed = 'FAILED',
  Ok = 'OK'
}

export type StoreBuildRequestInput = {
  campaignId?: InputMaybe<Scalars['Int']>;
  comment?: InputMaybe<Scalars['String']>;
  primaryColor: Scalars['String'];
  requester: StoreBuildRequester;
  secondaryColor: Scalars['String'];
  storeActivity: Scalars['String'];
  storeLogo: Scalars['String'];
  storeName: Scalars['String'];
};

export type StoreOrderSalesGraph = {
  __typename?: 'storeOrderSalesGraph';
  baseTotalSales?: Maybe<Scalars['Float']>;
  endDate?: Maybe<Scalars['String']>;
  scopeId?: Maybe<Scalars['BigInt']>;
  startDate?: Maybe<Scalars['String']>;
  totalDiscountAmount?: Maybe<Scalars['Float']>;
  totalOrders?: Maybe<Scalars['BigInt']>;
  totalSales?: Maybe<Scalars['Float']>;
};

export type StoreOrderSalesGraphUnionType = OnException | StoreOrderSalesGraphs;

export type TwispCardBalances = {
  __typename?: 'twispCardBalances';
  allTxs?: Maybe<Array<Maybe<TwispTransactions>>>;
  authorization_balance?: Maybe<Scalars['Int64']>;
  balance?: Maybe<Scalars['Int64']>;
  card_id?: Maybe<Scalars['String']>;
  card_limit?: Maybe<Scalars['Int64']>;
  history?: Maybe<Array<Maybe<TwispCardBalances>>>;
  seq?: Maybe<Scalars['Uint64']>;
  settled_balance?: Maybe<Scalars['Int64']>;
  visibleTxs?: Maybe<Array<Maybe<TwispTransactions>>>;
};


export type TwispCardBalancesHistoryArgs = {
  limit?: InputMaybe<Scalars['Int32']>;
  sort?: InputMaybe<Sort>;
};

/** Indexes for table twispCardBalances */
export enum TwispCardBalances_Indexes {
  CardId = 'card_id'
}

export type TwispCards = {
  __typename?: 'twispCards';
  card_id?: Maybe<Scalars['String']>;
  history?: Maybe<Array<Maybe<TwispCards>>>;
  last4?: Maybe<Scalars['String']>;
  seq?: Maybe<Scalars['Uint64']>;
};


export type TwispCardsHistoryArgs = {
  limit?: InputMaybe<Scalars['Int32']>;
  sort?: InputMaybe<Sort>;
};

/** Indexes for table twispCards */
export enum TwispCards_Indexes {
  CardId = 'card_id'
}

export type TwispStripeHooks = {
  __typename?: 'twispStripeHooks';
  account?: Maybe<Scalars['String']>;
  api_version?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['Int64']>;
  data?: Maybe<Scalars['JSON']>;
  history?: Maybe<Array<Maybe<TwispStripeHooks>>>;
  id?: Maybe<Scalars['String']>;
  livemode?: Maybe<Scalars['Boolean']>;
  object?: Maybe<Scalars['String']>;
  pending_webhooks?: Maybe<Scalars['Int64']>;
  processed?: Maybe<Scalars['Timestamp']>;
  request?: Maybe<Scalars['JSON']>;
  seq?: Maybe<Scalars['Uint64']>;
  type?: Maybe<Scalars['String']>;
};


export type TwispStripeHooksDataArgs = {
  expression?: InputMaybe<Scalars['String']>;
};


export type TwispStripeHooksHistoryArgs = {
  limit?: InputMaybe<Scalars['Int32']>;
  sort?: InputMaybe<Sort>;
};


export type TwispStripeHooksRequestArgs = {
  expression?: InputMaybe<Scalars['String']>;
};

/** Indexes for table twispStripeHooks */
export enum TwispStripeHooks_Indexes {
  Id = 'id'
}

export type TwispTransactions = {
  __typename?: 'twispTransactions';
  amount?: Maybe<Scalars['Int64']>;
  card_id?: Maybe<Scalars['String']>;
  correlation?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['Int64']>;
  description?: Maybe<Scalars['String']>;
  event_created?: Maybe<Scalars['Int64']>;
  history?: Maybe<Array<Maybe<TwispTransactions>>>;
  id?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  seq?: Maybe<Scalars['Uint64']>;
  status?: Maybe<Scalars['String']>;
  visible?: Maybe<Scalars['Boolean']>;
};


export type TwispTransactionsHistoryArgs = {
  limit?: InputMaybe<Scalars['Int32']>;
  sort?: InputMaybe<Sort>;
};


export type TwispTransactionsMetaArgs = {
  expression?: InputMaybe<Scalars['String']>;
};

/** Indexes for table twispTransactions */
export enum TwispTransactions_Indexes {
  CardId = 'card_id',
  CardIdViz = 'card_id_viz',
  Id = 'id'
}

export type UserNotificationPreference = {
  __typename?: 'userNotificationPreference';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type ApiStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type ApiStatusQuery = { __typename?: 'Query', apiReady?: boolean | null };

export type InsightsAddPreApprovedContactsMutationVariables = Exact<{
  org: InsAddPreApprovedContactOrgInput;
  contacts: Array<InputMaybe<InsPreApprovedContactInput>> | InputMaybe<InsPreApprovedContactInput>;
  approver: InsAddPreApprovedContactApproverInput;
  dashboardUrl: Scalars['String'];
}>;


export type InsightsAddPreApprovedContactsMutation = { __typename?: 'Mutation', insightsAddPreApprovedContacts: { __typename?: 'InsAddPreApprovedContactsResult', errors?: Array<string | null> | null, status: string, contactsResult?: Array<{ __typename?: 'InsPreApprovedContactResult', email: string, status: string } | null> | null } };

export type InsightsEditPreApprovedContactMutationVariables = Exact<{
  contactId: Scalars['Int'];
  orgId: Scalars['String'];
  senderName: Scalars['String'];
  updatedContact: InsEditPreApprovedContactInput;
}>;


export type InsightsEditPreApprovedContactMutation = { __typename?: 'Mutation', insightsEditPreApprovedContact: { __typename?: 'InsEditPreApprovedContactResult', error?: string | null, status: string } };

export type InsightsResendInvitePreApprovedContactMutationVariables = Exact<{
  contactId: Scalars['Int'];
  senderName: Scalars['String'];
}>;


export type InsightsResendInvitePreApprovedContactMutation = { __typename?: 'Mutation', insightsResendInvitePreApprovedContact: { __typename?: 'InsResendPreApprovedContactResult', errors?: Array<string | null> | null, status: string } };

export type InsightsDeletePreApprovedContactMutationVariables = Exact<{
  contactId: Scalars['Int'];
  userId: Scalars['String'];
}>;


export type InsightsDeletePreApprovedContactMutation = { __typename?: 'Mutation', insightsDeletePreApprovedContact: { __typename?: 'InsDeletePreApprovedContactResult', errors?: Array<string | null> | null, status: string } };

export type InsightsEditPreApprovedContactInviteMutationVariables = Exact<{
  token?: InputMaybe<Scalars['String']>;
}>;


export type InsightsEditPreApprovedContactInviteMutation = { __typename?: 'Mutation', insightsEditPreApprovedContactInvite: { __typename?: 'InsEditPreApprovedInviteResult', errors?: Array<string | null> | null, status: string, updatedInvite?: { __typename?: 'InsEditPreApprovedInvite', activity: string, email: string, firstName: string, inviteStatus: string, lastName: string, orgName: string, createdBy: string, phoneNumber?: string | null } | null } };

export type InsightsSetNotificationPreferencesMutationVariables = Exact<{
  preferences: Array<InputMaybe<InsSetNotificationPreferences>> | InputMaybe<InsSetNotificationPreferences>;
  userId: Scalars['String'];
}>;


export type InsightsSetNotificationPreferencesMutation = { __typename?: 'Mutation', insightsSetNotificationPreferences: { __typename?: 'InsSetNotificationPreferencesResult', errors?: Array<string | null> | null, messages?: Array<string | null> | null, status: string } };

export type CreateFundraiserApprovalSubmissionMutationVariables = Exact<{
  submitterId: Scalars['NonEmptyString'];
}>;


export type CreateFundraiserApprovalSubmissionMutation = { __typename?: 'Mutation', createFundraiserApprovalSubmission: { __typename?: 'FundraiserApprovalSubmissionResult', errors: Array<string | null>, status: string, fundraiserApprovalSubmission?: { __typename?: 'FundraiserApprovalSubmission', additionalNotes?: string | null, campaignRaiseId?: number | null, createdAt: any, customerSupportInfo?: string | null, duration?: string | null, estimatedTotalRaisedCents?: number | null, firstName?: string | null, formId: number, formType?: FundraiserApprovalSubmissionFormType | null, fundraiserServiceWebsite?: string | null, groupSize?: number | null, howThisWorks?: string | null, id: number, lastName?: string | null, latestRevision: boolean, newFundraiser: boolean, organizationId?: string | null, previousRevision?: number | null, pricingAndFees?: string | null, programId?: string | null, proposedStartDate?: any | null, reason?: string | null, revisionRequest?: string | null, safety?: string | null, status: FundraiserApprovalSubmissionStatus, submitterId?: string | null, updatedAt: any, fundraiserApprovalSubmissionDocs: Array<{ __typename?: 'FundraiserApprovalSubmissionDocument', createdAt?: any | null, filename?: any | null, filetype?: any | null, id?: number | null, location?: any | null, updatedAt?: any | null, s3Filename?: any | null, status?: any | null }>, fundraiserApprovers: Array<{ __typename?: 'FundraiserApprover', approverType?: FundraiserApproverType | null, createdAt?: any | null, email?: string | null, firstName?: string | null, id?: number | null, lastName?: string | null, phone?: string | null, roleId?: string | null, updatedAt?: any | null, userId?: string | null }> } | null } };

export type SetUserInsightsConfigurationsMutationVariables = Exact<{
  config: Array<InputMaybe<UserInsightsConfigInput>> | InputMaybe<UserInsightsConfigInput>;
}>;


export type SetUserInsightsConfigurationsMutation = { __typename?: 'Mutation', setUserInsightsConfigurations: { __typename?: 'UserInsightsConfigResult', messages: Array<string | null>, status: string } };

export type EditFundraiserApprovalSubmissionMutationVariables = Exact<{
  fundraiserApprovalSubmissionDocs: Array<InputMaybe<FundraiserApprovalSubmissionDocumentInput>> | InputMaybe<FundraiserApprovalSubmissionDocumentInput>;
  fundraiserApprovers: Array<InputMaybe<FundraiserApproverInput>> | InputMaybe<FundraiserApproverInput>;
  id: Scalars['Int'];
  additionalNotes?: InputMaybe<Scalars['String']>;
  campaignRaiseId?: InputMaybe<Scalars['Int']>;
  customerSupportInfo?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['String']>;
  estimatedTotalRaisedCents?: InputMaybe<Scalars['Int']>;
  firstName?: InputMaybe<Scalars['String']>;
  formType?: InputMaybe<FundraiserApprovalSubmissionFormType>;
  fundraiserServiceWebsite?: InputMaybe<Scalars['String']>;
  groupSize?: InputMaybe<Scalars['Int']>;
  howThisWorks?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  newFundraiser?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['String']>;
  pricingAndFees?: InputMaybe<Scalars['String']>;
  programId?: InputMaybe<Scalars['String']>;
  proposedStartDate?: InputMaybe<Scalars['DateTime']>;
  reason?: InputMaybe<Scalars['String']>;
  revisionRequest?: InputMaybe<Scalars['String']>;
  safety?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<FundraiserApprovalSubmissionStatus>;
}>;


export type EditFundraiserApprovalSubmissionMutation = { __typename?: 'Mutation', editFundraiserApprovalSubmission: { __typename?: 'FundraiserApprovalSubmissionResult', errors: Array<string | null>, fundraiserApprovalSubmission?: { __typename?: 'FundraiserApprovalSubmission', additionalNotes?: string | null, campaignRaiseId?: number | null, createdAt: any, customerSupportInfo?: string | null, duration?: string | null, estimatedTotalRaisedCents?: number | null, firstName?: string | null, formId: number, formType?: FundraiserApprovalSubmissionFormType | null, fundraiserServiceWebsite?: string | null, groupSize?: number | null, howThisWorks?: string | null, id: number, lastName?: string | null, latestRevision: boolean, newFundraiser: boolean, organizationId?: string | null, previousRevision?: number | null, pricingAndFees?: string | null, programId?: string | null, proposedStartDate?: any | null, reason?: string | null, revisionRequest?: string | null, safety?: string | null, status: FundraiserApprovalSubmissionStatus, submitterId?: string | null, updatedAt: any, fundraiserApprovalSubmissionDocs: Array<{ __typename?: 'FundraiserApprovalSubmissionDocument', createdAt?: any | null, filename?: any | null, filetype?: any | null, id?: number | null, location?: any | null, updatedAt?: any | null, s3Filename?: any | null, status?: any | null }>, fundraiserApprovers: Array<{ __typename?: 'FundraiserApprover', approverType?: FundraiserApproverType | null, email?: string | null, firstName?: string | null, id?: number | null, lastName?: string | null, phone?: string | null, roleId?: string | null, userId?: string | null }> } | null } };

export type S3PresignedInsightsApprovalsUrlMutationVariables = Exact<{
  files: Array<InputMaybe<Scalars['NonEmptyString']>> | InputMaybe<Scalars['NonEmptyString']>;
  approvalsFormId: Scalars['Int'];
  approvalsSubmissionId: Scalars['Int'];
}>;


export type S3PresignedInsightsApprovalsUrlMutation = { __typename?: 'Mutation', s3PresignedInsightsApprovalsUrl: Array<{ __typename?: 'S3PresignedInsightsApprovalsUrlResult', filename: string, s3Filename: string, url: string, status: string, expires: any }> };

export type CompleteFundraiserApprovalSubmissionDocumentUploadMutationVariables = Exact<{
  approvalSubmissionId: Scalars['Int'];
  s3FileName: Scalars['String'];
}>;


export type CompleteFundraiserApprovalSubmissionDocumentUploadMutation = { __typename?: 'Mutation', completeFundraiserApprovalSubmissionDocumentUpload: { __typename?: 'completeFundraiserApprovalSubmissionDocumentUploadResult', filename: string, s3FileName: string } };

export type DeleteFundraiserApprovalSubmissionDocumentMutationVariables = Exact<{
  documentKey: Scalars['String'];
  approvalSubmissionId: Scalars['Int'];
}>;


export type DeleteFundraiserApprovalSubmissionDocumentMutation = { __typename?: 'Mutation', deleteFundraiserApprovalSubmissionDocument: string };

export type SubmitFundraiserApprovalSubmissionMutationVariables = Exact<{
  submitFundraiserApprovalSubmissionId: Scalars['Int'];
}>;


export type SubmitFundraiserApprovalSubmissionMutation = { __typename?: 'Mutation', submitFundraiserApprovalSubmission: { __typename?: 'FundraiserApprovalSubmission', id: number, formId: number, formType?: FundraiserApprovalSubmissionFormType | null, previousRevision?: number | null, latestRevision: boolean, status: FundraiserApprovalSubmissionStatus, newFundraiser: boolean, campaignRaiseId?: number | null, organizationId?: string | null, programId?: string | null, submitterId?: string | null, firstName?: string | null, lastName?: string | null, groupSize?: number | null, estimatedTotalRaisedCents?: number | null, pricingAndFees?: string | null, howThisWorks?: string | null, reason?: string | null, proposedStartDate?: any | null, duration?: string | null, fundraiserServiceWebsite?: string | null, safety?: string | null, customerSupportInfo?: string | null, additionalNotes?: string | null, revisionRequest?: string | null, createdAt: any, updatedAt: any, fundraiserApprovalSubmissionDocs: Array<{ __typename?: 'FundraiserApprovalSubmissionDocument', id?: number | null, filename?: any | null, filetype?: any | null, location?: any | null, createdAt?: any | null, updatedAt?: any | null, s3Filename?: any | null, status?: any | null }>, fundraiserApprovers: Array<{ __typename?: 'FundraiserApprover', id?: number | null, userId?: string | null, approverType?: FundraiserApproverType | null, firstName?: string | null, lastName?: string | null, email?: string | null, phone?: string | null, roleId?: string | null, createdAt?: any | null, updatedAt?: any | null }> } };

export type ApproveFundraiserApprovalSubmissionMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type ApproveFundraiserApprovalSubmissionMutation = { __typename?: 'Mutation', approveFundraiserApprovalSubmission: { __typename: 'ApproveFundraiserApprovalSubmissionData', isSsoApprover: boolean, submitterName: string } | { __typename: 'InvalidToken', message: string } };

export type AddRevisionFundraiserApprovalSubmissionMutationVariables = Exact<{
  revision: Scalars['NonEmptyString'];
  token: Scalars['String'];
}>;


export type AddRevisionFundraiserApprovalSubmissionMutation = { __typename?: 'Mutation', addRevisionFundraiserApprovalSubmission: { __typename: 'InvalidToken', message: string } | { __typename: 'RevisionFundraiserApprovalSubmissionData', isSsoApprover: boolean, submitterName: string } | { __typename: 'UnprocessableSubmission', message: string } };

export type InsightsCampaignsDataQueryVariables = Exact<{
  campaignIds: Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>;
}>;


export type InsightsCampaignsDataQuery = { __typename?: 'Query', insightsCampaignsData: { __typename?: 'InsCampaignsData', lTRChart?: { __typename?: 'InsLTRChartData', ltr?: number | null, upcomingCampaignsCount?: number | null, closedCampaignsCount?: number | null, activeCampaignsCount?: number | null, activeCampaignsParticipation?: number | null, activeCampaignsTotalCents?: number | null, avgDonationCents?: number | null, closedCampaignsParticipation?: number | null, closedCampaignsTotalCents?: number | null, upcomingCampaignsForecastedAmountCents?: number | null, upcomingCampaignsParticipantSignIn?: number | null } | null, campaignStats?: Array<{ __typename?: 'InsCampaignStat', id: number, name?: string | null, end_date?: any | null, start_date?: any | null, donations_count?: number | null, insights_status?: string | null, total_raised_cents?: number | null, forecasted_amount_cents?: number | null, participation?: number | null, participants?: number | null, preloading?: number | null, group_leader_name?: string | null, group_leader_email?: string | null, slug: string } | null> | null, donationMapStats?: Array<{ __typename?: 'InsDonationMapStat', campaign_id?: number | null, campaign_ids?: Array<number | null> | null, donations_amount_cents?: number | null, donations_count?: number | null, lat?: number | null, long?: number | null, postal_code?: string | null } | null> | null, analyticsSummaryStats?: Array<{ __typename?: 'InsAnalyticsSummaryStat', year: number, month: number, campaign_id: number, amount_raised_cents: number } | null> | null, monthlyCampaignStats?: Array<{ __typename?: 'InsMonthlyCampaignStat', campaign_ids: Array<number | null>, month: number, year: number, preloading?: number | null, participation?: number | null } | null> | null, inviteStats?: Array<{ __typename?: 'InsInviteStat', campaign_id?: number | null, campaign_ids?: Array<number | null> | null, invite_type: string, invite_count: number, total_amount_cents: number } | null> | null } };

export type InsightsCampaignsDataForReportingQueryVariables = Exact<{
  campaignIds: Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>;
}>;


export type InsightsCampaignsDataForReportingQuery = { __typename?: 'Query', insightsCampaignsData: { __typename?: 'InsCampaignsData', campaignStats?: Array<{ __typename?: 'InsCampaignStat', id: number, name?: string | null, end_date?: any | null, start_date?: any | null, insights_status?: string | null, total_raised_cents?: number | null, slug: string } | null> | null } };

export type InsightsCampaignSettlementsQueryVariables = Exact<{
  campaignIds: Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>;
}>;


export type InsightsCampaignSettlementsQuery = { __typename?: 'Query', insightsCampaignSettlements: Array<{ __typename?: 'InsCampaignSettlement', id: number, campaign_id: number, campaign_name?: string | null, method?: string | null, status?: string | null, amount_cents?: number | null, last_updated?: any | null, slug: string } | null> };

export type InsightsCampaignDonationsQueryVariables = Exact<{
  campaignIds: Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>;
}>;


export type InsightsCampaignDonationsQuery = { __typename?: 'Query', insightsCampaignDonations: Array<{ __typename?: 'InsCampaignDonation', id: number, campaign_id: number, campaign_name?: string | null, donor_name?: string | null, participant_name?: string | null, amount_cents?: number | null, created_at?: any | null, slug: string } | null> };

export type InsightsDonorParticipantContactsQueryVariables = Exact<{
  campaignIds: Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>;
}>;


export type InsightsDonorParticipantContactsQuery = { __typename?: 'Query', insightsDonorParticipantContacts: Array<{ __typename?: 'InsDonorParticipantContact', campaign_id: number, donor_id?: number | null, donor_name?: string | null, donor_email?: string | null, participant_id?: number | null, participant_name?: string | null, participant_email?: string | null } | null> };

export type UserAssociationsQueryVariables = Exact<{
  userAssociationsId: Scalars['ID'];
  product: Product;
}>;


export type UserAssociationsQuery = { __typename?: 'Query', userAssociations: Array<{ __typename?: 'Org', id: string, fields?: any | null, name?: string | null, type: string, parentId?: string | null } | null> };

export type OrgQueryVariables = Exact<{
  orgId: Scalars['ID'];
  hierarchy?: InputMaybe<Scalars['Boolean']>;
}>;


export type OrgQuery = { __typename?: 'Query', org?: Array<{ __typename?: 'Org', id: string, fields?: any | null, name?: string | null, type: string, parentId?: string | null } | null> | null, financialAcctOrg: Array<{ __typename?: 'FinancialAcctOrg', acctId?: string | null, applicationId?: string | null, createdAt?: any | null, customerId?: string | null, finAcctId?: string | null, kybId?: string | null, orgEin?: string | null, orgId?: string | null, orgName?: string | null, orgType?: OrgType | null, paymentMethodId?: string | null, processor?: Processor | null, status?: FinAcctStatus | null, stripeEnv?: FinAcctStripeEnv | null, updatedAt?: any | null }> };

export type InsightsOrgsCampaignsSummaryQueryVariables = Exact<{
  orgCampaignIds: Array<InputMaybe<InsOrgCampaignSummaryInput>> | InputMaybe<InsOrgCampaignSummaryInput>;
}>;


export type InsightsOrgsCampaignsSummaryQuery = { __typename?: 'Query', insightsOrgsCampaignsSummary: Array<{ __typename?: 'InsOrgCampaignSummary', orgId?: string | null, totalRaisedCents: number, campaignsCount: number } | null> };

export type InsightsCampaignRaiseEntityInfoQueryVariables = Exact<{
  campaignId: Scalars['Int'];
}>;


export type InsightsCampaignRaiseEntityInfoQuery = { __typename?: 'Query', insightsCampaignRaiseEntityInfo: { __typename?: 'InsCampaignRaiseEntityInfo', campaign_id: number, entity_id?: number | null } };

export type UserQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']>;
}>;


export type UserQuery = { __typename?: 'Query', user?: { __typename?: 'User', firstName?: string | null, id?: string | null, lastName?: string | null, email?: string | null, phoneNumber?: string | null } | null };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'UserWithPermissions', apps?: Array<string | null> | null, email?: string | null, firstName?: string | null, lastName?: string | null, id?: string | null, phoneNumber?: string | null } | null };

export type InsightsPreApprovedContactsQueryVariables = Exact<{
  orgIds: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
}>;


export type InsightsPreApprovedContactsQuery = { __typename?: 'Query', insightsPreApprovedContacts: Array<{ __typename?: 'InsPreApprovedContact', id?: number | null, activity?: string | null, email?: string | null, first_name?: string | null, invite_sent_at?: any | null, invite_status?: string | null, last_name?: string | null, associated_org_id?: string | null, invite_phone_number?: string | null } | null> };

export type InsightsPreApprovedContactsEmailTestQueryVariables = Exact<{
  emails: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
  orgId: Scalars['String'];
}>;


export type InsightsPreApprovedContactsEmailTestQuery = { __typename?: 'Query', insightsPreApprovedContactsEmailTest: Array<{ __typename?: 'InsEmailTestPreApprovedContactResult', email: string, status: string } | null> };

export type InsightsUserPreferencesQueryVariables = Exact<{
  userId: Scalars['String'];
  queryType: UserPreferenceQueryType;
}>;


export type InsightsUserPreferencesQuery = { __typename?: 'Query', insightsUserPreferences?: Array<{ __typename?: 'InsightsGetUserPreference', id: number, org_id?: string | null, user_id?: string | null, campaign_raise_id?: number | null } | null> | null };

export type InsightsNotificationPreferencesQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type InsightsNotificationPreferencesQuery = { __typename?: 'Query', insightsNotificationPreferences: { __typename?: 'insightsUserOrgsNotificationPreferencesResponse', errors?: Array<string | null> | null, status: string, orgNotificationPreferences: Array<{ __typename: 'orgNotificationPreference', id?: number | null, org_id?: string | null } | null>, userNotificationPreferences: Array<{ __typename: 'userNotificationPreference', id?: number | null, name?: string | null } | null> } };

export type GroupLeaderCampaignMembershipsQueryVariables = Exact<{
  basicStatus?: InputMaybe<BasicCampaignStatus>;
}>;


export type GroupLeaderCampaignMembershipsQuery = { __typename?: 'Query', campaignMemberships: Array<{ __typename?: 'AdminCampaign' } | { __typename?: 'GroupLeaderCampaign', basicStatus: BasicCampaignStatus, id: string, name: string, status: CampaignStatus, goalInDollars?: number | null, teamSize?: number | null, donationLink: string } | { __typename?: 'ParticipantCampaign' } | { __typename?: 'SupporterCampaign' }> };

export type InsightsApprovalSubmissionsQueryVariables = Exact<{
  submitterId: Scalars['String'];
  approverId?: InputMaybe<Scalars['String']>;
  campaignIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
  formId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<FundraiserApprovalSubmissionStatus>;
  latest?: InputMaybe<Scalars['Boolean']>;
}>;


export type InsightsApprovalSubmissionsQuery = { __typename?: 'Query', fundraiserApprovalSubmissions: Array<{ __typename?: 'FundraiserApprovalSubmission', additionalNotes?: string | null, campaignRaiseId?: number | null, createdAt: any, customerSupportInfo?: string | null, duration?: string | null, estimatedTotalRaisedCents?: number | null, firstName?: string | null, formId: number, formType?: FundraiserApprovalSubmissionFormType | null, fundraiserServiceWebsite?: string | null, groupSize?: number | null, howThisWorks?: string | null, id: number, lastName?: string | null, latestRevision: boolean, newFundraiser: boolean, organizationId?: string | null, previousRevision?: number | null, pricingAndFees?: string | null, programId?: string | null, proposedStartDate?: any | null, reason?: string | null, revisionRequest?: string | null, safety?: string | null, status: FundraiserApprovalSubmissionStatus, submitterId?: string | null, updatedAt: any, fundraiserApprovalSubmissionDocs: Array<{ __typename?: 'FundraiserApprovalSubmissionDocument', createdAt?: any | null, filename?: any | null, filetype?: any | null, id?: number | null, location?: any | null, updatedAt?: any | null, s3Filename?: any | null, status?: any | null }>, fundraiserApprovers: Array<{ __typename?: 'FundraiserApprover', approverType?: FundraiserApproverType | null, createdAt?: any | null, email?: string | null, firstName?: string | null, id?: number | null, lastName?: string | null, phone?: string | null, roleId?: string | null, updatedAt?: any | null, userId?: string | null }> } | null> };

export type OrgsAndCampaignNameByCampaignIdQueryVariables = Exact<{
  campaignId: Scalars['ID'];
}>;


export type OrgsAndCampaignNameByCampaignIdQuery = { __typename?: 'Query', orgCampaignId?: Array<{ __typename?: 'Org', createdAt?: any | null, fields?: any | null, id: string, name?: string | null, lastMigrated?: any | null, type: string, financialAccounts?: Array<{ __typename?: 'FinancialAccount', acctId?: string | null, applicationId?: string | null, createdAt: any, customerId?: string | null, id: string, kybId: string, paymentMethodId?: string | null, processor: Processor, status: FinAcctStatus, stripeEnv: FinAcctStripeEnv, updatedAt?: any | null }> | null } | null> | null, org?: Array<{ __typename?: 'Org', name?: string | null } | null> | null };

export type CampaignOrgIdQueryVariables = Exact<{
  raiseId: Scalars['Int'];
}>;


export type CampaignOrgIdQuery = { __typename?: 'Query', orgId?: string | null };

export type UserInsightsConfigurationsQueryVariables = Exact<{ [key: string]: never; }>;


export type UserInsightsConfigurationsQuery = { __typename?: 'Query', userInsightsConfigurations: Array<{ __typename?: 'UserInsightsConfig', createdAt: any, id: number, name: any, updatedAt: any, userId: any, value: any } | null> };

export type FundraiserOrgAndGroupNamesQueryVariables = Exact<{
  orgId: Scalars['ID'];
  programId: Scalars['ID'];
}>;


export type FundraiserOrgAndGroupNamesQuery = { __typename?: 'Query', orgName?: Array<{ __typename?: 'Org', name?: string | null } | null> | null, programName?: Array<{ __typename?: 'Org', name?: string | null } | null> | null };

export type VaultAccountInfoQueryVariables = Exact<{
  provider: VaultFinancialProvider;
  accountId?: InputMaybe<Scalars['ID']>;
  finAcctId: Scalars['ID'];
}>;


export type VaultAccountInfoQuery = { __typename?: 'Query', vaultAccountStatus: { __typename?: 'VaultAccountStatus', valid: boolean, accountRepresentativeRequirements?: { __typename?: 'VaultEntityRequirements', missingFields: Array<string>, requiredDocuments: Array<VaultRequiredDocument> } | null, beneficialOwnersRequirements?: Array<{ __typename?: 'VaultEntityRequirements', missingFields: Array<string>, requiredDocuments: Array<VaultRequiredDocument> }> | null, kybRequirements?: { __typename?: 'VaultEntityRequirements', missingFields: Array<string>, requiredDocuments: Array<VaultRequiredDocument> } | null }, financialAcctUsers: Array<{ __typename?: 'FinancialAcctUser', acctId?: string | null, orgId?: string | null, status?: FinAcctStatus | null, userId?: string | null }> };

export type OrgSearchQueryVariables = Exact<{
  name: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  label?: InputMaybe<OrgType>;
}>;


export type OrgSearchQuery = { __typename?: 'Query', orgSearch: Array<{ __typename?: 'Org', id: string, name?: string | null, fields?: any | null }> };

export type GroupLeaderCampaignIdsQueryVariables = Exact<{ [key: string]: never; }>;


export type GroupLeaderCampaignIdsQuery = { __typename?: 'Query', campaignMemberships: Array<{ __typename?: 'AdminCampaign' } | { __typename?: 'GroupLeaderCampaign', id: string } | { __typename?: 'ParticipantCampaign' } | { __typename?: 'SupporterCampaign' }> };

export type InsightsUserAssociationIdsQueryVariables = Exact<{
  orgId: Scalars['ID'];
}>;


export type InsightsUserAssociationIdsQuery = { __typename?: 'Query', orgUserAssociations: Array<{ __typename?: 'UserNode', id: string } | null> };

export type UserPublicInfoQueryVariables = Exact<{
  userPublicInfoId?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
}>;


export type UserPublicInfoQuery = { __typename?: 'Query', userPublicInfo?: Array<{ __typename?: 'UserPublicInfoResult', user?: { __typename?: 'UserPublic', firstName?: string | null, id?: string | null, lastName?: string | null } | null } | null> | null };

export type OrgHierarchyQueryVariables = Exact<{
  orgId: Scalars['ID'];
}>;


export type OrgHierarchyQuery = { __typename?: 'Query', org?: Array<{ __typename?: 'Org', id: string, fields?: any | null, type: string } | null> | null };

export type InsightsApprovalSubmissionHistoryQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type InsightsApprovalSubmissionHistoryQuery = { __typename?: 'Query', fundraiserApprovalSubmissionsHistory: Array<{ __typename?: 'FundraiserApprovalSubmission', additionalNotes?: string | null, campaignRaiseId?: number | null, createdAt: any, customerSupportInfo?: string | null, duration?: string | null, estimatedTotalRaisedCents?: number | null, firstName?: string | null, formId: number, formType?: FundraiserApprovalSubmissionFormType | null, fundraiserServiceWebsite?: string | null, groupSize?: number | null, howThisWorks?: string | null, id: number, lastName?: string | null, latestRevision: boolean, newFundraiser: boolean, organizationId?: string | null, previousRevision?: number | null, pricingAndFees?: string | null, programId?: string | null, proposedStartDate?: any | null, reason?: string | null, revisionRequest?: string | null, safety?: string | null, status: FundraiserApprovalSubmissionStatus, submitterId?: string | null, updatedAt: any, fundraiserApprovalSubmissionDocs: Array<{ __typename?: 'FundraiserApprovalSubmissionDocument', createdAt?: any | null, filename?: any | null, filetype?: any | null, id?: number | null, location?: any | null, s3Filename?: any | null, status?: any | null, updatedAt?: any | null }>, fundraiserApprovers: Array<{ __typename?: 'FundraiserApprover', approverType?: FundraiserApproverType | null, createdAt?: any | null, email?: string | null, firstName?: string | null, id?: number | null, lastName?: string | null, phone?: string | null, roleId?: string | null, updatedAt?: any | null, userId?: string | null }> }>, fundraiserApprovalSubmissionsOrgNames: { __typename: 'FundraiserApprovalSubmissionsOrgNames', fundraiserName: string, organizationName: string, programName: string } | { __typename: 'InvalidToken', message: string } };

export type FundraiserApprovalSubmissionsOrgNamesQueryVariables = Exact<{
  submissionId?: InputMaybe<Scalars['Int']>;
}>;


export type FundraiserApprovalSubmissionsOrgNamesQuery = { __typename?: 'Query', fundraiserApprovalSubmissionsOrgNames: { __typename: 'FundraiserApprovalSubmissionsOrgNames', fundraiserName: string, organizationName: string, programName: string } | { __typename: 'InvalidToken', message: string } };

export type PermissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type PermissionsQuery = { __typename?: 'Query', me?: { __typename?: 'UserWithPermissions', email?: string | null, permissions?: Array<string | null> | null, id?: string | null } | null };

export type SuperBasicUserInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type SuperBasicUserInfoQuery = { __typename?: 'Query', me?: { __typename?: 'UserWithPermissions', firstName?: string | null, lastName?: string | null, id?: string | null } | null };


export const ApiStatusDocument = gql`
    query ApiStatus {
  apiReady @client
}
    `;

/**
 * __useApiStatusQuery__
 *
 * To run a query within a React component, call `useApiStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useApiStatusQuery(baseOptions?: Apollo.QueryHookOptions<ApiStatusQuery, ApiStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiStatusQuery, ApiStatusQueryVariables>(ApiStatusDocument, options);
      }
export function useApiStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiStatusQuery, ApiStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiStatusQuery, ApiStatusQueryVariables>(ApiStatusDocument, options);
        }
export type ApiStatusQueryHookResult = ReturnType<typeof useApiStatusQuery>;
export type ApiStatusLazyQueryHookResult = ReturnType<typeof useApiStatusLazyQuery>;
export type ApiStatusQueryResult = Apollo.QueryResult<ApiStatusQuery, ApiStatusQueryVariables>;
export const InsightsAddPreApprovedContactsDocument = gql`
    mutation InsightsAddPreApprovedContacts($org: InsAddPreApprovedContactOrgInput!, $contacts: [InsPreApprovedContactInput]!, $approver: InsAddPreApprovedContactApproverInput!, $dashboardUrl: String!) {
  insightsAddPreApprovedContacts(
    org: $org
    contacts: $contacts
    approver: $approver
    dashboardUrl: $dashboardUrl
  ) {
    errors
    contactsResult {
      email
      status
    }
    status
  }
}
    `;
export type InsightsAddPreApprovedContactsMutationFn = Apollo.MutationFunction<InsightsAddPreApprovedContactsMutation, InsightsAddPreApprovedContactsMutationVariables>;

/**
 * __useInsightsAddPreApprovedContactsMutation__
 *
 * To run a mutation, you first call `useInsightsAddPreApprovedContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsightsAddPreApprovedContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insightsAddPreApprovedContactsMutation, { data, loading, error }] = useInsightsAddPreApprovedContactsMutation({
 *   variables: {
 *      org: // value for 'org'
 *      contacts: // value for 'contacts'
 *      approver: // value for 'approver'
 *      dashboardUrl: // value for 'dashboardUrl'
 *   },
 * });
 */
export function useInsightsAddPreApprovedContactsMutation(baseOptions?: Apollo.MutationHookOptions<InsightsAddPreApprovedContactsMutation, InsightsAddPreApprovedContactsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsightsAddPreApprovedContactsMutation, InsightsAddPreApprovedContactsMutationVariables>(InsightsAddPreApprovedContactsDocument, options);
      }
export type InsightsAddPreApprovedContactsMutationHookResult = ReturnType<typeof useInsightsAddPreApprovedContactsMutation>;
export type InsightsAddPreApprovedContactsMutationResult = Apollo.MutationResult<InsightsAddPreApprovedContactsMutation>;
export type InsightsAddPreApprovedContactsMutationOptions = Apollo.BaseMutationOptions<InsightsAddPreApprovedContactsMutation, InsightsAddPreApprovedContactsMutationVariables>;
export const InsightsEditPreApprovedContactDocument = gql`
    mutation InsightsEditPreApprovedContact($contactId: Int!, $orgId: String!, $senderName: String!, $updatedContact: InsEditPreApprovedContactInput!) {
  insightsEditPreApprovedContact(
    contactId: $contactId
    orgId: $orgId
    senderName: $senderName
    updatedContact: $updatedContact
  ) {
    error
    status
  }
}
    `;
export type InsightsEditPreApprovedContactMutationFn = Apollo.MutationFunction<InsightsEditPreApprovedContactMutation, InsightsEditPreApprovedContactMutationVariables>;

/**
 * __useInsightsEditPreApprovedContactMutation__
 *
 * To run a mutation, you first call `useInsightsEditPreApprovedContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsightsEditPreApprovedContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insightsEditPreApprovedContactMutation, { data, loading, error }] = useInsightsEditPreApprovedContactMutation({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      orgId: // value for 'orgId'
 *      senderName: // value for 'senderName'
 *      updatedContact: // value for 'updatedContact'
 *   },
 * });
 */
export function useInsightsEditPreApprovedContactMutation(baseOptions?: Apollo.MutationHookOptions<InsightsEditPreApprovedContactMutation, InsightsEditPreApprovedContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsightsEditPreApprovedContactMutation, InsightsEditPreApprovedContactMutationVariables>(InsightsEditPreApprovedContactDocument, options);
      }
export type InsightsEditPreApprovedContactMutationHookResult = ReturnType<typeof useInsightsEditPreApprovedContactMutation>;
export type InsightsEditPreApprovedContactMutationResult = Apollo.MutationResult<InsightsEditPreApprovedContactMutation>;
export type InsightsEditPreApprovedContactMutationOptions = Apollo.BaseMutationOptions<InsightsEditPreApprovedContactMutation, InsightsEditPreApprovedContactMutationVariables>;
export const InsightsResendInvitePreApprovedContactDocument = gql`
    mutation InsightsResendInvitePreApprovedContact($contactId: Int!, $senderName: String!) {
  insightsResendInvitePreApprovedContact(
    contactId: $contactId
    senderName: $senderName
  ) {
    errors
    status
  }
}
    `;
export type InsightsResendInvitePreApprovedContactMutationFn = Apollo.MutationFunction<InsightsResendInvitePreApprovedContactMutation, InsightsResendInvitePreApprovedContactMutationVariables>;

/**
 * __useInsightsResendInvitePreApprovedContactMutation__
 *
 * To run a mutation, you first call `useInsightsResendInvitePreApprovedContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsightsResendInvitePreApprovedContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insightsResendInvitePreApprovedContactMutation, { data, loading, error }] = useInsightsResendInvitePreApprovedContactMutation({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      senderName: // value for 'senderName'
 *   },
 * });
 */
export function useInsightsResendInvitePreApprovedContactMutation(baseOptions?: Apollo.MutationHookOptions<InsightsResendInvitePreApprovedContactMutation, InsightsResendInvitePreApprovedContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsightsResendInvitePreApprovedContactMutation, InsightsResendInvitePreApprovedContactMutationVariables>(InsightsResendInvitePreApprovedContactDocument, options);
      }
export type InsightsResendInvitePreApprovedContactMutationHookResult = ReturnType<typeof useInsightsResendInvitePreApprovedContactMutation>;
export type InsightsResendInvitePreApprovedContactMutationResult = Apollo.MutationResult<InsightsResendInvitePreApprovedContactMutation>;
export type InsightsResendInvitePreApprovedContactMutationOptions = Apollo.BaseMutationOptions<InsightsResendInvitePreApprovedContactMutation, InsightsResendInvitePreApprovedContactMutationVariables>;
export const InsightsDeletePreApprovedContactDocument = gql`
    mutation InsightsDeletePreApprovedContact($contactId: Int!, $userId: String!) {
  insightsDeletePreApprovedContact(contactId: $contactId, userId: $userId) {
    errors
    status
  }
}
    `;
export type InsightsDeletePreApprovedContactMutationFn = Apollo.MutationFunction<InsightsDeletePreApprovedContactMutation, InsightsDeletePreApprovedContactMutationVariables>;

/**
 * __useInsightsDeletePreApprovedContactMutation__
 *
 * To run a mutation, you first call `useInsightsDeletePreApprovedContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsightsDeletePreApprovedContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insightsDeletePreApprovedContactMutation, { data, loading, error }] = useInsightsDeletePreApprovedContactMutation({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useInsightsDeletePreApprovedContactMutation(baseOptions?: Apollo.MutationHookOptions<InsightsDeletePreApprovedContactMutation, InsightsDeletePreApprovedContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsightsDeletePreApprovedContactMutation, InsightsDeletePreApprovedContactMutationVariables>(InsightsDeletePreApprovedContactDocument, options);
      }
export type InsightsDeletePreApprovedContactMutationHookResult = ReturnType<typeof useInsightsDeletePreApprovedContactMutation>;
export type InsightsDeletePreApprovedContactMutationResult = Apollo.MutationResult<InsightsDeletePreApprovedContactMutation>;
export type InsightsDeletePreApprovedContactMutationOptions = Apollo.BaseMutationOptions<InsightsDeletePreApprovedContactMutation, InsightsDeletePreApprovedContactMutationVariables>;
export const InsightsEditPreApprovedContactInviteDocument = gql`
    mutation InsightsEditPreApprovedContactInvite($token: String) {
  insightsEditPreApprovedContactInvite(token: $token) {
    errors
    status
    updatedInvite {
      activity
      email
      firstName
      inviteStatus
      lastName
      orgName
      createdBy
      phoneNumber
    }
  }
}
    `;
export type InsightsEditPreApprovedContactInviteMutationFn = Apollo.MutationFunction<InsightsEditPreApprovedContactInviteMutation, InsightsEditPreApprovedContactInviteMutationVariables>;

/**
 * __useInsightsEditPreApprovedContactInviteMutation__
 *
 * To run a mutation, you first call `useInsightsEditPreApprovedContactInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsightsEditPreApprovedContactInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insightsEditPreApprovedContactInviteMutation, { data, loading, error }] = useInsightsEditPreApprovedContactInviteMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useInsightsEditPreApprovedContactInviteMutation(baseOptions?: Apollo.MutationHookOptions<InsightsEditPreApprovedContactInviteMutation, InsightsEditPreApprovedContactInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsightsEditPreApprovedContactInviteMutation, InsightsEditPreApprovedContactInviteMutationVariables>(InsightsEditPreApprovedContactInviteDocument, options);
      }
export type InsightsEditPreApprovedContactInviteMutationHookResult = ReturnType<typeof useInsightsEditPreApprovedContactInviteMutation>;
export type InsightsEditPreApprovedContactInviteMutationResult = Apollo.MutationResult<InsightsEditPreApprovedContactInviteMutation>;
export type InsightsEditPreApprovedContactInviteMutationOptions = Apollo.BaseMutationOptions<InsightsEditPreApprovedContactInviteMutation, InsightsEditPreApprovedContactInviteMutationVariables>;
export const InsightsSetNotificationPreferencesDocument = gql`
    mutation InsightsSetNotificationPreferences($preferences: [InsSetNotificationPreferences]!, $userId: String!) {
  insightsSetNotificationPreferences(preferences: $preferences, userId: $userId) {
    errors
    messages
    status
  }
}
    `;
export type InsightsSetNotificationPreferencesMutationFn = Apollo.MutationFunction<InsightsSetNotificationPreferencesMutation, InsightsSetNotificationPreferencesMutationVariables>;

/**
 * __useInsightsSetNotificationPreferencesMutation__
 *
 * To run a mutation, you first call `useInsightsSetNotificationPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsightsSetNotificationPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insightsSetNotificationPreferencesMutation, { data, loading, error }] = useInsightsSetNotificationPreferencesMutation({
 *   variables: {
 *      preferences: // value for 'preferences'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useInsightsSetNotificationPreferencesMutation(baseOptions?: Apollo.MutationHookOptions<InsightsSetNotificationPreferencesMutation, InsightsSetNotificationPreferencesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsightsSetNotificationPreferencesMutation, InsightsSetNotificationPreferencesMutationVariables>(InsightsSetNotificationPreferencesDocument, options);
      }
export type InsightsSetNotificationPreferencesMutationHookResult = ReturnType<typeof useInsightsSetNotificationPreferencesMutation>;
export type InsightsSetNotificationPreferencesMutationResult = Apollo.MutationResult<InsightsSetNotificationPreferencesMutation>;
export type InsightsSetNotificationPreferencesMutationOptions = Apollo.BaseMutationOptions<InsightsSetNotificationPreferencesMutation, InsightsSetNotificationPreferencesMutationVariables>;
export const CreateFundraiserApprovalSubmissionDocument = gql`
    mutation CreateFundraiserApprovalSubmission($submitterId: NonEmptyString!) {
  createFundraiserApprovalSubmission(submitterId: $submitterId) {
    errors
    fundraiserApprovalSubmission {
      additionalNotes
      campaignRaiseId
      createdAt
      customerSupportInfo
      duration
      estimatedTotalRaisedCents
      firstName
      formId
      formType
      fundraiserApprovalSubmissionDocs {
        createdAt
        filename
        filetype
        id
        location
        updatedAt
        s3Filename
        status
      }
      fundraiserApprovers {
        approverType
        createdAt
        email
        firstName
        id
        lastName
        phone
        roleId
        updatedAt
        userId
      }
      fundraiserServiceWebsite
      groupSize
      howThisWorks
      id
      lastName
      latestRevision
      newFundraiser
      organizationId
      previousRevision
      pricingAndFees
      programId
      proposedStartDate
      reason
      revisionRequest
      safety
      status
      submitterId
      updatedAt
    }
    status
  }
}
    `;
export type CreateFundraiserApprovalSubmissionMutationFn = Apollo.MutationFunction<CreateFundraiserApprovalSubmissionMutation, CreateFundraiserApprovalSubmissionMutationVariables>;

/**
 * __useCreateFundraiserApprovalSubmissionMutation__
 *
 * To run a mutation, you first call `useCreateFundraiserApprovalSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFundraiserApprovalSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFundraiserApprovalSubmissionMutation, { data, loading, error }] = useCreateFundraiserApprovalSubmissionMutation({
 *   variables: {
 *      submitterId: // value for 'submitterId'
 *   },
 * });
 */
export function useCreateFundraiserApprovalSubmissionMutation(baseOptions?: Apollo.MutationHookOptions<CreateFundraiserApprovalSubmissionMutation, CreateFundraiserApprovalSubmissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFundraiserApprovalSubmissionMutation, CreateFundraiserApprovalSubmissionMutationVariables>(CreateFundraiserApprovalSubmissionDocument, options);
      }
export type CreateFundraiserApprovalSubmissionMutationHookResult = ReturnType<typeof useCreateFundraiserApprovalSubmissionMutation>;
export type CreateFundraiserApprovalSubmissionMutationResult = Apollo.MutationResult<CreateFundraiserApprovalSubmissionMutation>;
export type CreateFundraiserApprovalSubmissionMutationOptions = Apollo.BaseMutationOptions<CreateFundraiserApprovalSubmissionMutation, CreateFundraiserApprovalSubmissionMutationVariables>;
export const SetUserInsightsConfigurationsDocument = gql`
    mutation SetUserInsightsConfigurations($config: [UserInsightsConfigInput]!) {
  setUserInsightsConfigurations(config: $config) {
    messages
    status
  }
}
    `;
export type SetUserInsightsConfigurationsMutationFn = Apollo.MutationFunction<SetUserInsightsConfigurationsMutation, SetUserInsightsConfigurationsMutationVariables>;

/**
 * __useSetUserInsightsConfigurationsMutation__
 *
 * To run a mutation, you first call `useSetUserInsightsConfigurationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserInsightsConfigurationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserInsightsConfigurationsMutation, { data, loading, error }] = useSetUserInsightsConfigurationsMutation({
 *   variables: {
 *      config: // value for 'config'
 *   },
 * });
 */
export function useSetUserInsightsConfigurationsMutation(baseOptions?: Apollo.MutationHookOptions<SetUserInsightsConfigurationsMutation, SetUserInsightsConfigurationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserInsightsConfigurationsMutation, SetUserInsightsConfigurationsMutationVariables>(SetUserInsightsConfigurationsDocument, options);
      }
export type SetUserInsightsConfigurationsMutationHookResult = ReturnType<typeof useSetUserInsightsConfigurationsMutation>;
export type SetUserInsightsConfigurationsMutationResult = Apollo.MutationResult<SetUserInsightsConfigurationsMutation>;
export type SetUserInsightsConfigurationsMutationOptions = Apollo.BaseMutationOptions<SetUserInsightsConfigurationsMutation, SetUserInsightsConfigurationsMutationVariables>;
export const EditFundraiserApprovalSubmissionDocument = gql`
    mutation EditFundraiserApprovalSubmission($fundraiserApprovalSubmissionDocs: [FundraiserApprovalSubmissionDocumentInput]!, $fundraiserApprovers: [FundraiserApproverInput]!, $id: Int!, $additionalNotes: String, $campaignRaiseId: Int, $customerSupportInfo: String, $duration: String, $estimatedTotalRaisedCents: Int, $firstName: String, $formType: FundraiserApprovalSubmissionFormType, $fundraiserServiceWebsite: String, $groupSize: Int, $howThisWorks: String, $lastName: String, $newFundraiser: Boolean, $organizationId: String, $pricingAndFees: String, $programId: String, $proposedStartDate: DateTime, $reason: String, $revisionRequest: String, $safety: String, $status: FundraiserApprovalSubmissionStatus) {
  editFundraiserApprovalSubmission(
    fundraiserApprovalSubmissionDocs: $fundraiserApprovalSubmissionDocs
    fundraiserApprovers: $fundraiserApprovers
    id: $id
    additionalNotes: $additionalNotes
    campaignRaiseId: $campaignRaiseId
    customerSupportInfo: $customerSupportInfo
    duration: $duration
    estimatedTotalRaisedCents: $estimatedTotalRaisedCents
    firstName: $firstName
    formType: $formType
    fundraiserServiceWebsite: $fundraiserServiceWebsite
    groupSize: $groupSize
    howThisWorks: $howThisWorks
    lastName: $lastName
    newFundraiser: $newFundraiser
    organizationId: $organizationId
    pricingAndFees: $pricingAndFees
    programId: $programId
    proposedStartDate: $proposedStartDate
    reason: $reason
    revisionRequest: $revisionRequest
    safety: $safety
    status: $status
  ) {
    errors
    fundraiserApprovalSubmission {
      additionalNotes
      campaignRaiseId
      createdAt
      customerSupportInfo
      duration
      estimatedTotalRaisedCents
      firstName
      formId
      formType
      fundraiserApprovalSubmissionDocs {
        createdAt
        filename
        filetype
        id
        location
        updatedAt
        s3Filename
        status
      }
      fundraiserApprovers {
        approverType
        email
        firstName
        id
        lastName
        phone
        roleId
        userId
      }
      fundraiserServiceWebsite
      groupSize
      howThisWorks
      id
      lastName
      latestRevision
      newFundraiser
      organizationId
      previousRevision
      pricingAndFees
      programId
      proposedStartDate
      reason
      revisionRequest
      safety
      status
      submitterId
      updatedAt
    }
  }
}
    `;
export type EditFundraiserApprovalSubmissionMutationFn = Apollo.MutationFunction<EditFundraiserApprovalSubmissionMutation, EditFundraiserApprovalSubmissionMutationVariables>;

/**
 * __useEditFundraiserApprovalSubmissionMutation__
 *
 * To run a mutation, you first call `useEditFundraiserApprovalSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditFundraiserApprovalSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editFundraiserApprovalSubmissionMutation, { data, loading, error }] = useEditFundraiserApprovalSubmissionMutation({
 *   variables: {
 *      fundraiserApprovalSubmissionDocs: // value for 'fundraiserApprovalSubmissionDocs'
 *      fundraiserApprovers: // value for 'fundraiserApprovers'
 *      id: // value for 'id'
 *      additionalNotes: // value for 'additionalNotes'
 *      campaignRaiseId: // value for 'campaignRaiseId'
 *      customerSupportInfo: // value for 'customerSupportInfo'
 *      duration: // value for 'duration'
 *      estimatedTotalRaisedCents: // value for 'estimatedTotalRaisedCents'
 *      firstName: // value for 'firstName'
 *      formType: // value for 'formType'
 *      fundraiserServiceWebsite: // value for 'fundraiserServiceWebsite'
 *      groupSize: // value for 'groupSize'
 *      howThisWorks: // value for 'howThisWorks'
 *      lastName: // value for 'lastName'
 *      newFundraiser: // value for 'newFundraiser'
 *      organizationId: // value for 'organizationId'
 *      pricingAndFees: // value for 'pricingAndFees'
 *      programId: // value for 'programId'
 *      proposedStartDate: // value for 'proposedStartDate'
 *      reason: // value for 'reason'
 *      revisionRequest: // value for 'revisionRequest'
 *      safety: // value for 'safety'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useEditFundraiserApprovalSubmissionMutation(baseOptions?: Apollo.MutationHookOptions<EditFundraiserApprovalSubmissionMutation, EditFundraiserApprovalSubmissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditFundraiserApprovalSubmissionMutation, EditFundraiserApprovalSubmissionMutationVariables>(EditFundraiserApprovalSubmissionDocument, options);
      }
export type EditFundraiserApprovalSubmissionMutationHookResult = ReturnType<typeof useEditFundraiserApprovalSubmissionMutation>;
export type EditFundraiserApprovalSubmissionMutationResult = Apollo.MutationResult<EditFundraiserApprovalSubmissionMutation>;
export type EditFundraiserApprovalSubmissionMutationOptions = Apollo.BaseMutationOptions<EditFundraiserApprovalSubmissionMutation, EditFundraiserApprovalSubmissionMutationVariables>;
export const S3PresignedInsightsApprovalsUrlDocument = gql`
    mutation S3PresignedInsightsApprovalsUrl($files: [NonEmptyString]!, $approvalsFormId: Int!, $approvalsSubmissionId: Int!) {
  s3PresignedInsightsApprovalsUrl(
    files: $files
    approvalsFormId: $approvalsFormId
    approvalsSubmissionId: $approvalsSubmissionId
  ) {
    filename
    s3Filename
    url
    status
    expires
  }
}
    `;
export type S3PresignedInsightsApprovalsUrlMutationFn = Apollo.MutationFunction<S3PresignedInsightsApprovalsUrlMutation, S3PresignedInsightsApprovalsUrlMutationVariables>;

/**
 * __useS3PresignedInsightsApprovalsUrlMutation__
 *
 * To run a mutation, you first call `useS3PresignedInsightsApprovalsUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useS3PresignedInsightsApprovalsUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [s3PresignedInsightsApprovalsUrlMutation, { data, loading, error }] = useS3PresignedInsightsApprovalsUrlMutation({
 *   variables: {
 *      files: // value for 'files'
 *      approvalsFormId: // value for 'approvalsFormId'
 *      approvalsSubmissionId: // value for 'approvalsSubmissionId'
 *   },
 * });
 */
export function useS3PresignedInsightsApprovalsUrlMutation(baseOptions?: Apollo.MutationHookOptions<S3PresignedInsightsApprovalsUrlMutation, S3PresignedInsightsApprovalsUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<S3PresignedInsightsApprovalsUrlMutation, S3PresignedInsightsApprovalsUrlMutationVariables>(S3PresignedInsightsApprovalsUrlDocument, options);
      }
export type S3PresignedInsightsApprovalsUrlMutationHookResult = ReturnType<typeof useS3PresignedInsightsApprovalsUrlMutation>;
export type S3PresignedInsightsApprovalsUrlMutationResult = Apollo.MutationResult<S3PresignedInsightsApprovalsUrlMutation>;
export type S3PresignedInsightsApprovalsUrlMutationOptions = Apollo.BaseMutationOptions<S3PresignedInsightsApprovalsUrlMutation, S3PresignedInsightsApprovalsUrlMutationVariables>;
export const CompleteFundraiserApprovalSubmissionDocumentUploadDocument = gql`
    mutation CompleteFundraiserApprovalSubmissionDocumentUpload($approvalSubmissionId: Int!, $s3FileName: String!) {
  completeFundraiserApprovalSubmissionDocumentUpload(
    approvalSubmissionId: $approvalSubmissionId
    s3FileName: $s3FileName
  ) {
    filename
    s3FileName
  }
}
    `;
export type CompleteFundraiserApprovalSubmissionDocumentUploadMutationFn = Apollo.MutationFunction<CompleteFundraiserApprovalSubmissionDocumentUploadMutation, CompleteFundraiserApprovalSubmissionDocumentUploadMutationVariables>;

/**
 * __useCompleteFundraiserApprovalSubmissionDocumentUploadMutation__
 *
 * To run a mutation, you first call `useCompleteFundraiserApprovalSubmissionDocumentUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteFundraiserApprovalSubmissionDocumentUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeFundraiserApprovalSubmissionDocumentUploadMutation, { data, loading, error }] = useCompleteFundraiserApprovalSubmissionDocumentUploadMutation({
 *   variables: {
 *      approvalSubmissionId: // value for 'approvalSubmissionId'
 *      s3FileName: // value for 's3FileName'
 *   },
 * });
 */
export function useCompleteFundraiserApprovalSubmissionDocumentUploadMutation(baseOptions?: Apollo.MutationHookOptions<CompleteFundraiserApprovalSubmissionDocumentUploadMutation, CompleteFundraiserApprovalSubmissionDocumentUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteFundraiserApprovalSubmissionDocumentUploadMutation, CompleteFundraiserApprovalSubmissionDocumentUploadMutationVariables>(CompleteFundraiserApprovalSubmissionDocumentUploadDocument, options);
      }
export type CompleteFundraiserApprovalSubmissionDocumentUploadMutationHookResult = ReturnType<typeof useCompleteFundraiserApprovalSubmissionDocumentUploadMutation>;
export type CompleteFundraiserApprovalSubmissionDocumentUploadMutationResult = Apollo.MutationResult<CompleteFundraiserApprovalSubmissionDocumentUploadMutation>;
export type CompleteFundraiserApprovalSubmissionDocumentUploadMutationOptions = Apollo.BaseMutationOptions<CompleteFundraiserApprovalSubmissionDocumentUploadMutation, CompleteFundraiserApprovalSubmissionDocumentUploadMutationVariables>;
export const DeleteFundraiserApprovalSubmissionDocumentDocument = gql`
    mutation DeleteFundraiserApprovalSubmissionDocument($documentKey: String!, $approvalSubmissionId: Int!) {
  deleteFundraiserApprovalSubmissionDocument(
    documentKey: $documentKey
    approvalSubmissionId: $approvalSubmissionId
  )
}
    `;
export type DeleteFundraiserApprovalSubmissionDocumentMutationFn = Apollo.MutationFunction<DeleteFundraiserApprovalSubmissionDocumentMutation, DeleteFundraiserApprovalSubmissionDocumentMutationVariables>;

/**
 * __useDeleteFundraiserApprovalSubmissionDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteFundraiserApprovalSubmissionDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFundraiserApprovalSubmissionDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFundraiserApprovalSubmissionDocumentMutation, { data, loading, error }] = useDeleteFundraiserApprovalSubmissionDocumentMutation({
 *   variables: {
 *      documentKey: // value for 'documentKey'
 *      approvalSubmissionId: // value for 'approvalSubmissionId'
 *   },
 * });
 */
export function useDeleteFundraiserApprovalSubmissionDocumentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFundraiserApprovalSubmissionDocumentMutation, DeleteFundraiserApprovalSubmissionDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFundraiserApprovalSubmissionDocumentMutation, DeleteFundraiserApprovalSubmissionDocumentMutationVariables>(DeleteFundraiserApprovalSubmissionDocumentDocument, options);
      }
export type DeleteFundraiserApprovalSubmissionDocumentMutationHookResult = ReturnType<typeof useDeleteFundraiserApprovalSubmissionDocumentMutation>;
export type DeleteFundraiserApprovalSubmissionDocumentMutationResult = Apollo.MutationResult<DeleteFundraiserApprovalSubmissionDocumentMutation>;
export type DeleteFundraiserApprovalSubmissionDocumentMutationOptions = Apollo.BaseMutationOptions<DeleteFundraiserApprovalSubmissionDocumentMutation, DeleteFundraiserApprovalSubmissionDocumentMutationVariables>;
export const SubmitFundraiserApprovalSubmissionDocument = gql`
    mutation SubmitFundraiserApprovalSubmission($submitFundraiserApprovalSubmissionId: Int!) {
  submitFundraiserApprovalSubmission(id: $submitFundraiserApprovalSubmissionId) {
    id
    formId
    formType
    previousRevision
    latestRevision
    status
    newFundraiser
    campaignRaiseId
    organizationId
    programId
    submitterId
    firstName
    lastName
    groupSize
    estimatedTotalRaisedCents
    pricingAndFees
    howThisWorks
    reason
    proposedStartDate
    duration
    fundraiserServiceWebsite
    safety
    customerSupportInfo
    additionalNotes
    revisionRequest
    createdAt
    updatedAt
    fundraiserApprovalSubmissionDocs {
      id
      filename
      filetype
      location
      createdAt
      updatedAt
      s3Filename
      status
    }
    fundraiserApprovers {
      id
      userId
      approverType
      firstName
      lastName
      email
      phone
      roleId
      createdAt
      updatedAt
    }
  }
}
    `;
export type SubmitFundraiserApprovalSubmissionMutationFn = Apollo.MutationFunction<SubmitFundraiserApprovalSubmissionMutation, SubmitFundraiserApprovalSubmissionMutationVariables>;

/**
 * __useSubmitFundraiserApprovalSubmissionMutation__
 *
 * To run a mutation, you first call `useSubmitFundraiserApprovalSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitFundraiserApprovalSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitFundraiserApprovalSubmissionMutation, { data, loading, error }] = useSubmitFundraiserApprovalSubmissionMutation({
 *   variables: {
 *      submitFundraiserApprovalSubmissionId: // value for 'submitFundraiserApprovalSubmissionId'
 *   },
 * });
 */
export function useSubmitFundraiserApprovalSubmissionMutation(baseOptions?: Apollo.MutationHookOptions<SubmitFundraiserApprovalSubmissionMutation, SubmitFundraiserApprovalSubmissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitFundraiserApprovalSubmissionMutation, SubmitFundraiserApprovalSubmissionMutationVariables>(SubmitFundraiserApprovalSubmissionDocument, options);
      }
export type SubmitFundraiserApprovalSubmissionMutationHookResult = ReturnType<typeof useSubmitFundraiserApprovalSubmissionMutation>;
export type SubmitFundraiserApprovalSubmissionMutationResult = Apollo.MutationResult<SubmitFundraiserApprovalSubmissionMutation>;
export type SubmitFundraiserApprovalSubmissionMutationOptions = Apollo.BaseMutationOptions<SubmitFundraiserApprovalSubmissionMutation, SubmitFundraiserApprovalSubmissionMutationVariables>;
export const ApproveFundraiserApprovalSubmissionDocument = gql`
    mutation ApproveFundraiserApprovalSubmission($token: String!) {
  approveFundraiserApprovalSubmission(token: $token) {
    __typename
    ... on ApproveFundraiserApprovalSubmissionData {
      isSsoApprover
      submitterName
    }
    ... on InvalidToken {
      message
    }
  }
}
    `;
export type ApproveFundraiserApprovalSubmissionMutationFn = Apollo.MutationFunction<ApproveFundraiserApprovalSubmissionMutation, ApproveFundraiserApprovalSubmissionMutationVariables>;

/**
 * __useApproveFundraiserApprovalSubmissionMutation__
 *
 * To run a mutation, you first call `useApproveFundraiserApprovalSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveFundraiserApprovalSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveFundraiserApprovalSubmissionMutation, { data, loading, error }] = useApproveFundraiserApprovalSubmissionMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useApproveFundraiserApprovalSubmissionMutation(baseOptions?: Apollo.MutationHookOptions<ApproveFundraiserApprovalSubmissionMutation, ApproveFundraiserApprovalSubmissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveFundraiserApprovalSubmissionMutation, ApproveFundraiserApprovalSubmissionMutationVariables>(ApproveFundraiserApprovalSubmissionDocument, options);
      }
export type ApproveFundraiserApprovalSubmissionMutationHookResult = ReturnType<typeof useApproveFundraiserApprovalSubmissionMutation>;
export type ApproveFundraiserApprovalSubmissionMutationResult = Apollo.MutationResult<ApproveFundraiserApprovalSubmissionMutation>;
export type ApproveFundraiserApprovalSubmissionMutationOptions = Apollo.BaseMutationOptions<ApproveFundraiserApprovalSubmissionMutation, ApproveFundraiserApprovalSubmissionMutationVariables>;
export const AddRevisionFundraiserApprovalSubmissionDocument = gql`
    mutation AddRevisionFundraiserApprovalSubmission($revision: NonEmptyString!, $token: String!) {
  addRevisionFundraiserApprovalSubmission(revision: $revision, token: $token) {
    __typename
    ... on InvalidToken {
      message
    }
    ... on RevisionFundraiserApprovalSubmissionData {
      isSsoApprover
      submitterName
    }
    ... on UnprocessableSubmission {
      message
    }
  }
}
    `;
export type AddRevisionFundraiserApprovalSubmissionMutationFn = Apollo.MutationFunction<AddRevisionFundraiserApprovalSubmissionMutation, AddRevisionFundraiserApprovalSubmissionMutationVariables>;

/**
 * __useAddRevisionFundraiserApprovalSubmissionMutation__
 *
 * To run a mutation, you first call `useAddRevisionFundraiserApprovalSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRevisionFundraiserApprovalSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRevisionFundraiserApprovalSubmissionMutation, { data, loading, error }] = useAddRevisionFundraiserApprovalSubmissionMutation({
 *   variables: {
 *      revision: // value for 'revision'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useAddRevisionFundraiserApprovalSubmissionMutation(baseOptions?: Apollo.MutationHookOptions<AddRevisionFundraiserApprovalSubmissionMutation, AddRevisionFundraiserApprovalSubmissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddRevisionFundraiserApprovalSubmissionMutation, AddRevisionFundraiserApprovalSubmissionMutationVariables>(AddRevisionFundraiserApprovalSubmissionDocument, options);
      }
export type AddRevisionFundraiserApprovalSubmissionMutationHookResult = ReturnType<typeof useAddRevisionFundraiserApprovalSubmissionMutation>;
export type AddRevisionFundraiserApprovalSubmissionMutationResult = Apollo.MutationResult<AddRevisionFundraiserApprovalSubmissionMutation>;
export type AddRevisionFundraiserApprovalSubmissionMutationOptions = Apollo.BaseMutationOptions<AddRevisionFundraiserApprovalSubmissionMutation, AddRevisionFundraiserApprovalSubmissionMutationVariables>;
export const InsightsCampaignsDataDocument = gql`
    query InsightsCampaignsData($campaignIds: [Int]!) {
  insightsCampaignsData(campaignIds: $campaignIds) {
    lTRChart {
      ltr
      upcomingCampaignsCount
      closedCampaignsCount
      activeCampaignsCount
      activeCampaignsParticipation
      activeCampaignsTotalCents
      avgDonationCents
      closedCampaignsParticipation
      closedCampaignsTotalCents
      upcomingCampaignsForecastedAmountCents
      upcomingCampaignsParticipantSignIn
    }
    campaignStats {
      id
      name
      end_date
      start_date
      donations_count
      insights_status
      total_raised_cents
      forecasted_amount_cents
      participation
      participants
      preloading
      group_leader_name
      group_leader_email
      slug
    }
    donationMapStats {
      campaign_id
      campaign_ids
      donations_amount_cents
      donations_count
      lat
      long
      postal_code
    }
    analyticsSummaryStats {
      year
      month
      campaign_id
      amount_raised_cents
    }
    monthlyCampaignStats {
      campaign_ids
      month
      year
      preloading
      participation
    }
    inviteStats {
      campaign_id
      campaign_ids
      invite_type
      invite_count
      total_amount_cents
    }
  }
}
    `;

/**
 * __useInsightsCampaignsDataQuery__
 *
 * To run a query within a React component, call `useInsightsCampaignsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsCampaignsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsCampaignsDataQuery({
 *   variables: {
 *      campaignIds: // value for 'campaignIds'
 *   },
 * });
 */
export function useInsightsCampaignsDataQuery(baseOptions: Apollo.QueryHookOptions<InsightsCampaignsDataQuery, InsightsCampaignsDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InsightsCampaignsDataQuery, InsightsCampaignsDataQueryVariables>(InsightsCampaignsDataDocument, options);
      }
export function useInsightsCampaignsDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsCampaignsDataQuery, InsightsCampaignsDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InsightsCampaignsDataQuery, InsightsCampaignsDataQueryVariables>(InsightsCampaignsDataDocument, options);
        }
export type InsightsCampaignsDataQueryHookResult = ReturnType<typeof useInsightsCampaignsDataQuery>;
export type InsightsCampaignsDataLazyQueryHookResult = ReturnType<typeof useInsightsCampaignsDataLazyQuery>;
export type InsightsCampaignsDataQueryResult = Apollo.QueryResult<InsightsCampaignsDataQuery, InsightsCampaignsDataQueryVariables>;
export const InsightsCampaignsDataForReportingDocument = gql`
    query InsightsCampaignsDataForReporting($campaignIds: [Int]!) {
  insightsCampaignsData(campaignIds: $campaignIds) {
    campaignStats {
      id
      name
      end_date
      start_date
      insights_status
      total_raised_cents
      start_date
      slug
    }
  }
}
    `;

/**
 * __useInsightsCampaignsDataForReportingQuery__
 *
 * To run a query within a React component, call `useInsightsCampaignsDataForReportingQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsCampaignsDataForReportingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsCampaignsDataForReportingQuery({
 *   variables: {
 *      campaignIds: // value for 'campaignIds'
 *   },
 * });
 */
export function useInsightsCampaignsDataForReportingQuery(baseOptions: Apollo.QueryHookOptions<InsightsCampaignsDataForReportingQuery, InsightsCampaignsDataForReportingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InsightsCampaignsDataForReportingQuery, InsightsCampaignsDataForReportingQueryVariables>(InsightsCampaignsDataForReportingDocument, options);
      }
export function useInsightsCampaignsDataForReportingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsCampaignsDataForReportingQuery, InsightsCampaignsDataForReportingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InsightsCampaignsDataForReportingQuery, InsightsCampaignsDataForReportingQueryVariables>(InsightsCampaignsDataForReportingDocument, options);
        }
export type InsightsCampaignsDataForReportingQueryHookResult = ReturnType<typeof useInsightsCampaignsDataForReportingQuery>;
export type InsightsCampaignsDataForReportingLazyQueryHookResult = ReturnType<typeof useInsightsCampaignsDataForReportingLazyQuery>;
export type InsightsCampaignsDataForReportingQueryResult = Apollo.QueryResult<InsightsCampaignsDataForReportingQuery, InsightsCampaignsDataForReportingQueryVariables>;
export const InsightsCampaignSettlementsDocument = gql`
    query InsightsCampaignSettlements($campaignIds: [Int]!) {
  insightsCampaignSettlements(campaignIds: $campaignIds) {
    id
    campaign_id
    campaign_name
    method
    status
    amount_cents
    last_updated
    slug
  }
}
    `;

/**
 * __useInsightsCampaignSettlementsQuery__
 *
 * To run a query within a React component, call `useInsightsCampaignSettlementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsCampaignSettlementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsCampaignSettlementsQuery({
 *   variables: {
 *      campaignIds: // value for 'campaignIds'
 *   },
 * });
 */
export function useInsightsCampaignSettlementsQuery(baseOptions: Apollo.QueryHookOptions<InsightsCampaignSettlementsQuery, InsightsCampaignSettlementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InsightsCampaignSettlementsQuery, InsightsCampaignSettlementsQueryVariables>(InsightsCampaignSettlementsDocument, options);
      }
export function useInsightsCampaignSettlementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsCampaignSettlementsQuery, InsightsCampaignSettlementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InsightsCampaignSettlementsQuery, InsightsCampaignSettlementsQueryVariables>(InsightsCampaignSettlementsDocument, options);
        }
export type InsightsCampaignSettlementsQueryHookResult = ReturnType<typeof useInsightsCampaignSettlementsQuery>;
export type InsightsCampaignSettlementsLazyQueryHookResult = ReturnType<typeof useInsightsCampaignSettlementsLazyQuery>;
export type InsightsCampaignSettlementsQueryResult = Apollo.QueryResult<InsightsCampaignSettlementsQuery, InsightsCampaignSettlementsQueryVariables>;
export const InsightsCampaignDonationsDocument = gql`
    query InsightsCampaignDonations($campaignIds: [Int]!) {
  insightsCampaignDonations(campaignIds: $campaignIds) {
    id
    campaign_id
    campaign_name
    donor_name
    participant_name
    amount_cents
    created_at
    slug
  }
}
    `;

/**
 * __useInsightsCampaignDonationsQuery__
 *
 * To run a query within a React component, call `useInsightsCampaignDonationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsCampaignDonationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsCampaignDonationsQuery({
 *   variables: {
 *      campaignIds: // value for 'campaignIds'
 *   },
 * });
 */
export function useInsightsCampaignDonationsQuery(baseOptions: Apollo.QueryHookOptions<InsightsCampaignDonationsQuery, InsightsCampaignDonationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InsightsCampaignDonationsQuery, InsightsCampaignDonationsQueryVariables>(InsightsCampaignDonationsDocument, options);
      }
export function useInsightsCampaignDonationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsCampaignDonationsQuery, InsightsCampaignDonationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InsightsCampaignDonationsQuery, InsightsCampaignDonationsQueryVariables>(InsightsCampaignDonationsDocument, options);
        }
export type InsightsCampaignDonationsQueryHookResult = ReturnType<typeof useInsightsCampaignDonationsQuery>;
export type InsightsCampaignDonationsLazyQueryHookResult = ReturnType<typeof useInsightsCampaignDonationsLazyQuery>;
export type InsightsCampaignDonationsQueryResult = Apollo.QueryResult<InsightsCampaignDonationsQuery, InsightsCampaignDonationsQueryVariables>;
export const InsightsDonorParticipantContactsDocument = gql`
    query InsightsDonorParticipantContacts($campaignIds: [Int]!) {
  insightsDonorParticipantContacts(campaignIds: $campaignIds) {
    campaign_id
    donor_id
    donor_name
    donor_email
    participant_id
    participant_name
    participant_email
  }
}
    `;

/**
 * __useInsightsDonorParticipantContactsQuery__
 *
 * To run a query within a React component, call `useInsightsDonorParticipantContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsDonorParticipantContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsDonorParticipantContactsQuery({
 *   variables: {
 *      campaignIds: // value for 'campaignIds'
 *   },
 * });
 */
export function useInsightsDonorParticipantContactsQuery(baseOptions: Apollo.QueryHookOptions<InsightsDonorParticipantContactsQuery, InsightsDonorParticipantContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InsightsDonorParticipantContactsQuery, InsightsDonorParticipantContactsQueryVariables>(InsightsDonorParticipantContactsDocument, options);
      }
export function useInsightsDonorParticipantContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsDonorParticipantContactsQuery, InsightsDonorParticipantContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InsightsDonorParticipantContactsQuery, InsightsDonorParticipantContactsQueryVariables>(InsightsDonorParticipantContactsDocument, options);
        }
export type InsightsDonorParticipantContactsQueryHookResult = ReturnType<typeof useInsightsDonorParticipantContactsQuery>;
export type InsightsDonorParticipantContactsLazyQueryHookResult = ReturnType<typeof useInsightsDonorParticipantContactsLazyQuery>;
export type InsightsDonorParticipantContactsQueryResult = Apollo.QueryResult<InsightsDonorParticipantContactsQuery, InsightsDonorParticipantContactsQueryVariables>;
export const UserAssociationsDocument = gql`
    query UserAssociations($userAssociationsId: ID!, $product: Product!) {
  userAssociations(id: $userAssociationsId, product: $product) {
    id
    fields
    name
    type
    parentId
  }
}
    `;

/**
 * __useUserAssociationsQuery__
 *
 * To run a query within a React component, call `useUserAssociationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAssociationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAssociationsQuery({
 *   variables: {
 *      userAssociationsId: // value for 'userAssociationsId'
 *      product: // value for 'product'
 *   },
 * });
 */
export function useUserAssociationsQuery(baseOptions: Apollo.QueryHookOptions<UserAssociationsQuery, UserAssociationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserAssociationsQuery, UserAssociationsQueryVariables>(UserAssociationsDocument, options);
      }
export function useUserAssociationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserAssociationsQuery, UserAssociationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserAssociationsQuery, UserAssociationsQueryVariables>(UserAssociationsDocument, options);
        }
export type UserAssociationsQueryHookResult = ReturnType<typeof useUserAssociationsQuery>;
export type UserAssociationsLazyQueryHookResult = ReturnType<typeof useUserAssociationsLazyQuery>;
export type UserAssociationsQueryResult = Apollo.QueryResult<UserAssociationsQuery, UserAssociationsQueryVariables>;
export const OrgDocument = gql`
    query Org($orgId: ID!, $hierarchy: Boolean) {
  org(id: $orgId, hierarchy: $hierarchy) {
    id
    fields
    name
    type
    parentId
  }
  financialAcctOrg(orgId: $orgId) {
    acctId
    applicationId
    createdAt
    customerId
    finAcctId
    kybId
    orgEin
    orgId
    orgName
    orgType
    paymentMethodId
    processor
    status
    stripeEnv
    updatedAt
  }
}
    `;

/**
 * __useOrgQuery__
 *
 * To run a query within a React component, call `useOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      hierarchy: // value for 'hierarchy'
 *   },
 * });
 */
export function useOrgQuery(baseOptions: Apollo.QueryHookOptions<OrgQuery, OrgQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgQuery, OrgQueryVariables>(OrgDocument, options);
      }
export function useOrgLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgQuery, OrgQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgQuery, OrgQueryVariables>(OrgDocument, options);
        }
export type OrgQueryHookResult = ReturnType<typeof useOrgQuery>;
export type OrgLazyQueryHookResult = ReturnType<typeof useOrgLazyQuery>;
export type OrgQueryResult = Apollo.QueryResult<OrgQuery, OrgQueryVariables>;
export const InsightsOrgsCampaignsSummaryDocument = gql`
    query InsightsOrgsCampaignsSummary($orgCampaignIds: [InsOrgCampaignSummaryInput]!) {
  insightsOrgsCampaignsSummary(orgCampaignIds: $orgCampaignIds) {
    orgId
    totalRaisedCents
    campaignsCount
  }
}
    `;

/**
 * __useInsightsOrgsCampaignsSummaryQuery__
 *
 * To run a query within a React component, call `useInsightsOrgsCampaignsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsOrgsCampaignsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsOrgsCampaignsSummaryQuery({
 *   variables: {
 *      orgCampaignIds: // value for 'orgCampaignIds'
 *   },
 * });
 */
export function useInsightsOrgsCampaignsSummaryQuery(baseOptions: Apollo.QueryHookOptions<InsightsOrgsCampaignsSummaryQuery, InsightsOrgsCampaignsSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InsightsOrgsCampaignsSummaryQuery, InsightsOrgsCampaignsSummaryQueryVariables>(InsightsOrgsCampaignsSummaryDocument, options);
      }
export function useInsightsOrgsCampaignsSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsOrgsCampaignsSummaryQuery, InsightsOrgsCampaignsSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InsightsOrgsCampaignsSummaryQuery, InsightsOrgsCampaignsSummaryQueryVariables>(InsightsOrgsCampaignsSummaryDocument, options);
        }
export type InsightsOrgsCampaignsSummaryQueryHookResult = ReturnType<typeof useInsightsOrgsCampaignsSummaryQuery>;
export type InsightsOrgsCampaignsSummaryLazyQueryHookResult = ReturnType<typeof useInsightsOrgsCampaignsSummaryLazyQuery>;
export type InsightsOrgsCampaignsSummaryQueryResult = Apollo.QueryResult<InsightsOrgsCampaignsSummaryQuery, InsightsOrgsCampaignsSummaryQueryVariables>;
export const InsightsCampaignRaiseEntityInfoDocument = gql`
    query InsightsCampaignRaiseEntityInfo($campaignId: Int!) {
  insightsCampaignRaiseEntityInfo(campaignId: $campaignId) {
    campaign_id
    entity_id
  }
}
    `;

/**
 * __useInsightsCampaignRaiseEntityInfoQuery__
 *
 * To run a query within a React component, call `useInsightsCampaignRaiseEntityInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsCampaignRaiseEntityInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsCampaignRaiseEntityInfoQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useInsightsCampaignRaiseEntityInfoQuery(baseOptions: Apollo.QueryHookOptions<InsightsCampaignRaiseEntityInfoQuery, InsightsCampaignRaiseEntityInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InsightsCampaignRaiseEntityInfoQuery, InsightsCampaignRaiseEntityInfoQueryVariables>(InsightsCampaignRaiseEntityInfoDocument, options);
      }
export function useInsightsCampaignRaiseEntityInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsCampaignRaiseEntityInfoQuery, InsightsCampaignRaiseEntityInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InsightsCampaignRaiseEntityInfoQuery, InsightsCampaignRaiseEntityInfoQueryVariables>(InsightsCampaignRaiseEntityInfoDocument, options);
        }
export type InsightsCampaignRaiseEntityInfoQueryHookResult = ReturnType<typeof useInsightsCampaignRaiseEntityInfoQuery>;
export type InsightsCampaignRaiseEntityInfoLazyQueryHookResult = ReturnType<typeof useInsightsCampaignRaiseEntityInfoLazyQuery>;
export type InsightsCampaignRaiseEntityInfoQueryResult = Apollo.QueryResult<InsightsCampaignRaiseEntityInfoQuery, InsightsCampaignRaiseEntityInfoQueryVariables>;
export const UserDocument = gql`
    query User($userId: String) {
  user(id: $userId) {
    firstName
    id
    lastName
    email
    phoneNumber
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserQuery(baseOptions?: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    apps
    email
    firstName
    lastName
    id
    phoneNumber
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const InsightsPreApprovedContactsDocument = gql`
    query InsightsPreApprovedContacts($orgIds: [String]!) {
  insightsPreApprovedContacts(orgIds: $orgIds) {
    id
    activity
    email
    first_name
    invite_sent_at
    invite_status
    last_name
    associated_org_id
    invite_phone_number
  }
}
    `;

/**
 * __useInsightsPreApprovedContactsQuery__
 *
 * To run a query within a React component, call `useInsightsPreApprovedContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsPreApprovedContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsPreApprovedContactsQuery({
 *   variables: {
 *      orgIds: // value for 'orgIds'
 *   },
 * });
 */
export function useInsightsPreApprovedContactsQuery(baseOptions: Apollo.QueryHookOptions<InsightsPreApprovedContactsQuery, InsightsPreApprovedContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InsightsPreApprovedContactsQuery, InsightsPreApprovedContactsQueryVariables>(InsightsPreApprovedContactsDocument, options);
      }
export function useInsightsPreApprovedContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsPreApprovedContactsQuery, InsightsPreApprovedContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InsightsPreApprovedContactsQuery, InsightsPreApprovedContactsQueryVariables>(InsightsPreApprovedContactsDocument, options);
        }
export type InsightsPreApprovedContactsQueryHookResult = ReturnType<typeof useInsightsPreApprovedContactsQuery>;
export type InsightsPreApprovedContactsLazyQueryHookResult = ReturnType<typeof useInsightsPreApprovedContactsLazyQuery>;
export type InsightsPreApprovedContactsQueryResult = Apollo.QueryResult<InsightsPreApprovedContactsQuery, InsightsPreApprovedContactsQueryVariables>;
export const InsightsPreApprovedContactsEmailTestDocument = gql`
    query InsightsPreApprovedContactsEmailTest($emails: [String]!, $orgId: String!) {
  insightsPreApprovedContactsEmailTest(emails: $emails, orgId: $orgId) {
    email
    status
  }
}
    `;

/**
 * __useInsightsPreApprovedContactsEmailTestQuery__
 *
 * To run a query within a React component, call `useInsightsPreApprovedContactsEmailTestQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsPreApprovedContactsEmailTestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsPreApprovedContactsEmailTestQuery({
 *   variables: {
 *      emails: // value for 'emails'
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useInsightsPreApprovedContactsEmailTestQuery(baseOptions: Apollo.QueryHookOptions<InsightsPreApprovedContactsEmailTestQuery, InsightsPreApprovedContactsEmailTestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InsightsPreApprovedContactsEmailTestQuery, InsightsPreApprovedContactsEmailTestQueryVariables>(InsightsPreApprovedContactsEmailTestDocument, options);
      }
export function useInsightsPreApprovedContactsEmailTestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsPreApprovedContactsEmailTestQuery, InsightsPreApprovedContactsEmailTestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InsightsPreApprovedContactsEmailTestQuery, InsightsPreApprovedContactsEmailTestQueryVariables>(InsightsPreApprovedContactsEmailTestDocument, options);
        }
export type InsightsPreApprovedContactsEmailTestQueryHookResult = ReturnType<typeof useInsightsPreApprovedContactsEmailTestQuery>;
export type InsightsPreApprovedContactsEmailTestLazyQueryHookResult = ReturnType<typeof useInsightsPreApprovedContactsEmailTestLazyQuery>;
export type InsightsPreApprovedContactsEmailTestQueryResult = Apollo.QueryResult<InsightsPreApprovedContactsEmailTestQuery, InsightsPreApprovedContactsEmailTestQueryVariables>;
export const InsightsUserPreferencesDocument = gql`
    query InsightsUserPreferences($userId: String!, $queryType: UserPreferenceQueryType!) {
  insightsUserPreferences(userId: $userId, queryType: $queryType) {
    id
    org_id
    user_id
    campaign_raise_id
  }
}
    `;

/**
 * __useInsightsUserPreferencesQuery__
 *
 * To run a query within a React component, call `useInsightsUserPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsUserPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsUserPreferencesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      queryType: // value for 'queryType'
 *   },
 * });
 */
export function useInsightsUserPreferencesQuery(baseOptions: Apollo.QueryHookOptions<InsightsUserPreferencesQuery, InsightsUserPreferencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InsightsUserPreferencesQuery, InsightsUserPreferencesQueryVariables>(InsightsUserPreferencesDocument, options);
      }
export function useInsightsUserPreferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsUserPreferencesQuery, InsightsUserPreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InsightsUserPreferencesQuery, InsightsUserPreferencesQueryVariables>(InsightsUserPreferencesDocument, options);
        }
export type InsightsUserPreferencesQueryHookResult = ReturnType<typeof useInsightsUserPreferencesQuery>;
export type InsightsUserPreferencesLazyQueryHookResult = ReturnType<typeof useInsightsUserPreferencesLazyQuery>;
export type InsightsUserPreferencesQueryResult = Apollo.QueryResult<InsightsUserPreferencesQuery, InsightsUserPreferencesQueryVariables>;
export const InsightsNotificationPreferencesDocument = gql`
    query InsightsNotificationPreferences($userId: String!) {
  insightsNotificationPreferences(userId: $userId) {
    errors
    orgNotificationPreferences {
      __typename
      id
      org_id
    }
    status
    userNotificationPreferences {
      __typename
      id
      name
    }
  }
}
    `;

/**
 * __useInsightsNotificationPreferencesQuery__
 *
 * To run a query within a React component, call `useInsightsNotificationPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsNotificationPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsNotificationPreferencesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useInsightsNotificationPreferencesQuery(baseOptions: Apollo.QueryHookOptions<InsightsNotificationPreferencesQuery, InsightsNotificationPreferencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InsightsNotificationPreferencesQuery, InsightsNotificationPreferencesQueryVariables>(InsightsNotificationPreferencesDocument, options);
      }
export function useInsightsNotificationPreferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsNotificationPreferencesQuery, InsightsNotificationPreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InsightsNotificationPreferencesQuery, InsightsNotificationPreferencesQueryVariables>(InsightsNotificationPreferencesDocument, options);
        }
export type InsightsNotificationPreferencesQueryHookResult = ReturnType<typeof useInsightsNotificationPreferencesQuery>;
export type InsightsNotificationPreferencesLazyQueryHookResult = ReturnType<typeof useInsightsNotificationPreferencesLazyQuery>;
export type InsightsNotificationPreferencesQueryResult = Apollo.QueryResult<InsightsNotificationPreferencesQuery, InsightsNotificationPreferencesQueryVariables>;
export const GroupLeaderCampaignMembershipsDocument = gql`
    query GroupLeaderCampaignMemberships($basicStatus: BasicCampaignStatus) {
  campaignMemberships(basicStatus: $basicStatus) {
    ... on GroupLeaderCampaign {
      basicStatus
      id
      name
      status
      goalInDollars
      teamSize
      donationLink
    }
  }
}
    `;

/**
 * __useGroupLeaderCampaignMembershipsQuery__
 *
 * To run a query within a React component, call `useGroupLeaderCampaignMembershipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupLeaderCampaignMembershipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupLeaderCampaignMembershipsQuery({
 *   variables: {
 *      basicStatus: // value for 'basicStatus'
 *   },
 * });
 */
export function useGroupLeaderCampaignMembershipsQuery(baseOptions?: Apollo.QueryHookOptions<GroupLeaderCampaignMembershipsQuery, GroupLeaderCampaignMembershipsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupLeaderCampaignMembershipsQuery, GroupLeaderCampaignMembershipsQueryVariables>(GroupLeaderCampaignMembershipsDocument, options);
      }
export function useGroupLeaderCampaignMembershipsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupLeaderCampaignMembershipsQuery, GroupLeaderCampaignMembershipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupLeaderCampaignMembershipsQuery, GroupLeaderCampaignMembershipsQueryVariables>(GroupLeaderCampaignMembershipsDocument, options);
        }
export type GroupLeaderCampaignMembershipsQueryHookResult = ReturnType<typeof useGroupLeaderCampaignMembershipsQuery>;
export type GroupLeaderCampaignMembershipsLazyQueryHookResult = ReturnType<typeof useGroupLeaderCampaignMembershipsLazyQuery>;
export type GroupLeaderCampaignMembershipsQueryResult = Apollo.QueryResult<GroupLeaderCampaignMembershipsQuery, GroupLeaderCampaignMembershipsQueryVariables>;
export const InsightsApprovalSubmissionsDocument = gql`
    query InsightsApprovalSubmissions($submitterId: String!, $approverId: String, $campaignIds: [Int], $formId: Int, $status: FundraiserApprovalSubmissionStatus, $latest: Boolean) {
  fundraiserApprovalSubmissions(
    submitterId: $submitterId
    approverId: $approverId
    campaignIds: $campaignIds
    formId: $formId
    status: $status
    latest: $latest
  ) {
    additionalNotes
    fundraiserApprovalSubmissionDocs {
      createdAt
      filename
      filetype
      id
      location
      updatedAt
      s3Filename
      status
    }
    fundraiserApprovers {
      approverType
      createdAt
      email
      firstName
      id
      lastName
      phone
      roleId
      updatedAt
      userId
    }
    campaignRaiseId
    createdAt
    customerSupportInfo
    duration
    estimatedTotalRaisedCents
    firstName
    formId
    formType
    fundraiserServiceWebsite
    groupSize
    howThisWorks
    id
    lastName
    latestRevision
    newFundraiser
    organizationId
    previousRevision
    pricingAndFees
    programId
    proposedStartDate
    reason
    revisionRequest
    safety
    status
    submitterId
    updatedAt
  }
}
    `;

/**
 * __useInsightsApprovalSubmissionsQuery__
 *
 * To run a query within a React component, call `useInsightsApprovalSubmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsApprovalSubmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsApprovalSubmissionsQuery({
 *   variables: {
 *      submitterId: // value for 'submitterId'
 *      approverId: // value for 'approverId'
 *      campaignIds: // value for 'campaignIds'
 *      formId: // value for 'formId'
 *      status: // value for 'status'
 *      latest: // value for 'latest'
 *   },
 * });
 */
export function useInsightsApprovalSubmissionsQuery(baseOptions: Apollo.QueryHookOptions<InsightsApprovalSubmissionsQuery, InsightsApprovalSubmissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InsightsApprovalSubmissionsQuery, InsightsApprovalSubmissionsQueryVariables>(InsightsApprovalSubmissionsDocument, options);
      }
export function useInsightsApprovalSubmissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsApprovalSubmissionsQuery, InsightsApprovalSubmissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InsightsApprovalSubmissionsQuery, InsightsApprovalSubmissionsQueryVariables>(InsightsApprovalSubmissionsDocument, options);
        }
export type InsightsApprovalSubmissionsQueryHookResult = ReturnType<typeof useInsightsApprovalSubmissionsQuery>;
export type InsightsApprovalSubmissionsLazyQueryHookResult = ReturnType<typeof useInsightsApprovalSubmissionsLazyQuery>;
export type InsightsApprovalSubmissionsQueryResult = Apollo.QueryResult<InsightsApprovalSubmissionsQuery, InsightsApprovalSubmissionsQueryVariables>;
export const OrgsAndCampaignNameByCampaignIdDocument = gql`
    query OrgsAndCampaignNameByCampaignId($campaignId: ID!) {
  orgCampaignId(campaignId: $campaignId, hierarchy: true) {
    createdAt
    fields
    financialAccounts {
      acctId
      applicationId
      createdAt
      customerId
      id
      kybId
      paymentMethodId
      processor
      status
      stripeEnv
      updatedAt
    }
    id
    name
    lastMigrated
    type
  }
  org(id: $campaignId) {
    name
  }
}
    `;

/**
 * __useOrgsAndCampaignNameByCampaignIdQuery__
 *
 * To run a query within a React component, call `useOrgsAndCampaignNameByCampaignIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgsAndCampaignNameByCampaignIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgsAndCampaignNameByCampaignIdQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useOrgsAndCampaignNameByCampaignIdQuery(baseOptions: Apollo.QueryHookOptions<OrgsAndCampaignNameByCampaignIdQuery, OrgsAndCampaignNameByCampaignIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgsAndCampaignNameByCampaignIdQuery, OrgsAndCampaignNameByCampaignIdQueryVariables>(OrgsAndCampaignNameByCampaignIdDocument, options);
      }
export function useOrgsAndCampaignNameByCampaignIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgsAndCampaignNameByCampaignIdQuery, OrgsAndCampaignNameByCampaignIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgsAndCampaignNameByCampaignIdQuery, OrgsAndCampaignNameByCampaignIdQueryVariables>(OrgsAndCampaignNameByCampaignIdDocument, options);
        }
export type OrgsAndCampaignNameByCampaignIdQueryHookResult = ReturnType<typeof useOrgsAndCampaignNameByCampaignIdQuery>;
export type OrgsAndCampaignNameByCampaignIdLazyQueryHookResult = ReturnType<typeof useOrgsAndCampaignNameByCampaignIdLazyQuery>;
export type OrgsAndCampaignNameByCampaignIdQueryResult = Apollo.QueryResult<OrgsAndCampaignNameByCampaignIdQuery, OrgsAndCampaignNameByCampaignIdQueryVariables>;
export const CampaignOrgIdDocument = gql`
    query CampaignOrgId($raiseId: Int!) {
  orgId(raiseId: $raiseId)
}
    `;

/**
 * __useCampaignOrgIdQuery__
 *
 * To run a query within a React component, call `useCampaignOrgIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignOrgIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignOrgIdQuery({
 *   variables: {
 *      raiseId: // value for 'raiseId'
 *   },
 * });
 */
export function useCampaignOrgIdQuery(baseOptions: Apollo.QueryHookOptions<CampaignOrgIdQuery, CampaignOrgIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignOrgIdQuery, CampaignOrgIdQueryVariables>(CampaignOrgIdDocument, options);
      }
export function useCampaignOrgIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignOrgIdQuery, CampaignOrgIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignOrgIdQuery, CampaignOrgIdQueryVariables>(CampaignOrgIdDocument, options);
        }
export type CampaignOrgIdQueryHookResult = ReturnType<typeof useCampaignOrgIdQuery>;
export type CampaignOrgIdLazyQueryHookResult = ReturnType<typeof useCampaignOrgIdLazyQuery>;
export type CampaignOrgIdQueryResult = Apollo.QueryResult<CampaignOrgIdQuery, CampaignOrgIdQueryVariables>;
export const UserInsightsConfigurationsDocument = gql`
    query UserInsightsConfigurations {
  userInsightsConfigurations {
    createdAt
    id
    name
    updatedAt
    userId
    value
  }
}
    `;

/**
 * __useUserInsightsConfigurationsQuery__
 *
 * To run a query within a React component, call `useUserInsightsConfigurationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserInsightsConfigurationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserInsightsConfigurationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserInsightsConfigurationsQuery(baseOptions?: Apollo.QueryHookOptions<UserInsightsConfigurationsQuery, UserInsightsConfigurationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserInsightsConfigurationsQuery, UserInsightsConfigurationsQueryVariables>(UserInsightsConfigurationsDocument, options);
      }
export function useUserInsightsConfigurationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserInsightsConfigurationsQuery, UserInsightsConfigurationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserInsightsConfigurationsQuery, UserInsightsConfigurationsQueryVariables>(UserInsightsConfigurationsDocument, options);
        }
export type UserInsightsConfigurationsQueryHookResult = ReturnType<typeof useUserInsightsConfigurationsQuery>;
export type UserInsightsConfigurationsLazyQueryHookResult = ReturnType<typeof useUserInsightsConfigurationsLazyQuery>;
export type UserInsightsConfigurationsQueryResult = Apollo.QueryResult<UserInsightsConfigurationsQuery, UserInsightsConfigurationsQueryVariables>;
export const FundraiserOrgAndGroupNamesDocument = gql`
    query FundraiserOrgAndGroupNames($orgId: ID!, $programId: ID!) {
  orgName: org(id: $orgId, hierarchy: false) {
    name
  }
  programName: org(id: $programId, hierarchy: false) {
    name
  }
}
    `;

/**
 * __useFundraiserOrgAndGroupNamesQuery__
 *
 * To run a query within a React component, call `useFundraiserOrgAndGroupNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFundraiserOrgAndGroupNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFundraiserOrgAndGroupNamesQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useFundraiserOrgAndGroupNamesQuery(baseOptions: Apollo.QueryHookOptions<FundraiserOrgAndGroupNamesQuery, FundraiserOrgAndGroupNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FundraiserOrgAndGroupNamesQuery, FundraiserOrgAndGroupNamesQueryVariables>(FundraiserOrgAndGroupNamesDocument, options);
      }
export function useFundraiserOrgAndGroupNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FundraiserOrgAndGroupNamesQuery, FundraiserOrgAndGroupNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FundraiserOrgAndGroupNamesQuery, FundraiserOrgAndGroupNamesQueryVariables>(FundraiserOrgAndGroupNamesDocument, options);
        }
export type FundraiserOrgAndGroupNamesQueryHookResult = ReturnType<typeof useFundraiserOrgAndGroupNamesQuery>;
export type FundraiserOrgAndGroupNamesLazyQueryHookResult = ReturnType<typeof useFundraiserOrgAndGroupNamesLazyQuery>;
export type FundraiserOrgAndGroupNamesQueryResult = Apollo.QueryResult<FundraiserOrgAndGroupNamesQuery, FundraiserOrgAndGroupNamesQueryVariables>;
export const VaultAccountInfoDocument = gql`
    query VaultAccountInfo($provider: VaultFinancialProvider!, $accountId: ID, $finAcctId: ID!) {
  vaultAccountStatus(provider: $provider, accountId: $accountId) {
    accountRepresentativeRequirements {
      missingFields
      requiredDocuments
    }
    beneficialOwnersRequirements {
      missingFields
      requiredDocuments
    }
    kybRequirements {
      missingFields
      requiredDocuments
    }
    valid
  }
  financialAcctUsers(finAcctId: $finAcctId) {
    acctId
    orgId
    status
    userId
  }
}
    `;

/**
 * __useVaultAccountInfoQuery__
 *
 * To run a query within a React component, call `useVaultAccountInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useVaultAccountInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVaultAccountInfoQuery({
 *   variables: {
 *      provider: // value for 'provider'
 *      accountId: // value for 'accountId'
 *      finAcctId: // value for 'finAcctId'
 *   },
 * });
 */
export function useVaultAccountInfoQuery(baseOptions: Apollo.QueryHookOptions<VaultAccountInfoQuery, VaultAccountInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VaultAccountInfoQuery, VaultAccountInfoQueryVariables>(VaultAccountInfoDocument, options);
      }
export function useVaultAccountInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VaultAccountInfoQuery, VaultAccountInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VaultAccountInfoQuery, VaultAccountInfoQueryVariables>(VaultAccountInfoDocument, options);
        }
export type VaultAccountInfoQueryHookResult = ReturnType<typeof useVaultAccountInfoQuery>;
export type VaultAccountInfoLazyQueryHookResult = ReturnType<typeof useVaultAccountInfoLazyQuery>;
export type VaultAccountInfoQueryResult = Apollo.QueryResult<VaultAccountInfoQuery, VaultAccountInfoQueryVariables>;
export const OrgSearchDocument = gql`
    query OrgSearch($name: String!, $limit: Int, $label: OrgType) {
  orgSearch(name: $name, limit: $limit, label: $label) {
    id
    name
    fields
  }
}
    `;

/**
 * __useOrgSearchQuery__
 *
 * To run a query within a React component, call `useOrgSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgSearchQuery({
 *   variables: {
 *      name: // value for 'name'
 *      limit: // value for 'limit'
 *      label: // value for 'label'
 *   },
 * });
 */
export function useOrgSearchQuery(baseOptions: Apollo.QueryHookOptions<OrgSearchQuery, OrgSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgSearchQuery, OrgSearchQueryVariables>(OrgSearchDocument, options);
      }
export function useOrgSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgSearchQuery, OrgSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgSearchQuery, OrgSearchQueryVariables>(OrgSearchDocument, options);
        }
export type OrgSearchQueryHookResult = ReturnType<typeof useOrgSearchQuery>;
export type OrgSearchLazyQueryHookResult = ReturnType<typeof useOrgSearchLazyQuery>;
export type OrgSearchQueryResult = Apollo.QueryResult<OrgSearchQuery, OrgSearchQueryVariables>;
export const GroupLeaderCampaignIdsDocument = gql`
    query GroupLeaderCampaignIds {
  campaignMemberships {
    ... on GroupLeaderCampaign {
      id
    }
  }
}
    `;

/**
 * __useGroupLeaderCampaignIdsQuery__
 *
 * To run a query within a React component, call `useGroupLeaderCampaignIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupLeaderCampaignIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupLeaderCampaignIdsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGroupLeaderCampaignIdsQuery(baseOptions?: Apollo.QueryHookOptions<GroupLeaderCampaignIdsQuery, GroupLeaderCampaignIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupLeaderCampaignIdsQuery, GroupLeaderCampaignIdsQueryVariables>(GroupLeaderCampaignIdsDocument, options);
      }
export function useGroupLeaderCampaignIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupLeaderCampaignIdsQuery, GroupLeaderCampaignIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupLeaderCampaignIdsQuery, GroupLeaderCampaignIdsQueryVariables>(GroupLeaderCampaignIdsDocument, options);
        }
export type GroupLeaderCampaignIdsQueryHookResult = ReturnType<typeof useGroupLeaderCampaignIdsQuery>;
export type GroupLeaderCampaignIdsLazyQueryHookResult = ReturnType<typeof useGroupLeaderCampaignIdsLazyQuery>;
export type GroupLeaderCampaignIdsQueryResult = Apollo.QueryResult<GroupLeaderCampaignIdsQuery, GroupLeaderCampaignIdsQueryVariables>;
export const InsightsUserAssociationIdsDocument = gql`
    query InsightsUserAssociationIds($orgId: ID!) {
  orgUserAssociations(orgId: $orgId, product: INSIGHTS) {
    id
  }
}
    `;

/**
 * __useInsightsUserAssociationIdsQuery__
 *
 * To run a query within a React component, call `useInsightsUserAssociationIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsUserAssociationIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsUserAssociationIdsQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useInsightsUserAssociationIdsQuery(baseOptions: Apollo.QueryHookOptions<InsightsUserAssociationIdsQuery, InsightsUserAssociationIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InsightsUserAssociationIdsQuery, InsightsUserAssociationIdsQueryVariables>(InsightsUserAssociationIdsDocument, options);
      }
export function useInsightsUserAssociationIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsUserAssociationIdsQuery, InsightsUserAssociationIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InsightsUserAssociationIdsQuery, InsightsUserAssociationIdsQueryVariables>(InsightsUserAssociationIdsDocument, options);
        }
export type InsightsUserAssociationIdsQueryHookResult = ReturnType<typeof useInsightsUserAssociationIdsQuery>;
export type InsightsUserAssociationIdsLazyQueryHookResult = ReturnType<typeof useInsightsUserAssociationIdsLazyQuery>;
export type InsightsUserAssociationIdsQueryResult = Apollo.QueryResult<InsightsUserAssociationIdsQuery, InsightsUserAssociationIdsQueryVariables>;
export const UserPublicInfoDocument = gql`
    query UserPublicInfo($userPublicInfoId: [ID]) {
  userPublicInfo(id: $userPublicInfoId) {
    user {
      firstName
      id
      lastName
    }
  }
}
    `;

/**
 * __useUserPublicInfoQuery__
 *
 * To run a query within a React component, call `useUserPublicInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPublicInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPublicInfoQuery({
 *   variables: {
 *      userPublicInfoId: // value for 'userPublicInfoId'
 *   },
 * });
 */
export function useUserPublicInfoQuery(baseOptions?: Apollo.QueryHookOptions<UserPublicInfoQuery, UserPublicInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserPublicInfoQuery, UserPublicInfoQueryVariables>(UserPublicInfoDocument, options);
      }
export function useUserPublicInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserPublicInfoQuery, UserPublicInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserPublicInfoQuery, UserPublicInfoQueryVariables>(UserPublicInfoDocument, options);
        }
export type UserPublicInfoQueryHookResult = ReturnType<typeof useUserPublicInfoQuery>;
export type UserPublicInfoLazyQueryHookResult = ReturnType<typeof useUserPublicInfoLazyQuery>;
export type UserPublicInfoQueryResult = Apollo.QueryResult<UserPublicInfoQuery, UserPublicInfoQueryVariables>;
export const OrgHierarchyDocument = gql`
    query OrgHierarchy($orgId: ID!) {
  org(id: $orgId, hierarchy: true) {
    id
    fields
    type
  }
}
    `;

/**
 * __useOrgHierarchyQuery__
 *
 * To run a query within a React component, call `useOrgHierarchyQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgHierarchyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgHierarchyQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useOrgHierarchyQuery(baseOptions: Apollo.QueryHookOptions<OrgHierarchyQuery, OrgHierarchyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgHierarchyQuery, OrgHierarchyQueryVariables>(OrgHierarchyDocument, options);
      }
export function useOrgHierarchyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgHierarchyQuery, OrgHierarchyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgHierarchyQuery, OrgHierarchyQueryVariables>(OrgHierarchyDocument, options);
        }
export type OrgHierarchyQueryHookResult = ReturnType<typeof useOrgHierarchyQuery>;
export type OrgHierarchyLazyQueryHookResult = ReturnType<typeof useOrgHierarchyLazyQuery>;
export type OrgHierarchyQueryResult = Apollo.QueryResult<OrgHierarchyQuery, OrgHierarchyQueryVariables>;
export const InsightsApprovalSubmissionHistoryDocument = gql`
    query InsightsApprovalSubmissionHistory($token: String!) {
  fundraiserApprovalSubmissionsHistory(token: $token) {
    additionalNotes
    campaignRaiseId
    createdAt
    customerSupportInfo
    duration
    estimatedTotalRaisedCents
    firstName
    formId
    formType
    fundraiserApprovalSubmissionDocs {
      createdAt
      filename
      filetype
      id
      location
      s3Filename
      status
      updatedAt
    }
    fundraiserApprovers {
      approverType
      createdAt
      email
      firstName
      id
      lastName
      phone
      roleId
      updatedAt
      userId
    }
    fundraiserServiceWebsite
    groupSize
    howThisWorks
    id
    lastName
    latestRevision
    newFundraiser
    organizationId
    previousRevision
    pricingAndFees
    programId
    proposedStartDate
    reason
    revisionRequest
    safety
    status
    submitterId
    updatedAt
  }
  fundraiserApprovalSubmissionsOrgNames(token: $token) {
    __typename
    ... on FundraiserApprovalSubmissionsOrgNames {
      fundraiserName
      organizationName
      programName
    }
    ... on InvalidToken {
      message
    }
  }
}
    `;

/**
 * __useInsightsApprovalSubmissionHistoryQuery__
 *
 * To run a query within a React component, call `useInsightsApprovalSubmissionHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsApprovalSubmissionHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsApprovalSubmissionHistoryQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useInsightsApprovalSubmissionHistoryQuery(baseOptions: Apollo.QueryHookOptions<InsightsApprovalSubmissionHistoryQuery, InsightsApprovalSubmissionHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InsightsApprovalSubmissionHistoryQuery, InsightsApprovalSubmissionHistoryQueryVariables>(InsightsApprovalSubmissionHistoryDocument, options);
      }
export function useInsightsApprovalSubmissionHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsApprovalSubmissionHistoryQuery, InsightsApprovalSubmissionHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InsightsApprovalSubmissionHistoryQuery, InsightsApprovalSubmissionHistoryQueryVariables>(InsightsApprovalSubmissionHistoryDocument, options);
        }
export type InsightsApprovalSubmissionHistoryQueryHookResult = ReturnType<typeof useInsightsApprovalSubmissionHistoryQuery>;
export type InsightsApprovalSubmissionHistoryLazyQueryHookResult = ReturnType<typeof useInsightsApprovalSubmissionHistoryLazyQuery>;
export type InsightsApprovalSubmissionHistoryQueryResult = Apollo.QueryResult<InsightsApprovalSubmissionHistoryQuery, InsightsApprovalSubmissionHistoryQueryVariables>;
export const FundraiserApprovalSubmissionsOrgNamesDocument = gql`
    query FundraiserApprovalSubmissionsOrgNames($submissionId: Int) {
  fundraiserApprovalSubmissionsOrgNames(submissionId: $submissionId) {
    __typename
    ... on FundraiserApprovalSubmissionsOrgNames {
      fundraiserName
      organizationName
      programName
    }
    ... on InvalidToken {
      message
    }
  }
}
    `;

/**
 * __useFundraiserApprovalSubmissionsOrgNamesQuery__
 *
 * To run a query within a React component, call `useFundraiserApprovalSubmissionsOrgNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFundraiserApprovalSubmissionsOrgNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFundraiserApprovalSubmissionsOrgNamesQuery({
 *   variables: {
 *      submissionId: // value for 'submissionId'
 *   },
 * });
 */
export function useFundraiserApprovalSubmissionsOrgNamesQuery(baseOptions?: Apollo.QueryHookOptions<FundraiserApprovalSubmissionsOrgNamesQuery, FundraiserApprovalSubmissionsOrgNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FundraiserApprovalSubmissionsOrgNamesQuery, FundraiserApprovalSubmissionsOrgNamesQueryVariables>(FundraiserApprovalSubmissionsOrgNamesDocument, options);
      }
export function useFundraiserApprovalSubmissionsOrgNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FundraiserApprovalSubmissionsOrgNamesQuery, FundraiserApprovalSubmissionsOrgNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FundraiserApprovalSubmissionsOrgNamesQuery, FundraiserApprovalSubmissionsOrgNamesQueryVariables>(FundraiserApprovalSubmissionsOrgNamesDocument, options);
        }
export type FundraiserApprovalSubmissionsOrgNamesQueryHookResult = ReturnType<typeof useFundraiserApprovalSubmissionsOrgNamesQuery>;
export type FundraiserApprovalSubmissionsOrgNamesLazyQueryHookResult = ReturnType<typeof useFundraiserApprovalSubmissionsOrgNamesLazyQuery>;
export type FundraiserApprovalSubmissionsOrgNamesQueryResult = Apollo.QueryResult<FundraiserApprovalSubmissionsOrgNamesQuery, FundraiserApprovalSubmissionsOrgNamesQueryVariables>;
export const PermissionsDocument = gql`
    query Permissions {
  me {
    email
    permissions
    id
  }
}
    `;

/**
 * __usePermissionsQuery__
 *
 * To run a query within a React component, call `usePermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePermissionsQuery(baseOptions?: Apollo.QueryHookOptions<PermissionsQuery, PermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PermissionsQuery, PermissionsQueryVariables>(PermissionsDocument, options);
      }
export function usePermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PermissionsQuery, PermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PermissionsQuery, PermissionsQueryVariables>(PermissionsDocument, options);
        }
export type PermissionsQueryHookResult = ReturnType<typeof usePermissionsQuery>;
export type PermissionsLazyQueryHookResult = ReturnType<typeof usePermissionsLazyQuery>;
export type PermissionsQueryResult = Apollo.QueryResult<PermissionsQuery, PermissionsQueryVariables>;
export const SuperBasicUserInfoDocument = gql`
    query SuperBasicUserInfo {
  me {
    firstName
    lastName
    id
  }
}
    `;

/**
 * __useSuperBasicUserInfoQuery__
 *
 * To run a query within a React component, call `useSuperBasicUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuperBasicUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuperBasicUserInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useSuperBasicUserInfoQuery(baseOptions?: Apollo.QueryHookOptions<SuperBasicUserInfoQuery, SuperBasicUserInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SuperBasicUserInfoQuery, SuperBasicUserInfoQueryVariables>(SuperBasicUserInfoDocument, options);
      }
export function useSuperBasicUserInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SuperBasicUserInfoQuery, SuperBasicUserInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SuperBasicUserInfoQuery, SuperBasicUserInfoQueryVariables>(SuperBasicUserInfoDocument, options);
        }
export type SuperBasicUserInfoQueryHookResult = ReturnType<typeof useSuperBasicUserInfoQuery>;
export type SuperBasicUserInfoLazyQueryHookResult = ReturnType<typeof useSuperBasicUserInfoLazyQuery>;
export type SuperBasicUserInfoQueryResult = Apollo.QueryResult<SuperBasicUserInfoQuery, SuperBasicUserInfoQueryVariables>;