import "./App.css";

import { Outlet, useLocation } from "react-router-dom";
import { usePermissionsQuery} from "./graphql/generated";
import FeatureToggleInitializer from "./components/FeatureToggleInitializer";
import useSentry from "./hooks/useSentry";
import { isPublicPage } from "./utils/RouteUtils";


function App() {
  const location = useLocation();
  const { data: user, loading: userLoading } = usePermissionsQuery();
  const publicPage = isPublicPage(location.pathname);
  const id = publicPage ? 'LOGGED_OUT' : (!userLoading && user?.me?.id ? `${user.me.id}` : "");
  const userId = publicPage ? 'LOGGED_OUT' : (!userLoading && user?.me?.id ? `${user.me.id}` : undefined);
  useSentry(userId, true);

  return (
    <FeatureToggleInitializer id={id} featureList={[]}>
      <Outlet />
    </FeatureToggleInitializer>

  );
}

export default App;
