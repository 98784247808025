import React from 'react';
import { SnapInput } from '../suit';

interface ApprovalsUserNameProps {
  fieldId: string;
  semanticType: "first" | "last";
  name: string;
  className?: string;
}

const ApprovalsUserName: React.FC<ApprovalsUserNameProps> = ({ fieldId, semanticType, name, className = ""}) => {
  const label =  semanticType === "first" ? "Your First Name" : " Your Last Name";

  return (
    <div className={`${className}`}>
      <SnapInput
        _id={fieldId}
        data-testid={fieldId}
        name={fieldId}
        label={label}
        required
        _type="text"
        value={name}
        locked
        iconPosition="right"
        icon="lock-closed-solid"
      />
    </div>
  );
};

export default ApprovalsUserName;
