import { APPROVE_PAGE, REQUEST_REVISION_PAGE } from "../constants/app-routes";
import { SnapButton } from "../suit";
import { useNavigate } from "react-router-dom";

interface ApprovalRevisionButtonsProps {
  token: string;
  className?: string;
}

const ApprovalRevisionButtons: React.FC<ApprovalRevisionButtonsProps>  = ({token, className = ""}) => {
  const navigate = useNavigate();

  const approvePage = () => {
    navigate(`${APPROVE_PAGE}/${token}`);
  };

  const requestRevisionPage = () => {
    navigate(`${REQUEST_REVISION_PAGE}/${token}`);
  };

  return (
    <div data-testid="approval-revision-buttons" className={`flex flex-col sm:flex-row gap-5 w-full ${className}`}>
      <SnapButton
        onClick={approvePage}
        fullWidth
        variant="success"
        className="w-full sm:w-1/2"
      >
        Approve
      </SnapButton>
      <SnapButton
        onClick={requestRevisionPage}
        fullWidth
        variant="secondary"
        className="w-full sm:w-1/2"
      >
        Request Revisions
      </SnapButton>
    </div>
  );
};

export default ApprovalRevisionButtons;
