
interface ReadonlyFormValueProps {
  testId: string;
  label: string;
  value: string;
  forceTwoLines?: boolean;
  className?: string;
}

const ReadonlyFormValue : React.FC<ReadonlyFormValueProps> = ({ testId, label, value, forceTwoLines = false, className = "" }) => {

  return (
    <div
      data-testid={testId}
      className={`flex ${forceTwoLines ? "flex-col" : "flex-row"} justify-between flex-wrap ${className}`}
    >
      <p className={`text-sm text-gray-500 font-medium ${forceTwoLines ? "mb-1" : ""}`}>{label}</p>
      <p className="text-base text-gray-800 font-normal">{value}</p>
    </div>
  );
}

export default ReadonlyFormValue;
