import { useReducer } from "react"
import { OrgNotificationPreference } from "../../graphql/generated"
import { isOrgNotificationOffOnDB } from "./Utils"
interface orgNotificationsState  {
  affectedAddedNotifications: string[]
  affectedRemovedNotifications: string[]
}

const initialState: orgNotificationsState = {
  affectedAddedNotifications: [],
  affectedRemovedNotifications: []
}
interface UpdateOrgSettingsPayload {
  addedOrgNotifications: string[],
  removedOrgNotifications: string[]
}
interface orgSettingsReducerAction {
  type: string;
  payload: UpdateOrgSettingsPayload;
}

const reducer = (state: orgNotificationsState, action: orgSettingsReducerAction)=>{
  const {payload} = action
  switch (action.type) {
      case "UPDATE_ORG_NOTIFICATIONS":
          return {
            ...state,
            affectedAddedNotifications: payload.addedOrgNotifications,
            affectedRemovedNotifications: payload.removedOrgNotifications
          }
      default:
          return state;
  }
}

export const UseOrgNotificationsSettings = (orgNotificationPreferences: OrgNotificationPreference[])=>{

  const [orgNoticationsState, dispatch] = useReducer(reducer, initialState)
  
  const bulkAddRemoveOrg = (checkedOrgIds:string[], unCheckedOrgIds:string[], currentTarge:Record<string,string>) => {
    
    if( unCheckedOrgIds.length > 0){
      removeOrgs(unCheckedOrgIds);
    }
    if( checkedOrgIds.length > 0){
      addOrgs(checkedOrgIds); 
    }
      
  }

  const removeOrgs = (unCheckedOrgIds:string[])=>{
    let updatedRemovedOrgNotifications = [...orgNoticationsState.affectedRemovedNotifications];
    let updatedAffectedList: string[] = []
   
    updatedAffectedList = unCheckedOrgIds.reduce((mem, orgId)=>{
          if(!isOrgNotificationOffOnDB(orgNotificationPreferences, orgId)){
              return [...mem, orgId]
          }
          return [...mem]
      }, updatedAffectedList)
   
    if(updatedAffectedList.length > 0) {
      let allRemovedNotifications = [...updatedAffectedList, ...orgNoticationsState.affectedRemovedNotifications];
      allRemovedNotifications = allRemovedNotifications.filter((org, idx,)=>allRemovedNotifications.indexOf(org)=== idx)
      updatedRemovedOrgNotifications = allRemovedNotifications
    } 
  
    const updatedAddedOrgNotifications = [...orgNoticationsState.affectedAddedNotifications].filter((orgId)=> {
      return (unCheckedOrgIds.indexOf(orgId) < 0 && isOrgNotificationOffOnDB(orgNotificationPreferences, orgId) ) ?
      true :
      false;
    });

    dispatch({
      type: "UPDATE_ORG_NOTIFICATIONS",
      payload:{
        addedOrgNotifications: updatedAddedOrgNotifications,
        removedOrgNotifications: updatedRemovedOrgNotifications
      }
    })
  }

  const addOrgs = (checkedOrgIds:string[])=>{
    let updatedAddedOrgNotifications = [...orgNoticationsState.affectedAddedNotifications];
    let updatedAffectedList: string[] = []

    updatedAffectedList = checkedOrgIds.reduce((mem, orgId)=>{
        if(isOrgNotificationOffOnDB(orgNotificationPreferences, orgId)){
            return [...mem, orgId]
        }
        return [...mem]
    }, updatedAffectedList)
    
      if(updatedAffectedList.length > 0){
        let allAddedNotifications = [...updatedAffectedList, ...orgNoticationsState.affectedAddedNotifications];
        updatedAddedOrgNotifications = allAddedNotifications.filter((org, idx)=> allAddedNotifications.indexOf(org) === idx );
      }
    const updatedRemovedOrgNotifications = [...orgNoticationsState.affectedRemovedNotifications].filter((orgId)=> {
      return (checkedOrgIds.indexOf(orgId) < 0 && !isOrgNotificationOffOnDB(orgNotificationPreferences, orgId) ) ?
      true :
      false;
    });

    dispatch({
      type: "UPDATE_ORG_NOTIFICATIONS",
      payload:{
        addedOrgNotifications: updatedAddedOrgNotifications,
        removedOrgNotifications: updatedRemovedOrgNotifications
      }
    })
  }

  const isOrgRemoved = (orgId:string) : boolean | undefined=>{
      if(orgNoticationsState.affectedRemovedNotifications.findIndex((org)=>org === orgId) >= 0){
          return true;
      } else {
        if(isOrgNotificationOffOnDB(orgNotificationPreferences, orgId)&& 
        orgNoticationsState.affectedAddedNotifications.indexOf(orgId) < 0){
          return true;
        } else{
          return false;
        }
      }
  }

  const resetOrgNotifications=()=>{
    dispatch({
      type: "UPDATE_ORG_NOTIFICATIONS",
      payload:{
        addedOrgNotifications: [],
        removedOrgNotifications: []
      }
    })
  }

  return {
      addOrgs,
      affectedAddedOrgs: orgNoticationsState.affectedAddedNotifications,
      affectedRemovedOrgs: orgNoticationsState.affectedRemovedNotifications,
      bulkAddRemoveOrg,
      isOrgRemoved,
      removeOrgs,
      resetOrgNotifications
  }
}
