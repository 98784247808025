import {
  FundraiserApprovalSubmission,
  Org,
} from "../graphql/generated";
import { FORM_STEPS, ORG_TYPES } from "./constants";

export const findAssociatedOrgs = (orgs: Org[]) => {
  const district = findDistrict(orgs);
  const school = findSchool(orgs);
  const affiliate = findAffiliate(orgs);
  const program = findProgram(orgs);
  return affiliate.length > 0
    ? [...program, ...affiliate, ...district]
    : [...program, ...school, ...district];
};

const findDistrict = (orgs: Org[]) => {
  return orgs.filter((org) => org.type === ORG_TYPES.DISTRICT);
};

const findProgram = (orgs: Org[]) => {
  return orgs.filter((org) => org.type === ORG_TYPES.PROGRAM);
};

const findSchool = (orgs: Org[]) => {
  return orgs.filter(
    (org) => org.type === ORG_TYPES.SCHOOL || org.type === ORG_TYPES.SHELLSCHOOL
  );
};

const findAffiliate = (orgs: Org[]) => {
  return orgs.filter(
    (org) =>
      org.type === ORG_TYPES.PTA ||
      org.type === ORG_TYPES.BOOSTER ||
      org.type === ORG_TYPES.FOUNDATION ||
      org.type === ORG_TYPES.BUSINESS ||
      org.type === ORG_TYPES.CLUB
  );
};

export const shouldFetchForOrgDetails = ( submission: FundraiserApprovalSubmission, formStep: number)=>{
  if ( !submission.organizationId
  && formStep >= FORM_STEPS.snapFundraiserSelection ) return true;
  return false
}
