import { useNavigate, useParams } from "react-router-dom";
import { VaultForm, StripeEnv, VerifyAccountForm } from "@snap-mobile/vault";
import VaultInfoDisclaimer from "./vault-info-disclaimer";
import WhiteCard from "../components/WhiteCard";
import { useMeQuery, useOrgLazyQuery } from "../graphql/generated";
import { useContext, useEffect } from "react";
import { AppContext } from "../AppGlobalState";
import {
  getOrgFinancialAccount,
  getOrgVaultStatus,
  setShowSubmitSuccessModal,
} from "./vault-data-helper";
import { ORG_VAULT_STATUS } from "./constants";

const VaultOrgForm = () => {
  const {
    appState: {
      userAssociationsFinancialAccIndex,
      userAssociationsFinancialUsersIndex,
      userAssociationsVaultStatusIndex,
      vault: { referrerPath }
    },
    dispatch,
  } = useContext(AppContext);
  const navigate = useNavigate();
  const { orgId } = useParams();
  const { data: userData } = useMeQuery();
  const userId = userData?.me?.id;
  const [fetchOrgFinancialStatus, { data: orgData }] = useOrgLazyQuery();

  useEffect(() => {
    if (!orgData?.financialAcctOrg) {
      return;
    }
    const financialStatus = orgData.financialAcctOrg;
    dispatch({
      type: "UPDATE_ORG_FINANCIAL_ACCOUNT_STATUS",
      payload: {
        orgId,
        financialStatus,
      },
    });
    setTimeout(() => navigate(`/dashboards/${orgId}/overview`), 100)

  }, [orgData, dispatch, orgId, navigate]);

  if (!userId || !orgId) {
    return null;
  }

  const status = getOrgVaultStatus(userAssociationsFinancialAccIndex, userAssociationsFinancialUsersIndex, userAssociationsVaultStatusIndex, orgId, userId);
  const financialAccount = getOrgFinancialAccount(userAssociationsFinancialAccIndex, orgId);
  const statusUnsubmitted =
    status !== ORG_VAULT_STATUS.approved &&
    status !== ORG_VAULT_STATUS.underReview;

  const exitVaultPage = () => {
    if (!statusUnsubmitted) {
      navigate(`/vault`);
      return;
    }
    if (referrerPath !== null) {
      navigate(referrerPath);
      return;
    }
    navigate(`/dashboards/${orgId}/overview`);
  };

  const renderVaultForm = () => {
    if (status === ORG_VAULT_STATUS.uploadDocuments) {
      if (!financialAccount?.acctId) {
        return (
          <p className="font-normal text-gray-800 text-sm">
            We had a problem retrieving your financial account details, please
            contact support for assistance.
          </p>
        );
      }
      return (
        <VerifyAccountForm
          acctId={financialAccount?.acctId}
          personId={userId}
          type="entity_documentation"
          provider="stripe"
          onSuccess={() => {
            onFormSubmitSuccess();
          }}
        />
      );
    }
    return (
      <VaultForm
        awaitOrgId={false}
        orgId={orgId}
        payableOrgId={orgId}
        currentUserId={userId}
        provider="stripe"
        onSuccess={(successData) => null}
        createKybKycOnClose={() => {
          onFormSubmitSuccess();
        }}
        orgConfirmationOnClose={() => {
          exitVaultPage();
        }}
        stripeEnv={StripeEnv.RAISE}
        showSubmitForReviewButton={statusUnsubmitted}
        showSavePayableCheckbox={"REQUIRED"}
        embeded={true}
        awaitCurrentUserId={false}
        awaitPayableOrgId={false}
      />
    );
  };

  const onFormSubmitSuccess = () => {
    fetchOrgFinancialStatus({ variables: { orgId, hierarchy: false } }).then(
      (response) => {
        setShowSubmitSuccessModal(true, dispatch);
      }
    );
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      <WhiteCard testId="vault-form-container" className="sm:col-span-2">
        <div className="w-full overflow-y-auto">{renderVaultForm()}</div>
      </WhiteCard>
      <VaultInfoDisclaimer
        testId="vault-disclaimer"
        className="hidden md:block h-fit"
      />
    </div>
  );
};

export default VaultOrgForm;
