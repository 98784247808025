import { ReducerAction } from "../AppGlobalState";
import {
  FinAcctStatus,
  FinancialAcctOrg,
  FinancialAcctUser,
  VaultAccountStatus,
} from "../graphql/generated";
import { ORG_VAULT_STATUS } from "./constants";

export const isVaultActionRequired = (
  userAssociationsFinancialAccIndex: Record<string, FinancialAcctOrg[]>,
  userAssociationsFinancialUsersIndex: Record<string, FinancialAcctUser[]>,
  userAssociationsVaultStatusIndex: Record<string, VaultAccountStatus>,
  userId: string
): boolean => {
  let status: ORG_VAULT_STATUS;
  const financialAccountsOrgIds = Object.keys(
    userAssociationsFinancialAccIndex
  );
  for (const index in financialAccountsOrgIds) {
    status = getOrgVaultStatus(
      userAssociationsFinancialAccIndex,
      userAssociationsFinancialUsersIndex,
      userAssociationsVaultStatusIndex,
      financialAccountsOrgIds[index],
      userId
    );
    if (
      status === ORG_VAULT_STATUS.start ||
      status === ORG_VAULT_STATUS.profile ||
      status === ORG_VAULT_STATUS.uploadDocuments
    ) {
      //for uploadDocuments we have to check if user id matches submitter id
      return true;
    }
  }
  return false;
};

export const getOrgVaultStatus = (
  userAssociationsFinancialAccIndex: Record<string, FinancialAcctOrg[]>,
  userAssociationsFinancialUsersIndex: Record<string, FinancialAcctUser[]>,
  userAssociationsVaultStatusIndex: Record<string, VaultAccountStatus>,
  orgId: string,
  userId: string
): ORG_VAULT_STATUS => {
  const orgFinancialAccounts: FinancialAcctOrg[] =
    userAssociationsFinancialAccIndex[orgId];

  if (!orgFinancialAccounts) {
    return ORG_VAULT_STATUS.unknown;
  }
  const latestFinancialAccount =
    getLatestFinancialAccount(orgFinancialAccounts);
  if (
    latestFinancialAccount === null ||
    latestFinancialAccount.status === FinAcctStatus.Terminated
  ) {
    return ORG_VAULT_STATUS.start;
  }
  const accountStatus = latestFinancialAccount.status;

  if (accountStatus === FinAcctStatus.Pending) {
    return ORG_VAULT_STATUS.underReview;
  }
  if (accountStatus === FinAcctStatus.Approved) {
    return ORG_VAULT_STATUS.approved;
  }

  if (accountStatus !== FinAcctStatus.ActionRequired) {
    return ORG_VAULT_STATUS.underReview;
  }

  if (!orgHasRequiredDocuments(userAssociationsVaultStatusIndex, orgId)) {
    return ORG_VAULT_STATUS.profile;
  }

  if (!currentUserMatchesFinancialUserForOrg(userAssociationsFinancialUsersIndex, orgId, userId)) {
    return ORG_VAULT_STATUS.underReview;
  }

  return ORG_VAULT_STATUS.uploadDocuments;
};

export const getOrgFinancialAccount = (
  userAssociationsFinancialAccIndex: Record<string, FinancialAcctOrg[]>,
  orgId: string
): FinancialAcctOrg | null => {
  const orgFinancialAccounts: FinancialAcctOrg[] =
  userAssociationsFinancialAccIndex[orgId] || [];
  return getLatestFinancialAccount(orgFinancialAccounts);
};

export const getLatestFinancialAccount = (
  accounts: FinancialAcctOrg[]
): FinancialAcctOrg | null => {
  if (!accounts || accounts.length === 0) return null;
  return accounts.reduce((accountA, accountB) => {
    return new Date(accountA.updatedAt) > new Date(accountB.updatedAt)
      ? accountA
      : accountB;
  });
};

export const getLabelForOrgStatus = (status: ORG_VAULT_STATUS) => {
  switch (status) {
    case ORG_VAULT_STATUS.start:
      return "Setup Required";
    case ORG_VAULT_STATUS.profile:
    case ORG_VAULT_STATUS.uploadDocuments:
      return "Action Required";
    case ORG_VAULT_STATUS.underReview:
      return "Pending";
    case ORG_VAULT_STATUS.approved:
      return "Approved";
    default:
      return "";
  }
};

export const orgHasRequiredDocuments = (
  userAssociationsVaultStatusIndex: Record<string, VaultAccountStatus>,
  orgId: string
): boolean => {
  const vaultStatus = userAssociationsVaultStatusIndex[orgId];
  if (!vaultStatus) return false;
  const representativeRequiredDocs =
    vaultStatus.accountRepresentativeRequirements &&
    vaultStatus.accountRepresentativeRequirements.requiredDocuments.length > 0;
  const kybRequiredDocs =
    vaultStatus.kybRequirements &&
    vaultStatus.kybRequirements?.requiredDocuments.length > 0;
  const beneficialOwnersRequiredDocs = beneficialOwnersHasRequiredDocs(vaultStatus);
  return (
    representativeRequiredDocs ||
    kybRequiredDocs ||
    beneficialOwnersRequiredDocs
  );
};

const beneficialOwnersHasRequiredDocs = (
  vaultStatus: VaultAccountStatus
): boolean => {
  if (!vaultStatus.beneficialOwnersRequirements) {
    return false;
  }
  return vaultStatus.beneficialOwnersRequirements.some(
    (requirement) => requirement.requiredDocuments.length > 0
  );
};

export const currentUserMatchesFinancialUserForOrg = (
  userAssociationsFinancialUsersIndex: Record<string, FinancialAcctUser[]>,
  orgId: string,
  userId: string
): boolean => {
  const financialusers = userAssociationsFinancialUsersIndex[orgId];
  if (!financialusers) {
    return false;
  }
  return financialusers.some(
    (user) =>
      user.status === FinAcctStatus.ActionRequired && user.userId === userId
  );
};

export const setShowSubmitSuccessModal = (
  show: boolean,
  dispatch: React.Dispatch<ReducerAction>
) => {
  dispatch({
    type: "SET_VAULT_SUCCESS_MODAL_SHOW",
    payload: show,
  });
};
